import { styled, Box, Tab, Button, Typography } from '@mui/material';
import { brighthiveTheme } from "../../theme/theme";

export const LabelHeading = styled(Typography)`
  width: 20%;
  min-width: 200px;
  color: ${brighthiveTheme.palette.common.black};
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 0.875rem;
`;

export const FormLabelHeading = styled(Typography)`
  width: 20%;
  color: ${brighthiveTheme.palette.common.black};
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 1rem;
`;

export const FormLabelSubHeading = styled(Typography)`
  color: ${brighthiveTheme.palette.primary.darkest};
  font-weight: 400;
  margin-bottom: 8px;
  font-size: 0.875rem;
`;

export const WidgetLabel = styled(Typography)`
  margin: 0;
  font-size: 1rem;

  @media (min-width: 1350px) {
    font-size: 1.125rem;
  }
`;

export const WidgetLabelIndented = styled(Typography)`
  margin: 0 0 1rem;
  font-size: 1rem;

  @media (min-width: 1350px) {
    font-size: 1.125rem;
  }
`;

export const WidgetSubLabel = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 700;
  color: ${brighthiveTheme.palette.common.black}
`;

export const ButtonDefault = styled(Button)`
  color: ${brighthiveTheme.palette.gray.dark};
  border-radius: 3px;
  border: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
  min-width: 1px;
  padding: 6px 16px;

  &.small{
    width: 36px;
    height: 36px;
    padding: 0;
  }

  &.active,
  &:hover{
    border-color: ${brighthiveTheme.palette.primary.dark};
    background-color: ${brighthiveTheme.palette.primary.active};
    color: ${brighthiveTheme.palette.primary.dark};
  }
`;

export const ButtonPrimary = styled(Button)`
  background-color: ${brighthiveTheme.palette.primary.main};
  color: ${brighthiveTheme.palette.primary.darkest};
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: none;
  min-width: 1px;
  padding: 6px 16px;

  &:hover{
    background-color: ${brighthiveTheme.palette.primary.dark};
    color: ${brighthiveTheme.palette.common.white};
    border-color: transparent;
    box-shadow: none;
  }
`;

export const BtnsWrap = styled(Box)`
  display: flex;
  gap: 14px;
`;

export const SubHeadingEditMode = styled(Box)`
  margin-bottom: 0;
  font-weight: 500;
  font-size: 1rem;

  @media screen and (min-width: 992px){
    font-size: 1.5rem;
  }
`;