import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const FilterIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.16904 4.05635C5.27539 4.01905 5.38728 4 5.49999 4H18.5C18.6127 4 18.7246 4.01905 18.8309 4.05635C19.119 4.15737 19.3801 4.32297 19.5942 4.54048C19.8084 4.75799 19.9699 5.02162 20.0664 5.31119C20.1629 5.60076 20.1919 5.90858 20.1511 6.21108C20.1103 6.51358 20.0007 6.80272 19.8309 7.05635C19.8035 7.09732 19.7731 7.13619 19.7399 7.17267L15 12.3866V19C15 19.3788 14.786 19.725 14.4472 19.8944C14.1084 20.0638 13.703 20.0273 13.4 19.8L9.39999 16.8C9.14818 16.6111 8.99999 16.3148 8.99999 16V12.3866L4.26005 7.17267C4.22688 7.1362 4.19646 7.09732 4.16904 7.05635C3.99922 6.80272 3.88971 6.51358 3.84889 6.21108C3.80808 5.90859 3.83704 5.60077 3.93357 5.31119C4.03009 5.02162 4.19161 4.75798 4.40576 4.54048C4.61991 4.32297 4.881 4.15737 5.16904 4.05635ZM5.8969 6L10.7399 11.3273C10.9073 11.5114 11 11.7512 11 12V15.5L13 17V12C13 11.7512 13.0927 11.5114 13.26 11.3273L18.1031 6H5.8969Z"
      />
    </SvgIcon>
  );
};
