import { AllSources } from "../common/Icons/AllSources";
import { Internal } from "../common/Icons/Internal";
import { Marketplace } from "../common/Icons/Marketplace";
import { Partner } from "../common/Icons/Partner";
import { Subscriptions } from "../common/Icons/Subscriptions";

export const getSource = (source: any, iconClassName?: string) => {
  let sourceItem = <AllSources className={iconClassName} />;
  if (source?.toLowerCase() === "internal")
    sourceItem = <Internal className={iconClassName} />;
  if (source?.toLowerCase() === "marketplace")
    sourceItem = <Marketplace className={iconClassName} />;
  if (source?.toLowerCase() === "partner")
    sourceItem = <Partner className={iconClassName} />;
  if (source?.toLowerCase() === "subscriptions")
    sourceItem = <Subscriptions className={iconClassName} />;

  return sourceItem;
};
