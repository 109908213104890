import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const EditPencilIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4756 5.41593L13.4756 5.41595L4.72565 14.1659C4.70994 14.1816 4.68869 14.1904 4.66667 14.1904H1.33333C1.2873 14.1904 1.25 14.1531 1.25 14.107V10.7737C1.25 10.7516 1.25881 10.7304 1.27436 10.7148L1.27441 10.7148L10.0244 1.96477C10.0244 1.96477 10.0244 1.96477 10.0244 1.96476C10.4821 1.50709 11.1028 1.25 11.75 1.25C12.3972 1.25 13.0179 1.5071 13.4756 1.96477C13.9333 2.42241 14.1903 3.04311 14.1903 3.69036C14.1903 4.3376 13.9333 4.9583 13.4756 5.41593ZM9.78033 3.5055L9.25001 2.97518L8.71968 3.50549L1.63635 10.5885L1.41667 10.8082V11.1189V13.2737V14.0237H2.16667H4.32149H4.63218L4.85186 13.804L11.9345 6.72029L12.4648 6.18996L11.9345 5.65967L9.78033 3.5055ZM10.1423 2.0826L9.89857 2.32577L9.3671 2.8561L9.898 3.387L12.053 5.542L12.5831 6.07213L13.1135 5.5422L13.3575 5.2983L13.3577 5.2981C13.7842 4.87167 14.0237 4.29334 14.0237 3.69036C14.0237 3.08738 13.7842 2.50904 13.3577 2.08261L13.3577 2.0826C12.9313 1.65621 12.353 1.41667 11.75 1.41667C11.1473 1.41667 10.5692 1.656 10.1428 2.08204C10.1428 2.08207 10.1428 2.08211 10.1427 2.08214C10.1426 2.08229 10.1424 2.08245 10.1423 2.0826Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="1.5"
      />
    </SvgIcon>
  );
};
