import React from "react";
import { Box, useTheme } from "@mui/material";
import { SquareIconButton } from "../common/SquareIconButton/SquareIconButton";
import { GridView } from "@mui/icons-material";
import { Link } from "../common/Link/Link";
import { Typography } from "../common/Typography/Typography";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { GovernancePolicyDetail } from "./GovernancePolicyDetail";
import { BasicPage } from "../common/BasicPage/BasicPage";
import { useLocation, useParams } from "react-router-dom";
import useGetWorkspace from "../hooks/useGetWorkspace";

export interface GovernancePolicyDetailPageProps {}

export const GovernancePolicyDetailPage = () => {
  const { palette } = useTheme();
  const { workspaceId } = useParams();

  const { workspace } = useGetWorkspace();
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  return (
    <BasicPage
      sx={{
        ".right-elements": {
          alignSelf: "flex-end",
        },
        background: palette.common.white,
      }}
      leftHeaderElements={
        <Box>
          <Box mb={3} display="flex" alignItems={"center"}>
            <SquareIconButton
              variant="contained"
              size="small"
              icon={<GridView />}
              color="settings"
              sx={{
                textTransform: "initial",
                pointerEvents: "none",
                mr: 2,
                boxShadow: "none",
              }}
            />
            <Link
              to={`/workspace/${workspaceId}/governance`}
              display="flex"
              alignItems="center"
            >
              <Typography variant="body3" color={palette.link.main}>
                {workspace?.name}
              </Typography>
            </Link>
            <ChevronRightIcon fontSize="small" sx={{ mx: 1 }} />
            <Link
              to={`/workspace/${workspaceId}/governance?tab=policies`}
              display="flex"
              alignItems="center"
            >
              <Typography variant="body3" color={palette.link.main}>
                Governance
              </Typography>
            </Link>
            <ChevronRightIcon fontSize="small" sx={{ mx: 1 }} />
            <Typography variant="body3">Workspace policy</Typography>
          </Box>
          <Box display="flex">
            <Typography mr={4} variant="h5">
              Policy Detail
            </Typography>
          </Box>
        </Box>
      }
      content={<GovernancePolicyDetail />}
    />
  );
};
