import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const InfoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
      {...props}
    >
      <path
        d="M7.18473 0.478333C3.50473 0.478333 0.518066 3.465 0.518066 7.145C0.518066 10.825 3.50473 13.8117 7.18473 13.8117C10.8647 13.8117 13.8514 10.825 13.8514 7.145C13.8514 3.465 10.8647 0.478333 7.18473 0.478333ZM7.18473 10.4783C6.81807 10.4783 6.51807 10.1783 6.51807 9.81167V7.145C6.51807 6.77833 6.81807 6.47833 7.18473 6.47833C7.5514 6.47833 7.8514 6.77833 7.8514 7.145V9.81167C7.8514 10.1783 7.5514 10.4783 7.18473 10.4783ZM7.8514 5.145H6.51807V3.81167H7.8514V5.145Z"
        fill="#008575"
      />
    </SvgIcon>
  );
};
