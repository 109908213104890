import { useState } from "react";
import { Box, Grid } from "@mui/material";

import { AssetInformation } from "./AssetInformation";
import { AssetInformationEdit } from "./AssetInformationEdit";
import { AdditionalDetails } from "./AdditionalDetails";
import { Issues } from "./Issues";

import { GetADataAssetDocument } from "../../generated";
import { useQuery } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";
import { SimpleLoader } from "../../common/Loader/Loader";
import { LoaderBox, PageGrid } from "../style";

export interface AssetDetailOverviewProps {
  isEditMode: boolean;
}

export const AssetDetailOverview = ({
  isEditMode,
}: AssetDetailOverviewProps) => {
  const { workspaceId, dataAssetId } = useParams();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, loading, refetch } = useQuery(GetADataAssetDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
      dataAssetFilter: {
        dataAssetId: dataAssetId || "",
      },
    },
  });

  const handleCloseEdit = () => {
    navigate(`/workspace/${workspaceId}/asset-details/${dataAssetId}`, {
      replace: true,
    });
  };

  const handleOpenEdit = () => {
    navigate(
      `/workspace/${workspaceId}/asset-details/${dataAssetId}?edit=true`,
      { replace: true }
    );
  };

  if (loading) {
    return (
      <LoaderBox>
        <SimpleLoader />
      </LoaderBox>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <PageGrid className="AssetDetailOverview" container spacing={8}>
      <Grid item xs>
        {isEditMode ? (
          <AssetInformationEdit
            data={data}
            onSave={() => {
              handleCloseEdit();
              refetch();
            }}
            onCancel={handleCloseEdit}
          />
        ) : (
          <AssetInformation data={data} onEdit={handleOpenEdit} />
        )}
      </Grid>
      <Grid item sx={{ width: "27rem" }}>
        <AdditionalDetails data={data} mb={"1.5rem"} />
        {/* <Issues data={data?.workspace?.dataAssets?.[0]?.issues || []} /> */}
      </Grid>
    </PageGrid>
  );
};
