import { styled, Box, IconButton, Typography, Button } from "@mui/material";
import { brighthiveTheme } from "../../theme/theme";

export const MetadataHolder = styled(Box)`
  &.metadata-holder {
    grid-template-columns: 1fr 18rem;
    @media (max-width: 1400px) {
      grid-template-columns: none;
    }
  }
`;
