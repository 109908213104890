import React from "react";
import { Box, BoxProps, Divider, useTheme } from "@mui/material";
import { BasePageHeader } from "../BasePageHeader/BasePageHeader";
// import { Navbar } from "../Navbar/Navbar";
import { Footer } from "../Footer/Footer";
import { useQuery } from "@apollo/client";
import { GetCurrentUserInfoDocument } from "../../generated";

export interface BasicPageProps {
  content?: React.ReactNode;
  leftHeaderElements?: React.ReactNode;
  rightHeaderElements?: React.ReactNode;
}

export const BasicPage = ({
  content,
  leftHeaderElements,
  rightHeaderElements,
  ...rest
}: BasicPageProps & BoxProps) => {
  const { palette } = useTheme();

  const { data } = useQuery(GetCurrentUserInfoDocument);

  // const fullName = `${data?.currentUser?.firstName || ""} ${
  //   data?.currentUser?.lastName
  // }`;

  // const emailAddress = data?.currentUser?.emailAddress;

  if (!data) return null;

  return (
    <Box
      sx={{ background: palette.common.white }}
      height="100%"
      display="flex"
      flexDirection="column"
      {...rest}
    >
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        bgcolor={palette.background.default}
        pl={6}
        pr={6}
        pt={4}
        pb={4}
      >
        <BasePageHeader
          leftElements={leftHeaderElements}
          rightElements={rightHeaderElements}
        />
        <Divider sx={{ mb: 4, mt: 4 }} />
        <Box display="flex" flexDirection="column" flexGrow={1} p={1}>
          {content}
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
