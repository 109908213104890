import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const KeyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M21.5834 2.00003H19.0834C19.0288 1.99936 18.9746 2.01014 18.9244 2.03167C18.8741 2.0532 18.829 2.085 18.7918 2.12503L10.8501 10.0667C9.51744 9.45702 8.01293 9.33503 6.59946 9.72203C5.18599 10.109 3.95347 10.9804 3.11726 12.1839C2.28106 13.3874 1.89436 14.8465 2.02474 16.3062C2.15512 17.7659 2.79428 19.1333 3.83054 20.1696C4.8668 21.2058 6.23424 21.845 7.69392 21.9754C9.1536 22.1058 10.6127 21.7191 11.8162 20.8829C13.0197 20.0466 13.8911 18.8141 14.2781 17.4007C14.6651 15.9872 14.5431 14.4827 13.9334 13.15L15.8668 11.225L17.4751 11.7667C17.6154 11.8173 17.7668 11.8293 17.9134 11.8015C18.06 11.7736 18.1964 11.7069 18.3084 11.6084C18.4162 11.5165 18.4988 11.3986 18.5484 11.2659C18.5979 11.1332 18.6129 10.9901 18.5918 10.85L18.2584 8.83336L18.8584 8.22503L20.0751 8.78336C20.2167 8.84625 20.373 8.86842 20.5265 8.84737C20.68 8.82633 20.8246 8.76291 20.9441 8.66423C21.0635 8.56555 21.1531 8.43553 21.2027 8.28876C21.2524 8.14199 21.2601 7.98429 21.2251 7.83336L20.8001 6.32503L21.8751 5.2417C21.9196 5.20083 21.954 5.15023 21.9757 5.09385C21.9974 5.03747 22.0057 4.97684 22.0001 4.9167V2.4167C22.0001 2.30619 21.9562 2.20021 21.8781 2.12207C21.7999 2.04393 21.6939 2.00003 21.5834 2.00003ZM7.83342 17.4167C7.83342 17.6639 7.76011 17.9056 7.62276 18.1112C7.48541 18.3167 7.29019 18.4769 7.06178 18.5715C6.83337 18.6662 6.58204 18.6909 6.33956 18.6427C6.09708 18.5944 5.87436 18.4754 5.69954 18.3006C5.52472 18.1258 5.40567 17.903 5.35744 17.6606C5.30921 17.4181 5.33396 17.1667 5.42857 16.9383C5.52318 16.7099 5.6834 16.5147 5.88896 16.3774C6.09452 16.24 6.3362 16.1667 6.58342 16.1667C6.91494 16.1667 7.23289 16.2984 7.46731 16.5328C7.70173 16.7672 7.83342 17.0852 7.83342 17.4167Z" />
    </SvgIcon>
  );
};
