import { Box, useTheme } from "@mui/material";
import { ActionCell } from "./ActionCell";
import { dateHelper } from "../../helpers";
import ProfileAvatarIcon from "../../common/ProfileAvatarIcon/ProfileAvatarIcon";
import { ResourceHanlder } from "../../common/ResourceHanlder";
import {
  ContentCardWrapper,
  StyledAvatarContainer,
  StyledAvatarTypography,
  StyledBottomDetailsContainer,
  StyledCard,
  StyledDateTypography,
  StyledDetailsContainer,
  StyledLeftBottomDetailsContainer,
  StyledLeftBottomDetailsSubContainer,
  StyledResourceHandlerContainer,
  StyledRightBottomContainer,
  StyledRightBottomSubContainer,
  StyledTitleContainer,
  StyledTitleSubContainer,
  StyledTitleTypography,
} from "./style";
import Resource from "../../contexts/resource";
import { DisplayDrawerHandlerType } from "../../common/Drawer";
import PathLink from "../Resources/PathLink";
import { LinesEllipsisTypography } from "../../common/LinesEllipsisTypography/LinesEllipsisTypography";

export const CardView = ({
  emailAddress,
  username,
  rowData,
  displayDrawerHandler,
}: {
  emailAddress: string;
  username: string;
  rowData: any;
  displayDrawerHandler: DisplayDrawerHandlerType;
}) => {
  const {
    editMode: { setEditing },
  } = Resource.useContext();

  const { palette } = useTheme();

  const openViewHandler =
    (row: any) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const excludeDiv = document.getElementById(
        `resource-action-cell-container-${row?.id}`
      );
      const excludePopover = document.getElementById("project-action-items");
      const excludeDeleteDialog = document.getElementById(
        "delete-resource-dialog"
      );
      const excludeMoveModal = document.getElementById("move-resource-modal");
      if (
        !excludeDiv?.contains(e.target as any) &&
        !excludePopover?.contains(e.target as any) &&
        !excludeDeleteDialog?.contains(e.target as any) &&
        !excludeMoveModal?.contains(e.target as any)
      ) {
        displayDrawerHandler("view", row?.id);
      }
    };

  return (
    <ContentCardWrapper className="ag-theme-material ContentCardWrapper">
      {rowData?.map((row: any) => (
        <StyledCard key={row?.id}>
          <PathLink
            data={row}
            // href={row.path}
            target="_blank"
            rel="noreferrer"
          >
            <StyledResourceHandlerContainer>
              <ResourceHanlder
                path={row.path}
                isFolder={row.isFolder}
                type={row.type}
              >
                {/* <DocViewer
                  documents={[
                    {
                      uri: row.path,
                    },
                  ]}
                  requestHeaders={{
                    mode: "no-cors",
                  }}
                  pluginRenderers={DocViewerRenderers}
                  config={{
                    header: {
                      disableFileName: true,
                    },
                    noRenderer: {
                      overrideComponent: (<h1>hi</h1>) as any,
                    },
                  }}
                /> */}
              </ResourceHanlder>
            </StyledResourceHandlerContainer>
          </PathLink>
          <StyledDetailsContainer onClick={openViewHandler(row)}>
            <StyledTitleContainer>
              <StyledTitleSubContainer>
                <StyledTitleTypography variant="h2">
                  {row?.name ? row?.name : row?.path}
                </StyledTitleTypography>
              </StyledTitleSubContainer>
            </StyledTitleContainer>
            <LinesEllipsisTypography
              flexGrow={1}
              mt={1}
              mb={4}
              color={palette.gray.dark}
              variant="body3"
              text={row?.description}
              maxLines={2}
            />
            <StyledBottomDetailsContainer>
              <StyledLeftBottomDetailsContainer>
                <StyledLeftBottomDetailsSubContainer>
                  <StyledAvatarContainer>
                    <ProfileAvatarIcon
                      email={emailAddress}
                      name={username}
                      width={20}
                      height={20}
                      className="profileAvatarIcon"
                    />
                  </StyledAvatarContainer>
                  <Box>
                    <StyledAvatarTypography variant="h3">
                      {row.isFolder ? "Folder" : row?.type}
                    </StyledAvatarTypography>
                  </Box>
                </StyledLeftBottomDetailsSubContainer>
              </StyledLeftBottomDetailsContainer>
              <StyledRightBottomContainer>
                <StyledRightBottomSubContainer>
                  <Box>
                    <StyledDateTypography variant="caption">
                      {dateHelper(row?.createdAt)}
                    </StyledDateTypography>
                  </Box>
                  <Box id={`resource-action-cell-container-${row?.id}`}>
                    <ActionCell
                      name={row?.name ? row?.name : row?.path}
                      id={row.id}
                      onEdit={() => setEditing(row.id)}
                    />
                  </Box>
                </StyledRightBottomSubContainer>
              </StyledRightBottomContainer>
            </StyledBottomDetailsContainer>
          </StyledDetailsContainer>
        </StyledCard>
      ))}
    </ContentCardWrapper>
  );
};
