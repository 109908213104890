import React, { useState } from "react";
import { Box, Dialog, useTheme } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { Button } from "../../common/Button/Button";
import { Alert } from "../../common/Alert";
import { SnackBar } from "../../common/SnakBar/SnakBar";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { RemoveDataAssetFromFinalDataProductGroupDocument } from "../../generated";

export const DeleteConfirmationDataProduct = ({
  dataProductAssetId,
  dataProductAssetName,
  finalDataProductGroupId,
}: {
  dataProductAssetId: string;
  dataProductAssetName: string;
  finalDataProductGroupId: string;
}) => {
  const { workspaceId } = useParams();
  const { palette } = useTheme();
  const [open, setOpen] = React.useState(true);
  const [showSnakBar, setShowSnakBar] = React.useState(true);
  const [showSnakBarToast, setShowSnakBarToast] = useState<boolean>(false);

  const [del] = useMutation(RemoveDataAssetFromFinalDataProductGroupDocument);

  const handleCloseSnakBar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    setShowSnakBarToast(false);
  };
  const toggleDialog = () => {
    setOpen(false);
  };

  const toggleSnakBar = () => {
    setShowSnakBar(true);
  };
  const onDelete = async () => {
    await del({
      variables: {
        input: {
          dataAssetId: dataProductAssetId,
          finalDataProductGroupId,
          workspaceId: workspaceId || "",
        },
      },
    });
  };
  return (
    <>
      {showSnakBarToast && (
        <SnackBar
          open={showSnakBarToast}
          message="Destination added"
          onClose={handleCloseSnakBar}
        />
      )}
      <Dialog maxWidth="sm" open={open}>
        <Box width="560px" height="auto" p={6} boxSizing="border-box">
          <Typography mb={4} variant="h5" color={palette.common.black}>
            Delete data product from workspace?
          </Typography>
          <Box mb={8}>
            <Alert
              severity="warning"
              sx={{
                marginBottom: "16px",
              }}
            >
              This action will also delete the data product from the workspace
              data asset catalog.
            </Alert>
            <Alert
              severity="warning"
              sx={{
                marginBottom: "16px",
              }}
            >
              This action may cause validation, data lineage, or data quality
              issues.
            </Alert>
            <Typography
              variant="body3"
              color={palette.gray.dark}
              sx={{ mb: 4 }}
            >
              Are you sure you want to <strong>delete</strong> the following
              data product from the workspace and from the project?
            </Typography>
            <Typography
              variant="body3"
              color={palette.gray.dark}
              sx={{ mb: 2 }}
            >
              <ul>
                <li>{dataProductAssetName}</li>
              </ul>
            </Typography>
          </Box>
          <Box mt={4} display="flex" alignItems={"center"}>
            <Button
              color="grayAccent"
              variant="outlined"
              onClick={toggleDialog}
              sx={{ ml: "auto", mr: 3 }}
            >
              Cancel
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={async (e) => {
                await onDelete();
                setShowSnakBar(false);
                setOpen(false);
                setShowSnakBarToast(true);
                window.location.reload();
              }}
            >
              I understand - delete data product
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
