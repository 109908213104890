import { useQuery } from "@apollo/client";
import { AllGrid } from "../AllGrid/AllGrid";
import { KnowledgeCenter } from "../KnowledgeCenter";
import { GetResourcesDocument } from "../../generated";
import { useNavigate, useParams } from "react-router-dom";
import { ResourceViewer } from "../ResourceViewer";
import { Button } from "../../common/Button/Button";
import RightSidePanel from "../../common/RightSidePanel";
import { EditResource } from "../../Sheets/Resource/Edit";
import Resource from "../../contexts/resource";
import { useState } from "react";
import { DownloadCSV } from "../../common/DownloadCSV/DownloadCSV";

export const Resources = () => {
  const navigate = useNavigate();
  const { folderId } = useParams();
  const [cardView, setCardView] = useState(true);

  const { workspaceId } = useParams();

  const { data: resourceInfo, loading: resourceLoading } = useQuery(
    GetResourcesDocument,
    {
      variables: {
        input: {
          workspaceId: workspaceId || "",
        },
        resourceFilter: {
          id: folderId || "",
        },
      },
      skip: !folderId,
    }
  );

  const resource = resourceInfo?.workspace?.resources?.[0] as any;

  const { data, loading } = useQuery(GetResourcesDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
      resourceFilter: {
        folderId: folderId || "",
      },
    },
  });

  return (
    <Resource.ContextProvider>
      {({ editMode: { resourceId, closeEditor } }) => (
        <KnowledgeCenter
          isFolder={resource?.isFolder}
          title={
            resourceInfo?.workspace
              ? (resource?.isFolder &&
                  resourceInfo?.workspace?.resources?.[0]?.name) ||
                ""
              : "Resources"
          }
          rightElement={
            <>
              {!cardView && !loading && (
                <DownloadCSV json={data?.workspace?.resources} />
              )}
              {resource?.isFolder && (
                <Button onClick={() => navigate(-1)}>Back</Button>
              )}
            </>
          }
        >
          {resource?.isFolder || !resource ? (
            <AllGrid
              cardView={cardView}
              setCardView={setCardView}
              data={data?.workspace?.resources}
              isFolder={resource?.isFolder}
              loading={loading || resourceLoading}
            />
          ) : (
            <>
              <ResourceViewer resource={resource} />
            </>
          )}
          {resourceId && (
            <RightSidePanel isOpen={!!resourceId} onToggle={closeEditor}>
              <EditResource resourceId={resourceId} closeDialog={closeEditor} />
            </RightSidePanel>
          )}
        </KnowledgeCenter>
      )}
    </Resource.ContextProvider>
  );
};
