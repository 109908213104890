import React from "react";
import {
  alpha,
  Box,
  Dialog,
  DialogProps,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  useTheme,
} from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { FormLabel } from "../../common/Form/FormLabel";
import { Select } from "../../common/Form/Select";
import { MenuItem } from "../../common/Menu/MenuItem";
import { Button } from "../../common/Button/Button";

interface SpecificAddDataAssetDialogProps {
  onSubmit: () => void;
  onClose: () => void;
}

type AddDataAssetDialogProps = DialogProps & SpecificAddDataAssetDialogProps;

export const AddDataAssetDialog = (props: AddDataAssetDialogProps) => {
  const { palette, typography } = useTheme();
  const onClickCancel = () => {
    if (props.onClose) props.onClose();
  };

  return (
    <Dialog {...props}>
      <Box width="35rem" p={6} boxSizing="border-box">
        <Typography mb={4} variant="h5">
          Add data asset to project
        </Typography>
        <Typography color={palette.gray.dark} mb={2} variant="body2">
          Please select an existing project or create a new one for
        </Typography>
        <Typography
          color={palette.gray.darkest}
          mb={4}
          variant="body2"
          fontWeight={500}
        >
          Data Asset Name
        </Typography>
        <RadioGroup
          aria-labelledby="project-option"
          defaultValue="female"
          name="project-option"
        >
          <FormControlLabel
            sx={{ ".MuiTypography-root": { ...typography.body2 } }}
            value="addToExistingProject"
            control={<Radio />}
            label="Add to existing project"
          />
          <FormControl
            sx={{
              ml: "1.875rem",
              backgroundColor: alpha(palette.grayAccent.main, 0.06),
              pt: 2,
              pb: 4,
              pl: 4,
              pr: 4,
            }}
          >
            <FormLabel>Target Project</FormLabel>
            <Select>
              <MenuItem value="1">Test Project</MenuItem>
              <MenuItem value="2">Test Project</MenuItem>
              <MenuItem value="3">Test Project</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            sx={{ ".MuiTypography-root": { ...typography.body2 } }}
            value="createNewProject"
            control={<Radio />}
            label="Create a new project"
          />
        </RadioGroup>
        <Box display="flex" mt={7} justifyContent="flex-end">
          <Button
            onClick={onClickCancel}
            color="grayAccent"
            variant="outlined"
            sx={{ mr: 3 }}
          >
            Cancel
          </Button>
          <Button onClick={props.onSubmit} color="primary" variant="contained">
            Submit
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
