import React from "react";
import { Typography } from "../../common/Typography/Typography";
import { Box, useTheme } from "@mui/material";
import { Button } from "../../common/Button/Button";

export interface AdminInfoDialogContentProps {
  onClickOkay: (...args: any[]) => any;
}

export const AdminInfoDialogContent = ({
  onClickOkay,
}: AdminInfoDialogContentProps) => {
  const { palette } = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="35rem"
      p={6}
      boxSizing="border-box"
      overflow="hidden"
    >
      <Typography mb={4} variant="h5">
        Roles and Permissions
      </Typography>

      <Box p={2} sx={{ overflowY: "scroll" }}>
        <Typography color={palette.gray.dark} mb={4} variant="h6">
          Members
        </Typography>
        <Typography color={palette.gray.dark} mb={2} variant="subtitle2">
          Admins
        </Typography>
        <Typography color={palette.gray.dark} mb={4} variant="body2">
          Admins can view members of a workspace as well as add members, remove
          them and update their roles.
        </Typography>
        <Typography color={palette.gray.dark} mb={2} variant="subtitle2">
          Collaborators and Viewers
        </Typography>
        <Typography color={palette.gray.dark} mb={4} variant="body2">
          Collaborators and Viewers can only view members of a workspace.
        </Typography>

        <Typography color={palette.gray.dark} mb={4} variant="h5">
          Governance
        </Typography>
        <Typography color={palette.gray.dark} mb={2} variant="subtitle2">
          Admins
        </Typography>
        <Typography color={palette.gray.dark} mb={4} variant="body2">
          Admins can view governance documents as well as add new documents,
          update documents and remove documents.
        </Typography>
        <Typography color={palette.gray.dark} mb={2} variant="subtitle2">
          Collaborators and Viewers
        </Typography>
        <Typography color={palette.gray.dark} mb={4} variant="body2">
          Collaborators and Viewers can only view governance documents.
        </Typography>

        <Typography color={palette.gray.dark} mb={4} variant="h5">
          Projects
        </Typography>
        <Typography color={palette.gray.dark} mb={2} variant="subtitle2">
          Admins
        </Typography>
        <Typography color={palette.gray.dark} mb={4} variant="body2">
          Admins can view projects as well as create new projects, update
          projects and remove projects.
        </Typography>
        <Typography color={palette.gray.dark} mb={2} variant="subtitle2">
          Collaborators
        </Typography>
        <Typography color={palette.gray.dark} mb={4} variant="body2">
          Collaborators can view projects as well as create new projects and
          update projects. They remove projects if they own the project.
        </Typography>
        <Typography color={palette.gray.dark} mb={2} variant="subtitle2">
          Viewers
        </Typography>
        <Typography color={palette.gray.dark} mb={4} variant="body2">
          Viewers can only view projects.
        </Typography>

        <Typography color={palette.gray.dark} mb={4} variant="h5">
          Data Assets
        </Typography>
        <Typography color={palette.gray.dark} mb={2} variant="subtitle2">
          Admins, Collaborators and Viewers
        </Typography>
        <Typography color={palette.gray.dark} variant="body2">
          Data assets can be browsed by any member of the workspace but can only
          be used with permission.
        </Typography>
      </Box>

      <Box mt={4} display="flex" justifyContent="flex-end">
        <Button onClick={onClickOkay} color="primary" variant="contained">
          Okay
        </Button>
      </Box>
    </Box>
  );
};
