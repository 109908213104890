import { Button, TextField, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import {
  CreateReferenceDocumentInput,
  UpdateReferenceDocumentInput,
} from "../../generated";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { ButtonDefault } from "./style";

const ProjectOverviewBackgroundMaterialForm = ({
  index,
  onUpdate,
  onDelete,
  material,
}: {
  index: number;
  onUpdate: (
    modified: UpdateReferenceDocumentInput | CreateReferenceDocumentInput
  ) => void;
  onDelete: () => void;
  material: UpdateReferenceDocumentInput;
}) => {
  const { palette } = useTheme();
  return (
    <Box
      mb={4}
      sx={{
        background: palette.settings.lighter,
        borderRadius: "4px",
        padding: "20px",
      }}
    >
      <Typography
        variant="body1"
        mb={4}
        fontSize="1.25rem"
        fontWeight={400}
        color={palette.common.black}
      >
        Resource link {index}
      </Typography>
      <Typography
        variant="body1"
        mb={1}
        fontSize="0.875rem"
        fontWeight={400}
        color={palette.common.black}
      >
        Link title
      </Typography>
      <TextField
        value={material?.name}
        sx={{
          width: "100%",
          marginBottom: "24px",
          ".MuiInputBase-input": {
            width: "100%",
            height: "36px",
            fontSize: "16px",
            lineHeight: "20px",
            padding: "8px",
            boxSizing: "border-box",
            background: palette.common.white,
          },
          fieldset: {
            top: "0",
          },
          legend: {
            display: "none",
          },
        }}
        onChange={(e) => {
          onUpdate({
            ...material,
            name: e.currentTarget.value,
          });
        }}
      />
      <Typography
        variant="body1"
        mb={1}
        fontSize="0.875rem"
        fontWeight={400}
        color={palette.common.black}
      >
        URL
      </Typography>
      <TextField
        value={material?.href}
        sx={{
          width: "100%",
          marginBottom: "24px",
          ".MuiInputBase-input": {
            width: "100%",
            height: "36px",
            fontSize: "16px",
            lineHeight: "20px",
            padding: "8px",
            boxSizing: "border-box",
            background: palette.common.white,
          },
          fieldset: {
            top: "0",
          },
          legend: {
            display: "none",
          },
        }}
        onChange={(e) => {
          onUpdate({
            ...material,
            href: e.currentTarget.value,
          });
        }}
      />
      <ButtonDefault color="error" onClick={onDelete}>
        <DeleteOutlinedIcon />
        Delete link
      </ButtonDefault>
    </Box>
  );
};
export default ProjectOverviewBackgroundMaterialForm;
