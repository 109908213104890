import { useState } from "react";
import { Box, Grid } from "@mui/material";

import { useParams, useNavigate } from "react-router-dom";
import { SimpleLoader } from "../../../common/Loader/Loader";
import { SchemaInformationEdit } from "./SchemaInformationEdit";

export interface SchemaDetailsOverviewProps {
  isEditMode: boolean;
  data: any | undefined;
  loading: boolean;
}

export const SchemaDetailsOverview = ({
  isEditMode,
  data,
  loading,
}: SchemaDetailsOverviewProps) => {
  const [edit, setEdit] = useState(false);
  const { projectId, workspaceId, schemaId } = useParams();

  const handleCloseEdit = () => {
    setEdit(false);
  };

  const handleOpenEdit = () => {
    setEdit(true);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <SimpleLoader />
      </Box>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <Grid container spacing={8}>
      <Grid item xs>
        <SchemaInformationEdit
          isEditMode={edit}
          data={data}
          onSave={() => {
            handleCloseEdit();
            //  refetch();
          }}
          onEdit={handleOpenEdit}
          onCancel={handleCloseEdit}
        />
      </Grid>
    </Grid>
  );
};
