import { styled, Box, Typography, Divider, Button, Avatar, Select, Grid, Tabs, Tab } from "@mui/material";
import { brighthiveTheme } from "../theme/theme";
import { Label } from "../common/Label/Label";
import { CorporateFare } from "@mui/icons-material";
import { ColoredAvatar } from "../common/ColoredAvatar/ColoredAvatar";
import { BasicCard } from "../common/BasicCard/BasicCard";
import { BasicPage } from "../common/BasicPage/BasicPage";

export const ActionBarAssetDetailWrapper = styled(Box)`
  flex-grow: 0;
  border: solid ${brighthiveTheme.palette.action.disabledBackground};
  border-width: 1px 1px 0;
  border-radius: .25rem .25rem 0 0;
`;

export const ActionBarAssetDetailSearch = styled(Box)`
  padding: 4px 16px;
`;

export const ButtonDefault = styled(Button)`
  color: ${brighthiveTheme.palette.gray.dark};
  border-radius: 3px;
  border: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1px;

  &.ml-auto{
    margin-left: auto;
  }

  &.small {
    width: 36px;
    height: 36px;
    padding: 0;
  }

  &.icn-large {
    svg {
      width: 26px;
      height: 26px;
    }
  }

  &.btn-icn{
    border-radius: 100%;
    background-color: ${brighthiveTheme.palette.primary.active};
    border-color: ${brighthiveTheme.palette.primary.dark};
    color: ${brighthiveTheme.palette.primary.dark};

    &:hover{
      background-color: ${brighthiveTheme.palette.primary.main};
      border-color: ${brighthiveTheme.palette.primary.main};
      color: ${brighthiveTheme.palette.common.white};
    }

    > .MuiButton-startIcon{
      margin-right: 0;
    }
  }

  &.active,
  &:hover {
    border-color: ${brighthiveTheme.palette.primary.dark};
    background-color: ${brighthiveTheme.palette.primary.active};
    color: ${brighthiveTheme.palette.primary.dark};
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const ButtonPrimary = styled(Button)`
  background-color: ${brighthiveTheme.palette.primary.main};
  color: ${brighthiveTheme.palette.primary.darkest};
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: none;
  min-width: 1px;

  &:hover {
    background-color: ${brighthiveTheme.palette.primary.dark};
    color: ${brighthiveTheme.palette.common.white};
    border-color: transparent;
    box-shadow: none;
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const ActionsCellRendererWrapper = styled(Box)`
`;

export const ActionsCellModalBody = styled(Box)`
  width: 35rem;
  padding: 24px 24px 16px 24px;
  box-sizing: border-box;
`;

export const ActionsCellModalHeading = styled(Typography)`
  margin-bottom: 32px;
`;

export const ActionsCellModalFieldText = styled(Typography)`
  margin-bottom: 24px;
  color: ${brighthiveTheme.palette.gray.dark};
`;

export const RadioGroupWrapper = styled(Box)`
  margin-bottom: 44px;
`;

export const ModalBtnsWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  gap: 12px;

  .flex-box &{
    margin-top: -3px;
    margin-bottom: -3px;
  }
`;

export const AssetDetailGridWrapper = styled(Box)`
  background-color: ${brighthiveTheme.palette.common.white};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const AssetManagersFieldsEditWrapper = styled(Box)`
`;

export const FieldWrapper = styled(Box)`
  display: flex;
  padding-bottom: 1.5rem;
  align-items: flex-start;
  max-width: 58rem;
`;

export const FieldLabel = styled(Label)`
  width: 15.5rem;
  flex-shrink: 0;
`;

export const FieldWrapperInnerBox = styled(Box)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const FieldWrapperEditBox = styled(Box)`
  align-items: center;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const FieldWrapperAvatar = styled(Avatar)`
  color: ${brighthiveTheme.palette.common.black};
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 8px;
  font-weight: 700;
  font-size: .5rem;
`;

export const NewAssetsBoxWrapper = styled(Box)`
  background-color: ${brighthiveTheme.palette.background.default};
  border-radius: .25rem;
  padding: 16px;
`;

export const NewAssetsBoxtext = styled(Typography)`
  font-weight: 500;
  margin-bottom: 0.375rem;
`;

export const FieldSelectBox = styled(Box)`
`;

export const FieldSelect = styled(Select)`
  margin-bottom: 8px;
`;

export const TextParagraph = styled(Typography)`

  &.mt{
    margin-top: 8px;
  }

  &.dark{
    color: ${brighthiveTheme.palette.gray.dark};
  }
`;

export const AssetManagersFieldsReadOnlyWrapper = styled(Box)`
`;

export const CorporateFareWrapper = styled(CorporateFare)`
  margin-right: 8px;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  padding: 5px;
  margin-right: 8px;
  background-color: ${brighthiveTheme.palette.primary.active};
  border: 1px solid ${brighthiveTheme.palette.primary.dark};
  color: ${brighthiveTheme.palette.primary.dark};
`;

export const FieldAlignBox = styled(Box)`
  display: flex;
  align-items: center;
`;

export const ColoredAvatarWrapper = styled(ColoredAvatar)`
  margin-right: 8px;
  background-color: ${brighthiveTheme.palette.primary.active};
  border: 1px solid ${brighthiveTheme.palette.primary.dark};
  color: ${brighthiveTheme.palette.primary.dark};
`;

export const AdditionalDetailsWrapper = styled(BasicCard)`
`;

export const AdditionalTopBox = styled(Box)`
  padding: 1rem 1.5rem;

  &.flex-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.p-same{
    padding: 1.5rem;
  }

  > .btn-icn{
    margin-top: -7px;
    margin-bottom: -7px;
  }
`;

export const AdditionalRow = styled(Box)`
  display: flex;
  justify-content: space-between;

  &.mb-4{
    margin-bottom: 16px;
  }
`;

export const PageDivider = styled(Divider)`
  margin-top: 24px;
  margin-bottom: 24px;

  &.mt-0,
  &.my-0{
    margin-top: 0;
  }

  &.my-0{
    margin-bottom: 0;
  }
`;

export const LoaderBox = styled(Box)`
  display: flex;
  justify-content: center;
`;

export const PageGrid = styled(Grid)`
`;

export const FieldTagsBox = styled(Box)`
`;

export const FieldTags = styled(Box)`
  display: flex;
`;

export const FieldTagsText = styled(Typography)`
  colo: ${brighthiveTheme.palette.error.light};
  margin-top: 12px;

  &.mb-3{
    margin-top: 0;
    margin-bottom: 12px;
  }
`;

export const TabsWrapper = styled(Tabs)`
  margin-bottom: 32px;

  .MuiTabs-indicator{
    background-color: ${brighthiveTheme.palette.primary.dark};
  }
`;

export const TabButton = styled(Tab)`
  font-size: 16px;
  line-height: 1.42;
  padding: 12px 24px !important;
  min-width: 1px;
  color: ${brighthiveTheme.palette.gray.dark};
  background-color: transparent;
  border-radius: 4px 4px 0 0;

  &.Mui-selected,
  &:hover{
    background-color: transparent !important;
    color: ${brighthiveTheme.palette.primary.dark};
  }
`;

export const TextWithTagBox = styled(Box)`
  
 > .MuiChip-root{
    margin-right: 8px;
  }
`;

export const BasicPageWrapper = styled(BasicPage)`

  .right-elements{
    align-self: flex-end;
  }
`;