import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const CreatedDataProductIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.8334 1.6665C17.2141 1.6665 18.3334 2.78579 18.3334 4.1665C18.3334 5.25474 17.6381 6.18057 16.6675 6.52398V13.4757C17.6381 13.8191 18.3334 14.7449 18.3334 15.8332C18.3334 17.2139 17.2141 18.3332 15.8334 18.3332C14.7452 18.3332 13.8193 17.6379 13.4759 16.6673H6.52423C6.18082 17.6379 5.25498 18.3332 4.16675 18.3332C2.78604 18.3332 1.66675 17.2139 1.66675 15.8332C1.66675 14.7446 2.36246 13.8186 3.33349 13.4754V6.52428C2.36246 6.1811 1.66675 5.25505 1.66675 4.1665C1.66675 2.78579 2.78604 1.6665 4.16675 1.6665C5.25529 1.6665 6.18134 2.36221 6.52452 3.33324H13.4756C13.8188 2.36221 14.7449 1.6665 15.8334 1.6665ZM4.16675 14.9998C3.70651 14.9998 3.33341 15.3729 3.33341 15.8332C3.33341 16.2934 3.70651 16.6665 4.16675 16.6665C4.62699 16.6665 5.00008 16.2934 5.00008 15.8332C5.00008 15.3729 4.62699 14.9998 4.16675 14.9998ZM15.8334 14.9998C15.3732 14.9998 15.0001 15.3729 15.0001 15.8332C15.0001 16.2934 15.3732 16.6665 15.8334 16.6665C16.2937 16.6665 16.6667 16.2934 16.6667 15.8332C16.6667 15.3729 16.2937 14.9998 15.8334 14.9998ZM13.4759 5.00059H6.52423C6.27311 5.71033 5.71057 6.27287 5.00083 6.52398V13.4757C5.71085 13.7269 6.27355 14.2898 6.52452 14.9999H13.4756C13.7267 14.2895 14.2898 13.7265 15.0002 13.4754V6.52428C14.29 6.27331 13.7271 5.7106 13.4759 5.00059ZM4.16675 3.33317C3.70651 3.33317 3.33341 3.70627 3.33341 4.1665C3.33341 4.62674 3.70651 4.99984 4.16675 4.99984C4.62699 4.99984 5.00008 4.62674 5.00008 4.1665C5.00008 3.70627 4.62699 3.33317 4.16675 3.33317ZM15.8334 3.33317C15.3732 3.33317 15.0001 3.70627 15.0001 4.1665C15.0001 4.62674 15.3732 4.99984 15.8334 4.99984C16.2937 4.99984 16.6667 4.62674 16.6667 4.1665C16.6667 3.70627 16.2937 3.33317 15.8334 3.33317Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
