export const ASSET_DETAIL_ISSUES_DATA = [
  {
    title: "Connection issue",
    description:
      "Brighthive does not have, or has lost its connection to the data. Please check the API key or password.",
  },
  {
    title: "Validation issue",
    description:
      "The data asset is missing [X] field, which is required for its use. To fix this, update the data asset and add it back into the workspace.",
  },
];

export const ASSET_DETAIL_MAIN_DATA = {
  dataAssetName: "dataAssetName-0193834503282",
  description:
    "A description of the data asset goes here. It should be short and easy to read for humans. Character limit should be decided so that it is around 3 lines of text within the card at the smallest desktop breakpoint.",
  tags: ["programs", "competencies", "skills", "corses"],

  assetOwner: "City of Pawnee",
  assetManager: "Ben Wyatt",
  assetStatus: {
    status: "Shared",
    description:
      "Workspace members can add this data asset to any project within the workspace.",
  },

  dataSensitivity: {
    value: "Tier2",
    description: "Short description of tier 2 sensitivity goes here",
    linkTitle: "Workspace Tier 2 policies",
    linkUrl: "#",
  },
  pLLTypes: [
    {
      value: "HIPPA",
      linkTitle: "Workspace HIPAA policies",
      linkUrl: "#",
    },
    {
      value: "FERPA",
      linkTitle: "Workspace FERPA policies",
      linkUrl: "#",
    },
  ],
  assetSpecificPolicies: [
    {
      name: "Name of data asset policy",
      description:
        "Policy description text goes here. It can be added by the Asset Manager at any time. This information stays with the asset as it is pulled into projects.",
      link: "http://documentation.com/1",
    },
    {
      name: "Name of data asset policy",
      description:
        "Policy description text goes here. It can be added by the Asset Manager at any time. This information stays with the asset as it is pulled into projects.",
      link: "http://documentation.com/2",
    },
    {
      name: "Name of data asset policy",
      description:
        "Policy description text goes here. It can be added by the Asset Manager at any time. This information stays with the asset as it is pulled into projects.",
      link: "http://documentation.com/3",
    },
  ],

  source: "Source information (240 char)",
  connection: {
    type: "API",
    name: "API name is here",
  },
  dataRefresh: "Semester (custom)",
  storageService: "Brighthive",
  assetSize: "30 MB",
  tableProperties: [
    {
      property: "recordCount",
      value: "10,025",
    },
    {
      property: "avgRecordSize",
      value: "188",
    },
    {
      property: "compressionType",
      value: "none",
    },
    {
      property: "columnsOrdered",
      value: "true",
    },
    {
      property: "areColumnsQuated",
      value: "false",
    },
    {
      property: "delimiter",
      value: "|",
    },
  ],
};

export const MONITORED_POLICY_DATA = [
  {
    category: "Privacy",
    policies: [
      {
        title: "Pll stripping1",
        type: "Monitored policy",
        description:
          "All fields marked as containing PII must be stripped from any final data products prior to connecting them to a destination.",
        dateTime: "08/14/2021 at 11:55 pm",
        author: "David",
        tags: ["HIPPA", "FERRA", "PROJECTS"],
      },
      {
        title: "Pll stripping2",
        type: "Monitored policy",
        description:
          "All fields marked as containing PII must be stripped from any final data products prior to connecting them to a destination.",
        dateTime: "08/14/2021 at 11:55 pm",
        author: "David",
        tags: ["HIPPA", "ONE"],
      },
      {
        title: "Pll keyword stripping3",
        type: "Monitored policy",
        description:
          "All fields marked as containing PII must be stripped from any final data products prior to connecting them to a destination.",
        dateTime: "08/14/2021 at 11:55 pm",
        author: "David",
        tags: ["HIPPA"],
      },
    ],
  },
  {
    category: "Use",
    policies: [
      {
        title: "Pll stripping",
        type: "Monitored policy",
        description:
          "All fields marked as containing PII must be stripped from any final data products prior to connecting them to a destination.",
        dateTime: "08/14/2021 at 11:55 pm",
        author: "David",
        tags: ["HIPPA", "TWO", "PROJECTS"],
      },
      {
        title: "Pll stripping",
        type: "Monitored policy",
        description:
          "All fields marked as containing PII must be stripped from any final data products prior to connecting them to a destination.",
        dateTime: "08/14/2021 at 11:55 pm",
        author: "David",
        tags: ["TWO"],
      },
    ],
  },
  {
    category: "Transparency",
    policies: [
      {
        title: "Pll stripping",
        type: "Monitored policy",
        description:
          "All fields marked as containing PII must be stripped from any final data products prior to connecting them to a destination.",
        dateTime: "08/14/2021 at 11:55 pm",
        author: "David",
        tags: ["HIPPA"],
      },
    ],
  },
  {
    category: "Quality",
    policies: [],
  },
  {
    category: "Access",
    policies: [
      {
        title: "Pll stripping",
        type: "Monitored policy",
        description:
          "All fields marked as containing PII must be stripped from any final data products prior to connecting them to a destination.",
        dateTime: "08/14/2021 at 11:55 pm",
        author: "David",
        tags: ["HIPPA", "FERRA", "PROJECTS"],
      },
      {
        title: "Pll stripping",
        type: "Monitored policy",
        description:
          "All fields marked as containing PII must be stripped from any final data products prior to connecting them to a destination.",
        dateTime: "08/14/2021 at 11:55 pm",
        author: "David",
        tags: ["HIPPA", "KK"],
      },
      {
        title: "Pll stripping",
        type: "Monitored policy",
        description:
          "All fields marked as containing PII must be stripped from any final data products prior to connecting them to a destination.",
        dateTime: "08/14/2021 at 11:55 pm",
        author: "David",
        tags: ["HIPPA", "Access"],
      },
    ],
  },
  {
    category: "Security",
    policies: [
      {
        title: "Pll stripping",
        type: "Monitored policy",
        description:
          "All fields marked as containing PII must be stripped from any final data products prior to connecting them to a destination.",
        dateTime: "08/14/2021 at 11:55 pm",
        author: "David",
        tags: ["HIPPA", "FERRA", "PROJECTS"],
      },
      {
        title: "Pll stripping",
        type: "Monitored policy",
        description:
          "All fields marked as containing PII must be stripped from any final data products prior to connecting them to a destination.",
        dateTime: "08/14/2021 at 11:55 pm",
        author: "David",
        tags: ["HIPPA", "FERRA", "PROJECTS"],
      },
      {
        title: "Pll stripping",
        type: "Monitored policy",
        description:
          "All fields marked as containing PII must be stripped from any final data products prior to connecting them to a destination.",
        dateTime: "08/14/2021 at 11:55 pm",
        author: "David",
        tags: ["HIPPA", "FERRA", "PROJECTS"],
      },
    ],
  },
];

export const CUSTOM_POLICY_DATA = [
  {
    category: "Privacy",
    policies: [
      {
        title: "Pll stripping",
        type: "Custom policy",
        description:
          "All fields marked as containing PII must be stripped from any final data products prior to connecting them to a destination.",
        dateTime: "08/14/2021 at 11:55 pm",
        author: "David",
        referenceDocuments: [
          {
            name: "Policy Doc1",
            href: "https://documentlink1",
          },
          {
            name: "Policy Doc2",
            href: "https://documentlink2",
          },
        ],
      },
    ],
  },
  {
    category: "Use",
    policies: [
      {
        title: "Pll stripping",
        type: "Monitored policy",
        description:
          "All fields marked as containing PII must be stripped from any final data products prior to connecting them to a destination.",
        dateTime: "08/14/2021 at 11:55 pm",
        author: "David",
      },
      {
        title: "Pll stripping",
        type: "Monitored policy",
        description:
          "All fields marked as containing PII must be stripped from any final data products prior to connecting them to a destination.",
        dateTime: "08/14/2021 at 11:55 pm",
        author: "David",
      },
    ],
  },
  {
    category: "Transparency",
    policies: [
      {
        title: "Pll stripping",
        type: "Monitored policy",
        description:
          "All fields marked as containing PII must be stripped from any final data products prior to connecting them to a destination.",
        dateTime: "08/14/2021 at 11:55 pm",
        author: "David",
      },
    ],
  },
];

export const WORKSPACE_DETAIL = {
  name: "Post Secondary Outcomes",
  description:
    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore.    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore. ",
  goals: [
    {
      name: "Name of Goal",
      description:
        "Goal description text goes here. It can be added by the Asset Manager at any time. This information stays with the asset as it is pulled into projects.",
      link: "http://documentation.com",
    },
    {
      name: "Name of Goal",
      description:
        "Goal description text goes here. It can be added by the Asset Manager at any time. This information stays with the asset as it is pulled into projects.",
      link: "http://documentation.com",
    },
    {
      name: "Name of Goal",
      description:
        "Goal description text goes here. It can be added by the Asset Manager at any time. This information stays with the asset as it is pulled into projects.",
      link: "http://documentation.com",
    },
  ],
  legalDocs: [
    {
      name: "Legal Doc Name 1",
      href: "http://documentation.com",
    },
    {
      name: "Legal Docment Name - Updated 10/23",
      href: "http://documentation.com",
    },
  ],
  referencelDocs: [
    {
      name: "Referemnce Doc Name 1",
      href: "http://documentation.com",
    },
    {
      name: "Referemnce Doc Name 2",
      href: "http://documentation.com",
    },
    {
      name: "Referemnce Doc Name - Updated 10/23",
      href: "http://documentation.com",
    },
  ],
};

export const METADATA_SOURCES = [
  {
    id: "1",
    name: "Organization Managed Data Stack",
    cloudProvider: "AWS",
    service: "Glue Data Catalog",
    sync: "Auto",
    location: "On-platform",
    access: "Full access",
    permission: "Read",
    status: "Connected",
    project: "Cactus Community College",
    owner: "Citrus University",
  },
];

export const ENGINES = [
  {
    id: "1",
    name: "source",
    service: "Spark",
    jobs: 10,
    owner: "Citrus University",
    languages: ["participants", "enrollment"],
    status: "Connected",
    project: "Cactus Community College",
  },
  {
    id: "2",
    name: "source",
    service: "Airflow",
    jobs: 24,
    owner: "Citrus University",
    languages: ["participants", "enrollment"],
    status: "Disconnected",
    project: "Cactus Community College",
  },
  {
    id: "3",
    name: "source",
    service: "DBT",
    jobs: 22,
    owner: "Citrus University",
    languages: ["participants"],
    status: "Disconnected",
    project: "Citrus",
  },
];
