/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import { Box, SxProps, useTheme } from "@mui/material";

import { Typography } from "../Typography/Typography";
import { BrighthiveIcon } from "../Icons/BrighthiveIcon";

interface LoadingProps {
  text?: string;
  className?: string;
  customTextClass?: string;
}

export const SimpleLoader = ({
  text = "Loading...",
  className,
  customTextClass,
}: LoadingProps) => {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        width: "8.75rem",
        ".custom-loader": {
          borderRadius: "100%",
          background: palette.primary.light,
          width: "33px !important",
          height: "33px !important",
          "&.full-height": {
            width: "4.125rem !important",
            height: "4.125rem !important",
          },
          "&:before": {
            position: "absolute",
            left: "3px",
            right: "3px",
            bottom: "3px",
            top: "3px",
            background: palette.common.white,
            content: '""',
            borderRadius: "100%",
          },
          "&:after": {
            position: "absolute",
            left: "3px",
            right: "3px",
            bottom: "3px",
            top: "3px",
            background: palette.settings.lighter,
            content: '""',
            borderRadius: "100%",
            zIndex: "99",
          },
        },
        ".custom-text": {
          marginTop: "15px !important",
          marginBottom: "5px",
          fontSize: "14px",
          lineHeight: "20px",
          fontWeight: "500",
          color: palette.gray.darkest,
        },
      }}
      display={"flex"}
      flexDirection="column"
      alignItems={"center"}
    >
      <CircularProgress
        className={className}
        size={"4.125rem"}
        sx={{ color: palette.primary.darkest }}
      />
      <Typography mt={"1.6rem"} variant="subtitle1" className={customTextClass}>
        {text}
      </Typography>
    </Box>
  );
};

export const BrandedLoader = () => {
  return (
    <Box
      sx={{ width: "8.75rem", my: 2 }}
      display={"flex"}
      flexDirection="column"
      alignItems={"center"}
    >
      <BrighthiveIcon
        sx={{
          width: "4.375rem",
          height: "initial",
        }}
      />
      <LinearProgress sx={{ width: "8.75rem", mt: "1.875rem" }} />
    </Box>
  );
};

export const BrandedAnimationLoader = ({ sx }: { sx: SxProps }) => {
  return (
    <Box>
      <BrighthiveIcon
        css={css`
          animation: spin 2s linear infinite;
          @keyframes spin {
            0% {
              transform: rotate(360deg);
            }
            100% {
              transform: rotate(0deg);
            }
          }
        `}
        sx={{
          width: "4.375rem",
          height: "initial",
          ...sx,
        }}
      />
    </Box>
  );
};
