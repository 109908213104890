import React, { useState } from "react";
import { Box, Divider, Popover, useTheme } from "@mui/material";
import { useMenu } from "../Menu/useMenu";
import { Button } from "../Button/Button";
import {
  DuplicateProjectDocument,
  GetProjectQuery,
  ProjectStatus,
  ResourceType,
} from "../../generated";
import { ApolloQueryResult, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { convertToTitleCase } from "../../helpers";
import { getSource } from "../../Sources/sourceIcons";
import { PlusIcon } from "../Icons/PlusIcon";
import { ButtonPrimary } from "./style";

export const AddResourceButton = ({ setResource }: any) => {
  const [menuOpen, anchorEl, openMenuFromAnchorElement, closeMenu] = useMenu();
  const { palette } = useTheme();

  return (
    <>
      <ButtonPrimary
        id="project-action-button"
        aria-controls={menuOpen ? "project-action-items" : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        color="primary"
        variant="contained"
        onClick={openMenuFromAnchorElement}
        sx={{
          borderRadius: "3px",
          fontWeight: "700",
        }}
      >
        <PlusIcon sx={{ fontSize: "16px", marginRight: "5px" }} /> Add Resource
      </ButtonPrimary>
      <Popover
        id="project-action-items"
        aria-labelledby="project-action-button"
        open={menuOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        onClose={closeMenu}
        sx={{
          ".MuiPaper-root": {
            background: palette.common.white,
            border: "0",
            boxShadow: `0 2px 2px ${palette.gray.main}, 0 3px 1px ${palette.action.disabledBackground}, 0 1px 5px ${palette.action.disabled}`,
            borderRadius: "4px",
            display: "flex",
            flexDirection: "column",
            padding: "0",
          },
          ".MuiButtonBase-root": {
            padding: "12px 19px",
            textAlign: "left",
            boxShadow: "none",
            border: "0 !important",
            justifyContent: "flex-start",
            color: palette.accent.contrastText,
            borderRadius: "0",

            "&:hover": {
              backgroundColor: palette.primary.active,
              color: palette.primary.dark,
            },
          },
        }}
      >
        <>
          {[
            // { label: "Create Folder", value: "Create Folder" },
            { label: "Create Folder", value: "Folder", isDisabled: false },
            { label: "File/Doc", value: "File", isDisabled: false },
            { label: "Image", value: "IMAGE", isDisabled: false },
            { label: "Video", value: "VIDEO", isDisabled: false },
            { label: "Link", value: "LINK", isDisabled: false },
          ].map(({ label, value, isDisabled }) => (
            <Button
              id="project-action-button"
              aria-controls={menuOpen ? "project-action-items" : undefined}
              aria-haspopup="true"
              aria-expanded={menuOpen ? "true" : undefined}
              color="grayAccent"
              variant="outlined"
              disabled={isDisabled}
              onClick={() => {
                closeMenu();
                setResource(value);
              }}
              sx={{
                display: "flex",
                columnGap: "13px",
                alignItems: "center",
                borderRadius: "4px",

                "&:hover": {
                  background: palette.primary.active,
                  color: palette.primary.dark,
                },

                "&.Mui-disabled": {
                  background: "transparent",
                  opacity: "0.7",
                },

                ".csb-dropdown-icn": {
                  width: "13px",
                  height: "13px",
                },
              }}
            >
              {convertToTitleCase(label)}
            </Button>
          ))}
        </>
      </Popover>
    </>
  );
};
