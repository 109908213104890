import React from "react";
import { Typography } from "../../common/Typography/Typography";
import { Box, useTheme } from "@mui/material";
import { Button } from "../../common/Button/Button";
import { UpDateRole, UpDateRoleContent } from "../style";

export interface UpdateToAdminDialogContentProps {
  onClickCancel: (...any: any[]) => any;
  onClickConfirm: (...any: any[]) => any;
}

export const UpdateToAdminDialogContent = ({
  onClickCancel,
  onClickConfirm,
}: UpdateToAdminDialogContentProps) => {
  const { palette } = useTheme();

  return (
    <UpDateRole p={6}>
      <Typography mb={4} variant="h5">
        Update Role
      </Typography>
      <Typography color={palette.gray.dark} mb={4} variant="body2">
        You are updating Member Name from Viewer to Admin giving them greater
        access within the workspace. Proceed?
      </Typography>
      <UpDateRoleContent mt={7}>
        <Button
          onClick={onClickCancel}
          color="grayAccent"
          variant="outlined"
          sx={{ mr: 3 }}
        >
          Cancel
        </Button>
        <Button onClick={onClickConfirm} color="primary" variant="contained">
          Confirm
        </Button>
      </UpDateRoleContent>
    </UpDateRole>
  );
};
