import { styled } from "@mui/material";
import { SquareIconButton, SquareIconButtonProps, } from "../SquareIconButton/SquareIconButton";

export type CircleIconButtonProps = SquareIconButtonProps;

export const CircleIconButton = styled(SquareIconButton)(({ size }) => ({
  borderRadius: "50%",
  ...(size === "small" && { fontSize: ".875rem" }),
  ...(size === "medium" && { fontSize: "1.125rem" }),
  ...(size === "large" && { fontSize: "1.3125rem" }),
}));
