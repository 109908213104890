import { Box, Drawer, Tab } from "@mui/material";
import { useState } from "react";
import { Tabs } from "../../common/Tabs/Tabs";
import { Typography } from "../../common/Typography/Typography";
import { StatusCell } from "../CellRenderer/StatusCell";
import { MetadataConfiguration } from "./MetadataConfiguration";
import { MetadataWorkspace } from "./MetadataWorkspace";

interface MetadataSourceDetailProps {
  open: boolean;
  onClose: () => void;
}

export const MetadataSourceDetail = ({
  open,
  onClose,
}: MetadataSourceDetailProps) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const name = "Organization Managed Data Stack";

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box width={400} p={8}>
        <Box>
          <Typography variant="overline">METADATA SOURCE</Typography>
          <Typography mt={1} mb={3} variant="h5">
            {name}
          </Typography>
          <StatusCell value="Connected" />
        </Box>

        <Box
          sx={{
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            fontWeight: "bold",
          }}
          mt={4}
          mb={4}
        >
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            textColor="inherit"
          >
            <Tab label="Configuration" />
            {/* <Tab label="Tab 2" /> */}
          </Tabs>
        </Box>

        <Box>
          {value === 0 && <MetadataConfiguration />}
          {/* {value === 1 && <MetadataWorkspace />} */}
        </Box>
      </Box>
    </Drawer>
  );
};
