import { SidenavButtonLink } from "../Sidenav/SidenavButtonLink";
import { CollapsibleButton } from "../SideBarV2/CollapsibleButton";
import { LabelText, LinkButton } from "../Sidenav/style";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material";

// Helper function to recursively generate routes
export const genNav: any = (
  routes: any,
  pathname: string,
  workspaceId: string,
  role: string
) => {
  return Object.keys(routes).map((key, index) => {
    const { palette } = useTheme();
    const route = routes[key];

    // Check if there are nested routes
    if (
      route.routes &&
      Object.keys(route.routes).length > 0 &&
      (route?.access?.includes(role) ||
        route?.access?.length === 0 ||
        route?.access === undefined)
    ) {
      return (
        <>
          {route.nav ? (
            <CollapsibleButton
              label={route.name}
              icon={route.icon}
              level={route.level}
            >
              {genNav(route.routes, pathname, workspaceId, role)}
            </CollapsibleButton>
          ) : (
            genNav(route.routes, pathname, workspaceId, role)
          )}
        </>
      );
    }

    if (
      route?.access?.includes(role) ||
      route?.access?.length === 0 ||
      route?.access === undefined
    ) {
      return (
        route.nav && (
          <>
            {route.external ? (
              <a
                href={route.path}
                target="_blank"
                className="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways"
                rel="noreferrer"
              >
                <LinkButton startIcon={route.icon} variant="text">
                  <LabelText variant="body3">
                    <Tooltip
                      placement="bottom"
                      title={route.name}
                      componentsProps={{
                        tooltip: {
                          sx: { backgroundColor: palette.common.black },
                        },
                        arrow: { sx: { color: palette.common.black } },
                      }}
                      arrow
                    >
                      <span className="sidebutton-text cs-hidden-text">
                        {route.name}
                      </span>
                    </Tooltip>
                  </LabelText>
                </LinkButton>
              </a>
            ) : (
              <SidenavButtonLink
                active={pathname.includes(route.key)}
                to={route.path.replace(":workspaceId", workspaceId)}
                className={pathname.includes(route.key) ? "active" : ""}
                icon={route.icon}
                label={route.name}
              />
            )}
          </>
        )
      );
    } else return null;
  });

  // Base case: no nested routes
};
