import { Dialog } from "@mui/material";
import { useState } from "react";
import { CreateTerm } from "./CreateTerm";
import { ListTerms } from "./ListTerms";

export const LinkTermModal = (params: {
  resourceId: string;
  onClose?: () => void;
}) => {
  const [creating, setCreating] = useState(false);
  return (
    <Dialog
      open={true}
      maxWidth="md"
      sx={{ " + #filter-owner-form": { zIndex: 999999 } }}
    >
      {creating ? (
        <CreateTerm onClose={() => setCreating(false)} />
      ) : (
        <ListTerms {...params} onCreateTerm={() => setCreating(true)} />
      )}
    </Dialog>
  );
};
