import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { AppContext } from "../App";
import { SideNavigationPage } from "../common/SideNavigationPage/SideNavigationPage";
import { GetCurrentUserInfoDocument, GetProjectDocument } from "../generated";

import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { SessionNav } from "./SessionNav";
import { ChatBot } from "../BrightBot/ChatBox/ChatBot";
import { Issue } from "./Issue/Issue";
import { BrightBotSmileIcon } from "../common/Icons/BrightBotSmileIcon";
import chatApi from "../api/chatApi";

export const Session = ({
  title,
  children,
}: {
  title?: string;
  children?: any;
}) => {
  const { projectId, workspaceId, threadId } = useParams();
  const [threads, setThreads] = useState();
  const { palette } = useTheme();
  const [threadData, setThreadData] = useState<any>();
  const [loading, setLoading] = useState(threadId && true);
  const [refetch, setRefetch] = useState(false);

  const { data: currentUser } = useQuery(GetCurrentUserInfoDocument);

  const { data } = useQuery(GetProjectDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
      projectFilter: {
        projectId: projectId || "",
      },
    },
  });

  const workspace = data?.workspace;

  useEffect(() => {
    if ((currentUser?.currentUser.id, workspaceId)) {
      chatApi
        .post("/threads/search", {
          metadata: {
            user_id: currentUser?.currentUser.id,
            workspace_id: workspaceId,
          },
        })
        .then((res) => setThreads(res.data));
    }
  }, [currentUser?.currentUser.id, workspaceId, refetch]);

  return (
    <AppContext.Consumer>
      {({ showSidenav, setShowSidenav }) => (
        <>
          <SideNavigationPage
            innerSideBar
            className={"full-width bg-white"}
            leftHeaderElements={
              <Box
                sx={{
                  padding: "0 1.5rem",
                  display: "flex",
                  alignItems: "center",

                  svg: {
                    width: "40px",
                    height: "40px",
                    mr: 2,
                  },
                }}
              >
                <BrightBotSmileIcon />
                <Typography variant="h5">Brightbot</Typography>
              </Box>
            }
            rightContainerProps={{
              flex: "1",
            }}
            rightHeaderElements={
              <Box
                sx={{
                  display: "none",
                  flex: "1",
                  maxWidth: "80%",
                  border: `1px solid ${palette.gray.contrastText}`,
                  borderRadius: "8px",
                  padding: "10px",
                  textAlign: "center",
                  margin: "0 10px",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body2">
                  Help me updated the Student360 data model in our warehouse to
                  include Jenzebar data
                </Typography>
              </Box>
            }
            verticalNav={
              <SessionNav
                show={showSidenav}
                workspaceName={workspace?.name || ""}
                setShowSidenav={setShowSidenav}
                threads={threads}
                setRefetch={setRefetch}
              />
            }
            content={
              <Box
                sx={{
                  display: "flex",
                  flex: "1",
                  height: "100%",
                  alignItems: "flex-end",
                  marginTop: "-1rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flex: "1",
                    height: "100%",
                    alignItems: "flex-end",
                    padding: "1.5rem",
                    minHeight: "300px",
                  }}
                >
                  {threads && (
                  <ChatBot
                      showSuggestion={false}
                      setRefetch={setRefetch}
                    />
                  )}
                </Box>
                {/* <Issue /> */}
              </Box>
            }
          />
        </>
      )}
    </AppContext.Consumer>
  );
};
