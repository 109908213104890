import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const DataFlowIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 14.9998C4.53976 14.9998 4.16667 15.3729 4.16667 15.8332C4.16667 16.2934 4.53976 16.6665 5 16.6665C5.46024 16.6665 5.83333 16.2934 5.83333 15.8332C5.83333 15.3729 5.46024 14.9998 5 14.9998ZM2.5 15.8332C2.5 14.4525 3.61929 13.3332 5 13.3332C6.38071 13.3332 7.5 14.4525 7.5 15.8332C7.5 17.2139 6.38071 18.3332 5 18.3332C3.61929 18.3332 2.5 17.2139 2.5 15.8332Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 3.33317C14.5398 3.33317 14.1667 3.70627 14.1667 4.1665C14.1667 4.62674 14.5398 4.99984 15 4.99984C15.4602 4.99984 15.8333 4.62674 15.8333 4.1665C15.8333 3.70627 15.4602 3.33317 15 3.33317ZM12.5 4.1665C12.5 2.78579 13.6193 1.6665 15 1.6665C16.3807 1.6665 17.5 2.78579 17.5 4.1665C17.5 5.54722 16.3807 6.6665 15 6.6665C13.6193 6.6665 12.5 5.54722 12.5 4.1665Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.08333 4.99984C6.5308 4.99984 6.0009 5.21933 5.61019 5.61003C5.21949 6.00073 5 6.53064 5 7.08317C5 7.6357 5.21949 8.16561 5.61019 8.55631C6.0009 8.94701 6.5308 9.1665 7.08333 9.1665H13.75C14.7446 9.1665 15.6984 9.56159 16.4016 10.2649C17.1049 10.9681 17.5 11.9219 17.5 12.9165C17.5 13.9111 17.1049 14.8649 16.4016 15.5682C15.6984 16.2714 14.7446 16.6665 13.75 16.6665H10C9.53976 16.6665 9.16667 16.2934 9.16667 15.8332C9.16667 15.3729 9.53976 14.9998 10 14.9998H13.75C14.3025 14.9998 14.8324 14.7803 15.2231 14.3896C15.6138 13.9989 15.8333 13.469 15.8333 12.9165C15.8333 12.364 15.6138 11.8341 15.2231 11.4434C14.8324 11.0527 14.3025 10.8332 13.75 10.8332H7.08333C6.08877 10.8332 5.13494 10.4381 4.43168 9.73482C3.72842 9.03156 3.33333 8.07773 3.33333 7.08317C3.33333 6.08861 3.72842 5.13478 4.43168 4.43152C5.13494 3.72826 6.08877 3.33317 7.08333 3.33317H10C10.4602 3.33317 10.8333 3.70627 10.8333 4.1665C10.8333 4.62674 10.4602 4.99984 10 4.99984H7.08333Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
