import { Box, Typography, useTheme } from "@mui/material";
import { AGGridWrapper } from "../../common/AGGridWrapper/AGGridWrapper";
import { dateHelper } from "../../helpers";
import { ICellRendererParams } from "ag-grid-community";
import { ActionCell } from "./ActionCell";
import Resource from "../../contexts/resource";
import {
  ButtonLink,
  StyledGridContainer,
  StyledPathLinkContainer,
} from "./style";
import { DisplayDrawerHandlerType } from "../../common/Drawer";
import PathLink from "../Resources/PathLink";
import CommonTooltip from "../../common/ToolTips/CommonTooltip";
import { EllipsisBox } from "../../common/StyledComp/EllipsisBox";

type Props = {
  emailAddress: string;
  username: string;
  rowData: any;
  displayDrawerHandler: DisplayDrawerHandlerType;
};

export const GridView = ({
  rowData,
  username,
  displayDrawerHandler,
}: Props) => {
  const {
    editMode: { setEditing },
  } = Resource.useContext();

  const { palette } = useTheme();
  const columns = [
    {
      field: "name",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <ButtonLink
            onClick={() => displayDrawerHandler("view", params.data?.id)}
          >
            {params.value}
          </ButtonLink>
        );
      },
      minWidth: 300,
    },
    {
      field: "path",
      headerName: "Path",
      cellRenderer: (params: any) => {
        const item = params.data;
        return (
          <StyledPathLinkContainer display="flex" alignItems="center">
            <PathLink data={item} className="name-link">
              <Typography
                fontWeight="bold"
                ml={3}
                variant="body3"
                className="name"
              >
                {item.path || item.name}
              </Typography>
            </PathLink>
          </StyledPathLinkContainer>
        );
      },
    },
    {
      field: "description",
      minWidth: 400,
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <CommonTooltip
            title={params.data?.description || ""}
            placement="bottom-start"
          >
            <EllipsisBox>{params.data?.description || ""}</EllipsisBox>
          </CommonTooltip>
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
      cellRenderer: (params: ICellRendererParams) => {
        return params.data.isFolder ? "Folder" : params.data.type;
      },
    },
    {
      field: "added_by",
      headerName: "Added By",
      cellRenderer: (params: any) => {
        return username;
      },
    },
    {
      field: "createdAt",
      headerName: "Upload Date",
      cellRenderer: (params: any) => {
        return dateHelper(params.data.createdAt);
      },
    },
    {
      sortable: false,
      field: "actions",
      type: "rightAligned",
      headerName: "Actions",
      width: 50,
      maxWidth: 85,
      resizable: false,
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <ActionCell
            name={params?.data?.name ? params?.data?.name : params?.data?.path}
            id={params.data.id}
            onEdit={() => setEditing(params.data.id)}
          />
        );
      },
      suppressMenu: true,
    },
  ];

  return (
    <StyledGridContainer className="ag-theme-material">
      <AGGridWrapper
        // loading={loading}
        rowData={rowData}
        columnDefs={columns as any}
        defaultColDef={{
          sortable: true,
          flex: 1,
          minWidth: 200,
        }}
        getRowHeight={(params) => (params.node.group ? 32 : 62)}
        groupDefaultExpanded={1}
        domLayout="autoHeight"
      />
    </StyledGridContainer>
  );
};
