import { useNavigate, useParams } from "react-router-dom";

type Props = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> & { data: any };

const PathLink = ({ children, data, ...props }: Props) => {
  const { workspaceId } = useParams();
  const navigate = useNavigate();

  const handleOnClick = () => {
    if (data.isFolder || data?.path?.includes("axios")) {
      navigate(`/workspace/${workspaceId}/the-hive/resources/${data?.id}`);
    } else {
      window?.open(data.path, "_blank")?.focus();
    }
  };

  return (
    <a onClick={handleOnClick} target="_blank" rel="noreferrer" {...props}>
      {children}
    </a>
  );
};

export default PathLink;
