import React, { createContext, useState } from "react";
import { LicenseManager } from "ag-grid-enterprise";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { ProtectedRoute } from "./Auth/ProtectedRoute";
import { ProtectedNav } from "./Auth/ProtectedNav";
import { useGetUserRole } from "./hooks/useGetUserRole";
import { useWorkspaceId } from "./hooks/useWorkspace";
import WorkspaceSwitcherPage from "./WorkspaceSwitcher/WorkspaceSwitcherPage";
import { AssetRoutes, LoginRoutes } from "./routes";
import { generateRoutes } from "./routes/genRoutes";
import { PolicyAgreement } from "./Auth/RegisterFlow/PolicyAgreement/PolicyAgreement";

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY || "");

interface AppContextType {
  showSidenav: boolean;
  setShowSidenav: (...args: any[]) => any;
}

export const AppContext = createContext<AppContextType>({
  showSidenav: true,
  setShowSidenav: () => {},
});

const setting = {
  color: {
    theme: "#000",
  },
  launcher: {
    chatLabel: {
      "en-US": "Need Help",
    },
  },
  contactForm: {
    fields: [
      { id: "description", prefill: { "*": "My pre-filled description" } },
    ],
  },
};

export const App = () => {
  const { workspaceId } = useWorkspaceId();
  const [showSidenav, setShowSidenav] = useState(true);
  const { accessible, role } = useGetUserRole(workspaceId);

  if (!role && workspaceId) return null;

  return (
    <AppContext.Provider value={{ showSidenav, setShowSidenav }}>
      <ToastContainer autoClose={3000} />
      <ProtectedNav>
        <Routes>
          <Route
            path="/workspace"
            element={
              <ProtectedRoute>
                <WorkspaceSwitcherPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/agree-policy"
            element={
              <ProtectedRoute>
                <PolicyAgreement />
              </ProtectedRoute>
            }
          />
          {generateRoutes(AssetRoutes.Workspace.routes, true)}
          {generateRoutes(LoginRoutes)}
          <Route path="*" element={<Navigate to={"/workspace"} />} />
        </Routes>
      </ProtectedNav>
    </AppContext.Provider>
  );
};
