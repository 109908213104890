import {
  FormControl,
  MenuItem,
  Typography,
  Select,
  useTheme,
  Modal,
  Box,
  useForkRef,
  TextField,
  Button,
  FormLabel,
} from "@mui/material";
// import UppyModal from "../../common/UppyModal/UppyModal";
import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";

import {
  AccessMode,
  CreateResourceDocument,
  Directory,
  GetResourcesDocument,
  PreSignedUrlDocument,
  ResourceType,
} from "../../generated";
import { Formik } from "formik";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { CloseIcon } from "stream-chat-react";
import { Description, SelectAll } from "@mui/icons-material";
import * as Yup from "yup";
import { CustomTextField } from "../../common/CustomTextField/CustomTextField";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { allowedResourceTypes, resourceType } from "../../helpers";
import { Tag } from "../../common/Tag/Tag";
import { Label } from "../../common/Label/Label";
import { ButtonDefault, ButtonPrimary } from "./style";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const resourceNames = {
  DOCUMENT: "Document",
  LINK: "Webpage",
  IMAGE: "Image",
  VIDEO: "Video",
};

export const UploadResource = ({
  setResource,
  resource,
  submitButtonText,
}: {
  setResource: Function;
  resource: any;
  submitButtonText: string;
}) => {
  const { palette } = useTheme();
  const { workspaceId, folderId } = useParams();
  const [file, setFile] = useState<any>();
  const [tag, setTag] = useState<any>();

  const uppy = useMemo(
    () =>
      new Uppy({
        id: "file",
        allowMultipleUploads: false,
        autoProceed: false,
        restrictions: {
          allowedFileTypes: allowedResourceTypes(resource),
          maxNumberOfFiles: 1,
        },
      }),
    []
  );

  const [createResource] = useMutation(CreateResourceDocument, {
    refetchQueries: [GetResourcesDocument],
  });

  const [execQuery, { loading, data, error }] =
    useLazyQuery(PreSignedUrlDocument);

  return (
    <Box>
      <Formik
        validationSchema={Yup.object().shape({
          name: Yup.string().optional(),
          description: Yup.string().optional(),
        })}
        initialValues={{
          description: "",
          type: resource as ResourceType,
          name: "",
          tags: {
            connectOrCreate: [],
            disconnect: [],
          },
        }}
        onSubmit={(data) => {
          const file = uppy.getFiles()?.[0]?.data as any;
          execQuery({
            variables: {
              input: {
                access: AccessMode.ReadWrite,
                directory: Directory.Resource,
                name: file?.name?.split(".")[0],
                workspaceId: workspaceId || "",
                writeInfo: {
                  fileExt: file?.name?.split(".")[1],
                  uploaderProvider: "axios",
                },
              },
            },
          }).then(async (res) => {
            if (res?.data?.preSignedUrl?.presignedUrl) {
              const uploadResponse = await axios.put(
                res?.data?.preSignedUrl?.presignedUrl,
                file,
                {
                  headers: {
                    "Content-Type": file?.type,
                  },
                  onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                  },
                }
              );

              const response = await createResource({
                variables: {
                  input: {
                    name: data.name,
                    path: res?.data?.preSignedUrl?.key || "",
                    type: resourceType(file) || ResourceType.Document,
                    workspaceId: workspaceId || "",
                    parentId: folderId || "",
                    description: data.description,
                    tags: data.tags.connectOrCreate.map((tag) => tag),
                  },
                },
              });

              if (response.data?.createResource.success) {
                toast.success(
                  `${file?.name?.split(".")[0] || ""} has been uploaded`
                );
                setResource("");
              }

              return;
            }
          });
        }}
      >
        {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <>
            <Box
              sx={{
                padding: "0 24px 24px",
                position: "relative",

                ".uppy-Dashboard-close, .uppy-Dashboard-poweredBy, .uppy-Dashboard-dropFilesHereHint, .uppy-DashboardTab-name, .uppy-Dashboard-AddFiles-title .uppy-Dashboard-browse, .uppy-Dashboard-Item-previewIconWrap, .uppy-DashboardContent-bar .uppy-DashboardContent-title":
                  {
                    display: "none",
                  },

                ".uppy-Dashboard-inner": {
                  height: "auto !important",
                  width: "auto !important",

                  "&:before": {
                    display: "block",
                    content: "'Import File'",
                    fontSize: "14px",
                    fontWeight: "500",
                    marginBottom: "12px",
                  },

                  "&:after": {
                    display: "block",
                    content: "'Powered by Uppy'",
                    fontSize: "12px",
                    fontWeight: "500",
                    textAlign: "center",
                    color: palette.grayAccent.light,
                    position: "absolute",
                    left: "0",
                    right: "0",
                    bottom: "10px",
                  },
                },

                ".uppy-Dashboard-innerWrap": {
                  width: "100%",
                  minHeight: "240px",
                  borderRadius: "8px",
                  border: `1px dashed ${palette.action.disabledBackground}`,
                  padding: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  position: "relative",
                  textAlign: "center",
                  transform: "translateX(0px)",

                  "@media (min-width: 768px)": {
                    padding: "32px",
                  },
                },

                ".uppy-Dashboard-AddFiles": {
                  order: "-1",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                },

                ".uppy-Dashboard-AddFiles-list, .uppy-Dashboard-files": {
                  order: "-1",
                },

                ".uppy-ProviderIconBg": {
                  fill: "transparent",
                },

                ".uppy-u-reset.uppy-c-btn.uppy-DashboardTab-btn": {
                  marginBottom: "12px",
                  width: "48px",
                  height: "48px",
                  backgroundColor: palette.primary.lighter,
                  borderRadius: "100%",

                  path: {
                    fill: palette.primary.main,
                  },
                },

                ".uppy-Dashboard-AddFiles-title": {
                  fontSize: "0",

                  "&:before": {
                    color: palette.common.black,
                    fontSize: "16px",
                    fontWeight: "500",
                    content: "'Drop file here'",
                    display: "block",
                  },

                  "&:after": {
                    color: palette.grayAccent.light,
                    fontSize: "14px",
                    fontWeight: "400",
                    content: `'or, click to browse (${allowedResourceTypes(
                      resource
                    ).join(", ")})'`,
                    display: "block",
                    marginTop: "8px",
                  },
                },

                ".uppy-Dashboard-progressindicators": {
                  fontSize: "12px",
                  fontWeight: "500",
                  width: "100%",

                  ".uppy-StatusBar-actions": {
                    marginTop: "12px",
                  },
                },

                ".uppy-Dashboard-files": {
                  width: "100%",
                  marginBottom: "20px",
                },

                ".uppy-Dashboard-Item": {
                  borderRadius: "4px",
                  backgroundColor: palette.primary.lighter,
                  marginBottom: "12px",
                  padding: "12px 50px 12px 12px",
                  position: "relative",
                  textAlign: "left",
                  wordBreak: "break-word",

                  ".uppy-Dashboard-Item-action, .uppy-Dashboard-Item-progressIcon--circle, .uppy-Dashboard-Item-progress .uppy-u-reset.uppy-Dashboard-Item-progressIndicator":
                    {
                      position: "absolute",
                      right: "12px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      lineHeight: "0",
                    },

                  ".uppy-Dashboard-Item-name": {
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontWeight: "500",
                    marginBottom: "8px",
                  },

                  ".uppy-Dashboard-Item-statusSize": {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: palette.grayAccent.light,
                  },
                },

                ".uppy-DashboardContent-bar": {
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  gap: "12px",

                  ".uppy-DashboardContent-back, .uppy-DashboardContent-addMore":
                    {
                      fontWeight: "500",
                      fontSize: ".875rem",
                      lineHeight: "1.25rem",
                      letterSpacing: "0.02em",
                      display: "inline-block",
                      verticalAlign: "top",
                      padding: ".5rem .75rem",
                      borderRadius: "4px",
                      border: `1px solid ${palette.action.disabledBackground}`,
                      color: palette.gray.dark,
                      backgroundColor: "transparent",
                      cursor: "pointer",
                      transition:
                        "color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease",

                      "@media (max-width: 575px)": {
                        width: "100%",
                      },

                      "&:hover": {
                        borderColor: palette.primary.dark,
                        color: palette.primary.dark,
                        backgroundColor: palette.primary.active,
                      },

                      ".uppy-c-icon": {
                        display: "none",
                      },
                    },

                  ".uppy-DashboardContent-addMore": {
                    color: palette.common.white,
                    backgroundColor: palette.primary.main,
                    borderColor: palette.primary.main,

                    "&:hover": {
                      color: palette.common.white,
                      backgroundColor: palette.primary.dark,
                      borderColor: palette.primary.dark,
                      boxShadow: `0 2px 2px ${palette.gray.main}, 0 3px 1px ${palette.action.disabledBackground}, 0 1px 5px ${palette.action.disabled}`,
                    },
                  },
                },

                ".uppy-Dashboard-AddFilesPanel": {
                  width: "100%",
                  marginTop: "12px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                },

                ".uppy-StatusBar-actionBtn--upload, .uppy-StatusBar-actions .uppy-StatusBar-actionBtn.uppy-StatusBar-actionBtn--done":
                  {
                    color: palette.common.white,
                    backgroundColor: palette.primary.main,
                    borderColor: palette.primary.main,
                    height: "auto !important",
                    transition: "all 0.3s ease",
                    fontWeight: "500",
                    fontSize: ".875rem",
                    lineHeight: "1.25rem",
                    letterSpacing: "0.02em",
                    padding: ".5rem .75rem",
                    borderRadius: "4px",

                    "&:hover": {
                      color: palette.common.white,
                      backgroundColor: palette.primary.dark,
                      borderColor: palette.primary.dark,
                      boxShadow: `0 2px 2px ${palette.gray.main}, 0 3px 1px ${palette.action.disabledBackground}, 0 1px 5px ${palette.action.disabled}`,
                    },
                  },

                ".uppy-Dashboard-progressindicators .uppy-StatusBar-progress": {
                  marginTop: "12px",
                  height: "8px",
                  borderRadius: "99px",
                  backgroundColor: palette.primary.light,
                },

                ".uppy-StatusBar-actionBtn--retry": {
                  fontWeight: "500",
                  fontSize: ".875rem",
                  lineHeight: "1.25rem",
                  letterSpacing: "0.02em",
                  display: "inline-block",
                  verticalAlign: "top",
                  padding: ".5rem .75rem",
                  borderRadius: "4px",
                  border: `1px solid ${palette.action.disabledBackground}`,
                  color: palette.grayAccent.main,
                  backgroundColor: "transparent",
                  cursor: "pointer",
                  transition:
                    "color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease",

                  "@media (max-width: 575px)": {
                    width: "100%",
                  },

                  "&:hover": {
                    color: palette.grayAccent.dark,
                    backgroundColor: palette.settings.lighter,
                  },

                  ".uppy-c-icon": {
                    display: "none",
                  },
                },

                ".uppy-Dashboard-Item-progressIcon--circle": {
                  display: "none",
                },

                ".is-complete .uppy-Dashboard-Item-progressIcon--circle": {
                  width: "24px",
                  height: "24px",
                  color: palette.common.white,
                  display: "inline",

                  circle: {
                    fill: palette.primary.main,
                  },
                },

                ".uppy-StatusBar-status .uppy-StatusBar-statusPrimary": {
                  marginTop: "12px",
                  color: palette.primary.main,

                  ".uppy-c-icon": {
                    marginRight: "8px",
                  },
                },

                ".uppy-Dashboard-Item .uppy-Dashboard-Item-progress .uppy-u-reset.uppy-Dashboard-Item-progressIndicator":
                  {
                    right: "36px",
                    width: "18px",
                    height: "18px",
                  },

                ".uppy-StatusBar-content[title='Upload failed']": {
                  marginTop: "24px",

                  ".uppy-StatusBar-statusSecondary": {
                    marginTop: "8px",
                    color: palette.grayAccent.light,
                  },
                },

                ".uppy-Dashboard-Item-previewInnerWrap": {
                  background: "none !important",
                },

                ".uppy-Dashboard-Item-previewImg": {
                  width: "130px",
                },

                ".uppy-StatusBar.is-error": {
                  ".uppy-StatusBar-actionBtn--retry": {
                    height: "auto",
                  },

                  ".uppy-StatusBar-actions": {
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    alignItems: "center",
                  },
                },
              }}
            >
              <Dashboard
                uppy={uppy}
                plugins={[]}
                proudlyDisplayPoweredByUppy={false}
                showProgressDetails={true}
                hideUploadButton={true}
                showSelectedFiles={true}
                target="body"
              />
            </Box>

            <Box
              sx={{
                padding: "0 24px",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  marginBottom: "24px",
                }}
              >
                <FormLabel>Name</FormLabel>
                <CustomTextField
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  error={errors.name}
                  touched={touched.name}
                />
              </Box>
              <Box
                sx={{
                  marginBottom: "24px",
                }}
              >
                <FormLabel>Description</FormLabel>
                <CustomTextField
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  error={errors.description}
                  touched={touched.description}
                />
              </Box>
              <Box
                sx={{
                  marginBottom: "24px",
                }}
              >
                <FormLabel>Tags</FormLabel>
                <Box>
                  <TextField
                    hiddenLabel
                    fullWidth
                    placeholder="Add tags here"
                    value={tag}
                    onChange={(e) => setTag(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        setFieldValue("tags.connectOrCreate", [
                          ...(values.tags?.connectOrCreate || []),
                          tag,
                        ]);
                        setTag("");
                      }
                    }}
                    inputProps={{
                      sx: { fontSize: "1rem", padding: ".375rem .75rem" },
                    }}
                    size="small"
                  />
                  <Box display={"flex"} pt={"1.125rem"}>
                    {values.tags?.connectOrCreate.map((tag: string) => (
                      <Tag
                        label={tag}
                        dismissable={true}
                        mr={2}
                        onClickDismiss={() => {
                          setFieldValue("tags.connectOrCreate", [
                            ...(values.tags?.connectOrCreate.filter(
                              (aTag: any) => tag !== aTag
                            ) || []),
                          ]);
                          setFieldValue("tags.disconnect", [
                            ...(values.tags?.disconnect || []),
                            tag,
                          ]);
                        }}
                      ></Tag>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                boxShadow: `0 -3px 5px ${palette.gray.main}`,
                padding: "24px",
                display: "flex",
                justifyContent: "flex-end",
                gap: "12px",
              }}
            >
              <ButtonDefault
                type="button"
                onClick={() => setResource("")}
              >
                Cancel
              </ButtonDefault>
              <ButtonPrimary
                type="submit"
                onClick={() => handleSubmit()}
                disabled={!dirty}
              >
                {submitButtonText}
              </ButtonPrimary>
            </Box>
          </>
        )}
      </Formik>
    </Box>
  );
};
