import React, { useState } from "react";
import { Box, Divider, Popover, useTheme } from "@mui/material";
import { useMenu } from "../Menu/useMenu";
import { Button } from "../Button/Button";
import {
  DuplicateProjectDocument,
  GetProjectQuery,
  ProjectStatus,
} from "../../generated";
import { ApolloQueryResult, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ButtonDefault, ButtonDropdown, PopoverItemButton } from "./style";

export const ProjectCardActionDropdown = ({
  workspaceId,
  id,
  refetchProjectDocuments,
}: any) => {
  const [menuOpen, anchorEl, openMenuFromAnchorElement, closeMenu] = useMenu();
  const [mutate] = useMutation(DuplicateProjectDocument);
  const { palette } = useTheme();

  return (
    <Box>
      <ButtonDefault
        id="project-action-button"
        aria-controls={menuOpen ? "project-action-items" : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        color="grayAccent"
        variant="outlined"
        onClick={openMenuFromAnchorElement}
        className="small icn-large"
      >
        <MoreVertIcon />
      </ButtonDefault>
      <ButtonDropdown
        id="project-action-items"
        aria-labelledby="project-action-button"
        open={menuOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        onClose={closeMenu}
      >
        <PopoverItemButton
          id="project-action-button"
          aria-controls={menuOpen ? "project-action-items" : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? "true" : undefined}
          color="grayAccent"
          variant="outlined"
          onClick={() => {
            closeMenu();
            mutate({
              variables: {
                input: {
                  workspaceId: workspaceId || "",
                  projectId: id || "",
                },
              },
            }).then((res) => {
              if (res.data?.duplicateProject) {
                toast.success("Project has been duplicated");
                refetchProjectDocuments();
              } else {
                toast.error("Error duplicating Project");
              }
            });
          }}
        >
          Duplicate
        </PopoverItemButton>
      </ButtonDropdown>
    </Box>
  );
};
