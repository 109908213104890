import { useTheme } from "@mui/material";
import { LinesEllipsisTypography } from "../../common/LinesEllipsisTypography/LinesEllipsisTypography";
import { ChatBotWidgetDescription, ChatBotWidgetHeading, ChatBotWidgetWrapper } from "../style";

export const ChatBotWidget = ({ title, description, onClick }: any) => {
  const { palette } = useTheme();

  return (
    <ChatBotWidgetWrapper className="ChatBotWidget" onClick={onClick}>
      {title && <ChatBotWidgetHeading variant="h2">{title}</ChatBotWidgetHeading>}
      {description && (
        <ChatBotWidgetDescription variant="body1">
          <LinesEllipsisTypography
            color={palette.gray.dark}
            variant="body3"
            text={description}
            maxLines={2}
            sx={{
              mb: 4,
            }}
          />
        </ChatBotWidgetDescription>
      )}
    </ChatBotWidgetWrapper>
  );
};
