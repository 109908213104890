import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import chatApi from "../api/chatApi";
import { MoreHoriz } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DropdownHolder } from "./style";

const DropdownButton = ({ setRefetch, setEdit, thread, threads }: any) => {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const { threadId, workspaceId } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteThread = (id: string) => {
    if (threadId === id) navigate(`/workspace/${workspaceId}/brightbot`);

    chatApi
      .delete(`/threads/${id}`)
      .then(() => {
        toast.success(
          `Session deleted ${thread?.metadata?.Title || thread.thread_id}`
        );
        setRefetch((prev: any) => !prev);
        setDeleteModal(false);
      })
      .catch((err) => {
        console.error(err);
      });

    setDeleteModal(false);
  };

  return (
    <>
      <DropdownHolder>
        <Button
          id="dropdown-button"
          aria-controls={open ? "dropdown-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          variant="contained"
          color="gray"
        >
          <MoreHoriz />
        </Button>
        <Menu
          id="dropdown-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "dropdown-button",
          }}
        >
          <MenuItem
            onClick={() => {
              setDeleteModal(true);
              handleClose();
            }}
          >
            Delete
          </MenuItem>
          <MenuItem onClick={() => setEdit(thread)}>Edit</MenuItem>
        </Menu>
      </DropdownHolder>
      <Dialog maxWidth="sm" open={deleteModal}>
        <Box width="440px" height="auto" p={6} boxSizing="border-box">
          <Typography mb={4} variant="h5" color={palette.common.black}>
            Delete Session ({thread?.metadata?.Title || thread.thread_id})?
          </Typography>
          <Box mb={8}>
            <Typography
              variant="body3"
              color={palette.gray.dark}
              sx={{ mb: 2 }}
            >
              Are you sure you want to delete this session{" "}
              {thread?.metadata?.Title || thread.thread_id}? All changes will be
              lost.
            </Typography>
          </Box>
          <Box mt={4} display="flex" alignItems={"center"}>
            <Button
              color="grayAccent"
              variant="outlined"
              onClick={() => setDeleteModal(false)}
              sx={{ ml: "auto", mr: 3 }}
            >
              Cancel
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={() => deleteThread(thread.thread_id)}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default DropdownButton;
