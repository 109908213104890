import React, { useEffect, useState } from "react";
import { BrighthiveLogoSvg } from "../common/Icons/BrighthiveLogoSvg";
import { Label } from "../common/Label/Label";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Box, Typography, useTheme } from "@mui/material";
import { Button } from "../common/Button/Button";
import { useMutation } from "@apollo/client";
import { ConfirmForgotPasswordDocument, LoginDocument } from "../generated";
import { SimpleLoader } from "../common/Loader/Loader";
import { toast } from "react-toastify";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

export interface LoginProps {}

interface State {
  password: string;
  confirmPassword: string;
}

export const ResetPassword = () => {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [confirmForgotPassword] = useMutation(ConfirmForgotPasswordDocument);
  const [resetInProgress, setResetInProgress] = useState(false);

  const [values, setValues] = React.useState<State>({
    password: "",
    confirmPassword: "",
  });

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const isDisabled = () => {
    if (
      /^(?=.*\d)(?=.*[!@#$%^&?_=*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(
        values.password
      ) &&
      values.password === values.confirmPassword
    ) {
      return false;
    }

    return true;
  };

  const onClickReset = (values: State) => {
    setResetInProgress(true);
    const email = location.search.split("email=")[1].split("&")[0];

    confirmForgotPassword({
      variables: {
        input: {
          confirmationCode: searchParams.get("code") || "",
          password: values.password,
          username: email || "",
        },
      },

      onCompleted: (res) => {
        setResetInProgress(false);
        if (res.confirmForgotPassword) {
          toast.success(`Password change for ${email}`);
          navigate("/login");
        } else {
          toast.error("Unable to change password");
        }
      },

      onError: (err) => {
        setResetInProgress(false);
        toast.error("Unable to change password");
      },
    });
  };

  useEffect(() => {
    if (searchParams) {
      if (!searchParams.get("email") || !searchParams.get("code"))
        navigate("/login");
    }
  }, [searchParams]);

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        p: 6,
        m: "auto",
        width: "28.5rem",
        background: "white",
        borderRadius: ".375rem",
        boxShadow:
          `0 .0625rem .0625rem ${palette.customRolled.border}, 0 .125rem .0625rem ${palette.action.disabledBackground}, 0 .0625rem .1875rem ${palette.customRolled.contrastText}`,
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="left">
        <BrighthiveLogoSvg
          sx={{
            height: "1.875rem",
            width: "155px",
            mb: 6,
          }}
        />
      </Box>
      <Typography
        variant="h5"
        color={palette.common.black}
        sx={{ margin: "0 0 24px" }}
      >
        Enter your new password
      </Typography>
      {resetInProgress ? (
        <Box
          display="flex"
          flex="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            ">div": {
              width: "100%",
              fontSize: "16px",
            },
            h6: {
              fontSize: "16px",
            },
          }}
        >
          <SimpleLoader text="Resetting password" />
        </Box>
      ) : (
        <>
          <Label value="Password" variant="subtitle3" sx={{ mb: ".375rem" }} />
          <OutlinedInput
            type="password"
            name="password"
            fullWidth
            notched={false}
            sx={{ mb: 4 }}
            value={values.password}
            onChange={handleChange("password")}
            inputProps={{ sx: { fontSize: "1rem", padding: ".375rem .75rem" } }}
            label="Password"
          />
          <Label
            value="Confirm Password"
            variant="subtitle3"
            sx={{ mb: ".375rem" }}
          />
          <OutlinedInput
            type="password"
            name="confirmPassword"
            fullWidth
            notched={false}
            sx={{ mb: 4 }}
            value={values.confirmPassword}
            onChange={handleChange("confirmPassword")}
            inputProps={{ sx: { fontSize: "1rem", padding: ".375rem .75rem" } }}
            label="Password"
          />
          {/* <Link to="/login">Return to logn page</Link> */}
          <Button
            style={{ marginTop: "1rem" }}
            onClick={() => onClickReset(values)}
            disabled={isDisabled()}
            fullWidth
            variant="contained"
          >
            Reset password
          </Button>
        </>
      )}
    </Box>
  );
};
