import React, { useState } from "react";
import { Box, useTheme, Avatar } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { Chip } from "../../common/Chip/Chip";
import { stringAvatarLetters, concatName } from "../../helpers";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GetOrganizationSheetDocument } from "../../generated";
import {
  getIssueIcon,
  getIssueColor,
  getLabelIssueStatus,
  getAlertMessage,
  getLoader,
} from "../helpers";

const OrganizationSheets = ({ id }: { id: string }) => {
  const { palette } = useTheme();
  const { workspaceId, projectId } = useParams();
  const [show, setShow] = useState(false);
  const { data, loading } = useQuery(GetOrganizationSheetDocument, {
    variables: {
      workflowFilterInput: {
        projectId: projectId || "",
        workspaceId: workspaceId || "",
      },
      organizationFilter: {
        organizationId: id,
      },
    },
  });
  if (loading) return getLoader("Fetching Data", "custom-loader full-height");
  const orgData = data?.workflow?.organizations?.[0];
  if (orgData) {
    return (
      <Box boxSizing="border-box">
        <Box
          sx={{
            paddingRight: "20px",
            paddingBottom: "16px",
            borderBottom: `1px solid ${palette.outline}`,
          }}
          mb={6}
        >
          <Typography
            mb={2}
            variant="h5"
            color={palette.gray.dark}
            sx={{
              fontSize: "12px",
              lineHeight: "14px",
              textTransform: "uppercase",
              fontWeight: "700",
            }}
          >
            Organization
          </Typography>
          <Typography
            mb={4}
            variant="h5"
            color={palette.common.black}
            sx={{ wordBreak: "break-word" }}
          >
            {orgData.name}
          </Typography>
          {show && (
            <Chip
              icon={getIssueIcon(orgData.issueStatus)}
              color={getIssueColor(orgData.issueStatus)}
              label={getLabelIssueStatus(orgData.issueStatus)}
              sx={{
                color: palette.common.black,
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: "500",
              }}
            />
          )}
        </Box>

        <Box
          sx={{
            paddingBottom: "16px",
            borderBottom: `1px solid ${palette.outline}`,
          }}
          mb={6}
        >
          {show &&
            getAlertMessage(
              orgData.issueStatus,
              "An entity managed by this organization is preventing the data flow from running."
            )}
          <Typography
            mb={2}
            variant="h5"
            color={
              orgData.description ? palette.gray.darkest : palette.gray.dark
            }
            sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: "normal" }}
          >
            {orgData.description ? orgData.description : "None"}
          </Typography>
        </Box>

        <Box
          sx={{
            paddingBottom: "16px",
            borderBottom: `1px solid ${palette.outline}`,
          }}
          mb={6}
        >
          <Typography
            mb={4}
            variant="h5"
            color={palette.common.black}
            sx={{ fontSize: "18px", lineHeight: "24px", fontWeight: "normal" }}
          >
            Managed data assets in project
          </Typography>
          <Box mb={6}>
            <Typography
              mb={2}
              variant="h5"
              color={palette.common.black}
              sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "500" }}
            >
              Input data assets
            </Typography>
            <Typography
              mb={2}
              color={
                orgData.inputDataAssetCount && orgData.inputDataAssetCount > 0
                  ? palette.gray.darkest
                  : palette.gray.dark
              }
              sx={{
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: "normal",
              }}
            >
              {orgData.inputDataAssetCount && orgData.inputDataAssetCount > 0
                ? `${orgData.inputDataAssetCount} managed`
                : "None"}
            </Typography>
          </Box>
          <Box mb={6}>
            <Typography
              mb={2}
              variant="h5"
              color={palette.common.black}
              sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "500" }}
            >
              Data product assets
            </Typography>
            <Typography
              mb={2}
              color={
                orgData.dataProductCount && orgData.dataProductCount > 0
                  ? palette.gray.darkest
                  : palette.gray.dark
              }
              sx={{
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: "normal",
              }}
            >
              {orgData.dataProductCount && orgData.dataProductCount > 0
                ? `${orgData.dataProductCount} managed`
                : "None"}
            </Typography>
            <Typography
              mb={2}
              color={palette.gray.darkest}
              sx={{
                fontSize: "14px",
                lineHeight: "20px",
                fontWeight: "normal",
              }}
            >
              By default, data products produced by the project are owned by the
              workspace and are managed by the project manager.
            </Typography>
          </Box>
        </Box>

        <Box mb={4}>
          <Typography
            mb={4}
            variant="h5"
            color={palette.common.black}
            sx={{ fontSize: "18px", lineHeight: "24px", fontWeight: "normal" }}
          >
            Organization project role
          </Typography>
          <Box mb={6}>
            <Typography
              mb={2}
              variant="h5"
              color={palette.common.black}
              sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "500" }}
            >
              Organization role
            </Typography>
            <Typography
              mb={2}
              color={palette.gray.darkest}
              sx={{
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: "normal",
              }}
            >
              {orgData.projectRole}
            </Typography>
          </Box>
          <Box mb={6}>
            <Typography
              mb={2}
              variant="h5"
              color={palette.common.black}
              sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "500" }}
            >
              Project contributors
            </Typography>
            {orgData.contributors.map((contributor) => (
              <Box display="flex" mb={2}>
                <Avatar
                  sx={{
                    color: palette.common.black,
                    height: "1.5rem",
                    width: "1.5rem",
                    mr: 2,
                    bgcolor: palette.error.light,
                    fontWeight: 700,
                    fontSize: ".5rem",
                    outline: `0.015625rem solid ${palette.action.disabledBackground}`,
                  }}
                  alt={
                    contributor && contributor.firstName && contributor.lastName
                      ? concatName(
                          contributor?.firstName,
                          contributor?.lastName
                        )
                      : ""
                  }
                >
                  {contributor &&
                    contributor.firstName &&
                    contributor.lastName &&
                    stringAvatarLetters(
                      concatName(contributor?.firstName, contributor?.lastName)
                    )}
                </Avatar>
                <Typography
                  variant="body2"
                  sx={{ color: palette.gray.darkest }}
                >
                  {contributor &&
                    contributor.firstName &&
                    contributor.lastName &&
                    concatName(contributor?.firstName, contributor?.lastName)}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    );
  }
  return null;
};
export default OrganizationSheets;
