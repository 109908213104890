import { camelCase } from "lodash";
import { createElement } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { ResourceType } from "./generated";

export const getDayMonthYearFromDate = (date: Date) => {
  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;
  return {
    day,
    month,
    year,
  };
};

export const convertToTitleCase = (str: any) => {
  if (typeof str !== "string") return "";
  return str
    .replaceAll("_", " ")
    .split(" ")
    .map((curr) => {
      if (curr !== "DBT")
        return curr.charAt(0).toUpperCase() + curr.slice(1).toLowerCase();
      return curr;
    })
    .join(" ");
};

export const convertToSentenceCase = (str: any) => {
  if (typeof str !== "string") return "";
  let sentence = str.toLowerCase().split(" ");
  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }
  return sentence.join(" ");
};

export const getAndSetTimeAtStartOfDay = (date: Date) => {
  date.setHours(0, 0, 0, 0);
  return date.getTime();
};

export const convertIsoDateStringToMMDDYYYY = (isoDateString: any) => {
  if (isNaN(Date.parse(isoDateString))) return "";
  const { day, month, year } = getDayMonthYearFromDate(new Date(isoDateString));
  return month + "/" + day + "/" + year;
};

export const convertDateTime = (isoDateString: any) => {
  if (isNaN(Date.parse(isoDateString))) return "";
  return new Date(isoDateString).toLocaleString();
};

export const stringDateComparator = (valueA: any, valueB: any) => {
  const valueATime = new Date(convertIsoDateStringToMMDDYYYY(valueA)).getTime();
  const valueBTime = new Date(convertIsoDateStringToMMDDYYYY(valueB)).getTime();
  if (valueATime > valueBTime) {
    return -1;
  } else if (valueATime < valueBTime) {
    return 1;
  } else {
    return 0;
  }
};

export const getAllUniqueValuesFromArray = (arr: any[]) => {
  const uniqueValues: any = [];
  arr.forEach((curr) => {
    if (!uniqueValues.includes(curr)) {
      uniqueValues.push(curr);
    }
  });
  return uniqueValues;
};

export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const concatName = (fName?: string, lName?: string) =>
  fName + " " + lName;

export const stringAvatarLetters = (name: string) => {
  const splitName = name.split(" ");
  if (splitName.length >= 2) {
    let fName = name?.split(" ")[0];
    fName = fName?.replace(/[^a-zA-Z0-9 ]/g, "")[0];

    let lName = name?.split(" ")[1];
    lName = lName?.replace(/[^a-zA-Z0-9 ]/g, "")[0];
    return `${fName?.toLocaleUpperCase() || ""}${
      lName?.toLocaleUpperCase() || ""
    }`;
  } else {
    return name[0] || "";
  }
};

export const caseInsensitiveSort = (valueA: any, valueB: any) => {
  return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
};

export const getRandomNumber = (number = 9999): number =>
  Math.floor(Math.random() * number);

export const dateHelper = (date: string) => {
  const inputDate = new Date(date);
  const options = { day: "numeric", month: "short", year: "numeric" } as any;
  const formattedDate = inputDate.toLocaleDateString("en-US", options);

  return formattedDate;
};

export const setCurrentPageTitle = (name: any) => {
  const excludedList = ["brightbot"];
  if (excludedList.includes(name)) {
    let updatedName = camelCase(name.replaceAll("-", " "));
    return updatedName.replaceAll(" ", "");
  }
  return name.replaceAll("-", " ");
};

export const downloadUrl = (dataurl: string, filename: string) => {
  const link = document.createElement("a");
  link.href = dataurl;
  link.download = filename;
  link.click();
};

export const allowedDocTypes = [
  ".docs",
  ".doc",
  ".ooxml",
  ".pdf",
  ".xls",
  ".csv",
];
export const allowedImageTypes = [".png", ".jpg", ".jpwg", ".gif", ".webp"];
export const allowedVideoTypes = [".mp4", ".avi", ".mov", ".mkv", ".webm"];

export const resourceType = (file: any) => {
  if (file.name.includes("docs", "doc", "ooxml", "pdf", "xls", "csv")) {
    return ResourceType.Document;
  } else if (file.name.includes("png", "jpg", "jpeg", "gif", "webp")) {
    return ResourceType.Image;
  } else if (file.name.includes("mp4", "avi", "mov", "mkv", "webm")) {
    return ResourceType.Video;
  } else {
    return false;
  }
};

export const schemaJsonParser = (targetSchema: any, data: any, v: any) => {
  const parse = JSON.parse(targetSchema?.jsonSchema || "{}");
  let properties = parse?.properties || {};

  let required: string[] = parse?.required || [];

  if (!properties[v.name]) {
    delete properties[data.name];
    required = required.filter((req) => req !== data.name);
  }

  properties[v.name] = {
    description: v.description,
    title: v.title,
    type: v.type,
    // pii: v.pii,
    ...(v.type === "string" && v.format !== "none" && { format: v.format }),
    ...(v.validate &&
      v.type === "string" && {
        // unique: v.unique,
        ...(v.pattern && { pattern: v.patternValue }),
      }),
    ...(v.type === "array" && v.enum.length > 0 && { enum: v.enum }),
    // validate: v.validate,
    // required: v.required,
    // unique: v.unique,
    // pattern: v.pattern,
    // patternValue: v.patternValue,
  };

  if (v.minLength) {
    properties[v.name]["minLength"] = v.minLength;
  }

  if (v.maxLength) {
    properties[v.name]["maxLength"] = v.maxLength;
  }

  parse.properties = properties;

  if (!v.required) {
    required = required.filter((req) => req !== v.name);
  } else if (!required.includes(v.name)) {
    required.push(v.name);
  }

  parse.required = required;

  return parse;
};

export const allowedResourceTypes = (resource: string) => {
  if (resource === ResourceType.Image) {
    return [".png", ".jpg", ".gif", ".webp"];
  }
  if (resource === ResourceType.Video) {
    return [".mp4", ".avi", ".mov", ".mkv", ".webm"];
  } else {
    return [".docs", ".doc", ".ooxml", ".pdf", ".xls", ".csv", ".docx"];
  }
};
