import { Box } from "@mui/material";

export const ImageViewer = ({ url, zoom }: { url: string; zoom: number }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={url}
        style={{
          transform: `scale(${zoom / 100})`,
        }}
      />
    </Box>
  );
};
