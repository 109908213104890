import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Alert,
  useTheme,
} from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { Button } from "../../common/Button/Button";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface SpecificAddDataAssetDialogProps {
  onClose: () => void;
}

type ApiKeySuccessDialogProps = DialogProps & SpecificAddDataAssetDialogProps;

export const ApiKeySuccessDialog = (props: ApiKeySuccessDialogProps) => {
  const onClickCancel = () => {
    if (props.onClose) props.onClose();
  };

  const [hide, setHide] = useState(true);

  const handleClickCopy = () => {
    copy("iKNi2ARXj6xjTixFo2MeJOTsc48Z39A3-dth4bJRw_Zm");
    toast("Copied!");
  };
  const { palette } = useTheme();

  return (
    <Dialog {...props} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography mb={4} variant="h5">
          API key successfully created
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Alert severity="info">
          This is your unique API key, and it is non-recoverable. If you lose
          this key, you will have to generate a new one.
        </Alert>
        <Box
          mt={4}
          sx={{
            padding: 4,
            borderRadius: 1,
            background: palette.settings.lighter,
          }}
        >
          <Typography gutterBottom variant="subtitle3">
            API Key
          </Typography>
          <TextField
            size="small"
            value="iKNi2ARXj6xjTixFo2MeJOTsc48Z39A3-dth4bJRw_Zm"
            fullWidth
            type={hide ? "password" : "text"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => setHide(!hide)}>
                    {hide ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box mt={2}>
            <Button
              variant="contained"
              color="grayAccent"
              startIcon={<ContentCopyIcon />}
              onClick={handleClickCopy}
            >
              Copy
            </Button>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickCancel} color="grayAccent" variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
