import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const MoveFileIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 6.5L16.5 9M16.5 9L14 11.5M16.5 9H11.5M4 6.5L1.5 9M1.5 9L4 11.5M1.5 9H6.5M6.5 14L9 16.5M9 16.5L11.5 14M9 16.5V11.5M11.5 4L9 1.5M9 1.5L6.5 4M9 1.5V6.5"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};
