import { BrightBotSmileIcon } from "../../common/Icons/BrightBotSmileIcon";
import { AvatarWrapper, ChatRcvBotDescription, ChatRcvBotWidgetWrapper } from "../style";

export const ChatRcvBotWidget = ({ avatar, children }: any) => {
  return (
    <ChatRcvBotWidgetWrapper className="ChatRcvBotWidget">
      <AvatarWrapper>
        {avatar && <img src={avatar} alt="" />}
        <BrightBotSmileIcon />
      </AvatarWrapper>
      <ChatRcvBotDescription>
        <div style={{ whiteSpace: "pre-wrap" }}>{children}</div>
      </ChatRcvBotDescription>
    </ChatRcvBotWidgetWrapper>
  );
};
