import React, { ReactNode } from "react";
import { Box, useTheme } from "@mui/material";
import { BoxProps } from "@mui/system";
import { BasicCardHolder } from "./style";

export interface BasicCardProps {
  children?: ReactNode;
}

export const BasicCard = ({
  children,
  sx,
  ...rest
}: BasicCardProps & BoxProps) => {
  const { palette } = useTheme();

  return (
    <BasicCardHolder
      sx={{
        ...sx,
      }}
      {...rest}
    >
      {children}
    </BasicCardHolder>
  );
};
