import { GetTransformationServicesDocument } from "../../generated";
import { Services } from "../../common/Services/Services";

export const Transformation = () => {
  return (
    <Services
      title="Transformation"
      query={GetTransformationServicesDocument}
    />
  );
};
