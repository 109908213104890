/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Box, Dialog, useTheme } from "@mui/material";
import { Typography } from "../common/Typography/Typography";
import { Button, ButtonProps } from "../common/Button/Button";
import useGetWorkspace from "../hooks/useGetWorkspace";

const title = {
  add: "addition",
  delete: "deletion",
  update: "update",
};

interface SpecificPolicyOperationConfirmModalProps {
  handleClose?: Function;
  handleSubmit?: Function;
  operation?: "add" | "delete" | "update";
  show?: boolean;
  loading?: boolean;
  items?: string[];
}

type PolicyOperationConfirmModalProps = ButtonProps &
  SpecificPolicyOperationConfirmModalProps;

export const PolicyOperationConfirmModal = ({
  handleClose = () => {},
  handleSubmit = () => {},
  show = false,
  loading = false,
  operation = "delete",
  items,
  ...rest
}: PolicyOperationConfirmModalProps) => {
  const closeDialog = () => {
    handleClose();
  };
  const submitDialog = () => {
    handleSubmit();
  };
  const { palette } = useTheme();
  const { workspace } = useGetWorkspace();
  return (
    <>
      <Dialog open={show} onClose={closeDialog}>
        <Box width="35rem" p={6} boxSizing="border-box">
          <Typography mb={4} variant="h5">
            Confirm policy {title[operation]}
          </Typography>
          <Typography
            css={css`
              backgroundcolor: ${palette.warning.lighter};
              borderradius: 0.25rem;
            `}
            color={palette.warning.darkest}
            mb={2}
            p={3}
            variant="body3"
          >
            <strong>Warning:</strong> Adding or removing policies may cause
            unintended issues. Please ensure all changes conform to workspace
            legal agreements.
          </Typography>
          <Typography color={palette.gray.dark} mb={2} variant="body2">
            Are you sure you want to <strong>{operation}</strong> the following
            policies to the workspace, <strong> {workspace?.name} </strong>?
          </Typography>
          <ul
            css={css`
              margin: 0;
              paddingleft: 1.5rem;
            `}
          >
            {items?.map((item) => (
              <li key={item}>
                <Typography color={palette.gray.dark} variant="body2">
                  {item}
                </Typography>
              </li>
            ))}
          </ul>
          <Typography color={palette.gray.dark} mt={5} variant="body3">
            All workspace members will be notified of policy changes the next
            time they log into the workspace.
          </Typography>
          <Box display="flex" mt={8} justifyContent="flex-end">
            <Button
              onClick={closeDialog}
              color="grayAccent"
              variant="outlined"
              sx={{ mr: 3 }}
            >
              Cancel
            </Button>
            <Button
              onClick={submitDialog}
              color={operation === "delete" ? "error" : "primary"}
              variant="contained"
              disabled={loading}
            >
              I understand - {operation} policies
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
