import { styled, Box, Tab, Typography, TextField, Button, Divider } from '@mui/material';
import { Tabs } from "../common/Tabs/Tabs";
import { brighthiveTheme } from "../theme/theme";

export const ChatBotHeaderWrapper = styled("div")`
  text-align: center;
  padding: 24px;

  @media (min-width: 1200px) {
    padding: 32px;
  }
`;

export const ChatBotHeaderIcon = styled("i")`
  margin: 0 auto 10px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  color: ${brighthiveTheme.palette.grayAccent.contrastText};

  @media (min-width: 1200px) {
    width: 60px;
    height: 60px;
  }

  .MuiSvgIcon-root {
    width: 100%;
    height: 100%;
  }
`;

export const ChatBotWidgetWrapper = styled("button")`
  background-color: ${brighthiveTheme.palette.primary.lighter};
  overflow: hidden;
  padding: 24px 16px;
  border-radius: 4px;
  width: 100%;
  border: none;
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover {
    background-color: ${brighthiveTheme.palette.primary.outline};
    transition: all 0.2s linear;
  }
`;

export const ChatBotWidgetDescription = styled(Typography)`
  color: ${brighthiveTheme.palette.gray.darkest};
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.25px;

  &.black{
    color: ${brighthiveTheme.palette.common.black};
  }
`;

export const ChatBotHeaderHeading = styled(Typography)`
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: 0.15px;

  @media (min-width: 1200px) {
    font-size: 24px;
  }
`;

export const ChatBotWidgetHeading = styled(Typography)`
  color: ${brighthiveTheme.palette.common.black};
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: 0.25px;
  margin-bottom: 24px;
`;

export const ChatBotWrapper = styled("div")`
  width: 100%;
  max-width: 890px;
  margin-left: auto;
  margin-right: auto;

  &.grow-1{
    flex-grow: 1;
  }
`;

export const ChatBotHeadingSmall = styled(Typography)`
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: 0.25px;
  margin-bottom: 16px;
`;

export const ListWrapper = styled(Box)`
  border: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
  border-radius: 8px;
  padding: 8px 12px;
`;

export const HistoryActionsWrapper = styled(Box)`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  white-space: nowrap;
`;

export const Time = styled(Typography)`
  color: ${brighthiveTheme.palette.action.disabled};
  font-size: 12px;
  font-weight: 500;

  &.positioned{
    line-height: 1.5;
    position: absolute;
    right: 12px;
    top: 16px;
  }
`;

export const ChatSentBotWidgetWrapper = styled("div")`
  margin-top: 24px;
  background-color: ${brighthiveTheme.palette.primary.darkest};
  color: ${brighthiveTheme.palette.grayAccent.contrastText};
  font-size: 14px;
  line-height: 1.4;
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  border-radius: 12px 0 12px 12px;
  padding: 12px 16px;

  p:only-child {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const ButtonDelete = styled(Button)`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  min-width: 1px;
  padding: 0;
  width: 32px;
  height: 32px;
  background-color: ${brighthiveTheme.palette.settings.lighter};
  color: ${brighthiveTheme.palette.gray.border};
  opacity: 0;
  visibility: hidden;

  .MuiSvgIcon-root {
    width: 12px;
    height: 12px;
  }
`;

export const AvatarWrapper = styled(Box)`
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  color: ${brighthiveTheme.palette.grayAccent.contrastText};
  border: 1px solid ${brighthiveTheme.palette.custom.outline};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  line-height: 0;
  font-size: 0;
  padding: 3px 2px 0;

  &.positioned{
    position: absolute;
    left: 0;
    top: 0;
  }

  &.success {
    position: relative;
    border-color: ${brighthiveTheme.palette.success.lighter};

    img,
    svg {
      width: 80%;
      height: 80%;
    }
  }

  img,
  svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
    vertical-align: top;
  }
`;

export const ChatRcvBotDescription = styled(Box)`
  max-width: 560px;
  border-radius: 0 8px 8px 8px;
  padding: 12px 18px;
  color: ${brighthiveTheme.palette.common.black};
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.25px;
  border: 1px solid ${brighthiveTheme.palette.custom.contrastText};

  @media (min-width: 992px) {
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 0 12px 12px 12px;
  }

  p {
    a {
      color: ${brighthiveTheme.palette.link.main};
      text-decoration: none;

      &:hover {
        color: ${brighthiveTheme.palette.common.black};
      }
    }
  }
`;

export const ChatRcvBotWidgetWrapper = styled("div")`
  display: flex;
  gap: 8px;
  margin-top: 24px;
`;

export const ListItem = styled(Box)`
  padding: 20px 12px;
  position: relative;
  border-left: 3px solid transparent;
  border-radius: 4px;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 32px;
  font-size: 14px;
  line-height: 1.3;
  color: ${brighthiveTheme.palette.common.black};

  &:hover {
    border-left-color: ${brighthiveTheme.palette.primary.dark};
    background-color: ${brighthiveTheme.palette.primary.lighter};

    .ButtonDelete {
      opacity: 1;
      visibility: visible;
    }

    .Time {
      opacity: 0;
      visibility: hidden;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
  }
`;

export const WidgetsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 32px;
`;

export const WidgetCol = styled(Box)`
  display: flex;
  width: calc(50% - 8px);

  @media (min-width: 992px) {
    width: calc(33.333% - 11px);
  }
`;

export const MessagesWrapper = styled(Box)`
  padding-left: 16px;
  padding-right: 16px;

  @media (min-width: 992px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const EditorWrapper = styled(Box)`
  padding: 16px 24px;
  position: relative;
  border-radius: 4px;
  background-color: ${brighthiveTheme.palette.settings.lighter};
  margin-top: 24px;

  @media (min-width: 992px) {
    padding: 16px 32px;
  }
`;

export const FormWrapper = styled(Box)`
  position: relative;
`;

export const FormField = styled(TextField)`
  width: 100%;

  > .MuiInputBase-formControl {
    padding: 0;
    font-size: 14px;
    line-height: 1.2;
  }

  > div.MuiInputBase-formControl {
    padding: 14px 100px 14px 0;
  }

  fieldset {
    border-color: transparent !important;
  }
`;

export const ActionsWrapper = styled(Box)`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  gap: 8px;
`;

export const ButtonAttachment = styled(Box)`
  position: relative;
  line-height: 0;
  width: 40px;
  height: 40px;
  pointer-events: none;
  cursor: default;

  .MuiSvgIcon-root {
    width: 19px;
    height: 19px;
    opacity: 0.5;
  }

  label {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: ${brighthiveTheme.palette.primary.lighter};
    }
  }

  input {
    opacity: 0;
    clip: rect(0, 0, 0, 0);
    position: absolute;
    width: 1px;
    height: 1px;
  }
`;

export const ButtonSubmit = styled(Button)`
  padding: 0;
  min-width: 1px;
  line-height: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiSvgIcon-root {
    width: 19px;
    height: 19px;
  }
`;

export const Wrapper = styled('div')`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;

  *,
  *:after,
  *:before{
    box-sizing: inherit;
  }
`;

export const Tabset = styled(Tabs)`
  position: relative;
  z-index: 1;
  margin-bottom: 0 !important;

  &:after{
    content: '';
    border-bottom: 1px solid ${brighthiveTheme.palette.error.lighter};
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }

  .MuiTabs-flexContainer{
    justify-content: center;
  }
`;

export const TabsWrapper = styled(Tabs)`
  margin-bottom: 16px;

  .MuiTabs-flexContainer{
    justify-content: center;
  }

  .MuiTabs-indicator{
    background-color: ${brighthiveTheme.palette.primary.dark};
  }
`;

export const TabButton = styled(Tab)`
  font-size: 16px;
  line-height: 1.42;
  padding: 12px 24px !important;
  min-width: 1px;
  color: ${brighthiveTheme.palette.gray.dark};
  background-color: transparent;
  border-radius: 4px 4px 0 0;

  &.Mui-selected,
  &:hover{
    background-color: transparent !important;
    color: ${brighthiveTheme.palette.primary.dark};
  }
`;

export const TabItem = styled(Tab)`

`;

export const TopArea = styled(Box)`
  
`;

export const BottomArea = styled(Box)`
  flex-grow: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px;

  @media (min-width: 1200px){
    padding: 32px;
  }
`;

export const ChatRecentBotWidgetWrapper = styled("div")`
`;

export const Separator = styled(Divider)`
  margin-top: 16px;
  margin-bottom: 16px;
  border-color: ${brighthiveTheme.palette.custom.border};
`;

export const ChatRecentBotWrapper = styled(Box)`
  background-color: ${brighthiveTheme.palette.settings.lighter};
  overflow: hidden;
  padding: 16px 12px;
  border-radius: 4px;
  width: 100%;
  position: relative;
`;

export const ChatRecentBotHeading = styled(Typography)`
  color: ${brighthiveTheme.palette.grayAccent.light};
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.1px;
  margin-bottom: 2px;
  padding-right: 100px;
`;

export const SuggestionBoxWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 0 0 0 45px;
  margin: 10px 0 0;
  position: relative;
`;

export const SuggestionBoxHeader = styled(Box)`
  display: flex;
  align-items: center;

  .MuiTypography-root {
  }
`;

export const SuggestionBoxText = styled(Typography)`
  text-transform: uppercase;
  font-weight: bold;
  display: inline-flex;
`;

export const SuggestionBoxDescription = styled(Box)`
  max-width: 560px;
  color: ${brighthiveTheme.palette.common.black};
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.25px;
  display: flex;
  padding: 10px 0 0;

  p {
    a {
      color: ${brighthiveTheme.palette.link.main};
      text-decoration: none;

      &:hover {
        color: ${brighthiveTheme.palette.common.black};
      }
    }
  }
`;

export const SuggestionBoxSuggestion = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 10px 0 0 10px;
`;

export const ButtonDefault = styled(Button)`
  color: ${brighthiveTheme.palette.gray.dark};
  border-radius: 3px;
  border: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1px;

  &.small {
    width: 36px;
    height: 36px;
    padding: 0;
  }

  &.icn-large {
    svg {
      width: 26px;
      height: 26px;
    }
  }

  &.active,
  &:hover {
    border-color: ${brighthiveTheme.palette.primary.dark};
    background-color: ${brighthiveTheme.palette.primary.active};
    color: ${brighthiveTheme.palette.primary.dark};
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const ButtonPrimary = styled(Button)`
  background-color: ${brighthiveTheme.palette.primary.main};
  color: ${brighthiveTheme.palette.primary.darkest};
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: none;
  min-width: 1px;

  &:hover {
    background-color: ${brighthiveTheme.palette.primary.dark};
    color: ${brighthiveTheme.palette.common.white};
    border-color: transparent;
    box-shadow: none;
  }
`;

export const ButtonWrapper = styled(Box)`
  display: flex;
  gap: 10px;
  padding: 10px 0;
`;

export const CheckboxBox = styled(Box)`
  display: flex;
  flex-direction: column;

  > div{
    position: relative;
    padding: 7px 0 0 20px;
    color: ${brighthiveTheme.palette.gray.darkest};
  }

  .MuiCheckbox-root{
    position: absolute;
    left: 5px;
    top: 10px;
    padding: 0;
    width: 16px;
    height: 16px;

    input[type='checkbox']{
      z-index: 3;
    }

    "&:hover": {
      background: "none",
    },

    &:after{
      width: 16px;
      height: 16px;
      border-radius: 5px;
      border: 1px solid ${brighthiveTheme.palette.gray.contrastText};
      content: '';
      position: absolute;
      left: 0;
      top: 0;
    }

    &:before{
      content: '';
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      left: 6px;
      top: 2px;
      width: 16px;
      height: 12px;
      opacity: 0;
      z-index: 2;
    }

    &.Mui-checked{
      
      &:before{
        opacity: 1;
        background-image: url(${"data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxOSAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjUgMS42NzM4M0w2LjUgMTIuNjczOEwxLjUgNy42NzM4MyIgc3Ryb2tlPSIjMDA4NTc1IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K"});
        content: '';
      }

      &:after{
        width: 16px;
        height: 16px;
        background: ${brighthiveTheme.palette.primary.active};
        box-shadow: 0 0 1px white inset;
        border-radius: 5px;
        border: 1px solid ${brighthiveTheme.palette.gray.contrastText};
        content: '';
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    .MuiTouchRipple-root{
      display: none;
    }

    svg{
      color: ${brighthiveTheme.palette.gray.contrastText};
      width: 21px;
      height: 21px;
      border-radius: 5px;
      display: none;

      path{
        border-radius: 5px;
      }
    }
  }
`;