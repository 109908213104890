import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const CaretDownViiIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.49766 8.12461L10.731 11.3579L13.9643 8.12461C14.2893 7.79961 14.8143 7.79961 15.1393 8.12461C15.4643 8.44961 15.4643 8.97461 15.1393 9.29961L11.3143 13.1246C10.9893 13.4496 10.4643 13.4496 10.1393 13.1246L6.31432 9.29961C5.98932 8.97461 5.98932 8.44961 6.31432 8.12461C6.63932 7.80794 7.17266 7.79961 7.49766 8.12461Z" fill="currentColor"/>
    </SvgIcon>
  );
};
