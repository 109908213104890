import { GridView } from "@mui/icons-material";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { SquareIcon } from "../SquareIcon/SquareIcon";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { useWorkspaceId } from "../../hooks/useWorkspace";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import {
  GetProjectInfoDocument,
  GetWorkspaceInfoDocument,
} from "../../generated";
import { useQuery } from "@apollo/client";
import { useGetUserRole } from "../../hooks/useGetUserRole";
import { setCurrentPageTitle } from "../../helpers";
import { BackButton, BackIcon, BreadcrumbItem, BreadcrumbsWrapper, ItemIcon, ItemText } from "./style";

export const BreadCrumbs = () => {
  const { palette } = useTheme();
  const { workspaceId, projectId } = useWorkspaceId();
  const { accessible } = useGetUserRole(workspaceId);
  const location = useLocation();
  const navigate = useNavigate();

  const { data } = useQuery(GetWorkspaceInfoDocument, {
    variables: {
      input: { workspaceId: workspaceId || "" },
      accessible: accessible,
    },
  });

  const { data: projectData } = useQuery(GetProjectInfoDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
      projectFilter: {
        projectId: projectId || "",
      },
    },
    skip: !projectId,
  });

  const workspaceName = data?.workspace?.name || "";

  const active = location.pathname
    ?.split(`/workspace/${workspaceId}/`)[1]
    ?.split("/")[0];

  const services =
    active === "services"
      ? location.pathname
          ?.split(`/workspace/${workspaceId}/services/`)[1]
          ?.split("/")[0]
      : null;

  const title = (string: string) => {
    const tempString = string === "project" ? "project" : string;
    const words = tempString.split(" ");

    return words
      .map((word) => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(" ");
  };

  const linkActive = (string: string) => {
    if (active === "project") {
      return "project";
    } else if (active === "services") {
      return "services/transformation";
    } else {
      return active;
    }
  };

  const breadcrumbs = [
    {
      name: workspaceName || "",
      icon: <GridView />,
      redirectTo: `/workspace/${workspaceId}/project`,
    },
    ...(active
      ? [
          {
            name: title(active.replaceAll("-", " ")),
            redirectTo: `/workspace/${workspaceId}/${linkActive(active)}`,
            icon: <FolderOutlinedIcon />,
          },
        ]
      : []),
    ...(projectData?.workspace
      ? [
          {
            name: projectData?.workspace?.projects?.[0]?.name || "",
            redirectTo: `/workspace/${workspaceId}/project/${projectId}`,
            icon: <FolderOutlinedIcon />,
          },
        ]
      : []),

    ...(services
      ? [
          {
            name: title(services.replaceAll("-", " ")),
            redirectTo: `/workspace/${workspaceId}/services/${services}`,
            icon: <FolderOutlinedIcon />,
          },
        ]
      : []),
  ];

  return (
    <BreadcrumbsWrapper className="BreadcrumbsWrapper breadcrumbs">
      <BackButton
        onClick={() => navigate(-1)}
        size="small"
      >
        <BackIcon
          fontSize="small"
        />
      </BackButton>
      {breadcrumbs?.map((breadcrumb) => (
        <BreadcrumbItem
          key={breadcrumb.name}
          onClick={() =>
            breadcrumb.redirectTo && navigate(breadcrumb.redirectTo)
          }
          className={`breadcrumbs-item ${breadcrumb.redirectTo ? "link" : ""}`}
        >
          <ChevronRightOutlinedIcon className="arrow" />
          <ItemIcon
            icon={breadcrumb.icon}
            backgroundColor={palette.settings.main}
            color={palette.settings.contrastText}
            size="small"
          />
          <ItemText
            variant="body3"
          >
            {breadcrumb.name}
          </ItemText>
        </BreadcrumbItem>
      ))}
    </BreadcrumbsWrapper>
  );
};
