import React, { useContext, useState } from "react";
import { Box, Card, Tooltip, Typography, useTheme } from "@mui/material";
import { OfficeBuildingIcon } from "../../common/Icons/OfficeBuildingIcon";
import { SourceOutput } from "../../generated";
import { dateHelper } from "../../helpers";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import ProfileAvatarIcon from "../../common/ProfileAvatarIcon/ProfileAvatarIcon";
import { ResourceHanlder } from "../../common/ResourceHanlder";
import { SchemaCard } from "./SchemaCard";

export const CardView = ({ rowData }: { rowData: any }) => {
  return (
    <Box
      className="ag-theme-material"
      sx={{
        display: "flex",
        flexWrap: "wrap",
        padding: "20px 0",
      }}
    >
      {rowData?.map((row: any) => (
        <SchemaCard schema={row} />
      ))}
    </Box>
  );
};
