import { alpha, Menu as MUIMenu, MenuProps as MUIMenuProps, styled, } from "@mui/material";

export type MenuProps = MUIMenuProps;

export const Menu = styled(MUIMenu)<MenuProps>(({ theme }) => ({
  ".MuiList-root": {
    paddingTop: 0,
    paddingBottom: 0,
  },
  ".MuiMenuItem-root": {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  ".MuiMenuItem-root:focus-visible": {
    border: `.1875rem solid ${theme.palette.primary.main}`,
  },
  ".MuiMenuItem-root.Mui-selected": {
    backgroundColor: alpha(
      theme.palette.grayAccent.main,
      theme.palette.action.activatedOpacity
    ),
  },
  ".MuiMenuItem-root.Mui-selected:hover": {
    backgroundColor: alpha(theme.palette.grayAccent.main, 0.12),
  },
  ".MuiListItemText-primary": {
    ...theme.typography.body2,
    color: theme.palette.common.black,
  },
  ".MuiSvgIcon-root": {
    fontSize: "1rem",
    height: "1rem",
    width: "1rem",
  }
}));
