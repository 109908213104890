import { styled } from "@mui/material";
import React from "react";
import { Button, ButtonProps } from "../Button/Button";

interface SpecificButtonProps {
  icon: React.ReactNode;
  size: "small" | "medium" | "large";
}

export type SquareIconButtonProps = SpecificButtonProps &
  Omit<ButtonProps, "startIcon" | "endIcon">;

const StyledButton = styled(Button)(({ size }) => ({
  padding: 0,
  minWidth: "1.5rem",
  ".MuiButton-startIcon": {
    margin: 0,
  },
  ...(size === "small" && { height: "1.5rem", width: "1.5rem" }),
  ...(size === "medium" && { height: "2rem", width: "2rem" }),
  ...(size === "large" && { height: "2.25rem", width: "2.25rem" }),
  ".MuiButton-startIcon .MuiSvgIcon-root": {
    ...(size === "small" && { fontSize: ".875rem" }),
    ...(size === "medium" && { fontSize: "1.125rem" }),
    ...(size === "large" && { fontSize: "1.3125rem" }),
  },
  // To help align with buttons with outline
  marginTop: ".0625rem",
  marginBottom: ".0625rem",
}));

export const SquareIconButton = (props: SquareIconButtonProps) => {
  return <StyledButton startIcon={props.icon} {...props} />;
};
