import React from "react";
import { Routes, Route } from "react-router-dom";
import { ProtectedRoute } from "../Auth/ProtectedRoute";

// Helper function to recursively generate routes
export const generateRoutes: any = (
  routes: any,
  auth: Boolean = false,
  access: string[]
) => {
  return Object.keys(routes).map((key, index) => {
    const route = routes[key];
    const Element = route.component; // Capture the component to render

    let uAcc = route.access ? route.access : access;

    // Check if there are nested routes
    if (route.routes && Object.keys(route.routes).length > 0) {
      return route.path ? (
        <Route key={route.path} path={route.path}>
          {generateRoutes(route.routes, auth, uAcc)}
        </Route>
      ) : (
        generateRoutes(route.routes, auth, uAcc)
      );
    }

    // Base case: no nested routes
    return (
      <Route
        // index={index === 0}
        key={route.path}
        path={route.path}
        element={
          auth ? (
            <ProtectedRoute access={uAcc || false}>
              <Element showSidenav={route?.showSidenav} />
            </ProtectedRoute>
          ) : (
            <Element showSidenav={route?.showSidenav} />
          )
        }
      />
    );
  });
};
