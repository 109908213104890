import {
  styled,
  Box,
  IconButton,
  Typography,
  Button,
  Tab,
  Tabs,
} from "@mui/material";
import { brighthiveTheme } from "../../theme/theme";
import { Link } from "../../common/Link/Link";

export const ButtonPrimary = styled(Button)`
  background-color: ${brighthiveTheme.palette.primary.main};
  color: ${brighthiveTheme.palette.primary.darkest};
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: none;
  min-width: 1px;

  &:hover {
    background-color: ${brighthiveTheme.palette.primary.dark};
    color: ${brighthiveTheme.palette.common.white};
    border-color: transparent;
    box-shadow: none;
  }

  > svg {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
`;

export const SchemaName = styled(Link)`
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
  font-weight: 700;
  color: ${brighthiveTheme.palette.primary.dark} !important;
`;

export const CustomTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background: ${brighthiveTheme.palette.primary.dark};
  }
`;

export const CustomTab = styled(Tab)`
  justify-content: flex-start;
  border-radius: 6px;
  padding: 14px 10px;
  background-color: transparent;
  color: ${brighthiveTheme.palette.accent.contrastText};

  &:hover,
  &.Mui-selected {
    background-color: transparent !important;
    color: ${brighthiveTheme.palette.primary.dark};
  }

  .MuiTabs-indicator {
    background: ${brighthiveTheme.palette.primary.dark};
  }
`;
