import { Tabs } from "../common/Tabs/Tabs";
import { Box, Button } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Profile } from "./Profile/Profile";
import { Users } from "./Users/Users";
import { MetadataSource } from "./MetadataSources/MetadataSources";
import { DataSource } from "./DataSources/DataSources";
import { Storage } from "./Storage/Storage";
import {
  SidebarLinkButton,
  CustomBox,
  LinksHolder,
  ButtonHolder,
} from "./style";

export const MyOrganization = () => {
  const { workspaceId } = useParams();
  const { search } = useLocation();
  const sp = new URLSearchParams(search);

  const navigate = useNavigate();
  const activeTab = sp.get("tab") || "Profile";
  const tabs = [
    "Profile",
    "Users",
    "Metadata sources",
    "Data sources",
    "Storage",
  ];

  const handleChange = (value: string) => {
    navigate(`/workspace/${workspaceId}/my-organization?tab=${value}`, {
      replace: true,
    });
  };

  return (
    <CustomBox
      display="grid"
      gridTemplateColumns="12rem 1fr"
      gap="1rem"
      className="organization-main-contanier"
    >
      <LinksHolder>
        {tabs.map((item) => {
          const isActive = item === activeTab;
          return (
            <ButtonHolder
              sx={{
                borderColor: (theme) =>
                  ` ${
                    isActive
                      ? theme.palette.primary.dark
                      : theme.palette.background.default
                  }`,
              }}
            >
              <SidebarLinkButton
                onClick={() => handleChange(item)}
                sx={{
                  paddingLeft: "1.2rem",
                  fontWeight: isActive ? 700 : 400,
                  justifyContent: "left",
                  color: (theme) =>
                    isActive
                      ? theme.palette.primary.dark
                      : theme.palette.gray.dark,
                }}
                size="large"
                fullWidth
                role="tab"
              >
                {item}
              </SidebarLinkButton>
            </ButtonHolder>
          );
        })}
      </LinksHolder>
      <Box>
        {activeTab === "Profile" && <Profile />}
        {activeTab === "Users" && <Users />}
        {activeTab === "Metadata sources" && <MetadataSource />}
        {activeTab === "Data sources" && <DataSource />}
        {activeTab === "Storage" && <Storage />}
      </Box>
    </CustomBox>
  );
};
