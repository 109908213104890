import React from "react";
import { Box, Divider, Tooltip, useTheme } from "@mui/material";
import { Sidenav } from "../Sidenav/Sidenav";
import { ArrowBackOutlined, FolderOutlined } from "@mui/icons-material";
import { SquareIconButton } from "../SquareIconButton/SquareIconButton";
import { SidenavButtonLink } from "../Sidenav/SidenavButtonLink";
import { Typography } from "../Typography/Typography";
import { useLocation, useParams } from "react-router-dom";
import { CreatedDataProductIcon } from "../Icons/CreatedDataProductIcon";
import { ProjectOverviewIcon } from "../Icons/ProjectOverview";
import { DataFlowIcon } from "../Icons/DataFlow";
import { SchemaListIcon } from "../Icons/SchemaListIcon";
import { InputDataAssetIcon } from "../Icons/InputDataAssetIcon";
import { useQuery } from "@apollo/client";
import { GetCurrentUserRoleDocument } from "../../generated";
import { useUserRole } from "stream-chat-react";
import { useGetUserRole } from "../../hooks/useGetUserRole";
import { CollapsableNav } from "../SideNavigationPage/CollapsableNav";
import { ProjectNavWrapper, ProjectTopNavWrapper, TopNavMainHeading } from "./style";

export interface ProjectSidenavProps {
  projectName: string;
  workspaceName: string;
  show: boolean;
  setShowSidenav?: any;
}

export const ProjectNavBar = ({
  projectName,
  workspaceName,
  show,
  setShowSidenav,
}: ProjectSidenavProps) => {
  const { workspaceId, projectId, load } = useParams();
  const { pathname } = useLocation();
  const { accessible, loading } = useGetUserRole(workspaceId);

  const { palette } = useTheme();

  return (
    <ProjectTopNavWrapper>
      <TopNavMainHeading
        variant="h5"
        sx={{
          opacity: show ? "0" : "1",
          visibility: show ? "hidden" : "visible",

          "@media (min-width: 992px)": {
            opacity: show ? "1" : "0",
            visibility: show ? "visible" : "hidden",
          },
        }}
      >
        <Tooltip
          placement="bottom"
          title={projectName}
          componentsProps={{
            tooltip: { sx: { backgroundColor: palette.common.black } },
            arrow: { sx: { color: palette.common.black } },
          }}
          arrow
        >
          <span>{projectName}</span>
        </Tooltip>
      </TopNavMainHeading>
      <SidenavButtonLink
        to={`/workspace/${workspaceId}/project/${projectId}/project-overview`}
        icon={<ProjectOverviewIcon />}
        label="Overview"
        active={pathname.includes("overview")}
        className={pathname.includes("project-overview") ? "active tabLink" : " tabLink"}
      />
      {accessible && (
        <>
          <SidenavButtonLink
            active={pathname.includes("project-schema")}
            to={`/workspace/${workspaceId}/project/${projectId}/project-schema`}
            icon={<SchemaListIcon />}
            label="Schemas"
            className={pathname.includes("project-schema") ? "active tabLink" : " tabLink"}
          />
          <SidenavButtonLink
            active={pathname.includes("project-workflow")}
            to={`/workspace/${workspaceId}/project/${projectId}/project-workflow`}
            icon={<DataFlowIcon sx={{ transform: "rotate(90deg)" }} />}
            label="Flow"
            className={pathname.includes("project-workflow") ? "active tabLink" : " tabLink"}
          />
        </>
      )}
      <SidenavButtonLink
        active={pathname.includes("data-assets")}
        to={`/workspace/${workspaceId}/project/${projectId}/data-assets`}
        icon={<InputDataAssetIcon />}
        label={accessible ? "Input Data Assets" : "My Data"}
        className={pathname.includes("data-assets") ? "active tabLink" : " tabLink"}
      />
      {accessible && (
        <>
          <SidenavButtonLink
            active={pathname.includes("created-data-products")}
            to={`/workspace/${workspaceId}/project/${projectId}/created-data-products`}
            icon={<CreatedDataProductIcon />}
            label="Data Products"
            className={
              pathname.includes("created-data-products") ? "active tabLink" : " tabLink"
            }
          />
        </>
      )}
    </ProjectTopNavWrapper>
  );
};
