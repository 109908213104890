import { Box, Divider } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import BusinessIcon from "@mui/icons-material/Business";
import { DOCLink, Link } from "../../common/Link/Link";
import { IconCell } from "../CellRenderer/IconCell";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { SyncCell } from "../CellRenderer/SyncCell";
import { LocationCell } from "../CellRenderer/LocationCell";

interface MetadataConfigurationProps {
  location?: string;
}

export const MetadataConfiguration = ({
  location,
}: MetadataConfigurationProps) => {
  const description =
    "Metadata is collected for your organization data from your organization’s dedicated data stack used to staged data for sharing to workspaces.";
  const owner = "Brighthive";
  const contactEmail = "support@brigthhive.io";

  return (
    <Box>
      <Box>
        <Typography gutterBottom variant="subtitle3">
          Description
        </Typography>
        <Typography variant="body2" color="gray.darkest">
          {description}
        </Typography>
      </Box>

      <Box mt={5}>
        <Typography gutterBottom variant="subtitle3">
          Connection owner
        </Typography>
        <Typography variant="body2" color="gray.darkest">
          <Box display="flex" gap={2} alignItems="center">
            <BusinessIcon color="disabled" fontSize="small" /> {owner}
          </Box>
        </Typography>
      </Box>

      <Box mt={5}>
        <Typography gutterBottom variant="subtitle3">
          Point of contact
        </Typography>
        <Link sx={{ fontWeight: "bold" }} to={`mailto:${contactEmail}`}>
          {contactEmail}
        </Link>
      </Box>

      <Box mt={5}>
        <Divider />
      </Box>

      {location && (
        <Box mt={5}>
          <Typography gutterBottom variant="subtitle3">
            Location
          </Typography>
          <Typography variant="body2" color="gray.darkest">
            <Box display="flex" gap={2} alignItems="center">
              <LocationCell value={location} /> {location}
            </Box>
          </Typography>
        </Box>
      )}
      <Box mt={5}>
        <Typography gutterBottom variant="subtitle3">
          Service type
        </Typography>
        <Typography variant="body2" color="gray.darkest">
          <Box display="flex" gap={2} alignItems="center">
            <IconCell value="GlueDataCatalog" /> Glue Data Catalog
          </Box>
        </Typography>
      </Box>

      <Box mt={5}>
        <Typography gutterBottom variant="subtitle3">
          Cloud provider
        </Typography>
        <Typography variant="body2" color="gray.darkest">
          <Box display="flex" gap={2} alignItems="center">
            <IconCell value="AWS" /> AWS
          </Box>
        </Typography>
      </Box>

      {/* <Box mt={5}>
        <Typography gutterBottom variant="subtitle3">
          Username
        </Typography>
        <Typography variant="body2" color="gray.darkest">
          BRIGHTHIVE_USER
        </Typography>
        <Typography variant="body2" color="gray.darkest">
          <Box display="flex" gap={2} alignItems="center">
            <InfoOutlined color="disabled" fontSize="small" /> Authenticated
          </Box>
        </Typography>
      </Box> */}

      <Box mt={5}>
        <Typography gutterBottom variant="subtitle3">
          Sync
        </Typography>
        <SyncCell value="Auto" />
      </Box>

      {/* <Box mt={5}>
        <Typography gutterBottom variant="subtitle3">
          Endpoint name
        </Typography>
        <Typography variant="body2" color="gray.darkest">
          Endpoint name goes here (base-name)
        </Typography>
        <DOCLink href="/" name="View endpoint" />
      </Box> */}

      <Box mt={5}>
        <Typography gutterBottom variant="subtitle3">
          Access
        </Typography>
        <Typography variant="body2" color="gray.darkest">
          Full Access
        </Typography>
        <Typography variant="body3" color="gray.darkest">
          Read - Metadata only
        </Typography>
      </Box>

      {/* <Box mt={5} bgcolor={palette.settings.lighter} borderRadius={1} p={4}>
        <Typography mb={2} variant="subtitle2">
          Table access
        </Typography>
        <Typography variant="subtitle3">Included</Typography>
        <Typography variant="body2">table_name_1</Typography>
        <Typography variant="body2">table_name_2</Typography>

        <Box mt={4} />
        <Typography variant="subtitle3">Excluded</Typography>
        <Typography variant="body2">table_name_1</Typography>
        <Typography variant="body2">table_name_2</Typography>
      </Box> */}
    </Box>
  );
};
