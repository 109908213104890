import React from "react";
import { Box, Grid, Paper, useTheme } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { Button } from "../../common/Button/Button";
import { Alert } from "../../common/Alert";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { DestinationDownload } from "../../common/Icons/DestinationDownload";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { WorkflowFinalDataProductGroupOutput } from "../../generated";
import { DeepPartial, getAlertMessage } from "../helpers";
import { useParams } from "react-router-dom";
import { ViewSchema } from "../../common/ViewSchema/ViewSchema";

export const DataProductSchemaSetDetails = ({
  issueType,
  data,
}: {
  issueType?: any;
  data?: DeepPartial<WorkflowFinalDataProductGroupOutput>;
}) => {
  const { palette } = useTheme();
  const [isEdited, setisEdited] = React.useState("Major issue");
  const { projectId, workspaceId } = useParams();

  return (
    <Box boxSizing="border-box">
      <Box
        sx={{
          paddingBottom: "16px",
          borderBottom: `1px solid ${palette.outline}`,
        }}
        mb={6}
      >
        {getAlertMessage(
          issueType,
          "An entity managed by this organization is preventing the data flow from running."
        )}
        <Typography
          mb={2}
          variant="h5"
          color={
            data && data.description ? palette.gray.darkest : palette.gray.dark
          }
          sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: "normal" }}
        >
          {data && data.description ? data.description : "None"}
        </Typography>
      </Box>

      <Box mb={6}>
        <Typography
          mb={4}
          variant="h5"
          color={palette.common.black}
          sx={{ fontSize: "18px", lineHeight: "24px", fontWeight: "normal" }}
        >
          Data product target schema
        </Typography>
        <Box mb={6}>
          <Grid container>
            {data?.targetSchema ? (
              <>
                <ViewSchema
                  name={data?.targetSchema?.name}
                  modifiedAt={data?.targetSchema?.modifiedAt}
                  jsonSchema={data?.targetSchema?.jsonSchema}
                />

                <Button
                  sx={{
                    color: palette.link.main,
                    background: "none !important",
                    padding: "0",
                  }}
                  href={`/workspace/${workspaceId}/project/${projectId}/project-schema/${data?.targetSchema?.id}`}
                >
                  View schema
                </Button>
              </>
            ) : (
              <Typography
                mb={2}
                variant="h5"
                color={palette.error.dark}
                sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400" }}
              >
                <InfoOutlinedIcon
                  sx={{
                    fontSize: "16px",
                    lineHeight: "20px",
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginRight: "3px",
                    marginBottom: "3px",
                    color: palette.error.dark,
                  }}
                />
                No schema defined
              </Typography>
            )}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
