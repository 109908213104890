import { useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { AGGridWrapper } from "../../../common/AGGridWrapper/AGGridWrapper";
import { convertToTitleCase } from "../../../helpers";
import { StatusCell } from "../CellRenderer/StatusCell";
import { ICellRendererParams, ValueGetterParams } from "ag-grid-community";
import { Tag } from "../../../common/Tag/Tag";
import { getSource } from "../../sourceIcons";
import { FilterOperator } from "../../../common/AGGridWrapper/gridHelpers";

export const GridView = ({
  setDetail,
  rowData,
}: {
  setDetail: Function;
  rowData: any;
}) => {
  const { palette } = useTheme();
  const columns = [
    {
      field: "name",
      headerName: "Name",
      cellRenderer: (params: any) => {
        const item = params.data;
        return (
          <Box
            display="flex"
            alignItems="center"
            sx={{
              ".name": {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }}
          >
            <Box
              sx={{
                svg: {
                  width: "24px",
                  height: "24px",
                  verticalAlign: "top",
                },
              }}
            >
              {item?.logoUrl.includes("<svg") ? (
                <div className="source-logo">
                  <img
                    src={`data:image/svg+xml;base64,${window.btoa(
                      item.logoUrl
                    )}`}
                    width="24"
                    height="24"
                  />
                </div>
              ) : (
                <div className="source-logo">
                  <img src={item?.logoUrl} width="24" height="24" />
                </div>
              )}
            </Box>
            <Typography
              fontWeight="bold"
              ml={3}
              variant="body3"
              className="name"
            >
              {item.name}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: (params: any) => {
        const item = params.data;
        return <StatusCell value={item.status} />;
      },
    },
    {
      field: "sourceType",
      headerName: "Type",
      cellRenderer: (params: any) => {
        const item = params.data;
        return (
          <Box display="flex" alignItems="center">
            <Box
              sx={{
                display: "inline-flex",
                width: "20px",
                verticalAlign: "middle",
                marginRight: "10px",
                svg: {
                  width: "20px",
                  height: "20px",
                  color: palette.grayAccent.light,
                },
              }}
            >
              {getSource(item.sourceType)}
            </Box>
            {convertToTitleCase(item.sourceType)}
          </Box>
        );
      },
      headerComponentParams: {
        description: "The status of the data asset.",
        filterOperators: [FilterOperator.IS_ANY_OF, FilterOperator.IS_NONE_OF],
      },
    },
    {
      field: "dataAssets",
      headerName: "Data Asset",
    },
    {
      field: "owner",
      headerName: "Owner",
      valueGetter: (params: ValueGetterParams) => {
        return params.data.owner?.name;
      },
      headerComponentParams: {
        description: "The owner of the data asset.",
        filterOperators: [FilterOperator.IS_ANY_OF, FilterOperator.IS_NONE_OF],
      },
    },
    {
      field: "description",
      headerName: "Description",
      cellRenderer: (params: ICellRendererParams) => {
        const item = params.data;
        return (
          <Box
            display="flex"
            alignItems="center"
            flexWrap="nowrap"
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              display: "inline-block",
              textOverflow: "ellipsis",
            }}
          >
            {item.description}
          </Box>
        );
      },
    },
    {
      field: "tags",
      headerName: "Tags",
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value === null || params.value === undefined) return null;
        const items = (params.value as string[]) || [];
        return (
          <Box display="flex" alignItems="center" flexWrap="nowrap" gap={1}>
            {items.map((item) => (
              <Tag label={item} dismissable={false} />
            ))}
          </Box>
        );
      },
    },
  ];

  return (
    <Box
      className="ag-theme-material"
      sx={{
        ".ag-theme-material .ag-overlay-no-rows-wrapper.ag-layout-auto-height":
          {
            paddingTop: "0 !important",
          },
        ".ag-row .ag-cell.ag-cell-not-inline-editing.ag-cell-value > div.MuiBox-root":
          {
            flex: "1",
            maxWidth: "100%",
          },
        ".ag-row .ag-cell.ag-cell-not-inline-editing.ag-cell-value p": {
          maxWidth: "calc(100% - 36px)",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      }}
    >
      <AGGridWrapper
        // loading={loading}
        rowData={rowData}
        columnDefs={columns}
        defaultColDef={{
          sortable: true,
          flex: 1,
          minWidth: 200,
        }}
        getRowHeight={(params) => (params.node.group ? 32 : 62)}
        groupDefaultExpanded={1}
        domLayout="autoHeight"
        onRowClicked={(params) => {
          setDetail(params.data);
        }}
      />
    </Box>
  );
};
