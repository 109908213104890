import React from "react";
import { Chip, ChipProps } from "../Chip/Chip";
import { alpha, Box, useTheme } from "@mui/material";
import { CheckMarkIcon } from "../Icons/CheckMarkIcon";

interface SpecificChipFilterProps {
  checked?: boolean;
  amount?: number | string;
  badgeVariant: "gray" | "error";
}

export type ChipFilterProps = SpecificChipFilterProps &
  Omit<ChipProps, "deleteIcon">;

export const ChipFilter = ({
  sx,
  checked,
  amount,
  badgeVariant,
  disabled,
  label,
  ...rest
}: ChipFilterProps) => {
  const theme = useTheme();

  let badgeBgColor = theme.palette.grayAccent.main;
  let badgeTextColor = theme.palette.common.white;
  if (disabled) {
    badgeBgColor = alpha(theme.palette.grayAccent.main, 0.16);
    badgeTextColor = theme.palette.common.black;
  } else if (badgeVariant === "error") {
    badgeBgColor = theme.palette.error.light;
    badgeTextColor = theme.palette.common.black;
  }

  const endIcon =
    typeof amount === "number" || typeof amount === "string" ? (
      <Box
        textAlign="center"
        display="inline-block"
        bgcolor={`${badgeBgColor} !important`}
        color={`${badgeTextColor} !important`}
        fontSize=".75rem !important"
        lineHeight="1rem !important"
        letterSpacing=".01em !important"
        fontWeight={500}
        borderRadius="999px"
        p=".25rem .125rem !important"
        ml={1}
        minWidth="1.25rem"
      >
        {amount}
      </Box>
    ) : undefined;

  return (
    <Chip
      role="button"
      tabIndex={disabled ? -1 : 1}
      size="small"
      avatar={
        checked ? (
          <CheckMarkIcon
            id={`chip-filter-check-mark`}
            sx={{
              height: "1.25rem !important",
              width: "1.25rem !important",
              ...(disabled && {
                color: `${theme.palette.action.disabled} !important`,
              }),
            }}
          />
        ) : undefined
      }
      sx={{
        opacity: "1 !important",
        backgroundColor: "transparent",
        border: `.0625rem solid ${theme.palette.outline}`,
        ...(!checked && { color: theme.palette.gray.darkest }),
        ...(checked && {
          color: theme.palette.common.black,
          backgroundColor: alpha(theme.palette.grayAccent.main, 0.06),
        }),
        ...(disabled && {
          color: theme.palette.action.disabled,
          backgroundColor: "transparent",
          pointerEvent: "none",
        }),
        ...(!disabled && {
          cursor: "pointer",
        }),
        pt: ".375rem",
        pb: ".375rem",
        pl: ".75rem",
        pr: ".75rem",
        ...theme.typography.body3,
        "&:hover": {
          backgroundColor: alpha(theme.palette.grayAccent.main, 0.06),
        },
        // "&:focus": {
        //   backgroundColor: alpha(theme.palette.grayAccent.main, 0.16),
        // },
        ".MuiChip-avatar": {
          backgroundColor: "transparent",
        },
        ...sx,
      }}
      disabled={disabled}
      label={
        <>
          {label}
          {endIcon}
        </>
      }
      {...rest}
    />
  );
};

ChipFilter.defaultProps = {
  badgeVariant: "gray",
};
