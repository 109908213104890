import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { AppContext } from "../App";
import { SideNavigationPage } from "../common/SideNavigationPage/SideNavigationPage";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SettingContent } from "./SettingContent";
import { WorkspaceSettingNav } from "../common/WorkspaceSettingPage/WorkspaceSettingNav";

export const WorkspaceSettingsPage = () => {
  const navigate = useNavigate();

  const [openAdd, setOpenAdd] = useState(false);
  const { projectId, workspaceId } = useParams();
  const { palette } = useTheme();
  const location = useLocation();

  const active = location.pathname
    ?.split(`/workspace/${workspaceId}/`)[1]
    ?.split("/")[0];

  const source =
    active === "sources"
      ? location.pathname
          ?.split(`/workspace/${workspaceId}/sources/`)[1]
          ?.split("/")[0]
      : null;

  return (
    <AppContext.Consumer>
      {({ showSidenav, setShowSidenav }: any) => (
        <>
          <SideNavigationPage
            className="sources-view workspace-settings"
            innerSideBar
            leftHeaderElements={
              <Box
                sx={{
                  textTransform: "capitalize",
                }}
              >
                <Typography variant="h5">General</Typography>
                <WorkspaceSettingNav
                  show={showSidenav}
                  workspaceName={"workspace Name"}
                  setShowSidenav={setShowSidenav}
                />
              </Box>
            }
            verticalNav={<></>}
            content={<SettingContent />}
          />
        </>
      )}
    </AppContext.Consumer>
  );
};
