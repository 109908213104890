import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const SourcesIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_961_71114)">
        <path
          d="M4.5 16V14.5C4.5 14.1022 4.65804 13.7206 4.93934 13.4393C5.22064 13.158 5.60218 13 6 13H18C18.3978 13 18.7794 13.158 19.0607 13.4393C19.342 13.7206 19.5 14.1022 19.5 14.5V16M12 8.5V13M2.25 16H6.75C7.57843 16 8.25 16.6716 8.25 17.5V22C8.25 22.8284 7.57843 23.5 6.75 23.5H2.25C1.42157 23.5 0.75 22.8284 0.75 22V17.5C0.75 16.6716 1.42157 16 2.25 16ZM9.75 1H14.25C15.0784 1 15.75 1.67157 15.75 2.5V7C15.75 7.82843 15.0784 8.5 14.25 8.5H9.75C8.92157 8.5 8.25 7.82843 8.25 7V2.5C8.25 1.67157 8.92157 1 9.75 1ZM17.25 16H21.75C22.5784 16 23.25 16.6716 23.25 17.5V22C23.25 22.8284 22.5784 23.5 21.75 23.5H17.25C16.4216 23.5 15.75 22.8284 15.75 22V17.5C15.75 16.6716 16.4216 16 17.25 16Z"
          stroke="currentColor"
          fill="none"
          strokeOpacity="1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_961_71114">
          <rect width="24" height="24" fill="currentColor" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
