import React, { useState } from "react";
import { Box, Chip, Popover, Typography, useTheme } from "@mui/material";
import { TypographyProps } from "../Typography/Typography";
import { LeftRightContent } from "../LeftRightContent/LeftRightContent";
import { LinesEllipsisTypography } from "../LinesEllipsisTypography/LinesEllipsisTypography";
import { values } from "lodash";
import { Button } from "../Button/Button";

export interface ProjectCardDataRowProps {
  icon?: React.ReactNode;
  name: string;
  value?: string | number | any;
  valueContainerProps?: TypographyProps;
}

export const ProjectTagsDataRow = ({
  name,
  value,
  icon,
  valueContainerProps,
}: ProjectCardDataRowProps) => {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <LeftRightContent
      flexGrow="initial"
      mb={2}
      alignItems="initial"
      leftContainerProps={{ width: "50%", alignItems: "initial" }}
      rightContainerProps={{
        width: "50%",
        alignItems: "initial",
        justifyContent: "flex-end",
      }}
      leftElements={
        <Box
          display="flex"
          sx={{
            ".MuiSvgIcon-root": {
              fontSize: ".875rem",
              mt: ".1875rem",
              mr: 1,
              color: palette.grayAccent.light,
            },
          }}
        >
          {icon}
          <LinesEllipsisTypography
            color={palette.common.black}
            variant="body3"
            fontWeight={500}
            text={`${name}:`}
            maxLines={2}
          />
        </Box>
      }
      rightElements={
        <Box display="flex">
          {value && value?.length > 1 ? (
            <div>
              <span
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <Chip label={value[0]} />
                <Typography variant="body3" sx={{ padding: "5px 0 5px 5px" }}>
                  +{value?.length - 1}
                </Typography>
              </span>
              <Popover
                id="mouse-over-popover"
                sx={{ pointerEvents: "none" }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Box p={2}>
                  {value?.map((tag: any) => (
                    <Chip
                      sx={{
                        mr: 1,
                        backgroundColor: palette.grayAccent.lighter,
                        color: palette.common.black,
                      }}
                      size="small"
                      color={"gray"}
                      label={tag}
                    />
                  ))}
                </Box>
              </Popover>
            </div>
          ) : value?.length > 0 ? (
            <Chip
              sx={{
                mr: 1,
                backgroundColor: palette.grayAccent.lighter,
                color: palette.common.black,
              }}
              size="small"
              color={"gray"}
              label={value[0]}
            />
          ) : (
            <Typography variant="body3">No Tags Added</Typography>
          )}
        </Box>
      }
    />
  );
};
