import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const Internal = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.33337 6.66659V4.99992C3.33337 4.55789 3.50897 4.13397 3.82153 3.82141C4.13409 3.50885 4.55801 3.33325 5.00004 3.33325H6.66671M3.33337 13.3333V14.9999C3.33337 15.4419 3.50897 15.8659 3.82153 16.1784C4.13409 16.491 4.55801 16.6666 5.00004 16.6666H6.66671M13.3334 3.33325H15C15.4421 3.33325 15.866 3.50885 16.1786 3.82141C16.4911 4.13397 16.6667 4.55789 16.6667 4.99992V6.66659M13.3334 16.6666H15C15.4421 16.6666 15.866 16.491 16.1786 16.1784C16.4911 15.8659 16.6667 15.4419 16.6667 14.9999V13.3333M10 10.4166L13.3334 8.33325M10 10.4166L6.66671 8.33325L10 6.24992L13.3334 8.33325M10 10.4166V14.1666L13.3334 12.0833V8.33325"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66675 8.33325V12.0833L10.0001 14.1666"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
