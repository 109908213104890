import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const CreatedAssetIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M19 2C20.6569 2 22 3.34315 22 5C22 6.30588 21.1656 7.41688 20.0009 7.82898V16.171C21.1656 16.5831 22 17.6941 22 19C22 20.6569 20.6569 22 19 22C17.6941 22 16.5831 21.1656 16.171 20.0009H7.82898C7.41688 21.1656 6.30588 22 5 22C3.34315 22 2 20.6569 2 19C2 17.6937 2.83485 16.5825 4.00009 16.1707V7.82933C2.83485 7.41751 2 6.30625 2 5C2 3.34315 3.34315 2 5 2C6.30625 2 7.41751 2.83485 7.82933 4.00009H16.1707C16.5825 2.83485 17.6937 2 19 2ZM5 18C4.44772 18 4 18.4477 4 19C4 19.5523 4.44772 20 5 20C5.55228 20 6 19.5523 6 19C6 18.4477 5.55228 18 5 18ZM19 18C18.4477 18 18 18.4477 18 19C18 19.5523 18.4477 20 19 20C19.5523 20 20 19.5523 20 19C20 18.4477 19.5523 18 19 18ZM16.171 6.0009H7.82898C7.52764 6.85259 6.85259 7.52764 6.0009 7.82898V16.171C6.85292 16.4725 7.52816 17.1479 7.82933 18.0001H16.1707C16.472 17.1476 17.1476 16.472 18.0001 16.1707V7.82933C17.1479 7.52816 16.4725 6.85292 16.171 6.0009ZM5 4C4.44772 4 4 4.44772 4 5C4 5.55228 4.44772 6 5 6C5.55228 6 6 5.55228 6 5C6 4.44772 5.55228 4 5 4ZM19 4C18.4477 4 18 4.44772 18 5C18 5.55228 18.4477 6 19 6C19.5523 6 20 5.55228 20 5C20 4.44772 19.5523 4 19 4Z" />
    </SvgIcon>
  );
};
