import { styled, Box, Typography } from "@mui/material";
import { brighthiveTheme } from "../../../theme/theme";

export const AddSchemaButton = styled(Box)`
  display: flex;

  &.inside-drop {
    padding: 5px 10px;
    /* border-top: 1px solid
      ${brighthiveTheme.palette.action.disabledBackground}; */

    button {
      /* width: 100%; */
      background: none;
      box-shadow: none;
      padding: 5px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
      color: ${brighthiveTheme.palette.accent.contrastText};

      &:hover {
        color: ${brighthiveTheme.palette.primary.dark};
      }
    }
  }
`;
