import React from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { CompanyIcon } from "../../common/Icons/CompanyIcon";
import { Typography } from "../../common/Typography/Typography";
import { BasicCard } from "../../common/BasicCard/BasicCard";
import { Label } from "../../common/Label/Label";
import { GetParticipatingOrganizationsDocument } from "../../generated";

export const AdditionalDetails = ({ ...rest }) => {
  const { workspaceId } = useParams();

  const { data } = useQuery(GetParticipatingOrganizationsDocument, {
    variables: { input: { workspaceId: workspaceId || "" } },
  });

  const workspaceOwner = data?.workspace?.owner;
  const participatingOrganizations =
    data?.workspace?.participatingOrganizations;

  return (
    <BasicCard {...rest}>
      <Box sx={{ padding: "1rem 1.5rem" }}>
        <Typography variant={"h6"}>Approved organizations</Typography>
      </Box>
      <Box sx={{ padding: "0 1.5rem 1.5rem 1.5rem" }}>
        <Label value="Workspace Owner" fontSize={"medium"} />
        <Box display="flex" mt={1}>
          <CompanyIcon />
          <Typography pl={".375rem"} variant="body2">
            {workspaceOwner?.name}
          </Typography>
        </Box>
        <Label value="Collaborating organizations" mt={4} fontSize={"medium"} />
        {Array.isArray(participatingOrganizations) &&
          participatingOrganizations.map((organization) => (
            <Box key={organization?.id} display="flex" mt={1}>
              <CompanyIcon />
              <Typography pl={".375rem"} variant="body2">
                {organization?.name}
              </Typography>
            </Box>
          ))}
      </Box>
      <Box sx={{ padding: "0 1.5rem 1.5rem 1.5rem" }}>
        <Typography variant="body2">
          To add or remove an organization from the workspace,
          <Box
            component="a"
            sx={{
              fontWeight: "bold",
              color: (t) => t.palette.primary.dark,
              ml: 1,
            }}
            href="mailto:support@brighthive.io?subject=Add/Remove Organization from Workspace"
          >
            please contact your Brighthive account manager.
          </Box>
        </Typography>
      </Box>
    </BasicCard>
  );
};
