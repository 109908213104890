import React from "react";
import { StyledCloseIcon, StyledDrawer } from "./style";

const anchor = "right";
const RightSidePanel = ({
  isOpen,
  onToggle,
  children,
}: {
  isOpen: boolean;
  onToggle: () => void;
  children: React.ReactNode;
}) => {
  return (
    <React.Fragment key={anchor}>
      <StyledDrawer anchor={anchor} open={isOpen} onClose={onToggle}>
        {children}
        <StyledCloseIcon onClick={onToggle} />
      </StyledDrawer>
    </React.Fragment>
  );
};

export default RightSidePanel;
