import { Box, Typography, useTheme } from "@mui/material";

import { CreatedDataProductsGrid } from "./CreatedDataProductsGrid";
import { ProjectPage } from "../../common/ProjectPage/ProjectPage";

export const CreatedDataProductsPage = () => {
  const { palette } = useTheme();

  return (
    <>
      <ProjectPage
        leftHeaderElements={
          <Typography variant="h5">Created Data Products</Typography>
        }
        content={
          <Box
            bgcolor={palette.common.white}
            display="flex"
            flexDirection="column"
            boxSizing="border-box"
            flexGrow={1}
            border={`0.0625rem solid ${palette.outline}`}
            borderRadius="0.375rem"
          >
            <CreatedDataProductsGrid />
          </Box>
        }
      />
    </>
  );
};
