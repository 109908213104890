import React, { useState } from "react";
import { Box, BoxProps, useTheme } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GetWorkspaceInfoDocument } from "../../generated";
import { useWorkspaceId } from "../../hooks/useWorkspace";
import { WorkspaceSidenav } from "../WorkspaceSidenav/WorkspaceSidenav";
import { useGetUserRole } from "../../hooks/useGetUserRole";
import { SideBarV2 } from "../SideBarV2";
import { SideBarV3 } from "../SideBarV3";

export interface SideNavBarProbs {
  content?: React.ReactNode;
  leftHeaderElements?: React.ReactNode;
  rightHeaderElements?: React.ReactNode;
  collapse: boolean;
  setCollapse: Function;
}

export const SideNavBar = ({
  content,
  leftHeaderElements,
  rightHeaderElements,
  collapse,
  setCollapse,
  ...rest
}: SideNavBarProbs & BoxProps) => {
  const { workspaceId } = useWorkspaceId();
  const { accessible } = useGetUserRole(workspaceId);
  const { data } = useQuery(GetWorkspaceInfoDocument, {
    variables: {
      input: { workspaceId: workspaceId || "" },
      accessible: accessible,
    },
  });
  const workspaceName = data?.workspace?.name || "";
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        background: palette.common.white,
        height: "100vh",
        overflowY: "auto",
        boxSizing: "border-box",
      }}
      display="flex"
      flexDirection="column"
      {...rest}
    >
      {rest.children}
      <Box
        sx={{ background: palette.common.white }}
        height="100%"
        width="100%"
        display="flex"
        flexDirection="row"
      >
        <Box
          sx={{
            position: "fixed",
            top: 0,
            bottom: 0,
            background: palette.common.white,
            boxShadow: `inset 0px -1px 0px ${palette.action.disabledBackground}`,
            width: collapse ? "61px" : "260px",
            transition: "all ease-in-out 0.25s",
            overflowX: "hidden",
            overflowY: "auto",
            zIndex: "999",
            borderRight: collapse
              ? `1px solid transparent`
              : `1px solid ${palette.action.disabledBackground}`,

            "@media (min-width: 768px)": {
              width: collapse ? "260px" : "61px",
              overflowX: "hidden",
              overflowY: "auto",
              // zIndex: "auto",
            },
          }}
          height={"100vh"}
        >
          {/* <WorkspaceSidenav
            workspaceName={workspaceName}
            collapse={collapse}
            setCollapse={setCollapse}
          /> */}
          {/* <SideBarV2
            workspaceName={workspaceName}
            collapse={collapse}
            setCollapse={setCollapse}
          /> */}

          <SideBarV3
            workspaceName={workspaceName}
            collapse={collapse}
            setCollapse={setCollapse}
          />
        </Box>
        <Box
          sx={{
            paddingLeft: "61px",
            paddingTop: "48px",
            transition: "all ease-in-out 0.25s",

            "@media (min-width: 768px)": {
              paddingLeft: collapse ? "260px" : "61px",
            },
          }}
          width="100%"
        >
          {content}
        </Box>
      </Box>
    </Box>
  );
};
