import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { Chip } from "../../common/Chip/Chip";
import PersonIcon from "@mui/icons-material/Person";
import { InputSchemaSetDetails } from "./InputSchemaSetDetails";
import { EditInputSchemaSetDetails } from "./EditInputSchemaSetDetails";
import { Button } from "../../common/Button/Button";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GetInputSchemaSetDocument } from "../../generated";
import { getLoader } from "../helpers";
import { SheetTabs } from "../SheetTabs";
import { SheetChat } from "../SheetChat";

export const InputSchemaSet = ({
  id,
  showToast,
}: {
  id: string;
  showToast: any;
}) => {
  const { palette } = useTheme();
  const { workspaceId, projectId } = useParams();
  const [isEditState, setIsEditState] = useState(false);
  const [isEditable, setIsEditable] = useState(true);
  const [tab, setTab] = useState(0);

  const toggleEditView = () => {
    setIsEditState(!isEditState);
    setIsEditable(!isEditable);
  };
  const { data, loading } = useQuery(GetInputSchemaSetDocument, {
    variables: {
      workflowFilterInput: {
        projectId: projectId || "",
        workspaceId: workspaceId || "",
      },
      inputDataAssetGroupFilter: {
        inputDataAssetGroupId: id,
      },
    },
  });
  if (loading) return getLoader("Fetching Data", "custom-loader full-height");
  const inputSchemaAsset = data?.workflow?.inputDataAssetGroups?.[0];
  if (inputSchemaAsset) {
    return (
      <Box
        boxSizing="border-box"
        sx={{
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            paddingRight: "20px",
            paddingBottom: "16px",
            borderBottom: `1px solid ${palette.outline}`,
          }}
          mb={6}
        >
          <Typography
            mb={2}
            variant="h5"
            color={palette.gray.dark}
            sx={{
              fontSize: "12px",
              lineHeight: "14px",
              textTransform: "uppercase",
              fontWeight: "700",
            }}
          >
            Input Data Asset Group
          </Typography>
          <Typography
            mb={4}
            variant="h5"
            color={palette.common.black}
            sx={{ wordBreak: "break-word" }}
          >
            {inputSchemaAsset.name}
          </Typography>

          {inputSchemaAsset.pii && (
            <Chip
              icon={
                <PersonIcon
                  sx={{
                    fontSize: "1rem",
                    color: palette.common.black + "!important",
                  }}
                />
              }
              color="gray"
              label="Contains PII"
              sx={{
                color: palette.common.black,
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: "500",
                background: "none",
                border: `1px solid ${palette.grayAccent.light}`,
                marginLeft: "8px",
              }}
            />
          )}

          <SheetTabs value={tab} onChange={setTab} />
        </Box>

        {tab === 0 && (
          <>
            {isEditState ? (
              <EditInputSchemaSetDetails
                toggleEditView={toggleEditView}
                inputSchemaAsset={inputSchemaAsset}
                showToast={showToast}
              />
            ) : (
              <InputSchemaSetDetails
                issueType={inputSchemaAsset.issueStatus}
                data={inputSchemaAsset}
              />
            )}

            {isEditable && !isEditState && (
              <Box
                sx={{
                  marginTop: "auto",
                }}
              >
                <Button
                  color="grayAccent"
                  variant="contained"
                  sx={{ width: "100%" }}
                  onClick={toggleEditView}
                >
                  Edit
                </Button>
              </Box>
            )}
          </>
        )}

        {tab === 1 && <SheetChat id={id} />}
      </Box>
    );
  }
  return null;
};
