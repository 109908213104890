import {
  styled,
  Button,
  Popover,
  Theme,
  Box,
  Typography,
  Modal,
  Card,
} from "@mui/material";
import { brighthiveTheme } from "../../theme/theme";
import { BasicCard } from "../../common/BasicCard/BasicCard";

export const ContentCardWrapper = styled(`div`)`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const ActionBarWrapper = styled(`div`)`
  background-color: ${brighthiveTheme.palette.common.white};
  border: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
  margin-bottom: 32px;
  border-radius: 6px;
`;

export const ButtonDefault = styled(Button)`
  color: ${brighthiveTheme.palette.gray.dark};
  border-radius: 3px;
  border: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1px;

  &.small {
    width: 36px;
    height: 36px;
    padding: 0;
  }

  &.icn-large {
    svg {
      width: 26px;
      height: 26px;
    }
  }

  &.active,
  &:hover {
    border-color: ${brighthiveTheme.palette.primary.dark};
    background-color: ${brighthiveTheme.palette.primary.active};
    color: ${brighthiveTheme.palette.primary.dark};
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const ButtonLink = styled(Button)`
  padding: 0;
  min-width: 0;
  color: ${brighthiveTheme.palette.accent.darkest};
  background-color: transparent;

  &:hover {
    color: ${brighthiveTheme.palette.accent.darkest};
    background-color: transparent;
    text-decoration: underline;
  }
`;

export const StyledPopover = styled(Popover)(({ theme }) => ({
  ".MuiPaper-root": {
    background: theme.palette.common.white,
    border: "0",
    boxShadow: `0 2px 2px ${theme.palette.gray.main}, 0 3px 1px ${theme.palette.action.disabledBackground}, 0 1px 5px ${theme.palette.action.disabled}`,
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    padding: "0",
    width: "246px",
  },
  ".MuiPaper-elevation > .MuiButtonBase-root": {
    padding: "12px 19px",
    textAlign: "left",
    boxShadow: "none",
    border: "0 !important",
    color: theme.palette.accent.contrastText,
    borderRadius: "0",

    "&:hover": {
      backgroundColor: theme.palette.primary.active,
      color: theme.palette.primary.dark,
    },
  },
}));

const actionBtnStyles = (theme: Theme) => ({
  display: "flex",
  columnGap: "13px",
  alignItems: "center",
  fontSize: "16px",
  lineHeight: "24px",
  padding: "8px 12px",
  justifyContent: "space-between",
  fontWeight: "400",

  color: theme.palette.common.black,
  "&:hover": {
    background: theme.palette.settings.lighter,
    color: theme.palette.common.black,
  },

  ".csb-dropdown-icn": {
    width: "13px",
    height: "13px",
  },

  ".icn": {
    width: "20px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: "0",
    color: theme.palette.grayAccent.light,
  },

  ".MuiSvgIcon-root": {
    width: "15px",
    height: "15px",
  },
});

export const StyledMoveButton = styled(Button)(({ theme }) => ({
  ...actionBtnStyles(theme),
  borderRadius: "4px",
}));

export const StyledRemoveButton = styled(Button)(({ theme }) => ({
  ...actionBtnStyles(theme),
  borderRadius: "0",
  borderTop: `1px solid ${theme.palette.action.disabledBackground}`,
}));

export const StyledEditButton = styled(Button)(({ theme }) => ({
  ...actionBtnStyles(theme),
  borderRadius: "0",
  borderTop: `1px solid ${theme.palette.action.disabledBackground}`,
}));

export const StyledBasicCard = styled(BasicCard)(() => ({
  background: "transparent",
  boxShadow: "none",
  border: "none",
}));

export const StyledLoaderBox = styled(Box)(() => ({
  margin: "8rem 0",
}));

export const StyledHelperContainer = styled(Box)(() => ({
  marginTop: "16px",
  marginBottom: "16px",
}));

export const StyledHelperTypography = styled(Typography)(({ theme }) => ({
  display: "block",
  color: theme.palette.gray.darkest,
  fontSize: "14px",
  lineHeight: "20px",
  fontWeight: "400",
}));

export const StyledFolderContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.common.white,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  minHeight: "200px",
  flexDirection: "column",
}));

export const StyledIconFolderContainer = styled(Box)(({ theme }) => ({
  color: theme.palette.gray.darkest,
  width: "72px",
  height: "72px",
  background: theme.palette.gray.light,
  borderRadius: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "40px",
  lineHeight: "1.1",

  svg: {
    width: "40px",
    height: "40px",
  },
}));

export const StyledHelperRecordsTypography = styled(Typography)(
  ({ theme }) => ({
    display: "block",
    color: theme.palette.gray.darkest,
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "400",
    textAlign: "center",
  })
);

export const StyledAddResourceModal = styled(Modal)(() => ({
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  border: `1px solid ${theme.palette.action.disabledBackground}`,
  borderRadius: "6px",
  cursor: "pointer",
  position: "relative",
  width: "calc(100% - 12px)",
  boxShadow: "none",
  overflow: "hidden",

  "@media (min-width: 992px)": {
    width: "calc(50% - 12px)",
    display: "flex",
    flexDirection: "column",
  },
  "@media (min-width: 1200px)": {
    width: "calc(33.333% - 13px)",
  },
  "@media (min-width: 1600px)": {
    width: "calc(25% - 14px)",
  },
}));

export const StyledResourceHandlerContainer = styled(Box)(() => ({
  position: "relative",
  width: "100%",
  paddingTop: "50%",
  borderRadius: "6px 6px 0 0",

  ".resource-icon": {
    width: "100%",
    height: "100%",
    position: "absolute",
    objectFit: "cover",
    left: "0",
    top: "0",
    border: "0",
    borderRadius: "inherit",
    maxWidth: "none",
    maxHeight: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    svg: {
      width: "50%",
      height: "50%",
    },

    "&.placeholder": {
      objectFit: "contain",
    },
  },

  "#header-bar": {
    display: "none",
  },
}));

export const StyledDetailsContainer = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.action.disabledBackground}`,
  padding: "12px",
  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
}));

export const StyledTitleContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  gap: "5px",
  flexGrow: "1",
}));

export const StyledTitleSubContainer = styled(Box)(() => ({
  flexGrow: "1",
  flexBasis: "0",
  minWidth: "calc(50% - 10px)",
}));

export const StyledTitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",
  color: theme.palette.common.black,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

export const StyledBottomDetailsContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  marginTop: "8px",
}));

export const StyledLeftBottomDetailsContainer = styled(Box)(() => ({
  flexGrow: "1",
  flexBasis: "0",
  minWidth: "calc(50% - 5px)",
}));

export const StyledLeftBottomDetailsSubContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "8px",
}));

export const StyledAvatarContainer = styled(Box)(() => ({
  borderRadius: "100%",
  overflow: "hidden",

  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    display: "block",
    borderRadius: "inherit",
  },
}));

export const StyledAvatarTypography = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  lineHeight: "18px",
  fontWeight: "500",
  letterSpacing: "1px",
  color: theme.palette.gray.darkest,
}));

export const StyledRightBottomContainer = styled(Box)(() => ({
  flexGrow: "1",
  flexBasis: "0",
  minWidth: "calc(50% - 5px)",
}));

export const StyledRightBottomSubContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  width: "100%",
  gap: "14px",
}));

export const StyledDateTypography = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  lineHeight: "18px",
  display: "block",
  color: theme.palette.gray.dark,
}));

export const StyledGridContainer = styled(Box)(({ theme }) => ({
  marginTop: "-1px",

  ".ag-row.ag-row-hover": {
    background: theme.palette.primary.lighter,
  },

  ".ag-theme-material .ag-overlay-no-rows-wrapper.ag-layout-auto-height": {
    paddingTop: "0 !important",
  },
  ".ag-row .ag-cell.ag-cell-not-inline-editing.ag-cell-value > div.MuiBox-root":
    {
      flex: "1",
      maxWidth: "100%",
    },
  ".ag-row .ag-cell.ag-cell-not-inline-editing.ag-cell-value p": {
    maxWidth: "calc(100% - 36px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  ".ag-header-cell": {
    paddingLeft: "16px",
    paddingRight: "16px",
  },

  ".ag-pinned-right-cols-container": {
    ".ag-cell": {
      borderLeft: "0 !important",

      "> div": {
        textAlign: "right",
      },
    },
  },

  ".ag-row": {
    ".ag-cell:first-child": {
      // paddingLeft: "2px",
    },
  },

  ".ag-header-cell:not(:last-child)": {
    borderRight: "0 !important",
  },

  ".ag-pinned-right-header": {
    borderLeft: "0 !important",

    ".ag-left-aligned-header": {
      justifyContent: "flex-end",
    },
  },
}));

export const StyledPathLinkContainer = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  ".name, .name-link": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.accent.darkest,
    textDecoration: "none",

    "&:hover": {
      textDecoration: "underline",
    },
  },
}));
