import {
  styled,
  Box,
  Tab,
  Typography,
  TextField,
  Button,
  Divider,
} from "@mui/material";
import { Tabs } from "../common/Tabs/Tabs";
import { brighthiveTheme } from "../theme/theme";

export const Wrapper = styled(Box)`
  padding: 80px 24px 24px;
  display: flex;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;

  @media (min-width: 1200px) {
    padding: 80px 32px 32px;
  }
`;

export const PageHeader = styled(Box)`
  border-bottom: 1px solid ${brighthiveTheme.palette.gray.contrastText};
  overflow: hidden;
  padding: 10px 20px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;

  svg {
    width: 150px;
    height: auto;
  }
`;

export const PageContent = styled(Box)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  display: flex;
  width: 100%;
  flex: 1;
  gap: 30px;

  svg {
    display: block;
    margin: 0 auto;
    width: 150px;
    height: auto;
  }
`;
