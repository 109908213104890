import Drawer, {
  DisplayDrawerHandlerType,
  DrawerDisplayerType,
  DrawerIconType,
} from "../../../common/Drawer";
import {
  FieldContainer,
  StyledLinkContainer,
  StyledLinkedLabel,
  StyledLinkSubContainer,
  StyledPathLinkContainer,
  ViewDrawerContainer,
} from "./style";
import { Box, Modal, Typography, useTheme } from "@mui/material";
import { EditPencilIcon } from "../../../common/Icons/EditPencilIcon";
import { DeleteIcon } from "../../../common/Icons/DeleteIcon";
import { Tag } from "../../../common/Tag/Tag";
import { Label } from "../../../common/Label/Label";
import { DocumentResourceIcon } from "../../../common/Icons/DocumentResourceIcon";
import PathLink from "../PathLink";
import { MoveFileIcon } from "../../../common/Icons/MoveFileIcon";
import { DeleteResourceModal } from "../../AddResource/DeleteResourceModal";
import { Move } from "../../AddResource/Move";
import { useState } from "react";
import Resource from "../../../contexts/resource";

type Props = {
  drawerDisplayer: DrawerDisplayerType;
  displayDrawerHandler: DisplayDrawerHandlerType;
  data: any;
};

const ViewDrawer = ({ drawerDisplayer, displayDrawerHandler, data }: Props) => {
  const { palette } = useTheme();

  const [open, setOpen] = useState(false);
  const [openMove, setOpenMove] = useState(false);
  const {
    editMode: { setEditing },
  } = Resource.useContext();

  const icons: DrawerIconType[] = [
    {
      icon: <MoveFileIcon />,
      handler: () => {
        setOpenMove(true);
      },
    },
    {
      icon: <EditPencilIcon />,
      handler: () => {
        setEditing(data.id);
        displayDrawerHandler(null);
      },
    },
    {
      icon: <DeleteIcon />,
      handler: () => {
        setOpen(true);
      },
    },
  ];
  return (
    <>
      <Drawer
        drawerDisplayer={drawerDisplayer}
        displayDrawerHandler={displayDrawerHandler}
        displayFooter={false}
        drawerType="view"
        sectionName={"resource"}
        title={data?.name}
        icons={icons}
      >
        {data && (
          <ViewDrawerContainer>
            <FieldContainer>
              <Typography gutterBottom variant="subtitle3">
                Name
              </Typography>
              <Typography variant="body2" color="gray.darkest">
                <Box display="flex" gap={2} alignItems="center">
                  {data.name}
                </Box>
              </Typography>
            </FieldContainer>

            <FieldContainer>
              <Typography gutterBottom variant="subtitle3">
                Path
              </Typography>
              <Typography variant="body2" color="gray.darkest">
                <StyledPathLinkContainer display="flex" gap={2}>
                  <PathLink data={data} className="name-link">
                    {data.path}
                  </PathLink>
                </StyledPathLinkContainer>
              </Typography>
            </FieldContainer>

            <FieldContainer>
              <Typography gutterBottom variant="subtitle3">
                Type
              </Typography>
              <Typography variant="body2" color="gray.darkest">
                <Box display="flex" gap={2} alignItems="center">
                  {data.type}
                </Box>
              </Typography>
            </FieldContainer>

            <FieldContainer>
              <Typography gutterBottom variant="subtitle3">
                Description
              </Typography>
              <Typography variant="body2" color="gray.darkest">
                <Box display="flex" gap={2} alignItems="center">
                  {data.description}
                </Box>
              </Typography>
            </FieldContainer>

            <Box display={"flex"} pt={"1.125rem"}>
              {data?.tags?.map((tag: { id: string; name: string }) => (
                <Tag label={tag.name} mr={2}></Tag>
              ))}
            </Box>

            <FieldContainer>
              <StyledLinkedLabel mb={1} value="Linked Terms" />
              <Box>
                {data?.terms?.map((t: any) => {
                  return (
                    <StyledLinkContainer>
                      <StyledLinkSubContainer>
                        <DocumentResourceIcon />
                        {t?.name}
                      </StyledLinkSubContainer>
                    </StyledLinkContainer>
                  );
                })}
              </Box>
            </FieldContainer>

            <FieldContainer>
              <StyledLinkedLabel mb={1} value="Linked Schemas" />
              <Box>
                {data?.schemas?.map((t: any) => {
                  return (
                    <StyledLinkContainer>
                      <StyledLinkSubContainer>
                        <DocumentResourceIcon />
                        {t?.name}
                      </StyledLinkSubContainer>
                    </StyledLinkContainer>
                  );
                })}
              </Box>
            </FieldContainer>
          </ViewDrawerContainer>
        )}
      </Drawer>
      <DeleteResourceModal
        name={data?.name}
        open={open}
        setOpen={setOpen}
        id={data?.id}
      />

      <Modal open={openMove} onClose={() => setOpenMove(false)}>
        <Move
          name={data?.name}
          id={data?.id}
          onClose={() => setOpenMove(false)}
        />
      </Modal>
    </>
  );
};

export default ViewDrawer;
