import { Box, useTheme } from "@mui/material";
import { Typography } from "../../../common/Typography/Typography";
import { CorporateFare } from "@mui/icons-material";
import { Label } from "../../../common/Label/Label";
import { ColoredAvatar } from "../../../common/ColoredAvatar/ColoredAvatar";
import AccessStatusChip from "../../AccessStatusChip/AccessStatusChip";
import { AccessStatus } from "../../../generated";
import { AssetManagersFieldsReadOnlyWrapper, ColoredAvatarWrapper, CorporateFareWrapper, FieldAlignBox, FieldLabel, FieldWrapper, TextParagraph, TextWithTagBox } from "../../style";

export interface AssetManagersFieldsProps {
  assetOwner: string;
  assetManager: string;
  status?: AccessStatus | null;
}

export const AssetManagersFieldsReadOnly = ({
  assetOwner,
  assetManager,
  status,
}: AssetManagersFieldsProps) => {
  const { palette } = useTheme();

  return (
    <AssetManagersFieldsReadOnlyWrapper
      className="AssetManagersFieldsReadOnly"
    >
      <FieldWrapper>
        <FieldLabel value="Asset Owner" info />
        <FieldAlignBox>
          <CorporateFareWrapper />
          <Typography variant="body2">{assetOwner}</Typography>
        </FieldAlignBox>
      </FieldWrapper>
      <FieldWrapper>
        <FieldLabel value="Asset Manager" info />
        <FieldAlignBox display="flex" key={assetManager}>
          <ColoredAvatarWrapper
            name={assetManager?.trim() || "Un Assigned"}
          />
          {assetManager?.trim() || "Unassigned"}
        </FieldAlignBox>
      </FieldWrapper>
      <FieldWrapper>
        <FieldLabel value="Asset Status" info />
        <TextWithTagBox>
          {status && <AccessStatusChip status={status} />}
          <TextParagraph className="mt" variant="body3">
            {status === AccessStatus.Shared &&
              "Workspace members can add this data asset to any project within the workspace."}<br />
            {status === AccessStatus.Restricted &&
              "Only the Asset Manager can add this data asset to a project."}<br />
            {status === AccessStatus.Private &&
              "Private data assets cannot be added to or used in projects."}<br />
          </TextParagraph>
          <TextParagraph className="dark" variant="body3">
            {status === AccessStatus.Shared &&
              "Any workspace member can view information about the asset."}
            {status === AccessStatus.Restricted &&
              "Workspace members can still discover and view information about the asset."}
            {status === AccessStatus.Private &&
              "Only members of the Data Asset Manager’s organization can view information about the asset."}
          </TextParagraph>
        </TextWithTagBox>
      </FieldWrapper>
    </AssetManagersFieldsReadOnlyWrapper>
  );
};
