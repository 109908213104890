import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import { EditOutlined } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  OutlinedInput,
  useTheme,
} from "@mui/material";

import { Button } from "../../common/Button/Button";
import { Typography } from "../../common/Typography/Typography";
import { FormLabel } from "../../common/Form/FormLabel";
import { PiiTagType, PiiType, UpdateSchemaDocument } from "../../generated";
import { MAX_DESCRIPTION_CHARS } from "../../Constants";
import { Select } from "../../common/Form/Select";
import { Radio } from "../../common/Radio/Radio";
import { convertToTitleCase } from "../../helpers";
import { ActionsCellModalBody, ActionsCellModalFieldText, ActionsCellModalHeading, ActionsCellRendererWrapper, ButtonDefault, ButtonPrimary, ModalBtnsWrapper, RadioGroupWrapper } from "../style";

export interface ActionsCellRendererProps {
  fieldName?: string;
  description?: string;
  pii?: PiiType;
  rowIndex: number | null;
  refetch: () => void;
}

export const ActionsCellRenderer = ({
  fieldName,
  description,
  pii,
  rowIndex,
  refetch,
}: ActionsCellRendererProps) => {
  const { workspaceId, dataAssetId } = useParams();
  const [update, { loading }] = useMutation(UpdateSchemaDocument);
  const [dialogOpen, setDialogOpen] = useState(false);

  const { typography, palette } = useTheme();

  if (!workspaceId || !dataAssetId) return null;
  return (
    <ActionsCellRendererWrapper
      className="ActionsCellRenderer"
    >
      <ButtonPrimary
        onClick={() => setDialogOpen(true)}
        color="grayAccent"
        variant="text"
        startIcon={<EditOutlined />}
      >
        Edit
      </ButtonPrimary>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <Formik
          initialValues={{
            description: description || "",
            piiTag: (pii as unknown as PiiTagType) || PiiTagType.None,
          }}
          onSubmit={async (values) => {
            await update({
              variables: {
                input: {
                  ...values,
                  columnIndex: rowIndex!,
                  dataAssetId,
                  workspaceId,
                },
              },
            });
            refetch();
            setDialogOpen(false);
          }}
        >
          {({ handleChange, values, handleSubmit, setFieldValue }) => (
            <ActionsCellModalBody>
              <ActionsCellModalHeading variant="h6">
                Edit {fieldName}
              </ActionsCellModalHeading>
              <FormControl sx={{ mb: 2 }} fullWidth>
                <FormLabel>Field Description</FormLabel>
                <OutlinedInput
                  fullWidth
                  minRows={4}
                  onChange={handleChange("description")}
                  defaultValue={description || ""}
                  multiline
                  sx={{
                    ...typography.body3,
                    ".MuiInputBase-input": {
                      pt: 2,
                      pb: 2,
                      pl: 3,
                      pr: 3,
                      height: "2.25rem",
                      boxSizing: "border-box",
                    },
                    ".MuiOutlinedInput-input": {
                      p: 0,
                    },
                  }}
                  inputProps={{
                    maxLength: MAX_DESCRIPTION_CHARS,
                  }}
                  placeholder="Description here"
                />
              </FormControl>
              <ActionsCellModalFieldText variant="body3">
                {values.description.length}/{MAX_DESCRIPTION_CHARS} characters
                here
              </ActionsCellModalFieldText>
              <RadioGroupWrapper>
                <Typography sx={{ ...typography.subtitle3, mb: 1 }}>
                  Personal Identifiable Information (PII)
                </Typography>
                <Radio
                  name="piiTag"
                  value={PiiTagType.None}
                  label={convertToTitleCase(PiiTagType.None)}
                  checked={values.piiTag === PiiTagType.None}
                  onChange={handleChange("piiTag")}
                />
                <Radio
                  name="piiTag"
                  value={PiiTagType.NonSensitive}
                  label={convertToTitleCase(PiiTagType.NonSensitive)}
                  checked={values.piiTag === PiiTagType.NonSensitive}
                  onChange={handleChange("piiTag")}
                />
                <Radio
                  name="piiTag"
                  value={PiiTagType.Sensitive}
                  label={convertToTitleCase(PiiTagType.Sensitive)}
                  checked={values.piiTag === PiiTagType.Sensitive}
                  onChange={handleChange("piiTag")}
                />

                {/* <select
                  name="piiTag"
                  defaultValue={values.piiTag}
                  onChange={(e) => setFieldValue("piiTag", e.target.value)}
                >
                  <option value={PiiTagType.None}>None</option>
                  <option value={PiiTagType.Sensitive}>Sensitive</option>
                  <option value={PiiTagType.NonSensitive}>NonSensitive</option>
                </select> */}
                <Typography variant="body3">
                  This field contains personally identifiable information
                </Typography>
              </RadioGroupWrapper>
              <ModalBtnsWrapper>
                <ButtonDefault
                  onClick={() => setDialogOpen(false)}
                  color="grayAccent"
                  variant="outlined"
                >
                  Cancel
                </ButtonDefault>
                <ButtonPrimary
                  disabled={loading}
                  onClick={() => handleSubmit()}
                  color="primary"
                  variant="contained"
                >
                  Save Changes
                </ButtonPrimary>
              </ModalBtnsWrapper>
            </ActionsCellModalBody>
          )}
        </Formik>
      </Dialog>
    </ActionsCellRendererWrapper>
  );
};
