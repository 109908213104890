import {
  DataAssetsInput,
  GetDataAssetsDocument,
  GetDataAssetsQuery,
} from "../../generated";
import { generateArrayOfData } from "./helpers";
import { aDataAsset } from "./generated-mocks";
import { MockedResponse } from "@apollo/client/testing";

const dataAssets = generateArrayOfData(() => aDataAsset(), 200);

const input: DataAssetsInput = {
  workspaceId: "f4861165-f244-4cad-9827-65921385b9a0",
};

const data: GetDataAssetsQuery = {
  workspace: {
    dataAssets,
  },
};

export const dataAssetsMock: MockedResponse = {
  request: {
    query: GetDataAssetsDocument,
    variables: {
      input,
    },
  },
  result: {
    data,
  },
  delay: 2000,
};
