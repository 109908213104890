import { Box, Grid, useTheme } from "@mui/material";

import { Typography } from "../common/Typography/Typography";
import { BasicPage } from "../common/BasicPage/BasicPage";
import React from "react";
import { useQuery } from "@apollo/client";
import { GetCurrentUserInfoDocument } from "../generated";
import { convertIsoDateStringToMMDDYYYY } from "../helpers";
import { MyOrganization } from "./MyOrganization";

export const MyOrganizationPage = () => {
  const { palette } = useTheme();
  return (
    <BasicPage
      sx={{
        ".right-elements": {
          alignSelf: "flex-end",
        },
        background: palette.common.white,
      }}
      leftHeaderElements={
        <Box display="flex">
          <Typography variant="h5">My Organization</Typography>
        </Box>
      }
      content={<MyOrganization />}
    />
  );
};
