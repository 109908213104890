import { useTheme } from "@mui/material";
import {
  getAddNodes,
  getHeaderNodes,
  getNodePosition,
  NodeType,
} from "./helpers";
import { Edge, Node } from "react-flow-renderer";
import { GetWorkflowQuery } from "../generated";

export const getNodesAndEdgesFromData = ({
  workflow,
  loading,
  onNodeClick,
  isTesting = false,
  isActive,
}: {
  workflow: GetWorkflowQuery["workflow"];
  loading: boolean;
  onNodeClick: (node: Node<any>) => void;
  isActive: boolean;
  isTesting?: boolean;
}): { nodes: Node[]; edges: Edge[] } => {
  if (loading) return { nodes: [], edges: [] };
  if (workflow === null || workflow === undefined)
    return { nodes: [...getHeaderNodes()], edges: [] };

  const organizationNodes: Node[] = [];
  const inputDataNodes: Node[] = [];
  const transformationNodes: Node[] = [];
  const finalDataProductNodes: Node[] = [];
  const destinationNodes: Node[] = [];
  const edges: Edge[] = [];

  workflow.organizations.forEach((org, i) => {
    const { palette } = useTheme();
    if (org !== null) {
      organizationNodes.push({
        id: org.id,
        data: org,
        type: NodeType.ORGANIZATION,
        position: getNodePosition(NodeType.ORGANIZATION, i + 1),
      });
      org.linkedNodes.forEach((linkedNodeId) => {
        edges.push({
          source: org.id,
          target: linkedNodeId,
          id: `e-${org.id}-${linkedNodeId}`,
          animated: isActive || isTesting,
          style: {
            strokeDasharray: "10",
            stroke: isActive ? palette.primary.main : palette.accent.darkest,
          },
        });
      });
    }
  });

  workflow.inputDataAssetGroups.forEach((inputDataAssetGroup, i) => {
    const { palette } = useTheme();
    if (inputDataAssetGroup) {
      inputDataNodes.push({
        id: inputDataAssetGroup?.id,
        data: {
          ...inputDataAssetGroup,
          onSubNodeClick: (id: string, parentId: string) => {
            onNodeClick({
              id,
              type: NodeType.INPUT_DATA_ITEM,
              position: {
                x: 0,
                y: 0,
              },
              data: { parentId },
            });
          },
        },
        type: NodeType.INPUT_DATA,
        position: getNodePosition(NodeType.INPUT_DATA, i + 1),
      });
      inputDataAssetGroup.linkedNodes.forEach((linkedNodeId) => {
        edges.push({
          source: inputDataAssetGroup.id,
          target: linkedNodeId,
          id: `e-${inputDataAssetGroup.id}-${linkedNodeId}`,
          animated: isActive || isTesting,
          style: {
            strokeDasharray: "10",
            stroke: isActive ? palette.primary.main : palette.accent.darkest,
          },
        });
      });
    }
  });

  const transformations = [
    Array.from(workflow.transformations).sort((a, b) =>
      a && b
        ? b?.linkedTransformations.length - a?.linkedTransformations.length
        : 0
    )[0],
  ].filter((x) => x);

  transformations.forEach((transformation, i) => {
    const { palette } = useTheme();
    if (transformation) {
      transformationNodes.push({
        id: transformation.id,
        data: {
          ...transformation,
        },
        type: NodeType.TRANSFORMATIONS,
        position: getNodePosition(NodeType.TRANSFORMATIONS, i + 1),
      });
      transformation.linkedNodes.forEach((linkedNodeId) => {
        edges.push({
          source: transformation.id,
          target: linkedNodeId,
          id: `e-${transformation.id}-${linkedNodeId}`,
          sourceHandle: "a",
          targetHandle: "a",
          animated: isActive || isTesting,
          style: {
            strokeDasharray: "10",
            stroke: isActive ? palette.primary.main : palette.accent.darkest,
          },
        });
      });
      transformation.linkedTransformations.forEach((linkedNodeId) => {
        edges.push({
          source: transformation.id,
          target: linkedNodeId,
          id: `e-${transformation.id}-${linkedNodeId}`,
          sourceHandle: "b",
          targetHandle: "b",
          animated: isActive || isTesting,
          style: {
            strokeDasharray: "10",
            stroke: isActive ? palette.primary.main : palette.accent.darkest,
          },
        });
      });
    }
  });

  workflow.finalDataProducts.forEach((finalDataProduct, i) => {
    if (finalDataProduct) {
      finalDataProductNodes.push({
        id: finalDataProduct.id,
        data: {
          ...finalDataProduct,
          onSubNodeClick: (id: string, parentId: string) => {
            onNodeClick({
              id,
              position: {
                x: 0,
                y: 0,
              },
              type: NodeType.DATA_PRODUCT,
              data: { parentId },
            });
          },
        },
        type: NodeType.FINAL_DATA_PRODUCTS,
        position: getNodePosition(NodeType.FINAL_DATA_PRODUCTS, i + 1),
      });
      finalDataProduct.linkedNodes.forEach((linkedNodeId) => {
        const { palette } = useTheme();
        edges.push({
          source: finalDataProduct.id,
          target: linkedNodeId,
          id: `e-${finalDataProduct.id}-${linkedNodeId}`,
          animated: isActive || isTesting,
          style: {
            strokeDasharray: "10",
            stroke: isActive ? palette.primary.main : palette.accent.darkest,
          },
        });
      });
    }
  });

  workflow.destinations.forEach((destination, i) => {
    if (destination) {
      destinationNodes.push({
        id: destination.id,
        data: destination,
        type: NodeType.DESTINATIONS,
        position: getNodePosition(NodeType.DESTINATIONS, i + 1),
      });
    }
  });

  return {
    nodes: [
      ...getHeaderNodes(),
      ...getAddNodes(transformations.length > 0 && true),
      ...organizationNodes,
      ...inputDataNodes,
      ...transformationNodes,
      ...finalDataProductNodes,
      ...destinationNodes,
    ],
    edges,
  };
};
