import React from "react";
import { Box, Divider, useTheme } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { Typography } from "../../common/Typography/Typography";
import { BasicCard } from "../../common/BasicCard/BasicCard";
import { Label } from "../../common/Label/Label";
import { Tag } from "../../common/Tag/Tag";
import { Button } from "../../common/Button/Button";
import { AssetManagersFieldsReadOnly } from "./AssetManagersFieldsReadOnly/AssetManagersFieldsReadOnly";
import { GetADataAssetQuery } from "../../generated";
import { AdditionalDetailsWrapper, AdditionalTopBox, ButtonDefault, ButtonPrimary, FieldLabel, FieldTags, FieldTagsBox, FieldTagsText, FieldWrapper, PageDivider, TextParagraph } from "../style";

export const AssetInformation = ({
  data,
  onEdit,
  ...rest
}: {
  data: GetADataAssetQuery;
  onEdit: () => any;
}) => {
  const { palette } = useTheme();
  const dataAsset = data.workspace?.dataAssets?.[0];
  if (!dataAsset) {
    return null;
  }

  const tableProperties = [
    {
      key: "recordCount",
      value: dataAsset.projectCount || "500",
    },
    {
      key: "avgRecordSize",
      value: dataAsset.assetSize || "0",
    },
    {
      key: "compressionType",
      value: dataAsset.connectionType || "none", 
    },
  ];

  if (dataAsset)
    return (
      <AdditionalDetailsWrapper className="AssetInformation" {...rest}>
        <AdditionalTopBox
          className="flex-box"
        >
          <Typography variant={"h6"}>Asset information</Typography>
          {dataAsset?.isEditable && (
            <ButtonDefault
              className="small btn-icn"
              onClick={onEdit}
              size={"small"}
              color={"grayAccent"}
              startIcon={<EditOutlinedIcon />}
            >
            </ButtonDefault>
          )}
        </AdditionalTopBox>
        <PageDivider className="my-0" />
        <AdditionalTopBox className="p-same">
          <FieldWrapper>
            <FieldLabel value="Data asset name" />
            <TextParagraph className="dark" variant="body2">
              {dataAsset.name}
            </TextParagraph>
          </FieldWrapper>
          <FieldWrapper>
            <FieldLabel value="Description" />
            <TextParagraph className="dark" variant="body2">
              {dataAsset.description}
            </TextParagraph>
          </FieldWrapper>
          <FieldWrapper>
            <FieldLabel value="Tags" info />
            {dataAsset.tags.map((tag: string) => (
              <Tag key={tag} label={tag} dismissable={false} mr={2} />
            ))}
          </FieldWrapper>
          <PageDivider className="mt-0" />
          <AssetManagersFieldsReadOnly
            assetOwner={(dataAsset.owner as any)?.name || ""}
            assetManager={`${dataAsset.managers?.[0]?.firstName || ""} ${
              dataAsset.managers?.[0]?.lastName || ""
            }`}
            status={dataAsset?.accessStatus}
          />
          <PageDivider className="mt-0" />
          <FieldWrapper>
            <FieldLabel value="Data sensitivity" info />
            {dataAsset.sensitivity && (
              <Tag label={dataAsset.sensitivity} dismissable={false} mr={2} />
            )}
          </FieldWrapper>
          <FieldWrapper>
            <FieldLabel value="Pll types" info />
            <FieldTagsBox>
              <FieldTags>
                {dataAsset?.piiTypes?.map((pllData) => (
                  <Tag label={pllData} dismissable={false} mr={2} />
                ))}
              </FieldTags>
              <Box>
                {dataAsset?.piiTypes?.map((pllData: any) => (
                  <FieldTagsText
                    variant="body3"
                  >
                    {pllData.linkTitle}
                  </FieldTagsText>
                ))}
              </Box>
            </FieldTagsBox>
          </FieldWrapper>
          <FieldWrapper>
            <FieldLabel value="Asset-specific policies" info />
            <Box>
              {dataAsset.assetPolicies?.map((policyData: any) => (
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="body2">{policyData.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FieldTagsText
                      className="mb-3"
                      variant="body3"
                    >
                      {policyData.description}
                    </FieldTagsText>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </FieldWrapper>
          <PageDivider className="mt-0" />
          <FieldWrapper>
            <FieldLabel value="Source" info />
            <TextParagraph className="dark" variant="body2">
              {dataAsset.source}
            </TextParagraph>
          </FieldWrapper>
          <FieldWrapper>
            <FieldLabel value="Connection" info />
            <Box>
              <TextParagraph className="dark" variant="body2">
                {dataAsset.connectionType}
              </TextParagraph>
              <TextParagraph className="dark mt" variant="body3">
                {dataAsset.connectionDescription}
              </TextParagraph>
            </Box>
          </FieldWrapper>
          <FieldWrapper>
            <FieldLabel value="Data refresh" info />
            <TextParagraph className="dark" variant="body2">
              {dataAsset.refreshRate}
            </TextParagraph>
          </FieldWrapper>
          <FieldWrapper>
            <FieldLabel value="Storage service" info />
            <TextParagraph className="dark" variant="body2">
              {dataAsset.storageService}
            </TextParagraph>
          </FieldWrapper>
          <FieldWrapper>
            <FieldLabel value="Asset size" info />
            <TextParagraph className="dark" variant="body2">
              {dataAsset.assetSize}
            </TextParagraph>
          </FieldWrapper>
          <FieldWrapper>
            <FieldLabel value="Brighthive table name" info />
            <TextParagraph className="dark" variant="body2">
              {dataAsset.tableName}
            </TextParagraph>
          </FieldWrapper>
          {/* <Box
            display={"flex"}
            pb={"1.5rem"}
            alignItems={"flex-start"}
            maxWidth={"58rem"}
          >
            <Label value="Table properties" info width={"15.5rem"} />
            <Typography variant="body2" color={palette.gray.dark}>
              {tableProperties.map((tag: any) => (
                <Tag
                  label={tag.key}
                  secondaryText={tag.value}
                  dismissable={false}
                  mr={2}
                  mb={2}
                ></Tag>
              ))}
            </Typography>
          </Box> */}
          {/* <Box
            display={"flex"}
            pb={"1.5rem"}
            alignItems={"flex-start"}
            maxWidth={"58rem"}
          >
            <Label
              value="Table properties (Missing in API)"
              info
              width={"15.5rem"}
            />
            <Box display={"flex"} flexWrap="wrap">
              {dataAsset.tags.map((tag: any) => (
                <Tag
                  key={tag.value}
                  label={tag.property}
                  secondaryText={tag.value}
                  dismissable={false}
                  mr={2}
                  mb={2}
                />
              ))}
            </Box>
          </Box> */}
        </AdditionalTopBox>
      </AdditionalDetailsWrapper>
    );
  return null;
};
