import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const GlueDataCatalogIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon height="32" width="32" viewBox="0 0 33 32" fill="none" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.2041 12.2502C15.7854 12.6215 17.7867 12.6668 18.6667 12.6668C19.5474 12.6668 21.5487 12.6215 23.1301 12.2502L19.3334 19.8428V22.0002H18.0001V19.8428L14.2041 12.2502ZM18.6667 10.0002C21.3001 10.0002 23.0487 10.3742 23.7447 10.6668C23.0487 10.9608 21.3001 11.3335 18.6667 11.3335C16.0341 11.3335 14.2861 10.9608 13.5894 10.6668C14.2861 10.3742 16.0341 10.0002 18.6667 10.0002V10.0002ZM20.6667 23.3335V20.1575L25.0947 11.3015C25.2454 11.1162 25.3334 10.9062 25.3334 10.6668C25.3334 8.8115 20.2307 8.66683 18.6667 8.66683C17.1034 8.66683 12.0001 8.8115 12.0001 10.6668C12.0001 10.9062 12.0874 11.1162 12.2387 11.3015L16.6667 20.1575V23.3335H20.6667ZM2.66675 1.3335H6.00008V2.66683H4.00008V29.3335H6.00008V30.6668H2.66675V1.3335ZM10.6667 28.0002H26.6667V26.6668H10.6667V28.0002ZM10.6667 25.3335H26.6667V24.0002H10.6667V25.3335ZM10.6667 8.00016H26.6667V6.66683H10.6667V8.00016ZM10.6667 5.3335H26.6667V4.00016H10.6667V5.3335ZM8.66675 29.3335H28.0001V2.66683H8.66675V29.3335ZM7.33341 30.6668H29.3334V1.3335H7.33341V30.6668Z"
        fill="#4D27AA"
      />
    </SvgIcon>
  );
};
