export const getRandomIntFromInterval = (min: number, max: number) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const getDateInInterval = (start: Date, end: Date) => {
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
};

export const pickRandomValueFromArray = (arr: any[]) => {
  return arr[getRandomIntFromInterval(0, arr.length - 1)];
};

export const generateArrayOfData = (
  dataGenerator: (...args: []) => any,
  amountOfData: number
) => {
  const retData = [];
  for (let i = 0; i < amountOfData; i++) {
    retData.push(dataGenerator());
  }
  return retData;
};

export const pickSubsetOfArr = (arr: any) => {
  const amountToPick = getRandomIntFromInterval(0, arr.length);
  const subsetArr = [];
  for (let i = 0; i < amountToPick; i++) {
    subsetArr.push(arr[i]);
  }
  return subsetArr;
};

export const generateRandomString = (
  lowerBoundLength: number,
  upperBoundLength: number,
  withSpaces: boolean = false
) => {
  const length = getRandomIntFromInterval(lowerBoundLength, upperBoundLength);
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let putNextSpaceIndex = getRandomIntFromInterval(3, 9);
  for (let i = 0; i < length; i++) {
    if (i === putNextSpaceIndex && withSpaces) {
      result += " ";
      putNextSpaceIndex = getRandomIntFromInterval(3 + i, 9 + i);
    } else {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
  }
  return result;
};
