import DocViewer, {
  DocViewerRef,
  DocViewerRenderers,
  IHeaderOverride,
} from "@cyntler/react-doc-viewer";
import {
  Box,
  Button,
  Divider,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { DocumentsListIcon } from "../../common/Icons/DocumentsListIcon";
import { MinusIcon } from "../../common/Icons/MinusIcon";
import { PlusIcon } from "../../common/Icons/PlusIcon";
import { TrippleVerticalDotsIcon } from "../../common/Icons/TrippleVerticalDotsIcon";
import { DownloadFileIcon } from "../../common/Icons/DownloadFileIcon";
import { EditPencilIcon } from "../../common/Icons/EditPencilIcon";
import { FileExportIcon } from "../../common/Icons/FileExportIcon";
import { ChatBotIcon } from "../../common/Icons/ChatBotIcon";
import { useQuery } from "@apollo/client";
import {
  AccessMode,
  Directory,
  PreSignedUrlDocument,
  ResourceType,
} from "../../generated";
import { useNavigate, useParams } from "react-router-dom";
import { BrandedAnimationLoader } from "../../common/Loader/Loader";
import { ImageViewer } from "./ImageViewer";
import { allowedDocTypes, allowedResourceTypes } from "../../helpers";
import Resource from "../../contexts/resource";

const DocPreviewLoader = () => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1",
      }}
    >
      <BrandedAnimationLoader
        sx={{
          minHeight: "200px",
          padding: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: "1",
        }}
      />
    </Box>
  );
};

export const ResourceViewer = ({ resource }: any) => {
  const navigate = useNavigate();
  const { workspaceId } = useParams();
  const { palette } = useTheme();
  const {
    viewMode: { name },
  } = Resource.useContext();
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [zoom, setZoom] = useState(100);

  const [selectedDocs, setSelectedDocs] = useState<File[]>([]);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const { data, loading } = useQuery(PreSignedUrlDocument, {
    variables: {
      input: {
        access: AccessMode.ReadOnly,
        directory: Directory.Resource,
        workspaceId: workspaceId || "",
        readInfo: {
          key: resource.path,
        },
        name: "",
      },
    },
  });

  const isImage = useMemo(
    () =>
      allowedResourceTypes(ResourceType.Image).some((el) =>
        data?.preSignedUrl?.key?.includes(el)
      ),
    [data]
  );

  const isVideo = useMemo(
    () =>
      allowedResourceTypes(ResourceType.Video).some((el) =>
        data?.preSignedUrl?.key?.includes(el)
      ),
    [data]
  );

  return (
    <Box
      sx={{
        border: `6px solid ${palette.common.white}`,
        backgroundColor: palette.customViewer.main,
        height: "100%",

        "#image-renderer": {
          backgroundColor: "transparent",
        },

        "#react-doc-viewer": {
          backgroundColor: palette.settings.lighter,
        },

        "#proxy-renderer": {
          backgroundColor: palette.common.white,
          boxShadow: `0 1px 10px ${palette.action.disabledBackground}, 0 2px 4px ${palette.action.disabledBackground}`,
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "728px",
        },

        "#header-bar": {
          marginBottom: "32px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          backgroundColor: palette.common.white,
          padding: "16px",
        }}
      >
        <Box
          sx={{
            flexShrink: "0",
            maxWidth: "30%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",

              "@media (min-width: 1350px)": {
                gap: "16px",
              },
            }}
          >
            <Button
              sx={{
                minWidth: "1px",
                width: "32px",
                height: "32px",
                borderRadius: "4px",
                backgroundColor: "transparent",
                color: palette.primary.main,

                "&:hover": {
                  backgroundColor: palette.primary.lighter,
                  color: palette.primary.main,
                },
              }}
            >
              <DocumentsListIcon />
            </Button>
            <Divider
              orientation="vertical"
              sx={{
                height: "36px",
              }}
            />
            <Typography
              variant="caption"
              sx={{
                color: palette.common.black,
                fontSize: "14px",
                lineHeight: "22px",
                fontWeight: "400",

                "@media (min-width: 1350px)": {
                  fontSize: "18px",
                  lineHeight: "24px",
                },
              }}
            >
              {name}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexGrow: "1",
            flexBasis: "0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {/* <Button
              sx={{
                color: palette.common.black,
                minWidth: "1px",
                backgroundColor: "transparent",
                padding: "0",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "1",

                "&:hover": {
                  color: palette.primary.main,
                  backgroundColor: "transparent",
                },
              }}
            >
              5
            </Button>
            <Typography
              variant="caption"
              sx={{
                color: palette.grayAccent.light,
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "1",
              }}
            >
              /
            </Typography>
            <Button
              sx={{
                color: palette.grayAccent.light,
                minWidth: "1px",
                backgroundColor: "transparent",
                padding: "0",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "1",

                "&:hover": {
                  color: palette.primary.main,
                  backgroundColor: "transparent",
                },
              }}
            >
              16
            </Button> */}
            {/* <Divider
              orientation="vertical"
              sx={{
                height: "24px",

                "@media (min-width: 1350px)": {
                  marginRight: "14px",
                  marginLeft: "14px",
                },
              }}
            /> */}
            {isImage && (
              <>
                <Box sx={{ display: "none" }}>
                  <Button
                    onClick={() => setZoom((prev) => prev - 25)}
                    disabled={zoom === 25}
                    sx={{
                      width: "24px",
                      height: "24px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "2px",
                      minWidth: "1px",
                      padding: "0",
                      color: palette.grayAccent.main,

                      "@media (min-width: 1350px)": {
                        width: "32px",
                        height: "32px",
                      },

                      "&:hover": {
                        backgroundColor: palette.settings.lighter,
                        color: palette.grayAccent.main,
                      },

                      ".MuiSvgIcon-root": {
                        width: "14px",
                        height: "14px",

                        "@media (min-width: 1350px)": {
                          width: "20px",
                          height: "20px",
                        },
                      },
                    }}
                  >
                    <MinusIcon />
                  </Button>
                  <Typography
                    variant="caption"
                    sx={{
                      color: palette.common.black,
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    {zoom}%
                  </Typography>
                  <Button
                    onClick={() => setZoom((prev) => prev + 25)}
                    disabled={zoom === 200}
                    sx={{
                      width: "24px",
                      height: "24px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "2px",
                      minWidth: "1px",
                      padding: "0",
                      color: palette.grayAccent.main,

                      "@media (min-width: 1350px)": {
                        width: "32px",
                        height: "32px",
                      },

                      "&:hover": {
                        backgroundColor: palette.settings.lighter,
                        color: palette.grayAccent.main,
                      },

                      ".MuiSvgIcon-root": {
                        width: "14px",
                        height: "14px",

                        "@media (min-width: 1350px)": {
                          width: "20px",
                          height: "20px",
                        },
                      },
                    }}
                  >
                    <PlusIcon />
                  </Button>
                  <Divider
                    orientation="vertical"
                    sx={{
                      height: "24px",

                      "@media (min-width: 1350px)": {
                        marginRight: "14px",
                        marginLeft: "14px",
                      },
                    }}
                  />
                </Box>
                {/* <Button
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "2px",
                minWidth: "1px",
                padding: "0",
                color: palette.grayAccent.main,
                width: "24px",
                height: "24px",

                "@media (min-width: 1350px)": {
                  width: "32px",
                  height: "32px",
                },

                "&:hover": {
                  backgroundColor: palette.settings.lighter,
                  color: palette.grayAccent.main,
                },

                ".MuiSvgIcon-root": {
                  width: "14px",
                  height: "14px",

                  "@media (min-width: 1350px)": {
                    width: "20px",
                    height: "20px",
                  },
                },
              }}
            >
              <FileExportIcon />
            </Button>
            <Button
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "2px",
                minWidth: "1px",
                padding: "0",
                color: palette.grayAccent.main,
                width: "24px",
                height: "24px",

                "@media (min-width: 1350px)": {
                  width: "32px",
                  height: "32px",
                },

                "&:hover": {
                  backgroundColor: palette.settings.lighter,
                  color: palette.grayAccent.main,
                },

                ".MuiSvgIcon-root": {
                  width: "14px",
                  height: "14px",

                  "@media (min-width: 1350px)": {
                    width: "20px",
                    height: "20px",
                  },
                },
              }}
            >
              <EditPencilIcon />
            </Button> */}
                <Link
                  href={data?.preSignedUrl.presignedUrl || ""}
                  target="_blank"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "2px",
                    minWidth: "1px",
                    padding: "0",
                    color: palette.grayAccent.main,
                    width: "24px",
                    height: "24px",

                    "@media (min-width: 1350px)": {
                      width: "32px",
                      height: "32px",
                    },

                    "&:hover": {
                      backgroundColor: palette.settings.lighter,
                      color: palette.grayAccent.main,
                    },

                    ".MuiSvgIcon-root": {
                      width: "14px",
                      height: "14px",

                      "@media (min-width: 1350px)": {
                        width: "20px",
                        height: "20px",
                      },
                    },
                  }}
                >
                  <DownloadFileIcon />
                </Link>
                {/* <Button
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "2px",
                minWidth: "1px",
                padding: "0",
                color: palette.grayAccent.main,
                width: "24px",
                height: "24px",

                "@media (min-width: 1350px)": {
                  width: "32px",
                  height: "32px",
                },

                "&:hover": {
                  backgroundColor: palette.settings.lighter,
                  color: palette.grayAccent.main,
                },

                ".MuiSvgIcon-root": {
                  width: "14px",
                  height: "14px",

                  "@media (min-width: 1350px)": {
                    width: "20px",
                    height: "20px",
                  },
                },
              }}
            >
              <TrippleVerticalDotsIcon />
            </Button> */}
                {/* {pageNumber}/{numPages}
            <span>
              <Button>-</Button>
            </span>
            100%
            <span><Button>+</Button></span> */}
              </>
            )}
          </Box>
          {/* <Button>Copy</Button>
          <Button>Edit</Button>
          <Button>Share</Button>
          <Button>Download</Button>
          <Button onClick={() => setResource('')}>Back</Button>
          <Button>BrightBot</Button> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexShrink: "0",
            maxWidth: "30%",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "8px",

            "@media (min-width: 1350px)": {
              gap: "16px",
            },
          }}
        >
          <Divider
            orientation="vertical"
            sx={{
              height: "36px",
            }}
          />
          <Button
            sx={{
              color: palette.grayAccent.main,
              backgroundColor: "transparent",
              minWidth: "1px",
              padding: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "4px",
              width: "24px",
              height: "24px",

              "@media (min-width: 1350px)": {
                width: "32px",
                height: "32px",
              },

              "&:hover": {
                backgroundColor: palette.settings.lighter,
                color: palette.grayAccent.main,
              },
            }}
          >
            <ChatBotIcon />
          </Button>
          <Button onClick={() => navigate(-1)}>Back</Button>
        </Box>
      </Box>
      <Box
        sx={{
          flex: "1",
          height: "calc(100% - 70px)",
        }}
      >
        {data?.preSignedUrl.presignedUrl && (
          <>
            {isImage ? (
              <ImageViewer url={data?.preSignedUrl.presignedUrl} zoom={zoom} />
            ) : isVideo ? (
              <video width="100%" height="100%" controls>
                <source
                  src={data?.preSignedUrl.presignedUrl}
                  type="video/mp4"
                />
                <source
                  src={data?.preSignedUrl.presignedUrl}
                  type="video/ogg"
                />
                Your browser does not support the video tag.
              </video>
            ) : (
              <DocViewer
                prefetchMethod="GET"
                documents={[
                  {
                    uri: data?.preSignedUrl.presignedUrl || "",
                  },
                ]}
                pluginRenderers={DocViewerRenderers}
                config={{
                  loadingRenderer: {
                    overrideComponent: DocPreviewLoader,
                    showLoadingTimeout: 1000, // false if you want to disable or number to provide your own value (ms)
                  },
                }}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
