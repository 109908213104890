import { Box, Divider, useTheme } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import BusinessIcon from "@mui/icons-material/Business";
import { DOCLink, Link } from "../../common/Link/Link";
import { IconCell } from "../CellRenderer/IconCell";
import { Tag } from "../../common/Tag/Tag";
import SyncIcon from "@mui/icons-material/Sync";
import { Button } from "../../common/Button/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useState } from "react";
import { CopyInput } from "../../common/CopyInput/CopyInput";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

export const EngineConfiguration = () => {
  const { palette } = useTheme();
  const [openConfiguration, setOpenConfiguration] = useState(false);

  return (
    <Box>
      <Box>
        <Typography gutterBottom variant="subtitle3">
          Description
        </Typography>
        <Typography variant="body2" color="gray.darkest">
          Description goes here if available
        </Typography>
      </Box>

      <Box mt={5}>
        <Typography gutterBottom variant="subtitle3">
          Connection owner
        </Typography>
        <Typography variant="body2" color="gray.darkest">
          <Box display="flex" gap={2} alignItems="center">
            <BusinessIcon color="disabled" fontSize="small" /> Citrus University
          </Box>
        </Typography>
      </Box>

      <Box mt={5}>
        <Typography gutterBottom variant="subtitle3">
          Point of contact
        </Typography>
        <Typography variant="body2" color="gray.darkest">
          Kara Thrace
        </Typography>
        <Link sx={{ fontWeight: "bold" }} to="mailto:kthrace@citrusu.edu">
          kthrace@citrusu.edu
        </Link>
      </Box>

      <Box mt={5}>
        <Divider />
      </Box>

      <Box mt={5}>
        <Typography gutterBottom variant="subtitle3">
          Service type
        </Typography>
        <Typography variant="body2" color="gray.darkest">
          <Box display="flex" gap={2} alignItems="center">
            <IconCell value="BigQuery" /> BigQuery
          </Box>
        </Typography>
      </Box>

      <Box mt={5}>
        <Button
          variant="outlined"
          size="small"
          sx={{
            color: (theme) => theme.palette.text.primary,
          }}
          startIcon={
            <KeyboardArrowRightIcon
              style={{
                transition: "0.3s",
                transform: openConfiguration ? "rotate(90deg)" : "rotate(0deg)",
              }}
            />
          }
          onClick={() => setOpenConfiguration(!openConfiguration)}
        >
          Show configuration details
        </Button>
        {openConfiguration && (
          <Box
            mt={4}
            p={4}
            borderRadius={1}
            sx={{
              border: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            <CopyInput
              fullWidth
              label="API endpoint URL"
              value="https://api.brighthive.data.com"
            />
            <Box mt={4} />
            <CopyInput
              fullWidth
              label="API endpoint URL"
              value="workspace-namespace"
            />
            <Box mt={4} />
            <CopyInput
              fullWidth
              label="Marquez API key"
              value="•••••••••••••••••••••••••••••••"
              sx={{
                backgroundColor: palette.disabledLightBackground,
              }}
              endAdornment={<Box></Box>}
            />
            <Box display="flex" gap={2} mt={1}>
              <InfoOutlined fontSize="small" color="error" />
              <Typography variant="body3">Last update 10/28/2022</Typography>
            </Box>
            <Box mt={4} />
            <DOCLink href="/" name="Marquez documentation" />
          </Box>
        )}
      </Box>

      <Box mt={5}>
        <Typography gutterBottom variant="subtitle3">
          # Jobs
        </Typography>
        <Typography variant="body2" color="gray.darkest">
          24
        </Typography>
      </Box>

      <Box mt={5}>
        <Typography gutterBottom variant="subtitle3">
          Languages
        </Typography>
        <Tag label="SQL" />
      </Box>

      <Box mt={5}>
        <Divider />
      </Box>

      <Box mt={5}>
        <Typography gutterBottom variant="subtitle3">
          Usage status
        </Typography>
        <Tag label="participants" startIcon={<SyncIcon />} />
      </Box>

      <Box mt={5}>
        <Typography gutterBottom variant="subtitle3">
          Projects (3)
        </Typography>
        <DOCLink href="/" name="Project name" />
        <DOCLink href="/" name="Project name" />
        <DOCLink href="/" name="Project name" />
      </Box>
    </Box>
  );
};
