import {
  Box,
  Drawer,
  Divider,
  IconButton,
  AutocompleteRenderInputParams,
  TextField,
} from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import Close from "@mui/icons-material/Close";
import { Field, Formik } from "formik";
import { Autocomplete, TextField as TextFieldFormik } from "formik-mui";
import { CopyInput } from "../../common/CopyInput/CopyInput";
import { Button } from "../../common/Button/Button";
import { DOCLink } from "../../common/Link/Link";
import { useState } from "react";
import { ApiKeySuccessDialog } from "./ApiKeySuccessDialog";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ReplaceApiKeyDialog } from "./ReplaceApiKeyDialog";

interface AddEngineDialogProps {
  open: boolean;
  onClose: () => void;
}

export const AddEngineDialog = ({ open, onClose }: AddEngineDialogProps) => {
  const [openGenerate, setOpenGenerate] = useState(false);
  const [openRegenerate, setOpenRegenerate] = useState(false);

  return (
    <>
      <Drawer anchor="right" open={open} onClose={onClose}>
        <Box width={400} p={8}>
          <Box position="relative" mb={4}>
            <Box position="absolute" top={-10} right={0}>
              <IconButton onClick={() => onClose()} size="small">
                <Close />
              </IconButton>
            </Box>
            <Typography variant="overline">ENGINE</Typography>
            <Typography mt={1} mb={3} variant="h5">
              New Engine
            </Typography>
          </Box>

          <Divider />
          <Box mt={6}>
            <Typography variant="subtitle1">About</Typography>
            <Formik
              initialValues={{
                source: null,
              }}
              onSubmit={() => {}}
            >
              {() => {
                return (
                  <Box mt={4}>
                    <Box>
                      <label htmlFor="name">
                        <Typography gutterBottom variant="subtitle3">
                          Engine name
                        </Typography>
                      </label>
                      <Field
                        id="name"
                        name="name"
                        component={TextFieldFormik}
                        size="small"
                        fullWidth
                      />
                    </Box>
                    <Box mt={6}>
                      <label htmlFor="description">
                        <Box
                          mb={1}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Typography variant="subtitle3">
                            Engine description
                          </Typography>
                          <Typography variant="caption">Optional</Typography>
                        </Box>
                      </label>
                      <Field
                        id="description"
                        name="description"
                        component={TextFieldFormik}
                        placeholder="Describe the jobs available in this engine"
                        size="small"
                        fullWidth
                        rows={4}
                        multiline
                      />
                    </Box>
                    <Box mt={6}>
                      <Field
                        name="source"
                        component={Autocomplete}
                        options={["Citrus University"]}
                        size="small"
                        renderInput={(
                          params: AutocompleteRenderInputParams
                        ) => (
                          <Box>
                            <Typography
                              fontWeight="bold"
                              gutterBottom
                              variant="body3"
                            >
                              Source Type
                            </Typography>
                            <TextField {...params} />
                          </Box>
                        )}
                      />
                    </Box>
                    <Box mt={6}>
                      <label htmlFor="contact_name">
                        <Typography gutterBottom variant="subtitle3">
                          Point of contact name
                        </Typography>
                      </label>
                      <Field
                        id="contact_name"
                        name="contact_name"
                        component={TextFieldFormik}
                        size="small"
                        fullWidth
                      />
                    </Box>
                    <Box mt={6}>
                      <label htmlFor="contact_email">
                        <Typography gutterBottom variant="subtitle3">
                          Point of contact email
                        </Typography>
                      </label>
                      <Field
                        id="contact_email"
                        name="contact_email"
                        component={TextFieldFormik}
                        size="small"
                        fullWidth
                        placeholder="First and last name"
                      />
                    </Box>
                    <Box mt={6}>
                      <Divider />
                    </Box>
                    <Box mt={6}>
                      <Typography gutterBottom variant="subtitle1">
                        Configuration
                      </Typography>
                      <Typography variant="body3">
                        Add this information to your engine configuration to
                        allow this workspace to get jobs.
                      </Typography>
                    </Box>
                    <Box mt={4}>
                      <CopyInput
                        label="API endpoint URL"
                        value="https://api.brighthive.data.com"
                        fullWidth
                      />
                    </Box>
                    <Box mt={4}>
                      <CopyInput
                        label="API endpoint URL"
                        value="workspace-namespace"
                        fullWidth
                      />
                    </Box>
                    <Box mt={4}>
                      <Typography gutterBottom variant="subtitle3">
                        Marquez API key
                      </Typography>
                      {true ? (
                        <>
                          <TextField
                            type="password"
                            value="•••••••••••••••••••••••••••••••"
                            size="small"
                            fullWidth
                            disabled
                            sx={{
                              mb: 2,
                            }}
                          />
                          <Button
                            variant="outlined"
                            color="grayAccent"
                            onClick={() => setOpenRegenerate(true)}
                            startIcon={<RefreshIcon />}
                          >
                            Re-generate API key
                          </Button>
                        </>
                      ) : (
                        <>
                          <Typography color="gray" mb={2} variant="body2">
                            None
                          </Typography>
                          <Button
                            variant="contained"
                            color="grayAccent"
                            onClick={() => setOpenGenerate(true)}
                          >
                            Generate API key
                          </Button>
                        </>
                      )}
                      <Box mt={5}>
                        <DOCLink
                          name="Marquez documentation"
                          href="https://marquezproject.ai"
                          target="_black"
                        />
                      </Box>
                    </Box>
                    <Box mt={12}>
                      <Box mb={3}>
                        <Button fullWidth variant="contained">
                          Save
                        </Button>
                      </Box>
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => onClose()}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                );
              }}
            </Formik>
          </Box>
        </Box>
      </Drawer>
      <ApiKeySuccessDialog
        open={openGenerate}
        onClose={() => setOpenGenerate(false)}
      />
      <ReplaceApiKeyDialog
        open={openRegenerate}
        onClose={() => setOpenRegenerate(false)}
        onSubmit={() => setOpenGenerate(true)}
      />
    </>
  );
};
