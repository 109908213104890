import React from "react";
import {
  OutlinedInput,
  Select as MUISelect,
  SelectProps as MUISelectProps,
  useTheme,
} from "@mui/material";

export const Select = React.forwardRef(
  ({ sx, MenuProps, id, ...rest }: MUISelectProps, ref) => {
    const { typography } = useTheme();

    return (
      <MUISelect
        ref={ref}
        id={id}
        sx={{
          pt: 0,
          pb: 0,
          pl: 0,
          pr: 0,
          ...typography.body3,
          ".MuiSelect-select": {
            pt: 2,
            pb: 2,
            pl: 3,
            pr: 3,
          },
          height: "2.25rem",
          ...sx,
        }}
        MenuProps={{
          sx: {
            ".MuiList-root": {
              p: 0,
            },
          },
          ...MenuProps,
        }}
        input={<OutlinedInput />}
        {...rest}
      />
    );
  }
);
