import React from "react";
import { BasicPage } from "../common/BasicPage/BasicPage";
import { Typography } from "../common/Typography/Typography";
import { Box, Card, useTheme } from "@mui/material";
import { ColoredAvatar } from "../common/ColoredAvatar/ColoredAvatar";
import { CorporateFare } from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import { GetCurrentUserInfoDocument } from "../generated";
import ProfileAvatarIcon from "../common/ProfileAvatarIcon/ProfileAvatarIcon";

export interface MyProfilePageProps {}

export const MyProfilePage = () => {
  const { palette } = useTheme();

  const { data } = useQuery(GetCurrentUserInfoDocument);

  const fullName = `${data?.currentUser?.firstName || ""} ${
    data?.currentUser?.lastName
  }`;

  const emailAddress = data?.currentUser?.emailAddress;

  const organizationNames = data?.currentUser?.workspaces?.reduce(
    (accum, workspace) => {
      const orgName = workspace?.ownerOrganizationName;
      if (orgName) {
        if (accum === "") {
          return orgName;
        } else {
          return `${accum}, ${orgName}`;
        }
      }
      return accum;
    },
    ""
  );

  return (
    <BasicPage
      leftHeaderElements={<Typography variant="h4">My profile</Typography>}
      content={
        <Card sx={{ pt: 6, pl: 8, pr: 8, pb: 10 }}>
          <Typography mb={4} variant="h6">
            Profile information
          </Typography>

          <Box mb={6}>
            <Box mb={2}>
              <Typography color={palette.gray.dark} variant="overline">
                NAME
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <ProfileAvatarIcon
                name={fullName}
                email={emailAddress}
                width={24}
                height={24}
              />
              <Typography variant="body2" ml={1}>
                {fullName}
              </Typography>
            </Box>
          </Box>

          <Box mb={6}>
            <Box mb={2}>
              <Typography color={palette.gray.dark} variant="overline">
                ORGANIZATIONS
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <CorporateFare
                sx={{
                  mr: 2,
                  color: palette.gray.dark,
                  fontSize: "1rem",
                  width: "1rem",
                  height: "1rem",
                }}
              />
              <Typography variant="body2">{organizationNames}</Typography>
            </Box>
          </Box>

          {/*<Box mb={6}>*/}
          {/*  <Box mb={2}>*/}
          {/*    <Typography color={palette.gray.dark} variant="overline">*/}
          {/*      CONTACT*/}
          {/*    </Typography>*/}
          {/*  </Box>*/}
          {/*  <Box>*/}
          {/*    <Link fontWeight="bold" variant="body2" to="/#">*/}
          {/*      email@email.com*/}
          {/*    </Link>*/}
          {/*  </Box>*/}
          {/*</Box>*/}

          {/*<Box>*/}
          {/*  <Box mb={2}>*/}
          {/*    <Typography color={palette.gray.dark} variant="overline">*/}
          {/*      ACCOUNT CREATED*/}
          {/*    </Typography>*/}
          {/*  </Box>*/}
          {/*  <Box>*/}
          {/*    <Typography variant="body2">1/23/22</Typography>*/}
          {/*  </Box>*/}
          {/*</Box>*/}
        </Card>
      }
    />
  );
};
