import React, { useState } from "react";
import { Box, Divider, Popover, useTheme } from "@mui/material";
import { useMenu } from "../Menu/useMenu";
import { Button } from "../Button/Button";
import {
  DuplicateProjectDocument,
  GetProjectQuery,
  ProjectStatus,
} from "../../generated";
import { ApolloQueryResult, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { convertToTitleCase } from "../../helpers";
import { getSource } from "../../Sources/sourceIcons";
import { ActionButton, ButtonPrimary } from "./style";

export const AddSourceButton = ({ setSource }: any) => {
  const [menuOpen, anchorEl, openMenuFromAnchorElement, closeMenu] = useMenu();
  const { palette } = useTheme();

  return (
    <>
      <ButtonPrimary
        id="project-action-button"
        aria-controls={menuOpen ? "project-action-items" : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        color="grayAccent"
        variant="outlined"
        onClick={openMenuFromAnchorElement}
      >
        Add Source
      </ButtonPrimary>
      <Popover
        id="project-action-items"
        aria-labelledby="project-action-button"
        open={menuOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        onClose={closeMenu}
        sx={{
          ".MuiPaper-root": {
            background: palette.common.white,
            border: "0",
            boxShadow: `0 2px 2px ${palette.gray.main}, 0 3px 1px ${palette.action.disabledBackground}, 0 1px 5px ${palette.action.disabled}`,
            borderRadius: "4px",
            display: "flex",
            flexDirection: "column",
            padding: "8px",
          },
          ".MuiButtonBase-root": {
            padding: "12px 19px",
            textAlign: "left",
            boxShadow: "none",
            border: "0 !important",
            justifyContent: "flex-start",
            color: palette.grayAccent.light,
          },
        }}
      >
        <>
          {["Internal"].map((source) => (
            <ActionButton
              id="project-action-button"
              aria-controls={menuOpen ? "project-action-items" : undefined}
              aria-haspopup="true"
              aria-expanded={menuOpen ? "true" : undefined}
              color="grayAccent"
              variant="outlined"
              onClick={() => {
                closeMenu();
                setSource(source);
              }}
            >
              {getSource(source, "csb-dropdown-icn")}
              {convertToTitleCase(source)}
            </ActionButton>
          ))}
        </>
      </Popover>
    </>
  );
};
