import {
  Box,
  useTheme,
  Popover,
  Button,
  Dialog,
  Typography,
} from "@mui/material";
import { useMenu } from "../../common/Menu/useMenu";
import { EditPencilIcon } from "../../common/Icons/EditPencilIcon";
import { DownloadFileIcon } from "../../common/Icons/DownloadFileIcon";
import { MoveFileIcon } from "../../common/Icons/MoveFileIcon";
import { ShareIcon } from "../../common/Icons/ShareIcon";
import { DeleteIcon } from "../../common/Icons/DeleteIcon";
import { useMutation } from "@apollo/client";
import {
  DeleteResourceDocument,
  DeleteTermDocument,
  GetResourcesDocument,
  TermsDocument,
} from "../../generated";
import { toast } from "react-toastify";
import { DocumentResourceIcon } from "../../common/Icons/DocumentResourceIcon";
import { MessagesIcon } from "../../common/Icons/MessagesIcon";
import { TrippleHorizontalDotsIcon } from "../../common/Icons/TrippleHorizontalDotsIcon";
import { useState } from "react";
import { ButtonDefault } from "./style";

export const ActionCell = ({ data, setDetail }: any) => {
  const [menuOpen, anchorEl, openMenuFromAnchorElement, closeMenu] = useMenu();
  const { palette } = useTheme();

  const [dialogOpen, setDialogOpen] = useState(false);
  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const [deleteTerm, { loading }] = useMutation(DeleteTermDocument, {
    refetchQueries: [TermsDocument],
  });

  const deleteTermHandler = (id: string) => {
    deleteTerm({
      variables: {
        deleteTermInput2: {
          termId: id,
        },
      },
    }).then(() => {
      closeMenu();
      closeDialog();
    });
  };

  return (
    <Box>
      <ButtonDefault onClick={openMenuFromAnchorElement}>
        <TrippleHorizontalDotsIcon />
      </ButtonDefault>
      <Popover
        id="project-action-items"
        aria-labelledby="project-action-button"
        open={menuOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        onClose={closeMenu}
        sx={{
          ".MuiPaper-root": {
            background: palette.common.white,
            border: "0",
            boxShadow: `0 2px 2px ${palette.gray.main}, 0 3px 1px ${palette.action.disabledBackground}, 0 1px 5px ${palette.action.disabled}`,
            borderRadius: "4px",
            display: "flex",
            flexDirection: "column",
            padding: "0",
            width: "246px",
          },
          "> .MuiButtonBase-root": {
            padding: "12px 19px",
            textAlign: "left",
            boxShadow: "none",
            border: "0 !important",
            justifyContent: "flex-start",
            color: palette.grayAccent.light,
          },
        }}
      >
        <>
          <Button
            onClick={() => setDetail(data)}
            sx={{
              display: "flex",
              columnGap: "13px",
              alignItems: "center",
              fontSize: "16px",
              lineHeight: "24px",
              padding: "8px 12px",
              justifyContent: "space-between",
              fontWeight: "400",
              color: palette.accent.contrastText,
              borderRadius: "0",

              "&:hover": {
                backgroundColor: palette.primary.active,
                color: palette.primary.dark,
              },

              ".csb-dropdown-icn": {
                width: "13px",
                height: "13px",
              },

              ".icn": {
                width: "20px",
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                lineHeight: "0",
                color: palette.grayAccent.light,
              },

              ".MuiSvgIcon-root": {
                width: "15px",
                height: "15px",
              },
            }}
          >
            <span>View Details</span>
            <i className="icn">
              <DocumentResourceIcon />
            </i>
          </Button>
          <Button
            onClick={() => console.log("err")}
            disabled={true}
            sx={{
              display: "flex",
              columnGap: "13px",
              alignItems: "center",
              fontSize: "16px",
              lineHeight: "24px",
              padding: "8px 12px",
              justifyContent: "space-between",
              fontWeight: "400",
              color: palette.accent.contrastText,
              borderRadius: "0",

              "&:hover": {
                backgroundColor: palette.primary.active,
                color: palette.primary.dark,
              },

              ".csb-dropdown-icn": {
                width: "13px",
                height: "13px",
              },

              ".icn": {
                width: "20px",
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                lineHeight: "0",
                color: palette.grayAccent.light,
              },

              ".MuiSvgIcon-root": {
                width: "15px",
                height: "15px",
              },
            }}
          >
            <span>Discussion Threads</span>
            <i className="icn">
              <MessagesIcon />
            </i>
          </Button>
          <Button
            onClick={() => setDetail({ ...data, edit: true })}
            sx={{
              display: "flex",
              columnGap: "13px",
              alignItems: "center",
              fontSize: "16px",
              lineHeight: "24px",
              padding: "8px 12px",
              justifyContent: "space-between",
              fontWeight: "400",
              color: palette.accent.contrastText,
              borderRadius: "0",

              "&:hover": {
                backgroundColor: palette.primary.active,
                color: palette.primary.dark,
              },

              ".csb-dropdown-icn": {
                width: "13px",
                height: "13px",
              },

              ".icn": {
                width: "20px",
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                lineHeight: "0",
                color: palette.grayAccent.light,
              },

              ".MuiSvgIcon-root": {
                width: "15px",
                height: "15px",
              },
            }}
          >
            <span>Update Term</span>
            <i className="icn">
              <EditPencilIcon />
            </i>
          </Button>
          <Button
            onClick={() => console.log("err")}
            disabled={true}
            sx={{
              display: "flex",
              columnGap: "13px",
              alignItems: "center",
              fontSize: "16px",
              lineHeight: "24px",
              padding: "8px 12px",
              justifyContent: "space-between",
              fontWeight: "400",
              color: palette.accent.contrastText,
              borderRadius: "0",

              "&:hover": {
                backgroundColor: palette.primary.active,
                color: palette.primary.dark,
              },

              ".csb-dropdown-icn": {
                width: "13px",
                height: "13px",
              },

              ".icn": {
                width: "20px",
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",

                lineHeight: "0",
                color: palette.grayAccent.light,
              },

              ".MuiSvgIcon-root": {
                width: "15px",
                height: "15px",
              },
            }}
          >
            <span>Share</span>
            <i className="icn">
              <ShareIcon />
            </i>
          </Button>
          <Button
            onClick={() => openDialog()}
            sx={{
              display: "flex",
              columnGap: "13px",
              alignItems: "center",
              fontSize: "16px",
              lineHeight: "24px",
              padding: "8px 12px",
              justifyContent: "space-between",
              fontWeight: "400",
              color: palette.accent.contrastText,
              borderRadius: "0",

              "&:hover": {
                backgroundColor: palette.primary.active,
                color: palette.primary.dark,
              },

              ".csb-dropdown-icn": {
                width: "13px",
                height: "13px",
              },

              ".icn": {
                width: "20px",
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                lineHeight: "0",
                color: palette.grayAccent.light,
              },

              ".MuiSvgIcon-root": {
                width: "15px",
                height: "15px",
              },
            }}
          >
            <span>Delete</span>
            <i className="icn">
              <DeleteIcon />
            </i>
          </Button>
        </>
      </Popover>

      <Dialog open={dialogOpen} onClose={closeDialog}>
        <Box width="35rem" p={6} boxSizing="border-box">
          <Typography mb={4} variant="h5">
            Disable Term
          </Typography>
          <Typography color={palette.gray.dark} mb={4} variant="body2">
            By deleting term, you can no longer have it on workspace.
          </Typography>
          <Box display="flex" mt={7} justifyContent="flex-end">
            <Button
              onClick={closeDialog}
              color="grayAccent"
              variant="outlined"
              sx={{ mr: 3 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => deleteTermHandler(data.id)}
              color="error"
              variant="contained"
              disabled={loading}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};
