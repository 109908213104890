import { Chip } from "../../../common/Chip/Chip";
import CheckIcon from "@mui/icons-material/Check";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";

interface StatusCellProps {
  value: string;
}

export const StatusCell = ({ value }: StatusCellProps) => {
  let icon = <InfoOutlinedIcon />;
  let color = "error";

  if (value.toLowerCase() === "Connected".toLowerCase()) {
    icon = <CheckIcon />;
    color = "primary";
  }

  if (value.toLowerCase() === "Requsted".toLowerCase()) {
    icon = <CheckIcon />;
    color = "warning";
  }

  if (value.toLowerCase() === "Expired".toLowerCase()) {
    icon = <CheckIcon />;
    color = "secondry";
  }

  if (value.toLowerCase() === "Disabled".toLowerCase()) {
    icon = <DoNotDisturbIcon />;
    color = "error";
  }

  return (
    <Chip
      className="csb-status-tag"
      label={value}
      icon={icon}
      color={color as any}
    />
  );
};
