import React, { useState } from "react";
import {
  Box,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { Button } from "../../common/Button/Button";
import { Label } from "../../common/Label/Label";
import { Field, Formik, Form } from "formik";
import { TextField } from "formik-mui";
import { MAX_DESCRIPTION_CHARS } from "../../Constants";
import * as Yup from "yup";
import Editor from "@monaco-editor/react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

interface ModalSchemaFieldProps {
  show: boolean;
  editMode?: boolean;
  closeDialog: Function;
  submit: Function;
}

export const ModalSchemaField = ({
  show,
  editMode = false,
  closeDialog,
  submit,
}: ModalSchemaFieldProps) => {
  const { palette } = useTheme();
  const [open, setOpen] = useState(false);

  return (
    <Formik
      validationSchema={Yup.object().shape({
        name: Yup.string().required(),
        description: Yup.string().required().max(MAX_DESCRIPTION_CHARS),
        jsonSchema: Yup.string().required(),
      })}
      initialValues={{
        name: "",
        description: "",
        jsonSchema: JSON.stringify(
          {
            $id: "https://example.com/blank.schema.json",
            title: "Blank-schema-template",
            description: "",
            type: "object",
            properties: {},
          },
          null,
          2
        ),
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          setSubmitting(true);
          await submit({
            ...values,
          });
          setSubmitting(false);
          closeDialog();
        } catch (err) {
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        submitForm,
        setFieldValue,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Dialog maxWidth="xl" open={show} onClose={() => closeDialog()}>
              <DialogTitle>
                <Typography variant="h5">
                  {editMode ? "Edit schema" : "Create new schema"}
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Box width="50rem" boxSizing="border-box">
                  <Box mt={4}>
                    <Label mb={1} value="Name" width={"15.5rem"} />
                    <Field
                      component={TextField}
                      name="name"
                      hiddenLabel
                      fullWidth
                      inputProps={{
                        sx: { fontSize: "1rem", padding: ".375rem .75rem" },
                      }}
                      size="small"
                      sx={{ mb: 6 }}
                    />

                    <Label mb={1} value="Description" width={"15.5rem"} />
                    <Field
                      component={TextField}
                      name="description"
                      hiddenLabel
                      rows={3}
                      fullWidth
                      multiline
                      inputProps={{
                        sx: { fontSize: "1rem" },
                        maxLength: MAX_DESCRIPTION_CHARS,
                      }}
                      size="small"
                      sx={{ mb: 2 }}
                    />

                    <Typography
                      variant="body3"
                      color={palette.gray.dark}
                      sx={{ mb: 6 }}
                    >
                      {values.description.length}/{MAX_DESCRIPTION_CHARS}{" "}
                      characters
                    </Typography>

                    <Label
                      mb={1}
                      value="Edit Raw JSON Schema (optional)"
                      width={"15.5rem"}
                    />
                    <Box>
                      <Button
                        variant="outlined"
                        color="grayAccent"
                        size="small"
                        startIcon={<NavigateNextIcon />}
                        onClick={() => setOpen(!open)}
                      >
                        Show Raw JSON Schema
                      </Button>
                    </Box>
                    <Collapse in={open} timeout="auto">
                      <Box
                        sx={{
                          border: `1px solid ${palette.grayAccent.light}`,
                          borderRadius: "4px",
                          padding: "4px",
                          mt: "8px",
                        }}
                      >
                        <Editor
                          height="300px"
                          defaultLanguage="json"
                          defaultValue={JSON.stringify(
                            {
                              $id: "https://example.com/blank.schema.json",
                              title: "Blank-schema-template",
                              description: "",
                              type: "object",
                              properties: {},
                            },
                            null,
                            2
                          )}
                          onChange={(v) => setFieldValue("jsonSchema", v)}
                        />
                      </Box>
                    </Collapse>
                  </Box>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => closeDialog()}
                  color="grayAccent"
                  variant="outlined"
                  sx={{ ml: "auto", mr: 3 }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  onClick={submitForm}
                  color="primary"
                  variant="contained"
                >
                  {editMode ? "Save changes" : "Next"}
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        );
      }}
    </Formik>
  );
};
