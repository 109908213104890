import { Tooltip, TooltipProps, Zoom } from "@mui/material";
import { PropsWithChildren } from "react";

type Props = PropsWithChildren<TooltipProps>;

const CommonTooltip = ({ children, ...props }: Props) => {
  return (
    <Tooltip
      enterNextDelay={700}
      enterDelay={700}
      TransitionComponent={Zoom}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default CommonTooltip;
