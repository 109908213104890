import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { Chip } from "../../common/Chip/Chip";
import { DestinationDetails } from "./DestinationDetails";
import { EditDestination } from "./EditDestination";
import { Button } from "../../common/Button/Button";
import { useMutation, useQuery } from "@apollo/client";
import {
  DestinationType,
  DownloadFileDestinationDocument,
  GetDestinationSheetsDocument,
  GetOrganizationMembersDocument,
  GetOrganizationSheetDocument,
  GetOrganizationsDocument,
} from "../../generated";
import { useParams } from "react-router-dom";
import {
  getIssueIcon,
  getIssueColor,
  getLabelIssueStatus,
  getLoader,
} from "../helpers";
import { SheetTabs } from "../SheetTabs";
import { SheetChat } from "../SheetChat";
import { downloadUrl } from "../../helpers";

export const Destination = ({
  id,
  showToast,
}: {
  id: string;
  showToast: any;
}) => {
  const { palette } = useTheme();
  const { workspaceId, projectId } = useParams();
  const { data, loading } = useQuery(GetDestinationSheetsDocument, {
    variables: {
      workflowFilterInput: {
        projectId: projectId || "",
        workspaceId: workspaceId || "",
      },
      destinationFilter: {
        destinationId: id,
      },
    },
  });

  const [download] = useMutation(DownloadFileDestinationDocument);

  const [isEditState, setIsEditState] = useState(false);
  const [isEditable, setIsEditable] = useState(true);
  const [show, setShow] = useState(false);
  const [tab, setTab] = useState(0);

  const toggleEditView = () => {
    setIsEditState(!isEditState);
    setIsEditable(!isEditable);
  };

  if (loading) return getLoader("Fetching Data", "custom-loader full-height");
  const destinationData = data?.workflow?.destinations?.[0];
  if (destinationData) {
    return (
      <Box
        boxSizing="border-box"
        sx={{
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            paddingRight: "20px",
            paddingBottom: "16px",
            borderBottom: `1px solid ${palette.outline}`,
          }}
          mb={6}
        >
          <Typography
            mb={2}
            variant="h5"
            color={palette.gray.dark}
            sx={{
              fontSize: "12px",
              lineHeight: "14px",
              textTransform: "uppercase",
              fontWeight: "700",
            }}
          >
            Destination
          </Typography>
          <Typography
            mb={4}
            variant="h5"
            color={palette.common.black}
            sx={{ wordBreak: "break-word" }}
          >
            {destinationData?.name}
          </Typography>
          {show && (
            <Chip
              icon={getIssueIcon(destinationData.issueStatus)}
              color={getIssueColor(destinationData.issueStatus)}
              label={getLabelIssueStatus(destinationData.issueStatus)}
              sx={{
                color: palette.common.black,
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: "500",
              }}
            />
          )}

          <SheetTabs value={tab} onChange={setTab} />
        </Box>
        {tab === 0 && (
          <Box
            boxSizing="border-box"
            sx={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {isEditState ? (
              <EditDestination
                destination={destinationData}
                toggleEditView={toggleEditView}
                showToast={showToast}
              />
            ) : (
              <DestinationDetails
                issueType={destinationData.issueStatus}
                destination={destinationData}
              />
            )}
            {/* {isEditable && !isEditState && destinationData.description && (
              <Box>
                <Button
                  color="grayAccent"
                  variant="contained"
                  sx={{ width: "100%" }}
                  onClick={toggleEditView}
                >
                  Edit
                </Button>
              </Box>
            )} */}
            {destinationData.destinationType === DestinationType.Download && (
              <Box>
                <Button
                  color="grayAccent"
                  variant="contained"
                  sx={{ width: "100%" }}
                  onClick={() => {
                    download({
                      variables: {
                        input: {
                          workspaceId: workspaceId || "",
                          destinationId: destinationData.id,
                        },
                      },
                    }).then((res) =>
                      downloadUrl(
                        res.data?.downloadFileDestination.presignedUrl || "",
                        "download"
                      )
                    );
                  }}
                >
                  Download
                </Button>
              </Box>
            )}
          </Box>
        )}

        {tab === 1 && <SheetChat id={id} />}
      </Box>
    );
  }
  return null;
};
