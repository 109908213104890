import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const MinusIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0001 10.0833H10.8334H5.00008C4.9795 10.0833 4.95925 10.0756 4.94187 10.0582C4.92449 10.0408 4.91675 10.0206 4.91675 10C4.91675 9.97942 4.92449 9.95917 4.94187 9.94179C4.95925 9.92442 4.9795 9.91667 5.00008 9.91667H10.8334H15.0001C15.0207 9.91667 15.0409 9.92442 15.0583 9.94179C15.0757 9.95917 15.0834 9.97942 15.0834 10C15.0834 10.0206 15.0757 10.0408 15.0583 10.0582C15.0409 10.0756 15.0207 10.0833 15.0001 10.0833Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </SvgIcon>
  );
};
