import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";

interface Column {
  field: string;
  headerName: string;
  cellRenderer?: any;
  headerRenderer?: any;
}

interface TableDataProps {
  columns: Column[];
  rowData: any[];
  onRowClicked?: (row: any) => void;
}

export const TableData = ({
  columns,
  rowData,
  onRowClicked,
}: TableDataProps) => {
  return (
    <Table aria-label="table">
      <TableHead>
        <TableRow>
          {columns.map((item) => (
            <TableCell
              size="small"
              key={item.field}
              sx={{
                fontSize: ".88rem",
                borderTop: (theme) => `1px solid ${theme.palette.divider}`,
              }}
            >
              {item.headerRenderer
                ? item.headerRenderer(item)
                : item.headerName}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rowData.map((row) => (
          <TableRow
            hover
            key={row.id}
            onClick={() => onRowClicked?.(row)}
            sx={{
              cursor: "pointer",
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            {columns.map((col) => (
              <TableCell
                sx={{
                  fontSize: ".88rem",
                }}
              >
                {col.cellRenderer
                  ? col.cellRenderer(row)
                  : (row as any)[col.field]}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
