import React from "react";
import { Typography } from "../../common/Typography/Typography";
import {
  Box,
  FormControl,
  OutlinedInput,
  SelectChangeEvent,
  useTheme,
} from "@mui/material";
import { FormLabel } from "../../common/Form/FormLabel";
import { Select } from "../../common/Form/Select";
import { MenuItem } from "../../common/Menu/MenuItem";
import { Link } from "../../common/Link/Link";
import { Button } from "../../common/Button/Button";
import { useParams } from "react-router-dom";
import {
  GetOrganizationsDocument,
  OrganizationOutput,
  WorkspaceRoleEnum,
} from "../../generated";
import { useQuery } from "@apollo/client";

export interface AddMemberDialogContentProps {
  onClickInvite: (...args: any[]) => any;
  closeDialog: (...args: any[]) => any;
  email: string;
  setEmail: (...args: any[]) => any;
  role: string;
  setRole: (...args: any[]) => any;
  organization: Partial<OrganizationOutput>;
  setOrganization: (...args: any[]) => any;
  loading?: boolean;
}

export const AddMemberDialogContent = ({
  closeDialog,
  onClickInvite,
  email,
  setEmail,
  role,
  setRole,
  organization,
  setOrganization,
  loading,
}: AddMemberDialogContentProps) => {
  const { palette, typography } = useTheme();
  const { workspaceId } = useParams();
  const { data } = useQuery(GetOrganizationsDocument, {
    variables: {
      workspaceId: workspaceId || "",
    },
  });
  const onChangeOrg = (e: SelectChangeEvent<unknown>) =>
    setOrganization(
      data?.workspace?.participatingOrganizations?.find(
        (org) => org?.id === e.target.value
      )
    );
  const onChangeEmail = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setEmail(e.target.value);
  const inviteDisabled = email === "" || organization === "" || loading;

  return (
    <Box width="35rem" p={6} boxSizing="border-box">
      <Typography mb={4} variant="h5">
        Add Member
      </Typography>
      <Typography color={palette.gray.dark} mb={4} variant="body2">
        Enter the new workspace member’s email address associated with their
        organization.
      </Typography>
      <FormControl sx={{ mb: 2 }} fullWidth>
        <FormLabel>Organization</FormLabel>
        <Select value={organization?.id} onChange={onChangeOrg}>
          {data?.workspace?.participatingOrganizations?.map((org) => (
            <MenuItem value={org?.id} selected={organization?.id === org?.id}>
              {org?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Typography color={palette.gray.dark} mb={4} variant="body2">
        Can’t find your organization?
        <Link fontWeight={500} to="/#asd">
          {" "}
          Contact your account person at Brighthive{" "}
        </Link>
        to add an approved organization to your workspace.
      </Typography>

      <FormControl fullWidth sx={{ mb: 3 }}>
        <FormLabel>Email Address</FormLabel>
        <OutlinedInput
          value={email}
          onChange={onChangeEmail}
          sx={{
            ...typography.body3,
            ".MuiInputBase-input": {
              pt: 2,
              pb: 2,
              pl: 3,
              pr: 3,
              height: "2.25rem",
              boxSizing: "border-box",
            },
          }}
        />
      </FormControl>

      <FormControl sx={{ mb: 2 }} fullWidth>
        <FormLabel>Role</FormLabel>
        <Select value={role} onChange={(e) => setRole(e.target.value)}>
          <MenuItem value={WorkspaceRoleEnum.Admin}>
            {WorkspaceRoleEnum.Admin}
          </MenuItem>
          <MenuItem value={WorkspaceRoleEnum.Collaborator}>
            {WorkspaceRoleEnum.Collaborator}
          </MenuItem>
          <MenuItem value={WorkspaceRoleEnum.Viewer}>
            {WorkspaceRoleEnum.Viewer}
          </MenuItem>
          <MenuItem value={WorkspaceRoleEnum.Contributor}>
            {WorkspaceRoleEnum.Contributor}
          </MenuItem>
        </Select>
      </FormControl>

      <Box display="flex" mt={5} justifyContent="flex-end">
        <Button
          onClick={closeDialog}
          color="grayAccent"
          variant="outlined"
          sx={{ mr: 3 }}
        >
          Cancel
        </Button>
        <Button
          disabled={inviteDisabled}
          onClick={onClickInvite}
          color="primary"
          variant="contained"
        >
          Invite
        </Button>
      </Box>
    </Box>
  );
};
