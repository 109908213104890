import { Box, useTheme } from "@mui/material";
import React from "react";
import { Link } from "../Link/Link";
import { SquareIcon } from "../SquareIcon/SquareIcon";

interface BreadcrumbLinkProps {
  icon?: React.ReactNode;
  children: React.ReactNode;
  to: string;
  iconSize?: "small" | "medium" | undefined;
}

export const BreadcrumbLink = ({
  icon,
  children,
  to,
  iconSize,
}: BreadcrumbLinkProps) => {
  const { palette } = useTheme();
  return (
    <Link
      fontWeight="bold"
      sx={{
        display: "flex",
        ":focus-visible": {
          outlineOffset: 4,
        },
        ".MuiSvgIcon-root": {
          color: palette.link.main,
        },
        "&:visited .MuiSvgIcon-root": {
          color: palette.link.dark,
        },
      }}
      to={to}
    >
      {icon && (
        <SquareIcon
          icon={icon}
          backgroundColor={palette.settings.main}
          color={palette.settings.contrastText}
          sx={{
            mr: 2,
          }}
          size={iconSize}
        />
      )}
      <Box component="span">{children}</Box>
    </Link>
  );
};

interface LastBreadcrumbLinkProps {
  icon?: React.ReactNode;
  children: React.ReactNode;
  iconSize?: "small" | "medium" | undefined;
}

export const LastBreadcrumbLink = ({
  icon,
  children,
  iconSize,
}: LastBreadcrumbLinkProps) => {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        color: palette.common.black,
        ":focus": {
          outline: `.1875rem solid ${palette.primary.main}`,
          outlineOffset: 4,
        },
        ".MuiSvgIcon-root": {
          color: palette.grayAccent.main,
        },
      }}
    >
      {icon && (
        <SquareIcon
          icon={icon}
          backgroundColor={palette.settings.main}
          color={palette.settings.contrastText}
          sx={{
            mr: 2,
          }}
          size={iconSize}
        />
      )}
      <Box component="span">{children}</Box>
    </Box>
  );
};
