import { Box, Drawer, Divider, IconButton } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { StatusCell } from "../CellRenderer/StatusCell";
import { MetadataConfiguration } from "./MetadataConfiguration";
import Close from "@mui/icons-material/Close";

interface DataStorageDetailProps {
  open: boolean;
  onClose: () => void;
}

export const DataStorageDetail = ({
  open,
  onClose,
}: DataStorageDetailProps) => {
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box width={400} p={8}>
        <Box position="relative" mb={4}>
          <Box position="absolute" top={-10} right={0}>
            <IconButton onClick={() => onClose()} size="small">
              <Close />
            </IconButton>
          </Box>
          <Typography variant="overline">DATA STORAGE</Typography>
          <Typography mt={1} mb={3} variant="h5">
            source-name
          </Typography>
          <StatusCell value="Connected" />
        </Box>

        <Divider />
        <Box mt={6}>
          <MetadataConfiguration />
        </Box>
      </Box>
    </Drawer>
  );
};
