import { Box, Typography, useTheme } from "@mui/material";
import { LeftRightContent } from "../../LeftRightContent/LeftRightContent";
import { SearchBar } from "../../SearchBar/SearchBar";
import { Button } from "../../Button/Button";
import { useState } from "react";
import { palette } from "@mui/system";
import { ButtonPrimary } from "./style";
import { PlusIcon } from "../../Icons/PlusIcon";

export const ActionBar = ({
  loading,
  resetRowData,
  setRowData,
}: {
  loading?: boolean;
  resetRowData: Function;
  setRowData: Function;
}) => {
  const [searchText, setSearchText] = useState("");

  const onChangeSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchText(e.target.value);
    if (e.target.value !== "") {
      setRowData((prev: any) =>
        prev.filter((data: any) =>
          data.name.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else {
      resetRowData();
    }
  };
  const { palette } = useTheme();

  return (
    <>
      <LeftRightContent
        sx={{ p: 4 }}
        leftElements={
          <SearchBar
            onChange={onChangeSearch}
            value={searchText}
            placeholder="Find an engine"
          />
        }
        rightElements={
          <Box display="flex" alignItems="center" gap={2} ml={2}>
            <Typography variant="body2" sx={{ color: palette.gray.dark }}>
              Note: Contact Brighthive Support to configure new services
            </Typography>
            <ButtonPrimary
              variant="contained"
              disabled={loading}
              className="d-none"
            >
              <PlusIcon />
              Add Service
            </ButtonPrimary>
            {/* <Typography
              variant="button"
              whiteSpace="nowrap"
              color={palette.grayAccent.main}
            >
              Filter by:{" "}
            </Typography>
            <FilterByTagButton
              text="Service"
              tags={[]}
              onSubmit={() => {}}
              values={{}}
            />
            <FilterByTagButton
              text="Language"
              tags={[]}
              onSubmit={() => {}}
              values={{}}
            />
            <FilterByTagButton
              text="Status"
              tags={[]}
              onSubmit={() => {}}
              values={{}}
            /> */}
            {/* <Button variant="contained" onClick={() => setOpen(true)}>
              Connect an engine
            </Button> */}
          </Box>
        }
      />
      {/* <AddEngineDialog open={open} onClose={() => setOpen(false)} /> */}
    </>
  );
};
