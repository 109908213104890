import { Box } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import SyncIcon from "@mui/icons-material/Sync";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";

interface SyncCellProps {
  value: string;
}

export const SyncCell = ({ value }: SyncCellProps) => {
  return (
    <Box alignItems="center" display="flex">
      {value === "Auto" ? (
        <SyncIcon fontSize="small" color="disabled" />
      ) : (
        <SyncDisabledIcon fontSize="small" color="disabled" />
      )}
      <Typography ml={1} variant="body3">
        {value}
      </Typography>
    </Box>
  );
};
