import { Box, Modal } from "@mui/material";
import { TrippleVerticalDotsIcon } from "../../common/Icons/TrippleVerticalDotsIcon";
import { useMenu } from "../../common/Menu/useMenu";
import { EditPencilIcon } from "../../common/Icons/EditPencilIcon";
import { MoveFileIcon } from "../../common/Icons/MoveFileIcon";
import { DeleteIcon } from "../../common/Icons/DeleteIcon";
import { useState } from "react";
import { Move } from "../AddResource/Move";
import { DeleteResourceModal } from "../AddResource/DeleteResourceModal";
import {
  ButtonDefault,
  StyledEditButton,
  StyledMoveButton,
  StyledPopover,
  StyledRemoveButton,
} from "./style";

export const ActionCell = ({
  id,
  name,
  onEdit,
}: {
  id: string;
  name: string;
  onEdit: () => void;
}) => {
  const [open, setOpen] = useState(false);
  const [menuOpen, anchorEl, openMenuFromAnchorElement, closeMenu] = useMenu();

  const [openMove, setOpenMove] = useState(false);

  return (
    <Box>
      <ButtonDefault className="small" onClick={openMenuFromAnchorElement}>
        <TrippleVerticalDotsIcon />
      </ButtonDefault>
      <StyledPopover
        id="project-action-items"
        aria-labelledby="project-action-button"
        open={menuOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        onClose={closeMenu}
      >
        <>
          <StyledMoveButton
            onClick={() => {
              setOpenMove(true);
              closeMenu();
            }}
          >
            <span>Move</span>
            <i className="icn">
              <MoveFileIcon />
            </i>
          </StyledMoveButton>
          <StyledRemoveButton
            onClick={() => {
              setOpen(true);
              closeMenu();
            }}
          >
            <span>Delete</span>
            <i className="icn">
              <DeleteIcon />
            </i>
          </StyledRemoveButton>
          <StyledEditButton
            id="project-action-button"
            aria-controls={menuOpen ? "project-action-items" : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? "true" : undefined}
            onClick={() => {
              onEdit();
              closeMenu();
            }}
          >
            <span>Edit</span>
            <i className="icn">
              <EditPencilIcon />
            </i>
          </StyledEditButton>
        </>
      </StyledPopover>
      <DeleteResourceModal name={name} open={open} setOpen={setOpen} id={id} />

      <Modal
        id="move-resource-modal"
        open={openMove}
        onClose={() => setOpenMove(false)}
      >
        <Move name={name} id={id} onClose={() => setOpenMove(false)} />
      </Modal>
    </Box>
  );
};
