import React, { useRef } from "react";
import { Box, Dialog } from "@mui/material";

import { useParams } from "react-router-dom";
import { useApolloClient, useMutation } from "@apollo/client";
import {
  GetProjectDataAssetDocument,
  WorkflowDataAssetGroupOutput,
  OnboardDataAssetToInputDataAssetGroupDocument,
} from "../../generated";
import { SimpleLoader } from "../../common/Loader/Loader";
import { ImportAssetModal } from "../../AssetDetail/ImportAssetModal/ImportAssetModal";
import axios from "axios";
import { toast } from "react-toastify";

interface AddDataAssetModalProps {
  show: boolean;
  closeDialog: Function;
  item: WorkflowDataAssetGroupOutput | null;
}

export const AddDataAssetModal = ({
  show,
  closeDialog,
  item,
}: AddDataAssetModalProps) => {
  const { workspaceId, projectId } = useParams();
  const client = useApolloClient();

  const inputDataAssetGroupId = item?.id || "";
  const [onboardDataAssetToInputDataAssetGroupDocument] = useMutation(
    OnboardDataAssetToInputDataAssetGroupDocument
  );

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  return (
    <Dialog maxWidth="xl" open={show} onClose={() => closeDialog()}>
      <Box
        onClick={(e) => e.preventDefault()}
        width="50rem"
        p={6}
        boxSizing="border-box"
      >
        <Box
          mt={4}
          sx={{
            maxHeight: "calc(100vh - 300px)",
            minHeight: "200px",
            overflowY: "auto",
          }}
        >
          <Box display="flex" justifyContent="center">
            <SimpleLoader />
          </Box>
          {item?.schemaId && (
            <ImportAssetModal
              schemaId={item?.schemaId}
              onSuccess={async (fileName: string, csv: string) => {
                const res = await onboardDataAssetToInputDataAssetGroupDocument(
                  {
                    variables: {
                      input: {
                        inputDataAssetGroupId,
                        projectId: projectId || "",
                        workspaceId: workspaceId || "",
                        name: fileName,
                      },
                    },
                  }
                );

                if (
                  res.data?.onboardDataAssetToInputDataAssetGroup.success &&
                  res.data.onboardDataAssetToInputDataAssetGroup.presignedUrl
                ) {
                  const file = new File([csv], fileName, { type: "text/csv" });
                  const options = {
                    headers: {
                      "Content-Type": file.type,
                    },
                  };
                  await axios.put(
                    res.data.onboardDataAssetToInputDataAssetGroup.presignedUrl,
                    file,
                    options
                  );

                  await client.refetchQueries({
                    include: [GetProjectDataAssetDocument],
                  });

                  toast.success("Success import data");
                  closeDialog();
                } else {
                  toast.error("Failed");
                }
              }}
              onCancel={() => {
                closeDialog();
              }}
              onCompleted={() => {
                setTimeout(() => {
                  buttonRef?.current?.click();
                }, 1000);
              }}
              render={(importer: any, launch: Function) => {
                return (
                  <Box
                    ref={buttonRef}
                    sx={{ mr: 3 }}
                    onClick={() => launch()}
                  ></Box>
                );
              }}
            />
          )}
        </Box>
      </Box>
    </Dialog>
  );
};
