import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const Subscriptions = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.5 9.16667L10 11.6667L16.6667 5"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6667 9.99992V14.9999C16.6667 15.4419 16.4911 15.8659 16.1786 16.1784C15.866 16.491 15.4421 16.6666 15 16.6666H5.00004C4.55801 16.6666 4.13409 16.491 3.82153 16.1784C3.50897 15.8659 3.33337 15.4419 3.33337 14.9999V4.99992C3.33337 4.55789 3.50897 4.13397 3.82153 3.82141C4.13409 3.50885 4.55801 3.33325 5.00004 3.33325H12.5"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
