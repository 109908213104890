import { Box, FormControlLabel, styled, Typography } from "@mui/material";

export const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  width: "100%",
  mb: 6,
  ".MuiTypography-root": {
    fontSize: "0.875rem",
    lineHeight: 1.2,
  },
}));

export const ViewDrawerContainer = styled(Box)(() => ({
  wordBreak: "break-word",
}));

export const FieldContainer = styled(Box)(() => ({
  marginTop: "1rem",
}));
