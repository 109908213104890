import {
  Field,
  GetSchemaDocument,
  GetSchemaQuery,
  WorkspaceInput,
} from "../../generated";
import { generateArrayOfData, pickRandomValueFromArray } from "./helpers";
import { aField } from "./generated-mocks";
import { MockedResponse } from "@apollo/client/testing";

const fields: Field[] = generateArrayOfData(
  () =>
    aField({
      pii: pickRandomValueFromArray([true, false]),
    }),
  101
);

const input: WorkspaceInput = {
  workspaceId: "f4861165-f244-4cad-9827-65921385b9a0",
};

const data: GetSchemaQuery = {
  workspace: {
    dataAssets: [
      {
        isEditable: true,
        fields,
      },
    ],
  },
};

export const schemaMock: MockedResponse = {
  request: {
    query: GetSchemaDocument,
    variables: {
      input,
    },
  },
  result: {
    data,
  },
  delay: 2000,
};
