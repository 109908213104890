import { styled, Box, IconButton, Typography, Button } from "@mui/material";
import { ArrowBack, Block } from "@mui/icons-material";
import { brighthiveTheme } from "../theme/theme";

export const SidebarLinkButton = styled(Button)`
  @media (max-width: 992px) {
    border-radius: 0;
    padding: 5px 10px;
    font-size: 0.8rem;
  }
  &:hover {
    background-color: transparent;
    color: ${brighthiveTheme.palette.primary.dark};
  }
`;
export const ButtonHolder = styled(Box)`
  border-width: 0 0 3px;
  border-style: solid;
`;
export const CustomBox = styled(Box)`
  &.organization-main-contanier {
    display: block;
  }
`;
export const LinksHolder = styled(Box)`
  margin: 0 0 20px;
  display: flex;
`;
