import React, { useState } from "react";
import { Typography } from "../../common/Typography/Typography";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  useTheme,
} from "@mui/material";
import { Button } from "../../common/Button/Button";
import { OrganizationOutput } from "../../generated";

export interface InviteOutsideMemberDialogContentProps {
  closeDialog: (...args: any[]) => any;
  email: string;
  organization: Partial<OrganizationOutput>;
  onClickInviteAnyway: () => void;
  loading?: boolean;
}

export const InviteOutsideMemberDialogContent = ({
  closeDialog,
  email,
  organization,
  onClickInviteAnyway,
  loading,
}: InviteOutsideMemberDialogContentProps) => {
  const { palette, typography } = useTheme();
  const clickInviteAnyway = () => {
    onClickInviteAnyway();
  };

  const [userAcknowledgedWarningChecked, setUserAcknowledgedWarningChecked] =
    useState(false);
  const onChangeCheckbox = () => {
    setUserAcknowledgedWarningChecked(!userAcknowledgedWarningChecked);
  };

  return (
    <Box width="35rem" p={6} boxSizing="border-box">
      <Typography mb={4} variant="h5">
        Invite outside member
      </Typography>
      <Typography color={palette.gray.dark} mb={4} variant="body2">
        You are sharing to
        <Box component="span" fontWeight={500} color={palette.gray.darkest}>
          {" "}
          {email}{" "}
        </Box>
        who does not match the organization
        <Box component="span" fontWeight={500} color={palette.gray.darkest}>
          {" "}
          {organization.name}
        </Box>
        .
      </Typography>

      <FormControl fullWidth>
        <FormControlLabel
          sx={{ ".MuiTypography-root": { ...typography.body2 } }}
          value="piInformation"
          control={
            <Checkbox
              onChange={onChangeCheckbox}
              value={userAcknowledgedWarningChecked}
            />
          }
          label="I acknowledge this and want to proceed anyway."
        />
      </FormControl>

      <Box display="flex" mt="2.5rem" justifyContent="flex-end">
        <Button
          onClick={closeDialog}
          color="grayAccent"
          variant="outlined"
          sx={{ mr: 3 }}
        >
          Cancel
        </Button>
        <Button
          disabled={!userAcknowledgedWarningChecked || loading}
          onClick={clickInviteAnyway}
          color="primary"
          variant="contained"
        >
          Invite Anyway
        </Button>
      </Box>
    </Box>
  );
};
