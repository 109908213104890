import {
  alpha,
  Chip as MUIChip,
  ChipProps as MUIChipProps,
  styled,
} from "@mui/material";

export type ChipProps = MUIChipProps;

export const ChipModified = (props: ChipProps) => {
  return (
    <MUIChip
      sx={{
        ...(typeof props.label === "string" &&
          props.label.length > 3 &&
          props.size === "small" && {
            padding: ".125rem .5rem !important",
          }),
        ...(typeof props.label === "string" &&
          props.label.length > 3 &&
          (props.size === "large" || props.size === "medium" || undefined) && {
            padding: ".25rem .625rem !important",
          }),
        ...props.sx,
      }}
      role=""
      tabIndex={-1}
      {...props}
    />
  );
};

export const Chip = styled(ChipModified)(({ size, theme, color, variant }) => {
  const { palette } = theme;

  let cssColor = undefined;
  let backgroundColor = undefined;

  if (color === undefined || color === "primary") {
    cssColor = palette.common.black;
    backgroundColor = palette.primary.light;
  } else if (color === "warning") {
    backgroundColor = palette.warning.light;
  } else if (color === "error") {
    backgroundColor = palette.error.light;
    cssColor = palette.common.black;
  } else if (color === "gray") {
    backgroundColor = alpha(palette.grayAccent.main, 0.16);
    cssColor = palette.common.black;
  }

  if (variant === "outlined") {
    backgroundColor = "transparent";
  }

  return {
    height: "initial",
    borderRadius: "999px",
    ...(size === "small" && {
      padding: ".125rem .25rem",
      minWidth: "1.25rem",
    }),
    ...((size === "medium" || size === undefined) && {
      padding: ".25rem .3125rem",
      minWidth: "1.5rem",
    }),
    ...(size === "large" && {
      padding: ".3125rem .375rem",
      minWidth: "1.75rem",
    }),
    color: cssColor,
    backgroundColor,
    ...(size !== "large" && {
      fontWeight: 500,
      fontSize: ".75rem",
      lineHeight: "1rem",
      letterSpacing: "0.01em",
    }),
    ...(size === "large" && {
      fontWeight: 500,
      fontSize: ".875rem",
      lineHeight: "1.125rem",
      letterSpacing: "0.01em",
    }),
    ".MuiChip-label": {
      padding: 0,
    },
    ".MuiChip-avatar": {
      color: "inherit",
      backgroundColor: "inherit",
    },
    ".MuiChip-iconSmall, .MuiChip-avatarSmall": {
      height: ".75rem",
      width: ".75rem",
      marginRight: ".25rem",
      marginLeft: 0,
    },
    ".MuiChip-iconMedium, .MuiChip-avatarMedium": {
      height: ".75rem",
      width: ".75rem",
      marginRight: ".25rem",
      marginLeft: 0,
    },
    ".MuiChip-iconLarge, .MuiChip-avatarLarge": {
      height: ".875rem",
      width: ".875rem",
      marginRight: ".25rem",
      marginLeft: 0,
    },
  };
});

Chip.defaultProps = {
  deleteIcon: <></>,
};
