import React from "react";
import { ListItemText as MUIListItemText, ListItemTextProps as MUIListItemTextProps, } from "@mui/material";
import { brighthiveTheme } from "../../theme/theme";

export type ListItemTextProps = MUIListItemTextProps;

export const ListItemText = (props: ListItemTextProps) => (
  <MUIListItemText {...props} />
);

ListItemText.defaultProps = {
  primaryTypographyProps: {
    ...brighthiveTheme.typography.body2,
  },
};
