import React from "react";
import { Box } from "@mui/material";
import { Button, ButtonProps } from "../Button/Button";
import { FilterIcon } from "../Icons/FilterIcon";
import { ButtonDefault } from "../ActionBarProjects/style";

interface SpecificButtonProps {
  text: string;
  chip?: React.ReactNode;
}

export type FilterButtonProps = ButtonProps & SpecificButtonProps;

export const FilterButton = ({
  text,
  chip,
  sx,
  ...rest
}: FilterButtonProps) => {
  const mr = chip ? ".25rem" : 0;

  return (
    <ButtonDefault
      sx={sx}
      startIcon={<FilterIcon sx={{ fontSize: "1rem" }} />}
      {...rest}
    >
      <Box component="span" mr={mr}>
        {text}
      </Box>
      {chip}
    </ButtonDefault>
  );
};
