import React from "react";
import { Box, Tab, useTheme } from "@mui/material";
import { Tabs } from "../../common/Tabs/Tabs";
import { TabButton, TabsWrapper } from "../style";

interface SheetTabsProps {
  value: number;
  onChange: (v: number) => void;
}

export const SheetTabs = ({ value, onChange }: SheetTabsProps) => {
  const { palette } = useTheme();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onChange(newValue);
  };
  return (
    <Box
      sx={{
        borderBottom: `1px solid ${palette.outline}`,
        borderTop: `1px solid ${palette.outline}`,
      }}
    >
      <TabsWrapper value={value} onChange={handleChange} textColor="inherit">
        <TabButton label="Overview" />
        <TabButton label="Discussion" />
      </TabsWrapper>
    </Box>
  );
};
