import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const OffPlatformIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      {...props}
    >
      <path
        d="M16.9561 8.54792C16.3894 5.67292 13.8644 3.51459 10.8311 3.51459C8.42272 3.51459 6.33105 4.88125 5.28939 6.88125C2.78105 7.14792 0.831055 9.27292 0.831055 11.8479C0.831055 14.6063 3.07272 16.8479 5.83105 16.8479H16.6644C18.9644 16.8479 20.8311 14.9813 20.8311 12.6813C20.8311 10.4813 19.1227 8.69792 16.9561 8.54792ZM16.6644 15.1813H5.83105C3.98939 15.1813 2.49772 13.6896 2.49772 11.8479C2.49772 10.0063 3.98939 8.51459 5.83105 8.51459H6.42272C6.97272 6.58959 8.73105 5.18125 10.8311 5.18125C13.3644 5.18125 15.4144 7.23125 15.4144 9.76459V10.1813H16.6644C18.0477 10.1813 19.1644 11.2979 19.1644 12.6813C19.1644 14.0646 18.0477 15.1813 16.6644 15.1813Z"
        fill="#7E8AA0"
      />
    </SvgIcon>
  );
};
