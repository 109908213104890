import { DrawerType } from "../../../common/Drawer";
import RightSidePanel from "../../../common/RightSidePanel";
import { CreateNewSchema } from "../../../Sheets/Schema/CreateNewSchema";

const ProjectSchemaDetailMutationDrawer = ({
  data,
  drawerDisplayer,
  displayDrawerHandler,
  schemaDetail,
  update,
  drawerType,
}: any) => {
  return (
    <RightSidePanel
      isOpen={(drawerType as DrawerType[]).includes(drawerDisplayer.type)}
      onToggle={() => displayDrawerHandler(null)}
    >
      <CreateNewSchema
        targetSchema={schemaDetail}
        closeDialog={() => displayDrawerHandler(null)}
        initialValues={data}
        editMode={drawerType === "edit" || false}
        submit={(v: any) => {
          const parse = JSON.parse(schemaDetail?.jsonSchema || "{}");

          const properties = parse?.properties || {};

          properties[v.name] = {
            description: v.description,
            title: v.title,
            type: v.type,
            // pii: v.pii,
            ...(v.type === "string" &&
              v.format !== "none" && { format: v.format }),
            ...(v.validate && {
              ...(v.pattern && { pattern: v.patternValue }),
            }),
            ...(v.type === "array" && v.enum.length > 0 && { enum: v.enum }),
          };

          if (v.minLength) {
            properties[v.name]["minLength"] = v.minLength;
          }

          if (v.maxLength) {
            properties[v.name]["maxLength"] = v.maxLength;
          }

          parse.properties = properties;

          const required: string[] = parse?.required || [];
          if (!required.includes(v.name) && v.required) {
            required.push(v.name);
          }

          if (required.length > 0) {
            parse.required = required;
          }

          return update(parse)
            .then(() => {
              displayDrawerHandler(null);
            })
            .catch(() => {
              displayDrawerHandler(null);
            });
        }}
      />
    </RightSidePanel>
  );
};

export default ProjectSchemaDetailMutationDrawer;
