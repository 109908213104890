import { Box, Tab, Typography, useTheme } from "@mui/material";
import { Tabs } from "../../common/Tabs/Tabs";
import { useState } from "react";
import { UploadResource } from "./UploadResource";
import { AddResource } from "./AddResource";
import { ResourceType } from "../../generated";
import { CloseIcon } from "../../common/Icons/CloseIcon";
import { Button } from "../../common/Button/Button";
import { TabButton, TabsWrapper } from "./style";

export const AddResourceTab = ({
  setResource,
  resource,
}: {
  setResource: Function;
  resource: any;
}) => {
  const { palette } = useTheme();

  const [tab, setTab] = useState<number>(resource === "LINK" ? 1 : 0);

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "720px",
        backgroundColor: palette.common.white,
        borderRadius: "11px",
        margin: "0 auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "column",
          padding: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1",
            justifyContent: "space-between",
            width: "100%",
            flexDirection: "column",
            borderBottom: `1px solid ${palette.action.disabledBackground}`,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flex: "1",
              justifyContent: "space-between",
              width: "100%",
              mb: 4,
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                fontSize: "24px",
                lineHeight: "28px",
              }}
            >
              Add{" "}
              {resource === "File"
                ? "Document"
                : resource.charAt(0).toUpperCase() +
                  resource.slice(1).toLowerCase()}
            </Typography>
            <Button
              type="button"
              onClick={() => setResource("")}
              sx={{
                minWidth: "1px",
                padding: "5px",
                color: palette.gray.dark,
                svg: {
                  width: "24px",
                  height: "24px",
                },
              }}
            >
              <CloseIcon />
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flex: "1",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <TabsWrapper value={tab} aria-label="governance tab">
              <TabButton
                label="Import File"
                {...a11yProps(0)}
                onClick={() => setTab(0)}
                className={resource === "LINK" ? "d-none" : ""}
              />
              <TabButton
                label="Paste URL"
                {...a11yProps(1)}
                onClick={() => setTab(1)}
              />
            </TabsWrapper>
          </Box>
        </Box>
      </Box>

      {tab === 0 && (
        <UploadResource
          setResource={setResource}
          resource={resource}
          submitButtonText={`Add ${
            resource === "File"
              ? "Document"
              : resource.charAt(0).toUpperCase() +
                resource.slice(1).toLowerCase()
          }`}
        />
      )}
      {tab === 1 && (
        <AddResource
          setResource={setResource}
          resource={resource}
          submitButtonText={`Add ${
            resource === "File"
              ? "Document"
              : resource.charAt(0).toUpperCase() +
                resource.slice(1).toLowerCase()
          }`}
        />
      )}
    </Box>
  );
};
