import { Button } from "../../common/Button/Button";
import { Dialog } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { RemoveControlsContainer, RemoveDialogContainer } from "./style";

type Props = {
  dialogOpen: boolean;
  closeDialog: () => void;
  onClickRemoveField: () => void;
};

const RemoveFieldDialog = ({
  dialogOpen,
  closeDialog,
  onClickRemoveField,
}: Props) => {
  return (
    <Dialog open={dialogOpen} onClose={closeDialog}>
      <RemoveDialogContainer p={6}>
        <Typography mb={4} variant="h5">
          Remove Field
        </Typography>

        <RemoveControlsContainer mt={7}>
          <Button
            onClick={closeDialog}
            color="grayAccent"
            variant="outlined"
            sx={{ mr: 3 }}
          >
            Cancel
          </Button>
          <Button
            onClick={onClickRemoveField}
            color="error"
            variant="contained"
          >
            Remove
          </Button>
        </RemoveControlsContainer>
      </RemoveDialogContainer>
    </Dialog>
  );
};

export default RemoveFieldDialog;
