import { Box, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { AppContext } from "../App";
import { SideNavigationPage } from "../common/SideNavigationPage/SideNavigationPage";
import { GetProjectDocument } from "../generated";

import { useNavigate, useParams } from "react-router-dom";
import { KnowledgeCenterNav } from "./KnowledgeCenterNav";
import { useQuery } from "@apollo/client";
import { FolderIcon } from "../common/Icons/FolderIcon";

export const KnowledgeCenter = ({
  title,
  children,
  rightElement,
  back,
  isFolder,
}: {
  title: any;
  rightElement?: any;
  children: any;
  back?: boolean;
  isFolder?: any;
}) => {
  const { projectId, workspaceId } = useParams();

  const { data } = useQuery(GetProjectDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
      projectFilter: {
        projectId: projectId || "",
      },
    },
  });

  const workspace = data?.workspace;

  return (
    <AppContext.Consumer>
      {({ showSidenav, setShowSidenav }) => (
        <>
          <SideNavigationPage
            innerSideBar
            back={back}
            leftHeaderElements={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {isFolder && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mr: 2,
                    }}
                  >
                    <FolderIcon />
                  </Box>
                )}
                <Typography variant="h5" sx={{ lineHeight: "36px" }}>
                  {title}
                </Typography>
              </Box>
            }
            rightHeaderElements={rightElement}
            // verticalNav={
            //   <KnowledgeCenterNav
            //     show={showSidenav}
            //     workspaceName={workspace?.name || ""}
            //     setShowSidenav={setShowSidenav}
            //   />
            // }
            content={children}
          />
        </>
      )}
    </AppContext.Consumer>
  );
};
