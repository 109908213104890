import React, { useEffect, useState, useCallback } from "react";
import { Box, CircularProgress, useTheme } from "@mui/material";
import RichTextEditor from "../../common/RichTextEditor";
import streamClient from "../../utils/streamClient";
import { Chat, Channel, MessageList, Thread, Window } from "stream-chat-react";
import CustomMessage, { CustomMessageChannel } from "./CustomMessage";

import "@stream-io/stream-chat-css/dist/css/index.css";
import CustomMessageInput from "./CustomMessageInput";
import CustomThreadHeader from "./CustomThreadHeader";
import CustomDateSeparator from "./CustomDateSeparator";
import { useData } from "../../hooks/dataContext";
import { useLazyQuery } from "@apollo/client";
import { RefreshUserTokenDocument } from "../../generated";
import ErrorBoundaryChat from "./ErrorBoundaryChat";
interface SheetChatProps {
  id: string;
  staticInput?: boolean;
}

export const SheetChat = ({ id, staticInput }: SheetChatProps) => {
  const [channel, setChannel] = useState<any | null>(null);
  const { state } = useData();
  const [getUserToken] = useLazyQuery(RefreshUserTokenDocument);

  const getChannel = useCallback(async () => {
    try {
      const res = await getUserToken();
      const token = res.data?.refreshUserToken.userToken || "";
      const name = `${state.user.firstName} ${state.user.lastName}`;
      streamClient.connectUser({ id: state.user.id, name }, token);
      const channel = streamClient.channel("team", id);
      setChannel(channel);
    } catch (err) {}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.user]);

  useEffect(() => {
    if (state.user) {
      getChannel();
    }

    return () => {
      streamClient.disconnectUser().then();
    };
  }, [state.user]);

  /* useEffect(() => {
    return () => {
      streamClient.disconnectUser();
    };
  }, []);
*/
  const { palette } = useTheme();
  return (
    <Box pb={staticInput ? 0 : 108} mt={staticInput ? 0 : -10}>
      {channel ? (
        <ErrorBoundaryChat>
          <Chat client={streamClient} theme="str-chat__theme-light">
            <Channel
              channel={channel}
              Message={CustomMessageChannel}
              ThreadHeader={CustomThreadHeader}
              DateSeparator={CustomDateSeparator}
            >
              <Window>
                <MessageList />
              </Window>
              <Thread
                fullWidth
                Message={CustomMessage}
                Input={CustomMessageInput}
              />
            </Channel>
          </Chat>
        </ErrorBoundaryChat>
      ) : (
        <Box display="flex" justifyContent="center" mt={12}>
          <CircularProgress sx={{ color: palette.primary.darkest }} />
        </Box>
      )}

      <Box
        sx={
          staticInput
            ? {}
            : {
                backgroundColor: palette.common.white,
                position: "fixed",
                bottom: 0,
                width: "500px",
                right: 0,
              }
        }
      >
        <Box p={staticInput ? 0 : 4}>
          <RichTextEditor
            onSubmit={async (value: string) => {
              await channel?.sendMessage({
                text: value,
              });
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
