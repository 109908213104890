import { Box, FormHelperText, TextField, useTheme } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

interface CustomTextAreaProps {
  name?: string;
  value: string;
  onChange?: any;
  error?: string;
  rows?: number;
  maxRows?: number;
  touched?: boolean;
  disabled?: boolean;
}

export const CustomTextArea = ({
  name,
  value,
  onChange,
  error,
  rows,
  maxRows,
  touched,
  disabled
}: CustomTextAreaProps) => {
  const { palette } = useTheme();
  return (
    <>
      <TextField
        name={name}
        value={value}
        multiline
        rows={rows}
        disabled={disabled}
        error={touched && error ? true : false}
        onChange={onChange}
        sx={{
          ".MuiTextField-root": {
            width: "100%",
            fontSize: "16px",
            lineHeight: "20px",
            padding: "8px",
            height: "316px",
            boxSizing: "border-box",
          },
        }}
      />
      {touched && error && (
        <Box
          display="flex"
          mt={1}
          justifyContent="flex-start"
          alignItems="center"
        >
          <ErrorOutlineIcon
            color="error"
            style={{
              width: "13.33px",
              height: "13.33px",
              marginTop: "3px",
              marginRight: "5px",
            }}
          />

          <FormHelperText
            style={{
              color: palette.error.dark,
              fontFamily: "Roboto",
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: 400,
            }}
          >
            {error}
          </FormHelperText>
        </Box>
      )}
    </>
  );
};
CustomTextArea.defaultProps = {
  rows: 3,
  maxRows: 4,
};
