import React, { PropsWithChildren } from "react";
import RightSidePanel from "../RightSidePanel";
import DrawerFooter from "./DrawerFooter";
import DrawerHeader from "./DrawerHeader";
import { Box } from "@mui/material";

export type DrawerType = "create" | "view" | "edit" | null;

export type DrawerDisplayerType = { type: DrawerType; id: number | undefined };

export type DisplayDrawerHandlerType = (type: DrawerType, id?: number) => void;

export type DrawerIconType = {
  handler: () => void;
  icon: React.ReactNode;
  disabled?: boolean;
};

type Props = PropsWithChildren<{
  drawerDisplayer: DrawerDisplayerType;
  displayDrawerHandler: DisplayDrawerHandlerType;
  drawerType: DrawerType;
  handleSubmit?: () => void;
  displayHeader?: boolean;
  displayFooter?: boolean;
  title?: string;
  sectionName?: string;
  icons?: DrawerIconType[];
}>;

const Drawer = ({
  displayHeader = true,
  displayFooter = true,
  children,
  drawerDisplayer,
  displayDrawerHandler,
  drawerType,
  handleSubmit,
  title,
  sectionName,
  icons,
}: Props) => {
  return (
    <RightSidePanel
      isOpen={drawerDisplayer.type === drawerType}
      onToggle={() => displayDrawerHandler(null)}
    >
      <Box style={{ position: "relative" }}>
        {displayHeader && (
          <DrawerHeader
            title={title!}
            sectionName={sectionName!}
            drawerType={drawerType}
            icons={icons!}
          />
        )}

        {children}

        {displayFooter && (
          <DrawerFooter
            loading={false}
            onClose={() => displayDrawerHandler(null)}
            handleSubmit={handleSubmit!}
          />
        )}
      </Box>
    </RightSidePanel>
  );
};

export default Drawer;
