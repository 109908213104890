import { styled, Box, Tab, Button, Typography } from "@mui/material";
import { brighthiveTheme } from "../../theme/theme";

export const ProjectTopNavWrapper = styled(Box)`
  padding: 1.125rem 1.5rem 0;
  display: flex;
  flex-wrap: wrap;
  background-color: ${brighthiveTheme.palette.background.default};

  .tabLink{
    margin-bottom: 0;

    &.active{
    
      > .MuiButton-text{
        background-color: transparent;
        color: ${brighthiveTheme.palette.primary.dark};
        border-bottom-color: currentColor;
      }
    }

    > .MuiButton-text{
      padding: 12px 12px 13px;
      background-color: transparent;
      color: ${brighthiveTheme.palette.gray.dark};
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      min-width: 1px;
      border-bottom: 2px solid transparent;
      border-radius: 0;

      @media screen and (min-width: 1200px){
        padding: 12px 24px;
        font-size: 16px;
      }

      > span{
       font-size: inherit;
       font-weight: inherit;
      }

      &:hover{
        color: ${brighthiveTheme.palette.primary.dark};
      }
    }

    .MuiButton-startIcon{
      display: none;
    }
  }
`;

export const ProjectNavWrapper = styled(Box)`
  .MuiLink-root{
    margin-bottom: 0;

    &.active,
    &:hover{
      background: transparent !important;
      
      > .MuiButtonBase-root{
        border-left-color: currentColor;
      }
    }

    > .MuiButtonBase-root {
      background: transparent !important;
      border-left: 4px solid ${brighthiveTheme.palette.action.disabledBackground};
      border-radius: 0;

      .sidebutton-text{
        padding-left: 24px;
      }

      > .MuiButton-startIcon{
        display: none !important;
        
        > .MuiSvgIcon-root{
          font-size: 18px !important;
        }
      }
    }
  }
`;

export const TopNavMainHeading = styled(Typography)`
  width: 100%;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  color: ${brighthiveTheme.palette.common.black};
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.75rem;
  letter-spacing: 0.0015em;
  padding: 6px 0 16px;
  margin-bottom: 19px;
  border-bottom: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
`;