import { useState } from "react";
import { Box, ButtonBase, Grid, useTheme } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate, useParams } from "react-router-dom";

import { Typography } from "../../common/Typography/Typography";
import { SearchBar } from "../../common/SearchBar/SearchBar";
import { GovernancePolicyItem } from "../GovernancePolicyItem";
import { Chip } from "../../common/Chip/Chip";
import { PolicyOperationConfirmModal } from "../PolicyOperationConfirmModal";
import { CustomPolicyModal } from "./CustomPolicyModal";
import {
  GetCustomPoliciesDocument,
  UpdateCustomPolicyInput,
  UpdateGovernanceDocument,
} from "../../generated";
import { useMutation, useQuery } from "@apollo/client";
import { Policy } from "../types";
import CustomPolicyCard from "./CustomPolicyCard";
import { AddCustomPolicyButton } from "./AddCustomPolicyButton";
import { palette } from "@mui/system";

export interface GovernanceCustomPolices {
  refetch: Function;
  selected: Policy | null | undefined;
  operation: "delete" | "update";
  showEdit: boolean;
  setShowEdit: Function;
  setOperation: Function;
}

export const GovernanceCustomPolices = ({
  refetch,
  selected,
  operation,
  showEdit,
  setShowEdit,
  setOperation,
}: GovernanceCustomPolices) => {
  const navigate = useNavigate();
  const { workspaceId } = useParams();
  const [updateGovernance, { loading }] = useMutation(
    UpdateGovernanceDocument,
    {
      refetchQueries: [GetCustomPoliciesDocument],
    }
  );
  // const { data, refetch } = useQuery(GetCustomPoliciesDocument, {
  //   variables: { input: { workspaceId: workspaceId || "" } },
  // }) as unknown as {
  //   refetch: Function;
  //   data: {
  //     isEditable: boolean;
  //     categories: Array<[string, Array<Policy>]>;
  //   };
  // };

  // const isEditable = data?.isEditable;
  // let policyData = data?.categories?.map(([category, policies]) => ({
  //   category,
  //   policies,
  // }));

  // const [selectedPolicyItemIndex, setSelectedPolicyItemIndex] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  // const [operation, setOperation] = useState<"delete" | "update">("delete");
  // const [showEdit, setShowEdit] = useState(false);
  // const [selected, setSelected] = useState<Policy | null>();
  const [values, setValues] = useState<UpdateCustomPolicyInput | null>();

  // const handleCategoryItemClick = (index: number) => {
  //   if (index === selectedPolicyItemIndex) {
  //     setSelectedPolicyItemIndex(-1);
  //   } else {
  //     setSelectedPolicyItemIndex(index);
  //   }
  // };

  // const handleOpenNewTab = (item: Policy) => {
  //   localStorage.setItem("DETAIL", JSON.stringify(item));
  //   window.open(`/workspace/${workspaceId}/governance/detail-id?tab=custom`);
  // };

  // const handleDelete = (item: Policy) => {
  //   setOperation("delete");
  //   setSelected(item);
  //   setShowConfirm(true);
  // };

  // const handleEdit = (item: Policy) => {
  //   setSelected(item);
  //   setShowEdit(true);
  // };

  const editAction = (values: UpdateCustomPolicyInput) => {
    setValues(values);
    setShowEdit(false);
    setOperation("update");
    setShowConfirm(true);
  };

  const handleSubmitDelete = () => {
    updateGovernance({
      variables: {
        input: {
          workspaceId: workspaceId || "",
          customPolicies: {
            create: [],
            update: [],
            delete: selected ? [selected.id] : [],
          },
        },
      },
    }).then((res) => {
      setShowConfirm(false);
      refetch();
    });
  };

  const handleSubmitEdit = () => {
    updateGovernance({
      variables: {
        input: {
          workspaceId: workspaceId || "",
          customPolicies: {
            create: [],
            update: values ? [values] : [],
            delete: [],
          },
        },
      },
    }).then((res) => {
      setShowConfirm(false);
      refetch();
    });
  };

  const { palette } = useTheme();

  return (
    <Grid container spacing={8}>
      {/* <Grid item xs>
          <Box display="flex" alignItems="center">
            <SearchBar
              sx={{
                backgroundColor: palette.common.white,
                mr: "auto",
                width: "20rem",
              }}
              placeholder="Find a policy"
            />
            {isEditable && <AddCustomPolicyButton />}
          </Box>
          <Box marginTop="2rem">
            {policyData.map((category, index) => {
              return (
                <Box marginBottom="2rem" key={`category-${index}`}>
                  <Box display="flex" justifyContent={"space-between"}>
                    <ButtonBase onClick={() => handleCategoryItemClick(index)}>
                      {selectedPolicyItemIndex === index ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <ChevronRightIcon />
                      )}
                      <Typography variant="h6">{category.category}</Typography>
                    </ButtonBase>
                    <Chip
                      label={`${category.policies.length} policies `}
                      size="medium"
                      color={`${
                        category.policies.length === 0 ? "gray" : "primary"
                      }`}
                    />
                  </Box>
                  {selectedPolicyItemIndex === index &&
                    category.policies.map((policy, index) => (
                      <Box marginTop="1rem" key={`policy-${index}`}>
                        <GovernancePolicyItem
                          isEditable={isEditable}
                          handleEdit={() => handleEdit(policy)}
                          handleDelete={() => handleDelete(policy)}
                          handleOpenNewTab={() => handleOpenNewTab(policy)}
                          {...policy}
                        />
                      </Box>
                    ))}
                </Box>
              );
            })}
          </Box>
        </Grid>
        <Grid item sx={{ width: "27rem" }}>
          <CustomPolicyCard />
        </Grid> */}
      <PolicyOperationConfirmModal
        show={showConfirm}
        operation={operation}
        handleClose={() => setShowConfirm(false)}
        items={selected ? [selected.title] : []}
        loading={loading}
        handleSubmit={
          operation === "update" ? handleSubmitEdit : handleSubmitDelete
        }
      />
      <CustomPolicyModal
        show={showEdit}
        closeDialog={() => setShowEdit(false)}
        submit={editAction}
        editMode
        policy={selected}
      />
    </Grid>
  );
  return null;
};
