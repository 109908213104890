import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { AppContext } from "../../App";
import { ProjectSidenav } from "../../common/ProjectSidenav/ProjectSidenav";
import { SideNavigationPage } from "../../common/SideNavigationPage/SideNavigationPage";
import { FolderOutlined, GridView } from "@mui/icons-material";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import { useMutation, useQuery } from "@apollo/client";
import {
  CreateTargetSchemaDocument,
  GetProjectDocument,
  GetTargetSchemasDocument,
  TargetSchemaStatus,
} from "../../generated";
import { Button } from "../../common/Button/Button";

import { useNavigate, useParams } from "react-router-dom";
import { ProjectSchemaGrid } from "./ProjectSchemaGrid";
import { ModalSchemaField } from "./ModalSchemaField";
import { ButtonPrimary } from "./style";
import { PlusIcon } from "../../common/Icons/PlusIcon";
import { ProjectPage } from "../../common/ProjectPage/ProjectPage";
import { BreadcrumbLinks } from "../../common/BreadcrumbsLinks/BreadcrumbLinks";
import {
  BreadcrumbLink,
  LastBreadcrumbLink,
} from "../../common/BreadcrumbsLinks/BreadcrumbLink";
import { ModalAddExistingSchema } from "./ModalAddExistingSchema";

export const ProjectSchemaPage = () => {
  const navigate = useNavigate();

  const [openAdd, setOpenAdd] = useState(false);
  const [openExisting, setOpenExisting] = useState(false);
  const { projectId, workspaceId } = useParams();
  const { palette } = useTheme();

  const { data } = useQuery(GetProjectDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
      projectFilter: {
        projectId: projectId || "",
      },
    },
  });

  const [mutateCreate] = useMutation(CreateTargetSchemaDocument, {
    refetchQueries: [GetTargetSchemasDocument],
    onCompleted: (res) => {
      navigate(
        `/workspace/${workspaceId}/project/${projectId}/project-schema/${res.createTargetSchema.targetSchemaId}`
      );
    },
  });

  const workspaceName = data?.workspace?.name || "";

  const workspace = data?.workspace;
  const project = data?.workspace?.projects?.[0];

  const projectName = project?.name;

  return (
    <>
      <ProjectPage
        projectContent={
          <BreadcrumbLinks>
            <BreadcrumbLink
              icon={<GridView />}
              to={`/workspace/${workspaceId}/project`}
              iconSize="small"
            >
              <Typography variant="body3">{workspaceName}</Typography>
            </BreadcrumbLink>
            <LastBreadcrumbLink icon={<FolderOutlined />} iconSize="small">
              <Typography variant="body3">{projectName}</Typography>
            </LastBreadcrumbLink>
          </BreadcrumbLinks>
        }
        leftHeaderElements={<Typography variant="h5">Schema List</Typography>}
        rightHeaderElements={
          <>
            <ButtonPrimary
              variant="contained"
              onClick={() => setOpenExisting(true)}
            >
              <PlusIcon />
              Add Existing Schema
            </ButtonPrimary>
            <ButtonPrimary variant="contained" onClick={() => setOpenAdd(true)}>
              <PlusIcon />
              Create new schema
            </ButtonPrimary>
          </>
        }
        content={
          <Box
            bgcolor={palette.common.white}
            display="flex"
            flexDirection="column"
            boxSizing="border-box"
            flexGrow={1}
            border={`0.0625rem solid ${palette.outline}`}
            borderRadius="0.375rem"
          >
            <ProjectSchemaGrid />
          </Box>
        }
      />
      <ModalAddExistingSchema
        show={openExisting}
        projectId={projectId!!}
        onClose={() => setOpenExisting(false)}
      />
      <ModalSchemaField
        show={openAdd}
        closeDialog={() => setOpenAdd(false)}
        submit={(v: any) => {
          return mutateCreate({
            variables: {
              input: {
                workspaceId: workspaceId || "",
                projectId: projectId || "",
                name: v.name,
                status: TargetSchemaStatus.Draft,
                description: v.description,
                jsonSchema: v.jsonSchema,
              },
            },
          });
        }}
      />
    </>
  );
};
