import { useState } from "react";
import { Tabs } from "../common/Tabs/Tabs";
import { Tab } from "@mui/material";
import { AssetDetailOverview } from "./AssetDetailOverview/AssetDetailOverview";
import { AssetDetailGrid } from "./AssetDetailGrid/AssetDetailGrid";
import { useLocation } from "react-router-dom";
import { TabButton, TabsWrapper } from "./style";

export const AssetDetail = () => {
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const isEditMode = Boolean(sp.get("edit"));
  const tabParam = sp.get("tab");

  const [tab, setTab] = useState(tabParam === "schema" ? 1 : 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  return (
    <>
      <TabsWrapper
        value={tab}
        onChange={handleChange}
        sx={{ mb: 4 }}
        aria-label="asset detail tab"
      >
        <TabButton label="Overview" {...a11yProps(0)} />
        <TabButton label="Schema" {...a11yProps(1)} />
      </TabsWrapper>

      {tab === 0 && <AssetDetailOverview isEditMode={isEditMode} />}
      {tab === 1 && <AssetDetailGrid />}
    </>
  );
};
