import React from "react";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  DocumentNode,
  FetchResult,
  HttpLink,
  InMemoryCache,
  Observable,
  defaultDataIdFromObject,
} from "@apollo/client";
import { ErrorHandler, onError } from "@apollo/client/link/error";
import { toast } from "react-toastify";
import { setContext } from "@apollo/client/link/context";
import { graphqlLodash } from "graphql-lodash";
import { RefreshTokenDocument } from "./generated";
import { GraphQLError } from "graphql";
import { createUploadLink } from "apollo-upload-client";

export const AppApolloProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  //TODO temp error handling. Should probably talk to ui team about something more robust
  const handleErrors: ErrorHandler = ({
    graphQLErrors,
    networkError,
    operation,
    forward,
  }) => {
    if (graphQLErrors) {
      let showError = true;
      for (let err of graphQLErrors) {
        console.log(err, "error in appolo");
        switch (err.extensions?.code) {
          case "UNAUTHENTICATED":
            // refresh token
            if (err.message.includes("This access token has expired")) {
              // ignore 401 error for a refresh request
              if (operation.operationName === "refreshToken") return;

              const observable = new Observable<
                FetchResult<Record<string, any>>
              >((observer) => {
                // used an annonymous function for using an async function
                (async () => {
                  try {
                    const accessToken = await handleRefreshToken();
                    if (!accessToken) {
                      throw new GraphQLError("Empty AccessToken");
                    }

                    // Retry the failed request
                    const subscriber = {
                      next: observer.next.bind(observer),
                      error: observer.error.bind(observer),
                      complete: observer.complete.bind(observer),
                    };

                    forward(operation).subscribe(subscriber);
                  } catch (err) {
                    observer.error(err);
                    localStorage.clear();
                    window.location.href = "/login";
                  }
                })();
              });

              return observable;
            } else if (err.message.includes("not authenticated")) {
              localStorage.clear();
              window.location.href = "/login";
            }
            break;
          case "INTERNAL_SERVER_ERROR":
            showError = false;
            break;
        }
      }
      if (showError) {
        const errors = graphQLErrors.map(({ message }) => message);
        toast.error(errors.join(`, `), {
          onClose: () => {
            if (
              graphQLErrors.find(
                (error) => error.extensions.code === "FORBIDDEN"
              )
            ) {
              // localStorage.clear();
              // window.location.href = "/login";
              console.log("FORBIDDEN");
            }
          },
        });
      }
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      toast.error(
        `Unexpected error. If this continues, please contact the Brighthive Team. The page will reload.`,
        {
          onClose: () => {
            window.location.reload();
          },
        }
      );
    }
  };

  const authLink = setContext(({ operationName }, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem("token");
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization:
          token && operationName !== "refreshToken" ? `Bearer ${token}` : "",
      },
    };
  });

  const lodashLink = new ApolloLink((operation, forward) => {
    const { query, transform } = graphqlLodash(operation.query);
    operation.query = query as DocumentNode;
    return forward(operation).map((response) => ({
      ...response,
      data: transform(response.data),
    }));
  });

  const client = new ApolloClient({
    connectToDevTools: true,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            workspace: {
              keyArgs: ["@connection", ["cacheKey"]],
            },
            currentUser: {
              keyArgs: ["@connection", ["cacheKey"]],
            },
          },
        },
      },
    }),
    link: ApolloLink.from([
      onError(handleErrors),
      lodashLink,
      authLink,
      createUploadLink({
        uri: `${process.env.REACT_APP_GRAPHQL_URL}`,
        headers: {
          "Apollo-Require-Preflight": "true",
        },
      }),
      new HttpLink({ uri: `${process.env.REACT_APP_GRAPHQL_URL}` }),
    ]),
  });

  // Request a refresh token to then stores and returns the accessToken.
  const handleRefreshToken = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken") || "";
      const refreshResolverResponse = await client.mutate({
        mutation: RefreshTokenDocument,
        variables: {
          input: {
            refreshToken,
          },
        },
      });

      const accessToken = refreshResolverResponse.data?.refreshToken.idToken;
      const accessNewToken =
        refreshResolverResponse.data?.refreshToken.accessToken;
      if (accessToken) {
        localStorage.setItem("token", accessToken);
      }
      if (accessNewToken) {
        localStorage.setItem("accessToken", accessNewToken);
      }
      return accessToken;
    } catch (err) {
      throw err;
    }
  };

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export const getAuthToken = () => {
  const authToken = localStorage.getItem("accessToken") || "";

  return authToken;
};
