import { Box, ListItem, Typography } from "@mui/material";
import { DrawerIconType, DrawerType } from ".";
import {
  HeaderContainer,
  HeaderSubContainer,
  HeroTitle,
  IconButton,
  IconsList,
} from "./style";

type Props = {
  title: string;
  sectionName: string;
  drawerType: DrawerType;
  icons: DrawerIconType[];
};

const DrawerHeader = ({ title, sectionName, drawerType, icons }: Props) => {
  return (
    <HeaderContainer p={2}>
      <HeaderSubContainer>
        <Box>
          <HeroTitle variant="caption">
            {drawerType} {sectionName}
          </HeroTitle>
          <Typography
            textTransform={"capitalize"}
            variant="h4"
            color="gray.darkest"
          >
            {title}
          </Typography>
        </Box>
        <IconsList>
          {icons.map((iconObj) => (
            <ListItem>
              <IconButton
                onClick={iconObj.handler}
                disabled={iconObj.disabled || false}
              >
                <i className="icn">{iconObj.icon}</i>
              </IconButton>
            </ListItem>
          ))}
        </IconsList>
      </HeaderSubContainer>
    </HeaderContainer>
  );
};

export default DrawerHeader;
