import React, { useMemo } from "react";
import { Box, Divider, Popover, useTheme } from "@mui/material";
import { Field, Form, Formik, FormikValues } from "formik";
import { CheckboxWithLabel } from "formik-mui";
import { FilterButton, FilterButtonProps } from "../FilterButton/FilterButton";
import { useMenu } from "../Menu/useMenu";
import { Button } from "../Button/Button";
import { Typography } from "../Typography/Typography";
import { GetProjectsQuery } from "../../generated";
import { ButtonDefault, ButtonPrimary, PopoverBtnsWrap } from "./style";

interface SpecificFilterByOwnerButtonProps {
  text: string;
  values: FormikValues;
  onSubmit: (values: FormikValues) => void;
  workspace?: GetProjectsQuery["workspace"];
}

export type FilterByOwnerButtonProps = Omit<FilterButtonProps, "onSubmit"> &
  SpecificFilterByOwnerButtonProps;

export const FilterByOwnerButton = ({
  text,
  values,
  onSubmit,
  workspace,
  ...rest
}: FilterByOwnerButtonProps) => {
  const [menuOpen, anchorEl, openMenuFromAnchorElement, closeMenu] = useMenu();

  const owners = useMemo(() => {
    const owners: string[] = [];
    Object.keys(values).forEach((value) => {
      if (value !== "all") {
        owners.push(value);
      }
    });
    return owners;
  }, [values]);

  const onClickAll =
    (values: FormikValues, setValues: (...args: any[]) => any) => () => {
      if (values.all) {
        const allValues: FormikValues = { all: false };
        owners.forEach((owner) => {
          allValues[owner] = false;
        });
        setValues(allValues);
      } else {
        const allValues: FormikValues = { all: true };
        owners.forEach((owner) => {
          allValues[owner] = true;
        });
        setValues(allValues);
      }
    };

  const onClickValue =
    (values: FormikValues, setValues: (...args: any[]) => any, value: any) =>
    () => {
      let allOtherValuesActive = true;
      Object.keys(values).forEach((curr) => {
        if (curr !== value && curr !== "all" && values[curr] !== true) {
          allOtherValuesActive = false;
        }
      });

      if (values[value] === false && allOtherValuesActive) {
        setValues({
          ...values,
          all: true,
        });
      } else if (values[value] === true) {
        setValues({
          ...values,
          all: false,
          [value]: false,
        });
      } else {
        setValues({
          ...values,
          [value]: !values[value],
        });
      }
    };
  const { palette } = useTheme();

  return (
    <>
      <FilterButton
        id="filter-owner-button"
        aria-controls={menuOpen ? "filter-owner-form" : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        color="grayAccent"
        variant="outlined"
        onClick={openMenuFromAnchorElement}
        chip={null}
        text={text}
        {...rest}
      />
      <Popover
        id="filter-owner-form"
        aria-labelledby="filter-owner-button"
        open={menuOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        onClose={closeMenu}
      >
        <Formik
          initialValues={values}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            onSubmit(values);
            closeMenu();
          }}
        >
          {({ submitForm, setValues, values }) => (
            <Form>
              <Box width="20rem" pl={6} pr={6}>
                <Box pt={4} pb={3}>
                  <Typography sx={{ mb: 4 }} variant="h6">
                    Filter by owner
                  </Typography>
                  <Box>
                    <Field
                      component={CheckboxWithLabel}
                      name="all"
                      Label={{
                        label: `All (${workspace?.projects?.length || 0})`,
                        componentsProps: { typography: { variant: "body2" } },
                      }}
                      type="checkbox"
                      onClick={onClickAll(values, setValues)}
                    />
                  </Box>
                  {owners.map((owner) => {
                    return (
                      <Box>
                        <Field
                          component={CheckboxWithLabel}
                          name={owner}
                          Label={{
                            label: `${owner} (${
                              workspace?.projects?.filter(
                                (project) => project?.owner?.name === owner
                              ).length || 0
                            })`,
                            componentsProps: {
                              typography: { variant: "body2" },
                            },
                          }}
                          type="checkbox"
                          onClick={onClickValue(values, setValues, owner)}
                        />
                      </Box>
                    );
                  })}
                </Box>
                <Divider />
                <PopoverBtnsWrap>
                  <ButtonDefault
                    variant="outlined"
                    color="grayAccent"
                    onClick={closeMenu}
                  >
                    Cancel
                  </ButtonDefault>
                  <ButtonPrimary
                    variant="contained"
                    color="primary"
                    onClick={submitForm}
                  >
                    Apply
                  </ButtonPrimary>
                </PopoverBtnsWrap>
              </Box>
            </Form>
          )}
        </Formik>
      </Popover>
    </>
  );
};
