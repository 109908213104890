import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export interface DestinationApiIconProps {}

export const DestinationApiIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3969 6.15399C16.9502 4.20428 15.9064 2.84029 14.5603 2.05108C13.1147 1.20359 11.3925 1.06088 9.84243 1.46695C8.29233 1.87303 6.86037 2.84202 6.01308 4.28916C5.27282 5.55349 5.01067 7.12884 5.44429 8.90388C1.31844 9.99084 1.63817 16.4147 6.31668 16.7481C6.33442 16.7493 6.3522 16.75 6.36998 16.75H11.25V18.3535C10.3341 18.6125 9.61253 19.3342 9.3535 20.25H5.99998C5.58577 20.25 5.24998 20.5858 5.24998 21C5.24998 21.4142 5.58577 21.75 5.99998 21.75H9.3535C9.67996 22.9042 10.7412 23.75 12 23.75C13.2588 23.75 14.32 22.9042 14.6465 21.75H18C18.4142 21.75 18.75 21.4142 18.75 21C18.75 20.5858 18.4142 20.25 18 20.25H14.6465C14.3874 19.3342 13.6658 18.6125 12.75 18.3535V16.75H16.0269C17.3863 16.7607 18.694 16.2501 19.6989 15.34C22.8999 12.5345 21.4023 7.09961 17.3969 6.15399ZM6.39688 10.2601C6.48533 10.2616 6.57555 10.2475 6.66374 10.2157C7.05353 10.0756 7.2559 9.64602 7.11576 9.25623C6.48548 7.5032 6.69716 6.08954 7.30753 5.04705C7.92837 3.98668 9.00515 3.23691 10.2226 2.91799C11.44 2.59905 12.7441 2.72509 13.8016 3.34509C14.8412 3.95458 15.7151 5.08346 16.0201 6.92267C16.0743 7.24954 16.3366 7.5021 16.6653 7.54395C19.796 7.94255 21.0776 12.1426 18.7064 14.2153L18.6958 14.2248C17.9622 14.8909 17.0134 15.2583 16.0364 15.25L6.39803 15.25C3.27862 15.0036 3.29302 10.5059 6.39688 10.2601ZM12 19.75C11.3096 19.75 10.75 20.3096 10.75 21C10.75 21.6903 11.3096 22.25 12 22.25C12.6903 22.25 13.25 21.6903 13.25 21C13.25 20.3096 12.6903 19.75 12 19.75Z"
      />
    </SvgIcon>
  );
};
