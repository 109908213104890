import React from "react";
import { Node } from "react-flow-renderer";
import { Typography } from "../common/Typography/Typography";
import { Box, useTheme } from "@mui/material";
import { NodeType, NODE_WIDTH } from "./helpers";
import { Button } from "../common/Button/Button";
import { ButtonPrimary } from "./style";

export interface AddNodeData {
  buttonLabel: string;
  nodeType: NodeType;
  disabled?: boolean;
}

export const AddNode = ({
  data: { buttonLabel, disabled },
}: Node<AddNodeData>) => {
  return (
    <Box display="flex" flexDirection="column" width={NODE_WIDTH}>
      <ButtonPrimary
        disabled={disabled}
        color="grayAccent"
        variant="contained"
      >
        {buttonLabel}
      </ButtonPrimary>
    </Box>
  );
};
