import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const CloseIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M17.5271 6.97584C17.2021 6.65084 16.6771 6.65084 16.3521 6.97584L12.2771 11.0425L8.20214 6.9675C7.87714 6.6425 7.35214 6.6425 7.02714 6.9675C6.70214 7.2925 6.70214 7.81751 7.02714 8.14251L11.1021 12.2175L7.02714 16.2925C6.70214 16.6175 6.70214 17.1425 7.02714 17.4675C7.35214 17.7925 7.87714 17.7925 8.20214 17.4675L12.2771 13.3925L16.3521 17.4675C16.6771 17.7925 17.2021 17.7925 17.5271 17.4675C17.8521 17.1425 17.8521 16.6175 17.5271 16.2925L13.4521 12.2175L17.5271 8.14251C17.8438 7.82584 17.8438 7.29251 17.5271 6.97584Z"
        fill="black"
        fillOpacity="0.72"
      />
    </SvgIcon>
  );
};
