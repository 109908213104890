import { Box, Checkbox, Typography } from "@mui/material";
import Drawer, {
  DisplayDrawerHandlerType,
  DrawerDisplayerType,
  DrawerIconType,
} from "../../../common/Drawer";
import { EditPencilIcon } from "../../../common/Icons/EditPencilIcon";
import { DeleteIcon } from "../../../common/Icons/DeleteIcon";
import RemoveFieldDialog from "../RemoveFieldDialog";
import useRemoveFieldRenderer from "../hooks/useRemoveFieldRenderer";
import { TargetSchema } from "../../../generated";
import { displayToast } from "../../../common/Toast/toast";
import { GridApi } from "ag-grid-community";
import {
  FieldContainer,
  StyledFormControlLabel,
  ViewDrawerContainer,
} from "./style";

type Props = {
  drawerDisplayer: DrawerDisplayerType;
  displayDrawerHandler: DisplayDrawerHandlerType;
  data: any;
  targetSchema: TargetSchema;
  gridApi: GridApi | null;
};

const ProjectSchemaDetailViewDrawer = ({
  drawerDisplayer,
  displayDrawerHandler,
  data,
  targetSchema,
  gridApi,
}: Props) => {
  const removeField = () => {
    if (!gridApi) {
      displayToast("Error when removing row from Table", {}, "error");
      return;
    }
    displayDrawerHandler(null);
    // Find the node with the matching row ID
    const rowNode = gridApi.getRowNode(data?.no)?.data;
    if (!rowNode) {
      displayToast("Error when removing row from Table", {}, "error");
      return;
    }
    gridApi.applyTransaction({ remove: [rowNode] });
    displayToast("Removed Field");
  };

  const { openDialog, dialogOpen, closeDialog, onClickRemoveField } =
    useRemoveFieldRenderer({ id: data?.name, removeField, targetSchema });

  const icons: DrawerIconType[] = [
    {
      icon: <EditPencilIcon />,
      handler: () => {
        displayDrawerHandler("edit", data?.no);
      },
    },
    {
      icon: <DeleteIcon />,
      handler: () => {
        openDialog();
      },
    },
  ];

  return (
    <>
      <Drawer
        drawerDisplayer={drawerDisplayer}
        displayDrawerHandler={displayDrawerHandler}
        displayFooter={false}
        drawerType="view"
        sectionName={"schema"}
        title={data?.name}
        icons={icons}
      >
        {data && (
          <ViewDrawerContainer>
            <FieldContainer>
              <Typography gutterBottom variant="subtitle3">
                Name
              </Typography>
              <Typography variant="body2" color="gray.darkest">
                <Box display="flex" gap={2} alignItems="center">
                  {data.name}
                </Box>
              </Typography>
            </FieldContainer>

            <FieldContainer>
              <Typography gutterBottom variant="subtitle3">
                Title
              </Typography>
              <Typography variant="body2" color="gray.darkest">
                <Box display="flex" gap={2} alignItems="center">
                  {data.title}
                </Box>
              </Typography>
            </FieldContainer>

            <FieldContainer>
              <Typography gutterBottom variant="subtitle3">
                Type
              </Typography>
              <Typography variant="body2" color="gray.darkest">
                <Box display="flex" gap={2} alignItems="center">
                  {data.type}
                </Box>
              </Typography>
            </FieldContainer>

            <FieldContainer>
              <Typography gutterBottom variant="subtitle3">
                Description
              </Typography>
              <Typography variant="body2" color="gray.darkest">
                <Box display="flex" gap={2} alignItems="center">
                  {data.description}
                </Box>
              </Typography>
            </FieldContainer>

            <FieldContainer>
              <StyledFormControlLabel
                disabled={true}
                control={<Checkbox size="medium" />}
                label="Validate field constraints"
                checked={data.required}
              />
            </FieldContainer>
          </ViewDrawerContainer>
        )}
      </Drawer>
      <RemoveFieldDialog
        dialogOpen={dialogOpen}
        closeDialog={closeDialog}
        onClickRemoveField={onClickRemoveField}
      />
    </>
  );
};

export default ProjectSchemaDetailViewDrawer;
