import React from "react";
import { Box, Divider, useTheme } from "@mui/material";
import { Check as CheckIcon, ErrorOutline } from "@mui/icons-material";

import { Typography } from "../../common/Typography/Typography";
import { BasicCard } from "../../common/BasicCard/BasicCard";
import { Label } from "../../common/Label/Label";
import { Chip } from "../../common/Chip/Chip";
import { GetADataAssetQuery, RefreshStatus } from "../../generated";
import { AdditionalDetailsWrapper, AdditionalRow, AdditionalTopBox, PageDivider, TextParagraph } from "../style";

export const AdditionalDetails = ({
  data,
  ...rest
}: {
  data: GetADataAssetQuery;
  mb: string;
}) => {
  const { palette } = useTheme();
  const dataAsset = data.workspace?.dataAssets?.[0];
  if (!dataAsset) {
    return null;
  }
  if (dataAsset)
    return (
      <AdditionalDetailsWrapper className="AdditionalDetails" {...rest}>
        <AdditionalTopBox>
          <Typography variant={"h6"}>Additional details</Typography>
        </AdditionalTopBox>
        <Divider />
        <AdditionalTopBox className="p-same">
          <AdditionalRow>
            <Label value="Refresh status" info />
            {dataAsset?.refreshStatus !== RefreshStatus.OutOfDate ? (
              <Chip
                icon={
                  <CheckIcon
                    sx={{ ml: "0rem !important", mr: ".313rem !important" }}
                    fontSize={"small"}
                  />
                }
                size="medium"
                label={dataAsset.refreshStatus}
              />
            ) : (
              <Chip
                size="medium"
                icon={
                  <ErrorOutline
                    sx={{ ml: "0rem !important", mr: ".313rem !important" }}
                    fontSize={"small"}
                  />
                }
                color="error"
                label={dataAsset.refreshStatus?.replaceAll("_", " ")}
              />
            )}
          </AdditionalRow>
          <PageDivider />
          <AdditionalRow className="mb-4">
            <Label value="Created" />
            <TextParagraph className="dark" variant={"body2"}>
              {dataAsset.createdAt}
            </TextParagraph>
          </AdditionalRow>
          <AdditionalRow className="mb-4">
            <Label value="Updated" />
            <TextParagraph className="dark" variant={"body2"}>
              {dataAsset.updatedAt}
            </TextParagraph>
          </AdditionalRow>
        </AdditionalTopBox>
      </AdditionalDetailsWrapper>
    );
  return null;
};
