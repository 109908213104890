import {
  Box,
  Drawer,
  FormControl,
  FormLabel,
  List,
  ListItem,
  MenuItem,
  TextField,
  TextareaAutosize,
  Typography,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import { DeleteIcon } from "../../../common/Icons/DeleteIcon";
import { EditPencilIcon } from "../../../common/Icons/EditPencilIcon";
import { MessagesIcon } from "../../../common/Icons/MessagesIcon";
import { ShareIcon } from "../../../common/Icons/ShareIcon";
import ProfileAvatarIcon from "../../../common/ProfileAvatarIcon/ProfileAvatarIcon";
import { convertToTitleCase, dateHelper } from "../../../helpers";
import { useMutation } from "@apollo/client";
import {
  CreateTermDocument,
  ScopeType,
  TermsDocument,
} from "../../../generated";
import { Select } from "../../../common/Form/Select";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CustomTextField } from "../../../common/CustomTextField/CustomTextField";
import { CustomTextArea } from "../../../common/CustomTextArea/CustomTextArea";
import { CloseIcon } from "../../../common/Icons/CloseIcon";
import { Button } from "../../../common/Button/Button";
import * as Yup from "yup";
import { MAX_DESCRIPTION_CHARS } from "../../../Constants";

export const TermAdd = ({ onClose }: any) => {
  const { workspaceId } = useParams();
  const { palette } = useTheme();

  const [createTerm, { loading }] = useMutation(CreateTermDocument, {
    refetchQueries: [TermsDocument],
  });

  return (
    <Formik
      initialValues={{
        name: "",
        description: "",
        scope: ScopeType.DataAsset,
        type: "",
      }}
      initialErrors={{
        scope: ScopeType.Workspace,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(),
        description: Yup.string().max(MAX_DESCRIPTION_CHARS),
        type: Yup.string().required(),
        scope: Yup.string().oneOf(Object.values(ScopeType)),
      })}
      onSubmit={async (values) => {
        createTerm({
          variables: {
            input: {
              workspaceId: workspaceId || "",
              type: values.type,
              scope: values.scope,
              name: values.name,
              description: values.description,
            },
          },
        })
          .then((res) => {
            toast.success("Term Added");
            onClose();
          })
          .catch((err) => {
            toast.error("Error");
          });
      }}
    >
      {({
        handleChange,
        values,
        errors,
        touched,
        handleSubmit,
        setFieldValue,
      }: any) => (
        <Drawer anchor="right" open onClose={onClose}>
          <CloseIcon
            onClick={onClose}
            sx={{
              position: "absolute",
              right: "20px",
              top: "20px",
              cursor: "pointer",
              width: "20px",
              height: "20px",
              color: palette.gray.dark,
            }}
          />
          <Box width={400} p={6}>
            <Box
              boxSizing="border-box"
              sx={{
                minHeight: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Box
                  sx={{
                    paddingRight: "20px",
                    paddingBottom: "16px",
                    borderBottom: `1px solid ${palette.outline}`,
                  }}
                  mb={6}
                >
                  <Typography
                    mb={2}
                    variant="h5"
                    color={palette.gray.dark}
                    sx={{
                      fontSize: "12px",
                      lineHeight: "14px",
                      textTransform: "uppercase",
                      fontWeight: "700",
                    }}
                  >
                    Term
                  </Typography>
                  <Typography
                    mb={4}
                    variant="h5"
                    color={palette.common.black}
                    sx={{ wordBreak: "break-word" }}
                  >
                    New Term
                  </Typography>
                </Box>
              </Box>

              <Box boxSizing="border-box">
                <Box mb={6}>
                  <Box
                    sx={{
                      paddingRight: "20px",
                      paddingBottom: "16px",
                      borderBottom: `1px solid ${palette.outline}`,
                    }}
                    mb={6}
                  >
                    <Box mb={6} display="flex" flexDirection="column">
                      <Typography
                        variant="body1"
                        mb={2}
                        fontSize="0.875rem"
                        fontWeight={500}
                        color={palette.common.black}
                      >
                        Term Name
                      </Typography>

                      <CustomTextField
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        error={errors.name}
                        touched={touched.name}
                      />
                    </Box>
                    <Box mb={6} display="flex" flexDirection="column">
                      <Typography
                        variant="body1"
                        mb={2}
                        fontSize="0.875rem"
                        fontWeight={500}
                        color={palette.common.black}
                      >
                        Term Description
                      </Typography>

                      <CustomTextArea
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        error={errors.description}
                        touched={touched.description}
                      />
                    </Box>
                    <Box mb={6} display="flex" flexDirection="column">
                      <Typography
                        variant="body1"
                        mb={2}
                        fontSize="0.875rem"
                        fontWeight={500}
                        color={palette.common.black}
                      >
                        Term Type
                      </Typography>

                      <CustomTextField
                        name="type"
                        value={values.type}
                        onChange={handleChange}
                        error={errors.type}
                        touched={touched.type}
                      />
                    </Box>
                    <Box mb={6} display="flex" flexDirection="column">
                      <Typography
                        variant="body1"
                        mb={2}
                        fontSize="0.875rem"
                        fontWeight={500}
                        color={palette.common.black}
                      >
                        Term Scope
                      </Typography>
                      <Box>
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="scope"
                            placeholder="Select target schema validation method"
                            label="scope"
                            onChange={handleChange}
                            value={values.scope}
                            sx={{
                              fieldset: {
                                top: "0",
                              },
                              legend: {
                                display: "none",
                              },
                              ".MuiInputBase-input": {
                                width: "100%",
                                height: "36px",
                                fontSize: "16px",
                                lineHeight: "20px",
                                padding: "8px",
                                boxSizing: "border-box",
                              },
                            }}
                          >
                            {Object.entries(ScopeType).map((val, i) => (
                              <MenuItem value={val[1]}>
                                {convertToTitleCase(val[1])}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ width: "100%", marginBottom: "10px" }}
                  disabled={loading}
                  onClick={(e) => handleSubmit()}
                >
                  Save
                </Button>
                <Button
                  color="grayAccent"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Drawer>
      )}
    </Formik>
  );
};
