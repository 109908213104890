import { useQuery } from "@apollo/client";
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import {
  GetSourcesInternalDocument,
  SourceDefinitionOutput,
} from "../../../../generated";
import { useParams } from "react-router-dom";
import { SimpleLoader } from "../../../../common/Loader/Loader";
import { Specification } from "./Specification";

export const Internal = ({ close }: { close: Function }) => {
  const [definition, setDefinition] = useState<SourceDefinitionOutput>();
  const { workspaceId } = useParams();
  const { palette } = useTheme();

  const { data } = useQuery(GetSourcesInternalDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
    },
  });

  return (
    <Box
      sx={{
        marginTop: "24px",
      }}
    >
      {!data ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ margin: "2rem 0", ">div": { minWidth: "100%" } }}
        >
          <SimpleLoader text="Fetching Source Type..." />
        </Box>
      ) : (
        <FormControl
          fullWidth
          sx={{
            marginBottom: "24px",

            ".MuiInputBase-formControl": {
              borderRadius: "3px",
            },

            fieldset: {
              borderColor: palette.grayAccent.light,
            },
          }}
        >
          <Typography
            sx={{
              color: palette.common.black,
              letterSpacing: "0.0025em",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "20px",
              marginBottom: "6px",
            }}
          >
            Source Type
          </Typography>
          {/* <InputLabel
            id="demo-simple-select-label"
            sx={{
              fontSize: "16px",
              lineHeight: "1",
              fontWeight: "400",
              transform: "none",
              top: "35px",
              color: palette.gray.dark,
              padding: "0 15px"
            }}
          >
            Source Type
          </InputLabel> */}
          <Select
            value={definition}
            onChange={(e: any) => {
              const { __typename, ...rest } = JSON.parse(e.target.value);
              setDefinition(rest);
            }}
            sx={{
              "> div": {
                borderRadius: "3px",
                background: palette.common.white,
                height: "20px !important",
                padding: "7px 15px",
                fontSize: "14px",
                lineHeight: "1",
                fontWeight: "400",
                display: "flex",
                alignItems: "center",

                "&:before": {
                  content: "''",
                  position: "absolute",
                  right: "15px",
                  top: "10px",
                  border: `solid ${palette.gray.dark}`,
                  borderWidth: "0 2px 2px 0",
                  transform: "rotate(45deg)",
                  width: "7px",
                  height: "7px",
                },

                "&[aria-expanded='true']": {
                  "&:before": {
                    transform: "rotate(-135deg)",
                    top: "14px",
                  },
                },

                svg: {
                  width: "20px",
                  height: "20px",
                  color: palette.grayAccent.light,
                  marginRight: "3px",
                },

                img: {
                  width: "24px",
                  height: "24px",
                  color: palette.grayAccent.light,
                  marginRight: "3px",
                },
              },

              ".MuiSelect-icon": {
                display: "none",
              },
            }}
          >
            {data?.workspace?.services?.sourceDefinitions.map(
              (sourceDefinition: any, index) => (
                <MenuItem
                  key={index}
                  value={JSON.stringify(sourceDefinition)}
                  sx={{
                    padding: "8px",
                    borderRadius: "4px",
                    border: `1px solid ${palette.customRolled.lighter}`,
                    fontSize: "14px",
                    lineHeight: "20px",
                    letterSpacing: "0.0025rem",
                    fontWeight: "500",
                    margin: "8px",

                    "&:first-child": {
                      marginTop: "0",
                    },

                    "&:last-child": {
                      marginBottom: "0",
                    },

                    svg: {
                      width: "24px",
                      height: "24px",
                      color: palette.grayAccent.light,
                      marginRight: "12px",
                      verticalAlign: "top",

                      Image: {
                        objectFit: "cover",
                        width: "100% !important",
                        height: "100% !important",
                      },
                    },

                    img: {
                      width: "24px",
                      height: "24px",
                      color: palette.grayAccent.light,
                      marginRight: "12px",
                      verticalAlign: "top",
                      objectFit: "cover",
                    },
                  }}
                >
                  {sourceDefinition?.icon && (
                    <Box
                      sx={{
                        svg: {
                          width: "24px",
                          height: "24px",
                          color: palette.grayAccent.light,
                        },
                      }}
                    >
                      {sourceDefinition.icon.includes("<svg") ? (
                        <img
                          src={`data:image/svg+xml;base64,${window.btoa(
                            sourceDefinition.icon
                          )}`}
                          width="24"
                          height="24"
                        />
                      ) : (
                        <img
                          src={sourceDefinition.icon}
                          width="24"
                          height="24"
                        />
                      )}
                    </Box>
                  )}
                  {sourceDefinition.name}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      )}
      {definition && <Specification definition={definition} close={close} />}
    </Box>
  );
};
