import React from "react";
import { LeftRightContent } from "../../common/LeftRightContent/LeftRightContent";
import { SearchBar } from "../../common/SearchBar/SearchBar";
import { alpha, Box, useTheme } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { ActionBarAssetDetailSearch, ActionBarAssetDetailWrapper } from "../style";

export interface AssetBarDetailGridProps {
  onChangeSearch?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  searchText?: string;
  noResultsFoundFromFilter: boolean;
}

export const ActionBarAssetDetail = ({
  onChangeSearch,
  searchText,
  noResultsFoundFromFilter,
}: AssetBarDetailGridProps) => {
  const { palette } = useTheme();

  const border = `.0625rem  ${palette.outline} solid`;

  return (
    <ActionBarAssetDetailWrapper
      className="ActionBarAssetDetail"
    >
      <LeftRightContent
        sx={{ pb: 2, pt: 2, pr: 4, pl: 4 }}
        leftElements={<></>}
        rightElements={
          <SearchBar
            sx={{ width: "18rem", mr: 2 }}
            placeholder="Search name, description"
            onChange={onChangeSearch}
            value={searchText}
          />
        }
      />
      {searchText && searchText.length > 0 && (
        <ActionBarAssetDetailSearch
          borderTop={border}
          bgcolor={alpha(palette.primary.main, palette.action.hoverOpacity)}
        >
          <Typography variant="body3">
            {noResultsFoundFromFilter
              ? `0 results for "${searchText}"`
              : `Results for ${searchText}`}
          </Typography>
        </ActionBarAssetDetailSearch>
      )}
    </ActionBarAssetDetailWrapper>
  );
};
