import { useMutation } from "@apollo/client";
import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { CloseIcon } from "stream-chat-react";
import {
  CreateResourceFolderDocument,
  GetResourcesDocument,
} from "../../generated";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ButtonDefault, ButtonPrimary } from "./style";

export const CreateFolder = ({ onClose }: any) => {
  const { workspaceId, folderId } = useParams();
  const [folder, setFolder] = useState<any>();
  const { palette } = useTheme();

  const [create, { loading }] = useMutation(CreateResourceFolderDocument, {
    refetchQueries: [GetResourcesDocument],
  });

  const folderCreate = () => {
    create({
      variables: {
        input: {
          name: folder,
          workspaceId: workspaceId || "",
          parentId: folderId || "",
        },
      },
    }).then((res) => {
      toast.success(`${folder} has been created`);
      onClose();
    });
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "720px",
        position: "absolute",
        left: "50%",
        top: "50%",
        backgroundColor: palette.common.white,
        transform: "translate(-50%, -50%)",
        borderRadius: "11px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "15px",
          padding: "24px",
          borderBottom: `1px solid ${palette.action.disabledBackground}`,
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            fontSize: "24px",
            lineHeight: "28px",
          }}
        >
          Create Folder
        </Typography>
        <Button
          type="button"
          onClick={() => onClose()}
          sx={{
            minWidth: "1px",
            padding: "0",
            color: palette.gray.dark,

            svg: {
              width: "20px",
              height: "20px",
            },
          }}
        >
          <CloseIcon />
        </Button>
      </Box>
      <Box
        sx={{
          padding: "48px 24px",
        }}
      >
        <Typography
          variant="caption"
          sx={{
            fontSize: "14px",
            lineHeight: "20px",
            display: "block",
            color: palette.common.black,
            marginBottom: "6px",
          }}
        >
          Folder Name
        </Typography>
        <TextField
          placeholder="Enter Name"
          onChange={(e) => setFolder(e?.target?.value)}
          sx={{
            width: "100%",

            input: {
              height: "36px",
              fontSize: "14px",
              lineHeight: "18px",
              padding: "8px 12px",
              boxSizing: "border-box",

              "&::placeholder": {
                color: palette.gray.dark,
                opacity: "1",
              },
            },
          }}
        />
      </Box>
      <Box
        sx={{
          boxShadow: `0 -3px 5px ${palette.gray.main}`,
          padding: "24px",
          display: "flex",
          justifyContent: "flex-end",
          gap: "12px",
        }}
      >
        <ButtonDefault type="button" onClick={() => onClose()}>
          Cancel
        </ButtonDefault>
        <ButtonPrimary
          onClick={() => folderCreate()}
          type="button"
          disabled={!folder || loading}
        >
          Create Folder
        </ButtonPrimary>
      </Box>
    </Box>
  );
};
