import { Box, useTheme } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { LeftRightContent } from "../../common/LeftRightContent/LeftRightContent";
import { SearchBar } from "../../common/SearchBar/SearchBar";
import { FilterByTagButton } from "../../common/ActionBarProjects/FilterByTagButton";
import { useState } from "react";
import { AddEngineDialog } from "./AddEngineDialog";

export const ActionBarEngines = () => {
  const { palette } = useTheme();
  const [open, setOpen] = useState(false);

  return (
    <>
      <LeftRightContent
        sx={{ p: 4 }}
        leftElements={<SearchBar placeholder="Find an engine" />}
        rightElements={
          <Box display="flex" alignItems="center" gap={2} ml={2}>
            <Typography
              variant="button"
              whiteSpace="nowrap"
              color={palette.grayAccent.main}
            >
              Filter by:{" "}
            </Typography>
            <FilterByTagButton
              text="Service"
              tags={[]}
              onSubmit={() => {}}
              values={{}}
            />
            <FilterByTagButton
              text="Language"
              tags={[]}
              onSubmit={() => {}}
              values={{}}
            />
            <FilterByTagButton
              text="Status"
              tags={[]}
              onSubmit={() => {}}
              values={{}}
            />
            {/* <Button variant="contained" onClick={() => setOpen(true)}>
              Connect an engine
            </Button> */}
          </Box>
        }
      />
      <AddEngineDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
};
