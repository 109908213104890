import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const MessagesIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6940_15976)">
        <path
          d="M17.5 11.6667L15 9.16667H9.16667C8.94565 9.16667 8.73369 9.07887 8.57741 8.92259C8.42113 8.76631 8.33333 8.55435 8.33333 8.33333V3.33333C8.33333 3.11232 8.42113 2.90036 8.57741 2.74408C8.73369 2.5878 8.94565 2.5 9.16667 2.5H16.6667C16.8877 2.5 17.0996 2.5878 17.2559 2.74408C17.4122 2.90036 17.5 3.11232 17.5 3.33333V11.6667Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          fill="none"
          strokeLinejoin="round"
        />
        <path
          d="M11.6667 12.5002V14.1668C11.6667 14.3878 11.5789 14.5998 11.4226 14.7561C11.2663 14.9124 11.0543 15.0002 10.8333 15.0002H5L2.5 17.5002V9.16683C2.5 8.94582 2.5878 8.73385 2.74408 8.57757C2.90036 8.42129 3.11232 8.3335 3.33333 8.3335H5"
          stroke="currentColor"
          strokeWidth="1.5"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6940_15976">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
