import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const PaperPlaneIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.8149 10.1956L3.28293 11.4506C3.19633 11.4651 3.11507 11.5021 3.04731 11.5579C2.97955 11.6137 2.92769 11.6864 2.89693 11.7686L0.299927 18.7266C0.0519273 19.3666 0.720927 19.9766 1.33493 19.6686L19.3349 10.6686C19.4593 10.6063 19.5639 10.5106 19.6371 10.3921C19.7102 10.2737 19.7489 10.1373 19.7489 9.99815C19.7489 9.85898 19.7102 9.72257 19.6371 9.60415C19.5639 9.48574 19.4593 9.39 19.3349 9.32765L1.33493 0.327647C0.720927 0.0206467 0.0519273 0.630647 0.299927 1.26965L2.89793 8.22765C2.92869 8.30987 2.98055 8.38255 3.04831 8.43838C3.11607 8.4942 3.19733 8.53119 3.28393 8.54565L10.8159 9.80065C10.8629 9.80815 10.9057 9.83215 10.9366 9.86835C10.9674 9.90455 10.9844 9.95057 10.9844 9.99815C10.9844 10.0457 10.9674 10.0917 10.9366 10.1279C10.9057 10.1641 10.8629 10.1881 10.8159 10.1956H10.8149Z" fill="currentColor"/>
    </SvgIcon>
  );
};
