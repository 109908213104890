import React from "react";
import { Button } from "../Button/Button";
import { Check, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Menu } from "../Menu/Menu";
import { MenuItem } from "../Menu/MenuItem";
import { useMenu } from "../Menu/useMenu";
import { ListItemText } from "../Menu/ListItemText";
import { Box, useTheme } from "@mui/material";

export interface SortByMenuProps {
  currSortByType: SortByType | null;
  setCurrSortByType: (value: SortByType | null) => any;
}

export enum SortByType {
  DateUpdated = "Date Updated",
  DateCreated = "Date Created",
  ProjectStatus = "Project Status",
  ProjectName = "Project Name",
}

export const SortByButtonMenu = ({
  currSortByType,
  setCurrSortByType,
}: SortByMenuProps) => {
  const [menuOpen, anchorEl, openMenuFromAnchorElement, closeMenu] = useMenu();
  const onClickMenuItem = (sortByType: SortByType | null) => {
    setCurrSortByType(sortByType);
    closeMenu();
  };

  const { palette } = useTheme();

  return (
    <>
      <Box
        sx={{
          button: {
            backgroundColor: palette.primary.main,
            color: palette.common.white,
            borderColor: `${palette.action.disabledBackground} !important`,

            "&:hover": {
              backgroundColor: palette.primary.dark,
              color: palette.common.white,
              boxShadow: "none",
            },
          },
        }}
      >
        <Button
          id="sort-by-button"
          aria-controls={menuOpen ? "sort-by-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? "true" : undefined}
          variant="outlined"
          color="grayAccent"
          onClick={openMenuFromAnchorElement}
          endIcon={menuOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        >
          Sort{currSortByType && `: ${currSortByType}`}
        </Button>
      </Box>
      <Menu
        id="sort-by-menu"
        MenuListProps={{ "aria-labelledby": "sort-by-button" }}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          ".MuiPaper-root": {
            width: "14.375rem",
            background: palette.common.white,
            border: "0",
            boxShadow: `0 2px 2px ${palette.gray.main}, 0 3px 1px ${palette.action.disabledBackground}, 0 1px 5px ${palette.action.disabled}`,
            borderRadius: "4px",
            display: "flex",
            flexDirection: "column",
            padding: "8px",
          },
          ".MuiButtonBase-root": {
            padding: "12px 19px",
            textAlign: "left",
            boxShadow: "none",
            border: "0 !important",
            justifyContent: "flex-start",
            color: palette.grayAccent.light,
          },
          ".MuiMenu-list": {
            display: "flex",
            flexDirection: "column",
            rowGap: "5px",
          },
        }}
      >
        <MenuItem
          selected={currSortByType === SortByType.DateUpdated}
          onClick={() =>
            onClickMenuItem(
              currSortByType === SortByType.DateUpdated
                ? null
                : SortByType.DateUpdated
            )
          }
          disableRipple
          sx={{
            borderRadius: "4px",

            ".MuiListItemText-primary": {
              fontWeight: "500",
              fontSize: "14px",
              color: palette.grayAccent.light,
            },

            "&:hover, &.MuiMenuItem-root.Mui-selected": {
              background: palette.primary.lighter,

              ".MuiListItemText-primary": {
                color: palette.primary.main,
              },
            },
          }}
        >
          <ListItemText>Date Updated</ListItemText>
          {currSortByType === SortByType.DateUpdated && (
            <Check sx={{ color: palette.gray.dark }} />
          )}
        </MenuItem>

        <MenuItem
          selected={currSortByType === SortByType.ProjectStatus}
          onClick={() =>
            onClickMenuItem(
              currSortByType === SortByType.ProjectStatus
                ? null
                : SortByType.ProjectStatus
            )
          }
          disableRipple
          sx={{
            borderRadius: "4px",

            ".MuiListItemText-primary": {
              fontWeight: "500",
              fontSize: "14px",
              color: palette.grayAccent.light,
            },

            "&:hover, &.MuiMenuItem-root.Mui-selected": {
              background: palette.primary.lighter,

              ".MuiListItemText-primary": {
                color: palette.primary.main,
              },
            },
          }}
        >
          <ListItemText>Project Status</ListItemText>
          {currSortByType === SortByType.ProjectStatus && (
            <Check sx={{ color: palette.gray.dark }} />
          )}
        </MenuItem>
        <MenuItem
          selected={currSortByType === SortByType.ProjectName}
          onClick={() =>
            onClickMenuItem(
              currSortByType === SortByType.ProjectName
                ? null
                : SortByType.ProjectName
            )
          }
          disableRipple
          sx={{
            borderRadius: "4px",

            ".MuiListItemText-primary": {
              fontWeight: "500",
              fontSize: "14px",
              color: palette.grayAccent.light,
            },

            "&:hover, &.MuiMenuItem-root.Mui-selected": {
              background: palette.primary.lighter,

              ".MuiListItemText-primary": {
                color: palette.primary.main,
              },
            },
          }}
        >
          <ListItemText>Project Name</ListItemText>
          {currSortByType === SortByType.ProjectName && (
            <Check sx={{ color: palette.gray.dark }} />
          )}
        </MenuItem>
        <MenuItem
          selected={currSortByType === SortByType.DateCreated}
          onClick={() =>
            onClickMenuItem(
              currSortByType === SortByType.DateCreated
                ? null
                : SortByType.DateCreated
            )
          }
          disableRipple
          sx={{
            borderRadius: "4px",

            ".MuiListItemText-primary": {
              fontWeight: "500",
              fontSize: "14px",
              color: palette.grayAccent.light,
            },

            "&:hover, &.MuiMenuItem-root.Mui-selected": {
              background: palette.primary.lighter,

              ".MuiListItemText-primary": {
                color: palette.primary.main,
              },
            },
          }}
        >
          <ListItemText>Date Created</ListItemText>
          {currSortByType === SortByType.DateCreated && (
            <Check sx={{ color: palette.gray.dark }} />
          )}
        </MenuItem>
      </Menu>
    </>
  );
};
