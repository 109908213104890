import React, { useState } from "react";
import { Button } from "../../common/Button/Button";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, useTheme } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { useMutation } from "@apollo/client";
import { DeactivateOrganizationUserDocument } from "../../generated";

export interface RemoveUserCellRendererProps {
  userId: string;
  removeMember: () => void;
}

export const RemoveUserCellRenderer = ({
  userId,
  removeMember,
}: RemoveUserCellRendererProps) => {
  const [mutate, { loading }] = useMutation(DeactivateOrganizationUserDocument);
  const [dialogOpen, setDialogOpen] = useState(false);
  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };
  const onClickRemoveMember = async () => {
    await mutate({
      variables: {
        input: {
          userId,
        },
      },
    });
    if (removeMember) removeMember();
    closeDialog();
  };
  const { palette } = useTheme();

  return (
    <>
      <Button
        onClick={openDialog}
        color="grayAccent"
        variant="text"
        startIcon={<CloseIcon />}
      >
        Remove
      </Button>
      <Dialog open={dialogOpen} onClose={closeDialog}>
        <Box width="35rem" p={6} boxSizing="border-box">
          <Typography mb={4} variant="h5">
            Remove User
          </Typography>
          <Typography color={palette.gray.dark} mb={4} variant="body2">
            By removing this user they will no longer have access to the
            organization.
          </Typography>
          <Box display="flex" mt={7} justifyContent="flex-end">
            <Button
              onClick={closeDialog}
              color="grayAccent"
              variant="outlined"
              sx={{ mr: 3 }}
            >
              Cancel
            </Button>
            <Button
              onClick={onClickRemoveMember}
              color="error"
              variant="contained"
              disabled={loading}
            >
              Remove
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
