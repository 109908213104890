import React from "react";
import { WorkflowInputDataAssetGroupOutput } from "../../../generated";
import { Box, useTheme, Divider } from "@mui/material";
import { Typography } from "../../../common/Typography/Typography";
import { CheckRounded, PersonRounded } from "@mui/icons-material";
import { Chip } from "../../../common/Chip/Chip";
import { Button } from "../../../common/Button/Button";
import {
  getIssueIcon,
  getIssueColor,
  getLabelIssueStatus,
} from "../../../Sheets/helpers";

interface DataAssetItemProps {
  item: WorkflowInputDataAssetGroupOutput;
  selected: boolean;
  onClickSelected: (v: boolean) => void;
}

function DataAssetItem({
  item,
  selected,
  onClickSelected,
}: DataAssetItemProps) {
  const { palette } = useTheme();

  const isContainsPii = item.pii;
  return (
    <Box
      px={4}
      py={4}
      border={`1px solid ${palette.action.disabledBackground}`}
      borderRadius={1}
      sx={{
        backgroundColor: selected ? palette.action.selected : "transparent",
      }}
    >
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box>
            <Typography variant="subtitle2">{item.name}</Typography>
            <Box display="flex" gap={2} mt={1}>
              {item.issueStatus && (
                <Chip
                  icon={getIssueIcon(item.issueStatus)}
                  color={getIssueColor(item.issueStatus)}
                  label={getLabelIssueStatus(item.issueStatus)}
                  sx={{
                    color: palette.common.black,
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "500",
                  }}
                />
              )}
              {isContainsPii && (
                <Chip
                  size="small"
                  variant="outlined"
                  label="Contains PII"
                  avatar={<PersonRounded />}
                />
              )}
            </Box>
          </Box>
          <Button
            color="grayAccent"
            variant="outlined"
            onClick={() => onClickSelected(!selected)}
            sx={{
              height: 32,
            }}
          >
            {selected && (
              <CheckRounded sx={{ mr: 1, ml: -1, mt: -0.5, fontSize: 16 }} />
            )}
            {selected ? "Selected" : "Select"}
          </Button>
        </Box>
        {item.description && (
          <Typography sx={{ mt: 2 }} variant="body3">
            {item.description}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default DataAssetItem;
