import {
  Box,
  Button,
  Divider,
  FormControl,
  Input,
  styled,
  useTheme,
  IconButton,
  TextField,
} from "@mui/material";

import { Typography } from "../common/Typography/Typography";
import React, { useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import EditIcon from "@mui/icons-material/Edit";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  GetWorkspaceInfoDocument,
  UpdateWorkspaceDocument,
} from "../generated";
import { useParams } from "react-router";
import axios from "axios";
import { url } from "inspector";
import { BrighthiveIcon } from "../common/Icons/BrighthiveIcon";
import { toast } from "react-toastify";
import { getRandomNumber } from "../helpers";
import { ButtonPrimary } from "./style";

export const SettingContent = () => {
  const { palette } = useTheme();
  const [editName, setEditName] = useState(false);
  const [editImage, setEditImage] = useState(false);

  const [name, setName] = useState("");
  const [file, setFile] = useState<File>();

  const { workspaceId } = useParams();

  const client = useApolloClient();

  const { data } = useQuery(GetWorkspaceInfoDocument, {
    variables: { input: { workspaceId: workspaceId || "" } },
  });

  const [update] = useMutation(UpdateWorkspaceDocument);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const updateName = () => {
    const toastId = toast.loading("Updating Name");
    update({
      variables: {
        input: {
          workspaceId: workspaceId || "",
          name,
        },
      },
    })
      .then(() => {
        setEditName(false);
        client.refetchQueries({
          include: [GetWorkspaceInfoDocument],
        });
        toast.dismiss(toastId);
        toast.success("Name Updated");
      })
      .catch((e) => {
        toast.dismiss(toastId);
      });
  };

  const updateImage = () => {
    const toastId = toast.loading("Updating Logo");

    update({
      variables: {
        input: {
          workspaceId: workspaceId || "",
          logo: {
            mimeType: file?.type || "",
          },
        },
      },
    }).then((res) => {
      const logoContext = res.data?.updateWorkspace.logoUploadContext;
      axios
        .post(
          logoContext?.url || "",
          {
            ...JSON.parse(logoContext?.fields || ""),
            "Content-Type": logoContext?.contentType || "",
            file,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          client.refetchQueries({
            include: [GetWorkspaceInfoDocument],
          });
          setEditImage(false);
          toast.dismiss(toastId);
          toast.success("Logo Updated");
          window.location.reload();
        })
        .catch((e) => {
          toast.dismiss(toastId);
        });
    });
  };

  return (
    <Box
      sx={{
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: "40px",
        paddingBottom: "40px",
        maxWidth: "600px",
      }}
    >
      <Box
        sx={{
          position: "relative",
          paddingRight: "60px",
        }}
      >
        <Box
          sx={{
            marginBottom: "6px",
          }}
        >
          <Typography
            variant="caption"
            sx={{
              color: palette.grayAccent.light,
              fontSize: "13px",
              fontWeight: "500",
              textTransform: "uppercase",
            }}
          >
            Workspace Name
          </Typography>
        </Box>
        {editName ? (
          <>
            <TextField
              name="workspaceName"
              sx={{
                background: "white",
              }}
              defaultValue={data?.workspace?.name}
              onChange={(e: any) => setName(e.target.value)}
            />
          </>
        ) : (
          <>
            <Box>
              <Typography
                variant="caption"
                sx={{
                  color: palette.custom.dark,
                  fontSize: "18px",
                  fontWeight: "500",
                }}
              >
                {data?.workspace?.name}
              </Typography>
            </Box>
          </>
        )}
        <Box
          sx={{
            position: "absolute",
            right: "0",
            top: "50%",
            transform: "translateY(-50%)",

            ".edit-button": {
              border: `1px solid ${palette.primary.main}`,
              width: "24px",
              height: "24px",
              borderRadius: "100%",
              color: palette.grayAccent.contrastText,
              backgroundColor: palette.primary.main,
              transition: "all 0.3s ease",

              "@media (min-width: 992px)": {
                width: "32px",
                height: "32px",
              },

              "&:hover": {
                borderColor: palette.primary.dark,
                backgroundColor: palette.primary.dark,
                color: palette.grayAccent.contrastText,
              },
            },
          }}
        >
          {editName ? (
            <ButtonPrimary onClick={() => updateName()}>Submit</ButtonPrimary>
          ) : (
            <IconButton
              onClick={() => setEditName(true)}
              size="small"
              className="edit-button"
            >
              <EditIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      <Divider
        sx={{
          margin: "24px 0",
        }}
      />
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Box>
          <Typography
            variant="caption"
            sx={{
              color: palette.grayAccent.light,
              fontSize: "13px",
              fontWeight: "500",
              textTransform: "uppercase",
            }}
          >
            Workspace image
          </Typography>
        </Box>
        <Box
          position="relative"
          sx={{
            ".uploaded-img": {
              position: editImage ? "absolute" : "relative",
              left: editImage ? "20px" : "0",
              top: editImage ? "20px" : "0",
              zIndex: editImage ? "9" : "0",
              pointerEvents: editImage ? "none" : "auto",
            },
          }}
        >
          {file || data?.workspace?.logoUrl ? (
            <img
              className="uploaded-img"
              src={
                file
                  ? URL.createObjectURL(file as Blob)
                  : `${data?.workspace?.logoUrl}?${getRandomNumber()}` || ""
              }
              width={50}
              height={50}
            />
          ) : (
            <BrighthiveIcon
              className="logo-icon"
              sx={{
                marginTop: "10px",
                position: "relative",
                top: "8px",
                transition: "opacity 0.3s ease, visibility 0.3s ease",
              }}
            />
          )}
          {editImage ? (
            <Button
              component="label"
              variant="contained"
              sx={{
                minHeight: "1px",
                minWidth: "150px",
                alignItems: "flex-end",
                background: "white",
                color: "black",
                marginLeft: "10px",

                "&:hover": {
                  color: "white",
                },
              }}
              startIcon={<CloudUploadIcon />}
              onChange={(e: any) => {
                if (e.target.files[0].size < 50000000)
                  setFile(e.target.files[0]);
                else toast.error("File size to big");
              }}
            >
              Upload logo
              <VisuallyHiddenInput type="file" accept="image/*" />
            </Button>
          ) : (
            <Box
              sx={{
                ".ws-image": {
                  maxWidth: "100%",
                  height: "auto",
                },
              }}
            >
              <img className="ws-image" src="" alt="" />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            position: "absolute",
            right: "0",
            top: "50%",
            transform: "translateY(-50%)",

            ".edit-button": {
              border: `1px solid ${palette.primary.main}`,
              width: "24px",
              height: "24px",
              borderRadius: "100%",
              color: palette.grayAccent.contrastText,
              backgroundColor: palette.primary.main,
              transition: "all 0.3s ease",

              "@media (min-width: 992px)": {
                width: "32px",
                height: "32px",
              },

              "&:hover": {
                borderColor: palette.primary.dark,
                backgroundColor: palette.primary.dark,
                color: palette.grayAccent.contrastText,
              },
            },
          }}
        >
          {editImage ? (
            <ButtonPrimary onClick={() => updateImage()}>Submit</ButtonPrimary>
          ) : (
            <IconButton
              onClick={() => setEditImage(true)}
              size="small"
              className="edit-button"
            >
              <EditIcon />
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};
