import { Tooltip, useTheme } from "@mui/material";
import { InfoIcon } from "../../Icons/InfoIcon";

import { TypographyProps } from "../../Typography/Typography";

export interface TooltipProps {
  info?: boolean;
  placement?: string;
  infoTitle?: string;
  width?: string;
  height?: string;
  ml?: string;
}

export const TooltipHelper = ({
  info = false,
  variant = "subtitle2",
  placement = "top",
  infoTitle,
  width = ".833rem",
  height = ".833rem",
  ml = ".47rem",
  ...rest
}: TooltipProps & TypographyProps) => {
  const { palette } = useTheme();

  return (
    <>
      {info && (
        <Tooltip
          title={infoTitle || ""}
          placement="top"
          componentsProps={{
            tooltip: { sx: { backgroundColor: palette.common.black } },
            arrow: { sx: { color: palette.common.black } },
          }}
          arrow
        >
          <span>
            <InfoIcon sx={{ width, height, ml, zIndex: 100000 }} />
          </span>
        </Tooltip>
      )}
    </>
  );
};
