import React, { useState } from "react";
import { Box, Dialog, useTheme, Grid, Paper } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { Button } from "../../common/Button/Button";
import { SearchBar } from "../../common/SearchBar/SearchBar";
import { FilterByTagButton } from "../../common/ActionBarProjects/FilterByTagButton";
import { FormikValues } from "formik";
import { Chip } from "../../common/Chip/Chip";
import PersonIcon from "@mui/icons-material/Person";
import { DOCLink } from "../../common/Link/Link";
import DoneIcon from "@mui/icons-material/Done";

export const SelectJobModal = () => {
  const { palette } = useTheme();
  const [open, setOpen] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState(false);
  const categories = ["Privacy", "Use", "Access", "Quality"];
  const [categoryValues, setCategoryValues] = useState<FormikValues>({
    Privacy: false,
    Use: false,
    Access: false,
    Quality: false,
  });

  const tags = ["HIPPA", "FERRA", "PROJECTS", "ONE", "TWO"];
  const [tagValues, setTagValues] = useState<FormikValues>({
    HIPPA: false,
    FERRA: false,
    PROJECTS: false,
    ONE: false,
    TWO: false,
  });

  const toggleDialog = () => {
    setOpen(false);
  };

  return (
    <Dialog
      maxWidth="md"
      open={open}
      sx={{ " + #filter-owner-form": { zIndex: 999999 } }}
    >
      <Box width="560px" height="auto" p={6} boxSizing="border-box">
        <Typography mb={4} variant="h5" color={palette.common.black}>
          Select job
        </Typography>
        <Box mb={8} sx={{ display: "flex", justifyContent: "space-between" }}>
          <SearchBar
            sx={{ width: "100%", mr: 0 }}
            placeholder="Find a job"
            onChange={() => {}}
          />
        </Box>
        <Box sx={{ height: "300px", overflow: "auto" }}>
          <Box>
            <Grid container>
              <Grid
                item
                xs={12}
                mb={4}
                sx={{
                  minWidth: "360px",
                  ".seleceted-item": {
                    borderColor: palette.action.disabledBackground,
                    background: palette.primary.lighter,
                  },
                }}
              >
                <Paper
                  variant="outlined"
                  className={selectedTemplate ? "seleceted-item" : ""}
                  sx={{
                    p: 4,
                    minHeight: "100%",
                    boxSizing: "border-box",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    alignItems="flex-start"
                    mb={0}
                  >
                    <Box>
                      <Typography
                        variant="subtitle2"
                        color={palette.common.black}
                        mb={1}
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      >
                        Job Name
                      </Typography>
                      <Typography
                        variant="body3"
                        color={palette.gray.darkest}
                        mb={0}
                        sx={{
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        870492da-ecfb-4be0-91b9-9a89ddd3db90
                      </Typography>
                    </Box>
                    {selectedTemplate && (
                      <DoneIcon
                        sx={{ fontSize: "20px", color: palette.gray.dark }}
                      />
                    )}
                  </Box>
                </Paper>
              </Grid>
              <Grid
                item
                xs={12}
                mb={4}
                sx={{
                  minWidth: "360px",
                  ".seleceted-item": {
                    borderColor: palette.action.disabledBackground,
                    background: palette.primary.lighter,
                  },
                }}
              >
                <Paper
                  variant="outlined"
                  className={selectedTemplate ? "seleceted-item" : ""}
                  sx={{
                    p: 4,
                    minHeight: "100%",
                    boxSizing: "border-box",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    alignItems="flex-start"
                    mb={0}
                  >
                    <Box>
                      <Typography
                        variant="subtitle2"
                        color={palette.common.black}
                        mb={1}
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      >
                        Job Name
                      </Typography>
                      <Typography
                        variant="body3"
                        color={palette.gray.darkest}
                        mb={0}
                        sx={{
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        870492da-ecfb-4be0-91b9-9a89ddd3db90
                      </Typography>
                    </Box>
                    {selectedTemplate && (
                      <DoneIcon
                        sx={{ fontSize: "20px", color: palette.gray.dark }}
                      />
                    )}
                  </Box>
                </Paper>
              </Grid>
              <Grid
                item
                xs={12}
                mb={4}
                sx={{
                  minWidth: "360px",
                  ".seleceted-item": {
                    borderColor: palette.action.disabledBackground,
                    background: palette.primary.lighter,
                  },
                }}
              >
                <Paper
                  variant="outlined"
                  className={selectedTemplate ? "seleceted-item" : ""}
                  sx={{
                    p: 4,
                    minHeight: "100%",
                    boxSizing: "border-box",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    alignItems="flex-start"
                    mb={0}
                  >
                    <Box>
                      <Typography
                        variant="subtitle2"
                        color={palette.common.black}
                        mb={1}
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      >
                        Job Name
                      </Typography>
                      <Typography
                        variant="body3"
                        color={palette.gray.darkest}
                        mb={0}
                        sx={{
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        870492da-ecfb-4be0-91b9-9a89ddd3db90
                      </Typography>
                    </Box>
                    {selectedTemplate && (
                      <DoneIcon
                        sx={{ fontSize: "20px", color: palette.gray.dark }}
                      />
                    )}
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box mt={4} display="flex" alignItems={"center"}>
          <Button
            color="grayAccent"
            variant="outlined"
            onClick={toggleDialog}
            sx={{ ml: "auto", mr: 3 }}
          >
            Cancel
          </Button>
          <Button color="primary" variant="contained">
            Add job
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
