import { DashboardModal, useUppy } from "@uppy/react";
import Uppy, { UppyFile } from "@uppy/core";
import AwsS3, { AwsS3UploadParameters } from "@uppy/aws-s3";
import "@uppy/core/dist/style.css";

interface UppyModalProps {
  open: boolean;
  onClose: VoidFunction;
  getUploadParameters:
    | ((
        file: UppyFile<Record<string, unknown>, Record<string, unknown>>
      ) => Promise<AwsS3UploadParameters>)
    | undefined;
}

export default function UppyModal({
  onClose,
  open,
  getUploadParameters,
}: UppyModalProps) {
  const uppy = useUppy(() => {
    return new Uppy({
      restrictions: {
        allowedFileTypes: [".csv"],
      },
    }).use(AwsS3, {
      getUploadParameters,
    });
  });

  return (
    <DashboardModal
      uppy={uppy}
      closeModalOnClickOutside
      open={open}
      onRequestClose={onClose}
      locale={{
        strings: {
          uploading: "Processing Data Asset…Generating Metadata…",
        },
      }}
    />
  );
}
