import { Box, Button } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DataStorage } from "./DataStorage/DataStorage";
import { Engines } from "./Engines/Engines";
import { MetadataSources } from "./MetadataSources/MetadataSources";

export const WorkspaceConfiguration = () => {
  const { workspaceId } = useParams();
  const { search } = useLocation();
  const sp = new URLSearchParams(search);

  const navigate = useNavigate();
  const activeTab = sp.get("tab") || "Metadata sources";
  const tabs = ["Metadata sources", "Engines", "Storage"];

  const handleChange = (value: string) => {
    navigate(`/workspace/${workspaceId}/workspace-configuration?tab=${value}`, {
      replace: true,
    });
  };

  return (
    <Box display="grid" gridTemplateColumns="12rem 1fr" gap="1rem">
      <Box>
        {tabs.map((item) => {
          const isActive = item === activeTab;
          return (
            <Box
              sx={{
                borderLeft: (theme) =>
                  `3px solid ${
                    isActive
                      ? theme.palette.primary.main
                      : theme.palette.action.disabledBackground
                  }`,
              }}
            >
              <Button
                onClick={() => handleChange(item)}
                sx={{
                  paddingLeft: "1.2rem",
                  fontWeight: isActive ? 700 : 400,
                  justifyContent: "left",
                  color: (theme) =>
                    isActive
                      ? theme.palette.primary.main
                      : theme.palette.gray.dark,
                }}
                size="large"
                fullWidth
                role="tab"
              >
                {item}
              </Button>
            </Box>
          );
        })}
      </Box>
      <Box>
        {activeTab === "Metadata sources" && <MetadataSources />}
        {activeTab === "Engines" && <Engines />}
        {activeTab === "Storage" && <DataStorage />}
      </Box>
    </Box>
  );
};
