import { Tabs } from "../common/Tabs/Tabs";
import { Box, Tab } from "@mui/material";
import { GovernanceOverview } from "./GovernanceOverview/GovernanceOverview";
import { GovernanceCustomPolices } from "./GovernanceCustomPolicies/GovernanceCustomPolicies";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GovernancePolicies } from "./GovernancePolicies/GovernancePolicies";
import { TabButton, TabsWrapper } from "./style";

export const Governance = () => {
  const { workspaceId } = useParams();
  const { search } = useLocation();
  const sp = new URLSearchParams(search);

  const navigate = useNavigate();
  const activeTab = sp.get("tab") || "overview";
  const tabs = ["overview", "policies"];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(`/workspace/${workspaceId}/governance?tab=${tabs[newValue]}`, {
      replace: true,
    });
  };

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  return (
    <Box>
      <TabsWrapper
        value={tabs.indexOf(activeTab)}
        onChange={handleChange}
        aria-label="governance tab"
      >
        <TabButton label="Overview" {...a11yProps(0)} />
        <TabButton label="Policies" {...a11yProps(1)} />
      </TabsWrapper>

      {activeTab === "overview" && <GovernanceOverview />}
      {activeTab === "policies" && <GovernancePolicies />}
    </Box>
  );
};
