import { SharedIcon } from "../../common/Icons/SharedIcon";
import { KeyIcon } from "../../common/Icons/KeyIcon";
import { Chip } from "../../common/Chip/Chip";
import { AccessStatus } from "../../generated";
import { convertToTitleCase } from "../../helpers";
import { ChipPropsColorOverrides } from "@mui/material/Chip/Chip";
import { OverridableStringUnion } from "@mui/types";

export interface AccessStatusChipProps {
  status: AccessStatus;
}

function AccessStatusChip({ status }: any) {
  let color: OverridableStringUnion<
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning",
    ChipPropsColorOverrides
  > = "primary";
  const label = convertToTitleCase(convertToTitleCase(status));
  let icon = <SharedIcon />;
  if (label === "") {
    return <div></div>;
  } else if (status === AccessStatus.Restricted) {
    color = "warning";
    icon = <KeyIcon />;
  } else if (status === AccessStatus.Shared) {
    color = "primary";
  } else if (status === AccessStatus.Private) {
    color = "error";
    icon = <KeyIcon />;
  }
  return <Chip avatar={icon} size="small" label={label} color={color} />;
}

export default AccessStatusChip;
