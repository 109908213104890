import { styled, Box, Tab, Button, Typography } from "@mui/material";
import { brighthiveTheme } from "../../theme/theme";

export const ButtonPrimary = styled(Button)`
  background-color: ${brighthiveTheme.palette.primary.main};
  color: ${brighthiveTheme.palette.primary.darkest};
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: none;
  min-width: 1px;
  padding: 6px 16px;

  &:hover{
    background-color: ${brighthiveTheme.palette.primary.dark};
    color: ${brighthiveTheme.palette.common.white};
    border-color: transparent;
    box-shadow: none;
  }
`;

export const ButtonDefault = styled(Button)`
  color: ${brighthiveTheme.palette.gray.dark};
  border-radius: 3px;
  border: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
  min-width: 1px;
  padding: 6px 16px;

  &.small{
    width: 36px;
    height: 36px;
    padding: 0;
  }

  &.active,
  &:hover{
    border-color: ${brighthiveTheme.palette.primary.dark};
    background-color: ${brighthiveTheme.palette.primary.active};
    color: ${brighthiveTheme.palette.primary.dark};
  }
`;

export const BtnsWrap = styled(Box)`
  margin-top: 14px;
  display: flex;
  gap: 14px;
`;