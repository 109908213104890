import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const SourcesViiIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.5 16.5996V15.0996C4.5 14.7018 4.65804 14.3203 4.93934 14.0389C5.22064 13.7576 5.60218 13.5996 6 13.5996H18C18.3978 13.5996 18.7794 13.7576 19.0607 14.0389C19.342 14.3203 19.5 14.7018 19.5 15.0996V16.5996M12 9.09961V13.5996M2.25 16.5996H6.75C7.57843 16.5996 8.25 17.2712 8.25 18.0996V22.5996C8.25 23.428 7.57843 24.0996 6.75 24.0996H2.25C1.42157 24.0996 0.75 23.428 0.75 22.5996V18.0996C0.75 17.2712 1.42157 16.5996 2.25 16.5996ZM9.75 1.59961H14.25C15.0784 1.59961 15.75 2.27118 15.75 3.09961V7.59961C15.75 8.42804 15.0784 9.09961 14.25 9.09961H9.75C8.92157 9.09961 8.25 8.42804 8.25 7.59961V3.09961C8.25 2.27118 8.92157 1.59961 9.75 1.59961ZM17.25 16.5996H21.75C22.5784 16.5996 23.25 17.2712 23.25 18.0996V22.5996C23.25 23.428 22.5784 24.0996 21.75 24.0996H17.25C16.4216 24.0996 15.75 23.428 15.75 22.5996V18.0996C15.75 17.2712 16.4216 16.5996 17.25 16.5996Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
    </SvgIcon>
  );
};
