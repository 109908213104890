import React from "react";
import { Box, Dialog, DialogProps, useTheme } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { Button } from "../../common/Button/Button";

interface SpecificContactManagerDialogProps {
  onClose: () => void;
}

type ContactManagerDialogProps = DialogProps &
  SpecificContactManagerDialogProps;

export const ContactManagerDialog = (props: ContactManagerDialogProps) => {
  const { palette } = useTheme();
  const onClickOkay = () => {
    if (props.onClose) props.onClose();
  };

  return (
    <Dialog {...props}>
      <Box width="35rem" p={6} boxSizing="border-box">
        <Typography mb={4} variant="h5">
          Add data asset to project
        </Typography>
        <Typography color={palette.gray.dark} mb={8} variant="body2">
          This data asset is restricted and can only be added to projects by the
          asset manager.
        </Typography>
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={onClickOkay} color="primary" variant="contained">
            Okay
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
