import React, { useRef } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
  useTheme,
  Link,
  AutocompleteRenderInputParams,
} from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { Button } from "../../common/Button/Button";
import { IconCell } from "../CellRenderer/IconCell";
import { Formik, Field, Form } from "formik";
import { Autocomplete, TextField as TextFieldFormik } from "formik-mui";

interface SpecificAddDataAssetDialogProps {
  onSubmit: () => void;
  onClose: () => void;
}

type AddNewConnectionDialogProps = DialogProps &
  SpecificAddDataAssetDialogProps;

export const AddNewConnectionDialog = (props: AddNewConnectionDialogProps) => {
  const onClickCancel = () => {
    if (props.onClose) props.onClose();
  };
  const { palette } = useTheme();

  return (
    <Dialog {...props} maxWidth="sm" fullWidth>
      <Formik
        initialValues={{
          source: null,
        }}
        onSubmit={() => {}}
      >
        {({ values, submitForm }) => {
          const isDisabled = !values.source;
          return (
            <>
              <DialogTitle>
                <Typography mb={4} variant="h5">
                  Set up the source
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Form>
                  <Box boxSizing="border-box">
                    <Box>
                      <Field
                        name="source"
                        component={Autocomplete}
                        options={["S3"]}
                        size="small"
                        renderOption={(p: any, option: string) => {
                          return (
                            <li {...p}>
                              <Box display="flex" alignItems="center" gap={2}>
                                <IconCell value="BigQuery" />
                                {option}
                              </Box>
                            </li>
                          );
                        }}
                        placeholder="Type to search for connector"
                        renderInput={(
                          params: AutocompleteRenderInputParams
                        ) => (
                          <Box>
                            <Typography
                              fontWeight="bold"
                              gutterBottom
                              variant="body3"
                            >
                              Source Type
                            </Typography>
                            <TextField {...params} />
                          </Box>
                        )}
                      />
                    </Box>

                    <Box mt={2}>
                      <Link
                        href="/"
                        sx={{
                          textDecoration: "underline !important",
                          fontWeight: "bold",
                          color: `${palette.link.main} !important`,
                        }}
                      >
                        Setup Guide
                      </Link>
                    </Box>

                    {values.source && (
                      <Box mt={4}>
                        <Box
                          px={4}
                          py={3}
                          bgcolor={palette.warning.lighter}
                          borderRadius={1}
                        >
                          <Typography variant="body3">
                            <Box component="span" fontWeight="bold">
                              Beta
                            </Box>{" "}
                            connectors are in development but stable and
                            reliable and support is provided. See our
                            <Link
                              href="/"
                              sx={{
                                mx: 1,
                                textDecoration: "underline !important",
                                fontWeight: "bold",
                                color: `${palette.link.main} !important`,
                              }}
                            >
                              documentation
                            </Link>
                            for more details.
                          </Typography>
                        </Box>
                        <Box mt={6}>
                          <label htmlFor="name">
                            <Typography gutterBottom variant="body3">
                              <Box component="span" fontWeight="bold">
                                Name
                              </Box>{" "}
                              * - Pick a name to help you identify this source
                              in Airbyte
                            </Typography>
                          </label>
                          <Field
                            id="name"
                            name="name"
                            component={TextFieldFormik}
                            size="small"
                            fullWidth
                          />
                        </Box>
                        <Box
                          mt={6}
                          p={6}
                          borderRadius={2}
                          border={`1px solid ${palette.action.disabledBackground}`}
                        >
                          <Box maxWidth={300}>
                            <Field
                              name="format"
                              component={Autocomplete}
                              options={["CSV"]}
                              size="small"
                              renderInput={(
                                params: AutocompleteRenderInputParams
                              ) => (
                                <Box>
                                  <Typography
                                    fontWeight="bold"
                                    gutterBottom
                                    variant="body3"
                                  >
                                    Format:
                                  </Typography>
                                  <TextField {...params} />
                                </Box>
                              )}
                            />
                          </Box>

                          <Box mt={6}>
                            <label htmlFor="encoding">
                              <Typography gutterBottom variant="body3">
                                <Box component="span" fontWeight="bold">
                                  Encoding
                                </Box>{" "}
                                - The character encoding of the CSV data. Leave
                                blank to default to UTF-8. See
                                <Link
                                  href="https://documentation.help/Python-2.5/standard-encodings.html"
                                  sx={{
                                    mx: 1,
                                    textDecoration: "underline !important",
                                    fontWeight: "bold",
                                    color: `${palette.link.main} !important`,
                                  }}
                                >
                                  list of python encodings
                                </Link>
                                for allowable options.
                              </Typography>
                            </label>
                            <Field
                              id="encoding"
                              name="encoding"
                              component={TextFieldFormik}
                              size="small"
                              fullWidth
                            />
                          </Box>

                          <Box mt={6}>
                            <label htmlFor="delimiter">
                              <Typography gutterBottom variant="body3">
                                <Box component="span" fontWeight="bold">
                                  Delimiter
                                </Box>{" "}
                                - The character delimiting individual cells in
                                the CSV data. This may only be a 1-character
                                string. For tab-delimited data enter '\t'.
                              </Typography>
                            </label>
                            <Field
                              id="delimiter"
                              name="delimiter"
                              component={TextFieldFormik}
                              size="small"
                              fullWidth
                            />
                          </Box>

                          <Box mt={6}>
                            <label htmlFor="blocksize">
                              <Typography gutterBottom variant="body3">
                                <Box component="span" fontWeight="bold">
                                  Block Size
                                </Box>{" "}
                                The chunk size in bytes to process at a time in
                                memory from each file. If your data is
                                particularly wide and failing during schema
                                detection, increasing this should solve it.
                                Beware of raising this too high as you could hit
                                OOM errors.
                              </Typography>
                            </label>
                            <Field
                              id="blocksize"
                              name="blocksize"
                              component={TextFieldFormik}
                              size="small"
                              fullWidth
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Form>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={onClickCancel}
                  color="grayAccent"
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  disabled={isDisabled}
                  onClick={submitForm}
                  color="primary"
                  variant="contained"
                >
                  Setup Source
                </Button>
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};
