import { styled, Box, Button, Typography, Popover } from "@mui/material";
import { brighthiveTheme } from "../../theme/theme";

export const ButtonDefault = styled(Button)`
  color: ${brighthiveTheme.palette.gray.dark};
  border-radius: 3px;
  border: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 1px;

  &.small {
    width: 36px;
    height: 36px;
    padding: 0;
  }

  &.icn-large {
    svg {
      width: 26px;
      height: 26px;
    }
  }

  &.active,
  &:hover {
    border-color: ${brighthiveTheme.palette.primary.dark};
    background-color: ${brighthiveTheme.palette.primary.active};
    color: ${brighthiveTheme.palette.primary.dark};
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const ButtonLink = styled(Button)`
  color: ${brighthiveTheme.palette.accent.darkest};
  background-color: transparent;

  &:hover {
    color: ${brighthiveTheme.palette.accent.darkest};
    background-color: transparent;
    text-decoration: underline;
  }
`;

export const ButtonPrimary = styled(Button)`
  background-color: ${brighthiveTheme.palette.primary.main};
  color: ${brighthiveTheme.palette.primary.darkest};
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: none;
  min-width: 1px;

  &:hover {
    background-color: ${brighthiveTheme.palette.primary.dark};
    color: ${brighthiveTheme.palette.common.white};
    border-color: transparent;
    box-shadow: none;
  }
`;
