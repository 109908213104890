import React, { ReactNode } from "react";
import { BasicPageNav } from "../common/BasicPage/BasicPageNav";
import { useWorkspaceId } from "../hooks/useWorkspace";

export const ProtectedNav = ({ children }: { children: ReactNode }) => {
  const { workspaceId } = useWorkspaceId();

  const token = localStorage.getItem("token");

  if (
    (process.env.REACT_APP_MOCKS_ENABLED === "true" ||
      (typeof token === "string" && token.length > 0)) &&
      workspaceId
  ) {
    return (
      <>
        <BasicPageNav content={children} />
      </>
    );
  }

  return <>{children}</>;
};
