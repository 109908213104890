/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Box, Grid } from "@mui/material";

import { Typography } from "../common/Typography/Typography";
import { BasicCard } from "../common/BasicCard/BasicCard";
import { Tag } from "../common/Tag/Tag";
import { useLocation } from "react-router-dom";
import { Policy } from "./types";
import { convertDateTime } from "../helpers";
import CustomPolicyCard from "./GovernanceCustomPolicies/CustomPolicyCard";
import MonitoredPolicyCard from "./GovernanceMonitoredPolicies/MonitoredPolicyCard";
import { DOCLink } from "../common/Link/Link";
import { useEffect, useState } from "react";

export const GovernancePolicyDetail = () => {
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const tab = sp.get("tab");
  const [policy, setPolicy] = useState<Policy>();

  useEffect(() => {
    const data = localStorage.getItem("DETAIL");
    if (data) {
      setPolicy(JSON.parse(data));
    } else {
      window.close();
    }
  }, []);

  const tags = policy?.tags || [];
  const referenceDocuments = policy?.referenceDocuments;

  return (
    <>
      <Grid container spacing={8}>
        <Grid item xs>
          <BasicCard
            sx={{
              padding: "1.5rem",
            }}
            display={"flex"}
            justifyContent="space-between"
          >
            <Box sx={{ maxWidth: "43.75rem" }}>
              <Typography variant="subtitle1" sx={{ mb: 2 }}>
                {policy?.title}
              </Typography>
              <Typography variant="caption" color={"grayAccent"}>
                {policy?.type}
              </Typography>
              <Typography variant="caption" color={"grayAccent"} sx={{ mx: 2 }}>
                {"|"}
              </Typography>
              <Typography variant="caption" color={"grayAccent"}>
                Added {convertDateTime(policy?.dateTime)} by {policy?.author}
              </Typography>

              <Typography variant="body2" color={"grayAccent"} mt={4}>
                {policy?.description}
              </Typography>
              {tags?.length > 0 && (
                <Box>
                  <Typography variant="subtitle3" sx={{ mt: 4, mb: 2 }}>
                    Applied to
                  </Typography>
                  <Box display={"flex"}>
                    {tags.map((tag: string) => (
                      <Tag
                        color="primary"
                        label={tag}
                        dismissable={false}
                        mr={1}
                      ></Tag>
                    ))}
                  </Box>
                </Box>
              )}
              {referenceDocuments && (
                <Box>
                  <Typography variant="subtitle3" sx={{ mt: 4, mb: 2 }}>
                    Reference documents
                  </Typography>
                  <Box>
                    <ul
                      css={css`
                        paddingleft: "1.5rem";
                        margin: 0;
                      `}
                    >
                      {referenceDocuments.map((doc: any) => (
                        <li>
                          <DOCLink
                            sx={{ mb: 2 }}
                            name={doc.name}
                            href={doc.href}
                          />
                        </li>
                      ))}
                    </ul>
                  </Box>
                </Box>
              )}
            </Box>
            <Box></Box>
          </BasicCard>
        </Grid>
        <Grid item sx={{ width: "27rem" }}>
          {tab === "custom" ? <CustomPolicyCard /> : <MonitoredPolicyCard />}
        </Grid>
      </Grid>
    </>
  );
};
