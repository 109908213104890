import React from "react";

type MUIButtonOnClickHandler = (
  event: React.MouseEvent<HTMLButtonElement>
) => void;

export const useMenu = (): [
  boolean,
  HTMLElement | null,
  MUIButtonOnClickHandler,
  () => void
] => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const openMenuFromAnchorElement = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };
  return [menuOpen, anchorEl, openMenuFromAnchorElement, closeMenu];
};
