import React from "react";
import { Box, CircularProgress, useTheme } from "@mui/material";
import { Typography } from "../Typography/Typography";

export interface LoadingOverlayProps {}

export const LoadingOverlay = () => {
  const { palette } = useTheme();

  return (
    <Box
      color={palette.gray.dark}
      display={"flex"}
      flexDirection="column"
      alignItems={"center"}
    >
      <CircularProgress
        size={"4.125rem"}
        sx={{ color: palette.primary.darkest }}
      />
      <Typography mt={"1.6rem"} variant="body1">
        Loading...
      </Typography>
    </Box>
  );
};
