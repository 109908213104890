import { styled, Box, IconButton, Typography, Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { SquareIcon } from "../SquareIcon/SquareIcon";
import { MenuItem } from "../Menu/MenuItem";
import { Menu } from "../Menu/Menu";
import { brighthiveTheme } from "../../theme/theme";

export const MenuDropdown = styled(Menu)``;

export const MenuDropdownItem = styled(MenuItem)`
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: ${brighthiveTheme.palette.primary.active};
  }

  > a.MuiLink-root.unstyled-link,
  .MuiTypography-root {
    display: flex;
    align-items: center;
    color: ${brighthiveTheme.palette.accent.contrastText};
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;

    svg {
      margin: 0 5px 0 0;
    }
  }

  &,
  [role="presentation"] & {
    &:hover {
      > a.MuiLink-root.unstyled-link,
      .MuiTypography-root {
        color: ${brighthiveTheme.palette.primary.dark} !important;
      }
    }
  }
`;

export const BreadcrumbsWrapper = styled("div")`
  display: flex;
  padding-top: 4px;
  padding-bottom: 4px;
  max-width: 60%;
  overflow: hidden;
  gap: 4px;

  .breadcrumbs-item + .breadcrumbs-item .arrow {
    display: inline-block;
  }
`;

export const BackButton = styled(IconButton)`
  background-color: ${brighthiveTheme.palette.customRolled.light};
  border-radius: 6px;
  width: 24px;
  height: 24px;
  padding: 0;
  transition: color 0.3s ease, background-color 0.3s ease;
  margin-right: 12px;

  &:hover {
    background-color: ${brighthiveTheme.palette.primary.active};
    color: ${brighthiveTheme.palette.primary.dark};
  }

  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
  }
`;

export const BackIcon = styled(ArrowBack)`
  @media (max-width: 991px) {
    width: 0.875rem;
    height: 0.875rem;
  }
`;

export const BreadcrumbItem = styled(Box)`
  display: flex;
  align-items: center;
  gap: 6px;

  .arrow {
    display: none;
    vertical-align: middle;
    color: ${brighthiveTheme.palette.gray.dark};
    width: 14px;
    height: 14px;

    @media (min-width: 992px) {
      width: 18px;
      height: 18px;
    }
  }

  &.link {
    color: ${brighthiveTheme.palette.gray.dark};
    font-weight: 700;

    &,
    &:hover {
      textd-decoration: underline;
      cursor: pointer;
    }

    &:last-child {
      color: ${brighthiveTheme.palette.common.black};
    }

    p {
      color: ${brighthiveTheme.palette.gray.dark};
      font-weight: 700;
    }
  }
`;

export const ItemIcon = styled(SquareIcon)`
  display: none;
  width: 20px;
  height: 20px;
  background-color: ${brighthiveTheme.palette.primary.active};
  color: ${brighthiveTheme.palette.gray.dark};

  @media (min-width: 1200px) {
    display: flex;
  }

  .MuiSvgIcon-root {
    width: 12px;
    height: 12px;
  }

  .breadcrumbs-item + .breadcrumbs-item & {
    color: ${brighthiveTheme.palette.primary.darkest};
  }
`;

export const ItemText = styled(Typography)`
  font-size: 0.6875rem;
  line-height: 0.8125rem;
  transition: color 0.3s ease;

  @media (min-width: 1200px) {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .link:hover & {
    color: ${brighthiveTheme.palette.common.black};
  }
`;

export const ButtonDropIcon = styled(IconButton)`
  color: ${brighthiveTheme.palette.primary.dark};
  border: 1px solid ${brighthiveTheme.palette.primary.dark};
  background-color: ${brighthiveTheme.palette.primary.active};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1px;
  transition: color 0.3s ease, border-color 0.3s ease,
    background-color 0.3s ease;

  &.small {
    width: 21px;
    height: 21px;
    padding: 0;
    font-size: 11px;

    @media screen and (min-width: 992px) {
      width: 34px;
      height: 34px;
      font-size: 18px;
    }
  }

  &.icn-large {
    svg {
      width: 26px;
      height: 26px;
    }
  }

  &.active,
  &:hover {
    border-color: ${brighthiveTheme.palette.primary.dark};
    background-color: ${brighthiveTheme.palette.primary.dark};
    color: ${brighthiveTheme.palette.common.white};
  }

  svg {
    width: 11px;
    height: 11px;

    @media screen and (min-width: 992px) {
      width: 18px;
      height: 18px;
    }
  }
`;

export const ActionBtnsWrapper = styled(Box)`
  display: flex;
  gap: 4px;

  @media screen and (min-width: 992px) {
    gap: 12px;
  }
`;

export const NavbarWrapper = styled(Box)`
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonDropWrapper = styled(Box)``;

export const NavbarMenuWrapper = styled(Box)`
  cursor: pointer;
  align-items: center;
  display: flex;
  z-index: 0;
  gap: 8px;

  &:focus-visible {
    outline: ${brighthiveTheme.palette.primary.main} solid 0.1875rem;
  }
`;

export const ProfileName = styled(Typography)`
  display: block;
  font-size: 0.6rem;

  @media (min-width: 992px) {
    font-size: 0.75rem;
  }

  @media (min-width: 1200px) {
    font-size: 0.875rem;
  }
`;

export const ButtonPrimary = styled(Button)`
  background-color: ${brighthiveTheme.palette.primary.main};
  color: ${brighthiveTheme.palette.primary.darkest};
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: none;
  min-width: 1px;
  gap: 10px;

  &:hover {
    background-color: ${brighthiveTheme.palette.primary.dark};
    color: ${brighthiveTheme.palette.common.white};
    border-color: transparent;
    box-shadow: none;
  }
`;

export const NavHeading = styled(Box)`
  color: ${brighthiveTheme.palette.gray.dark};
  padding: 10px 16px 0;
`;
