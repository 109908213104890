import React from "react";
import { LeftRightContent } from "../../common/LeftRightContent/LeftRightContent";
import { SearchBar } from "../../common/SearchBar/SearchBar";
import { alpha, Box, useTheme, Link } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { Button } from "../../common/Button/Button";
import { ButtonPrimary, TextLink } from "./style";

export interface ActionBarUsersProps {
  onChangeSearch?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  searchText?: string;
  noResultsFoundFromFilter: boolean;
}

export const ActionBarUsers = ({
  onChangeSearch,
  searchText,
  noResultsFoundFromFilter,
}: ActionBarUsersProps) => {
  const { palette } = useTheme();

  const border = `.0625rem  ${palette.outline} solid`;

  return (
    <Box
      sx={{
        flexGrow: 0,
        borderLeft: border,
        borderRight: border,
        borderTop: border,
        borderRadius: ".25rem .25rem 0 0",
      }}
    >
      <LeftRightContent
        sx={{ pb: 2, pt: 2, pr: 4, pl: 4 }}
        // leftElements={<TextLink>View workspace role definitions</TextLink>}
        leftElements={<></>}
        rightElements={
          <>
            <SearchBar
              sx={{ width: "18rem", mr: 2 }}
              placeholder="Search name, email, or organization"
              onChange={onChangeSearch}
              value={searchText}
            />
            <ButtonPrimary variant="contained">Reset filters</ButtonPrimary>
          </>
        }
      />
      {searchText && searchText.length > 0 && (
        <Box
          borderTop={border}
          pt={1}
          pb={1}
          pl={4}
          pr={4}
          bgcolor={alpha(palette.primary.main, palette.action.hoverOpacity)}
        >
          <Typography variant="body3">
            {noResultsFoundFromFilter
              ? `0 results for "${searchText}"`
              : `Results for ${searchText}`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
