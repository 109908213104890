import { useState } from "react";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  UpdateTargetSchemaDocument,
  GetTargetSchemasDocument,
  TargetSchema,
} from "../../../generated";

type Props = {
  id: string;
  targetSchema?: TargetSchema;
  removeField: () => void;
};

const useRemoveFieldRenderer = ({ id, targetSchema, removeField }: Props) => {
  const [mutateUpdate] = useMutation(UpdateTargetSchemaDocument, {
    refetchQueries: [GetTargetSchemasDocument],
  });
  const { projectId, workspaceId, schemaId } = useParams();

  const [dialogOpen, setDialogOpen] = useState(false);
  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };
  const onClickRemoveField = async () => {
    const parse = JSON.parse(targetSchema?.jsonSchema || "{}");
    const properties = parse?.properties || {};
    delete properties[id];
    parse.properties = properties;

    await mutateUpdate({
      variables: {
        input: {
          targetSchemaId: schemaId || "",
          workspaceId: workspaceId || "",
          projectId: projectId || "",
          name: targetSchema?.name,
          description: targetSchema?.description,
          jsonSchema: JSON.stringify(parse),
        },
      },
    });
    if (removeField) removeField();
    closeDialog();
  };

  return {
    openDialog,
    dialogOpen,
    closeDialog,
    onClickRemoveField,
  };
};

export default useRemoveFieldRenderer;
