import { useState } from "react";
import { Box, Button, Tab, Typography, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import { Tabs } from "../Tabs/Tabs";
import { AddDataAsset } from "./AddDataAsset";
import { UpdateDataAsset } from "./UpdateDataAsset";
import { CloseIcon } from "../Icons/CloseIcon";
import { Label } from "../Label/Label";
import { TabButton, TabsWrapper } from "./style";

export const AddDataAssetTab = ({
  uploadOpen,
  setUploadOpen,
  onClose,
}: {
  uploadOpen: boolean;
  setUploadOpen: Function;
  onClose: any;
}) => {
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const tabParam = sp.get("tab");
  const { palette } = useTheme();

  const [tab, setTab] = useState(tabParam === "activity" ? 1 : 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          padding: "32px 50px 16px 24px",
          position: "relative",
        }}
      >
        <Typography
          variant="caption"
          sx={{
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "22px",
            display: "block",

            "@media (min-width: 768px)": {
              fontSize: "24px",
              lineHeight: "28px",
            },
          }}
        >
          Upload Data
        </Typography>
        <Button
          onClick={onClose}
          sx={{
            minWidth: "1px",
            width: "44px",
            height: "44px",
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: theme.palette.gray.dark,
            marginTop: "8px",

            "&:hover": {
              backgroundColor: "transparent",
              color: theme.palette.common.black,
            },
          }}
        >
          <CloseIcon />
        </Button>
      </Box>
      <Box
        sx={{
          padding: "0 24px",
        }}
      >
        <TabsWrapper
          value={tab}
          onChange={handleChange}
          aria-label="asset detail tab"
          sx={{
            position: "relative",

            "&:before": {
              content: '""',
              backgroundColor: palette.gray.main,
              position: "absolute",
              left: "0",
              right: "0",
              bottom: "0",
              height: "1px",
            },
          }}
        >
          <TabButton
            label="New Asset"
            {...a11yProps(0)}
            sx={{
              "@media (max-width: 767px)": {
                paddingLeft: "12px !important",
                paddingRight: "12px !important",
                fontSize: "13px !important",
              },
            }}
          />
          <Tab
            label="Update Asset"
            {...a11yProps(1)}
            sx={{
              "@media (max-width: 767px)": {
                paddingLeft: "12px !important",
                paddingRight: "12px !important",
                fontSize: "13px !important",
              },
            }}
          />
        </TabsWrapper>
      </Box>
      <Box
        sx={{
          padding: "24px",
          position: "relative",

          ".uppy-Dashboard-close, .uppy-Dashboard-poweredBy, .uppy-Dashboard-dropFilesHereHint, .uppy-DashboardTab-name, .uppy-Dashboard-AddFiles-title .uppy-Dashboard-browse, .uppy-Dashboard-Item-previewIconWrap, .uppy-DashboardContent-bar .uppy-DashboardContent-title":
            {
              display: "none",
            },

          ".uppy-Dashboard-inner": {
            "&:before": {
              display: "block",
              content: "'Upload File'",
              fontSize: "14px",
              fontWeight: "500",
              marginBottom: "12px",
            },

            "&:after": {
              display: "block",
              content: "'Powered by Uppy'",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "12px",
              textAlign: "center",
              color: theme.palette.grayAccent.light,
            },
          },

          ".uppy-Dashboard-innerWrap": {
            width: "100%",
            minHeight: "240px",
            borderRadius: "8px",
            border: `1px dashed ${theme.palette.action.disabledBackground}`,
            padding: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            position: "relative",
            textAlign: "center",
            transform: "translateX(0px)",

            "@media (min-width: 768px)": {
              padding: "32px",
            },
          },

          ".uppy-Dashboard-AddFiles": {
            order: "-1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          },

          ".uppy-Dashboard-AddFiles-list, .uppy-Dashboard-files": {
            order: "-1",
          },

          ".uppy-ProviderIconBg": {
            fill: "transparent",
          },

          ".uppy-u-reset.uppy-c-btn.uppy-DashboardTab-btn": {
            marginBottom: "12px",
            width: "48px",
            height: "48px",
            backgroundColor: theme.palette.primary.lighter,
            borderRadius: "100%",

            path: {
              fill: theme.palette.primary.main,
            },
          },

          ".uppy-Dashboard-AddFiles-title": {
            fontSize: "0",

            "&:before": {
              color: theme.palette.common.black,
              fontSize: "16px",
              fontWeight: "500",
              content: "'Drop file here'",
              display: "block",
            },

            "&:after": {
              color: theme.palette.grayAccent.light,
              fontSize: "14px",
              fontWeight: "400",
              content: "'or, click to browse (.csv)'",
              display: "block",
              marginTop: "8px",
            },
          },

          ".uppy-Dashboard-progressindicators": {
            fontSize: "12px",
            fontWeight: "500",
            width: "100%",

            ".uppy-StatusBar-actions": {
              marginTop: "12px",
            },
          },

          ".uppy-Dashboard-files": {
            width: "100%",
            marginBottom: "20px",
          },

          ".uppy-Dashboard-Item": {
            borderRadius: "4px",
            backgroundColor: theme.palette.primary.lighter,
            marginBottom: "12px",
            padding: "12px 50px 12px 12px",
            position: "relative",
            textAlign: "left",
            wordBreak: "break-word",

            ".uppy-Dashboard-Item-action, .uppy-Dashboard-Item-progressIcon--circle, .uppy-Dashboard-Item-progress .uppy-u-reset.uppy-Dashboard-Item-progressIndicator":
              {
                position: "absolute",
                right: "12px",
                top: "50%",
                transform: "translateY(-50%)",
                lineHeight: "0",
              },

            ".uppy-Dashboard-Item-name": {
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: "500",
              marginBottom: "8px",
            },

            ".uppy-Dashboard-Item-statusSize": {
              fontSize: "12px",
              fontWeight: "400",
              color: theme.palette.grayAccent.light,
            },
          },

          ".uppy-DashboardContent-bar": {
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "12px",

            ".uppy-DashboardContent-back, .uppy-DashboardContent-addMore": {
              fontWeight: "500",
              fontSize: ".875rem",
              lineHeight: "1.25rem",
              letterSpacing: "0.02em",
              display: "inline-block",
              verticalAlign: "top",
              padding: ".5rem .75rem",
              borderRadius: "4px",
              border: `1px solid ${palette.action.disabledBackground}`,
              color: palette.gray.dark,
              backgroundColor: "transparent",
              cursor: "pointer",
              transition:
                "color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease",

              "@media (max-width: 575px)": {
                width: "100%",
              },

              "&:hover": {
                borderColor: palette.primary.dark,
                color: palette.primary.dark,
                backgroundColor: palette.primary.active,
              },

              ".uppy-c-icon": {
                display: "none",
              },
            },

            ".uppy-DashboardContent-addMore": {
              color: theme.palette.primary.darkest,
              backgroundColor: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,

              "&:hover": {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.dark,
                borderColor: theme.palette.primary.dark,
              },
            },
          },

          ".uppy-Dashboard-AddFilesPanel": {
            width: "100%",
            marginTop: "12px",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          },

          ".uppy-StatusBar-actionBtn--upload, .uppy-StatusBar-actions .uppy-StatusBar-actionBtn.uppy-StatusBar-actionBtn--done":
            {
              color: theme.palette.primary.darkest,
              backgroundColor: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              height: "auto !important",
              transition: "all 0.3s ease",
              fontWeight: "500",
              fontSize: ".875rem",
              lineHeight: "1.25rem",
              letterSpacing: "0.02em",
              padding: ".5rem .75rem",
              borderRadius: "4px",

              "&:hover": {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.dark,
                borderColor: theme.palette.primary.dark,
              },
            },

          ".uppy-Dashboard-progressindicators .uppy-StatusBar-progress": {
            marginTop: "12px",
            height: "8px",
            borderRadius: "99px",
            backgroundColor: theme.palette.primary.light,
          },

          ".uppy-StatusBar-actionBtn--retry": {
            fontWeight: "500",
            fontSize: ".875rem",
            lineHeight: "1.25rem",
            letterSpacing: "0.02em",
            display: "inline-block",
            verticalAlign: "top",
            padding: ".5rem .75rem",
            borderRadius: "4px",
            border: `1px solid ${theme.palette.action.disabledBackground}`,
            color: theme.palette.grayAccent.main,
            backgroundColor: "transparent",
            cursor: "pointer",
            transition:
              "color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease",

            "@media (max-width: 575px)": {
              width: "100%",
            },

            "&:hover": {
              color: theme.palette.grayAccent.dark,
              backgroundColor: theme.palette.settings.lighter,
            },

            ".uppy-c-icon": {
              display: "none",
            },
          },

          ".uppy-Dashboard-Item-progressIcon--circle": {
            display: "none",
          },

          ".is-complete .uppy-Dashboard-Item-progressIcon--circle": {
            width: "24px",
            height: "24px",
            color: theme.palette.common.white,
            display: "inline",

            circle: {
              fill: theme.palette.primary.main,
            },
          },

          ".uppy-StatusBar-status .uppy-StatusBar-statusPrimary": {
            marginTop: "12px",
            color: theme.palette.primary.main,

            ".uppy-c-icon": {
              marginRight: "8px",
            },
          },

          ".uppy-Dashboard-Item .uppy-Dashboard-Item-progress .uppy-u-reset.uppy-Dashboard-Item-progressIndicator":
            {
              right: "36px",
              width: "18px",
              height: "18px",
            },

          ".uppy-StatusBar-content[title='Upload failed']": {
            marginTop: "24px",

            ".uppy-StatusBar-statusSecondary": {
              marginTop: "8px",
              color: theme.palette.grayAccent.light,
            },
          },

          ".uppy-StatusBar.is-error": {
            ".uppy-StatusBar-actionBtn--retry": {
              height: "auto",
            },

            ".uppy-StatusBar-actions": {
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              alignItems: "center",
            },
          },

          button: {
            cursor: "pointer",
          },

          ".uploader-tooltip": {
            position: "absolute",
            left: "100px",
            top: "23px",
            zIndex: 99,
          },
        }}
      >
        {tab === 0 && (
          <>
            <Label
              value="Please note that every file that you upload will create a separate data asset"
              className="uploader-tooltip"
              sx={{
                fontSize: "0",
                lineHeight: "0",
                color: theme.palette.common.black,
                m: 0,
                svg: {
                  m: 0,
                },
              }}
              info
            />
            <AddDataAsset
              uploadOpen={uploadOpen}
              setUploadOpen={setUploadOpen}
            />
          </>
        )}
        {tab === 1 && (
          <Box
            sx={{
              ".uppy-Container + .MuiAutocomplete-root": {
                marginTop: "24px",
              },
            }}
          >
            <UpdateDataAsset
              uploadOpen={uploadOpen}
              setUploadOpen={setUploadOpen}
            />
          </Box>
        )}
      </Box>
    </>
  );
};
