import React from "react";
import { ThemeProvider as Emotion10ThemeProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material/styles";
import { brighthiveTheme } from "./theme/theme";

export const BrighthiveThemeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Emotion10ThemeProvider theme={brighthiveTheme}>
      <ThemeProvider theme={brighthiveTheme}>{children}</ThemeProvider>
    </Emotion10ThemeProvider>
  );
};
