import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { BrighthiveThemeProvider } from "./BrighthiveThemeProvider";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { MockedProvider } from "@apollo/client/testing";
import "./index.css";
import { mocks } from "./mocks";
import { AppApolloProvider } from "./AppApolloProvider";
import "react-toastify/dist/ReactToastify.css";
import { DataProvider } from "./hooks/dataContext";
import Confirmation from "./contexts/confirmation";

const ApolloProvider =
  process.env.REACT_APP_MOCKS_ENABLED === "true"
    ? MockedProvider
    : AppApolloProvider;
const apolloProviderProps = {
  ...(process.env.REACT_APP_MOCKS_ENABLED && { mocks }),
};

ReactDOM.render(
  <React.StrictMode>
    <BrighthiveThemeProvider>
      <Confirmation.ContextProvider>
        <BrowserRouter>
          <ApolloProvider {...apolloProviderProps}>
            <DataProvider>
              <App />
            </DataProvider>
          </ApolloProvider>
        </BrowserRouter>
      </Confirmation.ContextProvider>
    </BrighthiveThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
