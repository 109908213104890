import * as React from "react";

type Action = { type: "user" | "members"; payload: any };
type Dispatch = (action: Action) => void;
type State = { user: any; members: any };
type DataProviderProps = { children: React.ReactNode };

const DataStateContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function userReducer(state: State, action: Action) {
  switch (action.type) {
    case "user": {
      return { user: action.payload, members: state.members };
    }
    case "members": {
      return { members: action.payload, user: state.user };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function DataProvider({ children }: DataProviderProps) {
  const [state, dispatch] = React.useReducer(userReducer, {
    user: null,
    members: [],
  });
  const value = { state, dispatch };
  return (
    <DataStateContext.Provider value={value}>
      {children}
    </DataStateContext.Provider>
  );
}

function useData() {
  const context = React.useContext(DataStateContext);
  if (context === undefined) {
    throw new Error("useData must be used within a DataProvider");
  }
  return context;
}

export { DataProvider, useData };
