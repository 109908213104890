import { Typography } from "@mui/material";
import React from "react";
import { BasicCard } from "../../common/BasicCard/BasicCard";

export default function MonitoredPolicyCard() {
  return (
    <BasicCard sx={{ padding: "1.5rem" }}>
      <Typography variant="h6" sx={{ mb: 4 }}>
        Monitored policies
      </Typography>
      <Typography variant="body3">
        Brighthive’s curated set of monitored policies helps collaborators
        validate that usage of shared data assets conforms to organizational
        agreements.
      </Typography>
    </BasicCard>
  );
}
