import React from "react";
import { Column, GridApi } from "ag-grid-enterprise";
import { Typography } from "../Typography/Typography";
import {
  Box,
  FormControl,
  Popover,
  PopoverProps,
  SelectChangeEvent,
  useTheme,
} from "@mui/material";
import { MenuItem } from "../Menu/MenuItem";
import { Select } from "../Form/Select";
import { FormLabel } from "../Form/FormLabel";
import { CloseIcon } from "../Icons/CloseIcon";
import { CircleIconButton } from "../CircleIconButton/CircleIconButton";
import {
  FilterOperator,
  TextFilterOperator,
  textOperatorMapToReadableNames,
} from "./gridHelpers";
import { ListItemText } from "../Menu/ListItemText";
import { useBooleanFilterWithGrid } from "./useBooleanFilterWithGrid";

interface SpecificBooleanFilterProps {
  displayName: string;
  description?: string;
  column: Column;
  gridApi: GridApi;
}

export type BooleanPopoverFilterProps = SpecificBooleanFilterProps &
  PopoverProps;

export const BooleanPopoverFilter = ({
  gridApi,
  column,
  displayName,
  description,
  ...rest
}: BooleanPopoverFilterProps) => {
  const { palette } = useTheme();

  const { filterText, onChangeFilterValue, onClickRefreshFilter } =
    useBooleanFilterWithGrid(column, gridApi);

  const onChange = (e: SelectChangeEvent<unknown>) => {
    if (e.target.value === "any" || typeof e.target.value !== "string") {
      onClickRefreshFilter();
    } else {
      onChangeFilterValue(e.target.value);
    }
  };

  return (
    <Popover {...rest}>
      <Box sx={{ pt: 4, pb: 6, pl: 6, pr: 6 }}>
        <Typography variant="h6">{displayName}</Typography>
        {description && (
          <Typography pt={2} variant="body3" color={palette.gray.dark}>
            {description}
          </Typography>
        )}
        <Box sx={{ mt: 4 }}>
          <FormControl disabled sx={{ mr: 3 }}>
            <FormLabel>Operator</FormLabel>
            <Select
              renderValue={(value: unknown) => {
                if (typeof value === "string") {
                  return textOperatorMapToReadableNames[
                    value as TextFilterOperator
                  ];
                }
                return "";
              }}
              sx={{ width: "12.5rem" }}
              value={FilterOperator.EQUALS}
            >
              <MenuItem
                key={FilterOperator.EQUALS}
                value={FilterOperator.EQUALS}
              >
                {`${textOperatorMapToReadableNames[FilterOperator.EQUALS]}...`}
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ mr: 3 }}>
            <FormLabel>Value</FormLabel>
            <Select
              sx={{ width: "12.5rem" }}
              value={filterText}
              onChange={onChange}
            >
              <MenuItem key="true" value="true">
                <ListItemText primary="true" />
              </MenuItem>
              <MenuItem key="false" value="false">
                <ListItemText primary="false" />
              </MenuItem>
              <MenuItem key="any" value="any">
                <ListItemText primary="any" />
              </MenuItem>
            </Select>
          </FormControl>
          <CircleIconButton
            onClick={onClickRefreshFilter}
            icon={<CloseIcon />}
            color="primary"
            sx={{ mt: "1.66rem" }}
            size="large"
          />
        </Box>
      </Box>
    </Popover>
  );
};
