import React from "react";
import { IconButton, OutlinedInput, OutlinedInputProps } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { brighthiveTheme } from "../../theme/theme";
import { Typography } from "../Typography/Typography";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";

type CopyInputProps = OutlinedInputProps;

export const CopyInput = ({ sx, label, ...rest }: CopyInputProps) => {
  const handleClick = () => {
    copy(rest.value as string);
    toast("Copied!");
  };

  return (
    <>
      <Typography variant="subtitle3" gutterBottom>
        {label}
      </Typography>
      <OutlinedInput
        endAdornment={
          <IconButton
            onClick={handleClick}
            sx={{
              p: 0,
              mr: 0,
              backgroundColor: "transparent",
              "&:hover": { backgroundColor: "transparent" },
              "&:focus": { backgroundColor: "transparent" },
              "&:active": { backgroundColor: "transparent" },
            }}
          >
            <ContentCopyIcon
              color="grayAccent"
              sx={{
                width: "1.042rem",
                height: "1.042rem",
                mr: "0.354rem",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            />
          </IconButton>
        }
        size="small"
        sx={{
          ".close-button": {
            visibility: "hidden",
          },
          ".close-button.show": {
            visibility: "visible",
          },
          ...brighthiveTheme.typography.body3,
          ...sx,
        }}
        {...rest}
      />
    </>
  );
};
