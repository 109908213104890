import React, { useState } from "react";
import { Button } from "../Button/Button";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Menu } from "../Menu/Menu";
import { MenuItem } from "../Menu/MenuItem";
import { useMenu } from "../Menu/useMenu";
import { ListItemText } from "../Menu/ListItemText";
import UppyModal from "../UppyModal/UppyModal";
import { useApolloClient, useMutation } from "@apollo/client";
import {
  GetDataAssetsDocument,
  OnboardDataAssetToWorkspaceDocument,
} from "../../generated";
import { useParams } from "react-router-dom";
import { AddDataAssetTab } from "./AddDataAssetTab";
import { Box, Dialog, Modal } from "@mui/material";
import { ButtonPrimary } from "./style";

export interface AddDataAssetButtonProps {
  onClick: (type: AddDataAssetButtonType) => void;
}

export enum AddDataAssetButtonType {
  Upload = "Upload",
  ExtractLoad = "Extract and load",
  APIconnect = "API connect",
  BulkTransfer = "Bulk Transfer",
}

export const AddDataAssetButton = ({ onClick }: AddDataAssetButtonProps) => {
  const [menuOpen, anchorEl, openMenuFromAnchorElement, closeMenu] = useMenu();
  const { workspaceId } = useParams();

  const [uploadOpen, setUploadOpen] = useState(false);

  const [onboardDataAsset] = useMutation(OnboardDataAssetToWorkspaceDocument);
  const client = useApolloClient();

  return (
    <>
      <ButtonPrimary
        id="add-data-asset-button"
        aria-controls={menuOpen ? "add-data-asset-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        variant="contained"
        onClick={openMenuFromAnchorElement}
        endIcon={menuOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      >
        Add data assets
      </ButtonPrimary>
      <Menu
        id="add-data-asset-menu"
        MenuListProps={{ "aria-labelledby": "add-data-asset-button" }}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ ".MuiList-root": { width: "10rem" } }}
      >
        <MenuItem
          disableRipple
          onClick={() => {
            closeMenu();
            onClick(AddDataAssetButtonType.Upload);
            setUploadOpen(true);
          }}
        >
          <ListItemText>{AddDataAssetButtonType.Upload}</ListItemText>
        </MenuItem>
        {/* <MenuItem
          disableRipple
          onClick={() => {
            closeMenu();
            onClick(AddDataAssetButtonType.ExtractLoad);
          }}
        >
          <ListItemText>{AddDataAssetButtonType.ExtractLoad}</ListItemText>
        </MenuItem>
        <MenuItem
          disableRipple
          onClick={() => {
            closeMenu();
            onClick(AddDataAssetButtonType.APIconnect);
          }}
        >
          <ListItemText>{AddDataAssetButtonType.APIconnect}</ListItemText>
        </MenuItem>
        <MenuItem
          disableRipple
          onClick={() => {
            closeMenu();
            onClick(AddDataAssetButtonType.BulkTransfer);
          }}
        >
          <ListItemText>{AddDataAssetButtonType.BulkTransfer}</ListItemText>
        </MenuItem> */}
      </Menu>
      {uploadOpen && (
        <Dialog
          open={uploadOpen}
          onClose={() => setUploadOpen(false)}
          sx={{
            ".MuiPaper-elevation": {
              width: "100%",
              transform: "translateX(0px)",
            },
          }}
        >
          <AddDataAssetTab
            uploadOpen={uploadOpen}
            setUploadOpen={setUploadOpen}
            onClose={() => setUploadOpen(false)}
          />
        </Dialog>
      )}
    </>
  );
};
