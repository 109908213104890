import React from "react";
import {
  DataAsset,
  GetDataAssetSuggestionsQueryVariables,
  WorkflowInputDataAssetGroupDataAssetSuggestion,
} from "../../generated";
import { Box, useTheme, Divider } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { Tag } from "../../common/Tag/Tag";
import {
  CheckRounded,
  CorporateFare,
  PersonRounded,
} from "@mui/icons-material";
import AccessStatusChip from "../../AssetDetail/AccessStatusChip/AccessStatusChip";
import { Chip } from "../../common/Chip/Chip";
import { Button } from "../../common/Button/Button";
import { useParams } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

interface DataAssetItemProps {
  item: WorkflowInputDataAssetGroupDataAssetSuggestion;
  selected: boolean;
  onClickSelected: (v: boolean) => void;
}

function DataAssetItem({
  item,
  selected,
  onClickSelected,
}: DataAssetItemProps) {
  const { palette } = useTheme();
  const { workspaceId } = useParams();

  const totalIssue = 0;
  const isNoIssues = totalIssue === 0;
  const isContainsPii = item.pii;
  return (
    <Box
      px={4}
      py={4}
      border={`1px solid ${palette.action.disabledBackground}`}
      borderRadius={1}
      sx={{
        backgroundColor: selected ? palette.action.selected : "transparent",
      }}
    >
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box>
            <Typography variant="subtitle2">{item.name}</Typography>
            <Box display="flex" gap={2} mt={1}>
              <Chip
                avatar={<CheckRounded />}
                size="small"
                label={
                  isNoIssues
                    ? "No issues"
                    : `${totalIssue} ${totalIssue > 1 ? "issues" : "issue"}`
                }
              />
              {item.accessStatus && (
                <AccessStatusChip status={item.accessStatus} />
              )}
              {isContainsPii && (
                <Chip
                  size="small"
                  variant="outlined"
                  label="Contains PII"
                  avatar={<PersonRounded />}
                />
              )}
            </Box>
          </Box>
          <Button
            color="grayAccent"
            variant="outlined"
            onClick={() => onClickSelected(!selected)}
            sx={{
              height: 32,
            }}
          >
            {selected && (
              <CheckRounded sx={{ mr: 1, ml: -1, mt: -0.5, fontSize: 16 }} />
            )}
            {selected ? "Selected" : "Select"}
          </Button>
        </Box>
        {item.description && (
          <Typography sx={{ mt: 2 }} variant="body3">
            {item.description}
          </Typography>
        )}
        <Box display="flex" gap={2} my={3}>
          {item.tags.map((tag) => (
            <Tag key={tag} label={tag} dismissable={false} />
          ))}
        </Box>
        <Box>
          <Button
            color="link"
            size="small"
            onClick={(e: any) => {
              window.open(`/workspace/${workspaceId}/asset-details/${item.id}`);
            }}
            sx={{ display: "flex", fontWeight: "bold" }}
          >
            View asset details
            <OpenInNewIcon
              color="link"
              fontSize="small"
              sx={{ ml: 1, transform: "scale(0.8)" }}
            />
          </Button>
        </Box>
        <Divider />
        <Box display="flex" alignItems="center" my={2}>
          <CorporateFare
            sx={{
              color: palette.gray.dark,
              mr: ".3125rem",
              fontSize: ".875rem",
              width: ".875rem",
              height: ".875rem",
            }}
          />
          <Typography variant="caption">{item.owner?.name || ""}</Typography>
          {item.managers?.map((manager) =>
            manager ? (
              <Box ml={2} display="flex" alignItems="center">
                <Divider
                  sx={{
                    height: ".8rem",
                    mt: 1,
                    mr: 2,
                    borderColor: (theme) => `${theme.palette.scrim}`,
                  }}
                  orientation="vertical"
                  flexItem
                />
                <Typography variant="caption">
                  {manager.firstName || ""} {manager.lastName || ""}
                </Typography>
              </Box>
            ) : (
              ""
            )
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default DataAssetItem;
