import { Dialog } from "@mui/material";
import { ListSchemas } from "./ListSchema";

export const LinkSchemaModal = (params: {
  resourceId: string;
  onClose?: () => void;
}) => {
  return (
    <Dialog
      open={true}
      maxWidth="md"
      sx={{ " + #filter-owner-form": { zIndex: 999999 } }}
    >
      <ListSchemas {...params} />
    </Dialog>
  );
};
