import axios from "axios";

export enum APIError {
  network = "network",
  server = "server",
  notFound = "notFound",
  canceled = "canceled",
}

export const processApiError = (error: any): any => {
  if (error.message === APIError.canceled) {
    return APIError.canceled;
  }

  if (!error.response) {
    return APIError.network;
  }

  if (
    error.response?.status &&
    [500, 501, 502, 503, 504].includes(error.response?.status)
  ) {
    return APIError.server;
  }

  if (error.response?.status === 401) {
    return {
      status: 401,
      message: "An unknown error occurred.",
    };
  }

  if (error.response?.status === 400) {
    return "Something went wrong.";
  }

  if (!error.response?.data) {
    return "An unknown error occurred.";
  }

  return error.response?.data;
};

const axiosClient = axios.create({
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosClient.interceptors.response.use(
  (response) => {
    const newResponse = { ...response };
    const isJsonResponse =
      response.headers["content-type"]?.includes("application/json");

    return newResponse;
  },
  async (error) => {
    return Promise.reject(processApiError(error));
  }
);

axiosClient.interceptors.request.use((config: any) => {
  config.headers = { ...config.headers };

  const newConfig = { ...config };

  if (
    config?.headers &&
    config.headers["Content-Type"] === "multipart/form-data"
  ) {
    return config;
  }

  if (config.signal) {
    newConfig.signal = config.signal;
  }

  return newConfig;
});

export const clientGet = (url: string, options = {}) =>
  axiosClient.get(url, options);

export const clientPost = async (url: string, data: any, options = {}) =>
  await axiosClient.post(url, data, options);

export const clientPut = async (url: string, data: any, options = {}) =>
  await axiosClient.put(url, data, options);

export const clientPatch = async (url: string, data: any, options = {}) =>
  await axiosClient.patch(url, data, options);

export const clientDelete = async (url: string, options = {}) =>
  await axiosClient.delete(url, options);

export default axiosClient;
