import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const PlusTextIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.8333 8.33333H4.16667M4.16667 5H15.8333M11.6667 11.6667H4.16667M4.16667 15H9.16667M15 12.5V17.5M12.5 15H17.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
