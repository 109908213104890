import React, { useEffect } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogProps,
  useTheme,
} from "@mui/material";
import { Typography } from "../common/Typography/Typography";
import { Button } from "../common/Button/Button";
import { SquareIcon } from "../common/SquareIcon/SquareIcon";
import { GridView } from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import { GetWorkspacesDocument } from "../generated";
import { useLocation, useNavigate } from "react-router-dom";

interface SpecificWorkspaceSwitcherDialogProps {
  onClose: () => void;
}

type WorkspaceSwitcherDialogProps = DialogProps &
  SpecificWorkspaceSwitcherDialogProps;

export const WorkspaceSwitcherDialog = (
  props: WorkspaceSwitcherDialogProps
) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { data, loading } = useQuery(GetWorkspacesDocument);
  const onClickCancel = () => {
    if (props.onClose) props.onClose();
  };

  const location = useLocation();
  const replaceWorkspaceIdFromRoute = (
    routeString: string,
    newId: string | undefined
  ) => {
    if (routeString.includes("workspace") && typeof newId === "string") {
      const startIndex = routeString.indexOf("workspace") + 10;
      let endIndex = startIndex;
      for (let i = startIndex; i < routeString.length; i++) {
        const char = routeString[i];
        if (char === "/") {
          endIndex = i;
          break;
        }
        i++;
      }
      const newString =
        routeString.slice(0, startIndex) +
        newId +
        routeString.slice(endIndex, routeString.length);
      return newString;
    } else {
      return routeString;
    }
  };

  // useEffect(() => {
  //   if (data?.currentUser?.workspaces.length === 1 && location.pathname === '/workspace') {
  //     navigate(`/workspace/${data?.currentUser?.workspaces[0]?.id}/project`);
  //   }
  // }, [data]);

  return (
    <Dialog {...props}>
      <Box width="35rem" p={6} boxSizing="border-box">
        <Typography mb={4} variant="h5">
          Select workspace
        </Typography>
        <Box
          sx={{
            overflow: "auto",
            maxHeight: "320px",
          }}
        >
          {loading && (
            <Box display="flex" justifyContent="center" my={12}>
              <CircularProgress sx={{ color: palette.primary.darkest }} />
            </Box>
          )}
          {!loading &&
            data &&
            data.currentUser?.workspaces?.map((workspace) => (
              <Button
                key={workspace?.id}
                color="grayAccent"
                variant="outlined"
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  mb: 2,
                }}
                onClick={() => {
                  if (
                    location.pathname === "/workspace" ||
                    location.pathname === "/workspace/"
                  ) {
                    if (workspace?.acceptedPolicies === false) {
                      navigate(`/agree-policy?workspaceId=${workspace?.id}`);
                    } else {
                      navigate(`/workspace/${workspace?.id}/brightbot`);
                    }
                  } else {
                    // const newRoute = replaceWorkspaceIdFromRoute(
                    //   location.pathname,
                    //   workspace?.id
                    // );
                    // window.location.href = newRoute;
                    window.location.replace(
                      `/workspace/${workspace?.id}/brightbot`
                    );
                  }
                }}
              >
                <SquareIcon
                  icon={<GridView />}
                  backgroundColor={palette.settings.main}
                  color={palette.settings.contrastText}
                  sx={{ mr: 2 }}
                  size="medium"
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    color={palette.grayAccent.main}
                    mb={1}
                    variant="body2"
                    fontWeight={500}
                  >
                    {workspace?.name}
                  </Typography>
                  <Typography
                    color={palette.grayAccent.dark}
                    mb={0}
                    variant="body2"
                    fontWeight={500}
                    fontSize="0.875rem"
                  >
                    {workspace?.ownerOrganizationName}
                  </Typography>
                </Box>
              </Button>
            ))}
        </Box>
        <Box display="flex" mt={7} justifyContent="flex-end">
          <Button
            onClick={onClickCancel}
            color="grayAccent"
            variant="outlined"
            sx={{ mr: 3 }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
