import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { Chip } from "../../common/Chip/Chip";
import PersonIcon from "@mui/icons-material/Person";
import { Button } from "../../common/Button/Button";
import { RemoveConfirmationModal } from "./RemoveConfirmationModal";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  getIssueIcon,
  getIssueColor,
  getLabelIssueStatus,
  getAlertMessage,
  getLoader,
} from "../helpers";
import {
  InputDataAssetDocument,
  GetWorkspaceMembersDocument,
} from "../../generated";
import { SheetTabs } from "../SheetTabs";
import { SheetChat } from "../SheetChat";
import { useData } from "../../hooks/dataContext";

export const InputDataAsset = ({
  id,
  parentId,
}: {
  id: string;
  parentId: string;
}) => {
  const { palette } = useTheme();
  const { workspaceId, projectId } = useParams();
  const navigate = useNavigate();
  const [hasPii, setHasPii] = useState(false);
  const [show, setShow] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [tab, setTab] = useState(0);

  const toggleRemoveModal = () => {
    setShowConfirmationModal(true);
  };
  const { data, loading } = useQuery(InputDataAssetDocument, {
    variables: {
      workflowFilterInput: {
        projectId: projectId || "",
        workspaceId: workspaceId || "",
      },
      inputDataAssetGroupFilter: {
        inputDataAssetGroupId: parentId,
      },
      dataAssetFilter: {
        dataAssetId: id,
      },
    },
  });

  const { dispatch } = useData();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  // const { data: dataMembers } = useQuery(GetWorkspaceMembersDocument, {
  //   variables: {
  //     input: {
  //       workspaceId: workspaceId || "",
  //     },
  //   },
  //   onCompleted: (res) => {
  //     dispatch({ type: "members", payload: res.workspace?.members || [] });
  //   },
  // });

  if (loading) return getLoader("Fetching Data", "custom-loader full-height");
  const inputData = data?.workflow?.inputDataAssetGroups?.[0]?.dataAssets?.[0];

  if (inputData) {
    return (
      <>
        <Box
          boxSizing="border-box"
          sx={{
            minHeight: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Box
              sx={{
                paddingRight: "20px",
                paddingBottom: "16px",
              }}
              mb={6}
            >
              <Typography
                mb={2}
                variant="h5"
                color={palette.gray.dark}
                sx={{
                  fontSize: "12px",
                  lineHeight: "14px",
                  textTransform: "uppercase",
                  fontWeight: "700",
                }}
              >
                Input data Asset
              </Typography>
              <Typography
                mb={4}
                variant="h5"
                color={palette.common.black}
                sx={{ wordBreak: "break-word" }}
              >
                {inputData?.name}
              </Typography>
              {show && (
                <Chip
                  icon={getIssueIcon(inputData.issueStatus)}
                  color={getIssueColor(inputData.issueStatus)}
                  label={getLabelIssueStatus(inputData.issueStatus)}
                  sx={{
                    color: palette.common.black,
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "500",
                  }}
                />
              )}
              {hasPii && (
                <Chip
                  icon={
                    <PersonIcon
                      sx={{
                        fontSize: "1rem",
                        color: palette.common.black + "!important",
                      }}
                    />
                  }
                  color="gray"
                  label="Contains PII"
                  sx={{
                    color: palette.common.black,
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "500",
                    background: "none",
                    border: `1px solid ${palette.grayAccent.light}`,
                    marginLeft: "8px",
                  }}
                />
              )}

              <SheetTabs value={tab} onChange={setTab} />
            </Box>

            {tab === 0 && (
              <Box
                sx={{
                  paddingBottom: "16px",
                }}
                mb={6}
              >
                {show &&
                  getAlertMessage(
                    inputData.issueStatus,
                    "An entity managed by this organization is preventing the data flow from running."
                  )}
                <Typography
                  mb={2}
                  variant="h5"
                  color={
                    inputData && inputData.description
                      ? palette.gray.darkest
                      : palette.gray.dark
                  }
                  sx={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: "normal",
                  }}
                >
                  {inputData && inputData.description
                    ? inputData.description
                    : "No Description"}
                </Typography>

                <Button
                  sx={{
                    color: palette.link.main,
                    background: "none !important",
                    padding: "0",
                  }}
                  onClick={() =>
                    navigate(`/workspace/${workspaceId}/asset-details/${id}`)
                  }
                >
                  View asset details
                </Button>
                <br />
                {/* <Button
                  sx={{
                    color: palette.link.main,
                    background: "none !important",
                    padding: "0",
                  }}
                  href={`/workspace/${workspaceId}/project/${projectId}/project-schema/${id}`}
                >
                  {" "}
                  xxx View target schema validation results
                </Button> */}
              </Box>
            )}

            {tab === 1 && <SheetChat id={id} />}
          </Box>

          {tab === 0 && (
            <Box>
              <Button
                color="error"
                variant="outlined"
                sx={{ width: "100%" }}
                onClick={toggleRemoveModal}
              >
                Remove asset from project
              </Button>
            </Box>
          )}

          {showConfirmationModal && (
            <RemoveConfirmationModal
              inputDataAssetId={inputData.id}
              inputDataAssetGroupId={parentId}
              inputDataAssetName={inputData.name}
            />
          )}
        </Box>
      </>
    );
  }
  return null;
};
