import React from "react";
import { Box, Divider, Popover, useTheme } from "@mui/material";
import { Field, Form, Formik, FormikValues } from "formik";
import { CheckboxWithLabel } from "formik-mui";
import { FilterButton, FilterButtonProps } from "../FilterButton/FilterButton";
import { useMenu } from "../Menu/useMenu";
import { Button } from "../Button/Button";
import { Typography } from "../Typography/Typography";
import { convertToTitleCase } from "../../helpers";
import { ProjectStatus } from "../../generated";
import { ButtonDefault, ButtonPrimary, PopoverBtnsWrap } from "./style";

interface SpecificFilterByStatusButtonProps {
  text: string;
  values: FormikValues;
  onSubmit: (values: FormikValues) => void;
  amountOfProjects: number;
  amountOfActiveProjects: number;
  amountOfArchivedProjects: number;
  amountOfDraftProjects: number;
  amountOfPublishedProjects: number;
  amountOfPrivateProjects: number;
  amountOfCompleteProjects: number;
}

export type FilterByStatusButtonProps = Omit<FilterButtonProps, "onSubmit"> &
  SpecificFilterByStatusButtonProps;

export const FilterByStatusButton = ({
  text,
  values,
  onSubmit,
  amountOfProjects,
  amountOfActiveProjects,
  amountOfPublishedProjects,
  amountOfArchivedProjects,
  amountOfDraftProjects,
  amountOfPrivateProjects,
  amountOfCompleteProjects,
  ...rest
}: FilterByStatusButtonProps) => {
  const [menuOpen, anchorEl, openMenuFromAnchorElement, closeMenu] = useMenu();

  const onClickAll =
    (values: FormikValues, setValues: (...args: any[]) => any) => () => {
      if (values.all) {
        setValues({
          all: false,
          [ProjectStatus.Published]: false,
          [ProjectStatus.Private]: false,
          [ProjectStatus.Draft]: false,
          [ProjectStatus.Complete]: false,
          [ProjectStatus.Active]: false,
          [ProjectStatus.Archived]: false,
        });
      } else {
        setValues({
          all: true,
          [ProjectStatus.Published]: true,
          [ProjectStatus.Private]: true,
          [ProjectStatus.Draft]: true,
          [ProjectStatus.Complete]: true,
          [ProjectStatus.Active]: true,
          [ProjectStatus.Archived]: true,
        });
      }
    };

  const onClickValue =
    (values: FormikValues, setValues: (...args: any[]) => any, value: any) =>
    () => {
      let allOtherValuesActive = true;
      Object.keys(values).forEach((curr) => {
        if (curr !== value && curr !== "all" && values[curr] !== true) {
          allOtherValuesActive = false;
        }
      });

      if (values[value] === false && allOtherValuesActive) {
        setValues({
          all: true,
          [ProjectStatus.Published]: true,
          [ProjectStatus.Private]: true,
          [ProjectStatus.Draft]: true,
          [ProjectStatus.Complete]: true,
          [ProjectStatus.Active]: true,
          [ProjectStatus.Archived]: true,
        });
      } else if (values[value] === true) {
        setValues({
          ...values,
          all: false,
          [value]: false,
        });
      } else {
        setValues({
          ...values,
          [value]: !values[value],
        });
      }
    };
  const { palette } = useTheme();

  return (
    <>
      <FilterButton
        id="filter-status-button"
        aria-controls={menuOpen ? "filter-status-form" : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        color="grayAccent"
        variant="outlined"
        onClick={openMenuFromAnchorElement}
        chip={null}
        text={text}
        {...rest}
      />
      <Popover
        id="filter-status-form"
        aria-labelledby="filter-status-button"
        open={menuOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        onClose={closeMenu}
      >
        <Formik
          initialValues={values}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            onSubmit(values);
            closeMenu();
          }}
        >
          {({ submitForm, values, setValues }) => (
            <Form>
              <Box width="20rem" pl={6} pr={6}>
                <Box pt={4} pb={3}>
                  <Typography sx={{ mb: 2 }} variant="h6">
                    Filter by status
                  </Typography>

                  <Box>
                    <Box>
                      <Field
                        component={CheckboxWithLabel}
                        name="all"
                        Label={{
                          label: `All (${amountOfProjects})`,
                          componentsProps: { typography: { variant: "body2" } },
                        }}
                        type="checkbox"
                        onClick={onClickAll(values, setValues)}
                      />
                    </Box>
                    <Box>
                      <Field
                        component={CheckboxWithLabel}
                        name={ProjectStatus.Active}
                        Label={{
                          label: `${convertToTitleCase(
                            ProjectStatus.Active
                          )} (${amountOfActiveProjects})`,
                          componentsProps: { typography: { variant: "body2" } },
                        }}
                        type="checkbox"
                        onClick={onClickValue(
                          values,
                          setValues,
                          ProjectStatus.Active
                        )}
                      />
                    </Box>
                    <Box>
                      <Field
                        component={CheckboxWithLabel}
                        name={ProjectStatus.Published}
                        Label={{
                          label: `${convertToTitleCase(
                            ProjectStatus.Published
                          )} (${amountOfPublishedProjects})`,
                          componentsProps: { typography: { variant: "body2" } },
                        }}
                        type="checkbox"
                        onClick={onClickValue(
                          values,
                          setValues,
                          ProjectStatus.Published
                        )}
                      />
                    </Box>
                    <Box>
                      <Field
                        component={CheckboxWithLabel}
                        name={ProjectStatus.Draft}
                        Label={{
                          label: `${convertToTitleCase(
                            ProjectStatus.Draft
                          )} (${amountOfDraftProjects})`,
                          componentsProps: { typography: { variant: "body2" } },
                        }}
                        type="checkbox"
                        onClick={onClickValue(
                          values,
                          setValues,
                          ProjectStatus.Draft
                        )}
                      />
                    </Box>
                    <Box>
                      <Field
                        component={CheckboxWithLabel}
                        name={ProjectStatus.Private}
                        Label={{
                          label: `${convertToTitleCase(
                            ProjectStatus.Private
                          )} (${amountOfPrivateProjects})`,
                          componentsProps: { typography: { variant: "body2" } },
                        }}
                        type="checkbox"
                        onClick={onClickValue(
                          values,
                          setValues,
                          ProjectStatus.Private
                        )}
                      />
                    </Box>
                    <Box>
                      <Field
                        component={CheckboxWithLabel}
                        name={ProjectStatus.Complete}
                        Label={{
                          label: `${convertToTitleCase(
                            ProjectStatus.Complete
                          )} (${amountOfCompleteProjects})`,
                          componentsProps: { typography: { variant: "body2" } },
                        }}
                        type="checkbox"
                        onClick={onClickValue(
                          values,
                          setValues,
                          ProjectStatus.Complete
                        )}
                      />
                    </Box>
                    <Box>
                      <Field
                        component={CheckboxWithLabel}
                        name={ProjectStatus.Archived}
                        Label={{
                          label: `${convertToTitleCase(
                            ProjectStatus.Archived
                          )} (${amountOfArchivedProjects})`,
                          componentsProps: { typography: { variant: "body2" } },
                        }}
                        type="checkbox"
                        onClick={onClickValue(
                          values,
                          setValues,
                          ProjectStatus.Archived
                        )}
                      />
                    </Box>
                  </Box>
                </Box>
                <Divider />
                <PopoverBtnsWrap>
                  <ButtonDefault
                    variant="outlined"
                    color="grayAccent"
                    onClick={closeMenu}
                  >
                    Cancel
                  </ButtonDefault>
                  <ButtonPrimary
                    variant="contained"
                    color="primary"
                    onClick={submitForm}
                  >
                    Apply
                  </ButtonPrimary>
                </PopoverBtnsWrap>
              </Box>
            </Form>
          )}
        </Formik>
      </Popover>
    </>
  );
};
