import { Box, useTheme } from "@mui/material";
import React from "react";

import { AddMemberButton } from "../AddMemberButton/AddMemberButton";
import { AppContext } from "../../App";
import { MembersGrid } from "../MembersGrid/MembersGrid";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GetWorkspaceInfoDocument } from "../../generated";
import { SideNavigationPage } from "../../common/SideNavigationPage/SideNavigationPage";
import { Typography } from "../../common/Typography/Typography";
import { WorkspaceSidenav } from "../../common/WorkspaceSidenav/WorkspaceSidenav";
import { GridView } from "@mui/icons-material";
import { MemberPageContent } from "../style";

export interface MembersPageProps {
  showSidenav: boolean;
}

export const MembersPage = () => {
  const { palette } = useTheme();
  const { workspaceId } = useParams();
  const { data } = useQuery(GetWorkspaceInfoDocument, {
    variables: { input: { workspaceId: workspaceId || "" } },
  });
  const workspaceName = data?.workspace?.name || "";
  const isEditable = data?.workspace?.governance?.isEditable;
  return (
    <AppContext.Consumer>
      {({ showSidenav }) => (
        <SideNavigationPage
          leftHeaderElements={
            <Typography variant="h5">Workspace Members</Typography>
          }
          rightHeaderElements={isEditable ? <AddMemberButton /> : null}
          content={
            <MemberPageContent bgcolor={palette.common.white}>
              <MembersGrid isEditable={isEditable} />
            </MemberPageContent>
          }
        />
      )}
    </AppContext.Consumer>
  );
};
