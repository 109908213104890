import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const ProjectOverviewIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_312_34147)">
        <path
          d="M10.5108 1.84126L10.5893 1.91058L18.0893 9.41058C18.5914 9.91273 18.2731 10.7558 17.5947 10.8282L17.5 10.8332H16.6667L16.6667 15.8332C16.6667 17.1646 15.6259 18.2529 14.3136 18.3289L14.1667 18.3332H5.83338C4.50198 18.3332 3.41366 17.2924 3.33762 15.9801L3.33338 15.8332L3.33337 10.8332H2.50004C1.7899 10.8332 1.41885 10.0119 1.84736 9.48106L1.91079 9.41058L9.41079 1.91058C9.71119 1.61018 10.1839 1.58707 10.5108 1.84126ZM10 3.679L4.45838 9.21984L4.53319 9.25121C4.59388 9.28097 4.65043 9.31791 4.70172 9.3609C4.70616 9.36462 4.7107 9.3685 4.71519 9.37243C4.73541 9.39013 4.75469 9.40871 4.77305 9.42817C4.77916 9.43464 4.78559 9.44168 4.7919 9.44884C4.80701 9.46597 4.82149 9.4838 4.83522 9.50222C4.84127 9.51033 4.84727 9.51869 4.85311 9.52715C4.86587 9.54566 4.8778 9.56452 4.88897 9.58388C4.89507 9.59444 4.90105 9.60535 4.90678 9.6164C4.91677 9.63564 4.92605 9.65535 4.93456 9.67547C4.93872 9.68532 4.94267 9.69518 4.94643 9.70512C4.95362 9.72414 4.96016 9.74354 4.96598 9.76325C4.97009 9.77715 4.97385 9.79121 4.97724 9.80541C4.98482 9.83712 4.9906 9.86959 4.99444 9.90265L5.00004 9.99984V15.8332C5.00004 16.2605 5.32174 16.6128 5.73619 16.6609L5.83338 16.6665H14.1667C14.5941 16.6665 14.9463 16.3448 14.9944 15.9304L15 15.8332V9.99984C15 9.92983 15.0087 9.86183 15.0249 9.79686C15.0282 9.78389 15.0317 9.7713 15.0354 9.75884C15.0409 9.74081 15.047 9.7228 15.0537 9.70507C15.059 9.69099 15.0647 9.67702 15.0708 9.66324C15.0786 9.6457 15.0868 9.62876 15.0955 9.61215C15.1009 9.60198 15.1066 9.59165 15.1125 9.58147C15.1259 9.55846 15.1403 9.53619 15.1558 9.51468C15.1586 9.51071 15.1617 9.50646 15.1648 9.50225C15.1824 9.47873 15.2011 9.45619 15.221 9.43469C15.2231 9.4324 15.2254 9.42992 15.2277 9.42746C15.2457 9.40846 15.2644 9.3904 15.284 9.37324C15.2898 9.36811 15.2955 9.36327 15.3012 9.3585C15.3515 9.31668 15.4072 9.28048 15.4669 9.25121L15.5409 9.21984L10 3.679Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_312_34147">
          <rect width="20" height="20" fill="currentColor" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
