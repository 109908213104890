import { useState, useEffect, useRef } from "react";
import { ChatBotWidget } from "../ChatBotWidget/ChatBotWidget";
import { ChatRecentBotWidget } from "../ChatRecentBotWidget/ChatRecentBotWidget";
import { ChatSentBotWidget } from "../ChatSentBotWidget/ChatSentBotWidget";
import { ChatRcvBotWidget } from "../ChatRcvBotWidget/ChatRcvBotWidget";
import { PaperPlaneIcon } from "../../common/Icons/PaperPlaneIcon";
import { AttachmentIcon } from "../../common/Icons/AttachmentIcon";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GetCurrentUserInfoDocument } from "../../generated";
import { useQuery } from "@apollo/client";
import { getAuthToken } from "../../AppApolloProvider";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { toast } from "react-toastify";
import { size } from "lodash";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-react";
import { SuggestionBox } from "../SuggestionBox";
import axios from "axios";
import {
  ChatBotWrapper,
  WidgetsWrapper,
  WidgetCol,
  MessagesWrapper,
  EditorWrapper,
  FormWrapper,
  FormField,
  ActionsWrapper,
  ButtonAttachment,
  ButtonSubmit,
} from "../style";
import chatApi from "../../api/chatApi";
import { Box, CircularProgress, useTheme } from "@mui/material";

let controller: any;

export const ChatBot = ({ showSuggestion, threadData, setRefetch }: any) => {
  const navigate = useNavigate();
  const { workspaceId, threadId } = useParams();
  const [messages, setMessages] = useState<any>([]);
  const [streaming, setStream] = useState<any>();
  const [messageValue, setMessageValue] = useState<any>("");
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const { palette } = useTheme();
  const { data } = useQuery(GetCurrentUserInfoDocument);

  const ref: any = useRef();

  const [thread, setThread]: any = useState();
  const [assistant, setAssistant]: any = useState();

  const [streamMessage, setStreamMessage] = useState("");

  const userName = `${data?.currentUser?.firstName.split(" ").join("-") || ""}`;
  const userId = data?.currentUser.id;

  const chatCreate = async (data: any, message: string) => {
    controller = new AbortController();
    const signal = controller?.signal;

    let id = threadId;
    if (!thread) id = await createSession(message);

    let newMessage = "";

    await fetchEventSource(
      `${process.env.REACT_APP_BASE_URL}/threads/${id}/runs/stream`,
      {
        signal: signal,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": process.env.REACT_APP_BRIGHTIBOT_API_KEY || "",
        },
        body: JSON.stringify({
          assistant_id: assistant.assistant_id,
          ...(messages.length === 0 && {
            metadata: { Title: message, Author: userName },
          }),
          input: {
            messages: data,
          },
          user_info: userId,
          stream_mode: "events",
        }),
        openWhenHidden: true,
        async onopen(res) {
          if (res.status === 200) {
            setStream(true);
            setStreamMessage("");
          }
        },
        onmessage(msg) {
          if (msg.event === "events") {
            const dt = JSON.parse(msg.data);

            if (dt.event === "on_chat_model_stream") {
              const output = newMessage + dt?.data?.chunk?.content;

              setStreamMessage(output);
              newMessage = output;
            }
          } else if (msg.event === "end") {
            setMessages([
              ...data,
              {
                content: newMessage,
                additional_kwargs: {},
                response_metadata: {},
                type: "ai",
                id: "01",
                example: false,
              },
            ]);
            setStream(false);
          }
        },
        onclose() {
          return;
        },
        onerror(err) {
          throw new Error(err);
        },
      }
    );
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (!e.currentTarget.message.value) return;

    const data = e.currentTarget.message.value;

    if (!data) return;

    const dataMessage = [
      ...messages,
      {
        content: data,
        additional_kwargs: {},
        response_metadata: {},
        type: "human",
        name: userName,
        id: "01",
        example: false,
      },
    ];
    setMessageValue("");
    setMessages(dataMessage);
    e?.currentTarget?.reset();

    await chatCreate(dataMessage, data);
  };

  const handleWidgetSubmit = (text: string) => {
    setMessageValue(text);
    setTimeout(() => {
      ref.current.click();
      setMessageValue("");
    });
  };

  useEffect(() => {
    return () => {
      controller?.abort();
    };
  }, []);

  const createSession = async (message: string) => {
    try {
      const res = await chatApi.post("/threads", {
        metadata: {
          user_id: userId,
          workspace_id: workspaceId,
          Title: message,
          Author: userName,
        },
      });

      const id = res.data.thread_id;
      setThread(id);
      if (setRefetch) {
        setRefetch((prev: any) => !prev);
        navigate(`/workspace/${workspaceId}/brightbot/${id}`);
      }
      return id;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (threadId && thread !== threadId) {
      setThread(threadId);
    }
  }, [threadId, thread, pathname, workspaceId]);

  useEffect(() => {
    if (pathname === `/workspace/${workspaceId}/brightbot`) {
      setThread();
      setMessages([]);
    }
  }, [pathname, workspaceId]);

  useEffect(() => {
    chatApi
      .post("/assistants/search", { graph_id: "chat_agent" })
      .then((res) => {
        setAssistant(res.data[0]);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (threadId && thread !== threadId) {
      setLoading(true);
      setMessages([]);
      chatApi
        .get(`/threads/${threadId}/state`)
        .then((res) => setMessages(res?.data?.values?.messages || []))
        .finally(() => setLoading(false));
    }
  }, [threadId]);

  return (
    <ChatBotWrapper className="ChatBot">
      {!loading ? (
        <>
          {size(messages) < 1 && (
            <WidgetsWrapper>
              <WidgetCol>
                <ChatBotWidget
                  title="Draft a data strategy"
                  description="Look at the documents provided in the workspace and write a data strategy document for me."
                  onClick={() =>
                    handleWidgetSubmit(
                      "Look at the documents provided in the workspace and write a data strategy document for me."
                    )
                  }
                />
              </WidgetCol>
              <WidgetCol>
                <ChatBotWidget
                  title="Extract information for docs"
                  description="Take the document that I upload and extract key terms based on the category of teams that I specify."
                  onClick={() =>
                    handleWidgetSubmit(
                      "Take the document that I upload and extract key terms based on the category of teams that I specify."
                    )
                  }
                />
              </WidgetCol>
              {/* <WidgetCol>
            <ChatBotWidget
              title="Connect new data sources"
              description="Walk me through adding a new data source to my workspace."
              onClick={() =>
                handleWidgetSubmit(
                  "Walk me through adding a new data source to my workspace."
                )
              }
            />
          </WidgetCol> */}
              <WidgetCol>
                <ChatBotWidget
                  title="Quick insights from my data"
                  description="Look at my most recently used data asset and tell me some interesting and novel insights."
                  onClick={() =>
                    handleWidgetSubmit(
                      "Look at my most recently used data asset and tell me some interesting and novel insights."
                    )
                  }
                />
              </WidgetCol>
              {/* <WidgetCol>
            <ChatBotWidget
              title="Create a new project"
              description="Help me set up a new data project by writing the project title, description, adding relevant data sources, and creating initial transformations, all based on my following description."
              onClick={() =>
                handleWidgetSubmit(
                  "Help me set up a new data project by writing the project title, description, adding relevant data sources, and creating initial transformations, all based on my following description."
                )
              }
            />
          </WidgetCol> */}
              {/* <WidgetCol>
            <ChatBotWidget
              title="Create a data visualization"
              description="Based on a data asset I specify, create a data visualization using the Evidence structure that communicates the key concepts I’ll outline."
              onClick={() =>
                handleWidgetSubmit(
                  "Based on a data asset I specify, create a data visualization using the Evidence structure that communicates the key concepts I’ll outline."
                )
              }
            />
          </WidgetCol> */}
            </WidgetsWrapper>
          )}

          <MessagesWrapper>
            {messages.map((message: any) => (
              <>
                {message?.type === "human" && (
                  <ChatSentBotWidget>
                    <ReactMarkdown
                      className="line-break"
                      children={message.content}
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeRaw] as any}
                    />
                  </ChatSentBotWidget>
                )}
                {message?.type === "ai" && (
                  <ChatRcvBotWidget>
                    <ReactMarkdown
                      children={message.content}
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeRaw] as any}
                    />
                  </ChatRcvBotWidget>
                )}
              </>
            ))}
            {streaming && (
              <ChatRcvBotWidget>
                <ReactMarkdown
                  children={streamMessage}
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw] as any}
                />
              </ChatRcvBotWidget>
            )}
            {showSuggestion && <SuggestionBox />}
          </MessagesWrapper>
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress sx={{ color: palette.primary.darkest }} />
        </Box>
      )}
      <EditorWrapper>
        <form onSubmit={onSubmit}>
          <FormWrapper>
            <FormField
              id="chatId"
              placeholder="Ask something&hellip;"
              name="message"
              value={messageValue}
              onChange={(e) => setMessageValue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter" && e.shiftKey === false) {
                  e.preventDefault();
                  ref.current.click();
                  setMessageValue("");
                }
              }}
              multiline
            />
            <ActionsWrapper>
              <ButtonAttachment>
                <input type="file" id="attachement" name="attachement" />
                <label htmlFor="attachement">
                  <AttachmentIcon />
                </label>
              </ButtonAttachment>
              <ButtonSubmit
                disabled={streaming || !assistant}
                type="submit"
                ref={ref}
              >
                <PaperPlaneIcon />
              </ButtonSubmit>
            </ActionsWrapper>
          </FormWrapper>
        </form>
        {/* <ChatRecentBotWidget
          title="Most recent chat"
          description="Help me extract key business terms within our PDF data documentation."
          time="Yesterday"
        /> */}
      </EditorWrapper>
    </ChatBotWrapper>
  );
};
