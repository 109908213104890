import React from "react";
import { DataNode } from "./DataNode";
import { DestinationType, WorkflowDestinationOutput } from "../generated";
import { Tag } from "../common/Tag/Tag";
import { DestinationApiIcon } from "../common/Icons/DestinationApiIcon";
import { DestinationConfiguredIcon } from "../common/Icons/DestinationConfiguredIcon";
import { DestinationDownloadIcon } from "../common/Icons/DestinationDownloadIcon";
import { useTheme } from "@mui/material";

export interface DestinationNodeProps {
  data: WorkflowDestinationOutput;
  selected?: boolean;
  id?: string;
}

export const DestinationNode = ({
  data: { name, issueStatus, modifiedAt, destinationType },
  selected,
  id,
}: DestinationNodeProps) => {
  const { palette } = useTheme();

  let icon: React.ReactNode = null;
  if (destinationType === DestinationType.Etl) {
    icon = <DestinationApiIcon />;
    // } else if (destinationType === DestinationType.Etl) {
    //   icon = <DestinationDownloadIcon />;
  } else {
    icon = <DestinationConfiguredIcon />;
  }

  return (
    <DataNode
      id={id}
      sourceHandle={false}
      selected={selected}
      title={name}
      issueStatus={issueStatus}
      bodyText={`Last updated ${modifiedAt || ""}`}
      footer={
        <Tag
          sx={{
            ".left-text": {
              bgcolor: selected
                ? palette.common.white
                : palette.grayAccent.main,
            },
          }}
          startIcon={icon}
          label={destinationType}
          dismissable={false}
        />
      }
    />
  );
};
