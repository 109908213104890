import React, { useMemo } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  OutlinedInput,
  Popover,
  PopoverProps,
  SelectChangeEvent,
  useTheme,
} from "@mui/material";
import { Typography } from "../Typography/Typography";
import { FormLabel } from "../Form/FormLabel";
import { Select } from "../Form/Select";
import {
  FilterOperator,
  SpecificTagsFilterOperator,
  TextFilterOperator,
  textOperatorMapToReadableNames,
} from "./gridHelpers";
import { MenuItem } from "../Menu/MenuItem";
import { ListItemText } from "../Menu/ListItemText";
import { CircleIconButton } from "../CircleIconButton/CircleIconButton";
import { CloseIcon } from "../Icons/CloseIcon";

export interface SpecificTextPopoverFilterUIProps {
  displayName?: string;
  description?: string;
  currentFilterOperator: string;
  onChangeFilterOperator: (e: SelectChangeEvent<unknown>) => any;
  filterOperators: TextFilterOperator[];
  selectedUniqueFilterValues: string[];
  onChangeSelectedUniqueFilterValues: (
    event: SelectChangeEvent<unknown>
  ) => any;
  uniqueFilterValues: string[];
  filterText: string;
  onChangeFilterText: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => any;
  onClickRefreshFilter: (...args: any[]) => any;
}
export type TextPopoverFilterUIPropsFilterProps =
  SpecificTextPopoverFilterUIProps & PopoverProps;

export const TextPopoverFilterUI = ({
  displayName,
  description,
  currentFilterOperator,
  onChangeFilterOperator,
  filterOperators,
  selectedUniqueFilterValues,
  onChangeSelectedUniqueFilterValues,
  uniqueFilterValues,
  filterText,
  onChangeFilterText,
  onClickRefreshFilter,
  ...rest
}: TextPopoverFilterUIPropsFilterProps) => {
  let valueInputDisabled = false;
  if (
    currentFilterOperator === FilterOperator.IS_EMPTY ||
    currentFilterOperator === FilterOperator.IS_NOT_EMPTY ||
    currentFilterOperator === SpecificTagsFilterOperator.IS_EMPTY ||
    currentFilterOperator === SpecificTagsFilterOperator.IS_NOT_EMPTY
  ) {
    valueInputDisabled = true;
  }

  const { palette, typography } = useTheme();
  const sortedUniqueFilterValues = useMemo(
    () => uniqueFilterValues.sort(),
    [uniqueFilterValues]
  );

  return (
    <Popover {...rest}>
      <Box sx={{ pt: 4, pb: 6, pl: 6, pr: 6 }}>
        <Typography variant="h6">{displayName}</Typography>
        {description && (
          <Typography pt={2} variant="body3" color={palette.gray.dark}>
            {description}
          </Typography>
        )}
        <Box sx={{ mt: 4 }}>
          <FormControl sx={{ mr: 3 }}>
            <FormLabel>Operator</FormLabel>
            <Select
              renderValue={(value: unknown) => {
                if (typeof value === "string") {
                  return textOperatorMapToReadableNames[
                    value as TextFilterOperator
                  ];
                }
                return "";
              }}
              sx={{ width: "12.5rem" }}
              value={currentFilterOperator}
              onChange={onChangeFilterOperator}
            >
              {filterOperators.map((currentFilterOption) => (
                <MenuItem key={currentFilterOption} value={currentFilterOption}>
                  {`${textOperatorMapToReadableNames[currentFilterOption]}...`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl disabled={valueInputDisabled} sx={{ mr: 3 }}>
            <FormLabel>Value</FormLabel>
            {currentFilterOperator === FilterOperator.IS_ANY_OF ||
            currentFilterOperator === FilterOperator.IS_NONE_OF ? (
              <Select
                sx={{ width: "12.5rem" }}
                multiple
                value={selectedUniqueFilterValues}
                renderValue={(selected: unknown) => {
                  if (Array.isArray(selected)) {
                    return selected.join(", ");
                  }
                  return "";
                }}
                onChange={onChangeSelectedUniqueFilterValues}
              >
                {sortedUniqueFilterValues.map((currFilterValue) => (
                  <MenuItem key={currFilterValue} value={currFilterValue}>
                    <Checkbox
                      checked={selectedUniqueFilterValues.includes(
                        currFilterValue
                      )}
                    />
                    <ListItemText primary={currFilterValue} />
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <OutlinedInput
                value={filterText}
                placeholder="Enter a value"
                onChange={onChangeFilterText}
                sx={{
                  width: "12.5rem",
                  ...typography.body3,
                  ".MuiInputBase-input": {
                    pt: 2,
                    pb: 2,
                    pl: 3,
                    pr: 3,
                    height: "2.25rem",
                    boxSizing: "border-box",
                  },
                }}
              />
            )}
          </FormControl>
          <CircleIconButton
            onClick={onClickRefreshFilter}
            icon={<CloseIcon />}
            color="primary"
            sx={{ mt: "1.66rem" }}
            size="large"
          />
        </Box>
      </Box>
    </Popover>
  );
};
