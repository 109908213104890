/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect, useMemo, useState } from "react";
import { Box, useTheme } from "@mui/material";
import {
  GetQuickFilterTextParams,
  GridApi,
  ICellRendererParams,
} from "ag-grid-enterprise";
import { AGGridWrapper } from "../../common/AGGridWrapper/AGGridWrapper";
import { LinesEllipsisTypography } from "../../common/LinesEllipsisTypography/LinesEllipsisTypography";
import { ActionBarAssetDetail } from "../ActionBarAssetDetail/ActionBarAssetDetail";
import { ActionsCellRenderer } from "../ActionsCellRenderer/ActionsCellRenderer";
import { useQuery } from "@apollo/client";
import { GetSchemaDocument } from "../../generated";
import {
  FilterChangedEvent,
  GridReadyEvent,
  ValueGetterParams,
} from "ag-grid-community";
import { useParams } from "react-router-dom";
import {
  FilterOperator,
  SpecificTextFilterOperator,
} from "../../common/AGGridWrapper/gridHelpers";
import { AssetDetailGridWrapper } from "../style";

export interface AssetDetailGridProps {}

export const AssetDetailGrid = () => {
  const { palette } = useTheme();
  const { workspaceId, dataAssetId } = useParams();

  const { data, loading, refetch } = useQuery(GetSchemaDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
      dataAssetFilter: {
        dataAssetId: dataAssetId || "",
      },
    },
  });

  const dataAsset = data?.workspace?.dataAssets?.[0];
  const isEditable = dataAsset?.isEditable;
  const rowData = useMemo(() => {
    if (
      Array.isArray(data?.workspace?.dataAssets) &&
      data?.workspace?.dataAssets[0] &&
      Array.isArray(data?.workspace?.dataAssets[0]?.fields)
    ) {
      return data.workspace.dataAssets[0].fields;
    } else {
      return [];
    }
  }, [data?.workspace?.dataAssets]);

  const columnDefs = useMemo(() => {
    return [
      {
        valueGetter: (params: ValueGetterParams) => {
          if (typeof params.node?.rowIndex === "number")
            return params.node?.rowIndex + 1;
          return "";
        },
        headerClass: "index-header-cell",
        field: "number",
        headerName: "#",
        suppressMenu: true,
        sortable: true,
        width: 25,
        resizable: false,
      },
      {
        field: "name",
        headerName: "Field Name",
        getQuickFilterText: (params: GetQuickFilterTextParams) => params.value,
        type: "textColumn",
        sortable: true,
        headerComponentParams: {
          description: "The field name of the schema.",
          filterOperators: [
            SpecificTextFilterOperator.CONTAINS,
            SpecificTextFilterOperator.STARTS_WITH,
            SpecificTextFilterOperator.ENDS_WITH,
            FilterOperator.IS_ANY_OF,
            FilterOperator.IS_NONE_OF,
            FilterOperator.IS_EMPTY,
            FilterOperator.IS_NOT_EMPTY,
          ],
        },
      },
      {
        field: "dataType",
        headerName: "Data Type",
        type: "textColumn",
        sortable: true,
        headerComponentParams: {
          description: "The data type of the schema.",
          filterOperators: [
            FilterOperator.IS_ANY_OF,
            FilterOperator.IS_NONE_OF,
          ],
        },
      },
      {
        valueGetter: (params: ValueGetterParams) => {
          if (params.data.pii === "NONE" || !params.data.pii) {
            return false;
          }
          return true;
        },
        field: "pii",
        headerName: "PII",
        type: "booleanColumn",
        sortable: true,
        width: 75,
        suppressMenu: false,
      },
      {
        field: "description",
        headerName: "Description",
        getQuickFilterText: (params: GetQuickFilterTextParams) => params.value,
        type: "textColumn",
        sortable: true,
        minWidth: 500,
        cellRenderer: (params: ICellRendererParams) => {
          return (
            <LinesEllipsisTypography
              variant="body3"
              maxLines={2}
              text={params.value || ""}
            />
          );
        },
        flex: 1,
        headerComponentParams: {
          description: "The description of the schema.",
          filterOperators: [
            SpecificTextFilterOperator.CONTAINS,
            SpecificTextFilterOperator.STARTS_WITH,
            SpecificTextFilterOperator.ENDS_WITH,
            FilterOperator.IS_ANY_OF,
            FilterOperator.IS_NONE_OF,
            FilterOperator.IS_EMPTY,
            FilterOperator.IS_NOT_EMPTY,
          ],
        },
      },
      {
        field: "jsonSchema",
        headerName: "Data Requirements",
        sortable: true,
        cellRenderer: (params: ICellRendererParams) => {
          // TODO this needs to be parsed from jsonSchema but not sure what this looks like
          // <>
          //   <Tag sx={{ mr: 1 }} label="required" dismissable={false} />
          //   <Tag
          //     sx={{ mr: 1 }}
          //     label="minChar"
          //     secondaryText="3"
          //     dismissable={false}
          //   />
          //   <Tag label="maxChar" secondaryText="80" dismissable={false} />
          // </>
          return params.value;
        },
        width: 360,
        headerComponentParams: {
          description: "The data requirements of the schema.",
          filterOperators: [
            SpecificTextFilterOperator.CONTAINS,
            SpecificTextFilterOperator.STARTS_WITH,
            SpecificTextFilterOperator.ENDS_WITH,
            FilterOperator.IS_ANY_OF,
            FilterOperator.IS_NONE_OF,
            FilterOperator.IS_EMPTY,
            FilterOperator.IS_NOT_EMPTY,
          ],
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        cellRenderer: (params: ICellRendererParams) => {
          return (
            <ActionsCellRenderer
              fieldName={params.data?.name}
              description={params.data?.description}
              pii={params.data?.pii}
              rowIndex={params.node?.rowIndex}
              refetch={refetch}
            />
          );
        },
        width: 80,
        suppressMenu: true,
      },
    ].filter((item) => {
      if (!isEditable && item.field === "actions") {
        return false;
      }
      return true;
    });
  }, [isEditable, refetch]);

  const [gridApi, setGridApi] = useState<GridApi>();
  const [searchText, setSearchText] = useState("");

  const onChangeSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchText(e.target.value);
    gridApi?.setQuickFilter(e.target.value);
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params?.api);
  };

  const [noResultsFoundFromFilter, setNoResultsFoundFromFilter] =
    useState(false);

  useEffect(() => {
    const onFilterChanged = ({ api }: FilterChangedEvent) => {
      if (rowData.length > 0 && api.getDisplayedRowCount() === 0) {
        setNoResultsFoundFromFilter(true);
      } else {
        setNoResultsFoundFromFilter(false);
      }
    };
    gridApi?.addEventListener("filterChanged", onFilterChanged);
    return () => {
      gridApi?.removeEventListener("filterChanged", onFilterChanged);
    };
  }, [gridApi, rowData]);

  return (
    <AssetDetailGridWrapper
      className="ag-theme-material AssetDetailGrid"
    >
      <ActionBarAssetDetail
        onChangeSearch={onChangeSearch}
        searchText={searchText}
        noResultsFoundFromFilter={noResultsFoundFromFilter}
      />
      <Box flexGrow={1}>
        <AGGridWrapper
          loading={loading}
          css={css`
            .ag-header-cell.index-header-cell {
              border-right: 0 !important;
            }
            .ag-root {
              border-radius: 0 0 0.25rem 0.25rem;
            }
          `}
          containerStyle={{
            borderRadius: "0 0 .25rem .25rem",
          }}
          className="asset-details-grid"
          onGridReady={onGridReady}
          rowData={rowData}
          columnDefs={columnDefs}
          onFirstDataRendered={() => {}}
          rowHeight={48}
        />
      </Box>
    </AssetDetailGridWrapper>
  );
};
