import React from "react";
import { ColDef, ICellRendererParams } from "ag-grid-enterprise";
import { Box } from "@mui/material";
import { Tag } from "../Tag/Tag";
import { CheckMarkIcon } from "../Icons/CheckMarkIcon";
import { brighthiveTheme } from "../../theme/theme";
import {
  convertIsoDateStringToMMDDYYYY,
  getAndSetTimeAtStartOfDay,
  stringDateComparator,
} from "../../helpers";

export const columnDefHasType = (type: string, columnDef: any) => {
  if (columnDef?.type === type) return true;
  return Array.isArray(columnDef?.type) && columnDef.type.includes(type);
};

const stringDateFilterComparatorGreaterThanOrEqual = (
  filterValue: any,
  cellValue: any
) => {
  const valueATime = getAndSetTimeAtStartOfDay(new Date(filterValue));
  const valueBTime = getAndSetTimeAtStartOfDay(new Date(cellValue));
  return valueBTime >= valueATime;
};

const stringDateFilterComparatorLessThanOrEqual = (
  filterValue: any,
  cellValue: any
) => {
  const valueATime = getAndSetTimeAtStartOfDay(new Date(filterValue));
  const valueBTime = getAndSetTimeAtStartOfDay(new Date(cellValue));
  return valueBTime <= valueATime;
};

export const stringDateSortComparator = (valueA: any, valueB: any) => {
  const valueATime = getAndSetTimeAtStartOfDay(new Date(valueA));
  const valueBTime = getAndSetTimeAtStartOfDay(new Date(valueB));
  if (valueATime > valueBTime) {
    return 1;
  } else if (valueATime < valueBTime) {
    return -1;
  } else {
    return 0;
  }
};

export enum FilterOperator {
  EQUALS = "equals",
  NOT_EQUALS = "notEqual",
  IS_EMPTY = "blank",
  IS_NOT_EMPTY = "notBlank",
  // These do not exist in grid but must be mapped onto necessary filters
  IS_ANY_OF = "isAnyOf",
  IS_NONE_OF = "isNoneOf",
}

export enum SpecificTextFilterOperator {
  CONTAINS = "contains",
  STARTS_WITH = "startsWith",
  ENDS_WITH = "endsWith",
  NOT_CONTAINS = "notContains",
}

export type TextFilterOperator = SpecificTextFilterOperator | FilterOperator;

export enum SpecificNumberFilterOperator {
  // These actually exist in grid as filters and directly map onto necessary filters
  GREATER = "greaterThan",
  GREATER_THAN_OR_EQUAL = "greaterThanOrEqual",
  LESS = "lessThan",
  LESS_THAN_OR_EQUAL = "lessThanOrEqual",
  // This is part of a hack to get number filter to work because these filters can't be overridden
  IS_EMPTY = "numberIsEmpty",
  IS_NOT_EMPTY = "numberIsNotEmpty",
}

export type NumberFilterOperator = Exclude<
  SpecificNumberFilterOperator | FilterOperator,
  FilterOperator.IS_EMPTY | FilterOperator.IS_NOT_EMPTY
>;

export enum SpecificDateFilterOperator {
  // These actually exist in grid as filters and directly map onto necessary filters
  GREATER = "greaterThan",
  GREATER_THAN_OR_EQUAL = "greaterThanOrEqual",
  LESS = "lessThan",
  LESS_THAN_OR_EQUAL = "lessThanOrEqual",
}

export type DateFilterOperator = Exclude<
  SpecificDateFilterOperator | FilterOperator,
  FilterOperator.IS_ANY_OF | FilterOperator.IS_NONE_OF
>;

export enum SpecificTagsFilterOperator {
  // These actually exist in grid as filters and directly map onto necessary filters
  EQUALS = "tagsEquals",
  NOT_EQUALS = "tagsNotEqual",
  IS_EMPTY = "tagsBlank",
  IS_NOT_EMPTY = "tagsNotBlank",
  CONTAINS = "tagsContains",
  STARTS_WITH = "tagsStartsWith",
  ENDS_WITH = "tagsEndsWith",
  NOT_CONTAINS = "tagsNotContains",
}

export type TagsFilterOperator = SpecificTagsFilterOperator &
  FilterOperator.IS_ANY_OF &
  FilterOperator.IS_NONE_OF;

export const allDefaultTextFilterOperators = [
  SpecificTextFilterOperator.CONTAINS,
  SpecificTextFilterOperator.NOT_CONTAINS,
  FilterOperator.EQUALS,
  FilterOperator.NOT_EQUALS,
  SpecificTextFilterOperator.STARTS_WITH,
  SpecificTextFilterOperator.ENDS_WITH,
  FilterOperator.IS_EMPTY,
  FilterOperator.IS_NOT_EMPTY,
  FilterOperator.IS_ANY_OF,
  FilterOperator.IS_NONE_OF,
];

export const allDefaultNumberFilterOperators = [
  FilterOperator.EQUALS,
  FilterOperator.NOT_EQUALS,
  SpecificNumberFilterOperator.GREATER,
  SpecificNumberFilterOperator.GREATER_THAN_OR_EQUAL,
  SpecificNumberFilterOperator.LESS,
  SpecificNumberFilterOperator.LESS_THAN_OR_EQUAL,
  FilterOperator.IS_ANY_OF,
  FilterOperator.IS_NONE_OF,
];

export const allDefaultDateFilterOperators = [
  FilterOperator.EQUALS,
  FilterOperator.NOT_EQUALS,
  SpecificDateFilterOperator.GREATER,
  SpecificDateFilterOperator.GREATER_THAN_OR_EQUAL,
  SpecificDateFilterOperator.LESS,
  SpecificDateFilterOperator.LESS_THAN_OR_EQUAL,
  FilterOperator.IS_EMPTY,
  FilterOperator.IS_NOT_EMPTY,
];

export const allDefaultTagsFilterOperators = [
  SpecificTagsFilterOperator.CONTAINS,
  SpecificTagsFilterOperator.NOT_CONTAINS,
  SpecificTagsFilterOperator.EQUALS,
  SpecificTagsFilterOperator.NOT_EQUALS,
  SpecificTagsFilterOperator.STARTS_WITH,
  SpecificTagsFilterOperator.ENDS_WITH,
  SpecificTagsFilterOperator.IS_EMPTY,
  SpecificTagsFilterOperator.IS_NOT_EMPTY,
  FilterOperator.IS_ANY_OF,
  FilterOperator.IS_NONE_OF,
];

export const textOperatorMapToReadableNames = {
  [SpecificTextFilterOperator.CONTAINS]: "contains",
  [SpecificTextFilterOperator.NOT_CONTAINS]: "does not contain",
  [SpecificTextFilterOperator.STARTS_WITH]: "starts with",
  [SpecificTextFilterOperator.ENDS_WITH]: "ends with",
  [FilterOperator.EQUALS]: "is",
  [FilterOperator.NOT_EQUALS]: "is not",
  [FilterOperator.IS_EMPTY]: "is empty",
  [FilterOperator.IS_NOT_EMPTY]: "is not empty",
  [FilterOperator.IS_ANY_OF]: "is any of",
  [FilterOperator.IS_NONE_OF]: "is none of",
  [SpecificTagsFilterOperator.CONTAINS]: "contains",
  [SpecificTagsFilterOperator.NOT_CONTAINS]: "does not contain",
  [SpecificTagsFilterOperator.EQUALS]: "is",
  [SpecificTagsFilterOperator.NOT_EQUALS]: "is not",
  [SpecificTagsFilterOperator.STARTS_WITH]: "starts with",
  [SpecificTagsFilterOperator.ENDS_WITH]: "ends with",
  [SpecificTagsFilterOperator.IS_EMPTY]: "is empty",
  [SpecificTagsFilterOperator.IS_NOT_EMPTY]: "is not empty",
};

export const numberOperatorMapToReadableNames = {
  [SpecificNumberFilterOperator.GREATER]: ">",
  [SpecificNumberFilterOperator.GREATER_THAN_OR_EQUAL]: ">=",
  [SpecificNumberFilterOperator.LESS]: "<",
  [SpecificNumberFilterOperator.LESS_THAN_OR_EQUAL]: "<=",
  [SpecificNumberFilterOperator.IS_EMPTY]: "is empty",
  [SpecificNumberFilterOperator.IS_NOT_EMPTY]: "is not empty",
  [FilterOperator.EQUALS]: "=",
  [FilterOperator.NOT_EQUALS]: "!=",
  [FilterOperator.IS_ANY_OF]: "is any of",
  [FilterOperator.IS_NONE_OF]: "is none of",
};

export const dateOperatorMapToReadableNames = {
  [SpecificDateFilterOperator.GREATER]: "is after",
  [SpecificDateFilterOperator.GREATER_THAN_OR_EQUAL]: "is on or after",
  [SpecificDateFilterOperator.LESS]: "is before",
  [SpecificDateFilterOperator.LESS_THAN_OR_EQUAL]: "is on or before",
  [FilterOperator.EQUALS]: "is",
  [FilterOperator.NOT_EQUALS]: "is not",
  [FilterOperator.IS_EMPTY]: "is empty",
  [FilterOperator.IS_NOT_EMPTY]: "is not empty",
};

export const textColumn: ColDef = {
  filter: "agTextColumnFilter",
  filterParams: {
    filterOptions: [
      SpecificTextFilterOperator.CONTAINS,
      SpecificTextFilterOperator.NOT_CONTAINS,
      FilterOperator.EQUALS,
      FilterOperator.NOT_EQUALS,
      SpecificTextFilterOperator.STARTS_WITH,
      SpecificTextFilterOperator.ENDS_WITH,
      FilterOperator.IS_EMPTY,
      FilterOperator.IS_NOT_EMPTY,
      {
        displayKey: FilterOperator.IS_ANY_OF,
        displayName: "Is Any Of",
        predicate: ([filterValue]: any[], cellValue: any) => {
          if (
            typeof cellValue === "string" &&
            typeof filterValue === "string"
          ) {
            return filterValue
              .split("|||")
              .some(
                (currentValue) =>
                  currentValue.toLowerCase() === cellValue.toLowerCase()
              );
          } else {
            return filterValue === cellValue;
          }
        },
      },
      {
        displayKey: FilterOperator.IS_NONE_OF,
        displayName: "Is None Of",
        predicate: ([filterValue]: any[], cellValue: any) => {
          if (
            typeof cellValue === "string" &&
            typeof filterValue === "string"
          ) {
            return !filterValue
              .split("|||")
              .some(
                (currentValue) =>
                  currentValue.toLowerCase() === cellValue.toLowerCase()
              );
          } else {
            return filterValue !== cellValue;
          }
        },
      },
    ],
  },
};

export const numberColumn: ColDef = {
  cellClass: "ag-right-aligned-cell",
  filter: "agTextColumnFilter",
  headerComponentParams: {
    headerAlignment: "right",
  },
  filterParams: {
    filterOptions: [
      {
        displayKey: FilterOperator.EQUALS,
        displayName: "=",
        predicate: ([filterValue]: any[], cellValue: any) => {
          const numberFilterValue = Number(filterValue);
          return numberFilterValue === cellValue;
        },
      },
      {
        displayKey: FilterOperator.NOT_EQUALS,
        displayName: "!=",
        predicate: ([filterValue]: any[], cellValue: any) => {
          const numberFilterValue = Number(filterValue);
          return numberFilterValue !== cellValue;
        },
      },
      {
        displayKey: SpecificNumberFilterOperator.GREATER,
        displayName: ">",
        predicate: ([filterValue]: any[], cellValue: any) => {
          if (isNaN(cellValue)) return false;
          const numberFilterValue = Number(filterValue);
          return cellValue > numberFilterValue;
        },
      },
      {
        displayKey: SpecificNumberFilterOperator.GREATER_THAN_OR_EQUAL,
        displayName: ">=",
        predicate: ([filterValue]: any[], cellValue: any) => {
          const numberFilterValue = Number(filterValue);
          if (isNaN(cellValue)) return false;
          return cellValue >= numberFilterValue;
        },
      },
      {
        displayKey: SpecificNumberFilterOperator.LESS,
        displayName: "<",
        predicate: ([filterValue]: any[], cellValue: any) => {
          if (isNaN(cellValue)) return false;
          const numberFilterValue = Number(filterValue);
          return cellValue < numberFilterValue;
        },
      },
      {
        displayKey: SpecificNumberFilterOperator.LESS_THAN_OR_EQUAL,
        displayName: "<=",
        predicate: ([filterValue]: any[], cellValue: any) => {
          if (isNaN(cellValue)) return false;
          const numberFilterValue = Number(filterValue);
          return cellValue <= numberFilterValue;
        },
      },
      {
        displayKey: SpecificNumberFilterOperator.IS_EMPTY,
        displayName: "Is Empty",
        predicate: ([filterValue]: any[], cellValue: any) => {
          return isNaN(cellValue);
        },
      },
      {
        displayKey: SpecificNumberFilterOperator.IS_NOT_EMPTY,
        displayName: "Is Not Empty",
        predicate: ([filterValue]: any[], cellValue: any) => {
          return !isNaN(cellValue);
        },
      },
      {
        displayKey: FilterOperator.IS_ANY_OF,
        displayName: "Is Any Of",
        predicate: ([filterValue]: any[], cellValue: any) => {
          return filterValue
            .split("|||")
            .some((currentValue: any) => Number(currentValue) === cellValue);
        },
      },
      {
        displayKey: FilterOperator.IS_NONE_OF,
        displayName: "Is None Of",
        predicate: ([filterValue]: any[], cellValue: any) => {
          return !filterValue
            .split("|||")
            .some((currentValue: any) => Number(currentValue) === cellValue);
        },
      },
    ],
  },
};

export const dateColumn: ColDef = {
  headerClass: "ag-left-aligned-header",
  cellClass: "ag-left-aligned-cell",
  filter: "agDateColumnFilter",
  filterParams: {
    comparator: stringDateComparator,
    filterOptions: [
      FilterOperator.EQUALS,
      FilterOperator.NOT_EQUALS,
      SpecificDateFilterOperator.GREATER,
      {
        displayKey: SpecificDateFilterOperator.GREATER_THAN_OR_EQUAL,
        displayName: "Is On Or After",
        predicate: ([filterValue]: any[], cellValue: any) => {
          return stringDateFilterComparatorGreaterThanOrEqual(
            filterValue,
            cellValue
          );
        },
      },
      SpecificDateFilterOperator.LESS,
      {
        displayKey: SpecificDateFilterOperator.LESS_THAN_OR_EQUAL,
        displayName: "Is On or Before",
        predicate: ([filterValue]: any[], cellValue: any) => {
          return stringDateFilterComparatorLessThanOrEqual(
            filterValue,
            cellValue
          );
        },
      },
      FilterOperator.IS_EMPTY,
      FilterOperator.IS_NOT_EMPTY,
    ],
  },
  comparator: stringDateSortComparator,
  headerComponentParams: {
    headerAlignment: "left",
  },
  valueFormatter: (params) => {
    const dateString = params.value;
    return convertIsoDateStringToMMDDYYYY(dateString);
  },
};

export const tagsColumn: ColDef = {
  filter: "agTextColumnFilter",
  filterParams: {
    filterOptions: [
      {
        displayKey: SpecificTagsFilterOperator.CONTAINS,
        displayName: "contains",
        predicate: ([filterValue]: any[], cellValue: any) => {
          if (Array.isArray(cellValue) && typeof filterValue === "string") {
            const atLeastOneTagContainsFilterValue = cellValue.some(
              (arrValue) => {
                return arrValue
                  ?.toString()
                  .toLowerCase()
                  .includes(filterValue.toLowerCase());
              }
            );
            if (atLeastOneTagContainsFilterValue) {
              return true;
            }
          }
          return false;
        },
      },
      {
        displayKey: SpecificTagsFilterOperator.NOT_CONTAINS,
        displayName: "does not contain",
        predicate: ([filterValue]: any[], cellValue: any) => {
          if (Array.isArray(cellValue) && typeof filterValue === "string") {
            const atLeastOneTagContainsFilterValue = cellValue.some(
              (arrValue) => {
                return arrValue
                  ?.toString()
                  .toLowerCase()
                  .includes(filterValue.toLowerCase());
              }
            );
            if (!atLeastOneTagContainsFilterValue) {
              return true;
            }
          }
          return false;
        },
      },
      {
        displayKey: SpecificTagsFilterOperator.EQUALS,
        displayName: "is",
        predicate: ([filterValue]: any[], cellValue: any) => {
          if (Array.isArray(cellValue) && typeof filterValue === "string") {
            const atLeastOneTagEqualsFilterValue = cellValue.some(
              (arrValue) => {
                return (
                  arrValue?.toString().toLowerCase() ===
                  filterValue.toLowerCase()
                );
              }
            );
            if (atLeastOneTagEqualsFilterValue) {
              return true;
            }
          }
          return false;
        },
      },
      {
        displayKey: SpecificTagsFilterOperator.NOT_EQUALS,
        displayName: "is not",
        predicate: ([filterValue]: any[], cellValue: any) => {
          if (Array.isArray(cellValue) && typeof filterValue === "string") {
            const atLeastOneTagEqualsFilterValue = cellValue.some(
              (arrValue) => {
                return (
                  arrValue?.toString().toLowerCase() ===
                  filterValue.toLowerCase()
                );
              }
            );
            if (!atLeastOneTagEqualsFilterValue) {
              return true;
            }
          }
          return false;
        },
      },
      {
        displayKey: SpecificTagsFilterOperator.STARTS_WITH,
        displayName: "starts with",
        predicate: ([filterValue]: any[], cellValue: any) => {
          if (Array.isArray(cellValue) && typeof filterValue === "string") {
            const atLeastOneTagStartsWith = cellValue.some((arrValue) => {
              return arrValue
                ?.toString()
                .toLowerCase()
                .startsWith(filterValue.toLowerCase());
            });
            if (atLeastOneTagStartsWith) {
              return true;
            }
          }
          return false;
        },
      },
      {
        displayKey: SpecificTagsFilterOperator.ENDS_WITH,
        displayName: "ends with",
        predicate: ([filterValue]: any[], cellValue: any) => {
          if (Array.isArray(cellValue) && typeof filterValue === "string") {
            const atLeastOneTagEndsWith = cellValue.some((arrValue) => {
              return arrValue
                ?.toString()
                .toLowerCase()
                .endsWith(filterValue.toLowerCase());
            });
            if (atLeastOneTagEndsWith) {
              return true;
            }
          }
          return false;
        },
      },
      {
        displayKey: SpecificTagsFilterOperator.IS_EMPTY,
        displayName: "is empty",
        predicate: ([filterValue]: any[], cellValue: any) => {
          return Array.isArray(cellValue) && cellValue.length === 0;
        },
      },
      {
        displayKey: SpecificTagsFilterOperator.IS_NOT_EMPTY,
        displayName: "is not empty",
        predicate: ([filterValue]: any[], cellValue: any) => {
          return Array.isArray(cellValue) && cellValue.length > 0;
        },
      },
      {
        displayKey: FilterOperator.IS_ANY_OF,
        displayName: "Is Any Of",
        predicate: ([filterValue]: any[], cellValue: any) => {
          if (Array.isArray(cellValue) && typeof filterValue === "string") {
            const atLeastOneTagIsOneOfAnyOf = cellValue.some((arrValue) => {
              return filterValue
                .split("|||")
                .some(
                  (currentValue) =>
                    currentValue.toLowerCase() === arrValue.toLowerCase()
                );
            });
            if (atLeastOneTagIsOneOfAnyOf) {
              return true;
            }
          }
          return false;
        },
      },
      {
        displayKey: FilterOperator.IS_NONE_OF,
        displayName: "Is None Of",
        predicate: ([filterValue]: any[], cellValue: any) => {
          if (Array.isArray(cellValue) && typeof filterValue === "string") {
            const atLeastOneTagIsOneOfAnyOf = cellValue.some((arrValue) => {
              return filterValue
                .split("|||")
                .some(
                  (currentValue) =>
                    currentValue.toLowerCase() === arrValue.toLowerCase()
                );
            });
            if (!atLeastOneTagIsOneOfAnyOf) {
              return true;
            }
          }
          return false;
        },
      },
    ],
  },
  cellRenderer: (params: ICellRendererParams) => {
    return (
      <Box display="flex" alignItems="center" flexWrap="wrap" gap={1}>
        {Array.isArray(params.value) &&
          params.value.map((value) => (
            <Tag key={value} label={value} dismissable={false} />
          ))}
      </Box>
    );
  },
};

export const booleanColumn: ColDef = {
  filter: "agTextColumnFilter",
  filterParams: {
    filterOptions: [FilterOperator.EQUALS],
  },
  cellRenderer: (params: ICellRendererParams) => {
    if (params.value) {
      return (
        <Box display="flex" alignItems="center" flexWrap="wrap" gap={1}>
          <CheckMarkIcon sx={{ color: brighthiveTheme.palette.gray.dark }} />
        </Box>
      );
    }
    return null;
  },
};
