import React, { createContext, useCallback, useState } from "react";
import { Confirm } from "../common/Confirm";

type ConfirmationParams<T> = {
  message?: string;
  affirmLabel?: string;
  denyLabel?: string;
  additionalMessage?: string;
  assertMessage?: string;
  assert?: boolean;
  onYes: (_?: T) => void;
  onNo?: (_?: T) => void;
  data?: T;
};

type ConfirmationContextType<T> = {
  confirm: (_: ConfirmationParams<T>) => void;
};

const ConfirmationContext =
  createContext<ConfirmationContextType<unknown> | null>(null);
const ConfirmationContextProvider: React.FC<{
  children:
    | React.ReactNode
    | ((
        confirming: boolean,
        onYes: () => void,
        onNo: () => void
      ) => React.ReactNode);
}> = ({ children }) => {
  const [confirming, setConfirming] = useState<boolean>(false);
  const [params, setParams] = useState<ConfirmationParams<unknown>>();

  const onConfirm = useCallback((params: ConfirmationParams<unknown>) => {
    setParams(params);
    setConfirming(true);
  }, []);
  const onYes = useCallback(() => {
    setConfirming(false);
    params?.onYes(params?.data);
  }, [params]);
  const onNo = useCallback(() => {
    setConfirming(false);
    params?.onNo?.(params?.data);
  }, [params]);

  const contextValue = {
    confirm: onConfirm,
  };
  return (
    <ConfirmationContext.Provider value={contextValue}>
      {children}
      {confirming && <Confirm {...params} onYes={onYes} onNo={onNo} />}
    </ConfirmationContext.Provider>
  );
};
export const useConfirm = <T,>() =>
  React.useContext(ConfirmationContext) as ConfirmationContextType<T>;

const Confirmation = {
  ContextProvider: ConfirmationContextProvider,
  useContext: useConfirm,
};

export default Confirmation;
