import { Box, IconButton } from "@mui/material";
import React from "react";
import { ThreadHeaderProps } from "stream-chat-react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Typography } from "../../common/Typography/Typography";
import Close from "@mui/icons-material/Close";

function CustomThreadHeader({ closeThread, thread }: ThreadHeaderProps) {
  return (
    <Box>
      <Box display="flex" alignItems="center" pb={6} position="relative">
        <IconButton size="small" onClick={closeThread}>
          <ArrowBackIcon fontSize="small" color="grayAccent" />
        </IconButton>
        <Typography variant="h5">Comment thread</Typography>
        <IconButton
          size="small"
          onClick={closeThread}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <Close fontSize="small" color="grayAccent" />
        </IconButton>
      </Box>
      <Typography variant="subtitle3">All replies</Typography>
    </Box>
  );
}

export default CustomThreadHeader;
