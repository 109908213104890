/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import React from "react";
import { Typography, TypographyProps } from "../Typography/Typography";
import { css } from "@emotion/react";

export interface SpecificLinesEllipsisTypographyProps {
  text?: string;
  maxLines: number;
}

export type LinesEllipsisTypographyProps =
  SpecificLinesEllipsisTypographyProps & TypographyProps;

export const LinesEllipsisTypography = ({
  text,
  maxLines,
  ...rest
}: LinesEllipsisTypographyProps) => {
  return (
    <Typography
      css={css`
        text-overflow: ellipsis;
        overflow: hidden;
        // Addition lines for 2 line or multiline ellipsis
        display: -webkit-box !important;
        -webkit-line-clamp: ${maxLines};
        -webkit-box-orient: vertical;
        white-space: normal;
      `}
      {...rest}
    >
      {text}
    </Typography>
  );
};
