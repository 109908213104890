import React from "react";
import { Box, Divider, IconButton, useTheme } from "@mui/material";
import { BasePageHeader } from "../BasePageHeader/BasePageHeader";
import { Footer } from "../Footer/Footer";
import { Typography } from "../Typography/Typography";
import { SquareIcon } from "../SquareIcon/SquareIcon";
import { AppContext } from "../../App";
import { useQuery } from "@apollo/client";
import { GetCurrentUserInfoDocument } from "../../generated";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";

import { useLocation, useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { useData } from "../../hooks/dataContext";

export interface SideNavigationPageProps {
  breadcrumbs?: any[];
  workspaceContent?: React.ReactNode;
  content: React.ReactNode;
  verticalNav?: React.ReactNode;
  leftHeaderElements?: React.ReactNode;
  rightHeaderElements?: React.ReactNode;
  back?: boolean;
  innerSideBar?: boolean;
  className?: string;
  leftContainerProps?: any;
  rightContainerProps?: any;
}

export const SideNavigationPage = ({
  breadcrumbs,
  workspaceContent,
  content,
  verticalNav,
  leftHeaderElements,
  leftContainerProps,
  rightContainerProps,
  rightHeaderElements,
  back,
  innerSideBar,
  className,
}: SideNavigationPageProps) => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  return (
    <AppContext.Consumer>
      {({ showSidenav, setShowSidenav }) => (
        <Box
          sx={{
            background: palette.common.white,
            "&.sources-view": {
              background: "transparrent",
            },

            "&.full-width": {
              ".page-container": {
                paddingLeft: "0",
                paddingRight: "0",
                paddingBottom: "0",
              },
            },

            "&.bg-white": {
              ".content-holder": {
                background: palette.common.white,
              },
            },
          }}
          height="100%"
          display="flex"
          className={className}
          flexDirection="column"
        >
          <Box display="flex" flexGrow={1}>
            {verticalNav}
            <Box
              display="flex"
              flexDirection="column"
              flexGrow={1}
              bgcolor={palette.background.default}
              className="content-holder"
              sx={{
                height: "100%",
                overflow: "auto",
                transition: "all ease-in-out 0.25s",
                // paddingLeft: innerSideBar ? "47px" : "0",

                "@media (min-width: 1200px)": {
                  paddingLeft: innerSideBar
                    ? showSidenav && verticalNav
                      ? "0"
                      : "0"
                    : "0",
                },
              }}
            >
              <Box
                display="flex"
                flexDirection="row"
                pl={6}
                pr={6}
                pt="1.125rem"
                className="breadcrumbs"
                sx={{
                  ".arrow": {
                    display: "none",
                  },
                  ".breadcrumbs-item + .breadcrumbs-item .arrow": {
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginRight: "5px",
                    color: palette.gray.dark,
                  },
                  ".breadcrumbs-item.link": {
                    color: palette.link.main,
                    fontWeight: 700,
                  },
                  ".breadcrumbs-item.link p": {
                    color: palette.link.main,
                    fontWeight: 700,
                  },
                  ".breadcrumbs-item.link:hover": {
                    textDecoration: "underline",
                    cursor: "pointer",
                  },
                }}
              >
                {breadcrumbs?.map((breadcrumb) => (
                  <Box
                    key={breadcrumb.name}
                    alignItems="center"
                    ml={2}
                    mb=".375rem"
                    onClick={() =>
                      breadcrumb.redirectTo && navigate(breadcrumb.redirectTo)
                    }
                    display="flex"
                    className={`breadcrumbs-item ${
                      breadcrumb.redirectTo ? "link" : ""
                    }`}
                  >
                    <ChevronRightOutlinedIcon className="arrow" />
                    <SquareIcon
                      icon={breadcrumb.icon}
                      backgroundColor={palette.settings.main}
                      color={palette.settings.contrastText}
                      sx={{ mr: 2 }}
                      size="small"
                    />
                    <Typography variant="body3">{breadcrumb.name}</Typography>
                  </Box>
                ))}

                {back && (
                  <Box
                    display="flex"
                    alignItems="center"
                    onClick={() => navigate(-1)}
                  >
                    <IconButton size="small">
                      <ArrowBack fontSize="small" />
                    </IconButton>
                    <Typography fontWeight="bold" variant="body3">
                      Back
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                flexGrow={1}
                pl={6}
                pr={6}
                pb="1.125rem"
                className="page-container"
              >
                <Box alignItems="center" ml={2} mb=".375rem" display="flex">
                  {workspaceContent}
                  {/*{workspaceContent || (*/}
                  {/*  <>*/}
                  {/*    <SquareIcon*/}
                  {/*      icon={<GridView />}*/}
                  {/*      backgroundColor={palette.settings.main}*/}
                  {/*      color={palette.settings.contrastText}*/}
                  {/*      sx={{ mr: 2 }}*/}
                  {/*      size="small"*/}
                  {/*    />*/}
                  {/*    <Typography variant="body3">{workspaceName}</Typography>*/}
                  {/*  </>*/}
                  {/*  */}
                  {/*)}*/}
                </Box>
                <BasePageHeader
                  sideNav={!back}
                  expanded={showSidenav}
                  onClickToggleNav={() => setShowSidenav(!showSidenav)}
                  leftElements={leftHeaderElements}
                  rightElements={rightHeaderElements}
                  leftContainerProps={leftContainerProps}
                  rightContainerProps={rightContainerProps}
                />
                <Divider sx={{ mb: '32px', mt: 4 }} />
                <Box display="flex" flexDirection="column" flexGrow={1}>
                  {content}
                </Box>
              </Box>
              <Footer />
            </Box>
          </Box>
        </Box>
      )}
    </AppContext.Consumer>
  );
};
