import { useEffect, useState } from "react";
import { ActionBar } from "./ActionBar";
import { BasicCard } from "../../common/BasicCard/BasicCard";
import {
  Box,
  Dialog,
  Drawer,
  IconButton,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import { Close, Palette } from "@mui/icons-material";
import { SimpleLoader } from "../../common/Loader/Loader";
import { size } from "lodash";
import { CreateFolder } from "../AddResource/CreateFolder";
import { AddResource } from "../AddResource/AddResource";
import { useQuery } from "@apollo/client";
import {
  GetCurrentUserInfoDocument,
  TermOutput,
  TermsDocument,
} from "../../generated";
import { GridView } from "./GridView";
import { TermDetail } from "./TermDetail";

export const AllGrid = ({ data, loading }: { data: any; loading: boolean }) => {
  const [resource, setResource] = useState<string | boolean>("");
  const [cardView, setCardView] = useState(true);
  const [rowData, setRowData] = useState<any>(data);
  const [detail, setDetail] = useState<any>();

  const { data: userInfo } = useQuery(GetCurrentUserInfoDocument);

  const fullName = `${userInfo?.currentUser?.firstName || ""} ${
    userInfo?.currentUser?.lastName
  }`;
  const emailAddress = userInfo?.currentUser?.emailAddress;

  const closeDialog = () => {
    setResource(false);
  };

  useEffect(() => {
    setRowData(data);
  }, [data]);

  const { palette } = useTheme();

  return (
    <Box>
      <BasicCard
        sx={{
          background: "transparent",
          boxShadow: "none",
          border: "none",

          ".ag-theme-material .ag-header-cell": {
            borderRight: "none !important",
          },
        }}
      >
        <ActionBar
          resetRowData={() => setRowData(data)}
          setRowData={setRowData}
          data={data}
        />
        <Box className="ag-theme-material">
          {loading ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ margin: "8rem 0" }}
            >
              <SimpleLoader />
            </Box>
          ) : size(rowData) >= 1 ? (
            <GridView
              rowData={rowData}
              setDetail={setDetail}
              username={fullName}
              emailAddress={emailAddress}
            />
          ) : (
            <Typography
              mt={8}
              textAlign={"center"}
              sx={{
                display: "block",
                color: palette.gray.darkest,
                fontSize: "14px",
                lineHeight: "20px",
                fontWeight: "400",
              }}
            >
              No Record Found
            </Typography>
          )}
        </Box>
      </BasicCard>
      {/* <AddDetail data={detail} onClose={() => setDetail(undefined)} /> */}

      {detail && (
        <TermDetail data={detail} setDetail={setDetail} onDelete={() => {}} />
      )}
    </Box>
  );
};
