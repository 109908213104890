import React from "react";
import { Box, Dialog, useTheme } from "@mui/material";
import { Typography } from "../Typography/Typography";
import { Button } from "..//Button/Button";
import { Alert } from "../Alert";

interface ConfirmProps {
  message?: string;
  affirmLabel?: string;
  denyLabel?: string;
  additionalMessage?: string;
  assertMessage?: string;
  assert?: boolean;
  onYes: () => void;
  onNo: () => void;
}

export const Confirm = ({
  message,
  additionalMessage,
  assertMessage,
  affirmLabel,
  denyLabel,
  assert,
  onYes,
  onNo,
}: ConfirmProps) => {
  const { palette } = useTheme();
  const [asserting, setAssert] = React.useState(false);

  const ascertain = () => {
    setAssert(true);
  };

  return (
    <Dialog maxWidth="sm" open={true}>
      <Box width="560px" height="auto" p={6} boxSizing="border-box">
        <Typography mb={4} variant="h5" color={palette.common.black}>
          {message || "Are you sure"}
        </Typography>
        <Box mb={8}>
          {additionalMessage && (
            <Alert
              severity="warning"
              sx={{
                marginBottom: "16px",
              }}
            >
              {additionalMessage}
            </Alert>
          )}
        </Box>
        <Box mt={4} display="flex" alignItems={"center"}>
          <Button
            color="grayAccent"
            variant="outlined"
            onClick={onNo}
            sx={{ ml: "auto", mr: 3 }}
          >
            {denyLabel || "Cancel"}
          </Button>
          {assert && !asserting ? (
            <Button color="error" variant="contained" onClick={ascertain}>
              {affirmLabel || "Yes"}
            </Button>
          ) : (
            <Button color="error" variant="contained" onClick={onYes}>
              {assert ? assertMessage || "Are you sure?" : affirmLabel || "Yes"}
            </Button>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};
