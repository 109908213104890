import React from "react";
import {
  Typography as MUITypography,
  TypographyProps as MUITypographyProps,
} from "@mui/material";

export type TypographyProps = MUITypographyProps;

export const Typography = ({ children, variant, ...rest }: TypographyProps) => {
  let component = undefined;
  if (variant === "body3") {
    component = "p";
  } else if (variant === "subtitle3") {
    component = "h6";
  }

  return (
    <MUITypography
      variant={variant}
      //@ts-ignore
      component={component}
      {...rest}
    >
      {children}
    </MUITypography>
  );
};
