import React, { ReactNode } from "react";
import { Box, BoxProps, useTheme } from "@mui/material";
import TextField from "@mui/material/TextField";

import { Typography } from "../Typography/Typography";
import { Label } from "../Label/Label";
import { UpdateAssetPolicyInput } from "../../generated";

export interface AssetPolicyFormProps {
  policy: UpdateAssetPolicyInput;
  ctaBtn?: ReactNode;
  onUpdate: (_: UpdateAssetPolicyInput) => void;
}

export const AssetPolicyForm = ({
  policy,
  ctaBtn,
  onUpdate,
  ...rest
}: AssetPolicyFormProps & BoxProps) => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: palette.background.default,
        padding: "1rem",
        borderRadius: 4,
      }}
      {...rest}
    >
      <Typography mb={"1rem"}>Asset Policy</Typography>
      <Label value="Policy name" variant="body3" mb={1.5} />
      <TextField
        hiddenLabel
        fullWidth
        defaultValue={policy?.name}
        onChange={(e) =>
          onUpdate({
            ...policy,
            name: e.target.value,
          })
        }
        inputProps={{
          sx: {
            fontSize: "1rem",
            padding: ".375rem .75rem",
            background: palette.common.white,
          },
        }}
        size="small"
        sx={{ mb: 5 }}
      />
      <Label value="Policy description" variant="body3" mb={1.5} />
      <TextField
        hiddenLabel
        multiline
        fullWidth
        defaultValue={policy?.description}
        onChange={(e) =>
          onUpdate({
            ...policy,
            description: e.target.value,
          })
        }
        inputProps={{
          sx: { fontSize: "1rem", padding: "0 0.45rem" },
        }}
        size="small"
        sx={{ mb: 5, background: palette.common.white }}
      />
      <Label
        value="Link to policy documentation"
        optional
        variant="body3"
        mb={1.5}
      />
      <TextField
        hiddenLabel
        fullWidth
        defaultValue={policy?.href}
        onChange={(e) =>
          onUpdate({
            ...policy,
            href: e.target.value,
          })
        }
        inputProps={{
          sx: {
            fontSize: "1rem",
            padding: ".375rem .75rem",
            background: palette.common.white,
          },
        }}
        size="small"
        sx={{ mb: 2 }}
      />
      {ctaBtn}
    </Box>
  );
};
