import { Box, Button, List, styled, Typography } from "@mui/material";

export const FooterContainer = styled(Box)`
  position: sticky;
  bottom: 0px;
  width: 100%;
  background-color: white;
  paddingbottom: 24px;
  boxsizing: border-box;
`;

export const HeaderContainer = styled(Box)`
  position: sticky;
  padding: 0;
  top: -24px;
  backgroundcolor: white;
`;

export const HeaderSubContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "white",
  padding: "0.5rem 0",
  borderBottom: `1px solid ${theme.palette.outline}`,
}));

export const HeroTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.gray.dark,
  mb: 1,
  display: "flex",
  textTransform: "capitalize",
}));

export const IconButton = styled(Button)(({ theme }) => ({
  display: "flex",
  columnGap: "13px",
  alignItems: "center",
  borderRadius: "0",
  fontSize: "16px",
  lineHeight: "24px",
  padding: "8px 12px",
  minWidth: "inherit",
  justifyContent: "space-between",
  color: theme.palette.settings.contrastText,
  fontWeight: "400",

  "&:hover": {
    background: theme.palette.settings.lighter,
    color: theme.palette.common.black,
  },

  ".csb-dropdown-icn": {
    width: "13px",
    height: "13px",
  },

  ".icn": {
    width: "20px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: "0",
  },

  ".MuiSvgIcon-root": {
    width: "15px",
    height: "15px",
  },
}));

export const IconsList = styled(List)(() => ({
  display: "inline-flex",
  ".MuiListItem-root": {
    padding: "0",
  },
}));
