import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const TrippleVerticalDotsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="4"
      height="12"
      viewBox="0 0 4 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.00002 3.33335C2.73335 3.33335 3.33335 2.73335 3.33335 2.00002C3.33335 1.26669 2.73335 0.666687 2.00002 0.666687C1.26669 0.666687 0.666687 1.26669 0.666687 2.00002C0.666687 2.73335 1.26669 3.33335 2.00002 3.33335ZM2.00002 4.66669C1.26669 4.66669 0.666687 5.26669 0.666687 6.00002C0.666687 6.73335 1.26669 7.33335 2.00002 7.33335C2.73335 7.33335 3.33335 6.73335 3.33335 6.00002C3.33335 5.26669 2.73335 4.66669 2.00002 4.66669ZM2.00002 8.66669C1.26669 8.66669 0.666687 9.26669 0.666687 10C0.666687 10.7334 1.26669 11.3334 2.00002 11.3334C2.73335 11.3334 3.33335 10.7334 3.33335 10C3.33335 9.26669 2.73335 8.66669 2.00002 8.66669Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
