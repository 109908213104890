import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const SearchIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
      {...props}
    >
      <path
        d="M7.33334 0.666687C11.0152 0.666687 14 3.65146 14 7.33335C14 8.87352 13.4777 10.2917 12.6006 11.4205L17.0893 15.9108C17.4147 16.2362 17.4147 16.7638 17.0893 17.0893C16.7889 17.3897 16.3162 17.4128 15.9893 17.1586L15.9107 17.0893L11.4204 12.6006C10.2917 13.4777 8.87351 14 7.33334 14C3.65144 14 0.666672 11.0153 0.666672 7.33335C0.666672 3.65146 3.65144 0.666687 7.33334 0.666687ZM7.33334 2.33335C4.57191 2.33335 2.33334 4.57193 2.33334 7.33335C2.33334 10.0948 4.57191 12.3334 7.33334 12.3334C10.0948 12.3334 12.3333 10.0948 12.3333 7.33335C12.3333 4.57193 10.0948 2.33335 7.33334 2.33335Z"
        fill="black"
        fillOpacity="0.54"
      />
    </SvgIcon>
  );
};
