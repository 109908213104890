import React from "react";
import { Box, useTheme, Avatar } from "@mui/material";
import { stringAvatarLetters } from "../../helpers";

import { Typography } from "../Typography/Typography";

export interface AvatarLabelProps {
  label: string;
  size?: string;
}

export const AvatarLabel = ({ label, size }: AvatarLabelProps) => {
  const { palette } = useTheme();

  return (
    <Box display="flex">
      <Avatar
        sx={{
          color: palette.common.black,
          height: size === "small" ? "1.2rem" : "1.5rem",
          width: size === "small" ? "1.2rem" : "1.5rem",
          mr: 2,
          bgcolor: palette.warning.light,
          fontWeight: 700,
          fontSize: ".6rem",
          outline: `0.015625rem solid ${palette.scrim}`,
        }}
        alt={`${label}`}
      >
        {stringAvatarLetters(`${label}`)}
      </Avatar>
      <Typography
        variant={size === "small" ? "body3" : "body2"}
        sx={{ color: palette.gray.darkest }}
      >
        {`${label}`}{" "}
      </Typography>{" "}
    </Box>
  );
};
