import { Button, TextField, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import {
  CreateProjectGoalInput,
  UpdateProjectGoalInput,
} from "../../generated";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { BtnsWrap, ButtonDefault, ButtonPrimary } from "./style";

const ProjectOverviewGoalForm = ({
  index,
  goal,
  onUpdate,
  onDelete,
}: {
  index: number;
  goal: CreateProjectGoalInput;
  onUpdate: (goal: UpdateProjectGoalInput | CreateProjectGoalInput) => void;
  onDelete: () => void;
}) => {
  const { palette } = useTheme();
  const setMetrics = (index: number, newMetric: string) => {
    goal.metrics[index] = newMetric;
    onUpdate({ ...goal });
  };
  const addMetric = () => {
    onUpdate({
      ...goal,
      metrics: [...goal.metrics, ""],
    });
  };
  const deleteMetric = (index: number) => {
    goal.metrics.splice(index, 1);
    onUpdate({ ...goal });
  };
  return (
    <Box
      mb={4}
      sx={{
        background: palette.settings.lighter,
        borderRadius: "4px",
        padding: "20px",
      }}
    >
      <Typography
        variant="body1"
        mb={4}
        fontSize="1.25rem"
        fontWeight={400}
        color={palette.common.black}
      >
        Goal {index}
      </Typography>
      <Typography
        variant="body1"
        mb={1}
        fontSize="0.875rem"
        fontWeight={400}
        color={palette.common.black}
      >
        Description
      </Typography>
      <TextField
        value={goal.description}
        sx={{
          width: "100%",
          marginBottom: "24px",
          ".MuiInputBase-input": {
            width: "100%",
            height: "36px",
            fontSize: "16px",
            lineHeight: "20px",
            padding: "8px",
            boxSizing: "border-box",
            background: palette.common.white,
          },
          fieldset: {
            top: "0",
          },
          legend: {
            display: "none",
          },
        }}
        onChange={(e) =>
          onUpdate({
            ...goal,
            description: e.currentTarget.value,
          })
        }
      />
      {goal.metrics.map((metric, index) => (
        <>
          <Typography
            variant="body1"
            mb={1}
            fontSize="0.875rem"
            fontWeight={400}
            color={palette.common.black}
          >
            Success Metric {index + 1}
          </Typography>
          <Box display="flex" mb={4}>
            <TextField
              value={metric}
              sx={{
                width: "100%",
                ".MuiInputBase-input": {
                  width: "100%",
                  height: "36px",
                  fontSize: "16px",
                  lineHeight: "20px",
                  padding: "8px",
                  boxSizing: "border-box",
                  background: palette.common.white,
                },
                fieldset: {
                  top: "0",
                },
                legend: {
                  display: "none",
                },
              }}
              onChange={(e) => setMetrics(index, e.currentTarget.value)}
            />
            <Button
              onClick={() => deleteMetric(index)}
              sx={{
                color: palette.gray.dark,
                minWidth: "50px",
                marginLeft: "10px",
              }}
            >
              X
            </Button>
          </Box>
        </>
      ))}
      <BtnsWrap>
        <ButtonPrimary
          color="grayAccent"
          variant="contained"
          onClick={addMetric}
        >
          Add a metric
        </ButtonPrimary>
        <ButtonDefault color="error" onClick={onDelete}>
          <DeleteOutlinedIcon />
          Delete Goal
        </ButtonDefault>
      </BtnsWrap>
    </Box>
  );
};
export default ProjectOverviewGoalForm;
