import React, { useState } from "react";
import { Box } from "@mui/material";

import { Button } from "../../common/Button/Button";
import { PolicyOperationConfirmModal } from "../PolicyOperationConfirmModal";
import { CustomPolicyModal } from "./CustomPolicyModal";
import { useMutation } from "@apollo/client";
import {
  GetCustomPoliciesDocument,
  UpdateGovernanceDocument,
  UpdateCustomPolicyInput,
  WorkspaceRoleEnum,
} from "../../generated";
import { useParams } from "react-router-dom";
import { useGetUserRole } from "../../hooks/useGetUserRole";
import { ButtonPrimary } from "../GovernanceOverview/style";

export const AddCustomPolicyButton = () => {
  const { workspaceId } = useParams();
  const { role } = useGetUserRole(workspaceId);

  const [updateGovernance, { loading }] = useMutation(
    UpdateGovernanceDocument,
    {
      refetchQueries: [GetCustomPoliciesDocument],
    }
  );

  const [dialogOpen, setDialogOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [values, setValues] = useState<UpdateCustomPolicyInput | null>(null);

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const addAction = (data: any) => {
    setValues(data);
    setDialogOpen(false);
    setShowConfirm(true);
  };

  const handleSubmitAdd = () => {
    updateGovernance({
      variables: {
        input: {
          workspaceId: workspaceId || "",
          customPolicies: {
            create: values
              ? [
                {
                  ...values,
                  referenceDocuments:
                    values.referenceDocuments?.create?.map((item) => ({
                      name: item?.name || "",
                      href: item?.href || "",
                    })) || [],
                },
              ]
              : [],
            update: [],
            delete: [],
          },
        },
      },
    }).then((res) => {
      setShowConfirm(false);
    });
  };

  return (
    <Box>
      {(role === WorkspaceRoleEnum.Admin || role === WorkspaceRoleEnum.Collaborator) &&
        <ButtonPrimary
          sx={{ ml: 3 }}
          onClick={openDialog}
          variant="contained"
          color="primary"
        >
          Add custom policy
        </ButtonPrimary>
      }
      <CustomPolicyModal
        show={dialogOpen}
        closeDialog={closeDialog}
        submit={addAction}
      />
      <PolicyOperationConfirmModal
        show={showConfirm}
        operation={"add"}
        handleClose={() => setShowConfirm(false)}
        loading={loading}
        handleSubmit={handleSubmitAdd}
      />
    </Box>
  );
};
