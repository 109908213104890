import React, { useState } from "react";
import { Button } from "../../common/Button/Button";
import CloseIcon from "@mui/icons-material/Close";
import {
  alpha,
  Box,
  Dialog,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  DisableUserDocument,
  GetWorkspaceMembersDocument,
} from "../../generated";
import { BanIcon } from "../../common/Icons/BanIcon";
export interface DisableMemberCellRendererProps {
  userId: string;
  removeMember: () => void;
}

export const DisableMemberCellRenderer = ({
  userId,
  removeMember,
}: DisableMemberCellRendererProps) => {
  const [mutate, { loading }] = useMutation(DisableUserDocument, {
    refetchQueries: [GetWorkspaceMembersDocument],
  });
  const { workspaceId } = useParams();
  const [dialogOpen, setDialogOpen] = useState(false);
  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };
  const onClickRemoveMember = async () => {
    await mutate({
      variables: {
        user: {
          workspaceId: workspaceId || "",
          userId,
        },
      },
    });
    if (removeMember) removeMember();
    closeDialog();
  };
  const { palette } = useTheme();

  return (
    <>
      <Tooltip
        placement="bottom"
        title="Disable User"
        componentsProps={{
          tooltip: { sx: { backgroundColor: palette.common.black } },
          arrow: { sx: { color: palette.common.black } },
        }}
        arrow
      >
        <IconButton
          onClick={openDialog}
          color="warning"
          sx={{
            display: "inline-flex",
            minWidth: "inherit",
            padding: "5px 8px",
            margin: "0 2px",
          }}
        >
          <BanIcon />
        </IconButton>
      </Tooltip>

      <Dialog open={dialogOpen} onClose={closeDialog}>
        <Box width="35rem" p={6} boxSizing="border-box">
          <Typography mb={4} variant="h5">
            Disable User
          </Typography>
          <Typography color={palette.gray.dark} mb={4} variant="body2">
            By disabling this user they will no longer have access to the
            workspace.
          </Typography>
          <Box display="flex" mt={7} justifyContent="flex-end">
            <Button
              onClick={closeDialog}
              color="grayAccent"
              variant="outlined"
              sx={{ mr: 3 }}
            >
              Cancel
            </Button>
            <Button
              onClick={onClickRemoveMember}
              color="error"
              variant="contained"
              disabled={loading}
            >
              Disable
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
