import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { Chip } from "../../common/Chip/Chip";
import { TransformationDetails } from "./TransformationDetails";
import { Button } from "../../common/Button/Button";
import { GetTransformationDocument } from "../../generated";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { convertDateTime } from "../../helpers";
import {
  getIssueIcon,
  getIssueColor,
  getLabelIssueStatus,
  getLoader,
} from "../helpers";
import { EditTransformationDetails } from "./EditTransformationDetails";
import { SheetTabs } from "../SheetTabs";
import { SheetChat } from "../SheetChat";

export const Transformation = ({
  id,
  showToast,
}: {
  id: string;
  showToast: any;
}) => {
  const { palette } = useTheme();
  const [show, setShow] = useState(false);
  const [tab, setTab] = useState(0);
  const { workspaceId, projectId } = useParams();
  const { data, loading } = useQuery(GetTransformationDocument, {
    variables: {
      workflowFilterInput: {
        projectId: projectId || "",
        workspaceId: workspaceId || "",
      },
      transformationFilter: {
        transformationId: id,
      },
    },
  });
  const [isEditState, setIsEditState] = useState(false);
  const [isEditable, setIsEditable] = useState(true);

  const toggleEditView = () => {
    setIsEditState(!isEditState);
    setIsEditable(!isEditable);
  };

  if (loading) return getLoader("Fetching Data", "custom-loader full-height");
  const transformationData = data?.workflow?.transformations?.[0];
  if (transformationData) {
    return (
      <Box
        boxSizing="border-box"
        sx={{
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            paddingRight: "20px",
            paddingBottom: "16px",
            borderBottom: `1px solid ${palette.outline}`,
          }}
          mb={6}
        >
          <Typography
            mb={2}
            variant="h5"
            color={palette.gray.dark}
            sx={{
              fontSize: "12px",
              lineHeight: "14px",
              textTransform: "uppercase",
              fontWeight: "700",
            }}
          >
            Transformation
          </Typography>
          <Typography
            mb={4}
            variant="h5"
            color={palette.common.black}
            sx={{ wordBreak: "break-word" }}
          >
            {transformationData.name}
          </Typography>
          <Box display="flex" alignItems="center">
            {show && (
              <Chip
                icon={getIssueIcon(transformationData.issueStatus)}
                color={getIssueColor(transformationData.issueStatus)}
                label={getLabelIssueStatus(transformationData.issueStatus)}
                sx={{
                  color: palette.common.black,
                  fontSize: "12px",
                  lineHeight: "16px",
                  fontWeight: "500",
                }}
              />
            )}
            {show && transformationData.job?.startedAt && (
              <Typography
                mb={0}
                ml={2}
                variant="body3"
                color={palette.gray.darkest}
                sx={{ wordBreak: "break-word" }}
              >
                Last job run{" "}
                {convertDateTime(transformationData.job?.startedAt)}
              </Typography>
            )}
          </Box>
          <SheetTabs value={tab} onChange={setTab} />
        </Box>
        {tab === 0 && (
          <Box
            sx={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {isEditState ? (
              <EditTransformationDetails
                transformation={transformationData}
                toggleEditView={toggleEditView}
                showToast={showToast}
              />
            ) : (
              <TransformationDetails
                issueType={transformationData.issueStatus}
                transformation={transformationData}
              />
            )}
            {isEditable && !isEditState && (
              <Box>
                <Button
                  color="grayAccent"
                  variant="contained"
                  sx={{ width: "100%" }}
                  onClick={toggleEditView}
                >
                  Edit
                </Button>
              </Box>
            )}
          </Box>
        )}
        {tab === 1 && <SheetChat id={id} />}
      </Box>
    );
  }
  return null;
};
