import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const BrighthiveIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      fill="none"
      viewBox="0 0 27 27"
      {...props}
    >
      <g clip-path="url(#clip0_253_35114)">
        <path
          d="M18.19 23.3959V15.7819L9.80978 20.6185V23.3959C9.80994 23.8196 9.64498 24.2266 9.34992 24.5306C9.05489 24.8346 8.65295 25.0117 8.22949 25.0241H19.8181C19.3863 25.0241 18.9722 24.8526 18.6668 24.5472C18.3615 24.2419 18.19 23.8277 18.19 23.3959Z"
          fill="#A1D2AB"
        />
        <path
          d="M9.80934 3.28333V10.8974L18.1895 6.06078V3.28333C18.1895 2.85152 18.361 2.4374 18.6664 2.13206C18.9717 1.82673 19.3859 1.65518 19.8176 1.65518H8.18115C8.61297 1.65518 9.02709 1.82673 9.33243 2.13206C9.63773 2.4374 9.80934 2.85152 9.80934 3.28333Z"
          fill="#FEBE2D"
        />
        <path
          d="M20.6321 4.67181L18.1899 6.06054V15.7816L24.8462 11.9506C25.2156 11.7396 25.653 11.6816 26.0647 11.789C26.4764 11.8964 26.8297 12.1607 27.049 12.5253L21.2068 2.46902C21.3177 2.65032 21.3909 2.85212 21.422 3.06237C21.4531 3.2726 21.4415 3.48695 21.3879 3.69261C21.3342 3.89825 21.2397 4.09098 21.1098 4.25923C20.9799 4.42748 20.8175 4.5678 20.6321 4.67181Z"
          fill="#5BCBF5"
        />
        <path
          d="M27.049 14.1058C27.1857 13.8743 27.2521 13.6082 27.2405 13.3396C27.251 13.056 27.1848 12.7748 27.049 12.5255C26.8297 12.1609 26.4764 11.8967 26.0647 11.7893C25.653 11.6819 25.2156 11.7399 24.8462 11.9509L18.1899 15.7819V23.3959C18.1899 23.8277 18.3615 24.2418 18.6668 24.5472C18.9721 24.8525 19.3863 25.024 19.8181 25.024H20.2491L20.6801 24.8324L20.9674 24.641V24.5452L21.1589 24.3057L25.8039 16.2129L27.0011 14.2016L27.049 14.1058Z"
          fill="#15B9A8"
        />
        <path
          d="M18.1899 3.28311V6.06056L20.6321 4.67183C20.8175 4.56782 20.9799 4.4275 21.1098 4.25925C21.2397 4.091 21.3342 3.89827 21.3879 3.69263C21.4415 3.48697 21.4531 3.27262 21.422 3.06239C21.3909 2.85214 21.3177 2.65034 21.2068 2.46904C21.1552 2.3396 21.0731 2.22457 20.9674 2.13383C20.6647 1.8611 20.2733 1.7079 19.866 1.70285H19.8181C19.3944 1.70267 18.9874 1.86761 18.6834 2.16266C18.3794 2.45772 18.2023 2.85965 18.1899 3.28311Z"
          fill="#89A150"
        />
        <path
          d="M7.36749 22.007L9.80971 20.6183V10.8972L3.20133 14.7281C2.83188 14.9392 2.3945 14.9972 1.98281 14.8899C1.57111 14.7824 1.21782 14.5181 0.998535 14.1535L6.79285 24.2098C6.58182 23.8403 6.5238 23.4029 6.63119 22.9912C6.7386 22.5796 7.00288 22.2263 7.36749 22.007Z"
          fill="#F6A7C0"
        />
        <path
          d="M9.80937 23.396V20.6185L7.36715 22.0073C7.00254 22.2265 6.73825 22.5798 6.63086 22.9915C6.52345 23.4032 6.58148 23.8406 6.7925 24.21L7.03194 24.5453H7.07983C7.22571 24.6864 7.3982 24.7973 7.58727 24.8713C7.77635 24.9452 7.97825 24.9809 8.18123 24.9763H8.22912C8.64442 24.9642 9.0394 24.7938 9.3332 24.5C9.62694 24.2063 9.7973 23.8112 9.80937 23.396Z"
          fill="#A79087"
        />
        <path
          d="M3.20102 14.7283L9.80943 10.8973V3.28333C9.80943 2.85152 9.6379 2.4374 9.33252 2.13206C9.0272 1.82673 8.61307 1.65518 8.18126 1.65518H7.75027C7.60975 1.68929 7.47878 1.75478 7.36717 1.84673L7.07986 2.03827C7.03197 2.08616 7.03197 2.08616 7.03197 2.13405L6.84043 2.37349H6.79254L2.14751 10.4185L0.950334 12.4297C0.836475 12.6699 0.771374 12.9304 0.758789 13.1959C0.765241 13.4837 0.847859 13.7646 0.998224 14.01C1.09466 14.2027 1.22841 14.3744 1.39171 14.5151C1.55501 14.6558 1.74462 14.7626 1.94953 14.8294C2.15445 14.8963 2.37058 14.9217 2.58541 14.9043C2.80024 14.887 3.00949 14.8271 3.20102 14.7283Z"
          fill="#F5843C"
        />
      </g>
      <defs>
        <clipPath id="clip0_253_35114">
          <rect
            width="26.4828"
            height="26.4828"
            fill="white"
            transform="translate(0.758789)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
