import { FormHelperText, styled, TextareaAutosize } from "@mui/material";

export const StyledTextAreaAutosize = styled(TextareaAutosize)`
  min-height: 100px;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  padding: 8px;
  height: 116px;
  box-sizing: border-box;
  resize: vertical;
  overflow: auto;
`;

export const StyledFormHelperText = styled(FormHelperText)`
  margin-left: 14px;
`;
