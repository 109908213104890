import { styled, Box, Button, Typography, Popover, Tab, Tabs } from "@mui/material";
// import { FilterButton } from "../FilterButton/FilterButton";
import { brighthiveTheme } from "../../theme/theme";

export const ActionBarWrapper = styled(`div`)`
  background-color: ${brighthiveTheme.palette.common.white};
  border: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
  margin-bottom: 32px;
  border-radius: 6px;
`;

export const ButtonDefault = styled(Button)`
  color: ${brighthiveTheme.palette.gray.dark};
  border-radius: 3px;
  border: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1px;

  &.small {
    width: 36px;
    height: 36px;
    padding: 0;
  }

  &.icn-large {
    svg {
      width: 26px;
      height: 26px;
    }
  }

  &.active,
  &:hover {
    border-color: ${brighthiveTheme.palette.primary.dark};
    background-color: ${brighthiveTheme.palette.primary.active};
    color: ${brighthiveTheme.palette.primary.dark};
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const FilterLabel = styled(Typography)`
  color: ${brighthiveTheme.palette.gray.dark};
`;

// export const FilterDropdownButton = styled(FilterButton)`
//   border-color: ${brighthiveTheme.palette.action.disabledBackground};
//   background-color: transparent;
//   color: ${brighthiveTheme.palette.gray.dark};
//   margin-right: 12px;

//   &:hover{
//     border-color: ${brighthiveTheme.palette.primary.dark};
//     background-color: ${brighthiveTheme.palette.primary.active};
//     color: ${brighthiveTheme.palette.primary.dark};
//   }
// `;

export const ButtonPrimary = styled(Button)`
  background-color: ${brighthiveTheme.palette.primary.main};
  color: ${brighthiveTheme.palette.primary.darkest};
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: none;
  min-width: 1px;

  &:hover {
    background-color: ${brighthiveTheme.palette.primary.dark};
    color: ${brighthiveTheme.palette.common.white};
    border-color: transparent;
    box-shadow: none;
  }
`;

export const ActionButton = styled(Button)`
  display: flex;
  column-gap: 3px;
  align-items: center;
  border-radius: 4px;

  &:hover {
    background: ${brighthiveTheme.palette.primary.lighter},
    color: ${brighthiveTheme.palette.primary.main},
  }

  .csb-dropdown-icn {
    width: 13px;
    height: 13px;
  }
`;

export const PopoverBtnsWrap = styled(Box)`
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const PopoverItemButton = styled(Button)`
  color: ${brighthiveTheme.palette.gray.dark};
  border-radius: 3px;
  border: 0;
  min-width: 1px;

  &.active,
  &:hover {
    border: 0;
    background-color: ${brighthiveTheme.palette.primary.active};
    color: ${brighthiveTheme.palette.primary.dark};
  }
`;

export const ButtonDropdown = styled(Popover)`
  .MuiPaper-root {
    background: ${brighthiveTheme.palette.common.white};
    border: 0;
    box-shadow: 0 2px 2px ${brighthiveTheme.palette.gray.main},
      0 3px 1px ${brighthiveTheme.palette.action.disabledBackground},
      0 1px 5px ${brighthiveTheme.palette.action.disabled};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 8px;
  }
`;

export const TabsWrapper = styled(Tabs)`
  margin-bottom: 32px;

  .MuiTabs-indicator{
    background-color: ${brighthiveTheme.palette.primary.dark};
  }
`;

export const TabButton = styled(Tab)`
  font-size: 16px;
  line-height: 1.42;
  padding: 12px 24px !important;
  min-width: 1px;
  color: ${brighthiveTheme.palette.gray.dark};
  background-color: transparent;
  border-radius: 4px 4px 0 0;

  &.Mui-selected,
  &:hover{
    background-color: transparent !important;
    color: ${brighthiveTheme.palette.primary.dark};
  }
`;