import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const CaretUpViiIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.49764 12.2777L10.731 9.0444L13.9643 12.2777C14.2893 12.6027 14.8143 12.6027 15.1393 12.2777C15.4643 11.9527 15.4643 11.4277 15.1393 11.1027L11.3143 7.27773C10.9893 6.95273 10.4643 6.95273 10.1393 7.27773L6.31431 11.1027C5.98931 11.4277 5.98931 11.9527 6.31431 12.2777C6.63931 12.5944 7.17264 12.6027 7.49764 12.2777Z" fill="currentColor"/>
    </SvgIcon>
  );
};
