import { BrighthiveIcon } from "../../common/Icons/BrighthiveIcon";
import { OffPlatformIcon } from "../../common/Icons/OffPlatformIcon";

interface LocationCellProps {
  value: string;
}

export const LocationCell = ({ value }: LocationCellProps) => {
  if (value === "Off-platform") {
    return <OffPlatformIcon />;
  }

  return <BrighthiveIcon width="30" height="23" />;
};
