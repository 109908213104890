import React, { useState } from "react";
import { IconButton, useTheme, Dialog, Box } from "@mui/material";
import { Menu } from "../../common/Menu/Menu";
import { MenuItem } from "../../common/Menu/MenuItem";
import { MoreHoriz } from "@mui/icons-material";

import {
  GetTargetSchemasDocument,
  RemoveTargetSchemaFromProjectDocument,
} from "../../generated";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../common/Button/Button";
import { Typography } from "../../common/Typography/Typography";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

export interface ActionsCellRendererProps {
  id: string;
  rowIndex: number;
  isEditable: boolean;
}

export const ActionsCellRenderer = ({
  id,
  rowIndex,
  isEditable,
}: ActionsCellRendererProps) => {
  const navigate = useNavigate();
  const { projectId, workspaceId } = useParams();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { palette } = useTheme();

  const onClickUpdate = () => {
    navigate(
      `/workspace/${workspaceId}/project/${projectId}/project-schema/edit/${id}`
    );
  };

  const openDialog = () => {
    setDialogOpen(true);
    handleClose();
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const [mutateDelete, { loading }] = useMutation(
    RemoveTargetSchemaFromProjectDocument,
    {
      refetchQueries: [GetTargetSchemasDocument],
    }
  );
  const onClickRemoveField = () => {
    mutateDelete({
      variables: {
        input: {
          targetSchemaId: id,
          projectId: projectId || "",
        },
      },
    })
      .then((res) => {
        toast.success("Success remove data");
        closeDialog();
      })
      .catch((err) => {
        toast.error("Failed");
      });
  };
  return (
    <>
      <IconButton
        sx={{
          color: palette.gray.dark,
        }}
        id={`icon-button-${rowIndex}`}
        onClick={handleClick}
        aria-controls={open ? `action-menu-${rowIndex}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id={`action-menu-${rowIndex}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": `icon-button-${rowIndex}`,
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
      >
        <MenuItem disabled={!isEditable} onClick={onClickUpdate}>
          Update
        </MenuItem>
        <MenuItem disabled={!isEditable} onClick={openDialog}>
          Remove
        </MenuItem>
      </Menu>
      <Dialog open={dialogOpen} onClose={closeDialog}>
        <Box width="35rem" p={6} boxSizing="border-box">
          <Typography mb={4} variant="h5">
            Remove Schema
          </Typography>

          <Box display="flex" mt={7} justifyContent="flex-end">
            <Button
              onClick={closeDialog}
              color="grayAccent"
              variant="outlined"
              sx={{ mr: 3 }}
            >
              Cancel
            </Button>
            <Button
              onClick={onClickRemoveField}
              disabled={loading}
              color="error"
              variant="contained"
            >
              Remove
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
