import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const DataAssetLibraryIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 18C21 18.777 20.5855 19.4156 20.0622 19.8894C19.5385 20.3634 18.8276 20.7503 18.008 21.0576C16.3656 21.6736 14.205 22 12 22C9.79501 22 7.63443 21.6736 5.99202 21.0576C5.17237 20.7503 4.46146 20.3634 3.93782 19.8894C3.41454 19.4156 3 18.777 3 18V6L3.001 5.987L3.00891 5.80257C3.07354 5.09081 3.48147 4.51295 3.96527 4.08401C4.49422 3.61504 5.20256 3.2384 5.99202 2.94235C7.57833 2.34749 9.70269 2 12 2C14.2973 2 16.4217 2.34749 18.008 2.94235C18.7974 3.2384 19.5058 3.61504 20.0347 4.08401C20.5547 4.54505 20.9871 5.17814 20.9997 5.96462C20.9998 5.97645 21 5.9882 21 6V18ZM18.008 15.0576C16.3656 15.6736 14.205 16 12 16C9.79501 16 7.63443 15.6736 5.99202 15.0576C5.63882 14.9252 5.3058 14.778 4.99927 14.6154L5 18C5 18.0187 5.00689 18.1594 5.28011 18.4067C5.55297 18.6538 6.01363 18.9298 6.69427 19.185C8.05244 19.6943 9.96153 20 12 20C14.0385 20 15.9476 19.6943 17.3057 19.185C17.9864 18.9298 18.447 18.6538 18.7199 18.4067C18.9931 18.1594 19 18.0187 19 18L19.0017 14.6149C18.6949 14.7777 18.3616 14.925 18.008 15.0576ZM18.008 9.05765C16.4217 9.65251 14.2973 10 12 10C9.70269 10 7.57833 9.65251 5.99202 9.05765C5.64232 8.92651 5.30853 8.77956 4.9992 8.61577L5 12C5 12.0187 5.00689 12.1594 5.28011 12.4067C5.55297 12.6538 6.01363 12.9298 6.69427 13.185C8.05244 13.6943 9.96153 14 12 14C14.0385 14 15.9476 13.6943 17.3057 13.185C17.9864 12.9298 18.447 12.6538 18.7199 12.4067C18.9931 12.1594 19 12.0187 19 12L19.0018 8.61524C18.6922 8.77924 18.3581 8.92637 18.008 9.05765ZM12 4C9.87903 4 8.0034 4.32409 6.69427 4.81501C6.03602 5.06185 5.57279 5.33166 5.29209 5.58052C5.04154 5.80265 5.00562 5.94115 5.00074 5.98712L5 6C5 6.02553 5.01022 6.16958 5.29209 6.41948C5.57279 6.66834 6.03602 6.93815 6.69427 7.18499C8.0034 7.67591 9.87903 8 12 8C14.121 8 15.9966 7.67591 17.3057 7.18499C17.964 6.93815 18.4272 6.66834 18.7079 6.41948C18.9898 6.16958 19 6.02553 19 6C19 5.97447 18.9898 5.83042 18.7079 5.58052C18.4272 5.33166 17.964 5.06185 17.3057 4.81501C15.9966 4.32409 14.121 4 12 4Z"
        fill="currentColor"
        fillOpacity="1"
      />
    </SvgIcon>
  );
};
