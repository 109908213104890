import React, { useState } from "react";
import {
  Box,
  useTheme,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Autocomplete,
  TextareaAutosize,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Grid,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import {
  CreateDestinationDocument,
  DestinationType,
  DownloadFileFormat,
  GetOrganizationMembersDocument,
  GetOrganizationsDocument,
  WarehouseServiceProvider,
} from "../../generated";
import { useParams } from "react-router-dom";
import { DestinationForm } from "./DestinationForm/DestinationForm";
export const CreateDestination = ({
  toggleRightSidePanel,
  showToast,
}: {
  toggleRightSidePanel?: any;
  showToast?: any;
}) => {
  const { palette } = useTheme();
  const { projectId, workspaceId } = useParams();
  const [mutate, { loading }] = useMutation(CreateDestinationDocument);
  const { data: organization } = useQuery(GetOrganizationMembersDocument);

  return (
    <Formik
      validationSchema={Yup.object().shape({
        name: Yup.string().required(),
        description: Yup.string().required(),
        method: Yup.string().required(),
        // destinationURL: Yup.string().required(),
        // access: Yup.string().required(),
        // format: Yup.string().required(),
        // template: Yup.string().required(),
        // viewURL: Yup.string().required(),
        // apiURL: Yup.string().required(),
        // category: Yup.string().required(),
      })}
      initialValues={{
        name: "",
        description: "",
        method: DestinationType.Download,
        destinationUrl: "",
        format: DownloadFileFormat.Csv,
        dataStorage: WarehouseServiceProvider.Redshift,
        tableName: "",
        // template: "",
        // viewURL: "",
        // apiURL: "",
        // category: "",
      }}
      onSubmit={async (values) => {
        const { errors } = await mutate({
          variables: {
            input: {
              destinationType: values.method,
              description: values.description,
              dataStorage: values.dataStorage,

              tableName: values.tableName,
              name: values.name,
              // destinationUrl: values.destinationUrl,
              projectId: projectId || "",
              workspaceId: workspaceId || "",
              organizationId: organization?.currentUser?.organization?.id || "",
              ...(values.method === DestinationType.Download && {
                fileFormat: values.format,
              }),
            },
          },
          refetchQueries: ["getWorkflow"],
        });

        if (errors)
          showToast(
            "Unabele to add destination due to following error \n" + errors
          );
        else {
          // toast.success("Destination added");
          toggleRightSidePanel(false);
          showToast("Destination added");
        }
      }}
    >
      {({ values, errors, touched, handleChange, handleSubmit }) => (
        <DestinationForm
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          disabled={loading}
        />
      )}
    </Formik>
  );
};
