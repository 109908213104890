import { Button } from "../../common/Button/Button";
import CloseIcon from "@mui/icons-material/Close";
import { TargetSchema } from "../../generated";
import RemoveFieldDialog from "./RemoveFieldDialog";
import useRemoveFieldRenderer from "./hooks/useRemoveFieldRenderer";

export interface RemoveFieldCellRendererProps {
  id: string;
  removeField: () => void;
  targetSchema?: TargetSchema;
}

export const RemoveFieldCellRenderer = ({
  id,
  removeField,
  targetSchema,
}: RemoveFieldCellRendererProps) => {
  const { openDialog, dialogOpen, closeDialog, onClickRemoveField } =
    useRemoveFieldRenderer({ id, removeField, targetSchema });

  return (
    <>
      <Button
        onClick={openDialog}
        color="grayAccent"
        variant="text"
        startIcon={<CloseIcon />}
      >
        Remove
      </Button>
      <RemoveFieldDialog
        dialogOpen={dialogOpen}
        closeDialog={closeDialog}
        onClickRemoveField={onClickRemoveField}
      />
    </>
  );
};
