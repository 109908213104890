import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const DocumentsListIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33333 3.99999C5.33333 3.63181 5.63181 3.33333 6 3.33333H13.3333C13.7015 3.33333 14 3.63181 14 3.99999C14 4.36818 13.7015 4.66666 13.3333 4.66666H6C5.63181 4.66666 5.33333 4.36818 5.33333 3.99999Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 8C8 7.63181 8.29848 7.33333 8.66667 7.33333H13.3333C13.7015 7.33333 14 7.63181 14 8C14 8.36819 13.7015 8.66666 13.3333 8.66666H8.66667C8.29848 8.66666 8 8.36819 8 8Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33333 12C5.33333 11.6318 5.63181 11.3333 6 11.3333H13.3333C13.7015 11.3333 14 11.6318 14 12C14 12.3682 13.7015 12.6667 13.3333 12.6667H6C5.63181 12.6667 5.33333 12.3682 5.33333 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.19526 4.86192C2.45561 4.60157 2.87772 4.60157 3.13807 4.86192L5.80474 7.52859C6.06509 7.78894 6.06509 8.21105 5.80474 8.4714L3.13807 11.1381C2.87772 11.3984 2.45561 11.3984 2.19526 11.1381C1.93491 10.8777 1.93491 10.4556 2.19526 10.1953L4.39052 8L2.19526 5.80473C1.93491 5.54438 1.93491 5.12227 2.19526 4.86192Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
