import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const HomeViiIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="30"
      height="29"
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.3829 5.5293L7.3072 13.7848L7.41492 13.8309C7.50233 13.8746 7.58375 13.9289 7.65761 13.992C7.664 13.9975 7.67053 14.0032 7.677 14.009C7.70612 14.035 7.73389 14.0623 7.76032 14.0908C7.76912 14.1003 7.77838 14.1107 7.78746 14.1212C7.80923 14.1463 7.83007 14.1725 7.84985 14.1996C7.85857 14.2115 7.8672 14.2238 7.87561 14.2362C7.89398 14.2634 7.91116 14.2911 7.92725 14.3195C7.93603 14.3351 7.94464 14.3511 7.9529 14.3673C7.96728 14.3956 7.98064 14.4245 7.99289 14.4541C7.99889 14.4685 8.00458 14.483 8.00999 14.4976C8.02035 14.5255 8.02976 14.554 8.03814 14.583C8.04406 14.6034 8.04947 14.6241 8.05436 14.6449C8.06528 14.6915 8.07359 14.7392 8.07912 14.7877L8.08719 14.9305L8.18289 23.3811C8.18289 24.0088 8.64614 24.5261 9.24294 24.5968L9.38289 24.6051H21.3829C21.9983 24.6051 22.5055 24.1326 22.5748 23.5238L22.5829 23.3811L22.6544 14.9103C22.6544 14.8075 22.6668 14.7076 22.6902 14.6122C22.6949 14.5931 22.6999 14.5746 22.7053 14.5563C22.7131 14.5298 22.7219 14.5034 22.7316 14.4774C22.7393 14.4567 22.7475 14.4361 22.7563 14.4159C22.7674 14.3902 22.7793 14.3653 22.7918 14.3409C22.7996 14.3259 22.8078 14.3108 22.8163 14.2958C22.8356 14.262 22.8564 14.2293 22.8786 14.1977C22.8827 14.1919 22.8871 14.1856 22.8917 14.1794C22.9169 14.1449 22.9439 14.1118 22.9725 14.0802C22.9755 14.0769 22.9788 14.0732 22.9822 14.0696C23.0081 14.0417 23.0351 14.0152 23.0632 13.99C23.0716 13.9824 23.0798 13.9753 23.0881 13.9683C23.1605 13.9069 23.2407 13.8537 23.3266 13.8107L23.4332 13.7646L15.3829 5.5293Z" fill="currentColor" fillOpacity="0.16"/>
      <path d="M16.1178 3.13055L16.2308 3.23038L27.0308 14.0304C27.7539 14.7535 27.2955 15.9675 26.3186 16.0717L26.1823 16.0789H24.9823L24.9823 23.2789C24.9823 25.1961 23.4836 26.7633 21.5938 26.8728L21.3823 26.8789H9.38229C7.46507 26.8789 5.8979 25.3802 5.7884 23.4904L5.78229 23.2789L5.78228 16.0789H4.58229C3.55968 16.0789 3.02537 14.8963 3.64242 14.1319L3.73376 14.0304L14.5338 3.23038C14.9663 2.7978 15.647 2.76452 16.1178 3.13055ZM15.3823 5.77691L7.40229 13.7557L7.51002 13.8009C7.59742 13.8437 7.67884 13.8969 7.7527 13.9588C7.7591 13.9642 7.76563 13.9698 7.7721 13.9754C7.80122 14.0009 7.82898 14.0277 7.85542 14.0557C7.86421 14.065 7.87347 14.0752 7.88256 14.0855C7.90432 14.1101 7.92517 14.1358 7.94494 14.1623C7.95366 14.174 7.96229 14.1861 7.9707 14.1982C7.98907 14.2249 8.00625 14.2521 8.02234 14.2799C8.03112 14.2951 8.03973 14.3108 8.048 14.3268C8.06238 14.3545 8.07573 14.3828 8.08799 14.4118C8.09399 14.426 8.09967 14.4402 8.10509 14.4545C8.11544 14.4819 8.12485 14.5098 8.13324 14.5382C8.13916 14.5582 8.14457 14.5785 8.14945 14.5989C8.16037 14.6446 8.16869 14.6914 8.17422 14.739L8.18229 14.8789V23.2789C8.18229 23.8943 8.64554 24.4015 9.24234 24.4708L9.38229 24.4789H21.3823C21.9977 24.4789 22.5049 24.0157 22.5742 23.4189L22.5823 23.2789V14.8789C22.5823 14.7781 22.5947 14.6802 22.6181 14.5866C22.6228 14.5679 22.6278 14.5498 22.6332 14.5319C22.6411 14.5059 22.6499 14.48 22.6595 14.4544C22.6672 14.4342 22.6754 14.4141 22.6842 14.3942C22.6954 14.369 22.7072 14.3446 22.7198 14.3206C22.7275 14.306 22.7357 14.2911 22.7443 14.2765C22.7635 14.2433 22.7843 14.2113 22.8065 14.1803C22.8106 14.1746 22.8151 14.1684 22.8196 14.1624C22.8449 14.1285 22.8718 14.096 22.9004 14.0651C22.9034 14.0618 22.9068 14.0582 22.9101 14.0547C22.936 14.0273 22.963 14.0013 22.9912 13.9766C22.9996 13.9692 23.0077 13.9622 23.016 13.9554C23.0885 13.8952 23.1686 13.843 23.2546 13.8009L23.3611 13.7557L15.3823 5.77691Z" fill="currentColor"/>
    </SvgIcon>
  );
};
