import { alpha, styled, Tabs as MUITabs, TabsProps as MUITabProps, } from "@mui/material";
import { typography } from "../../theme/typography";

export type TabsProps = MUITabProps;

export const Tabs = styled(MUITabs)<TabsProps>(({ theme, indicatorColor }) => ({
  ".MuiTab-root:focus-visible": {
    ...(indicatorColor !== undefined && {
      border: `.1875rem solid ${theme.palette[indicatorColor].main}`,
      padding: ".5625rem 1.8125rem",
    }),
  },
  ".MuiTab-root": {
    ...typography.body2,
    padding: ".75rem 2rem",
  },
  ".MuiTab-root:hover": {
    backgroundColor: alpha(theme.palette.grayAccent.main, 0.06),
  },
}));
