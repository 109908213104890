import "ag-grid-enterprise";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-material.css";
import React, { useEffect, useMemo, useState } from "react";
import {
  booleanColumn,
  dateColumn,
  numberColumn,
  tagsColumn,
  textColumn,
} from "./gridHelpers";
import { AGGridReactStyled } from "./AGGridReactStyled";
import { ColumnHeader } from "./ColumnHeader";
import { NoDataOverlay } from "./NoDataOverlay";
import { AgGridReactProps } from "ag-grid-react";
import {
  FilterChangedEvent,
  GridReadyEvent,
  RowDataChangedEvent,
} from "ag-grid-community";
import { GridApi } from "ag-grid-enterprise";
import { LoadingOverlay } from "./LoadingOverlay";

export const AGGridWrapper = ({
  defaultColDef,
  components,
  loading,
  onGridReady,
  ref,
  ...rest
}: AgGridReactProps & { loading?: boolean; ref?: any }) => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const noRowsOverlayComponent = useMemo(() => {
    return NoDataOverlay;
  }, []);

  const loadingOverlay = useMemo(() => {
    return LoadingOverlay;
  }, []);

  const onRowDataChanged = (event: RowDataChangedEvent) => {
    event.api.setFilterModel(null);
    if (event.api.getDisplayedRowCount() <= 0) {
      event.api.showNoRowsOverlay();
    }
  };

  const onFilterChanged = (event: FilterChangedEvent) => {
    if (event.api.getDisplayedRowCount() <= 0) {
      event.api.showNoRowsOverlay();
    } else {
      event.api.hideOverlay();
    }
  };

  const onAgGridReady = (params: GridReadyEvent) => {
    setGridApi(params?.api);
    if (typeof onGridReady === "function") onGridReady(params);
  };

  useEffect(() => {
    if (gridApi) {
      if (loading) {
        gridApi.showLoadingOverlay();
      } else {
        gridApi.hideOverlay();
      }
    }
  }, [loading, gridApi]);

  return (
    <>
      {!loading ? (
        <AGGridReactStyled
          columnTypes={{
            textColumn,
            numberColumn,
            dateColumn,
            tagsColumn,
            booleanColumn,
          }}
          defaultColDef={{
            resizable: true,
            sortable: true,
            filter: false,
            getQuickFilterText: () => "",
            type: "textColumn",
            ...defaultColDef,
          }}
          pagination={true}
          suppressPaginationPanel={true}
          suppressMovableColumns
          suppressDragLeaveHidesColumns
          components={{
            agColumnHeader: ColumnHeader,
            ...components,
          }}
          onRowDataChanged={onRowDataChanged}
          onFilterChanged={onFilterChanged}
          headerHeight={40}
          rowHeight={40}
          noRowsOverlayComponent={loading || noRowsOverlayComponent}
          loadingOverlayComponent={loadingOverlay}
          onGridReady={onAgGridReady}
          {...rest}
        />
      ) : (
        <LoadingOverlay />
      )}
    </>
  );
};
