import { Drawer, styled } from "@mui/material";
import { CloseIcon as MuiCloseIcon } from "../Icons/CloseIcon";

export const StyledDrawer = styled(Drawer)(() => ({
  zIndex: "99999",
  "~ .MuiModal-root": {
    zIndex: "99999",
  },
  ".MuiDrawer-paper": {
    width: "500px",
    padding: "24px",
    paddingBottom: 0,
    boxSizing: "border-box",
  },
}));

export const StyledCloseIcon = styled(MuiCloseIcon)(({ theme }) => ({
  zIndex: "100",
  position: "fixed",
  right: "30px",
  top: "24px",
  cursor: "pointer",
  width: "20px",
  height: "20px",
  color: theme.palette.gray.dark,
}));
