import React from "react";
import {
  Box,
  Radio as MUIRadio,
  RadioGroup as MUIRadioGroup,
  RadioProps as MUIRadioProps,
  useTheme,
} from "@mui/material";
import { Typography } from "../Typography/Typography";

export const RadioGroup = MUIRadioGroup;

export interface RadioProps {
  type?: "default" | "small";
  label?: string;
}

export const Radio = ({
  type = "default",
  label = "Radio",
  ...rest
}: RadioProps & MUIRadioProps) => {
  const { palette } = useTheme();

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <MUIRadio
        sx={{
          color: palette.grayAccent.main,
          ml: -2,
          "&.Mui-checked": {
            color: palette.primary.main,
          },
          "& .MuiSvgIcon-root": {
            fontSize: 24,
          },
        }}
        {...rest}
      />
      <Typography
        variant={type === "default" ? "body2" : "body3"}
        ml={type === "default" ? ".25rem" : "0rem"}
      >
        {label}
      </Typography>
    </Box>
  );
};
