import {
  Box,
  FormHelperText,
  TextField,
  useTheme,
  colors,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

interface CustomTextFieldProps {
  name?: string;
  value: string;
  onChange: any;
  error?: string;
  touched?: boolean;
  type?: string;
  showErrorText?: boolean;
  onBlur?: any;
}

export const CustomTextField = ({
  name,
  value,
  onChange,
  error,
  touched,
  type,
  showErrorText,
  onBlur,
}: CustomTextFieldProps) => {
  const { palette } = useTheme();
  return (
    <>
      <TextField
        sx={{
          width: "100%",
          ".MuiInputBase-input": {
            width: "100%",
            height: "36px",
            fontSize: "16px",
            lineHeight: "20px",
            padding: "8px",
            boxSizing: "border-box",
            background: "white",
          },
        }}
        onBlur={onBlur}
        name={name}
        value={value}
        error={touched && error ? true : false}
        onChange={onChange}
        type={type}
      />
      {showErrorText && touched && error && (
        <Box
          display="flex"
          mt={1}
          justifyContent="flex-start"
          alignItems="center"
        >
          <ErrorOutlineIcon
            htmlColor={palette.error.dark}
            style={{
              width: "13.33px",
              height: "13.33px",
              marginTop: "4px",
              marginRight: "5px",
            }}
          />

          <FormHelperText
            style={{
              color: palette.error.dark,
              fontFamily: "Roboto",
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: 400,
            }}
          >
            {error}
          </FormHelperText>
        </Box>
      )}
    </>
  );
};
CustomTextField.defaultProps = {
  showErrorText: true,
};
