import { Box, Divider, Drawer, IconButton, useTheme } from "@mui/material";
import { Typography } from "../../../common/Typography/Typography";
import BusinessIcon from "@mui/icons-material/Business";
import { DOCLink, Link } from "../../../common/Link/Link";
import { IconCell } from "../CellRenderer/IconCell";
import { Tag } from "../../../common/Tag/Tag";
import SyncIcon from "@mui/icons-material/Sync";
import { Button } from "../../../common/Button/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useState } from "react";
import { CopyInput } from "../../../common/CopyInput/CopyInput";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { SourceOutput, TransformationServiceOutput } from "../../../generated";
import { convertToTitleCase } from "../../../helpers";
import { Close } from "@mui/icons-material";
import { StatusCell } from "../CellRenderer/StatusCell";
import { Partner } from "../../../common/Icons/Partner";
import { getSource } from "../../sourceIcons";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { IngestionIcon } from "../../../common/Icons/IngestionIcon";

export const AddDetail = ({
  data,
  onClose,
}: {
  data: SourceOutput | undefined;
  onClose: () => void;
}) => {
  const [openConfiguration, setOpenConfiguration] = useState(false);
  const { palette } = useTheme();

  return (
    <Drawer anchor="right" open={data ? true : false} onClose={onClose}>
      <Box width={452} sx={{ padding: "28px 24px" }}>
        <Box position="relative" mb={4}>
          <Box position="absolute" top={-10} right={0}>
            <IconButton onClick={() => onClose()} size="small">
              <Close />
            </IconButton>
          </Box>
          <Typography
            sx={{
              color: palette.gray.dark,
              letterSpacing: "0.08em",
              textTransform: "uppercase",
              fontSize: "12px",
              lineHeight: "14px",
              fontWeight: "700",
            }}
            variant="overline"
          >
            (Data Source)
          </Typography>
          <Typography
            mt={1}
            mb={3}
            variant="h5"
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "12px",

              svg: {
                verticalAlign: "top",
                width: "24px",
                height: "24px",
              },
            }}
          >
            {data?.logoUrl && (
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  width: "24px",
                  height: "24px",
                  ">div": {
                    width: "24px",
                    height: "24px",
                  },
                  svg: {
                    width: "24px",
                    height: "24px",
                    color: palette.grayAccent.light,
                  },

                  img: {
                    width: "24px",
                    height: "24px",
                    color: palette.grayAccent.light,
                  },
                }}
              >
                {data?.logoUrl.includes("<svg") ? (
                  <div className="source-logo">
                    <img
                      src={`data:image/svg+xml;base64,${window.btoa(
                        data?.logoUrl
                      )}`}
                      width="24"
                      height="24"
                    />
                  </div>
                ) : (
                  <div className="source-logo">
                    <img src={data?.logoUrl} width="24" height="24" />
                  </div>
                )}
              </Box>
            )}
            {data?.name}
          </Typography>
          <StatusCell value={data?.status || ""} />
          <Divider sx={{ mb: 4, mt: 4 }} />
          <Box>
            <Typography gutterBottom variant="subtitle3">
              Type
            </Typography>
            <Typography
              variant="body2"
              color="gray.darkest"
              sx={{
                marginBottom: "24px",

                svg: {
                  verticalAlign: "-6px",
                  width: "24px",
                  height: "24px",
                  marginRight: "10px",
                  color: palette.grayAccent.light,
                },

                img: {
                  verticalAlign: "-6px",
                  width: "24px",
                  height: "24px",
                  marginRight: "10px",
                  color: palette.grayAccent.light,
                },
              }}
            >
              {getSource(data?.sourceType)}
              {convertToTitleCase(data?.sourceType)}
            </Typography>
            <Typography gutterBottom variant="subtitle3">
              Description
            </Typography>
            <Typography
              variant="body2"
              sx={{ marginBottom: "24px" }}
              color="gray.darkest"
            >
              {data?.description}
            </Typography>
            <Box sx={{ marginBottom: "24px" }}>
              <Typography gutterBottom variant="subtitle3">
                Source Owner
              </Typography>
              <Typography variant="body2" color="gray.darkest">
                {data?.owner.name}
              </Typography>
            </Box>
            <Box sx={{ marginBottom: "24px" }}>
              <Typography gutterBottom variant="subtitle3">
                Documentation
              </Typography>
              <Typography
                variant="body2"
                color="gray.darkest"
                sx={{
                  a: {
                    color: palette.link.main,
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: "700",
                    textDecoration: "none",

                    ".MuiSvgIcon-root": {
                      width: "16px",
                      height: "16px",
                      verticalAlign: "middle",
                      margin: "0 0 4px 4px",
                    },
                  },
                }}
              >
                <a
                  href={data?.documentationUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  Link Text
                  <OpenInNewIcon />
                </a>
              </Typography>
            </Box>

            <Box>
              <Typography gutterBottom variant="subtitle3">
                Source Manager
              </Typography>
              <Typography variant="body2" color="gray.darkest">
                {data?.manager?.firstName} {data?.manager?.lastName}
              </Typography>
              <Typography
                variant="body2"
                color="gray.darkest"
                sx={{
                  a: {
                    color: palette.link.main,
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: "700",
                    textDecoration: "none",
                  },
                }}
              >
                <a href={`mailto:${data?.manager?.emailAddress}`}>
                  {data?.manager?.emailAddress}
                </a>
              </Typography>
            </Box>
            <Divider sx={{ mb: 4, mt: 4 }} />
            {/* <Box sx={{ marginBottom: "24px" }}>
              <Typography gutterBottom variant="subtitle3">
                Data contact
              </Typography>
              <Typography
                variant="body2"
                color="gray.darkest"
                sx={{
                  a: {
                    color: palette.link.main,
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: "700",
                    textDecoration: "none",
                  },
                }}
              >
                <a href={data?.documentationUrl}>Link Text</a>
              </Typography>
            </Box> */}
            {/* <Box sx={{ marginBottom: "24px" }}>
              <Typography gutterBottom variant="subtitle3">
                Data Service Type
              </Typography>
              <Typography variant="body2" color="gray.darkest">
                <Box sx={{
                  display: "inline-flex",
                  verticalAlign: "top",
                  margin: "0 5px 0 0",
                  "svg": {
                    width: "20px",
                    height: "20px",
                    verticalAlign: "top"
                  }
                }}>
                  <IngestionIcon />
                </Box>
                Ingestion 
              </Typography>
            </Box> */}
            <Box>
              <Typography gutterBottom variant="subtitle3">
                Data Assets from Source
              </Typography>
              <Typography variant="body2" color="gray.darkest">
                {data?.dataAssets}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};
