import { useState } from "react";
import { Tabs } from "../../Tabs/Tabs";
import { Tab, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { Engines } from "../../../WorkspaceConfiguration/Engines/Engines";
import { Service } from "../Service/Service";
import { TabButton, TabsWrapper } from "../style";

export const ServiceTab = ({ query }: any) => {
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const tabParam = sp.get("tab");

  const [tab, setTab] = useState(tabParam === "activity" ? 1 : 1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  return (
    <>
      <TabsWrapper
        value={tab}
        onChange={() => {}}
        aria-label="asset detail tab"
      >
        <TabButton label="Activity" {...a11yProps(0)} className="d-none" />
        <TabButton label="Service Configuration" {...a11yProps(1)} />
      </TabsWrapper>

      {tab === 0 && (
        <Typography variant="body2" color="gray.dark">
          Activity tab is not supported at the moment.
        </Typography>
      )}

      {tab === 1 && <Service query={query} />}
    </>
  );
};
