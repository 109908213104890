import {
  FormControl,
  MenuItem,
  Typography,
  Select,
  useTheme,
} from "@mui/material";
import { getSource } from "../../sourceIcons";
import { Internal } from "./Internal";
import { Marketplace } from "./Marketplace";
import { Partner } from "./Partner";
import { Subscription } from "./Subscription";

export const AddSource = ({
  source,
  setSource,
  close,
}: {
  source: string | boolean;
  setSource: Function;
  close: Function;
}) => {
  const { palette } = useTheme();
  return (
    <>
      <Typography
        sx={{
          color: palette.common.black,
          letterSpacing: "0.0025em",
          fontWeight: "500",
          fontSize: "24px",
          lineHeight: "28px",
          paddingBottom: "17px",
          marginBottom: "24px",
          backgroundImage: `linear-gradient(45deg, ${palette.action.disabledBackground} 0%, ${palette.action.disabledBackground} 100%)`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "0 100%",
          backgroundSize: "calc(100% - 104px) 1px",
        }}
      >
        {`Add Source: ${source}`}
      </Typography>
      <FormControl
        fullWidth
        sx={{
          ".MuiInputBase-formControl": {
            borderRadius: "3px",
          },

          fieldset: {
            borderColor: palette.grayAccent.light,
          },
        }}
      >
        <Typography
          sx={{
            color: palette.common.black,
            letterSpacing: "0.0025em",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "20px",
            marginBottom: "6px",
          }}
        >
          Source Group
        </Typography>
        <Select
          value={source}
          onChange={(e: any) => setSource(e.target?.value)}
          sx={{
            div: {
              borderRadius: "3px",
              background: palette.common.white,
              height: "20px !important",
              padding: "7px 15px",
              fontSize: "14px",
              lineHeight: "1",
              fontWeight: "500",

              "&:before": {
                content: "''",
                position: "absolute",
                right: "15px",
                top: "10px",
                border: `solid ${palette.gray.dark}`,
                borderWidth: "0 2px 2px 0",
                transform: "rotate(45deg)",
                width: "7px",
                height: "7px",
              },

              "&[aria-expanded='true']": {
                "&:before": {
                  transform: "rotate(-135deg)",
                  top: "14px",
                },
              },

              svg: {
                width: "20px",
                height: "20px",
                color: palette.grayAccent.light,
                verticalAlign: "-5px",
              },
            },

            ".MuiSelect-icon": {
              display: "none",
            },
          }}
        >
          <MenuItem
            value={"Internal"}
            sx={{
              padding: "8px",
              borderRadius: "4px",
              border: `1px solid ${palette.customRolled.lighter}`,
              fontSize: "14px",
              lineHeight: "20px",
              letterSpacing: "0.0025rem",
              fontWeight: "500",
              margin: "8px",

              "&:first-child": {
                marginTop: "0",
              },

              svg: {
                width: "20px",
                height: "20px",
                color: palette.grayAccent.light,
                marginRight: "3px",
              },
            }}
          >
            {getSource("Internal", "csb-dropdown-icn")} Internal
          </MenuItem>
          <MenuItem
            value={"Partner"}
            disabled
            sx={{
              padding: "8px",
              borderRadius: "4px",
              border: `1px solid ${palette.customRolled.lighter}`,
              fontSize: "14px",
              lineHeight: "20px",
              letterSpacing: "0.0025rem",
              fontWeight: "500",
              margin: "8px",

              "&:first-child": {
                marginTop: "0",
              },

              "&:last-child": {
                marginBottom: "0",
              },

              svg: {
                width: "20px",
                height: "20px",
                color: palette.grayAccent.light,
                marginRight: "3px",
              },
            }}
          >
            {getSource("Partner", "csb-dropdown-icn")} Partner
          </MenuItem>
          <MenuItem
            value={"Subscription"}
            disabled
            sx={{
              padding: "8px",
              borderRadius: "4px",
              border: `1px solid ${palette.customRolled.lighter}`,
              fontSize: "14px",
              lineHeight: "20px",
              letterSpacing: "0.0025rem",
              fontWeight: "500",
              margin: "8px",

              "&:first-child": {
                marginTop: "0",
              },

              svg: {
                width: "20px",
                height: "20px",
                color: palette.grayAccent.light,
                marginRight: "3px",
              },
            }}
          >
            {getSource("Subscription", "csb-dropdown-icn")} Subscription
          </MenuItem>
          <MenuItem
            value={"Marketplace"}
            disabled
            sx={{
              padding: "8px",
              borderRadius: "4px",
              border: `1px solid ${palette.customRolled.lighter}`,
              fontSize: "14px",
              lineHeight: "20px",
              letterSpacing: "0.0025rem",
              fontWeight: "500",
              margin: "8px",

              "&:first-child": {
                marginTop: "0",
              },

              "&:hover": {
                backgroundColor: palette.primary.lighter,
              },

              svg: {
                width: "20px",
                height: "20px",
                color: palette.grayAccent.light,
                marginRight: "3px",
              },
            }}
          >
            {getSource("Marketplace", "csb-dropdown-icn")} Marketplace
          </MenuItem>
        </Select>
      </FormControl>

      {source === "Internal" && <Internal close={close} />}

      {source === "Partner" && <Partner close={close} />}

      {source === "Subscription" && <Subscription close={close} />}

      {source === "Marketplace" && <Marketplace close={close} />}
    </>
  );
};
