import { styled, Box } from "@mui/material";
import { brighthiveTheme } from "../../theme/theme";

export const BasicCardHolder = styled(Box)`
  width: 100%;,
  minHeight: 3rem;
  background-color: ${brighthiveTheme.palette.common.white};
  box-shadow: inset 0px -.0625rem 0px ${brighthiveTheme.palette.action.disabledBackground};
  box-sizing: border-box;
  border: .0625rem solid ${brighthiveTheme.palette.action.disabledBackground};
  border-radius: .375rem;
`;
