import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const BigQueryIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.209142 12.882L5.42952 21.9225C5.70703 22.4037 6.21895 22.7008 6.77415 22.7032H17.2199C17.777 22.7025 18.2918 22.4055 18.5705 21.9225L23.7908 12.882C24.0697 12.3991 24.0697 11.8039 23.7908 11.3211L18.5705 2.28025C18.2928 1.7995 17.7808 1.50262 17.2257 1.5H6.78014C6.22297 1.50041 5.70821 1.79769 5.42952 2.28025L0.209142 11.3211C-0.0697141 11.8039 -0.0697141 12.3991 0.209142 12.882ZM11.8624 15.2737C10.0501 15.2737 8.58102 13.8045 8.58102 11.9923C8.58102 10.18 10.0501 8.71095 11.8624 8.71095C13.6747 8.71095 15.1438 10.1802 15.1438 11.9923C15.1438 12.8626 14.7981 13.6971 14.1827 14.3124C13.5673 14.9278 12.7327 15.2737 11.8624 15.2737ZM16.9724 16.31L15.3683 14.7057C15.3506 14.688 15.3304 14.6732 15.3083 14.6609C16.7175 12.8396 16.4668 10.236 14.7359 8.71712C13.0049 7.19806 10.391 7.2873 8.76768 8.92101C7.14441 10.5545 7.07179 13.1687 8.60163 14.89C10.1317 16.6113 12.7367 16.8456 14.5492 15.425C14.5604 15.4458 14.5746 15.4645 14.5912 15.4814L16.1953 17.0857C16.2425 17.1341 16.3075 17.1619 16.3754 17.1619C16.4432 17.1619 16.5082 17.1341 16.5554 17.0857L16.9757 16.6651C17.0224 16.6179 17.0484 16.5533 17.0476 16.4865C17.0474 16.4197 17.0201 16.3562 16.9724 16.31ZM9.76617 13.1713C9.97522 13.5408 10.2779 13.8485 10.6439 14.0634V11.8171H9.76617V13.1713ZM11.4193 14.3528C11.7094 14.4067 12.0071 14.4067 12.297 14.3528V10.6945H11.4193V14.3528ZM13.0728 14.0608C13.4385 13.8463 13.7412 13.539 13.9505 13.1699V12.3654H13.0728V14.0608Z"
        fill="#4386FA"
      />
    </SvgIcon>
  );
};
