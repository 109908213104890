import React, { createContext, useState } from "react";

type ResourceContextType = {
  editMode: {
    resourceId?: string;
    setEditing: (resourceId: string) => void;
    closeEditor: () => void;
  };
  viewMode: {
    name?: string;
    setBeingViewed: (name: string) => void;
  };
};

const ResourceContext = createContext<ResourceContextType | null>(null);
const ResourceContextProvider: React.FC<{
  children:
    | React.ReactNode
    | ((context: ResourceContextType) => React.ReactNode);
}> = ({ children }) => {
  const [editing, setEditing] = useState<string | undefined>();
  const [viewing, setViewing] = useState<string | undefined>();
  const contextValue = {
    editMode: {
      resourceId: editing,
      setEditing,
      closeEditor: () => setEditing(undefined),
    },
    viewMode: {
      name: viewing,
      setBeingViewed: setViewing,
    },
  };
  return (
    <ResourceContext.Provider value={contextValue}>
      {typeof children === "function" ? children(contextValue) : children}
    </ResourceContext.Provider>
  );
};
const useResourceContext = () =>
  React.useContext(ResourceContext) as ResourceContextType;

const Resource = {
  ContextProvider: ResourceContextProvider,
  useContext: useResourceContext,
};

export default Resource;
