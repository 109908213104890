import React, { useState } from "react";
import { Box, Grid, Paper, useTheme } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { Button } from "../../common/Button/Button";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { DestinationDownload } from "../../common/Icons/DestinationDownload";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { WorkflowDestinationOutput } from "../../generated";
import { getAlertMessage } from "../helpers";
import { DOCLink } from "../../common/Link/Link";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ReactJson from "react-json-view";

const inputSchemaData: string | any[] = [];

export const DestinationDetails = ({
  issueType,
  destination,
}: {
  issueType: any;
  destination?: WorkflowDestinationOutput;
}) => {
  const [editView, setEditView] = useState(false);
  const { palette } = useTheme();
  const [isEdited, setisEdited] = useState<string>("Major issue");
  const [show, setShow] = useState<boolean>(false);

  return (
    <Box boxSizing="border-box">
      <Box mb={6}>
        {getAlertMessage(
          issueType,
          "An entity managed by this organization is preventing the data flow from running."
        )}
        <Typography
          mb={2}
          variant="h5"
          color={
            destination && destination.description
              ? palette.gray.darkest
              : palette.gray.dark
          }
          sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: "normal" }}
        >
          {destination && destination.description
            ? destination.description
            : "None"}
        </Typography>

        {destination?.connectionSettings && (
          <Box mt={5}>
            <Button
              variant="outlined"
              size="small"
              sx={{
                color: (theme) => theme.palette.text.primary,
              }}
              startIcon={
                <KeyboardArrowRightIcon
                  style={{
                    transition: "0.3s",
                    transform: editView ? "rotate(90deg)" : "rotate(0deg)",
                  }}
                />
              }
              onClick={() => setEditView(!editView)}
            >
              Show configuration details
            </Button>
            {editView && (
              <Box
                mt={5}
                sx={{
                  fontSize: "0.875rem",
                  lineHeight: "1.25",
                }}
              >
                <ReactJson
                  src={
                    {
                      configuration: JSON.parse(
                        destination?.connectionSettings || ""
                      ),
                    } || {}
                  }
                  enableClipboard={(e) =>
                    navigator.clipboard.writeText(
                      JSON.stringify({ [e?.name || ""]: e.src })
                    )
                  }
                />
              </Box>
            )}
          </Box>
        )}
        {show && (
          <Box mb={6}>
            <Grid container>
              {inputSchemaData && inputSchemaData.length > 0 ? (
                <>
                  {inputSchemaData.map((product) => (
                    <Grid item xs={12} mb={4} sx={{ minWidth: "360px" }}>
                      <Paper
                        variant="outlined"
                        sx={{
                          p: 4,
                          display: "flex",
                          minHeight: "100%",
                          boxSizing: "border-box",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box display="flex">
                          <Box
                            mr={3}
                            width="2rem"
                            sx={{
                              ".MuiSvgIcon-root": {
                                minWidth: "2rem",
                                width: "2rem",
                                height: "2rem",
                                path: {
                                  fill: palette.grayAccent.light,
                                },
                              },
                            }}
                          >
                            <DestinationDownload />
                          </Box>

                          <Box sx={{ paddingLeft: 3 }}>
                            <Typography
                              variant="subtitle2"
                              color={palette.common.black}
                              sx={{
                                fontSize: "0.875rem",
                                fontWeight: 500,
                              }}
                            >
                              {product?.name}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color={palette.gray.darkest}
                              sx={{
                                fontSize: "12px",
                                fontWeight: 400,
                              }}
                            >
                              {product?.time}
                            </Typography>
                          </Box>
                        </Box>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() =>
                            window.open(product?.href || "#", "_blank")
                          }
                          endIcon={
                            <FileDownloadOutlinedIcon fontSize="small" />
                          }
                          sx={{
                            ".MuiSvgIcon-root": {
                              fontSize: "1.5rem !important",
                            },
                          }}
                        ></Button>
                      </Paper>
                    </Grid>
                  ))}
                </>
              ) : (
                <Typography
                  mb={2}
                  variant="h5"
                  color={palette.error.dark}
                  sx={{
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontWeight: "400",
                  }}
                >
                  <InfoOutlinedIcon
                    sx={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      display: "inline-block",
                      verticalAlign: "middle",
                      marginRight: "3px",
                      marginBottom: "3px",
                      color: palette.error.dark,
                    }}
                  />
                  No data products available
                </Typography>
              )}
            </Grid>
          </Box>
        )}

        {destination?.destinationUrl && (
          <DOCLink
            sx={{ mb: 2 }}
            name="Open Destination"
            href={destination?.destinationUrl}
            target="_blank"
          />
        )}
      </Box>
    </Box>
  );
};
