import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactFlow, {
  Controls,
  MiniMap,
  Node,
  OnConnect,
  ReactFlowInstance,
  useNodes,
  useReactFlow,
} from "react-flow-renderer";
import { useMutation, useQuery } from "@apollo/client";
import {
  CreateWorkflowConnectionDocument,
  GetWorkflowDocument,
  GetWorkflowQuery,
  WorkflowNodeType,
  WorkflowState,
} from "../generated";
import { getNodesAndEdgesFromData } from "./getNodesAndEdgesFromData";
import { ElementType, NodeType, workflowUpdater } from "./helpers";
import { HeaderNode } from "./HeaderNode";
import { OrganizationNode } from "./OrganizationNode";
import { BrandedAnimationLoader } from "../common/Loader/Loader";
import { InputDataNode } from "./InputDataNode";
import { DestinationNode } from "./DestinationNode";
import { FinalDataProductNode } from "./FinalDataProductNode";
import { TransformationNode } from "./TransformationNode";
import { useParams } from "react-router-dom";
import { AddNode } from "./AddNode";
import { toast } from "react-toastify";

export interface ProjectWorkflowProps {}

const nodeTypes = {
  [ElementType.HEADER_NODE]: HeaderNode,
  [ElementType.ADD_NODE]: AddNode,
  [NodeType.ORGANIZATION]: OrganizationNode,
  [NodeType.INPUT_DATA]: InputDataNode,
  [NodeType.TRANSFORMATIONS]: TransformationNode,
  [NodeType.FINAL_DATA_PRODUCTS]: FinalDataProductNode,
  [NodeType.DESTINATIONS]: DestinationNode,
  [NodeType.INPUT_DATA_ITEM]: null,
};
export const ProjectWorkflow = ({
  onNodeClick,
  isEditable = false,
  testRunning = false,
  dataRunning = false,
}: {
  onNodeClick: (node: Node<any>) => void;
  isEditable: boolean | undefined;
  testRunning: boolean;
  dataRunning: boolean;
}) => {
  const { workspaceId, projectId } = useParams();
  const [initialzed, setInit] = useState(false);
  const reactFlowInstance = useReactFlow();
  const [instance, setInstance] = useState<ReactFlowInstance | null>(null);
  const [workflow, setWorkflow] = useState<GetWorkflowQuery>();

  const { data, loading, refetch } = useQuery(GetWorkflowDocument, {
    variables: {
      workflowFilterInput: {
        projectId: projectId || "",
        workspaceId: workspaceId || "",
      },
    },
    onCompleted: () => {
      if (instance) {
        setTimeout(() => {
          const viewport = instance.getViewport();
          instance.setViewport({ zoom: viewport.zoom, y: 0, x: 0 });
        }, 1000);
      }
    },
  });

  useEffect(() => {
    setWorkflow(data);
  }, [data]);

  const [createConnection] = useMutation(CreateWorkflowConnectionDocument);

  const isActive = useMemo(
    () => workflow?.workflow?.state === WorkflowState.Active || dataRunning,
    [dataRunning, workflow?.workflow?.state]
  );

  const { nodes, edges } = useMemo(
    () =>
      getNodesAndEdgesFromData({
        workflow: workflow?.workflow,
        loading,
        onNodeClick,
        isActive,
        isTesting: testRunning,
      }),
    [workflow?.workflow, loading, onNodeClick, isActive, testRunning]
  );

  const renderedNodes = useNodes();

  useEffect(() => {
    const centerNode = renderedNodes.find((n) => n.id === "addTransformation");
    if (centerNode && reactFlowInstance && !initialzed) {
      setInit(true);
      reactFlowInstance.fitView();
      reactFlowInstance.setCenter(
        centerNode.position.x + 150,
        centerNode.position.y + 200,
        {
          zoom: 0.7,
        }
      );
    }
  }, [initialzed, reactFlowInstance, renderedNodes]);

  const onConnect = useCallback(
    (connect: any) => {
      if (!isEditable) return false;

      const sourceNode = nodes.find((node) => node.id === connect.source);
      const targetNode = nodes.find((node) => node.id === connect.target);

      const updatedWorkflow = workflowUpdater(
        workflow,
        sourceNode?.id,
        targetNode?.id
      );

      setWorkflow(updatedWorkflow as GetWorkflowQuery);

      createConnection({
        variables: {
          input: {
            workspaceId: workspaceId || "",
            projectId: projectId || "",
            sourceNodeType: (sourceNode?.type as WorkflowNodeType) || "",
            sourceId: sourceNode?.id || "",
            targetNodeType: (targetNode?.type as WorkflowNodeType) || "",
            targetId: targetNode?.id || "",
          },
        },
      }).then((res) => {
        if (res.data?.createWorkflowConnection) {
          setInstance(null);
          setInit(false);
          refetch();
        } else {
          toast.error("Connection can't be created");
        }
      });
    },
    [
      createConnection,
      isEditable,
      nodes,
      projectId,
      refetch,
      workflow,
      workspaceId,
    ]
  );

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      nodesDraggable={false}
      onNodeClick={(event, node) => {
        if (!event.isDefaultPrevented()) onNodeClick(node);
      }}
      onConnect={onConnect}
      nodeTypes={nodeTypes}
      fitView={true}
      onInit={(i) => {
        setInstance(i);
      }}
    >
      {loading && (
        <BrandedAnimationLoader
          sx={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            top: "40%",
          }}
        />
      )}
      <Controls />
      <MiniMap />
    </ReactFlow>
  );
};
