import React from "react";
import { Node } from "react-flow-renderer";
import OrganizationSheets from "./OrganizationSheets";
import { InputDataAsset } from "./InputDataAsset";
import { DataProduct } from "./DataProduct";
import { DataProductSchemaSet } from "./DataProductSchemaSet";
import { Destination } from "./Destination";
import { Transformation } from "./Transformation";
import { InputSchemaSet } from "./InputSchemaSet";
import { ElementType, NodeType } from "../ProjectWorkflow/helpers";
import { CreateInputSchemaSetDetails } from "./InputSchemaSet/CreateInputSchemaSetDetails";
import { CreateDataProductSchemaSetDetails } from "./DataProductSchemaSet/CreateDataProductSchemaSetDetails";
import { CreateTransformation } from "./Transformation/CreateTransformation";
import { CreateDestination } from "./Destination/CreateDestination";

const renderSheet = (
  node: Partial<Node<any>>,
  addNode: React.ReactElement,
  viewNode: React.ReactElement,
  toggleRightSidePanel?: any,
  showToast?: any
) => (node.type === ElementType.ADD_NODE ? addNode : viewNode);

export const Sheets = ({
  node,
  toggleRightSidePanel,
  showToast,
}: {
  node: Partial<Node<{ parentId?: string; nodeType?: NodeType }>> | null;
  toggleRightSidePanel?: any;
  showToast?: any;
}) => {
  if (!node?.id) {
    return null;
  }
  switch (node?.data?.nodeType || node?.type) {
    case NodeType.ORGANIZATION:
      return <OrganizationSheets id={node?.id} />;
    case NodeType.INPUT_DATA:
      return renderSheet(
        node,
        <CreateInputSchemaSetDetails
          toggleRightSidePanel={toggleRightSidePanel}
          showToast={showToast}
        />,
        <InputSchemaSet id={node?.id} showToast={showToast} />
      );
    case NodeType.INPUT_DATA_ITEM:
      return (
        <InputDataAsset id={node?.id} parentId={node?.data?.parentId || ""} />
      );
    case NodeType.FINAL_DATA_PRODUCTS:
      return renderSheet(
        node,
        <CreateDataProductSchemaSetDetails
          toggleRightSidePanel={toggleRightSidePanel}
          showToast={showToast}
        />,
        <DataProductSchemaSet id={node?.id} showToast={showToast} />
      );
    case NodeType.DATA_PRODUCT:
      return (
        <DataProduct id={node?.id} parentId={node?.data?.parentId || ""} />
      );
    case NodeType.DESTINATIONS:
      return renderSheet(
        node,
        <CreateDestination
          toggleRightSidePanel={toggleRightSidePanel}
          showToast={showToast}
        />,
        <Destination id={node?.id} showToast={showToast} />
      );
    case NodeType.TRANSFORMATIONS:
      return renderSheet(
        node,
        <CreateTransformation
          toggleRightSidePanel={toggleRightSidePanel}
          showToast={showToast}
        />,
        <Transformation id={node?.id} showToast={showToast} />
      );
    default:
      return <></>;
  }
};
