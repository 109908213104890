import React, { useEffect, useState } from "react";
import classNames from "classnames";
import gravatar from "gravatar";
import { memoize } from "lodash";
import { stringAvatarLetters } from "../../helpers";
import { generateGradientColors } from "./Profile";
import { useTheme } from "@mui/material";
import axios from "axios";
import { Wrapper } from "./style";
interface Props {
  email?: string;
  name?: string;
  className?: string;
  width?: number;
  height?: number;
}

const ProfileAvatarIcon = ({
  email,
  name,
  width,
  height,
  className: propClassName,
  ...props
}: Props) => {
  const [doesntHaveGravatar, setDoesntHaveGravatar] = useState(true);
  const defaultHeight = 22;
  const defaultWidth = 22;
  const { palette } = useTheme();

  const createGravatarUrl = () => {
    if (!email) {
      return "";
    }

    return gravatar.url(email, { s: "350", d: "404" });
  };

  const generateGradientColor = memoize(generateGradientColors);

  const gradientColor = generateGradientColor(
    palette.primary.main,
    palette.common.white,
    10
  );

  const className = classNames(propClassName);

  useEffect(() => {
    if (email) {
      axios
        .get(createGravatarUrl())
        .then(() => setDoesntHaveGravatar(false))
        .catch(() => setDoesntHaveGravatar(true));
    } else {
      setDoesntHaveGravatar(true);
    }
  }, [email]);

  return doesntHaveGravatar && name ? (
    <Wrapper
      className={className}
      {...props}
    >
      {name.includes("undefined") || !name ? "" : stringAvatarLetters(name)}
    </Wrapper>
  ) : (
    <img
      style={{
        border: `2px solid ${palette.primary.main}`,
        height: `${height || defaultHeight}px`,
        width: `${width || defaultWidth}px`,
        borderRadius: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      {...props}
      className={className}
      src={createGravatarUrl()}
      alt={`gravatar-${name}`}
      width={`${width || 18}`}
      height={`${height || 18}`}
    />
  );
};

export default ProfileAvatarIcon;
