import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const DatabricksIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <g clip-path="url(#clip0_1579_12845)">
        <path
          d="M1.125 6.23945V6.95635L12.1213 13.145L21.993 7.62034V9.87065L12.1213 15.4461L1.68732 9.51272L1.125 9.81982V14.1161L12.1213 20.3047L21.993 14.7811V16.9806L12.1213 22.555L1.68732 16.6226L1.125 16.9297V17.6964L12.1213 23.885L23.1177 17.6964V13.4002L22.6062 13.0931L12.1213 19.0265L2.30151 13.4002V11.2007L12.1213 16.7243L23.1177 10.5357V6.29132L22.6062 5.98423L12.1213 11.9166L2.81299 6.64926L12.1213 1.3809L19.8444 5.72797L20.5094 5.3192V4.75688L12.1213 0L1.125 6.23945Z"
          fill="#EF3E2B"
        />
      </g>
      <defs>
        <clipPath id="clip0_1579_12845">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
