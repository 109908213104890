import React from "react";
import { Divider, ListItemText, useTheme } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { useMenu } from "../common/Menu/useMenu";

import { Button } from "../common/Button/Button";
import { Typography } from "../common/Typography/Typography";
import { MenuItem } from "../common/Menu/MenuItem";
import { Menu } from "../common/Menu/Menu";

interface SpecificGovernancePolicyItemMenuButtonProps {
  disableEdit?: boolean;
  handleEdit?: Function;
  handleDelete?: Function;
  handleOpenNewTab?: Function;
}

export type GovernancePolicyItemMenuButtonProps =
  SpecificGovernancePolicyItemMenuButtonProps;

export const GovernancePolicyItemMenuButton = ({
  disableEdit,
  handleEdit,
  handleDelete,
  handleOpenNewTab = () => {},
  ...rest
}: GovernancePolicyItemMenuButtonProps) => {
  const [menuOpen, anchorEl, openMenuFromAnchorElement, closeMenu] = useMenu();
  const { palette } = useTheme();

  const onEdit = () => {
    handleEdit?.();
    closeMenu();
  };

  const onDelete = () => {
    closeMenu();
    handleDelete?.();
  };

  const onNewTab = () => {
    closeMenu();
    handleOpenNewTab();
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        color="grayAccent"
        sx={{ padding: ".3125rem 0", minWidth: "2rem", marginTop: -4 }}
        onClick={openMenuFromAnchorElement}
      >
        <MoreHorizIcon fontSize="small" />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {handleEdit && (
          <MenuItem disabled={disableEdit} onClick={onEdit}>
            <ListItemText>Edit policy</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={onNewTab}>
          <ListItemText>Open in new tab</ListItemText>
        </MenuItem>
        {handleDelete && (
          <>
            <Divider />
            <MenuItem onClick={onDelete}>
              <ListItemText>
                <Typography
                  variant="body2"
                  color={palette.error.main}
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  Delete Policy
                  <DeleteOutlineIcon sx={{ mb: 1 }} />
                </Typography>
              </ListItemText>
            </MenuItem>
          </>
        )}
      </Menu>
    </>
  );
};
