import { Box, Dialog, DialogProps } from "@mui/material";
import React, { useState } from "react";
import { Button } from "../../common/Button/Button";
import { Typography } from "../../common/Typography/Typography";

interface SpecificProjectShareLinkDialogProps {
  onClose: () => void;
}

type ProjectShareLinkDialogProps = DialogProps &
  SpecificProjectShareLinkDialogProps;

export const ProjectShareLinkDialog = (props: ProjectShareLinkDialogProps) => {
  const [linkGenerate, setLinkGenerated] = useState(false);
  /* const onClickCancel = () => {
   *   if (props.onClose) props.onClose();
   * }; */
  return (
    <Dialog {...props}>
      <Box width="35rem" p={6} boxSizing="border-box">
        <Typography mb={4} variant="h5">
          Create a shareable Project Overview link
        </Typography>{" "}
        <Typography mb={4} variant="body1">
          You can create a shareable public link to allow anyone to view the
          Project Overview.
        </Typography>
        <Box display="flex" mt={7} justifyContent="flex-start" flexGrow={1}>
          {!linkGenerate && (
            <Button
              onClick={() => setLinkGenerated(true)}
              variant="contained"
              sx={{ mr: 3 }}
            >
              Generate Public Link
            </Button>
          )}
          {linkGenerate && (
            <Box display="flex" justifyContent="space-between">
              <Typography mb={4} variant="body2" sx={{ bgcolor: "gray" }}>
                https://brighthive.io/clean-slug{" "}
              </Typography>
              <Button color="primary" variant="contained" sx={{ mr: 3 }}>
                Copy
              </Button>
            </Box>
          )}
        </Box>
        <a href="/">Preview link in new tab </a>
      </Box>
      <Box display="flex" ml={7} mb={7} justifyContent="flex-start">
        {linkGenerate && (
          <Button
            onClick={() => setLinkGenerated(false)}
            color="error"
            variant="outlined"
            sx={{ mr: 3 }}
          >
            X Disable Link
          </Button>
        )}
      </Box>
    </Dialog>
  );
};
