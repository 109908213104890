import { Column, GridApi, IFilter } from "ag-grid-enterprise";
import React, { useEffect, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import { DateFilterOperator, FilterOperator } from "./gridHelpers";
import { getDayMonthYearFromDate } from "../../helpers";

export const useDateFiltersWithGrid = (
  filterOperators: DateFilterOperator[],
  column: Column,
  gridApi: GridApi,
  initialFilterOperator: DateFilterOperator
) => {
  const filterType = "date";

  const [currentFilterOperator, setCurrentFilterOperator] = useState<string>(
    initialFilterOperator
  );
  const [filterText, setFilterText] = useState<string>("");

  const filterInstance = gridApi.getFilterInstance(column);

  const changeInternalGridFilter = (
    filterValue: string,
    filterOperator: string,
    filterInstance: IFilter | null | undefined,
    filterType: string,
    gridApi: GridApi
  ) => {
    if (
      filterOperator === FilterOperator.IS_EMPTY ||
      filterOperator === FilterOperator.IS_NOT_EMPTY
    ) {
      filterInstance?.setModel({
        filterType: "text",
        type: filterOperator,
        filter: filterValue,
      });
    } else {
      if (filterValue === "") {
        filterInstance?.setModel(null);
      } else {
        filterInstance?.setModel({
          filterType: "date",
          type: filterOperator,
          dateFrom: filterValue,
          dateTo: null,
        });
      }
    }
    gridApi.onFilterChanged();
  };

  const onChangeFilterOperator = (e: SelectChangeEvent<unknown>) => {
    const filterOperator = e.target.value as string;
    setCurrentFilterOperator(filterOperator);
    if (
      filterOperator === FilterOperator.IS_EMPTY ||
      filterOperator === FilterOperator.IS_NOT_EMPTY
    ) {
      setFilterText("");
      changeInternalGridFilter(
        "",
        filterOperator,
        filterInstance,
        filterType,
        gridApi
      );
    } else {
      changeInternalGridFilter(
        filterText,
        filterOperator,
        filterInstance,
        filterType,
        gridApi
      );
    }
  };

  const onClickRefreshFilter = () => {
    setFilterText("");
    filterInstance?.setModel(null);
    gridApi.onFilterChanged();
  };

  const onChangeFilterText = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newFilterText = e.target.value;

    setFilterText(newFilterText);
    changeInternalGridFilter(
      newFilterText,
      currentFilterOperator,
      filterInstance,
      filterType,
      gridApi
    );
  };

  useEffect(() => {
    const convertDateStringToYYYYMMDD = (dateString: string) => {
      if (isNaN(Date.parse(dateString))) return "";
      const date = new Date(dateString);
      const { year, month, day } = getDayMonthYearFromDate(date);
      return year + "-" + month + "-" + day;
    };

    const syncFilterWithGridFilterModel = ({
      column,
      api,
    }: {
      column: Column;
      api: GridApi;
    }) => {
      const currentFilterModel = api?.getFilterModel();
      const currentField = column?.getColDef()?.field;

      if (currentField && currentFilterModel?.[currentField]) {
        const { dateFrom: dateString, type: newOperator } =
          currentFilterModel[currentField];
        const formattedGridFilter = convertDateStringToYYYYMMDD(dateString);
        setCurrentFilterOperator(newOperator);
        if (
          newOperator === FilterOperator.IS_EMPTY ||
          newOperator === FilterOperator.IS_NOT_EMPTY
        ) {
          setFilterText("");
        } else {
          setFilterText(formattedGridFilter);
        }
      }
    };

    column.addEventListener("filterChanged", syncFilterWithGridFilterModel);
    return () => {
      column.removeEventListener(
        "filterChanged",
        syncFilterWithGridFilterModel
      );
    };
  }, [filterOperators, column, gridApi, initialFilterOperator]);

  return {
    filterText,
    currentFilterOperator,
    onChangeFilterOperator,
    onChangeFilterText,
    onClickRefreshFilter,
  };
};
