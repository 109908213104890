import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const CheckMarkIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7071 6.29289C21.0976 6.68342 21.0976 7.31658 20.7071 7.70711L10.7071 17.7071C10.3166 18.0976 9.6834 18.0976 9.29288 17.7071L4.29288 12.7071C3.90235 12.3166 3.90235 11.6834 4.29288 11.2929C4.6834 10.9024 5.31657 10.9024 5.70709 11.2929L9.99998 15.5858L19.2929 6.29289C19.6834 5.90237 20.3166 5.90237 20.7071 6.29289Z"
      />
    </SvgIcon>
  );
};
