import React from "react";
import { Column, GridApi } from "ag-grid-enterprise";
import { Typography } from "../Typography/Typography";
import {
  Box,
  FormControl,
  OutlinedInput,
  Popover,
  PopoverProps,
  useTheme,
} from "@mui/material";
import { MenuItem } from "../Menu/MenuItem";
import { Select } from "../Form/Select";
import { FormLabel } from "../Form/FormLabel";
import { CloseIcon } from "../Icons/CloseIcon";
import { CircleIconButton } from "../CircleIconButton/CircleIconButton";
import {
  allDefaultDateFilterOperators,
  DateFilterOperator,
  dateOperatorMapToReadableNames,
  FilterOperator,
} from "./gridHelpers";
import { useDateFiltersWithGrid } from "./useDateFiltersWithGrid";

interface SpecificDatePopoverFilterProps {
  displayName: string;
  description?: string;
  column: Column;
  gridApi: GridApi;
  filterOperators: DateFilterOperator[];
}

export type DatePopoverFilterProps = SpecificDatePopoverFilterProps &
  PopoverProps;

export const DatePopoverFilter = ({
  gridApi,
  column,
  displayName,
  description,
  filterOperators,
  ...rest
}: DatePopoverFilterProps) => {
  const { palette, typography } = useTheme();

  const initialFilterOperator = filterOperators.includes(FilterOperator.EQUALS)
    ? FilterOperator.EQUALS
    : filterOperators[0];

  const {
    filterText,
    currentFilterOperator,
    onChangeFilterOperator,
    onChangeFilterText,
    onClickRefreshFilter,
  } = useDateFiltersWithGrid(
    filterOperators,
    column,
    gridApi,
    initialFilterOperator
  );

  let disabledTextInput = false;
  if (
    currentFilterOperator === FilterOperator.IS_EMPTY ||
    currentFilterOperator === FilterOperator.IS_NOT_EMPTY
  ) {
    disabledTextInput = true;
  }

  if (filterOperators.length === 0) {
    return <div>No filter options provided</div>;
  }

  return (
    <Popover {...rest}>
      <Box sx={{ pt: 4, pb: 6, pl: 6, pr: 6 }}>
        <Typography variant="h6">{displayName}</Typography>
        {description && (
          <Typography pt={2} variant="body3" color={palette.gray.dark}>
            {description}
          </Typography>
        )}
        <Box sx={{ mt: 4 }}>
          <FormControl sx={{ mr: 3 }}>
            <FormLabel>Operator</FormLabel>
            <Select
              renderValue={(value: unknown) => {
                if (typeof value === "string") {
                  return dateOperatorMapToReadableNames[
                    value as DateFilterOperator
                  ];
                }
                return "";
              }}
              sx={{ width: "12.5rem" }}
              value={currentFilterOperator}
              onChange={onChangeFilterOperator}
            >
              {filterOperators.map(
                (currentFilterOption: DateFilterOperator) => (
                  <MenuItem
                    key={currentFilterOption}
                    value={currentFilterOption}
                  >
                    {`${dateOperatorMapToReadableNames[currentFilterOption]}...`}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
          <FormControl disabled={disabledTextInput} sx={{ mr: 3 }}>
            <FormLabel>Value</FormLabel>
            <OutlinedInput
              type="date"
              value={filterText}
              placeholder="Enter a value"
              onChange={onChangeFilterText}
              sx={{
                width: "12.5rem",
                ...typography.body3,
                ".MuiInputBase-input": {
                  pt: 2,
                  pb: 2,
                  pl: 3,
                  pr: 3,
                  height: "2.25rem",
                  boxSizing: "border-box",
                },
              }}
            />
          </FormControl>
          <CircleIconButton
            onClick={onClickRefreshFilter}
            icon={<CloseIcon />}
            color="primary"
            sx={{ mt: "1.66rem" }}
            size="large"
          />
        </Box>
      </Box>
    </Popover>
  );
};

DatePopoverFilter.defaultProps = {
  filterOperators: allDefaultDateFilterOperators,
};
