import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const CompanyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M12.2772 7.21753V5.21753C12.2772 4.11753 11.3772 3.21753 10.2772 3.21753H4.27716C3.17716 3.21753 2.27716 4.11753 2.27716 5.21753V19.2175C2.27716 20.3175 3.17716 21.2175 4.27716 21.2175H20.2772C21.3772 21.2175 22.2772 20.3175 22.2772 19.2175V9.21753C22.2772 8.11753 21.3772 7.21753 20.2772 7.21753H12.2772ZM6.27716 19.2175H4.27716V17.2175H6.27716V19.2175ZM6.27716 15.2175H4.27716V13.2175H6.27716V15.2175ZM6.27716 11.2175H4.27716V9.21753H6.27716V11.2175ZM6.27716 7.21753H4.27716V5.21753H6.27716V7.21753ZM10.2772 19.2175H8.27716V17.2175H10.2772V19.2175ZM10.2772 15.2175H8.27716V13.2175H10.2772V15.2175ZM10.2772 11.2175H8.27716V9.21753H10.2772V11.2175ZM10.2772 7.21753H8.27716V5.21753H10.2772V7.21753ZM19.2772 19.2175H12.2772V17.2175H14.2772V15.2175H12.2772V13.2175H14.2772V11.2175H12.2772V9.21753H19.2772C19.8272 9.21753 20.2772 9.66753 20.2772 10.2175V18.2175C20.2772 18.7675 19.8272 19.2175 19.2772 19.2175ZM18.2772 11.2175H16.2772V13.2175H18.2772V11.2175ZM18.2772 15.2175H16.2772V17.2175H18.2772V15.2175Z"
        fill="#7E8AA0"
      />
    </SvgIcon>
  );
};
