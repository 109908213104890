import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const TrippleHorizontalDotsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 8.33334C4.08333 8.33334 3.33333 9.08334 3.33333 10C3.33333 10.9167 4.08333 11.6667 5 11.6667C5.91667 11.6667 6.66667 10.9167 6.66667 10C6.66667 9.08334 5.91667 8.33334 5 8.33334ZM15 8.33334C14.0833 8.33334 13.3333 9.08334 13.3333 10C13.3333 10.9167 14.0833 11.6667 15 11.6667C15.9167 11.6667 16.6667 10.9167 16.6667 10C16.6667 9.08334 15.9167 8.33334 15 8.33334ZM10 8.33334C9.08333 8.33334 8.33333 9.08334 8.33333 10C8.33333 10.9167 9.08333 11.6667 10 11.6667C10.9167 11.6667 11.6667 10.9167 11.6667 10C11.6667 9.08334 10.9167 8.33334 10 8.33334Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
