import { styled } from "@mui/material";
import { AgGridReact } from "ag-grid-react";

export const AGGridReactStyled = styled(AgGridReact)(({ theme }) => {
  const { palette, typography } = theme;

  return {
    ".ag-root": {
      border: `.0625rem ${palette.outline} solid`,
    },
    ".ag-row": {
      borderColor: palette.outline,
    },
    ".ag-cell": {
      paddingLeft: ".75rem",
      paddingRight: ".75rem",
      display: "flex",
      alignItems: "center",
      ...typography.body3,
    },
    ".ag-header-cell:not(:last-child)": {
      borderRight: `.0625rem ${palette.outline} solid !important`,
    },
    ".ag-header": {
      borderBottom: `.125rem ${palette.outline} solid !important`,
    },
    ".ag-header-cell": {
      padding: ".625rem .75rem",
    },
    ".ag-right-aligned-cell": {
      justifyContent: "flex-end",
    },
    ".ag-header-cell-text": {
      ...typography.body3,
      fontWeight: 700,
      color: palette.common.black,
    },
    ".ag-header-cell:hover .header-initial-sort-button": {
      display: "inline-flex",
    },
    ".header-action-menu-button": {
      marginLeft: "auto",
    },
    ".ag-right-aligned-header .header-action-menu-button": {
      order: 0,
      marginRight: "auto",
      marginLeft: 0,
    },
    ".ag-right-aligned-header .header-sort-button": {
      order: 1,
    },
    ".ag-right-aligned-header .header-display-name-text": {
      order: 2,
    },
    ".ag-right-aligned-header .header-filter-button": {
      order: 3,
    },
    ".ag-cell-focus:focus": {
      borderColor: `${palette.primary.main} !important`,
    },
    ".ag-keyboard-focus .ag-header-cell:focus::after": {
      width: "100%",
      height: "calc(100% - .3125rem)",
      top: 0,
      left: 0,
      borderColor: palette.primary.main,
    },
    ".ag-overlay-wrapper": {
      alignItems: "start",
      marginTop: "4.5rem",
    },
    ".ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected), .ag-theme-material .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected), .ag-theme-material .ag-ltr .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group, .ag-theme-material .ag-ltr .ag-cell-range-single-cell, .ag-theme-material .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle, .ag-theme-material .ag-rtl .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected), .ag-theme-material .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected), .ag-theme-material .ag-rtl .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group, .ag-theme-material .ag-rtl .ag-cell-range-single-cell, .ag-theme-material .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle":
      {
        borderColor: `${palette.primary.main} !important`,
      },
    ".dark-cell": {
      backgroundColor: palette.disabledLightBackground,
    },
    ".ag-full-width-row .ag-cell-wrapper.ag-row-group": {
      padding: "6px 2px!important",
      backgroundColor: palette.action.hover,
      fontWeight: "bold",
    },
    ".ag-cell-wrapper.ag-row-group": {
      alignItems: "center",
    },
  };
});
