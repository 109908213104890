import {
  Box,
  Grid,
  List,
  ListItem,
  Paper,
  useTheme,
  ListItemText,
} from "@mui/material";
import React from "react";

import { Chip } from "../../common/Chip/Chip";
import { GoalIcon } from "../../common/Icons/GoalIcon";
import { Typography } from "../../common/Typography/Typography";
import { DestinationType, ProjectOutput } from "../../generated";
import { DestinationDownload } from "../../common/Icons/DestinationDownload";
import { Button } from "../../common/Button/Button";
import { CloudConnection } from "../../common/Icons/CloudConnection";
import { CloudConfigured } from "../../common/Icons/CloudConfigured";
import { DOCLink } from "../../common/Link/Link";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { BasicCard } from "../../common/BasicCard/BasicCard";
import { SheetChat } from "../../Sheets/SheetChat";

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};
type ProjectOverviewDetailType = {
  project: RecursivePartial<ProjectOutput>;
  projectId: any;
};

const ProjectOverviewDetails = ({
  project,
  projectId,
}: ProjectOverviewDetailType) => {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        padding: "1.5rem 2rem",
      }}
    >
      <Box display="flex">
        <Typography variant="h6" color={palette.common.black} sx={{ mb: 4 }}>
          Project Summary
        </Typography>
      </Box>
      <Typography
        variant="body1"
        mb={2}
        fontSize="0.875rem"
        color={palette.common.black}
      >
        Description
      </Typography>
      <Typography
        variant="body1"
        mb={6}
        fontSize="1rem"
        color={palette.gray.darkest}
        style={{ wordBreak: "break-word" }}
      >
        {project.description ? project.description : "None"}
      </Typography>
      <Box
        mb={6}
        sx={{
          ".MuiChip-colorDefault": {
            margin: "0 0.5rem 1rem 0",
            borderRadius: "4px",
            background: palette.settings.darkest,
            color: palette.common.black,
            fontWeight: 500,
            fontSize: "0.875rem",
            height: "24px",
          },
          ".MuiChip-label": {
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
          },
        }}
      >
        <Typography
          variant="body1"
          mb={2}
          fontSize="0.875rem"
          fontWeight={500}
          color={palette.common.black}
        >
          Tags
        </Typography>
        {project?.tags && project?.tags?.length > 0 ? (
          project?.tags?.map((tag) => <Chip label={tag} />)
        ) : (
          <Typography
            variant="body1"
            mb={1}
            fontSize="1rem"
            sx={{ marginLeft: "0.5rem" }}
            color={palette.gray.darkest}
          >
            None
          </Typography>
        )}
      </Box>

      <Box mb={8}>
        <Box display="flex">
          <Typography variant="h6" color={palette.common.black} mb={4}>
            Project Goals
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {project.goals && project.goals.length > 0 ? (
            project.goals?.map((goal) => (
              <Grid item xs={6} sx={{ minWidth: "360px" }}>
                <Paper
                  variant="outlined"
                  sx={{
                    p: 4,
                    display: "flex",
                    minHeight: "100%",
                    boxSizing: "border-box",
                  }}
                >
                  <GoalIcon />
                  <Box sx={{ paddingLeft: 3 }}>
                    <Typography variant="subtitle2" mb={2}>
                      {goal?.description}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "0.875rem",
                        fontWeight: 500,
                        color: palette.common.black,
                      }}
                      mb={1}
                    >
                      Metrics of Success
                    </Typography>

                    <List
                      sx={{
                        p: 0,
                        ml: 4,
                        listStyle: "disc",
                        ".MuiListItemText-root": {
                          m: 0,
                          ".MuiTypography-root": {
                            fontSize: "1rem",
                            color: palette.gray.darkest,
                          },
                        },
                      }}
                    >
                      {goal?.metrics?.map((metric) => (
                        <ListItem disablePadding sx={{ display: "list-item" }}>
                          <ListItemText primary={metric} />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Paper>
              </Grid>
            ))
          ) : (
            <Typography
              variant="body1"
              mb={1}
              fontSize="1rem"
              sx={{ marginLeft: "0.5rem" }}
              color={palette.gray.darkest}
            >
              None
            </Typography>
          )}
        </Grid>
      </Box>

      <Box mb={8}>
        <Box display="flex">
          <Typography
            variant="h6"
            color={palette.common.black}
            sx={{ fontSize: "1rem" }}
            mb={4}
            mt={2}
          >
            Project Data products ({project?.dataProductCount})
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {project?.destinations && project?.destinations.length > 0 ? (
            project?.destinations?.map((product) => (
              <Grid item xs={6} sx={{ minWidth: "360px" }}>
                <Paper
                  variant="outlined"
                  sx={{
                    p: 4,
                    display: "flex",
                    minHeight: "100%",
                    boxSizing: "border-box",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box display="flex">
                    <Box
                      mr={3}
                      width="2rem"
                      sx={{
                        ".MuiSvgIcon-root": {
                          minWidth: "2rem",
                          width: "2rem",
                          height: "2rem",
                        },
                      }}
                    >
                      {product?.destinationType === DestinationType.Etl ? (
                        <CloudConnection />
                      ) : (
                        // ) : product?.destinationType === DestinationType.Etl ? (
                        //   <CloudConfigured />
                        <DestinationDownload />
                      )}
                    </Box>

                    <Box sx={{ paddingLeft: 3 }}>
                      <Typography
                        variant="subtitle2"
                        color={palette.common.black}
                        sx={{
                          fontSize: "0.875rem",
                          fontWeight: 500,
                        }}
                      >
                        {product?.destinationType}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color={palette.common.black}
                        sx={{
                          fontSize: "0.875rem",
                          fontWeight: 400,
                        }}
                      >
                        {product?.name}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color={palette.common.black}
                        sx={{
                          fontSize: "1rem",
                          fontWeight: 400,
                        }}
                      >
                        {product?.description}
                      </Typography>
                    </Box>
                  </Box>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      window.open(product?.destinationUrl || "#", "_blank")
                    }
                    endIcon={
                      <OpenInNewIcon
                        fontSize="small"
                        sx={{ transform: "scale(0.8)" }}
                      />
                    }
                    sx={{
                      ".MuiSvgIcon-root": {
                        fontSize: "1.5rem !important",
                      },
                    }}
                  ></Button>
                </Paper>
              </Grid>
            ))
          ) : (
            <Typography
              variant="body1"
              mb={1}
              fontSize="1rem"
              sx={{ marginLeft: "0.5rem" }}
              color={palette.gray.darkest}
            >
              None
            </Typography>
          )}
        </Grid>
      </Box>
      <Box
        sx={{
          ".MuiChip-colorDefault": {
            margin: "0 0.5rem 1rem 0",
            borderRadius: "4px",
            background: palette.settings.darkest,
            color: palette.common.black,
            fontWeight: 500,
            fontSize: "0.875rem",
            height: "24px",
          },
          ".MuiChip-label": {
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
          },
        }}
      >
        <Box display="flex" mb={4}>
          <Typography
            variant="body2"
            color={palette.common.black}
            fontWeight={500}
          >
            Data product details
          </Typography>
        </Box>
        <Box mb={4}>
          <Typography
            variant="subtitle3"
            mb={2}
            fontSize="0.875rem"
            fontWeight={500}
            color={palette.common.black}
          >
            Data product type
          </Typography>
          <Typography variant="body2" color={palette.gray.darkest} mb={1}>
            {project?.dataProductType === "FINAL_DATA_PRODUCT"
              ? "Final data product(s)"
              : "Proof of Concept"}
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography
            variant="subtitle3"
            mb={2}
            fontSize="0.875rem"
            fontWeight={500}
            color={palette.common.black}
          >
            Data sensitivity
          </Typography>
          <Box mt={2}>
            {project?.sensitivity && project?.sensitivity.length > 0 ? (
              project?.sensitivity?.map((tag) => <Chip label={tag} />)
            ) : (
              <Typography
                variant="body1"
                mb={1}
                fontSize="1rem"
                color={palette.gray.darkest}
              >
                None
              </Typography>
            )}
          </Box>
        </Box>
        <Box mb={4}>
          <Typography
            variant="subtitle3"
            mb={2}
            fontSize="0.875rem"
            fontWeight={500}
            color={palette.common.black}
          >
            Data consumers
          </Typography>
          <Box mt={2}>
            {project?.dataConsumers && project?.dataConsumers ? (
              project?.dataConsumers?.map((tag) => <Chip label={tag} />)
            ) : (
              <Typography
                variant="body1"
                mb={1}
                fontSize="1rem"
                color={palette.gray.darkest}
              >
                None
              </Typography>
            )}
          </Box>
        </Box>
      </Box>

      <Box>
        <Box display="flex">
          <Typography
            variant="h6"
            fontWeight={500}
            color={palette.common.black}
            mb={4}
          >
            Resources
          </Typography>
        </Box>
        <Typography
          variant="subtitle3"
          mb={2}
          fontSize="0.875rem"
          fontWeight={500}
          color={palette.common.black}
        >
          Background Material
        </Typography>
        {project?.backgroundMaterials &&
        project?.backgroundMaterials.length > 0 ? (
          <nav aria-label="Proof of concenpt Links">
            <List sx={{ p: 0 }}>
              {project?.backgroundMaterials?.map((material) => (
                <ListItem disablePadding>
                  {material && material.name && material.href && (
                    <DOCLink
                      name={material?.name}
                      onClick={() => window.open(material?.href, "_blank")}
                      href={material?.href || ""}
                      target="_blank"
                    ></DOCLink>
                  )}
                </ListItem>
              ))}
            </List>
          </nav>
        ) : (
          <Typography
            variant="body1"
            mb={1}
            fontSize="1rem"
            color={palette.gray.darkest}
          >
            None
          </Typography>
        )}
      </Box>
      <BasicCard
        sx={{
          padding: "1rem 1.5rem",
          mb: 5,
          mt: 5,
          ".str-chat__ul": { maxHeight: "250px", overflow: "auto" },
        }}
      >
        <Typography variant="h6" mb={5}>
          Discussion
        </Typography>
        {projectId && <SheetChat staticInput id={projectId} />}
      </BasicCard>
    </Box>
  );
};
export default ProjectOverviewDetails;
