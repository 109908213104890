import { Chip } from "../../Chip/Chip";
import CheckIcon from "@mui/icons-material/Check";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { Tooltip, useTheme } from "@mui/material";

interface StatusCellProps {
  value: string;
}

export const StatusCell = ({ value }: StatusCellProps) => {
  const { palette } = useTheme();
  let icon = <InfoOutlinedIcon />;
  let color = "error";

  if (value?.toLowerCase() === "Connected".toLowerCase()) {
    icon = <CheckIcon />;
    color = "primary";
  }

  if (value?.toLowerCase() === "Enabled".toLowerCase()) {
    icon = <CheckIcon />;
    color = "primary";
  }

  if (value?.toLowerCase() === "Disabled".toLowerCase()) {
    icon = <DoNotDisturbIcon />;
    color = "error";
  }

  return (
    <>
      {value?.toLowerCase() === "unknown" ? (
        <Tooltip
          placement="top"
          componentsProps={{
            tooltip: { sx: { backgroundColor: palette.common.black } },
            arrow: { sx: { color: palette.common.black } },
          }}
          arrow
          title="This service does not currently support real-time status updates"
        >
          <div>
            <Chip label={value} icon={icon} color={color as any} />
          </div>
        </Tooltip>
      ) : (
        <Chip label={value} icon={icon} color={color as any} />
      )}
    </>
  );
};
