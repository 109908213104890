import { Box } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Alert } from "../common/Alert";
import { SimpleLoader } from "../common/Loader/Loader";

export enum IssueStatus {
  None = "NONE",
  Major = "MAJOR",
  Minor = "MINOR",
  Incomplete = "INCOMPLETE",
}

export enum IssueStatusText {
  None = "No Issue",
  Major = "Major issue",
  Minor = "Minor issue",
  Incomplete = "incomplete",
}

export const getLabelIssueStatus = (issueStatus: string) => {
  if (issueStatus === IssueStatus.None) {
    return IssueStatusText.None;
  } else if (issueStatus === IssueStatus.Major) {
    return IssueStatusText.Major;
  } else if (issueStatus === IssueStatus.Minor) {
    return IssueStatusText.Minor;
  } else {
    return "";
  }
};

export const getIssueColor = (issueStatus: string) => {
  if (issueStatus === IssueStatus.None) {
    return "primary";
  } else if (issueStatus === IssueStatus.Major) {
    return "error";
  } else if (issueStatus === IssueStatus.Minor) {
    return "warning";
  } else {
    return "primary";
  }
};

export const getIssueIcon = (issueStatus: string) => {
  if (issueStatus === IssueStatus.None) {
    return <DoneIcon sx={{ fontSize: "1rem" }} />;
  } else if (issueStatus === IssueStatus.Major) {
    return <ErrorOutlineOutlinedIcon sx={{ fontSize: "1rem" }} />;
  } else if (issueStatus === IssueStatus.Minor) {
    return <ReportProblemOutlinedIcon sx={{ fontSize: "1rem" }} />;
  } else {
    return <DoneIcon sx={{ fontSize: "1rem" }} />;
  }
};

export const getAlertMessage = (issueStatus: string, issueMessage: string) => {
  if (issueStatus === IssueStatus.Major) {
    return (
      <Alert severity="error" sx={{ marginBottom: "16px" }}>
        {" "}
        {issueMessage}
      </Alert>
    );
  } else if (issueStatus === IssueStatus.Minor) {
    return (
      <Alert severity="warning" sx={{ marginBottom: "16px" }}>
        {" "}
        {issueMessage}
      </Alert>
    );
  } else {
    return "";
  }
};

export const getLoader = (
  text: string,
  className?: string,
  customTextClass?: string
) => {
  return (
    <Box
      display="flex"
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <SimpleLoader
        text={text}
        className={className}
        customTextClass={customTextClass}
      />
    </Box>
  );
};

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;
