import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const NavBuildViiIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21.08 25.24L13.82 17.92C13.42 18.08 13.0152 18.2 12.6056 18.28C12.196 18.36 11.7608 18.4 11.3 18.4C9.30001 18.4 7.60001 17.7 6.20001 16.3C4.80001 14.9 4.10001 13.2 4.10001 11.2C4.10001 10.48 4.20001 9.7952 4.40001 9.1456C4.60001 8.496 4.88001 7.8808 5.24001 7.3L9.62001 11.68L11.78 9.52L7.40001 5.14C7.98001 4.78 8.59521 4.5 9.24561 4.3C9.89601 4.1 10.5808 4 11.3 4C13.3 4 15 4.7 16.4 6.1C17.8 7.5 18.5 9.2 18.5 11.2C18.5 11.66 18.46 12.0952 18.38 12.5056C18.3 12.916 18.18 13.3208 18.02 13.72L25.34 20.98C25.58 21.22 25.7 21.51 25.7 21.85C25.7 22.19 25.58 22.48 25.34 22.72L22.82 25.24C22.58 25.48 22.29 25.6 21.95 25.6C21.61 25.6 21.32 25.48 21.08 25.24ZM21.95 22.69L22.76 21.88L15.08 14.2C15.44 13.8 15.7 13.3352 15.86 12.8056C16.02 12.276 16.1 11.7408 16.1 11.2C16.1 10 15.7152 8.9552 14.9456 8.0656C14.176 7.176 13.2208 6.6408 12.08 6.46L14.3 8.68C14.54 8.92 14.66 9.2 14.66 9.52C14.66 9.84 14.54 10.12 14.3 10.36L10.46 14.2C10.22 14.44 9.94001 14.56 9.62001 14.56C9.30001 14.56 9.02001 14.44 8.78001 14.2L6.56001 11.98C6.74001 13.12 7.27521 14.0752 8.16561 14.8456C9.05601 15.616 10.1008 16.0008 11.3 16C11.82 16 12.34 15.92 12.86 15.76C13.38 15.6 13.85 15.35 14.27 15.01L21.95 22.69Z" fill="currentColor"/>
      <path d="M21.95 22.6909L22.76 21.8809L15.08 14.2009C15.44 13.8009 15.7 13.3361 15.86 12.8065C16.02 12.2769 16.1 11.7417 16.1 11.2009C16.1 10.0009 15.7152 8.95614 14.9456 8.06654C14.176 7.17694 13.2208 6.64174 12.08 6.46094L14.3 8.68094C14.54 8.92094 14.66 9.20094 14.66 9.52094C14.66 9.84094 14.54 10.1209 14.3 10.3609L10.46 14.2009C10.22 14.4409 9.94 14.5609 9.62 14.5609C9.3 14.5609 9.02 14.4409 8.78 14.2009L6.56 11.9809C6.74 13.1209 7.2752 14.0761 8.1656 14.8465C9.056 15.6169 10.1008 16.0017 11.3 16.0009C11.82 16.0009 12.34 15.9209 12.86 15.7609C13.38 15.6009 13.85 15.3509 14.27 15.0109L21.95 22.6909Z" fill="currentColor" fillOpacity="0.16"/>
    </SvgIcon>
  );
};
