import React, { useState } from "react";
import { Box, IconButton, useTheme } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { Button } from "../../common/Button/Button";
import { Label } from "../../common/Label/Label";
import { Field, Formik, Form } from "formik";
import { TextField } from "formik-mui";
import { TextField as MaterialUITextField } from "@mui/material";
import { MAX_DESCRIPTION_CHARS } from "../../Constants";
import * as Yup from "yup";
import { Tag } from "../../common/Tag/Tag";
import {
  GetResourcesDocument,
  MutationRemoveSchemaFromResourceArgs,
  MutationRemoveTermFromResourceArgs,
  RemoveSchemaFromResourceDocument,
  RemoveTermFromResourceDocument,
  ResourceOutput,
  ResourceType,
  UpdateResourceDocument,
} from "../../generated";
import { useParams } from "react-router-dom";
import { NetworkStatus, useMutation, useQuery } from "@apollo/client";
import { LinkTermModal } from "./modals/LinkTermModal";
import { DocumentResourceIcon } from "../../common/Icons/DocumentResourceIcon";
import { Close, LocalTaxiRounded } from "@mui/icons-material";
import { useConfirm } from "../../contexts/confirmation";
import { LoadingOverlay } from "../../common/AGGridWrapper/LoadingOverlay";
import { LinkSchemaModal } from "./modals/LinkSchemaModal";
import { ButtonDefault, ButtonPrimary } from "./style";

interface ModalSchemaFieldProps {
  resourceId: string;
  closeDialog: Function;
}

export const EditResource = ({
  resourceId,
  closeDialog,
}: ModalSchemaFieldProps) => {
  const [tag, setTag] = useState("");
  const [linkingTerm, setLinkingTerm] = useState(false);
  const [linkingSchema, setLinkingSchema] = useState(false);
  const { palette } = useTheme();
  const { confirm } =
    useConfirm<{ variables: MutationRemoveTermFromResourceArgs }>();

  const { confirm: confirmDeleteSchema } =
    useConfirm<{ variables: MutationRemoveSchemaFromResourceArgs }>();
  const { workspaceId } = useParams<{ workspaceId: string }>();
  const [updateResource] = useMutation(UpdateResourceDocument, {
    refetchQueries: [GetResourcesDocument],
  });
  const [delink] = useMutation(RemoveTermFromResourceDocument, {
    refetchQueries: [GetResourcesDocument],
  });
  const [delinkSchema] = useMutation(RemoveSchemaFromResourceDocument, {
    refetchQueries: [GetResourcesDocument],
  });
  const { data, loading } = useQuery(GetResourcesDocument, {
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
      resourceFilter: {
        id: resourceId,
      },
    },
  });
  if (loading && !data) {
    return <LoadingOverlay />;
  }
  const resource = data?.workspace?.resources?.[0] as ResourceOutput;

  return (
    <>
      {linkingTerm && (
        <LinkTermModal
          resourceId={resourceId}
          onClose={() => setLinkingTerm(false)}
        />
      )}
      {linkingSchema && (
        <LinkSchemaModal
          resourceId={resourceId}
          onClose={() => setLinkingSchema(false)}
        />
      )}
      <Box
        boxSizing="border-box"
        sx={{
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mb={6}>
          <Box
            sx={{
              paddingRight: "20px",
              paddingBottom: "16px",
              borderBottom: `1px solid ${palette.outline}`,
            }}
            mb={6}
          >
            <Typography
              mb={2}
              variant="h5"
              color={palette.gray.dark}
              sx={{
                fontSize: "12px",
                lineHeight: "14px",
                textTransform: "uppercase",
                fontWeight: "700",
              }}
            >
              Edit Document Info
            </Typography>
            <Typography
              mb={4}
              variant="h5"
              color={palette.common.black}
              sx={{ wordBreak: "break-word" }}
            >
              {resource.name}
            </Typography>
          </Box>
          <Formik
            validationSchema={Yup.object().shape({
              name: Yup.string().required(),
              description: Yup.string().max(MAX_DESCRIPTION_CHARS),
              type: Yup.string().oneOf(Object.values(ResourceType)),
              tags: Yup.array()
                .of(Yup.object({ id: Yup.string(), name: Yup.string() }))
                .required(),
            })}
            initialValues={{
              name: resource?.name || "",
              description: resource.description || "",
              tags: (resource.tags || []) as Array<{
                id: string;
                name: string;
              }>,
            }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                setSubmitting(true);
                await updateResource({
                  variables: {
                    input: {
                      resourceId,
                      workspaceId: workspaceId!,
                      ...values,
                      tags: values.tags.map((t) => t.name),
                    },
                  },
                });
                setSubmitting(false);
                closeDialog();
              } catch (err) {
                setSubmitting(false);
              }
            }}
          >
            {({
              values,
              submitForm,
              setFieldValue,
              handleSubmit,
              isSubmitting,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Box width="100%" boxSizing="border-box">
                    <Box mt={4}>
                      <Label
                        mb={1}
                        value="Name"
                        width={"100%"}
                        sx={{
                          fontSize: "0.875rem",
                          fontWeight: "400",
                        }}
                      />
                      <Field
                        component={TextField}
                        name="name"
                        hiddenLabel
                        fullWidth
                        inputProps={{
                          sx: { fontSize: "1rem", padding: ".375rem .75rem" },
                        }}
                        size="small"
                        sx={{ mb: 4 }}
                      />
                      <Label
                        mb={1}
                        value="Description"
                        width={"100%"}
                        sx={{
                          fontSize: "0.875rem",
                          fontWeight: "400",
                        }}
                      />
                      <Field
                        component={TextField}
                        name="description"
                        hiddenLabel
                        rows={3}
                        fullWidth
                        multiline
                        inputProps={{
                          sx: { fontSize: "1rem" },
                          maxLength: MAX_DESCRIPTION_CHARS,
                        }}
                        size="small"
                        sx={{ mb: 2 }}
                      />
                      <Typography
                        variant="body3"
                        color={palette.gray.dark}
                        sx={{ mb: 4 }}
                      >
                        {values.description.length}/{MAX_DESCRIPTION_CHARS}{" "}
                        characters
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginBottom: "24px",
                      }}
                    >
                      <Label
                        mb={1}
                        value="Tags"
                        width={"100%"}
                        sx={{
                          fontSize: "0.875rem",
                          fontWeight: "400",
                        }}
                      />
                      <Box sx={{ mb: 4 }}>
                        <MaterialUITextField
                          hiddenLabel
                          fullWidth
                          value={tag}
                          placeholder="Add tags here"
                          onChange={(e) => setTag(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              setFieldValue("tags", [
                                ...(values.tags || []),
                                { name: tag },
                              ]);
                              setTag("");
                            }
                          }}
                          inputProps={{
                            sx: { fontSize: "1rem", padding: ".375rem .75rem" },
                          }}
                          size="small"
                        />
                        <Box display={"flex"} pt={"1.125rem"}>
                          {values.tags.map(
                            (tag: { id: string; name: string }) => (
                              <Tag
                                label={tag.name}
                                dismissable={true}
                                mr={2}
                                onClickDismiss={() => {
                                  setFieldValue("tags", [
                                    ...(values.tags.filter(
                                      (aTag: { name: string }) =>
                                        tag.name !== aTag.name
                                    ) || []),
                                  ]);
                                }}
                              ></Tag>
                            )
                          )}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          marginBottom: "24px",
                        }}
                      >
                        <Label
                          mb={1}
                          value="Linked Terms"
                          width={"100%"}
                          sx={{
                            fontSize: "0.875rem",
                            fontWeight: "400",
                          }}
                        />
                        <Box>
                          {resource?.terms?.map((t) => {
                            return (
                              <Box sx={{ display: "flex" }}>
                                <Box
                                  sx={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    fontSize: "0.875rem",
                                    fontWeight: "400",
                                    marginBottom: "5px",
                                    cursor: "pointer",
                                    "&:hover .dismiss": {
                                      visibility: "visible",
                                    },
                                  }}
                                >
                                  <DocumentResourceIcon />
                                  {t?.name}
                                  <Box
                                    onClick={() =>
                                      confirm({
                                        onYes: delink,
                                        message: `Are you sure you want to delink ${t?.name} from this resource?`,
                                        data: {
                                          variables: {
                                            input: {
                                              resourceId,
                                              termId: t.id,
                                            },
                                          },
                                        },
                                      })
                                    }
                                    className="dismiss"
                                    sx={{
                                      visibility: "hidden",
                                      display: "flex",
                                      alignItems: "center",
                                      ".MuiSvgIcon-root": {
                                        fontSize: "1rem",
                                        ml: 1,
                                      },
                                    }}
                                  >
                                    <IconButton
                                      disableRipple
                                      sx={{
                                        padding: 0,
                                        color: palette.common.black,
                                      }}
                                    >
                                      <Close />
                                    </IconButton>
                                  </Box>
                                </Box>
                              </Box>
                            );
                          })}
                          <ButtonPrimary
                            onClick={() => setLinkingTerm(true)}
                            color="primary"
                            disabled={linkingTerm || linkingSchema || loading}
                            variant="contained"
                          >
                            Link Term
                          </ButtonPrimary>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          marginBottom: "24px",
                        }}
                      >
                        <Label
                          mb={1}
                          value="Linked Schemas"
                          width={"100%"}
                          sx={{
                            fontSize: "0.875rem",
                            fontWeight: "400",
                          }}
                        />
                        <Box>
                          {resource?.schemas?.map((t) => {
                            return (
                              <Box sx={{ display: "flex" }}>
                                <Box
                                  sx={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    fontSize: "0.875rem",
                                    fontWeight: "400",
                                    marginBottom: "5px",
                                    cursor: "pointer",
                                    "&:hover .dismiss": {
                                      visibility: "visible",
                                    },
                                  }}
                                >
                                  <DocumentResourceIcon />
                                  {t?.name}
                                  <Box
                                    onClick={() =>
                                      confirmDeleteSchema({
                                        onYes: delinkSchema,
                                        message: `Are you sure you want to delink schema "${t?.name}"?`,
                                        data: {
                                          variables: {
                                            input: {
                                              resourceId,
                                              schemaId: t.id,
                                            },
                                          },
                                        },
                                      })
                                    }
                                    className="dismiss"
                                    sx={{
                                      visibility: "hidden",
                                      display: "flex",
                                      alignItems: "center",
                                      ".MuiSvgIcon-root": {
                                        fontSize: "1rem",
                                        ml: 1,
                                      },
                                    }}
                                  >
                                    <IconButton
                                      disableRipple
                                      sx={{
                                        padding: 0,
                                        color: palette.common.black,
                                      }}
                                    >
                                      <Close />
                                    </IconButton>
                                  </Box>
                                </Box>
                              </Box>
                            );
                          })}
                          <ButtonPrimary
                            onClick={() => setLinkingSchema(true)}
                            color="primary"
                            disabled={linkingSchema || linkingTerm || loading}
                            variant="contained"
                          >
                            Link Schema
                          </ButtonPrimary>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <ButtonDefault
                    onClick={() => closeDialog()}
                    color="grayAccent"
                    variant="outlined"
                    sx={{ ml: "auto", mr: 3 }}
                  >
                    Cancel
                  </ButtonDefault>
                  <ButtonPrimary
                    disabled={isSubmitting}
                    onClick={submitForm}
                    color="primary"
                    variant="contained"
                  >
                    Update
                  </ButtonPrimary>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </>
  );
};
