import { Box, IconButton, useTheme } from "@mui/material";
import { NavbarMenu, NavbarMenuProps } from "./NavbarMenu";
import { Typography } from "../Typography/Typography";
import NotificationsIcon from "@mui/icons-material/Notifications";
import InfoIcon from "@mui/icons-material/Info";
import { useState } from "react";
import Notification from "../Notification";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useWorkspaceId } from "../../hooks/useWorkspace";
import { BreadCrumbs } from "./BreadCrumbs";
import { BrighthiveIcon } from "../Icons/BrighthiveIcon";
import { Support } from "./Support";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";
import { useGetUserRole } from "../../hooks/useGetUserRole";
import { ActionBtnsWrapper, ButtonDropIcon, ButtonDropWrapper, NavbarWrapper } from "./style";

export type NavBarProps = NavbarMenuProps;

export const Navbar = ({
  username,
  emailAddress,
  multipleWorkspaces,
}: NavBarProps) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { workspaceId } = useWorkspaceId();
  const { accessible } = useGetUserRole(workspaceId);
  const [openNotification, setOpenNotification] = useState(false);
  const [openSupport, setSupport] = useState(false);

  const haveNotifications = false;
  return (
    <>
      {/* */}
      <NavbarWrapper
        className="NavbarWrapper"
        aria-label="global navigation and utility menu"
        role="banner"
      >
        <BreadCrumbs />
        <ActionBtnsWrapper>
          {false && (
            <Box display="flex" alignItems="center" marginRight="32px">
              <Box position="relative">
                <IconButton
                  size="small"
                  onClick={() => setOpenNotification(true)}
                >
                  {haveNotifications ? (
                    <NotificationsNoneIcon color="grayAccent" />
                  ) : (
                    <Box>
                      <NotificationsIcon color="grayAccent" />
                      <Box
                        sx={{
                          position: "absolute",
                          background: "white",
                          width: 13,
                          height: 13,
                          border: `0.5px solid ${palette.common.white}`,
                          borderRadius: 13,
                          bottom: 3,
                          right: 3,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <InfoIcon
                          sx={{ width: 13, height: 13 }}
                          color="primary"
                        />
                      </Box>
                    </Box>
                  )}
                </IconButton>
              </Box>
              <Typography variant="body3" fontWeight="bold">
                Notifications
              </Typography>
            </Box>
          )}
          <ButtonDropWrapper>
            <ButtonDropIcon
              size="small"
              className="small"
              onClick={() => setSupport((prev) => !prev)}
            >
              ?
            </ButtonDropIcon>
          </ButtonDropWrapper>
          <ButtonDropWrapper>
            {accessible && (
              <ButtonDropIcon
                size="small"
                className="settings-button small"
                onClick={() =>
                  navigate(`/workspace/${workspaceId}/workspace-settings`)
                }
              >
                <SettingsIcon />
              </ButtonDropIcon>
            )}
          </ButtonDropWrapper>
          <NavbarMenu
            username={username}
            emailAddress={emailAddress}
            multipleWorkspaces={multipleWorkspaces}
          />
        </ActionBtnsWrapper>
      </NavbarWrapper>
      <Notification
        open={openNotification}
        onClose={() => setOpenNotification(false)}
      />
      <Support open={openSupport} onClose={() => setSupport(false)} />
    </>
  );
};
