import { Box, styled } from "@mui/material";
import { ArrowBack, Block } from "@mui/icons-material";
import { brighthiveTheme } from "../../theme/theme";
export const FooterHolder = styled(Box)`
  &.footer-holder {
    display: flex;
    @media (max-width: 992px) {
      display: block;
    }
  }
`;
