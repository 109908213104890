import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const NavConnectViiIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.6107 25.5033C9.74891 28.3651 6.88713 27.7927 4.59772 25.5033C2.3083 23.2139 1.73594 20.3521 4.59772 17.4904L8.03184 14.0562L16.0448 22.0692L12.6107 25.5033ZM17.1895 4.89856C20.0513 2.03679 22.9131 2.60914 25.2036 4.89856C27.4942 7.18798 28.0642 10.0497 25.2036 12.9115L21.7695 16.3456L13.7554 8.33268L17.1895 4.89856Z" fill="currentColor" fillOpacity="0.16"/>
      <path fill="none" d="M13.7554 12.9115L10.8936 15.7733M17.1895 16.3456L14.3277 19.2074M12.6107 25.5033C9.74891 28.3651 6.88713 27.7927 4.59772 25.5033C2.3083 23.2139 1.73594 20.3521 4.59772 17.4904L8.03184 14.0562L16.0448 22.0692L12.6107 25.5033ZM17.1895 4.89856C20.0513 2.03679 22.9131 2.60914 25.2036 4.89856C27.4942 7.18798 28.0642 10.0497 25.2036 12.9115L21.7695 16.3456L13.7554 8.33268L17.1895 4.89856Z" stroke="currentColor" strokeWidth="2.28942"/>
    </SvgIcon>
  );
};
