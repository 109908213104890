import { Box, FormControlLabel, styled } from "@mui/material";
import { Label } from "../../../common/Label/Label";

export const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  width: "100%",
  mb: 6,
  ".MuiTypography-root": {
    fontSize: "0.875rem",
    lineHeight: 1.2,
  },
}));

export const ViewDrawerContainer = styled(Box)(() => ({
  wordBreak: "break-word",
}));

export const FieldContainer = styled(Box)(() => ({
  marginTop: "1rem",
}));

export const StyledPathLinkContainer = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  alignItems: "center",
  ".name, .name-link": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.accent.darkest,
    textDecoration: "none",

    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export const StyledLinkContainer = styled(Box)(() => ({
  display: "flex",
}));

export const StyledLinkSubContainer = styled(Box)(() => ({
  display: "inline-flex",
  alignItems: "center",
  fontSize: "0.875rem",
  fontWeight: "400",
  marginBottom: "5px",
  "&:hover .dismiss": {
    visibility: "visible",
  },
}));

export const StyledLinkedLabel = styled(Label)(() => ({
  fontSize: "0.875rem",
  fontWeight: "400",
  width: "100%",
}));
