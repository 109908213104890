import { Box, Divider, Tooltip, useTheme } from "@mui/material";
import { Sidenav } from "../Sidenav/Sidenav";
import { SquareIconButton } from "../SquareIconButton/SquareIconButton";
import { SidenavButtonLink } from "../Sidenav/SidenavButtonLink";
import { Typography } from "../Typography/Typography";
import { useLocation, useParams } from "react-router-dom";
import { useGetUserRole } from "../../hooks/useGetUserRole";
import { CollapsableNav } from "../SideNavigationPage/CollapsableNav";
import { SourcesIcon } from "../Icons/SourcesIcon";
import { AllSources } from "../Icons/AllSources";
import { Internal } from "../Icons/Internal";
import { Marketplace } from "../Icons/Marketplace";
import { Partner } from "../Icons/Partner";
import { Subscriptions } from "../Icons/Subscriptions";
import { TopNavWrapper } from "./style";

export interface WorkspaceSettingNavProps {
  workspaceName: string;
  show: boolean;
  setShowSidenav: Function;
}

export const WorkspaceSettingNav = ({
  workspaceName,
  show,
  setShowSidenav,
}: WorkspaceSettingNavProps) => {
  const { palette } = useTheme();
  const { workspaceId } = useParams();
  const { pathname } = useLocation();
  const { loading } = useGetUserRole(workspaceId);

  return (
    <Sidenav
      show={show}
      aria-label={workspaceName}
      sx={{
        transition: "all ease-in-out 0.25s",

        "@media (min-width: 1200px)": {
          width: "100%",
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        sx={{
          position: "relative",
        }}
      >
        <Box alignItems="center" p={2} display="none">
          <SquareIconButton
            variant="contained"
            size="medium"
            icon={<SourcesIcon />}
            color="settings"
            sx={{
              textTransform: "initial",
              pointerEvents: "none",
              boxShadow: "none",
              background: palette.customRolled.light,
              minWidth: "36px",
              height: "36px",
              marginRight: "8px",

              "@media (min-width: 992px)": {
                marginRight: show ? "8px" : "0",
              },
            }}
          />
          <Box display="flex" flexDirection="column" flex={1}>
            <Typography
              variant="subtitle3"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "165px",
                width: "100%",
                opacity: show ? "0" : "1",
                visibility: show ? "hidden" : "visible",
                transition: "opacity 0.3s ease, visibility 0.3s ease",

                "@media (min-width: 992px)": {
                  opacity: show ? "1" : "0",
                  visibility: show ? "visible" : "hidden",
                },
              }}
            >
              <Tooltip
                placement="bottom"
                title="Sources"
                componentsProps={{
                  tooltip: { sx: { backgroundColor: palette.common.black } },
                  arrow: { sx: { color: palette.common.black } },
                }}
                arrow
              >
                <span>Settings</span>
              </Tooltip>
            </Typography>
          </Box>
        </Box>
        {/* <Divider /> */}
        {!loading && (
          <TopNavWrapper
          >
            <SidenavButtonLink
              to={`/workspace/${workspaceId}/workspace-settings`}
              icon={<AllSources />}
              label="General"
              className={
                pathname.includes("workspace-settings") ? "active tabLink" : " tabLink"
              }
              active={pathname.includes("workspace-settings")}
            />
          </TopNavWrapper>
        )}
        {/* <CollapsableNav
          sideNav
          expanded={show}
          onClickToggleNav={() => setShowSidenav(!show)}
        /> */}
      </Box>
    </Sidenav>
  );
};
