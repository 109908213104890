import { dataAssetsMock } from "./graphql/mocks/dataAssets";
import { schemaMock } from "./graphql/mocks/schema";
import { workspaceMembersMock } from "./graphql/mocks/workspaceMembers";
import { workflowMock } from "./graphql/mocks/workflow";

export const mocks = [
  dataAssetsMock,
  schemaMock,
  workspaceMembersMock,
  workflowMock,
];
