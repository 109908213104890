import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  GetWorkspaceMembersDocument,
  WorkspaceRoleEnum,
} from "../../generated";
import { useParams } from "react-router-dom";
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  useTheme,
} from "@mui/material";
import { ChipsBox } from "./style";
import { Tag } from "../Tag/Tag";

export const SchemaManagers = ({ defaultMembers, onChange, onDelete }: any) => {
  const { palette } = useTheme();
  const { workspaceId, projectId } = useParams();
  const [memberId, setMemberId] = useState<string[]>();
  const [editManagers, setEditManagers] = useState(false);

  const [selectedMembers, setSelectedMembers] = useState<any>([]);

  const { data } = useQuery(GetWorkspaceMembersDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
    },
  });

  const members = data?.workspace?.members.filter(
    (member) =>
      member.status === "ACTIVE" &&
      (member.workspaceRole === WorkspaceRoleEnum.Admin ||
        member.workspaceRole === WorkspaceRoleEnum.Collaborator)
  );

  // const managers = data?.workspace?.schemas?.filter((manager) =>
  //   manager?.find((mn: UserOutput) => manager?.id !== mn.id)
  // );

  const handleSelect = (event: any) => {
    setSelectedMembers(event.target.value);
  };

  const handleDelete = (memberToDelete: any) => {
    // event.stopPropagation();

    setSelectedMembers((prev: any) =>
      prev.filter((member: any) => member.id !== memberToDelete.id)
    );

    onDelete(memberToDelete.id);
  };

  useEffect(() => {
    setSelectedMembers(defaultMembers);
  }, [defaultMembers]);

  useEffect(() => {
    onChange(selectedMembers);
  }, [onChange, selectedMembers]);

  return (
    <Box sx={{ width: "100%" }}>
      <FormControl fullWidth>
        <InputLabel>Select Member</InputLabel>
        <Select
          label="Select Member"
          multiple
          value={selectedMembers}
          onChange={handleSelect}
          input={<OutlinedInput label="Select Member" />}
          renderValue={(selected) => (
            <ChipsBox>
              {selected.map((member: any) => (
                <Tag
                  label={`${member.firstName} ${member.lastName}`}
                  dismissable={true}
                  mr={2}
                  onClickDismiss={() => {
                    handleDelete(member);
                  }}
                  onMouseDown={(event) => {
                    // Prevent dropdown from opening when delete button is clicked
                    event.stopPropagation();
                  }}
                ></Tag>
              ))}
            </ChipsBox>
          )}
        >
          {members?.map((member) => (
            <MenuItem
              key={member.id}
              value={member as any}
              disabled={selectedMembers.some(
                (selected: any) => selected.id === member.id
              )}
            >
              {`${member.firstName} ${member.lastName}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
