import React, { useState } from "react";
import { IconButton, OutlinedInput, OutlinedInputProps } from "@mui/material";

import { SearchIcon } from "../Icons/SearchIcon";
import { CloseIcon } from "../Icons/CloseIcon";
import { brighthiveTheme } from "../../theme/theme";

type SearchBarProps = OutlinedInputProps;

export const SearchBar = ({ sx, ...rest }: SearchBarProps) => {
  const [value, setValue] = useState("");

  return (
    <OutlinedInput
      value={value}
      onChange={(e) => setValue(e.target.value)}
      startAdornment={
        <SearchIcon
          sx={{
            width: "1.042rem",
            height: "1.042rem",
            mr: "0.354rem",
          }}
        />
      }
      endAdornment={
        <IconButton
          className={`close-button ${value.length > 0 ? "show" : ""}`}
          sx={{
            p: 0,
            mr: 0,
            backgroundColor: "transparent",
            "&:hover": { backgroundColor: "transparent" },
            "&:focus": { backgroundColor: "transparent" },
            "&:active": { backgroundColor: "transparent" },
          }}
          onClick={() => setValue("")}
        >
          <CloseIcon
            sx={{
              width: "1.042rem",
              height: "1.042rem",
              mr: "0.354rem",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          />
        </IconButton>
      }
      sx={{
        ".MuiOutlinedInput-input": {
          p: 0,
        },
        ".close-button": {
          visibility: "hidden",
        },
        ".close-button.show": {
          visibility: "visible",
        },
        pt: ".5rem",
        pb: ".5rem",
        pl: ".85rem",
        pr: ".5rem",
        borderRadius: ".188rem",
        ...brighthiveTheme.typography.body3,
        ...sx,
      }}
      {...rest}
    />
  );
};
