import axios from "axios";
import { useEffect, useState } from "react";
import { FileExportIcon } from "../Icons/FileExportIcon";
import File from "./image.png";
import ImageIcon from "@mui/icons-material/Image";
import { ResourceType } from "../../generated";
import DescriptionIcon from "@mui/icons-material/Description";
import link from "../../assets/link.png";
import VideocamIcon from "@mui/icons-material/Videocam";
import { DocumentResourceIcon } from "../Icons/DocumentResourceIcon";
import { Box, useTheme } from "@mui/material";
import { ImageResourceIcon } from "../Icons/ImageResourceIcon";
import { UrlResourceIcon } from "../Icons/UrlResourceIcon";
import { VideoResourceIcon } from "../Icons/VideoResourceIcon";
import { FolderIcon } from "../Icons/FolderIcon";

export const ResourceHanlder = ({ path, children, isFolder, type }: any) => {
  const [loaded, setLoaded] = useState(true);
  const { palette } = useTheme();

  if (isFolder)
    return (
      <>
        <Box className="resource-icon" sx={{ color: palette.primary.main }}>
          <Box
            sx={{
              background: palette.primary.lighter,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "50%",
              height: "50%",
              maxWidth: "134px",
              maxHeight: "150px",
              padding: "20px",
              fontSize: "20px",
              lineHeight: "1.1",
              borderRadius: "12px",

              svg: {
                width: "70% !important",
                height: "80% !important",
              },
            }}
          >
            <FolderIcon />
          </Box>
        </Box>
      </>
    );

  return (
    <>
      {type === ResourceType.Image && (
        <Box className="resource-icon" sx={{ color: palette.primary.main }}>
          <ImageResourceIcon />
        </Box>
      )}

      {type === ResourceType.Document && (
        <Box className="resource-icon" sx={{ color: palette.primary.main }}>
          <DocumentResourceIcon />
        </Box>
      )}

      {type === ResourceType.Link && (
        <Box className="resource-icon" sx={{ color: palette.primary.main }}>
          <UrlResourceIcon />
        </Box>
      )}

      {type === ResourceType.Video && (
        <Box className="resource-icon" sx={{ color: palette.primary.main }}>
          <VideoResourceIcon />
        </Box>
      )}
    </>
  );
};
