import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { Chip } from "../../common/Chip/Chip";
import PersonIcon from "@mui/icons-material/Person";
import { DataProductSchemaSetDetails } from "./DataProductSchemaSetDetails";
import { EditDataProductSchemaSetDetails } from "./EditDataProductSchemaSetDetails";
import { Button } from "../../common/Button/Button";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GetDataProductAssetDocument } from "../../generated";
import {
  getIssueIcon,
  getIssueColor,
  getLabelIssueStatus,
  getLoader,
} from "../helpers";
import { SheetTabs } from "../SheetTabs";
import { SheetChat } from "../SheetChat";

export const DataProductSchemaSet = ({
  id,
  showToast,
}: {
  id: string;
  showToast: any;
}) => {
  const { palette } = useTheme();
  const { workspaceId, projectId } = useParams();
  const [isEditState, setIsEditState] = useState(false);
  const [isEditable, setIsEditable] = useState(true);
  const [show, setShow] = useState(false);
  const [tab, setTab] = useState(0);

  const toggleEditView = () => {
    setIsEditState(!isEditState);
    setIsEditable(!isEditable);
  };
  const { data, loading } = useQuery(GetDataProductAssetDocument, {
    variables: {
      workflowFilterInput: {
        projectId: projectId || "",
        workspaceId: workspaceId || "",
      },
      finalDataProductGroupFilter: {
        finalDataProductGroupId: id,
      },
    },
  });
  if (loading) return getLoader("Fetching Data", "custom-loader full-height");
  const finalDataProduct = data?.workflow?.finalDataProducts?.[0];
  if (finalDataProduct) {
    return (
      <Box
        boxSizing="border-box"
        sx={{
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            paddingRight: "20px",
            paddingBottom: "16px",
            borderBottom: `1px solid ${palette.outline}`,
          }}
          mb={6}
        >
          <Typography
            mb={2}
            variant="h5"
            color={palette.gray.dark}
            sx={{
              fontSize: "12px",
              lineHeight: "14px",
              textTransform: "uppercase",
              fontWeight: "700",
            }}
          >
            Data Product
          </Typography>
          <Typography
            mb={4}
            variant="h5"
            color={palette.common.black}
            sx={{ wordBreak: "break-word" }}
          >
            {finalDataProduct.name}
          </Typography>
          {show && (
            <Chip
              icon={getIssueIcon(finalDataProduct.issueStatus)}
              color={getIssueColor(finalDataProduct.issueStatus)}
              label={getLabelIssueStatus(finalDataProduct.issueStatus)}
              sx={{
                color: palette.common.black,
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: "500",
              }}
            />
          )}
          {finalDataProduct.pii && (
            <Chip
              icon={
                <PersonIcon
                  sx={{
                    fontSize: "1rem",
                    color: palette.common.black + "!important",
                  }}
                />
              }
              color="gray"
              label="Contains PII"
              sx={{
                color: palette.common.black,
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: "500",
                background: "none",
                border: `1px solid ${palette.grayAccent.light}`,
                marginLeft: "8px",
              }}
            />
          )}

          <SheetTabs value={tab} onChange={setTab} />
        </Box>

        {tab === 0 && (
          <Box
            display="flex"
            flexDirection="column"
            flex="1"
            justifyContent="space-between"
          >
            {isEditState ? (
              <EditDataProductSchemaSetDetails
                dataProductSchema={finalDataProduct}
                toggleEditView={toggleEditView}
                showToast={showToast}
              />
            ) : (
              <DataProductSchemaSetDetails data={finalDataProduct} />
            )}
            {isEditable && !isEditState && (
              <Box
                sx={{
                  marginTop: "auto",
                }}
              >
                <Button
                  color="grayAccent"
                  variant="contained"
                  sx={{ width: "100%" }}
                  onClick={toggleEditView}
                >
                  Edit
                </Button>
              </Box>
            )}
          </Box>
        )}
        {tab === 1 && <SheetChat id={id} />}
      </Box>
    );
  }
  return null;
};
