import { useMutation, useQuery } from "@apollo/client";
import { Autocomplete, Button, TextField } from "@mui/material";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  GetWorkspaceMembersDocument,
  Member,
  ProjectCollaboratingMember,
  UpdateWorkspaceRoleScopeDocument,
} from "../../generated";
import { BtnsWrap, ButtonDefault, ButtonPrimary } from "./style";

interface ProjectOverviewMemeberProps {
  members: ProjectCollaboratingMember[];
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: any;
}

export const ProjectOverviewMemeber = ({
  members,
  setShow,
  refetch,
}: ProjectOverviewMemeberProps) => {
  const { workspaceId, projectId } = useParams();
  const [memberId, setMemberId] = useState<string>();

  const { data, loading } = useQuery(GetWorkspaceMembersDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
    },
  });

  const contributors = useMemo(
    () =>
      data?.workspace?.members.filter(
        (member) =>
          member.workspaceRole.includes("CONTRIBUTOR") &&
          member.status === "ACTIVE" &&
          !members.find((mb) => mb.id === member.id)
      ),
    [data]
  );

  const defaultMember = useMemo(
    () =>
      contributors?.filter((contributor) =>
        members.find((member) => contributor.id === member.id)
      ),
    [members, data]
  );

  const [updateRole] = useMutation(UpdateWorkspaceRoleScopeDocument);

  const submitHandler = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (memberId) {
      updateRole({
        variables: {
          input: {
            workspaceId: workspaceId || "",
            userId: memberId || "",
            projectsToAdd: [projectId || ""],
            projectsToRemove: [],
          },
        },
      }).then((res) => {
        if (res.data?.updateWorkspaceRoleScope) {
          toast.success("User added");
          refetch();
          setShow(false);
        } else {
          toast.error("User can't be added");
        }
      });
    }
  };

  return (
    <>
      {!loading && (
        <form onSubmit={submitHandler}>
          <Autocomplete
            // multiple
            id="add-member"
            options={contributors || []}
            getOptionLabel={(option) =>
              option?.firstName + " " + option?.lastName
            }
            onChange={(event, value: any) => setMemberId(value?.id)}
            renderInput={(params) => (
              <TextField {...params} variant="standard" placeholder="Members" />
            )}
          />
          <br />
          <BtnsWrap>
            <ButtonPrimary type="submit" id="add-member">
              Add
            </ButtonPrimary>
            <ButtonDefault onClick={() => setShow(false)}>Cancel</ButtonDefault>
          </BtnsWrap>
        </form>
      )}
    </>
  );
};
