import React, { useState } from "react";
import {
  Box,
  Dialog,
  useTheme,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { Button } from "../../common/Button/Button";
import { SearchBar } from "../../common/SearchBar/SearchBar";
import { FilterByTagButton } from "../../common/ActionBarProjects/FilterByTagButton";
import { FormikValues } from "formik";
import { Chip } from "../../common/Chip/Chip";
import PersonIcon from "@mui/icons-material/Person";
import { DOCLink } from "../../common/Link/Link";
import DoneIcon from "@mui/icons-material/Done";
import { OffPlatformIcon } from "../../common/Icons/OffPlatformIcon";
import { SimpleLoader } from "../../common/Loader/Loader";

export const OffPlateformTransformationEngineModal = () => {
  const { palette } = useTheme();
  const [open, setOpen] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState(false);
  const categories = ["Privacy", "Use", "Access", "Quality"];
  const [categoryValues, setCategoryValues] = useState<FormikValues>({
    Privacy: false,
    Use: false,
    Access: false,
    Quality: false,
  });

  const tags = ["HIPPA", "FERRA", "PROJECTS", "ONE", "TWO"];
  const [tagValues, setTagValues] = useState<FormikValues>({
    HIPPA: false,
    FERRA: false,
    PROJECTS: false,
    ONE: false,
    TWO: false,
  });

  const toggleDialog = () => {
    setOpen(false);
  };

  return (
    <Dialog
      maxWidth="md"
      open={open}
      sx={{ " + #filter-owner-form": { zIndex: 999999 } }}
    >
      <Box width="800px" height="auto" p={6} boxSizing="border-box">
        <Typography mb={4} variant="h5" color={palette.common.black}>
          Off-platform transformation engine
        </Typography>
        {/* <SimpleLoader text="custom loading" className="custom-loader" customTextClass="custom-text" /> */}
        <Box mb={8} sx={{ display: "flex", justifyContent: "space-between" }}>
          <SearchBar
            sx={{ width: "300px", mr: 2 }}
            placeholder="Find an engine"
            onChange={() => {}}
          />
        </Box>
        <Box sx={{ height: "400px", overflow: "auto" }}>
          <Box>
            <Grid container>
              <Grid
                item
                xs={12}
                mb={4}
                sx={{
                  minWidth: "360px",
                  ".seleceted-item": {
                    borderColor: palette.action.disabledBackground,
                    background: palette.primary.lighter,
                  },
                  ".selected-button": {
                    color: palette.primary.darkest,
                    borderColor: palette.primary.dark,
                    background: palette.primary.lighter,
                  },
                }}
              >
                <Paper
                  variant="outlined"
                  className={selectedTemplate ? "seleceted-item" : ""}
                  sx={{
                    p: 4,
                    minHeight: "100%",
                    boxSizing: "border-box",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    alignItems="flex-start"
                    mb={4}
                  >
                    <Box display="flex">
                      <Box
                        mr={2}
                        sx={{
                          minWidth: "32px",
                          svg: { width: "32px", height: "32px" },
                        }}
                      >
                        <OffPlatformIcon />
                      </Box>
                      <Box>
                        <Typography
                          variant="subtitle2"
                          color={palette.common.black}
                          mb={0}
                          sx={{
                            fontSize: "16px",
                            fontWeight: 500,
                          }}
                        >
                          Dual Enrollment Registered Students
                        </Typography>
                        <List
                          sx={{
                            display: "flex",
                            fontSize: "14px",
                            lineHeight: "20px",
                            padding: "0",
                            li: {
                              width: "inherit",
                              position: "relative",
                            },
                            "li:before": {
                              width: "1px",
                              height: "12px",
                              position: "absolute",
                              left: "0",
                              top: "6px",
                              content: '""',
                              background: palette.gray.darkest,
                            },
                            "li:first-child:before": {
                              display: "none",
                            },
                            a: {
                              padding: "2px 5px",
                              div: {
                                margin: "0",
                              },
                              span: {
                                fontSize: "14px",
                                lineHeight: "20px",
                              },
                            },
                          }}
                        >
                          <ListItem disablePadding>
                            <ListItemButton component="a" href="#">
                              <ListItemText primary="Off-platform" />
                            </ListItemButton>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemButton component="a" href="#">
                              <ListItemText primary="Apache Airflow" />
                            </ListItemButton>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemButton component="a" href="#">
                              <ListItemText primary="Citrus University" />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </Box>
                    </Box>
                    <Button
                      color="grayAccent"
                      variant="outlined"
                      className={selectedTemplate ? "selected-button" : ""}
                      onClick={() => {}}
                      sx={{
                        minWidth: "70px",
                        padding: "8px",
                      }}
                    >
                      {selectedTemplate ? (
                        <>
                          <DoneIcon
                            sx={{ fontSize: "16px", marginRight: "8px" }}
                          />
                          Selected
                        </>
                      ) : (
                        <>Select</>
                      )}
                    </Button>
                  </Box>
                  <Box>
                    <Typography
                      variant="body3"
                      mb={4}
                      color={palette.gray.darkest}
                      sx={{
                        fontSize: "14px",
                      }}
                    >
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem
                      aperiam, eaque ipsa quae ab illo inventore.
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
              <Grid
                item
                xs={12}
                mb={4}
                sx={{
                  minWidth: "360px",
                  ".seleceted-item": {
                    borderColor: palette.action.disabledBackground,
                    background: palette.primary.lighter,
                  },
                  ".selected-button": {
                    color: palette.primary.darkest,
                    borderColor: palette.primary.dark,
                    background: palette.primary.lighter,
                  },
                }}
              >
                <Paper
                  variant="outlined"
                  className={selectedTemplate ? "seleceted-item" : ""}
                  sx={{
                    p: 4,
                    minHeight: "100%",
                    boxSizing: "border-box",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    alignItems="flex-start"
                    mb={4}
                  >
                    <Box display="flex">
                      <Box
                        mr={2}
                        sx={{
                          minWidth: "32px",
                          svg: { width: "32px", height: "32px" },
                        }}
                      >
                        <OffPlatformIcon />
                      </Box>
                      <Box>
                        <Typography
                          variant="subtitle2"
                          color={palette.common.black}
                          mb={0}
                          sx={{
                            fontSize: "16px",
                            fontWeight: 500,
                          }}
                        >
                          Dual Enrollment Registered Students
                        </Typography>
                        <List
                          sx={{
                            display: "flex",
                            fontSize: "14px",
                            lineHeight: "20px",
                            padding: "0",
                            li: {
                              width: "inherit",
                              position: "relative",
                            },
                            "li:before": {
                              width: "1px",
                              height: "12px",
                              position: "absolute",
                              left: "0",
                              top: "6px",
                              content: '""',
                              background: palette.gray.darkest,
                            },
                            "li:first-child:before": {
                              display: "none",
                            },
                            a: {
                              padding: "2px 5px",
                              div: {
                                margin: "0",
                              },
                              span: {
                                fontSize: "14px",
                                lineHeight: "20px",
                              },
                            },
                          }}
                        >
                          <ListItem disablePadding>
                            <ListItemButton component="a" href="#">
                              <ListItemText primary="Off-platform" />
                            </ListItemButton>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemButton component="a" href="#">
                              <ListItemText primary="Apache Airflow" />
                            </ListItemButton>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemButton component="a" href="#">
                              <ListItemText primary="Citrus University" />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </Box>
                    </Box>
                    <Button
                      color="grayAccent"
                      variant="outlined"
                      className={selectedTemplate ? "selected-button" : ""}
                      onClick={() => {}}
                      sx={{
                        minWidth: "70px",
                        padding: "8px",
                      }}
                    >
                      {selectedTemplate ? (
                        <>
                          <DoneIcon
                            sx={{ fontSize: "16px", marginRight: "8px" }}
                          />
                          Selected
                        </>
                      ) : (
                        <>Select</>
                      )}
                    </Button>
                  </Box>
                  <Box>
                    <Typography
                      variant="body3"
                      mb={4}
                      color={palette.gray.darkest}
                      sx={{
                        fontSize: "14px",
                      }}
                    >
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem
                      aperiam, eaque ipsa quae ab illo inventore.
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
              <Grid
                item
                xs={12}
                mb={4}
                sx={{
                  minWidth: "360px",
                  ".seleceted-item": {
                    borderColor: palette.action.disabledBackground,
                    background: palette.primary.lighter,
                  },
                  ".selected-button": {
                    color: palette.primary.darkest,
                    borderColor: palette.primary.dark,
                    background: palette.primary.lighter,
                  },
                }}
              >
                <Paper
                  variant="outlined"
                  className={selectedTemplate ? "seleceted-item" : ""}
                  sx={{
                    p: 4,
                    minHeight: "100%",
                    boxSizing: "border-box",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    alignItems="flex-start"
                    mb={4}
                  >
                    <Box display="flex">
                      <Box
                        mr={2}
                        sx={{
                          minWidth: "32px",
                          svg: { width: "32px", height: "32px" },
                        }}
                      >
                        <OffPlatformIcon />
                      </Box>
                      <Box>
                        <Typography
                          variant="subtitle2"
                          color={palette.common.black}
                          mb={0}
                          sx={{
                            fontSize: "16px",
                            fontWeight: 500,
                          }}
                        >
                          Dual Enrollment Registered Students
                        </Typography>
                        <List
                          sx={{
                            display: "flex",
                            fontSize: "14px",
                            lineHeight: "20px",
                            padding: "0",
                            li: {
                              width: "inherit",
                              position: "relative",
                            },
                            "li:before": {
                              width: "1px",
                              height: "12px",
                              position: "absolute",
                              left: "0",
                              top: "6px",
                              content: '""',
                              background: palette.gray.darkest,
                            },
                            "li:first-child:before": {
                              display: "none",
                            },
                            a: {
                              padding: "2px 5px",
                              div: {
                                margin: "0",
                              },
                              span: {
                                fontSize: "14px",
                                lineHeight: "20px",
                              },
                            },
                          }}
                        >
                          <ListItem disablePadding>
                            <ListItemButton component="a" href="#">
                              <ListItemText primary="Off-platform" />
                            </ListItemButton>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemButton component="a" href="#">
                              <ListItemText primary="Apache Airflow" />
                            </ListItemButton>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemButton component="a" href="#">
                              <ListItemText primary="Citrus University" />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </Box>
                    </Box>
                    <Button
                      color="grayAccent"
                      variant="outlined"
                      className={selectedTemplate ? "selected-button" : ""}
                      onClick={() => {}}
                      sx={{
                        minWidth: "70px",
                        padding: "8px",
                      }}
                    >
                      {selectedTemplate ? (
                        <>
                          <DoneIcon
                            sx={{ fontSize: "16px", marginRight: "8px" }}
                          />
                          Selected
                        </>
                      ) : (
                        <>Select</>
                      )}
                    </Button>
                  </Box>
                  <Box>
                    <Typography
                      variant="body3"
                      mb={4}
                      color={palette.gray.darkest}
                      sx={{
                        fontSize: "14px",
                      }}
                    >
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem
                      aperiam, eaque ipsa quae ab illo inventore.
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box mt={4} display="flex" alignItems={"center"}>
          <Button
            color="grayAccent"
            variant="outlined"
            onClick={toggleDialog}
            sx={{ ml: "auto", mr: 3 }}
          >
            Cancel
          </Button>
          <Button color="primary" variant="contained">
            Use engine
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
