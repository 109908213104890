import { Box, Divider, useTheme } from "@mui/material";
import { Tag } from "../../common/Tag/Tag";
import { Typography } from "../../common/Typography/Typography";
import { StatusCell } from "../CellRenderer/StatusCell";
import SyncIcon from "@mui/icons-material/Sync";
import LockOutlined from "@mui/icons-material/LockOutlined";
import { palette } from "@mui/system";

export const MetadataWorkspace = () => {
  const { palette } = useTheme();
  const automaticaly = (status: string) => {
    return (
      <Box
        borderRadius={1}
        bgcolor={palette.primary.lighter}
        p={2}
        display="flex"
        justifyContent="space-between"
      >
        <Typography variant="body3">
          Automatically{" "}
          <Box component="span" fontWeight="bold">
            {status}
          </Box>{" "}
          for new workspaces
        </Typography>
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.grayAccent.main,
            height: 20,
            width: 20,
          }}
        />
      </Box>
    );
  };

  const internalItem = ({ status }: { status: string }) => {
    return (
      <Box
        py={4}
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography
            fontWeight={status === "Disabled" ? "regular" : "bold"}
            variant="body3"
          >
            Post Secondary Outcomes Regional Dual Enrollment Outcomes
          </Typography>
          <Box minWidth={100} display="flex" justifyContent="flex-end">
            <StatusCell value={status} />
          </Box>
        </Box>
        <Box mt={2} display="flex" gap={2}>
          <Tag label="Internal" startIcon={<LockOutlined />} fontSize="small" />

          <Tag label="In-use" startIcon={<SyncIcon />} fontSize="small" />
        </Box>
      </Box>
    );
  };

  const collaborativeItem = ({ status }: { status: string }) => {
    return (
      <Box
        py={4}
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography
            fontWeight={status === "Disabled" ? "regular" : "bold"}
            variant="body3"
          >
            Post Secondary Outcomes Regional Dual Enrollment Outcomes
          </Typography>
          <Box minWidth={100} display="flex" justifyContent="flex-end">
            <StatusCell value={status} />
          </Box>
        </Box>
        <Box mt={2} display="flex" gap={2}>
          <Tag label="Internal" startIcon={<LockOutlined />} fontSize="small" />

          <Tag label="In-use" startIcon={<SyncIcon />} fontSize="small" />
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Box>
        <Typography gutterBottom variant="subtitle2">
          Internal workspaces (3)
        </Typography>
        {automaticaly("disabled")}
        <Box mt={3}>
          <Divider />
        </Box>
        {internalItem({ status: "Enabled" })}
        {internalItem({ status: "Enabled" })}
        {internalItem({ status: "Disabled" })}
      </Box>
      <Box mt={10}>
        <Typography gutterBottom variant="subtitle2">
          Collaborative workspaces (3)
        </Typography>
        {automaticaly("enabled")}
        <Box mt={3}>
          <Divider />
        </Box>
        {collaborativeItem({ status: "Enabled" })}
        {collaborativeItem({ status: "Enabled" })}
        {collaborativeItem({ status: "Disabled" })}
      </Box>
    </Box>
  );
};
