import { Breadcrumbs as MUIBreadcrumbs, BreadcrumbsProps as MUIBreadcrumbsProps, useTheme, } from "@mui/material";
import React from "react";
import { ChevronRight } from "@mui/icons-material";


interface SpecificBreadcrumbLinksProps {
  children: React.ReactNode;
}

export type BreadcrumbsLinksProps = SpecificBreadcrumbLinksProps &
  MUIBreadcrumbsProps;

export const BreadcrumbLinks = (props: BreadcrumbsLinksProps) => {
  const { palette } = useTheme();
  const separator = <ChevronRight sx={{ color: palette.gray.dark }} />;
  return (
    <MUIBreadcrumbs separator={separator} {...props}>
      {props.children}
    </MUIBreadcrumbs>
  );
};
