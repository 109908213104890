import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const BanIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM4 12C4 16.4183 7.58172 20 12 20C13.8482 20 15.55 19.3733 16.9045 18.3207L5.67927 7.09548C4.62674 8.44998 4 10.1518 4 12ZM12 4C10.1518 4 8.44998 4.62674 7.09548 5.67927L18.3207 16.9045C19.3733 15.55 20 13.8482 20 12C20 7.58172 16.4183 4 12 4Z"
        fill="currentColor"
        fillOpacity="1"
      />
    </SvgIcon>
  );
};
