import { Box, useTheme } from "@mui/material";

import { Typography } from "../common/Typography/Typography";
import { BasicPage } from "../common/BasicPage/BasicPage";
import React from "react";
import { WorkspaceConfiguration } from "./WorkspaceConfiguration";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export const WorkspaceConfigurationPage = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  return (
    <BasicPage
      sx={{
        ".right-elements": {
          alignSelf: "flex-end",
        },
        background: palette.common.white,
      }}
      leftHeaderElements={
        <Box>
          <Box
            mb={1}
            onClick={() => navigate(-1)}
            sx={{ cursor: "pointer" }}
            display="flex"
          >
            <ArrowBack
              sx={{
                color: palette.link.main,
                width: "1.25rem",
                height: "1.25rem",
                fontSize: "1.25rem",
                mr: 2,
              }}
            />
            <Typography sx={{ color: palette.link.main }} variant="body3">
              Back
            </Typography>
          </Box>
          <Box display="flex">
            <Typography variant="h5">Workspace Configuration</Typography>
          </Box>
        </Box>
      }
      content={<WorkspaceConfiguration />}
    />
  );
};
