import {
  GetWorkspaceMembersDocument,
  GetWorkspaceMembersQuery,
  WorkspaceInput,
  WorkspaceRoleEnum,
} from "../../generated";
import {
  generateArrayOfData,
  getDateInInterval,
  getRandomIntFromInterval,
} from "./helpers";
import { aMember } from "./generated-mocks";
import { MockedResponse } from "@apollo/client/testing";

const data: GetWorkspaceMembersQuery = {
  workspace: {
    members: generateArrayOfData(
      () =>
        aMember({
          createdAt: getDateInInterval(
            new Date(2018, 0, 0),
            new Date()
          ).toISOString(),
          workspaceRole: [
            WorkspaceRoleEnum.Viewer,
            WorkspaceRoleEnum.Admin,
            WorkspaceRoleEnum.Collaborator,
          ][getRandomIntFromInterval(0, 2)],
        }),
      101
    ),
  },
};

const input: WorkspaceInput = {
  workspaceId: "f4861165-f244-4cad-9827-65921385b9a0",
};

export const workspaceMembersMock: MockedResponse = {
  request: {
    query: GetWorkspaceMembersDocument,
    variables: {
      input,
    },
  },
  result: {
    data,
  },
  delay: 2000,
};
