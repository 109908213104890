import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const OfficeBuildingIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon height="17" width="16" viewBox="0 0 17 16" fill="none" {...props}>
      <g clip-path="url(#clip0_468_19402)">
        <path
          d="M2.60001 14H14.6"
          stroke="currentColor"
          fill="none"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.60001 5.33333H7.26667"
          stroke="currentColor"
          fill="none"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.60001 8H7.26667"
          stroke="currentColor"
          fill="none"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.60001 10.6667H7.26667"
          stroke="#7E8AA0"
          fill="none"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.93335 5.33333H10.6"
          stroke="#7E8AA0"
          fill="none"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.93335 8H10.6"
          stroke="#7E8AA0"
          fill="none"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.93335 10.6667H10.6"
          stroke="#7E8AA0"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.93335 14V3.33333C3.93335 2.97971 4.07383 2.64057 4.32387 2.39052C4.57392 2.14048 4.91306 2 5.26668 2H11.9333C12.287 2 12.6261 2.14048 12.8762 2.39052C13.1262 2.64057 13.2667 2.97971 13.2667 3.33333V14"
          stroke="currentColor"
          fill="none"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_468_19402">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.600006)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
