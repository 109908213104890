import { useMemo, useState } from "react";
import { Button } from "../../../common/Button/Button";
import { Box, Dialog, List, ListItem, Typography } from "@mui/material";
import { CollapseSchema } from "./CollapseSchema";
import {
  ComparingListHolder,
  SchemaListItems,
  EmptyBox,
  SchemaName,
  SchemaType,
  NoRecordBlock,
  SchemaDescription,
} from "./style";
import { CompareScore } from "./CompareScore";
import { testSchemaOne } from "./TestData/schemaOne";
import { testSchemaTwo } from "./TestData/schemaTwoResponse";
import { responseCompare } from "./TestData/response";
import { SchemaTable } from "../SchemaTable";
import { SchemaDetailPage } from "../SchemaDetail/SchemaDetailPage";
import CommonTooltip from "../../../common/ToolTips/CommonTooltip";
import { EllipsisBox } from "../../../common/StyledComp/EllipsisBox";
import Close from "@mui/icons-material/Close";

export const SchemaList = ({ baseSchema, comparableSchema, compare }: any) => {
  const [showSchema, setShowSchema] = useState<any>();

  const { newData1, newData2 } = useMemo(() => {
    let newData1: any = [];
    let newData2: any = [];

    if (compare && baseSchema && comparableSchema && compare.length > 0) {
      const property1 = Object.entries(
        JSON.parse(baseSchema.jsonSchema)?.properties
      );
      const property2 = Object.entries(
        JSON.parse(comparableSchema.jsonSchema)?.properties
      );

      compare?.forEach((item: any) => {
        newData1 = [
          ...newData1,
          property1.find((p) => p[0] === item.schema1_field),
        ];
        newData2 = [
          ...newData2,
          property2.find((p) => p[0] === item.schema2_field),
        ];
      });
    }

    return { newData1, newData2 };
  }, [baseSchema, comparableSchema, compare]);

  const len =
    newData1.length > newData2.length ? newData1.length : newData2.length;

  const scoreCal = (name1: any, name2: any) => {
    let score: any;
    compare?.forEach((item: any) => {
      if (item.schema1_field === name1 && item.schema2_field === name2) {
        score = item.comparison_score;
        return;
      }
    });

    return score;
  };

  const schemaList = (schema: any) => {
    if (schema) {
      const parse = JSON.parse(schema.jsonSchema);
      // const parse = schema;
      return Object.keys(parse.properties || {}).map((key, idx): any => {
        const item = parse.properties[key];
        let type = item.type;
        if (item.enum) {
          type = "enum";
        }

        const callRecords: any[] = [];

        item.anyOf?.forEach((element: any) => {
          let value = "";

          if (element.type === "string") {
            value = element.pattern;
          }

          if (element.enum) {
            value = element.enum.join(", ");
          }

          callRecords.push({
            ...element,
            type: element.enum ? "enum" : element.type,
            value,
          });
        });

        let value = "";

        if (item.type === "string") {
          value = item.pattern;
        }

        if (item.enum) {
          value = item.enum.join(", ");
        }

        return {
          type,
          value,
          no: idx + 1,
          name: key,
          callRecords,
          required: parse.required?.includes(key) || false,
          ...item,
        };
      });
    }
    return [];
  };

  return (
    <ComparingListHolder>
      {compare ? (
        compare.length > 0 ? (
          <SchemaListItems className="compare-response">
            {[...Array(len)].map((x, index) => (
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                {newData1?.[index] ? (
                  <ListItem>
                    <SchemaName>{newData1[index][0]}</SchemaName>
                    <SchemaDescription>
                      <CommonTooltip
                        title={newData1[index][1].description || ""}
                        placement="bottom-start"
                      >
                        <EllipsisBox>
                          {newData1[index][1].description || ""}
                        </EllipsisBox>
                      </CommonTooltip>
                    </SchemaDescription>
                    <SchemaType>{newData1[index][1].type}</SchemaType>
                  </ListItem>
                ) : (
                  <ListItem sx={{ visibility: "hidden" }} />
                )}

                {compare && newData1?.[index] && newData2?.[index] ? (
                  <CompareScore
                    className="custom-compare-box"
                    data={scoreCal(
                      newData1?.[index]?.[0],
                      newData2?.[index]?.[0]
                    )}
                  />
                ) : (
                  <CompareScore className="custom-compare-box" />
                )}

                {newData2?.[index] ? (
                  <ListItem>
                    <SchemaName>{newData2[index][0]}</SchemaName>
                    <SchemaDescription>
                      <CommonTooltip
                        title={newData2[index][1].description || ""}
                        placement="bottom-start"
                      >
                        <EllipsisBox>
                          {newData2[index][1].description || ""}
                        </EllipsisBox>
                      </CommonTooltip>
                    </SchemaDescription>
                    <SchemaType>{newData2[index][1].type}</SchemaType>
                  </ListItem>
                ) : (
                  <ListItem sx={{ visibility: "hidden" }} />
                )}
              </Box>
            ))}
            <Box display={"flex"} justifyContent={"space-between"}>
              <Button onClick={() => setShowSchema(baseSchema)}>
                Show Schema
              </Button>
              <Button onClick={() => setShowSchema(comparableSchema)}>
                Show Schema
              </Button>
            </Box>
          </SchemaListItems>
        ) : (
          <NoRecordBlock>
            <Typography mt={2} className="no-record" textAlign={"center"}>
              No Comparison Found
            </Typography>
          </NoRecordBlock>
        )
      ) : (
        <>
          <SchemaListItems>
            {schemaList(baseSchema).map((row: any) => (
              <ListItem>
                <SchemaName>{row?.name}</SchemaName>
                <SchemaDescription>
                  <CommonTooltip
                    title={row?.description || ""}
                    placement="bottom-start"
                  >
                    <EllipsisBox>{row?.description || ""}</EllipsisBox>
                  </CommonTooltip>
                </SchemaDescription>
                <SchemaType>{row?.type}</SchemaType>
              </ListItem>
            ))}
          </SchemaListItems>
          <EmptyBox />
          <SchemaListItems>
            {schemaList(comparableSchema).map((row: any) => (
              <ListItem>
                <SchemaName>{row?.name}</SchemaName>
                <SchemaDescription>
                  <CommonTooltip
                    title={row?.description || ""}
                    placement="bottom-start"
                  >
                    <EllipsisBox>{row?.description || ""}</EllipsisBox>
                  </CommonTooltip>
                </SchemaDescription>
                <SchemaType>{row?.type}</SchemaType>
              </ListItem>
            ))}
          </SchemaListItems>
        </>
      )}

      <Dialog
        open={showSchema}
        fullScreen={true}
        maxWidth={"lg"}
        onClose={() => setShowSchema("")}
        sx={{ ".page-footer": { display: "none" } }}
      >
        <SchemaDetailPage showBackBtn={false} schema={showSchema} />
        <Close
          sx={{
            cursor: "pointer",
            position: "absolute",
            right: "10px",
            top: "10px",
          }}
          onClick={() => setShowSchema("")}
        />
      </Dialog>
    </ComparingListHolder>
  );
};
