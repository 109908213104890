import { Box } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { BasicCard } from "../../common/BasicCard/BasicCard";
import { METADATA_SOURCES } from "../../mock-data/mock-data";
import { ActionBarDataStorage } from "./ActionBarDataStorage";
import { StatusCell } from "../CellRenderer/StatusCell";
import { useState } from "react";
import { DataStorageDetail } from "./DataStorageDetail";
import { IconCell } from "../CellRenderer/IconCell";
import { AGGridWrapper } from "../../common/AGGridWrapper/AGGridWrapper";

export const DataStorage = () => {
  const [detail, setDetail] = useState(null);

  const columns = [
    { field: "project", rowGroup: true, hide: true },
    {
      field: "name",
      headerName: "Name",
      cellRenderer: (params: any) => {
        const item = params.data;
        return (
          <Box display="flex" alignItems="center">
            <IconCell value={item.service} />
            <Typography fontWeight="bold" ml={3} variant="body3">
              {item.name}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "location",
      headerName: "Location",
      cellRenderer: (params: any) => {
        const item = params.data;
        return (
          <Box display="flex" alignItems="center">
            <IconCell value={item.location} />
            <Typography fontWeight="bold" ml={3} variant="body3">
              {item.location}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "cloudProvider",
      headerName: "Cloud provider",
    },
    {
      field: "service",
      headerName: "Service",
    },

    {
      field: "access",
      headerName: "Access",
    },
    {
      field: "owner",
      headerName: "Owner",
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: (params: any) => {
        const item = params.data;
        return <StatusCell value={item.status} />;
      },
    },
  ];

  const rowData = METADATA_SOURCES;

  return (
    <>
      <Box display="grid" gridTemplateColumns="1fr 18rem" gap="1rem">
        <Box>
          <BasicCard>
            <ActionBarDataStorage />
            <Box className="ag-theme-material">
              <AGGridWrapper
                rowData={rowData}
                columnDefs={columns}
                defaultColDef={{
                  sortable: false,
                  flex: 1,
                  minWidth: 200,
                }}
                groupDisplayType="groupRows"
                components={{
                  agColumnHeader: null,
                }}
                getRowHeight={(params) => (params.node.group ? 32 : 62)}
                groupDefaultExpanded={1}
                domLayout="autoHeight"
                onRowClicked={(params) => {
                  setDetail(params.data);
                }}
              />
            </Box>
          </BasicCard>
        </Box>
        <Box>
          <BasicCard sx={{ padding: "1.6rem" }}>
            <Typography variant="subtitle2">
              Data storage in workspace explanation
            </Typography>
            <Typography variant="body3" mt={3}>
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Typography>
          </BasicCard>
        </Box>
      </Box>
      <DataStorageDetail
        open={Boolean(detail)}
        onClose={() => setDetail(null)}
      />
    </>
  );
};
