import { useState } from "react";

import { ProjectSchemaDetailsOverview } from "./ProjectSchemaDetailsOverview";
import { Tabs } from "../../common/Tabs/Tabs";
import {
  GetTargetSchemasDocument,
  TargetSchema,
  UpdateTargetSchemaDocument,
} from "../../generated";
import { ProjectSchemaDetailGrid } from "../ProjectSchemaDetailPage/ProjectSchemaDetailGrid";
import { ProjectSchemaDetailPage } from "../ProjectSchemaDetailPage/ProjectSchemaDetailPage";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { CustomTab, CustomTabs } from "./style";
import { useTheme } from "@mui/material";

export const SchemaDetail = ({
  data,
  loading,
  isEditMode,
}: {
  data: any | undefined;
  loading: boolean;
  isEditMode: boolean;
}) => {
  const { workspaceId, schemaId, projectId } = useParams();
  const [tab, setTab] = useState(0);
  const { palette } = useTheme();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  const [mutateUpdate] = useMutation(UpdateTargetSchemaDocument, {
    refetchQueries: [GetTargetSchemasDocument],
  });

  const updateSchemaJson = (parse: any) =>
    mutateUpdate({
      variables: {
        input: {
          targetSchemaId: schemaId || "",
          workspaceId: workspaceId || "",
          projectId: projectId || "",
          name: data?.name,
          description: data?.description,
          jsonSchema: JSON.stringify(parse),
        },
      },
    });

  return (
    <>
      <CustomTabs
        value={tab}
        onChange={handleChange}
        sx={{ mb: 4 }}
        aria-label="asset detail tab"
      >
        <CustomTab label="Overview" {...a11yProps(0)} />
        <CustomTab label="Schema" {...a11yProps(0)} />
      </CustomTabs>

      {tab === 0 && (
        <ProjectSchemaDetailsOverview
          isEditMode={isEditMode}
          data={data}
          loading={loading}
        />
      )}
      {tab === 1 && (
        <ProjectSchemaDetailPage data={data} update={updateSchemaJson} />
      )}
    </>
  );
};

export {};
