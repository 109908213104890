import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const ErrorPage = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="160"
      height="131"
      viewBox="0 0 160 131"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="1"
        y="1"
        width="158"
        height="129"
        rx="3"
        fill="none"
        stroke="black"
        strokeWidth="2"
      />
      <rect
        x="1"
        y="1"
        width="158"
        height="18"
        rx="3"
        fill="none"
        stroke="black"
        strokeWidth="2"
      />
      <circle
        cx="147"
        cy="10"
        r="2"
        fill="none"
        stroke="black"
        strokeWidth="2"
      />
      <circle
        cx="136"
        cy="10"
        r="2"
        fill="none"
        stroke="black"
        strokeWidth="2"
      />
      <circle
        cx="125"
        cy="10"
        r="2"
        fill="none"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M65 41L45 61"
        stroke="#333"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M45 41L65 61"
        stroke="#333"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M115 41L95 61"
        stroke="#333"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M95 41L115 61"
        stroke="#333"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M38 78H121"
        stroke="#333"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M88.5 78H121.5V90.5C121.5 99.6127 114.113 107 105 107C95.8873 107 88.5 99.6127 88.5 90.5V78Z"
        stroke="#333"
        fill="none"
        strokeWidth="2"
      />
    </SvgIcon>
  );
};
