import { useQuery } from '@apollo/client';
import { GetCurrentUserRoleDocument } from '../generated';

export const useGetUserRole = (workspaceId?: string) => {

  const { data, loading } = useQuery(GetCurrentUserRoleDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || '',
      }
    },
    skip: workspaceId === undefined,
  })

  const role = data?.currentUser?.workspaceRole

  const accessible = !['CONTRIBUTOR'].includes(role || '')

  return { role, accessible, loading }
}

