import React, { forwardRef, useImperativeHandle, useState } from "react";
import { MenuItem } from "../../common/Menu/MenuItem";
import { Tag } from "../../common/Tag/Tag";
import { Person } from "@mui/icons-material";
import { Select } from "../../common/Form/Select";
import { Dialog, SelectChangeEvent } from "@mui/material";
import { UpdateToAdminDialogContent } from "../UpdateToAdminDialogContent/UpdateToAdminDialogContent";
import { AdminInfoDialogContent } from "../AdminInfoDialogContent/AdminInfoDialogContent";
import { displayToast } from "../../common/Toast/toast";
import { UpdateRoleDocument, WorkspaceRoleEnum, Role } from "../../generated";
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

enum UpdateAdminStep {
  Confirm,
  ReadInfo,
}

export const RoleCellEditor = forwardRef((props: any, ref) => {
  const userId = props.data.id;
  const { workspaceId } = useParams();
  const [value, setValue] = useState(props.value);
  const [open, setOpen] = useState(true);
  const [updateToAdminDialogOpen, setUpdateToAdminDialogOpen] = useState(false);
  const [currentUpdateAdminStep, setCurrentUpdateAdminStep] = useState(
    UpdateAdminStep.Confirm
  );
  const [updateRole] = useMutation(UpdateRoleDocument);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        return value;
      },
    };
  });

  const stopEditingAfterTimeout = () => {
    setTimeout(() => {
      props.stopEditing();
    }, 0);
  };

  const onChange = async (event: SelectChangeEvent<unknown>) => {
    if (event.target.value === "Admin") {
      setUpdateToAdminDialogOpen(true);
    } else {
      setValue(event.target.value);
      setOpen(false);
      stopEditingAfterTimeout();
      /* displayToast("Invitation Sent"); */
    }
    await updateRole({
      variables: {
        workspaceId: workspaceId || "",
        userId: userId,
        newRole: (event.target.value as string).toUpperCase() as Role,
      },
    });
    displayToast("Role Switched");
  };
  const closeUpdateToAdminModal = () => {
    setUpdateToAdminDialogOpen(false);
    setCurrentUpdateAdminStep(UpdateAdminStep.Confirm);
  };

  const onClickConfirmAdmin = () => {
    setCurrentUpdateAdminStep(UpdateAdminStep.ReadInfo);
  };

  const onClickOkayForAdminInfo = () => {
    setValue("Admin");
    setOpen(false);
    stopEditingAfterTimeout();
    displayToast("Invitation Sent");
  };

  const onCloseSelect = (e: any) => {
    const adminMenuItemWasClicked = Boolean(
      e?.target?.closest(".MuiMenuItem-root[data-value='Admin']")
    );
    if (!adminMenuItemWasClicked) {
      setOpen(false);
      stopEditingAfterTimeout();
    }
  };

  return (
    <>
      <Select
        id="test-id"
        autoFocus
        open={open}
        sx={{
          width: 200,
          height: 41,
          ".MuiSelect-icon": {
            marginRight: 1.5,
          },
        }}
        fullWidth
        value={value}
        onChange={onChange}
        onClose={onCloseSelect}
      >
        <MenuItem value={WorkspaceRoleEnum.Admin}>
          <Tag startIcon={<Person />} label="Admin" dismissable={false} />
        </MenuItem>
        <MenuItem value={WorkspaceRoleEnum.Collaborator}>
          <Tag label="Collaborator" dismissable={false} />
        </MenuItem>
        <MenuItem value={WorkspaceRoleEnum.Viewer}>
          <Tag label="Viewer" dismissable={false} />
        </MenuItem>
        <MenuItem value={WorkspaceRoleEnum.Contributor}>
          <Tag label="Contributor" dismissable={false} />
        </MenuItem>
      </Select>
      <Dialog open={updateToAdminDialogOpen}>
        {currentUpdateAdminStep === UpdateAdminStep.Confirm && (
          <UpdateToAdminDialogContent
            onClickCancel={closeUpdateToAdminModal}
            onClickConfirm={onClickConfirmAdmin}
          />
        )}
        {currentUpdateAdminStep === UpdateAdminStep.ReadInfo && (
          <AdminInfoDialogContent onClickOkay={onClickOkayForAdminInfo} />
        )}
      </Dialog>
    </>
  );
});
