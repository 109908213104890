import React, { useState } from "react";
import { Box, Divider, Popover } from "@mui/material";
import { CheckboxWithLabel } from "formik-mui";
import { Field, Form, Formik, FormikValues } from "formik";
import { FilterButton, FilterButtonProps } from "../FilterButton/FilterButton";
import { useMenu } from "../Menu/useMenu";
import { Button } from "../Button/Button";
import { Typography } from "../Typography/Typography";
import { SearchBar } from "../SearchBar/SearchBar";

interface SpecificFilterByOwnerButtonProps {
  text: string;
  onSubmit: (values: FormikValues) => void;
  tags: string[];
  values: FormikValues;
}

export type FilterByOwnerButton = Omit<FilterButtonProps, "onSubmit"> &
  SpecificFilterByOwnerButtonProps;

export const FilterByTagButton = ({
  text,
  values,
  onSubmit,
  tags,
  ...rest
}: FilterByOwnerButton) => {
  const [menuOpen, anchorEl, openMenuFromAnchorElement, closeMenu] = useMenu();

  const [searchText, setSearchText] = useState("");

  const displayTag = (tag: string, searchText: string) => {
    const trimmedSearchText = searchText.trim();
    if (trimmedSearchText.length === 0) return true;
    return tag.includes(trimmedSearchText);
  };

  const onClickAll =
    (values: FormikValues, setValues: (...args: any[]) => any) => () => {
      if (values.all) {
        const allValues: FormikValues = { all: false };
        tags.forEach((tag) => {
          allValues[tag] = false;
        });
        setValues(allValues);
      } else {
        const allValues: FormikValues = { all: true };
        tags.forEach((tag) => {
          allValues[tag] = true;
        });
        setValues(allValues);
      }
    };

  const onClickValue =
    (values: FormikValues, setValues: (...args: any[]) => any, value: any) =>
    () => {
      let allOtherValuesActive = true;
      Object.keys(values).forEach((curr) => {
        if (curr !== value && curr !== "all" && values[curr] !== true) {
          allOtherValuesActive = false;
        }
      });

      if (values[value] === false && allOtherValuesActive) {
        setValues({
          ...values,
          all: true,
        });
      } else if (values[value] === true) {
        setValues({
          ...values,
          all: false,
          [value]: false,
        });
      } else {
        setValues({
          ...values,
          [value]: !values[value],
        });
      }
    };

  return (
    <>
      <FilterButton
        id="filter-owner-button"
        aria-controls={menuOpen ? "filter-owner-form" : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        color="grayAccent"
        variant="outlined"
        onClick={openMenuFromAnchorElement}
        chip={null}
        text={text}
        {...rest}
      />
      <Popover
        id="filter-owner-form"
        aria-labelledby="filter-owner-button"
        open={menuOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        onClose={closeMenu}
      >
        <Formik
          initialValues={values}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            onSubmit(values);
            closeMenu();
          }}
        >
          {({ submitForm, setValues, values }) => (
            <Form>
              <Box width="20rem" pl={6} pr={6}>
                <Box pt={4} pb={3}>
                  <Typography sx={{ mb: 4 }} variant="h6">
                    Filter by {text || "tag"}
                  </Typography>
                  <SearchBar
                    value={searchText}
                    fullWidth
                    onChange={(event) => setSearchText(event.target.value)}
                    placeholder="Filter"
                    sx={{ marginBottom: 4 }}
                  />
                  <Box maxHeight="15.625rem" sx={{ overflowY: "auto" }}>
                    <Box>
                      <Field
                        component={CheckboxWithLabel}
                        name="all"
                        Label={{
                          label: `All`,
                          componentsProps: { typography: { variant: "body2" } },
                        }}
                        type="checkbox"
                        onClick={onClickAll(values, setValues)}
                      />
                    </Box>
                    {tags.map((tag) => (
                      <Box
                        key={tag}
                        display={
                          displayTag(tag, searchText) ? undefined : "none"
                        }
                      >
                        <Field
                          component={CheckboxWithLabel}
                          name={tag}
                          Label={{
                            label: tag,
                            componentsProps: {
                              typography: { variant: "body2" },
                            },
                          }}
                          type="checkbox"
                          onClick={onClickValue(values, setValues, tag)}
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Divider />
                <Box pt={3} pb={3} justifyContent="flex-end" display="flex">
                  <Button
                    variant="outlined"
                    color="grayAccent"
                    sx={{ mr: 2 }}
                    onClick={closeMenu}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={submitForm}
                  >
                    Apply
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Popover>
    </>
  );
};
