import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";
import { ReactComponent as DestinationDownloadIcon } from "../../assets/destination-download-display.svg";

export const DestinationDownload = (props: SvgIconProps) => {
  return (
    <SvgIcon
      component={DestinationDownloadIcon}
      {...props}
      viewBox="0 0 32 32"
    />
  );
};
