import { Column, GridApi, IFilter } from "ag-grid-enterprise";
import { useEffect, useState } from "react";
import { FilterOperator } from "./gridHelpers";

export const useBooleanFilterWithGrid = (column: Column, gridApi: GridApi) => {
  const [filterText, setFilterText] = useState<string>("any");

  const filterInstance = gridApi.getFilterInstance(column);

  const changeInternalGridFilter = (
    filterValue: string,
    filterInstance: IFilter | null | undefined,
    filterType: string
  ) => {
    if (filterValue === "") {
      filterInstance?.setModel(null);
      setFilterText("any");
    } else {
      filterInstance?.setModel({
        filterType,
        type: FilterOperator.EQUALS,
        filter: filterValue,
      });
    }
    gridApi.onFilterChanged();
  };

  const onClickRefreshFilter = () => {
    setFilterText("any");
    filterInstance?.setModel(null);
    gridApi.onFilterChanged();
  };

  const onChangeFilterValue = (newFilterText: string) => {
    setFilterText(newFilterText);
    changeInternalGridFilter(newFilterText, filterInstance, "text");
  };

  useEffect(() => {
    const syncFilterWithGridFilterModel = ({
      column,
      api,
    }: {
      column: Column;
      api: GridApi;
    }) => {
      const currentFilterModel = api?.getFilterModel();
      const currentField = column?.getColDef()?.field;
      if (currentField && currentFilterModel?.[currentField]) {
        const { filter: gridFilter } = currentFilterModel[currentField];

        setFilterText(gridFilter);
      } else {
        setFilterText("any");
      }
    };

    column.addEventListener("filterChanged", syncFilterWithGridFilterModel);
    return () => {
      column.removeEventListener(
        "filterChanged",
        syncFilterWithGridFilterModel
      );
    };
  }, [column, gridApi]);

  return {
    filterText,
    onChangeFilterValue,
    onClickRefreshFilter,
  };
};
