import { Box, Tab, Typography, useTheme, lighten } from "@mui/material";
import { useState } from "react";
import { Button } from "../../common/Button/Button";
import { CustomTextArea } from "../../common/CustomTextArea/CustomTextArea";
import { BrightBotSmileGreenIcon } from "../../common/Icons/BrightBotSmileGreenIcon";
import { BrightBotSmileIcon } from "../../common/Icons/BrightBotSmileIcon";
import { CheckBox } from "../../common/CheckBox/CheckBox";
import { AvatarWrapper, ButtonDefault, ButtonPrimary, ButtonWrapper, CheckboxBox, SuggestionBoxDescription, SuggestionBoxHeader, SuggestionBoxSuggestion, SuggestionBoxText, SuggestionBoxWrapper } from "../style";

export const SuggestionBox = ({ children }: any) => {
  const { palette } = useTheme();

  return (
    <SuggestionBoxWrapper className="SuggestionBox">
      <SuggestionBoxHeader>
        <AvatarWrapper className="positioned">
          <BrightBotSmileIcon />
        </AvatarWrapper>
        <SuggestionBoxText variant="body3">BrightBot</SuggestionBoxText>
      </SuggestionBoxHeader>
      <SuggestionBoxDescription>
        <AvatarWrapper className="success">
          <BrightBotSmileGreenIcon />
        </AvatarWrapper>
        <SuggestionBoxSuggestion>
          <Typography variant="body3">
            Would you like me to extract data terms from governance.txt?
          </Typography>
          <ButtonWrapper>
            <ButtonPrimary variant="contained">Confirm</ButtonPrimary>
            <ButtonDefault variant="outlined" color="grayAccent">
              Deny
            </ButtonDefault>
          </ButtonWrapper>
          <CheckboxBox>
            <CheckBox label={"Auto approve all actions"} />
          </CheckboxBox>
        </SuggestionBoxSuggestion>
      </SuggestionBoxDescription>
    </SuggestionBoxWrapper>
  );
};
