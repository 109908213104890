import React, { useState } from "react";

import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useApolloClient, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Box, Dialog, Modal, useTheme } from "@mui/material";
import { Button } from "../../../common/Button/Button";
import { MenuItem } from "../../../common/Menu/MenuItem";
import { ListItemText } from "../../../common/Menu/ListItemText";
import { Menu } from "../../../common/Menu/Menu";
import { useMenu } from "../../../common/Menu/useMenu";
import { PlusIcon } from "../../../common/Icons/PlusIcon";
import { PlusTextIcon } from "../../../common/Icons/PlusTextIcon";
import { ButtonPrimary } from "../style";

export interface AddDataAssetButtonProps {
  onClick: Function;
}

export enum AddDataAssetButtonType {
  Upload = "Upload",
  ExtractLoad = "Extract and load",
  APIconnect = "API connect",
  BulkTransfer = "Bulk Transfer",
}

export const AddTermButton = ({ onClick }: AddDataAssetButtonProps) => {
  const [menuOpen, anchorEl, openMenuFromAnchorElement, closeMenu] = useMenu();
  const { workspaceId } = useParams();
  const { palette } = useTheme();

  return (
    <>
      <ButtonPrimary
        id="add-terms-button"
        aria-controls={menuOpen ? "add-terms-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        variant="contained"
        onClick={openMenuFromAnchorElement}
        endIcon={menuOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      >
        <PlusIcon sx={{ fontSize: "16px", marginRight: "5px" }} /> Add Term
      </ButtonPrimary>
      <Menu
        id="add-terms-menu"
        MenuListProps={{ "aria-labelledby": "add-terms-button" }}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          ".MuiList-root": {
            width: "250px",
          },
          ".MuiMenuItem-root": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            "&:hover": {
              backgroundColor: palette.settings.lighter,
            },
          },
          ".MuiListItemText-root": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          },
          ".MuiListItemText-primary": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            color: palette.common.black,
          },
          ".MuiSvgIcon-root": {
            color: palette.grayAccent.light,
          },
        }}
      >
        <MenuItem
          disableRipple
          onClick={() => {
            closeMenu();
            onClick("manually");
          }}
        >
          <ListItemText>
            Add Manually{" "}
            <PlusTextIcon
              sx={{
                fontSize: "20px !important",
                width: "20px !important",
                height: "20px !important",
              }}
            />{" "}
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
