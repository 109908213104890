import React from "react";
import RichTextEditor from "../../common/RichTextEditor";
import {
  useMessageInputContext,
  useChannelActionContext,
  ChatAutoComplete,
} from "stream-chat-react";
import { Box } from "@mui/material";

function CustomMessageInput() {
  const { message, parent, ...p } = useMessageInputContext();
  const { sendMessage } = useChannelActionContext();

  return (
    <Box>
      <RichTextEditor
        onSubmit={async (value: string) => {
          sendMessage(
            {
              text: value,
            },
            {
              parent_id: parent?.id,
              show_in_channel: false,
            }
          );
        }}
      />
    </Box>
  );
}

export default CustomMessageInput;
