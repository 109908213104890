import { Box, Drawer, Tab, IconButton } from "@mui/material";
import { Typography } from "../../Typography/Typography";
import { StatusCell } from "../CellRenderer/StatusCell";
import { ServiceConfiguration } from "./ServiceConfiguration";
import { Tabs } from "../../Tabs/Tabs";
import { useState } from "react";
import Close from "@mui/icons-material/Close";
import { TransformationServiceOutput } from "../../../generated";
import { SheetChat } from "../../../Sheets/SheetChat";
import { Button } from "../../Button/Button";
import ReactJson from "react-json-view";
import { useLocation } from "react-router-dom";
import { TabButton, TabsWrapper } from "../style";

interface ServiceDetailProps {
  data?: TransformationServiceOutput;
  open: boolean;
  onClose: () => void;
}

export const ServiceDetail = ({ data, open, onClose }: ServiceDetailProps) => {
  const location = useLocation();
  const [editView, setEditView] = useState(false);
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box width={400} p={8}>
        <Box position="relative" mb={4}>
          <Box position="absolute" top={-10} right={0}>
            <IconButton onClick={() => onClose()} size="small">
              <Close />
            </IconButton>
          </Box>
          <Typography variant="overline">{`${location.pathname
            .split("/")
            .pop()} Service`}</Typography>
          <Typography mt={1} mb={3} variant="h5">
            {data?.name}
          </Typography>
          <StatusCell value={data?.status || ""} />
        </Box>

        <Box
          sx={{
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            fontWeight: "bold",
          }}
          mt={4}
          mb={4}
        >
          <TabsWrapper
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            textColor="inherit"
          >
            <TabButton label="Configuration" />
            <TabButton label="Discussion" />
          </TabsWrapper>
        </Box>

        <Box mt={6}>
          {value === 0 && <ServiceConfiguration data={data} />}
          {value === 1 && data?.id && <SheetChat staticInput id={data.id} />}
        </Box>
      </Box>
    </Drawer>
  );
};
