import React, { ReactNode } from "react";
import { Box, Tooltip, useTheme } from "@mui/material";
import { MenuOpen } from "@mui/icons-material";
import { CircleIconButton } from "../CircleIconButton/CircleIconButton";
import { LeftRightContent } from "../LeftRightContent/LeftRightContent";

export interface BasePageHeaderProps {
  leftElements: ReactNode;
  rightElements: ReactNode;
  sideNav?: boolean;
  expanded?: boolean;
  leftContainerProps?: any;
  rightContainerProps?: any;
  onClickToggleNav?: (...args: any[]) => any;
}

export const BasePageHeader = ({
  leftElements,
  rightElements,
  leftContainerProps,
  rightContainerProps,
  sideNav,
  expanded,
  onClickToggleNav,
  ...rest
}: BasePageHeaderProps) => {
  const { palette } = useTheme();

  return (
    <Box alignItems="center" display="flex" {...rest}>
      {/* <Box display="flex" alignItems="center">
        {sideNav && expanded && (
          <Tooltip
            placement="bottom-end"
            componentsProps={{
              tooltip: { sx: { backgroundColor: palette.grayAccent.main } },
            }}
            title="Hide the side navigation"
          >
            <div>
              <CircleIconButton
                size="large"
                icon={<MenuOpen color="primary" />}
                onClick={onClickToggleNav}
                aria-label="hide-nav"
              />
            </div>
          </Tooltip>
        )}
        {sideNav && !expanded && (
          <Tooltip
            placement="bottom-end"
            componentsProps={{
              tooltip: { sx: { backgroundColor: palette.grayAccent.main } },
            }}
            title="Show the side navigation"
          >
            <div>
              <CircleIconButton
                size="large"
                icon={
                  <MenuOpen sx={{ transform: "scaleX(-1)" }} color="primary" />
                }
                onClick={onClickToggleNav}
                aria-label="open-nav"
              />
            </div>
          </Tooltip>
        )}
      </Box> */}
      <LeftRightContent
        leftElements={leftElements}
        rightElements={rightElements}
        leftContainerProps={leftContainerProps}
        rightContainerProps={rightContainerProps}
        flexGrow={1}
      />
    </Box>
  );
};
