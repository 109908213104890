import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const InputDataAssetIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83333 11.6665C4.91286 11.6665 4.16667 12.4127 4.16667 13.3332C4.16667 14.2536 4.91286 14.9998 5.83333 14.9998C6.75381 14.9998 7.5 14.2536 7.5 13.3332C7.5 12.4127 6.75381 11.6665 5.83333 11.6665ZM2.5 13.3332C2.5 11.4922 3.99238 9.99984 5.83333 9.99984C7.67428 9.99984 9.16667 11.4922 9.16667 13.3332C9.16667 15.1741 7.67428 16.6665 5.83333 16.6665C3.99238 16.6665 2.5 15.1741 2.5 13.3332Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1667 14.9998C13.7064 14.9998 13.3333 15.3729 13.3333 15.8332C13.3333 16.2934 13.7064 16.6665 14.1667 16.6665C14.6269 16.6665 15 16.2934 15 15.8332C15 15.3729 14.6269 14.9998 14.1667 14.9998ZM11.6667 15.8332C11.6667 14.4525 12.786 13.3332 14.1667 13.3332C15.5474 13.3332 16.6667 14.4525 16.6667 15.8332C16.6667 17.2139 15.5474 18.3332 14.1667 18.3332C12.786 18.3332 11.6667 17.2139 11.6667 15.8332Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9167 3.33317C11.3058 3.33317 10 4.63901 10 6.24984C10 7.86067 11.3058 9.1665 12.9167 9.1665C14.5275 9.1665 15.8333 7.86067 15.8333 6.24984C15.8333 4.63901 14.5275 3.33317 12.9167 3.33317ZM8.33333 6.24984C8.33333 3.71853 10.3854 1.6665 12.9167 1.6665C15.448 1.6665 17.5 3.71853 17.5 6.24984C17.5 8.78114 15.448 10.8332 12.9167 10.8332C10.3854 10.8332 8.33333 8.78114 8.33333 6.24984Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
