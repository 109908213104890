/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from "react";
import { css } from "@emotion/react";
import { Avatar, Box, useTheme } from "@mui/material";
import { AGGridWrapper } from "../../common/AGGridWrapper/AGGridWrapper";
import { ActionBarMembers } from "../ActionBarMembers/ActionBarMembers";
import { MemberBlock } from "../style";
import {
  ColDef,
  ColGroupDef,
  GetQuickFilterTextParams,
  GridApi,
  ICellRendererParams,
} from "ag-grid-enterprise";
import { GridReadyEvent } from "ag-grid-community";
import { useQuery } from "@apollo/client";
import {
  GetWorkspaceMembersDocument,
  MemberStatus,
  WorkspaceRoleEnum,
} from "../../generated";
import { ArrowDropDown, CorporateFare, Person } from "@mui/icons-material";
import { Tag } from "../../common/Tag/Tag";
import { OverridableStringUnion } from "@mui/types";
import { ChipPropsColorOverrides } from "@mui/material/Chip/Chip";
import { Chip } from "../../common/Chip/Chip";
import { displayToast } from "../../common/Toast/toast";
import { RoleCellEditor } from "../RoleCellEditor/RoleCellEditor";
import { convertToTitleCase } from "../../helpers";
import { useParams } from "react-router-dom";
import { GridFooter } from "../../common/AGGridWrapper/GridFooter";
import { ChangeDetectionStrategyType } from "ag-grid-react/lib/shared/changeDetectionService";
import { DisableMemberCellRenderer } from "../DisableMemberCellRenderer/DisableMemberCellRenderer";
import { RemoveMemberCellRenderer } from "../RemoveMemberCellRenderer/RemoveMemberCellRenderer";
import { ResendMemberCellRenderer } from "../ResendMemberCellRenderer/ResendMemberCellRenderer";

export interface MembersGridProps {
  isEditable?: boolean;
}

export const MembersGrid = ({ isEditable }: MembersGridProps) => {
  const { workspaceId } = useParams();
  const { data, loading } = useQuery(GetWorkspaceMembersDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
    },
  });

  const initialPaginationPageSize = 20;
  const [gridApi, setGridApi] = useState<GridApi>();
  const [paginationPageSize, setPaginationPageSize] = useState<number>(
    initialPaginationPageSize
  );
  const [paginationTotalPages, setPaginationTotalPages] = useState<number>(0);
  const [paginationCurrentPage, setPaginationCurrentPage] = useState<number>(0);

  const readOnlyData = useMemo(
    () => data?.workspace?.members || [],
    [data?.workspace?.members]
  );

  const [rowData, setRowData] = useState<any[]>([]);

  useEffect(() => {
    if (Array.isArray(readOnlyData)) {
      const editableData = readOnlyData.map((curr) => ({ ...curr }));
      setRowData(editableData);
    } else {
      setRowData([]);
    }
  }, [readOnlyData]);

  const { palette } = useTheme();

  const onPaginationChanged = () => {
    if (gridApi) {
      setPaginationTotalPages(gridApi.paginationGetTotalPages());
      setPaginationCurrentPage(gridApi.paginationGetCurrentPage());
      setPaginationPageSize(gridApi.paginationGetPageSize());
    }
  };

  const columnDefs = useMemo(() => {
    return [
      {
        field: "memberName",
        headerName: "Member Name",
        getQuickFilterText: (params: GetQuickFilterTextParams) => params.value,
        type: "textColumn",
        cellClass: "dark-cell",
        valueGetter: (params: any) => {
          const firstName = params.data?.firstName;
          const lastName = params.data?.lastName;
          if (firstName && lastName) {
            return `${firstName} ${lastName}`;
          } else if (firstName) {
            return firstName;
          } else if (lastName) {
            return lastName;
          } else {
            return "";
          }
        },
        cellRenderer: (params: ICellRendererParams) => {
          return (
            <Box display="flex" alignItems="center">
              <Avatar
                sx={{ height: "1rem", width: "1rem", fontSize: "1rem", mr: 1 }}
              />
              {params.value}
            </Box>
          );
        },
      },
      {
        field: "emailAddress",
        headerName: "Email",
        getQuickFilterText: (params: GetQuickFilterTextParams) => params.value,
        type: "textColumn",
      },
      {
        field: "organizationName",
        headerName: "Organization",
        getQuickFilterText: (params: GetQuickFilterTextParams) => params.value,
        type: "textColumn",
        cellRenderer: (params: ICellRendererParams) => {
          if (params.value === "") return "";
          return (
            <Box display="flex" alignItems="center">
              <CorporateFare
                sx={{
                  color: palette.gray.dark,
                  mr: ".3125rem",
                  fontSize: ".875rem",
                  width: ".875rem",
                  height: ".875rem",
                }}
              />
              {params.value}
            </Box>
          );
        },
      },
      {
        field: "workspaceRole",
        headerName: "Role",
        getQuickFilterText: (params: GetQuickFilterTextParams) => params.value,
        type: "textColumn",
        cellRenderer: (params: ICellRendererParams) => {
          const startIcon =
            params.value === WorkspaceRoleEnum.Admin ? <Person /> : undefined;
          return (
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
            >
              <Tag
                startIcon={startIcon}
                label={convertToTitleCase(params.value)}
                dismissable={false}
              />
              <ArrowDropDown sx={{ color: palette.gray.dark }} />
            </Box>
          );
        },
        cellEditor: RoleCellEditor,
        cellEditorParams: {
          values: ["Admin", "Collaborator", "Viewer", "Contributor"],
        },
        cellEditorPopup: true,
        editable: isEditable,
      },
      {
        field: "createdAt",
        type: "dateColumn",
        headerName: "Date Added",
      },
      {
        field: "lastAccessed",
        type: "dateColumn",
        headerName: "Last Access",
      },
      {
        field: "status",
        headerName: "Member Status",
        getQuickFilterText: (params: GetQuickFilterTextParams) => params.value,
        type: "textColumn",
        cellRenderer: (params: ICellRendererParams) => {
          let color: OverridableStringUnion<
            | "default"
            | "primary"
            | "secondary"
            | "error"
            | "info"
            | "success"
            | "warning",
            ChipPropsColorOverrides
          > = "primary";
          if (params.value === MemberStatus.Pending) {
            color = "warning";
          } else if (params.value === MemberStatus.Deactivated) {
            color = "error";
          }
          return (
            <Chip
              size="small"
              color={color}
              label={convertToTitleCase(params.value)}
            />
          );
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        getQuickFilterText: (params: GetQuickFilterTextParams) => params.value,
        cellRenderer: (params: ICellRendererParams) => {
          const removeMember = () => {
            let deletedRow = params.data;
            params.api.applyTransaction({ remove: [deletedRow] });
            displayToast("Removed member");
          };
          return (
            <>
              {/* <RemoveMemberCellRenderer
                removeMember={removeMember}
                userId={params.data.id}
              /> */}
              {params.data.status === MemberStatus.Pending && isEditable && (
                <ResendMemberCellRenderer
                  userId={params.data.id}
                  toast={displayToast}
                />
              )}
              <DisableMemberCellRenderer
                removeMember={removeMember}
                userId={params.data.id}
              />
            </>
          );
        },
        width: 80,
      },
    ].filter((item) => {
      if (!isEditable && item.field === "remove") {
        return false;
      }
      return true;
    });
  }, [isEditable, palette]);

  const [searchText, setSearchText] = useState("");

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params?.api);
    params?.api?.paginationSetPageSize(initialPaginationPageSize);
    params?.columnApi?.applyColumnState({
      state: [{ colId: "updated", sort: "asc" }],
    });
  };

  const [noResultsFoundFromFilter, setNoResultsFoundFromFilter] =
    useState(false);

  useEffect(() => {
    const onFilterChanged = ({ api }: { api: GridApi }) => {
      if (rowData.length > 0 && api.getDisplayedRowCount() === 0) {
        setNoResultsFoundFromFilter(true);
      } else {
        setNoResultsFoundFromFilter(false);
      }
    };
    gridApi?.addEventListener("filterChanged", onFilterChanged);
    return () => {
      gridApi?.removeEventListener("filterChanged", onFilterChanged);
    };
  }, [gridApi, rowData]);

  const onChangeSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchText(e.target.value);
    gridApi?.setQuickFilter(e.target.value);
  };

  return (
    <MemberBlock bgcolor={palette.common.white} className="ag-theme-material">
      <ActionBarMembers
        onChangeSearch={onChangeSearch}
        searchText={searchText}
        noResultsFoundFromFilter={noResultsFoundFromFilter}
      />
      <Box flexGrow={1}>
        <AGGridWrapper
          css={css`
            .ag-cell-inline-editing[col-id="role"] {
              height: inherit;
              padding: 0;
              box-shadow: none;
              background-color: initial;
            }
          `}
          loading={loading}
          onGridReady={onGridReady}
          rowData={rowData}
          columnDefs={columnDefs}
          onPaginationChanged={onPaginationChanged}
          defaultColDef={{
            flex: 1,
            minWidth: 200,
          }}
          singleClickEdit
        />
      </Box>
      <GridFooter
        totalRows={rowData.length}
        gridApi={gridApi}
        paginationPageSize={paginationPageSize}
        paginationCurrentPage={paginationCurrentPage}
        paginationTotalPages={paginationTotalPages}
        displayNoResults={noResultsFoundFromFilter || rowData.length === 0}
      />
    </MemberBlock>
  );
};
