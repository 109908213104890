import React, { useState } from "react";
import { BrighthiveLogoSvg } from "../common/Icons/BrighthiveLogoSvg";
import { Label } from "../common/Label/Label";
import TextField from "@mui/material/TextField";
import { Box, Typography, useTheme } from "@mui/material";
import { Button } from "../common/Button/Button";
import { useMutation } from "@apollo/client";
import { ForgotPasswordDocument } from "../generated";
import { SimpleLoader } from "../common/Loader/Loader";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

export interface ForgotPasswordProps {}

interface State {
  username: string;
}

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const { palette } = useTheme();

  const [forgotPassword] = useMutation(ForgotPasswordDocument);
  const [forgotPage, setForgotPage] = useState(true);
  const [loginInProgress, setLoginInProgress] = useState(false);

  const [values, setValues] = React.useState<State>({
    username: "",
  });

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const isDisabled = () => {
    if (values.username.length === 0) {
      return true;
    }

    return false;
  };

  const onClickLogin = (values: State) => {
    setLoginInProgress(true);
    forgotPassword({
      variables: {
        input: { username: values.username },
      },
    })
      .then((res) => {
        setLoginInProgress(false);
        toast.success(`Email has been sent, on ${values.username}`);
        setForgotPage(false);
      })
      .catch((err) => {
        setLoginInProgress(false);
        toast.error("Invalid username");
      });
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        p: 6,
        m: "auto",
        width: "28.5rem",
        background: "white",
        borderRadius: ".375rem",
        boxShadow:
          `0 .0625rem .0625rem ${palette.customRolled.border}, 0 .125rem .0625rem ${palette.action.disabledBackground}, 0 .0625rem .1875rem ${palette.customRolled.contrastText}`,
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="left">
        <BrighthiveLogoSvg
          sx={{
            height: "1.875rem",
            width: "155px",
            mb: 6,
          }}
        />
      </Box>

      {forgotPage ? (
        <>
          <Typography
            variant="h5"
            color={palette.common.black}
            sx={{ margin: "0 0 24px" }}
          >
            Forgot your password?
          </Typography>
          {loginInProgress ? (
            <Box
              display="flex"
              flex="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                ">div": {
                  width: "100%",
                  fontSize: "16px",
                },
                h6: {
                  fontSize: "16px",
                },
              }}
            >
              <SimpleLoader text="Resetting password" />
            </Box>
          ) : (
            <>
              <Label value="Email" variant="subtitle3" sx={{ mb: ".375rem" }} />
              <TextField
                name="username"
                value={values.username}
                hiddenLabel
                fullWidth
                onChange={handleChange("username")}
                inputProps={{
                  sx: { fontSize: "1rem", padding: ".375rem .75rem" },
                }}
                size="small"
                sx={{ mb: 4 }}
              />
              <Box
                sx={{
                  padding: "8px 0 16px",
                  a: {
                    color: palette.link.main,
                    textDecoration: "none",
                    fontSize: "14px",
                    lineHeight: "1",
                    fontWeight: "700",
                  },
                }}
              >
                <Link to="/login">Return to login page</Link>
              </Box>
              <Button
                style={{ marginTop: "1rem" }}
                onClick={() => onClickLogin(values)}
                disabled={isDisabled()}
                fullWidth
                variant="contained"
              >
                Reset password
              </Button>
            </>
          )}
        </>
      ) : (
        <>
          <Typography
            variant="h5"
            color={palette.common.black}
            sx={{ margin: "0 0 24px" }}
          >
            Forgot your password?
          </Typography>
          <Typography variant="body2" color={palette.common.black} mb={8}>
            Look for an email from Brighthive in your inbox with instructions
            for how to reset your password
          </Typography>
          <Box
            sx={{
              padding: "8px 0 16px",
              a: {
                color: palette.link.main,
                textDecoration: "none",
                fontSize: "14px",
                lineHeight: "1",
                fontWeight: "700",
              },
            }}
          >
            <Link to="/login">Return to login page</Link>
          </Box>
        </>
      )}
    </Box>
  );
};
