import { Box } from "@mui/material";
import { Typography } from "../../Typography/Typography";
import { BasicCard } from "../../BasicCard/BasicCard";
import { ActionBar } from "./ActionBar";
import { StatusCell } from "../CellRenderer/StatusCell";
import { useEffect, useState } from "react";
import { ServiceDetail } from "./ServiceDetail";
import { IconCell } from "../CellRenderer/IconCell";
import { AGGridWrapper } from "../../AGGridWrapper/AGGridWrapper";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  GetTransformationServicesDocument,
  TransformationServiceOutput,
} from "../../../generated";
import { convertToTitleCase } from "../../../helpers";

export const Service = ({ query }: any) => {
  const [rowData, setRowData] = useState<any>([]);
  const [detail, setDetail] = useState<any>(undefined);
  const { workspaceId } = useParams();

  const { data, loading } = useQuery(query, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
    },
  });

  const columns = [
    {
      field: "provider",
      rowGroup: true,
      hide: true,
      valueFormatter: ({ value, data }: { value: any; data: any }) =>
        convertToTitleCase(value.replaceAll("_", " ")),
    },
    {
      field: "name",
      headerName: "Service Name",
      cellRenderer: (params: any) => {
        const item = params.data;
        return (
          <Box display="flex" alignItems="center">
            <IconCell value={item.service} />
            <Typography fontWeight="bold" ml={3} variant="body3">
              {item.name}
            </Typography>
          </Box>
        );
      },
    },

    {
      field: "provider",
      headerName: "Provider",
      cellRenderer: (params: any) => {
        const item = params.data;
        const providerName = item.provider;
        return (
          <Box display="flex" alignItems="center">
            <Typography variant="body3">
              {convertToTitleCase(providerName.replaceAll("_", " "))}
            </Typography>
          </Box>
        );
      },
    },
    // {
    //   field: "jobs",
    //   headerName: "Jobs",
    // },
    // {
    //   field: "languages",
    //   headerName: "Languages",
    //   cellRenderer: (params: any) => {
    //     const item = params.data;
    //     return (
    //       <Box display="flex" gap={2}>
    //         {item.languages.map((language: string) => (
    //           <Tag fontSize="small" label={language} />
    //         ))}
    //       </Box>
    //     );
    //   },
    // },
    {
      field: "owner.name",
      headerName: "Owner",
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: (params: any) => {
        const item = params.data;
        return <StatusCell value={item.status} />;
      },
    },
  ];

  useEffect(() => {
    setRowData(
      data?.workspace?.services?.transformationServices ||
        data?.workspace?.services?.warehouseServices ||
        data?.workspace?.services?.ingestionServices ||
        []
    );
  }, [data]);

  return (
    <>
      <Box
        sx={{
          ".ag-theme-material .ag-overlay-no-rows-wrapper.ag-layout-auto-height":
            {
              paddingTop: "0 !important",
            },
          ".ag-row .ag-cell.ag-cell-not-inline-editing.ag-cell-value > div.MuiBox-root":
            {
              flex: "1",
              maxWidth: "100%",
            },
          ".ag-row .ag-cell.ag-cell-not-inline-editing.ag-cell-value p": {
            maxWidth: "calc(100% - 36px)",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }}
      >
        <BasicCard>
          <ActionBar
            loading={loading}
            resetRowData={() =>
              setRowData(data?.workspace?.services.transformationServices || [])
            }
            setRowData={setRowData}
          />
          <Box className="ag-theme-material">
            <AGGridWrapper
              loading={loading}
              rowData={rowData}
              columnDefs={columns}
              defaultColDef={{
                sortable: false,
                flex: 1,
                minWidth: 200,
              }}
              groupDisplayType="groupRows"
              components={{
                agColumnHeader: null,
              }}
              getRowHeight={(params) => (params.node.group ? 32 : 62)}
              groupDefaultExpanded={1}
              domLayout="autoHeight"
              onRowClicked={(params) => {
                setDetail(params.data);
              }}
            />
          </Box>
        </BasicCard>
      </Box>
      <ServiceDetail
        data={detail}
        open={Boolean(detail)}
        onClose={() => setDetail(undefined)}
      />
    </>
  );
};
