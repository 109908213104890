import { Box } from "@mui/material";
import React from "react";
import { DateSeparatorProps } from "stream-chat-react";
import { Typography } from "../../common/Typography/Typography";
import moment from "moment";

function CustomDateSeparator({ date }: DateSeparatorProps) {
  return (
    <Box pt={4} pb={2}>
      <Typography variant="subtitle2">{moment(date).fromNow()}</Typography>
    </Box>
  );
}

export default CustomDateSeparator;
