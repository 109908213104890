import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  useTheme
} from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { Button } from "../../common/Button/Button";
import { DOCLink } from "../../common/Link/Link";
import { SearchBar } from "../../common/SearchBar/SearchBar";
import { groupBy } from "lodash";
import { METADATA_SOURCES } from "../../mock-data/mock-data";
import { IconCell } from "../CellRenderer/IconCell";
import CheckIcon from "@mui/icons-material/Check";

interface SpecificAddDataAssetDialogProps {
  onSubmit: () => void;
  onClose: () => void;
}

type AddMetadataSourceDialogProps = DialogProps &
  SpecificAddDataAssetDialogProps;

export const AddMetadataSourceDialog = (
  props: AddMetadataSourceDialogProps
) => {
  const onClickCancel = () => {
    if (props.onClose) props.onClose();
  };
  const { palette } = useTheme();
  const [selected, setSelected] = useState(["1"]);
  const data = groupBy(METADATA_SOURCES, "project");

  const handleClickItem = (id: string) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id));
    } else {
      const newSelected = [...selected];
      newSelected.push(id);
      setSelected(newSelected);
    }
  };

  return (
    <Dialog {...props} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography mb={4} variant="h5">
          Add metadata sources
        </Typography>
        <Typography variant="body3">
          Organization-owned sources must be enabled by an organization admin
          before they can be added to a workspace.
        </Typography>
        <DOCLink name="View organization connections" href="/" />
        <SearchBar fullWidth placeholder="Find a source" />
      </DialogTitle>
      <DialogContent>
        <Box>
          {Object.keys(data).map((key) => {
            return (
              <Box mb={4}>
                <Typography variant="subtitle3">{key}</Typography>
                {data[key].map((item) => (
                  <Box
                    key={item.id}
                    display="flex"
                    alignItems="center"
                    paddingX={4}
                    paddingY={3}
                    borderRadius={1}
                    mt={2}
                    sx={{
                      position: "relative",
                      cursor: "pointer",
                      border: (theme) => `1px solid ${theme.palette.divider}`,
                      backgroundColor: selected.includes(item.id)
                        ? palette.customRolled.outline
                        : "transparent",
                    }}
                    onClick={() => handleClickItem(item.id)}
                  >
                    {selected.includes(item.id) && (
                      <Box position="absolute" top={10} right={10}>
                        <CheckIcon fontSize="small" />
                      </Box>
                    )}
                    <IconCell value={item.service} />
                    <Box ml={3}>
                      <Typography variant="body3" fontWeight={500}>
                        {item.name}
                      </Typography>
                      <Typography variant="body3">
                        {item.cloudProvider} | {item.service} | {item.access}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            );
          })}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box position="absolute" left={22}>
          <Typography variant="body3">
            {selected.length ? `${selected.length} selected` : ""}
          </Typography>
        </Box>
        <Button onClick={onClickCancel} color="grayAccent" variant="outlined">
          Cancel
        </Button>
        <Button color="primary" variant="contained">
          Add selected
        </Button>
      </DialogActions>
    </Dialog>
  );
};
