import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const LayoutGridViiIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="none" d="M9 4.59961H5C4.44772 4.59961 4 5.04732 4 5.59961V9.59961C4 10.1519 4.44772 10.5996 5 10.5996H9C9.55228 10.5996 10 10.1519 10 9.59961V5.59961C10 5.04732 9.55228 4.59961 9 4.59961Z" stroke="#097771" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M19 4.59961H15C14.4477 4.59961 14 5.04732 14 5.59961V9.59961C14 10.1519 14.4477 10.5996 15 10.5996H19C19.5523 10.5996 20 10.1519 20 9.59961V5.59961C20 5.04732 19.5523 4.59961 19 4.59961Z" stroke="#097771" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M9 14.5996H5C4.44772 14.5996 4 15.0473 4 15.5996V19.5996C4 20.1519 4.44772 20.5996 5 20.5996H9C9.55228 20.5996 10 20.1519 10 19.5996V15.5996C10 15.0473 9.55228 14.5996 9 14.5996Z" stroke="#097771" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M17 14.5996V20.5996M14 17.5996H20H14Z" stroke="#097771" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};
