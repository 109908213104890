import { Box, Typography, useTheme } from "@mui/material";
import { useMemo, useState } from "react";
import { Button } from "../../common/Button/Button";
import { FetchResult } from "@apollo/client";
import {
  TargetSchema,
  UpdateTargetSchemaMutation,
  UpdateWorkspaceSchemaMutation,
} from "../../generated";

import { ProjectSchemaDetailGrid } from "./ProjectSchemaDetailGrid";
import ProjectSchemaDetailMutationDrawer from "./Drawers/MutationDrawer";
import ProjectSchemaDetailViewDrawer from "./Drawers/ViewDrawer";
import {
  DisplayDrawerHandlerType,
  DrawerDisplayerType,
  DrawerType,
} from "../../common/Drawer";

export const ProjectSchemaDetailPage = ({
  data,
  update,
}: {
  data: TargetSchema;
  update: (
    parse: any
  ) => Promise<
    FetchResult<UpdateTargetSchemaMutation | UpdateWorkspaceSchemaMutation>
  >;
}) => {
  const [gridApi, setGridApi] = useState(null);
  const { palette } = useTheme();
  const [drawerDisplayer, setDrawerDisplayer] = useState<DrawerDisplayerType>({
    type: null,
    id: undefined,
  });

  const schemaDetail = data;

  const displayDrawerHandler: DisplayDrawerHandlerType = (type, id?) => {
    setDrawerDisplayer({ type, id });
  };

  const handleApiReady = (api: any) => {
    setGridApi(api);
  };

  const rowData = useMemo(() => {
    if (schemaDetail?.jsonSchema) {
      const parse = JSON.parse(schemaDetail.jsonSchema);
      return Object.keys(parse.properties || {}).map((key, idx) => {
        const item = parse.properties[key];
        let type = item.type;
        if (item.enum) {
          type = "enum";
        }

        const callRecords: any[] = [];

        item.anyOf?.forEach((element: any) => {
          let value = "";

          if (element.type === "string") {
            value = element.pattern;
          }

          if (element.enum) {
            value = element.enum.join(", ");
          }

          callRecords.push({
            ...element,
            type: element.enum ? "enum" : element.type,
            value,
          });
        });

        let value = "";

        if (item.type === "string") {
          value = item.pattern;
        }

        if (item.enum) {
          value = item.enum.join(", ");
        }

        return {
          type,
          value,
          no: idx + 1,
          name: key,
          callRecords,
          required: parse.required?.includes(key) || false,
          ...item,
        };
      });
    }
    return [];
  }, [schemaDetail?.jsonSchema]);

  const selectedRow = useMemo(() => {
    if (!drawerDisplayer.id || rowData.length === 0) return null;

    return rowData.find((row) => row.no === drawerDisplayer.id);
  }, [drawerDisplayer.id]);

  return (
    <>
      <Box
        display="flex"
        sx={{ justifyContent: "space-between" }}
        marginBottom="1rem"
      >
        <Typography variant="h6">
          Target Schema:
          <Box component="span" ml={1} fontWeight="normal">
            {schemaDetail?.name}
          </Box>
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => displayDrawerHandler("create")}
        >
          Add a field
        </Button>
      </Box>

      <Box
        bgcolor={palette.common.white}
        display="flex"
        flexDirection="column"
        boxSizing="border-box"
        flexGrow={1}
        border={`0.0625rem solid ${palette.outline}`}
        borderRadius="0.375rem"
      >
        <ProjectSchemaDetailGrid
          schemaDetail={schemaDetail}
          rowData={rowData}
          update={update}
          displayDrawerHandler={displayDrawerHandler}
          handleApiReady={handleApiReady}
        />
      </Box>
      {/* <ModalTargetSchemaField
        show={openAdd}
        closeDialog={() => setOpenAdd(false)}
        submit={(v: any) => {
          const parse = JSON.parse(schemaDetail?.jsonSchema || "{}");

          const properties = parse?.properties || {};

          properties[v.name] = {
            description: v.description,
            type: v.type,
          };

          if (v.minLength) {
            properties[v.name]["minLength"] = v.minLength;
          }

          if (v.maxLength) {
            properties[v.name]["maxLength"] = v.maxLength;
          }

          parse.properties = properties;
          const required: string[] = parse?.required || [];
          if (!required.includes(v.name) && v.required) {
            required.push(v.name);
          }

          if (required.length > 0) {
            parse.required = required;
          }

          return mutateUpdate({
            variables: {
              input: {
                targetSchemaId: schemaId || "",
                workspaceId: workspaceId || "",
                projectId: projectId || "",
                name: schemaDetail?.name,
                description: schemaDetail?.description,
                jsonSchema: JSON.stringify(parse),
              },
            },
          });
        }}
      /> */}

      <ProjectSchemaDetailViewDrawer
        data={selectedRow}
        drawerDisplayer={drawerDisplayer}
        displayDrawerHandler={displayDrawerHandler}
        targetSchema={schemaDetail}
        gridApi={gridApi}
      />
      <ProjectSchemaDetailMutationDrawer
        data={selectedRow}
        displayDrawerHandler={displayDrawerHandler}
        drawerType={["create", "edit"] as DrawerType[]}
        drawerDisplayer={drawerDisplayer}
        schemaDetail={schemaDetail}
        update={update}
      />
    </>
  );
};
