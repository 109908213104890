import { useEffect, useState } from "react";
import { ActionBar } from "./ActionBar";
import { BasicCard } from "../../../common/BasicCard/BasicCard";
import { Box, Dialog, Drawer, IconButton, Typography } from "@mui/material";
import { GridView } from "./GridView";
import { CardView } from "./CardView";
import { AddDetail } from "../AddDetail/AddDetail";
import { AddSource } from "../AddSource/AddSource";
import { Close } from "@mui/icons-material";
import { SourceOutput } from "../../../generated";
import { SimpleLoader } from "../../../common/Loader/Loader";
import { size } from "lodash";

// const data = [
//   {
//     name: "PortFort",
//     status: "Connected",
//     type: "Partner",
//     dataAsset: 40,
//     owner: {
//       name: "BrightHivee",
//       email: "brightgive@hibve.com",
//     },
//     url: "https://www.google.com/",
//     dataServiceType: "Connection Type",
//     description: "A new eve of motion... awpdawdawdk awdnaiwdniawd awd",
//     tags: ["one", "two", "three"],
//   },
//   {
//     name: "BrightHive",
//     status: "Connected",
//     type: "Partner",
//     dataAsset: 40,
//     owner: {
//       name: "BrightHivee",
//       email: "brightgive@hibve.com",
//     },
//     dataServiceType: "Connection Type",
//     description: "A new eve of motion... awpdawdawdk awdnaiwdniawd awd",
//     tags: ["one", "two", "three"],
//   },
//   {
//     name: "RotMOt",
//     status: "Requested",
//     type: "Partner",
//     dataAsset: 40,
//     owner: {
//       name: "BrightHivee",
//       email: "brightgive@hibve.com",
//     },
//     dataServiceType: "Connection Type",
//     description: "A new eve of motion... awpdawdawdk awdnaiwdniawd awd",
//     tags: ["one", "two", "three"],
//   },
// ];

export const AllGrid = ({ data, loading }: { data: any; loading: boolean }) => {
  const [source, setSource] = useState<string | boolean>(false);
  const [cardView, setCardView] = useState(true);
  const [rowData, setRowData] = useState<any>(data);
  const [detail, setDetail] = useState<SourceOutput>();

  const closeDialog = () => {
    setSource(false);
  };

  useEffect(() => {
    setRowData(data);
  }, [data]);

  return (
    <Box>
      <BasicCard
        sx={{
          background: "transparent",
          boxShadow: "none",
          border: "none",
        }}
      >
        <ActionBar
          resetRowData={() => setRowData(data)}
          setRowData={setRowData}
          data={data}
          setCardView={setCardView}
          setSource={setSource}
          cardView={cardView}
        />
        <Box className="ag-theme-material">
          {loading ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ margin: "8rem 0" }}
            >
              <SimpleLoader />
            </Box>
          ) : size(rowData) >= 1 ? (
            cardView ? (
              <CardView rowData={rowData} setDetail={setDetail} />
            ) : (
              <GridView rowData={rowData} setDetail={setDetail} />
            )
          ) : (
            <Typography mt={8} textAlign={"center"}>
              No Record Found
            </Typography>
          )}
        </Box>
      </BasicCard>
      <AddDetail data={detail} onClose={() => setDetail(undefined)} />

      <Drawer
        anchor="right"
        open={(source && true) || false}
        onClose={closeDialog}
      >
        <Box width={400} p={8}>
          <Box position="relative" mb={4}>
            <Box position="absolute" top={-10} right={0}>
              <IconButton onClick={() => closeDialog()} size="small">
                <Close />
              </IconButton>
            </Box>
            <AddSource
              source={source}
              setSource={setSource}
              close={closeDialog}
            />
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};
