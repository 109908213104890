import React from "react";
import { DataNode } from "./DataNode";
import { WorkflowOrganizationOutput } from "../generated";

export interface OrganizationNodeProps {
  data: WorkflowOrganizationOutput;
  selected?: boolean;
  id?: string;
}

export const OrganizationNode = ({
  data: { name, issueStatus, dataAssetCount },
  selected,
  id,
}: OrganizationNodeProps) => {
  const getDataAssetCountText = (dataAssetCount: number) => {
    if (dataAssetCount === 0 || isNaN(dataAssetCount)) {
      return "0 owned assets";
    } else if (dataAssetCount === 1) {
      return "1 owned asset";
    } else {
      return `${dataAssetCount} owned assets`;
    }
  };

  return (
    <DataNode
      id={id}
      targetHandle={false}
      selected={selected}
      title={name}
      issueStatus={issueStatus}
      caption={getDataAssetCountText(dataAssetCount)}
    />
  );
};
