import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const AppendCheckIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="11"
      height="14"
      viewBox="0 0 11 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="none"
        d="M6.83325 1V3.66667C6.83325 3.84348 6.90349 4.01305 7.02851 4.13807C7.15354 4.2631 7.32311 4.33333 7.49992 4.33333H10.1666"
        stroke="black"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        d="M5.49992 6.33333V10.3333M3.49992 8.33333H7.49992M8.83325 13H2.16659C1.81296 13 1.47382 12.8595 1.22378 12.6095C0.973728 12.3594 0.833252 12.0203 0.833252 11.6667V2.33333C0.833252 1.97971 0.973728 1.64057 1.22378 1.39052C1.47382 1.14048 1.81296 1 2.16659 1H6.83325L10.1666 4.33333V11.6667C10.1666 12.0203 10.0261 12.3594 9.77606 12.6095C9.52601 12.8595 9.18687 13 8.83325 13Z"
        stroke="black"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
