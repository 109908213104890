import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { AppContext } from "../../App";
import { ProjectSidenav } from "../../common/ProjectSidenav/ProjectSidenav";
import { SideNavigationPage } from "../../common/SideNavigationPage/SideNavigationPage";
import { GridView } from "@mui/icons-material";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import { useMutation, useQuery } from "@apollo/client";
import {
  CreateTargetSchemaDocument,
  GetProjectDocument,
  GetSourcesDocument,
  GetTargetSchemasDocument,
  SourceType,
} from "../../generated";
import { Button } from "../../common/Button/Button";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DataServiceNav } from "../../common/DataServicePage/DataServiceNav";
import { SourcesNav } from "../../common/SourcesPage/SorcesNav";
import { AllGrid } from "./AllGrid/AllGrid";

export const All = () => {
  const navigate = useNavigate();

  const [openAdd, setOpenAdd] = useState(false);
  const { projectId, workspaceId } = useParams();
  const { palette } = useTheme();
  const location = useLocation();

  const active = location.pathname
    ?.split(`/workspace/${workspaceId}/`)[1]
    ?.split("/")[0];

  const source =
    active === "sources"
      ? location.pathname
          ?.split(`/workspace/${workspaceId}/sources/`)[1]
          ?.split("/")[0]
      : null;

  const { data } = useQuery(GetProjectDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
      projectFilter: {
        projectId: projectId || "",
      },
    },
  });

  const { data: sources, loading } = useQuery(GetSourcesDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
      sourceFilter: {
        sourceType: SourceType.Internal,
      },
    },
  });

  const workspace = data?.workspace;
  const project = data?.workspace?.projects?.[0];

  return (
    <AppContext.Consumer>
      {({ showSidenav, setShowSidenav }) => (
        <>
          <SideNavigationPage
            className="sources-view"
            innerSideBar
            leftHeaderElements={
              <Box
                sx={{
                  textTransform: "capitalize",
                }}
              >
                <Typography variant="h5">
                  {source === "all" ? "All" : source} Sources
                </Typography>
              </Box>
            }
            // verticalNav={
            //   <SourcesNav
            //     show={showSidenav}
            //     workspaceName={workspace?.name || ""}
            //     setShowSidenav={setShowSidenav}
            //   />
            // }
            content={
              <AllGrid
                data={sources?.workspace?.services?.sources || []}
                loading={loading}
              />
            }
          />
        </>
      )}
    </AppContext.Consumer>
  );
};
