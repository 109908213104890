import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const SendIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M10 14L21 3M10 14L13.5 21C13.5439 21.0957 13.6143 21.1769 13.703 21.2338C13.7916 21.2906 13.8947 21.3209 14 21.3209C14.1053 21.3209 14.2084 21.2906 14.2971 21.2338C14.3857 21.1769 14.4561 21.0957 14.5 21L21 3M10 14L3.00001 10.5C2.90427 10.4561 2.82314 10.3857 2.76626 10.2971C2.70938 10.2084 2.67914 10.1053 2.67914 10C2.67914 9.89468 2.70938 9.79158 2.76626 9.70295C2.82314 9.61431 2.90427 9.54387 3.00001 9.5L21 3"
        stroke="currentColor"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
