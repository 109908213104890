import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { Chip } from "../../common/Chip/Chip";
import PersonIcon from "@mui/icons-material/Person";
import { Button } from "../../common/Button/Button";
import { DeleteConfirmationDataProduct } from "./DeleteConfirmationDataProduct";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetCreatedDataProductsDocument,
  GetDataProductDocument,
} from "../../generated";
import { useQuery } from "@apollo/client";
import {
  getAlertMessage,
  getIssueColor,
  getIssueIcon,
  getLabelIssueStatus,
  getLoader,
} from "../helpers";
import { SheetTabs } from "../SheetTabs";
import { SheetChat } from "../SheetChat";

export const DataProduct = ({
  id,
  parentId,
}: {
  id: string;
  parentId: string;
}) => {
  const { palette } = useTheme();
  const { workspaceId, projectId } = useParams();
  const navigate = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [show, setShow] = useState(false);
  const [hasPii, setHasPii] = useState(false);
  const [tab, setTab] = useState(0);

  const toggleRemoveModal = () => {
    setShowConfirmationModal(true);
  };

  const { data, loading } = useQuery(GetCreatedDataProductsDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
      projectFilter: {
        projectId: projectId || "",
      },
      createdDataProductFilter: {
        dataAssetId: id || "",
      },
    },
  });

  if (loading) return getLoader("Fetching Data", "custom-loader full-height");
  const dataProduct = data?.workspace?.projects?.[0]?.createdDataProducts?.[0];

  if (dataProduct) {
    return (
      <Box
        boxSizing="border-box"
        sx={{
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box
            sx={{
              paddingRight: "20px",
              paddingBottom: "16px",
              borderBottom: `1px solid ${palette.outline}`,
            }}
            mb={6}
          >
            <Typography
              mb={2}
              variant="h5"
              color={palette.gray.dark}
              sx={{
                fontSize: "12px",
                lineHeight: "14px",
                textTransform: "uppercase",
                fontWeight: "700",
              }}
            >
              Data Product
            </Typography>
            <Typography
              mb={4}
              variant="h5"
              color={palette.common.black}
              sx={{ wordBreak: "break-word" }}
            >
              {dataProduct?.name}
            </Typography>

            {show && (
              <Chip
                icon={getIssueIcon(dataProduct.status)}
                color={getIssueColor(dataProduct.status)}
                label={getLabelIssueStatus(dataProduct.status)}
                sx={{
                  color: palette.common.black,
                  fontSize: "12px",
                  lineHeight: "16px",
                  fontWeight: "500",
                }}
              />
            )}

            {hasPii && (
              <Chip
                icon={
                  <PersonIcon
                    sx={{
                      fontSize: "1rem",
                      color: palette.common.black + "!important",
                    }}
                  />
                }
                color="gray"
                label="Contains PII"
                sx={{
                  color: palette.common.black,
                  fontSize: "12px",
                  lineHeight: "16px",
                  fontWeight: "500",
                  background: "none",
                  border: `1px solid ${palette.grayAccent.light}`,
                  marginLeft: "8px",
                }}
              />
            )}

            <SheetTabs value={tab} onChange={setTab} />
          </Box>

          {tab === 0 && (
            <Box
              sx={{
                paddingBottom: "16px",
              }}
              mb={6}
            >
              {show &&
                getAlertMessage(
                  dataProduct.status,
                  "An entity managed by this organization is preventing the data flow from running."
                )}

              <Typography
                mb={2}
                variant="h5"
                color={
                  dataProduct?.description
                    ? palette.gray.darkest
                    : palette.gray.dark
                }
                sx={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: "normal",
                }}
              >
                {dataProduct?.description ? dataProduct.description : "None"}
              </Typography>

              <Button
                sx={{
                  color: palette.link.main,
                  background: "none !important",
                  padding: "0",
                }}
                onClick={() =>
                  navigate(`/workspace/${workspaceId}/asset-details/${id}`)
                }
              >
                View asset details
              </Button>
              <br />
              {/* <Button
                sx={{
                  color: palette.link.main,
                  background: "none !important",
                  padding: "0",
                }}
                onClick={() =>
                  navigate(`/workspace/${workspaceId}/asset-details/${id}?tab=schema`)
                }
              >
                View target schema validation results
              </Button> */}
            </Box>
          )}

          {tab === 1 && <SheetChat id={id} />}
        </Box>

        {tab === 0 && (
          <Box>
            <Button
              color="error"
              variant="outlined"
              sx={{ width: "100%" }}
              onClick={toggleRemoveModal}
            >
              Removing data product from group
            </Button>
          </Box>
        )}
        {showConfirmationModal && dataProduct && (
          <DeleteConfirmationDataProduct
            dataProductAssetId={dataProduct.id}
            finalDataProductGroupId={parentId}
            dataProductAssetName={dataProduct.name}
          />
        )}
      </Box>
    );
  }
  return null;
};
