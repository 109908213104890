import { Box, Button, Grid, Paper, Typography, useTheme } from "@mui/material";

import { DestinationDownload } from "../../common/Icons/DestinationDownload";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Maybe } from "../../generated";

interface ViewSchemaProps {
  name: string | undefined;
  modifiedAt: Maybe<string> | string | undefined;
  jsonSchema: Maybe<string> | string | undefined;
}

export const ViewSchema = ({
  name,
  modifiedAt,
  jsonSchema,
}: ViewSchemaProps) => {
  const { palette } = useTheme();
  return name ? (
    <>
      <Grid item xs={12} mb={4} sx={{ minWidth: "360px" }}>
        <Paper
          variant="outlined"
          sx={{
            p: 4,
            display: "flex",
            minHeight: "100%",
            boxSizing: "border-box",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box display="flex">
            <Box
              mr={3}
              width="2rem"
              sx={{
                ".MuiSvgIcon-root": {
                  minWidth: "2rem",
                  width: "2rem",
                  height: "2rem",
                  path: {
                    fill: palette.grayAccent.light,
                  },
                },
              }}
            >
              <DestinationDownload />
            </Box>

            <Box sx={{ paddingLeft: 3 }}>
              <Typography
                variant="subtitle2"
                color={palette.common.black}
                sx={{
                  fontSize: "0.875rem",
                  fontWeight: 500,
                }}
              >
                {name}
              </Typography>
              <Typography
                variant="subtitle2"
                color={palette.gray.darkest}
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                {modifiedAt}
              </Typography>
            </Box>
          </Box>
          <Button
            color="primary"
            variant="contained"
            href={`data:text/json;charset=utf-8,${encodeURIComponent(
              jsonSchema || ""
            )}`}
            //@ts-ignore
            download="schema.json"
            endIcon={<FileDownloadOutlinedIcon fontSize="small" />}
            sx={{
              ".MuiSvgIcon-root": {
                fontSize: "1.5rem !important",
              },
              ".MuiButton-endIcon": {
                margin: 0,
              },
            }}
          ></Button>
        </Paper>
      </Grid>
    </>
  ) : (
    <></>
  );
};
ViewSchema.defaultProps = {};
