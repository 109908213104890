import React, { Fragment, useState } from "react";
import { css, keyframes } from "@emotion/react";
import { Typography } from "../common/Typography/Typography";
import { Box, useTheme } from "@mui/material";
import { FolderOutlined, GridView, PlayArrow } from "@mui/icons-material";
import { Node } from "react-flow-renderer";
import {
  BreadcrumbLink,
  LastBreadcrumbLink,
} from "../common/BreadcrumbsLinks/BreadcrumbLink";
import { BreadcrumbLinks } from "../common/BreadcrumbsLinks/BreadcrumbLinks";
import { ProjectWorkflow } from "./ProjectWorkflow";
import { ReactFlowProvider } from "react-flow-renderer";
import { useMutation, useQuery } from "@apollo/client";
import {
  GetProjectInfoDocument,
  GetWorkspaceInfoDocument,
  RunTransformationsInProjectDocument,
  ValidateTransformationsInProjectDocument,
} from "../generated";
import { ProjectPage } from "../common/ProjectPage/ProjectPage";
import { Button } from "../common/Button/Button";
import { useParams } from "react-router-dom";
import RightSidePanel from "../common/RightSidePanel";
import { Sheets } from "../Sheets";
import { ElementType } from "./helpers";
import { SnackBar } from "../common/SnakBar/SnakBar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { delay } from "lodash";
import SettingsIcon from "@mui/icons-material/Settings";
import { ButtonPrimary } from "./style";

export interface ProjectWorkflowPageProps {}

const myEffectExit = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const ProjectWorkflowPage = () => {
  const { palette } = useTheme();
  const { workspaceId, projectId } = useParams();
  const [showRightSidePanel, toggleRightSidePanel] = useState<boolean>(false);
  const [selectedNode, selectNode] = useState<Node | null>(null);
  const [testRunning, setTestRunning] = useState(false);
  const [dataRunning, setDataRunning] = useState(false);
  const [testData, setTestData] = useState(false);
  const [disabledTestButton, setDisabledTestButton] = useState(false);
  const { data: workspaceData } = useQuery(GetWorkspaceInfoDocument, {
    variables: { input: { workspaceId: workspaceId || "" } },
  });
  const { data: projectData } = useQuery(GetProjectInfoDocument, {
    variables: {
      input: { workspaceId: workspaceId || "" },
      projectFilter: {
        projectId: projectId || "",
      },
    },
  });

  const [runTransformation, { loading }] = useMutation(
    RunTransformationsInProjectDocument
  );

  const [validateTransformation, { loading: validateLoading }] = useMutation(
    ValidateTransformationsInProjectDocument
  );

  const [showSnakBarToast, setShowSnakBarToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const workspaceName = workspaceData?.workspace?.name || "";
  const projects = projectData?.workspace?.projects;
  const project = projectData?.workspace?.projects?.[0];
  const projectName =
    Array.isArray(projects) && typeof projects[0]?.name === "string"
      ? projects[0].name
      : "";
  const isEditable = project?.isEditable;

  const handleCloseSnakBar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    setShowSnakBarToast(false);
  };

  const action = (
    <Fragment>
      {/* <Button color="secondary" size="small" onClick={handleCloseSnakBar}>
        UNDO
      </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnakBar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  const showToast = (message: string) => {
    setToastMessage(message);
    setShowSnakBarToast(true);
  };
  const toggleRightSidePanelState = () => {
    toggleRightSidePanel((state) => !state);
  };
  return (
    <>
      {showSnakBarToast && (
        <SnackBar
          open={showSnakBarToast}
          message={toastMessage}
          onClose={handleCloseSnakBar}
          action={action}
        />
      )}
      <ProjectPage
        projectContent={
          <BreadcrumbLinks>
            <BreadcrumbLink
              icon={<GridView />}
              to={`/workspace/${workspaceId}/project`}
              iconSize="small"
            >
              <Typography variant="body3">{workspaceName}</Typography>
            </BreadcrumbLink>
            <LastBreadcrumbLink icon={<FolderOutlined />} iconSize="small">
              <Typography variant="body3">{projectName}</Typography>
            </LastBreadcrumbLink>
          </BreadcrumbLinks>
        }
        leftHeaderElements={
          <>
            <Typography mr={2} variant="h5">
              Project Data Flow
            </Typography>
            {/*<Chip avatar={<PlayArrow />} color="primary" label="Active" />*/}
          </>
        }
        rightHeaderElements={
          isEditable ? (
            <>
              <ButtonPrimary
                variant="contained"
                color="grayAccent"
                startIcon={<PlayArrow />}
                disabled={disabledTestButton}
                style={{ marginRight: "0.5rem" }}
                onClick={() => {
                  setDisabledTestButton(true);
                  validateTransformation({
                    variables: {
                      input: {
                        workspaceId: workspaceId || "",
                        projectId: projectId || "",
                      },
                    },
                  })
                    .then(() => {
                      setTestRunning(true);
                      delay(() => {
                        toast.success(
                          "Data flow is tested, passed, and ready to run"
                        );
                        setTestData(true);
                        setDisabledTestButton(false);
                        setTestRunning(false);
                      }, 5000);
                    })
                    .catch((err) => {
                      setTestData(false);
                      setDisabledTestButton(false);
                      toast.error(err.message);
                    });
                }}
              >
                Test Data Flow
              </ButtonPrimary>
              {!dataRunning ? (
                <ButtonPrimary
                  variant="contained"
                  startIcon={<PlayArrow />}
                  disabled={loading || !testData}
                  onClick={() => {
                    setDataRunning(true);
                    runTransformation({
                      variables: {
                        input: {
                          workspaceId: workspaceId || "",
                          projectId: projectId || "",
                        },
                      },
                    })
                      .then(() => {
                        // setDataRunning(true);
                        delay(() => {
                          toast.success("Data flow has been triggered");
                          setDataRunning(false);
                        }, 10000);
                      })
                      .catch((err) => {
                        setDataRunning(false);
                        toast.error(err.message);
                      });
                  }}
                >
                  Run
                </ButtonPrimary>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={
                    <SettingsIcon
                      sx={{
                        animation: `${myEffectExit} 1s infinite linear`,
                      }}
                    />
                  }
                >
                  Run
                </Button>
              )}
            </>
          ) : null
        }
        content={
          <Box
            bgcolor={palette.common.white}
            display="flex"
            flexDirection="column"
            boxSizing="border-box"
            flexGrow={1}
            border={`0.0625rem solid ${palette.outline}`}
            borderRadius="0.375rem"
          >
            <RightSidePanel
              isOpen={showRightSidePanel}
              onToggle={toggleRightSidePanelState}
            >
              <Sheets
                node={selectedNode}
                toggleRightSidePanel={toggleRightSidePanelState}
                showToast={showToast}
              />
            </RightSidePanel>
            <ReactFlowProvider>
              <ProjectWorkflow
                testRunning={testRunning}
                dataRunning={dataRunning}
                onNodeClick={(node: Node<any>) => {
                  if (node.type === ElementType.HEADER_NODE) return;
                  selectNode(node);
                  toggleRightSidePanel((state) => !state);
                }}
                isEditable={isEditable}
              />
            </ReactFlowProvider>
          </Box>
        }
      />
    </>
  );
};
