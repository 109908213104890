import React from "react";
import { Box } from "@mui/material";
import { Link } from "../../../common/Link/Link";
import { ArrowBack, KeyboardArrowDown } from "@mui/icons-material";
import { Typography } from "../../../common/Typography/Typography";
import { Button } from "../../../common/Button/Button";
import { BasicPage } from "../../../common/BasicPage/BasicPage";

import { useParams, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  GetADataAssetDocument,
  GetSchemasDocument,
  GetTargetSchemasDocument,
  UpdateSchemaDocument,
} from "../../../generated";
import { SchemaDetail } from "./SchemaDetails";

export interface ProjectSchemaEditDetailrops {}

export const SchemaDetailPage = ({
  isEditMode = false,
  schema,
  showBackBtn = true,
}: {
  isEditMode?: boolean;
  schema?: any;
  showBackBtn?: any;
}) => {
  const { workspaceId, schemaId: id } = useParams();
  const navigate = useNavigate();

  const schemaId = id || schema?.id;

  const { data, loading } = useQuery(GetSchemasDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
      schemaId: schemaId || "",
    },
    skip: schema,
  });

  const schemaDetail = data?.workspace?.schemas?.[0] || schema;

  return (
    <BasicPage
      sx={{
        ".right-elements": {
          alignSelf: "flex-end",
        },
      }}
      leftHeaderElements={
        <Box>
          {showBackBtn && (
            <Box mb={3}>
              <Box
                onClick={() => navigate(-1)}
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ArrowBack
                  sx={{
                    width: "1.25rem",
                    height: "1.25rem",
                    fontSize: "1.25rem",
                    mr: 2,
                  }}
                />
                <Typography variant="body3">Back</Typography>
              </Box>
            </Box>
          )}
          <Box display="flex">
            <Typography mr={4} variant="h6">
              {schemaDetail?.name || ""}
            </Typography>
          </Box>
        </Box>
      }
      content={
        <SchemaDetail
          data={schemaDetail}
          loading={loading}
          isEditMode={isEditMode}
        />
      }
    />
  );
};

export {};
