import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const PapersListIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="none"
        d="M8.66675 1.5V4.83333C8.66675 5.05435 8.75455 5.26631 8.91083 5.42259C9.06711 5.57887 9.27907 5.66667 9.50008 5.66667H12.8334"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        d="M1.16675 9V3.16667C1.16675 2.72464 1.34234 2.30072 1.6549 1.98816C1.96746 1.67559 2.39139 1.5 2.83341 1.5H8.66675L12.8334 5.66667V9M1.16675 16.5H12.8334M1.16675 14H12.8334M1.16675 11.5H12.8334"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
