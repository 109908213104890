import React, { useState, useMemo } from "react";
import { Box, Dialog, InputAdornment, TextField, Grid } from "@mui/material";

import { Typography } from "../../../common/Typography/Typography";
import { Button } from "../../../common/Button/Button";
import { Search } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useApolloClient, useQuery } from "@apollo/client";
import {
  GetWorkflowDocument,
  WorkflowDataAssetGroupOutput,
} from "../../../generated";
import DataAssetItem from "./DataAssetItem";
import { debounce } from "lodash";
import { SimpleLoader } from "../../../common/Loader/Loader";

interface SelectInputDataAssetGroupsModalProps {
  show: boolean;
  closeDialog: Function;
  submit: (item: WorkflowDataAssetGroupOutput) => void;
}

export const SelectInputDataAssetGroupsModal = ({
  show,
  closeDialog,
  submit,
}: SelectInputDataAssetGroupsModalProps) => {
  const [search, setSearch] = useState("");

  const { workspaceId, projectId } = useParams();
  const client = useApolloClient();

  const { data, loading } = useQuery(GetWorkflowDocument, {
    variables: {
      workflowFilterInput: {
        projectId: projectId || "",
        workspaceId: workspaceId || "",
      },
    },
  });

  const rowData = useMemo(() => {
    const results =
      data?.workflow?.inputDataAssetGroups
        ?.map((asset: any) => asset)
        .filter((asset: any) => {
          if (search) {
            return asset?.name
              ?.toLocaleLowerCase()
              .includes(search.toLocaleLowerCase());
          }
          return true;
        }).filter((asset) => asset.schemaId) || [];

    return results;
  }, [data?.workflow?.inputDataAssetGroups, search]);

  return (
    <Dialog maxWidth="xl" open={show} onClose={() => closeDialog()}>
      <Box
        onClick={(e) => e.preventDefault()}
        width="50rem"
        p={6}
        boxSizing="border-box"
      >
        <Typography mb={4} variant="h5">
          Select input data asset groups
        </Typography>
        <Box display="flex" justifyContent="space-between">
          <TextField
            placeholder="Find a input data asset groups"
            size="small"
            onChange={debounce((e) => setSearch(e.target.value), 1000)}
            InputProps={{
              sx: {
                fontSize: "1rem",
                width: "20rem",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          mt={4}
          sx={{
            maxHeight: "calc(100vh - 300px)",
            minHeight: "200px",
            overflowY: "auto",
          }}
        >
          {loading && (
            <Box display="flex" justifyContent="center">
              <SimpleLoader />
            </Box>
          )}
          <Grid container spacing={2}>
            {rowData.map((item) =>
              !item ? null : (
                <Grid item key={item.id} xs={12}>
                  <DataAssetItem
                    item={item}
                    selected={false}
                    onClickSelected={(v) => {
                      submit(item);
                    }}
                  />
                </Grid>
              )
            )}
          </Grid>
        </Box>
        <Box
          mt={4}
          display="flex"
          alignItems={"center"}
          justifyContent="space-between"
        >
          <Box></Box>
          <Box display="flex">
            <Button
              onClick={(e) => {
                e.preventDefault();
                closeDialog();
              }}
              color="grayAccent"
              variant="outlined"
              sx={{ ml: "auto", mr: 3 }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
