import { styled, Box, IconButton, Typography, Button } from "@mui/material";
import { brighthiveTheme } from "../../../theme/theme";

export const LeftRightContentHolder = styled(Box)`
  background: ${brighthiveTheme.palette.common.white};
  border: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
  border-radius: 6px;
  .rightelement {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .button-style {
    color: ${brighthiveTheme.palette.gray.dark};
    border-radius: 3px;
    border: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
    width: 36px;
    height: 36px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 1px;

    &.active,
    &:hover {
      border-color: ${brighthiveTheme.palette.primary.main};
      background-color: ${brighthiveTheme.palette.primary.lighter};
      color: ${brighthiveTheme.palette.primary.main};
    }

    ,
    svg {
      width: 18px;
      height: 18px;
    }
  }
  .divider {
    border-left: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
    width: 1px;
    height: 36px;
  }
`;
export const CardHolder = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 10px 0;
  .cardholder {
    display: flex;
    cursor: pointer;
    position: relative;
    width: calc(100%);

    @media (min-width: 992px) {
      width: calc(50% - 12px);
    }
    @media (min-width: 1200px) {
      width: calc(33.333% - 12px);
    }
    @media (min-width: 1600px) {
      width: calc(25% - 12px);
    }
  }
`;
