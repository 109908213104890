import { useState, useEffect, useMemo } from "react";
import {
  Box,
  useTheme,
  Grid,
  Paper,
  Typography,
  Chip,
  Dialog,
} from "@mui/material";
import { FormikValues } from "formik";
import { useParams } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import { useMutation, useQuery } from "@apollo/client";
import { uniq } from "lodash";
import {
  AddSchemaToProjectDocument,
  GetSchemasDocument,
  GetTargetSchemasDocument,
  TargetSchema,
} from "../../generated";
import { SearchBar } from "../../common/SearchBar/SearchBar";
import { FilterByTagButton } from "../../common/ActionBarProjects/FilterByTagButton";
import { ButtonDefault, ButtonPrimary } from "../../Sheets/Resource/style";
import { SimpleLoader } from "../../common/Loader/Loader";

export const ModalAddExistingSchema = ({
  show,
  projectId,
  onClose,
}: {
  show: boolean;
  projectId: string;
  onClose?: () => void;
}) => {
  const { palette } = useTheme();
  const [_, setOpen] = useState(true);
  const [linking, setLinking] = useState(false);
  const [selectedSchemas, setSelectedSchemas] = useState<TargetSchema[]>([]);

  const [searchText, setSearchText] = useState("");
  const { workspaceId } = useParams();

  const [tags, setTags] = useState<string[]>([]);
  const [tagValues, setTagValues] = useState<FormikValues>({
    all: true,
  });

  const toggleDialog = () => {
    setOpen(false);
  };
  const { data, loading } = useQuery(GetSchemasDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
    },
  });

  const { data: projectData } = useQuery(GetTargetSchemasDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
      projectFilter: {
        projectId: projectId,
      },
    },
  });

  const [mutate] = useMutation(AddSchemaToProjectDocument, {
    refetchQueries: [GetTargetSchemasDocument],
  });
  useEffect(() => {
    const schemas = data?.workspace?.schemas || ([] as TargetSchema[]);
    const newTags: string[] = [];
    schemas.forEach((item) => {
      item?.tags?.forEach((tag) => {
        newTags.push(tag as string);
      });
    });

    if (newTags?.length > 0) setTags(uniq(newTags));
  }, [data?.workspace?.schemas]);

  const schemaList = useMemo(() => {
    const project = projectData?.workspace?.projects?.[0];
    const schemas = project?.targetSchemas?.map((t) => t.id);
    return (data?.workspace?.schemas?.filter(
      (schema) => schema !== null && !schemas?.includes(schema.id)
    ) || []) as TargetSchema[];
  }, [data?.workspace?.schemas]);

  const filteredSchemaList = useMemo(() => {
    const getSchemasFilteredBySearch = (schemas: TargetSchema[]) => {
      if (searchText === "") return schemas;

      return schemas.filter((asset) => {
        return (
          asset.name.toLowerCase().includes(searchText.toLowerCase()) ||
          asset?.description?.toLowerCase()?.includes(searchText.toLowerCase())
        );
      });
    };

    const getSchemasFilteredByTag = (schemas: TargetSchema[]) => {
      if (tagValues.all === true) {
        return schemas;
      } else {
        return schemas.filter((curr) => {
          if (!curr?.tags?.length) return false;
          let filter = false;
          Object.keys(tagValues).forEach((key) => {
            if (tagValues[key] && curr.tags?.includes(key)) {
              filter = true;
            }
          });
          return filter;
        });
      }
    };
    const schemasFilteredBySearch = getSchemasFilteredBySearch(schemaList);
    return getSchemasFilteredByTag(schemasFilteredBySearch);
  }, [schemaList, searchText, tagValues]);
  const onSelect = (schema: TargetSchema) => {
    if (!selectedSchemas.map((s) => s.id).includes(schema?.id)) {
      setSelectedSchemas([...selectedSchemas, schema]);
      return;
    }
    setSelectedSchemas(selectedSchemas.filter((s) => s.id !== schema.id));
  };
  const onLink = async () => {
    setLinking(true);
    await Promise.all(
      selectedSchemas.map(
        async (schema) =>
          await mutate({
            variables: {
              input: {
                projectId: projectId,
                schemaId: schema.id,
              },
            },
          })
      )
    );
    setLinking(false);
    toggleDialog();
    onClose?.();
  };
  return (
    <Dialog
      open={show}
      maxWidth="md"
      sx={{ " + #filter-owner-form": { zIndex: 999999 } }}
    >
      <Box width="800px" height="auto" p={6} boxSizing="border-box">
        <Typography mb={4} variant="h5" color={palette.common.black}>
          Add Schemas
        </Typography>
        <Box mb={8} sx={{ display: "flex", justifyContent: "space-between" }}>
          <SearchBar
            sx={{ width: "300px", mr: 2 }}
            placeholder="Search Find a Schema"
            onChange={(e) => {
              setSearchText(e.target.value);
              console.log("asd", e.target.value);
            }}
            value={searchText}
          />
          <Box
            sx={{
              "#filter-owner-button": {
                display: "inline-flex",
              },
            }}
          >
            <Typography
              mr={3}
              variant="button"
              color={palette.accent.contrastText}
            >
              Filter:{" "}
            </Typography>
            <FilterByTagButton
              tags={tags}
              text="Tag"
              values={tagValues}
              onSubmit={setTagValues}
            />
          </Box>
        </Box>
        <Box sx={{ height: "calc(100vh - 300px)", overflow: "auto" }}>
          <Box>
            <Grid container>
              {filteredSchemaList?.map((schema: TargetSchema) => {
                return (
                  <Grid
                    key={schema?.id}
                    item
                    xs={12}
                    mb={4}
                    sx={{
                      minWidth: "360px",
                      ".seleceted-item": {
                        borderColor: palette.action.disabledBackground,
                        background: palette.primary.lighter,
                      },
                      ".selected-button": {
                        color: palette.primary.darkest,
                        borderColor: palette.primary.dark,
                        background: palette.primary.lighter,
                      },
                    }}
                  >
                    <Paper
                      variant="outlined"
                      className={
                        selectedSchemas.map((s) => s.id).includes(schema?.id)
                          ? "seleceted-item"
                          : ""
                      }
                      sx={{
                        p: 4,
                        minHeight: "100%",
                        boxSizing: "border-box",
                      }}
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                        alignItems="flex-start"
                        mb={4}
                      >
                        <Box>
                          <Typography
                            variant="subtitle2"
                            color={palette.common.black}
                            mb={2}
                            sx={{
                              fontSize: "16px",
                              fontWeight: 500,
                            }}
                          >
                            {schema?.name}
                          </Typography>
                          {schema?.status && (
                            <Chip
                              color="gray"
                              label={schema?.status}
                              sx={{
                                color: palette.common.black,
                                fontSize: "12px",
                                lineHeight: "16px",
                                fontWeight: "500",
                                background: "none",
                                border: `1px solid ${palette.grayAccent.light}`,
                              }}
                            />
                          )}
                        </Box>
                        <ButtonDefault
                          color="grayAccent"
                          variant="outlined"
                          className={
                            selectedSchemas
                              .map((s) => s.id)
                              .includes(schema?.id)
                              ? "selected-button"
                              : ""
                          }
                          onClick={() => onSelect(schema)}
                          sx={{
                            minWidth: "70px",
                            padding: "8px",
                          }}
                        >
                          {selectedSchemas
                            .map((s) => s.id)
                            .includes(schema?.id) ? (
                            <>
                              <DoneIcon
                                sx={{ fontSize: "16px", marginRight: "8px" }}
                              />
                              Selected
                            </>
                          ) : (
                            <>Select</>
                          )}
                        </ButtonDefault>
                      </Box>
                      <Box>
                        <Typography
                          variant="body3"
                          mb={4}
                          color={palette.gray.darkest}
                          sx={{
                            fontSize: "14px",
                          }}
                        >
                          {schema?.description}
                        </Typography>
                        <Box
                          sx={{
                            ".MuiChip-colorDefault": {
                              margin: "0 0.5rem 1rem 0",
                              borderRadius: "4px",
                              background: palette.settings.darkest,
                              color: palette.common.black,
                              fontWeight: 500,
                              fontSize: "0.875rem",
                              height: "24px",
                            },
                            ".MuiChip-label": {
                              paddingLeft: "0.5rem",
                              paddingRight: "0.5rem",
                            },
                          }}
                        >
                          {schema?.tags &&
                            schema.tags.map((tag) => <Chip label={tag} />)}
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          {loading || linking ? (
            <Grid
              item
              xs={12}
              mt={20}
              display="flex"
              alignContent="center"
              justifyContent="center"
            >
              <SimpleLoader />
            </Grid>
          ) : (
            <></>
          )}
        </Box>
        <Box
          mt={4}
          display="flex"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <ButtonDefault
              color="grayAccent"
              variant="outlined"
              sx={{ mr: 3 }}
              onClick={() => {
                onClose?.();
                toggleDialog();
              }}
            >
              Cancel
            </ButtonDefault>
            <ButtonPrimary color="primary" variant="contained" onClick={onLink}>
              Add with Project
            </ButtonPrimary>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
