import { Box, Button, styled } from "@mui/material";
import { brighthiveTheme } from "../../theme/theme";

export const ButtonLink = styled(Button)`
  padding: 0;
  min-width: 0;
  color: ${brighthiveTheme.palette.accent.darkest};
  background-color: transparent;

  &:hover {
    color: ${brighthiveTheme.palette.accent.darkest};
    background-color: transparent;
    text-decoration: underline;
  }
`;

export const RemoveDialogContainer = styled(Box)(() => ({
  width: "35rem",
  boxSizing: "border-box",
}));

export const RemoveControlsContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
}));
