import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const BrighthiveLogoSvg = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="155"
      height="32"
      fill="none"
      viewBox="0 0 170 32"
      {...props}
    >
      <g clip-path="url(#clip0_1287_3255)">
        <path
          d="M21.0623 28.2703V19.07L10.9362 24.9142V28.2703C10.9364 28.7822 10.7371 29.274 10.3805 29.6413C10.024 30.0087 9.53836 30.2226 9.02667 30.2377H23.0296C22.5078 30.2377 22.0075 30.0304 21.6385 29.6614C21.2696 29.2925 21.0623 28.7921 21.0623 28.2703Z"
          fill="#A1D2AB"
        />
        <path
          d="M10.9363 3.96759V13.1679L21.0623 7.32368V3.96759C21.0623 3.44582 21.2696 2.94543 21.6386 2.57648C22.0075 2.20753 22.5079 2.00024 23.0296 2.00024H8.96887C9.49065 2.00024 9.99105 2.20753 10.36 2.57648C10.7289 2.94543 10.9363 3.44582 10.9363 3.96759Z"
          fill="#FEBE2D"
        />
        <path
          d="M24.0133 5.64559L21.0623 7.32364V19.0699L29.1053 14.4408C29.5517 14.1858 30.0802 14.1157 30.5777 14.2455C31.0752 14.3753 31.5021 14.6946 31.767 15.1352L24.7077 2.98389C24.8417 3.20295 24.9301 3.44679 24.9677 3.70084C25.0053 3.95488 24.9913 4.21388 24.9265 4.46238C24.8617 4.71087 24.7474 4.94375 24.5905 5.14705C24.4336 5.35035 24.2373 5.5199 24.0133 5.64559Z"
          fill="#5BCBF5"
        />
        <path
          d="M31.767 17.0446C31.9322 16.7649 32.0125 16.4433 31.9984 16.1187C32.0111 15.7761 31.9311 15.4362 31.767 15.1351C31.5021 14.6945 31.0752 14.3752 30.5777 14.2454C30.0802 14.1156 29.5517 14.1858 29.1053 14.4407L21.0623 19.0698V28.2701C21.0623 28.7919 21.2696 29.2923 21.6385 29.6613C22.0075 30.0302 22.5079 30.2374 23.0296 30.2374H23.5504L24.0712 30.0059L24.4184 29.7745V29.6588L24.6498 29.3694L30.2626 19.5906L31.7091 17.1603L31.767 17.0446Z"
          fill="#15B9A8"
        />
        <path
          d="M21.0623 3.96747V7.32355L24.0133 5.64551C24.2373 5.51982 24.4336 5.35027 24.5905 5.14697C24.7474 4.94366 24.8617 4.71078 24.9265 4.4623C24.9913 4.2138 25.0053 3.95479 24.9677 3.70076C24.9301 3.44671 24.8417 3.20286 24.7077 2.9838C24.6454 2.82739 24.5461 2.6884 24.4184 2.57875C24.0527 2.2492 23.5797 2.06408 23.0875 2.05798H23.0296C22.5177 2.05776 22.0259 2.25706 21.6586 2.61359C21.2912 2.97012 21.0773 3.45579 21.0623 3.96747Z"
          fill="#89A150"
        />
        <path
          d="M7.98528 26.5922L10.9363 24.9142V13.1679L2.95117 17.7969C2.50475 18.0519 1.97625 18.1221 1.47879 17.9923C0.981321 17.8625 0.554427 17.5431 0.289459 17.1026L7.29092 29.2539C7.03593 28.8075 6.96582 28.279 7.09559 27.7815C7.22537 27.2841 7.54471 26.8572 7.98528 26.5922Z"
          fill="#F6A7C0"
        />
        <path
          d="M10.9361 28.2702V24.9141L7.98508 26.5922C7.54451 26.8571 7.22516 27.284 7.09539 27.7815C6.96562 28.2789 7.03573 28.8075 7.29072 29.2539L7.58004 29.6589H7.63791C7.81417 29.8295 8.0226 29.9635 8.25106 30.0529C8.47953 30.1422 8.72349 30.1854 8.96877 30.1797H9.02662C9.52845 30.1652 10.0057 29.9593 10.3607 29.6043C10.7157 29.2493 10.9215 28.772 10.9361 28.2702Z"
          fill="#A79087"
        />
        <path
          d="M2.95103 17.797L10.9362 13.1679V3.96765C10.9362 3.44589 10.7289 2.94549 10.3599 2.57654C9.991 2.20759 9.49059 2.00031 8.96882 2.00031H8.44804C8.27825 2.04152 8.11998 2.12065 7.98513 2.23176L7.63796 2.4632C7.5801 2.52107 7.5801 2.52108 7.5801 2.57894L7.34864 2.86826H7.29078L1.67804 12.5893L0.23145 15.0195C0.0938706 15.3098 0.0152066 15.6245 0 15.9454C0.00779653 16.2931 0.107626 16.6325 0.289317 16.929C0.405848 17.1619 0.567456 17.3694 0.76478 17.5394C0.962105 17.7094 1.19121 17.8384 1.43882 17.9191C1.68642 18 1.94758 18.0307 2.20717 18.0097C2.46676 17.9888 2.7196 17.9164 2.95103 17.797Z"
          fill="#F5843C"
        />
        <path
          d="M41.6566 9.86804H46.8951C47.5201 9.86996 48.1413 9.96702 48.7372 10.1559C49.2347 10.3189 49.6878 10.5947 50.0612 10.9618C50.3229 11.2151 50.5295 11.5196 50.6682 11.8564C50.8069 12.1932 50.8746 12.5549 50.8671 12.919C50.8708 13.2907 50.8124 13.6604 50.6944 14.0128C50.5755 14.2998 50.4207 14.5706 50.2339 14.8187L49.6006 15.3944C49.3825 15.5323 49.1511 15.648 48.9098 15.7398L49.946 16.2003C50.2492 16.3385 50.5228 16.534 50.7519 16.7759C50.9758 17.0202 51.1691 17.2909 51.3276 17.5819C51.4645 17.9511 51.5422 18.3397 51.5579 18.7332C51.5729 19.2351 51.4536 19.7319 51.2125 20.1723C50.9858 20.5815 50.6712 20.9354 50.2914 21.2085C49.8822 21.5296 49.4122 21.7645 48.9098 21.8993C48.3061 22.0433 47.6884 22.1206 47.0678 22.1295H41.6566V9.86804ZM46.6648 15.2792C47.3726 15.307 48.073 15.1269 48.6796 14.7611C48.9332 14.5767 49.1388 14.334 49.2791 14.0535C49.4194 13.773 49.4901 13.4629 49.4855 13.1493C49.4997 12.8708 49.4439 12.5932 49.3233 12.3418C49.2026 12.0904 49.0209 11.8732 48.7947 11.7101C48.1974 11.3177 47.4945 11.1169 46.7799 11.1345H43.0382V15.3368L46.6648 15.2792ZM47.1253 20.8631C47.9161 20.9177 48.7034 20.7158 49.3704 20.2874C49.624 20.103 49.8296 19.8603 49.9699 19.5798C50.1101 19.2993 50.1809 18.9892 50.1763 18.6756C50.1826 18.3711 50.1055 18.0707 49.9533 17.8068C49.8011 17.543 49.5796 17.3259 49.3128 17.1789C48.5892 16.7333 47.7418 16.5316 46.8951 16.6032H43.0382V20.9207L47.1253 20.8631Z"
          fill="#333333"
        />
        <path
          d="M54.8397 9.86806H60.1357C60.8177 9.86648 61.4963 9.96342 62.1505 10.1559C62.6967 10.3439 63.1901 10.6597 63.5896 11.0769C63.9222 11.3658 64.1625 11.7462 64.2804 12.1707C64.4643 12.5882 64.5622 13.0385 64.5683 13.4947C64.5684 13.9701 64.4704 14.4405 64.2804 14.8763C64.1619 15.2843 63.9207 15.6461 63.5896 15.9125C63.2793 16.2072 62.9308 16.4589 62.5535 16.6608L61.2295 17.0638L64.9712 22.072H63.3018L59.7328 17.294H56.2213V22.072H54.8397V9.86806ZM60.0206 16.0852C60.4488 16.0883 60.8753 16.0301 61.287 15.9125L62.2656 15.452C62.5586 15.2441 62.7959 14.9673 62.9565 14.6461C63.1172 14.3248 63.1962 13.9689 63.1867 13.6099C63.1982 13.2647 63.1257 12.9219 62.9752 12.611C62.8248 12.3001 62.601 12.0304 62.3232 11.8253C61.6437 11.3722 60.8362 11.1502 60.0206 11.1921H56.2213V16.1427L60.0206 16.0852Z"
          fill="#333333"
        />
        <path
          d="M68.0217 9.86804H69.4033V22.1295H68.0217V9.86804Z"
          fill="#333333"
        />
        <path
          d="M79.1324 22.3023C78.248 22.3093 77.3698 22.1532 76.542 21.8418C75.8039 21.5165 75.1384 21.0467 74.5847 20.4602C74.0268 19.9064 73.5949 19.2389 73.3183 18.503C73.0401 17.7079 72.9037 16.87 72.9153 16.0277C72.8997 15.1986 73.0566 14.3753 73.3759 13.6099C73.6532 12.8705 74.0628 12.1878 74.5847 11.5951C75.1485 11.0204 75.8116 10.5523 76.542 10.2135C77.339 9.8602 78.2031 9.68346 79.0749 9.69546L80.4564 9.81058L81.6653 10.0984L82.7015 10.6165L83.6225 11.3073L82.7015 12.4011L82.0107 11.8254L81.1472 11.3649L80.1686 11.077L79.0173 10.9619C78.364 10.9724 77.7189 11.1093 77.1176 11.3649C76.5696 11.6295 76.0802 12.0014 75.6785 12.4586C75.2344 12.9148 74.8998 13.466 74.6999 14.0705C74.4686 14.697 74.3516 15.3599 74.3545 16.0277C74.342 16.715 74.4591 17.3986 74.6999 18.0425C74.8855 18.6535 75.222 19.2078 75.6785 19.6543C76.0856 20.1303 76.5981 20.5048 77.1752 20.7481C77.8153 21.0085 78.499 21.1452 79.19 21.151C79.8646 21.1674 80.534 21.0296 81.1472 20.7481C81.7127 20.5552 82.239 20.2628 82.7015 19.8846V16.8336H79.0173V15.6247H84.0255V20.5178C83.407 21.0633 82.708 21.5099 81.9531 21.8418C81.0512 22.1751 80.0935 22.3315 79.1324 22.3023Z"
          fill="#333333"
        />
        <path
          d="M87.7671 9.86792H89.1487V15.2791H96.2292V9.86792H97.6108V22.1294H96.2292V16.6031H89.1487V22.1294H87.7671V9.86792Z"
          fill="#333333"
        />
        <path
          d="M104.517 11.1344H100.43V9.86792H110.044V11.1344H105.899V22.1294H104.517V11.1344Z"
          fill="#333333"
        />
        <path
          d="M112.865 9.86792H114.246V15.2791H121.327V9.86792H122.708V22.1294H121.327V16.6031H114.246V22.1294H112.865V9.86792Z"
          fill="#333333"
        />
        <path
          d="M126.796 9.86792H128.177V22.1294H126.796V9.86792Z"
          fill="#333333"
        />
        <path
          d="M130.998 9.86792H132.553L136.87 20.4024L141.245 9.86792H142.742L137.503 22.187H136.237L130.998 9.86792Z"
          fill="#333333"
        />
        <path
          d="M145.504 9.86792H154.369V11.0768H146.886V15.3367H153.563V16.5456H146.886V20.863H154.427V22.1294H145.504V9.86792Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_1287_3255">
          <rect
            width="154.427"
            height="32"
            fill="white"
            transform="translate(0 0.000305176)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
