import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const TablerListSearchViiIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.5 18.9L21 21.4M4 6.40002H20M4 12.4H8M4 18.4H8M11 15.4C11 16.4609 11.4214 17.4783 12.1716 18.2285C12.9217 18.9786 13.9391 19.4 15 19.4C16.0609 19.4 17.0783 18.9786 17.8284 18.2285C18.5786 17.4783 19 16.4609 19 15.4C19 14.3392 18.5786 13.3217 17.8284 12.5716C17.0783 11.8215 16.0609 11.4 15 11.4C13.9391 11.4 12.9217 11.8215 12.1716 12.5716C11.4214 13.3217 11 14.3392 11 15.4Z" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};
