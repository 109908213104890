import React, { useState, useMemo, useEffect } from "react";
import { Box, Dialog, useTheme, Grid, Paper } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { Button } from "../../common/Button/Button";
import { SearchBar } from "../../common/SearchBar/SearchBar";
import { FilterByTagButton } from "../../common/ActionBarProjects/FilterByTagButton";
import { FormikValues } from "formik";
import { Chip } from "../../common/Chip/Chip";
import PersonIcon from "@mui/icons-material/Person";
import { DOCLink } from "../../common/Link/Link";
import DoneIcon from "@mui/icons-material/Done";
import { useQuery } from "@apollo/client";
import { GetTargetSchemasDocument, TargetSchema } from "../../generated";
import { useNavigate, useParams } from "react-router-dom";
import { SimpleLoader } from "../../common/Loader/Loader";
import { uniq } from "lodash";
import { ButtonDefault, ButtonPrimary } from "./style";

export const ApplySchemaTemplateModal = ({
  selectedTemplate,
  setSchema,
  setToggleSchemaTemplate,
}: {
  selectedTemplate: TargetSchema;
  setSchema: any;
  setToggleSchemaTemplate: any;
}) => {
  const { palette } = useTheme();
  const [open, setOpen] = useState(true);
  const [selectedTemplateValue, setSelectedTemplateValue] =
    useState<any>(selectedTemplate);
  const [searchText, setSearchText] = useState("");
  const { workspaceId, projectId } = useParams();
  const categories = ["Privacy", "Use", "Access", "Quality"];
  const [categoryValues, setCategoryValues] = useState<FormikValues>({
    Privacy: false,
    Use: false,
    Access: false,
    Quality: false,
  });

  const [tags, setTags] = useState<string[]>([]);
  const [tagValues, setTagValues] = useState<FormikValues>({
    all: true,
  });

  const toggleDialog = () => {
    setOpen(false);
  };
  const { data, loading } = useQuery(GetTargetSchemasDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
      projectFilter: {
        projectId: projectId || "",
      },
    },
  });

  useEffect(() => {
    const schemas = data?.workspace?.projects?.[0]?.targetSchemas || [];
    const newTags: string[] = [];
    schemas.forEach((item) => {
      item?.tags.forEach((tag) => {
        newTags.push(tag);
      });
    });

    console.log(newTags, "new tags");
    if (newTags?.length > 0) setTags(uniq(newTags));
  }, [data?.workspace?.projects]);

  const schemasList = useMemo(() => {
    return (
      data?.workspace?.projects?.[0]?.targetSchemas?.filter(
        (asset) => asset !== null
      ) || []
    );
  }, [data?.workspace?.projects]);

  const filteredSchemasList = useMemo(() => {
    const getSchemasFilteredBySearch = (schemas: Array<TargetSchema>) => {
      if (searchText === "") return schemas;

      return schemas.filter((asset) => {
        return (
          asset.name.toLowerCase().includes(searchText.toLowerCase()) ||
          asset?.description?.toLowerCase()?.includes(searchText.toLowerCase())
        );
      });
    };

    // const getSchemasFilteredByCategory = (schemas: Array<TargetSchema>) => {
    //   if (categoryValues.all === true) {
    //     return schemas;
    //   } else {
    //     return schemas.filter((curr) => {
    //       if (!curr?.owner) return false;
    //       return categoryValues[curr?.owner?.name] === true;
    //     });
    //   }
    // };

    const getSchemasFilteredByTag = (schemas: Array<TargetSchema>) => {
      if (tagValues.all === true) {
        return schemas;
      } else {
        return schemas.filter((curr) => {
          if (!curr?.tags.length) return false;
          let filter = false;
          Object.keys(tagValues).forEach((key) => {
            if (tagValues[key] && curr.tags.includes(key)) {
              filter = true;
            }
          });
          return filter;
        });
      }
    };
    const schemasFilteredBySearch = getSchemasFilteredBySearch(schemasList);

    // const projectsFilteredByCategory =
    //   getProjectsFilteredByCategory(projectsFilteredBySearch);
    const projectsFilteredByEverything = getSchemasFilteredByTag(
      schemasFilteredBySearch
    );
    return projectsFilteredByEverything;
  }, [schemasList, searchText, tagValues]);

  return (
    <Dialog
      maxWidth="md"
      open={open}
      sx={{ " + #filter-owner-form": { zIndex: 999999 } }}
    >
      <Box width="800px" height="auto" p={6} boxSizing="border-box">
        <Typography mb={4} variant="h5" color={palette.common.black}>
          Apply a schema template
        </Typography>
        <Box mb={8} sx={{ display: "flex", justifyContent: "space-between" }}>
          <SearchBar
            sx={{ width: "300px", mr: 2 }}
            placeholder="Search Find a template"
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            value={searchText}
          />
          <Box
            sx={{
              "#filter-owner-button": {
                display: "inline-flex",
              },
            }}
          >
            <Typography
              mr={3}
              variant="button"
              color={palette.accent.contrastText}
            >
              Filter:{" "}
            </Typography>
            {/* <FilterByTagButton
              tags={categories}
              text="Category"
              values={categoryValues}
              onSubmit={setCategoryValues}
              sx={{ mr: 2 }}
            /> */}
            <FilterByTagButton
              tags={tags}
              text="Tag"
              values={tagValues}
              onSubmit={setTagValues}
            />
          </Box>
        </Box>

        <Box sx={{ height: "calc(100vh - 300px)", overflow: "auto" }}>
          <Box>
            <Grid container>
              {filteredSchemasList?.map((asset) => {
                return (
                  <Grid
                    key={asset?.id}
                    item
                    xs={12}
                    mb={4}
                    sx={{
                      minWidth: "360px",
                      ".seleceted-item": {
                        borderColor: palette.action.disabledBackground,
                        background: palette.primary.lighter,
                      },
                      ".selected-button": {
                        color: palette.primary.darkest,
                        borderColor: palette.primary.dark,
                        background: palette.primary.lighter,
                      },
                    }}
                  >
                    <Paper
                      variant="outlined"
                      className={
                        asset?.id === selectedTemplateValue?.id
                          ? "seleceted-item"
                          : ""
                      }
                      sx={{
                        p: 4,
                        minHeight: "100%",
                        boxSizing: "border-box",
                      }}
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                        alignItems="flex-start"
                        mb={4}
                      >
                        <Box>
                          <Typography
                            variant="subtitle2"
                            color={palette.common.black}
                            mb={2}
                            sx={{
                              fontSize: "16px",
                              fontWeight: 500,
                            }}
                          >
                            {asset?.name}
                          </Typography>
                          {asset?.piiTypes?.[0] && (
                            <Chip
                              icon={
                                <PersonIcon
                                  sx={{
                                    fontSize: "1rem",
                                    color: palette.common.black + "!important",
                                  }}
                                />
                              }
                              color="gray"
                              label="Contains PII"
                              sx={{
                                color: palette.common.black,
                                fontSize: "12px",
                                lineHeight: "16px",
                                fontWeight: "500",
                                background: "none",
                                border: `1px solid ${palette.grayAccent.light}`,
                              }}
                            />
                          )}
                        </Box>
                        <ButtonDefault
                          color="grayAccent"
                          variant="outlined"
                          className={
                            asset?.id === selectedTemplateValue?.id
                              ? "selected-button"
                              : ""
                          }
                          onClick={() => {
                            setSelectedTemplateValue(asset);
                            // setSelectedTemplate(true)
                          }}
                          sx={{
                            minWidth: "70px",
                            padding: "8px",
                          }}
                        >
                          {asset?.id === selectedTemplateValue?.id ? (
                            <>
                              <DoneIcon
                                sx={{ fontSize: "16px", marginRight: "8px" }}
                              />
                              Selected
                            </>
                          ) : (
                            <>Select</>
                          )}
                        </ButtonDefault>
                      </Box>
                      <Box>
                        <Typography
                          variant="body3"
                          mb={4}
                          color={palette.gray.darkest}
                          sx={{
                            fontSize: "14px",
                          }}
                        >
                          {asset?.description}
                        </Typography>
                        <Box
                          sx={{
                            ".MuiChip-colorDefault": {
                              margin: "0 0.5rem 1rem 0",
                              borderRadius: "4px",
                              background: palette.settings.darkest,
                              color: palette.common.black,
                              fontWeight: 500,
                              fontSize: "0.875rem",
                              height: "24px",
                            },
                            ".MuiChip-label": {
                              paddingLeft: "0.5rem",
                              paddingRight: "0.5rem",
                            },
                          }}
                        >
                          {asset?.tags &&
                            asset.tags.map((tag) => <Chip label={tag} />)}
                        </Box>
                        {/* <DOCLink sx={{ mb: 2 }} name="Preview schema" href="#" /> */}
                      </Box>
                    </Paper>
                  </Grid>
                );
              })}
              {/* <Grid
                item
                xs={12}
                mb={4}
                sx={{
                  minWidth: "360px",
                  ".seleceted-item": {
                    borderColor: palette.action.disabledBackground,
                    background: palette.primary.lighter,
                  },
                  ".selected-button": {
                    color: palette.primary.darkest,
                    borderColor: palette.primary.dark,
                    background: palette.primary.lighter,
                  },
                }}
              >
                <Paper
                  variant="outlined"
                  className={selectedTemplate ? "seleceted-item" : ""}
                  sx={{
                    p: 4,
                    minHeight: "100%",
                    boxSizing: "border-box",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    alignItems="flex-start"
                    mb={4}
                  >
                    <Box>
                      <Typography
                        variant="subtitle2"
                        color={palette.common.black}
                        mb={2}
                        sx={{
                          fontSize: "16px",
                          fontWeight: 500,
                        }}
                      >
                        Dual Enrollment Registered Students
                      </Typography>
                      <Chip
                        icon={
                          <PersonIcon
                            sx={{
                              fontSize: "1rem",
                              color: palette.common.black + "!important",
                            }}
                          />
                        }
                        color="gray"
                        label="Contains PII"
                        sx={{
                          color: palette.common.black,
                          fontSize: "12px",
                          lineHeight: "16px",
                          fontWeight: "500",
                          background: "none",
                          border: `1px solid ${palette.grayAccent.light}`,
                        }}
                      />
                    </Box>
                    <Button
                      color="grayAccent"
                      variant="outlined"
                      className={selectedTemplate ? "selected-button" : ""}
                      onClick={() => {}}
                      sx={{
                        minWidth: "70px",
                        padding: "8px",
                      }}
                    >
                      {selectedTemplate ? (
                        <>
                          <DoneIcon
                            sx={{ fontSize: "16px", marginRight: "8px" }}
                          />
                          Selected
                        </>
                      ) : (
                        <>Select</>
                      )}
                    </Button>
                  </Box>
                  <Box>
                    <Typography
                      variant="body3"
                      mb={4}
                      color={palette.gray.darkest}
                      sx={{
                        fontSize: "14px",
                      }}
                    >
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem
                      aperiam, eaque ipsa quae ab illo inventore. Sed ut
                      perspiciatis unde omnis iste natus error sit voluptatem
                      accusantium doloremque laudantium, totam rem aperiam,
                      eaque ipsa quae ab illo inventore.
                    </Typography>
                    <Box
                      sx={{
                        ".MuiChip-colorDefault": {
                          margin: "0 0.5rem 1rem 0",
                          borderRadius: "4px",
                          background: palette.settings.darkest,
                          color: palette.common.black,
                          fontWeight: 500,
                          fontSize: "0.875rem",
                          height: "24px",
                        },
                        ".MuiChip-label": {
                          paddingLeft: "0.5rem",
                          paddingRight: "0.5rem",
                        },
                      }}
                    >
                      {tags && tags.map((tag) => <Chip label={tag} />)}
                    </Box>
                    <DOCLink sx={{ mb: 2 }} name="Preview schema" href="#" />
                  </Box>
                </Paper>
              </Grid> */}
            </Grid>
          </Box>
          {loading ? (
            <Grid
              item
              xs={12}
              mt={20}
              display="flex"
              alignContent="center"
              justifyContent="center"
            >
              <SimpleLoader />
            </Grid>
          ) : (
            <></>
          )}
        </Box>

        <Box mt={4} display="flex" alignItems={"center"}>
          <ButtonDefault
            color="grayAccent"
            variant="outlined"
            onClick={() => {
              setToggleSchemaTemplate(false);
              toggleDialog();
            }}
            sx={{ ml: "auto", mr: 3 }}
          >
            Cancel
          </ButtonDefault>
          <ButtonPrimary
            color="primary"
            variant="contained"
            onClick={(e) => {
              toggleDialog();
              setToggleSchemaTemplate(false);
              setSchema(selectedTemplateValue);
            }}
          >
            Apply template
          </ButtonPrimary>
        </Box>
      </Box>
    </Dialog>
  );
};
