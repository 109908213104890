import React, { useState } from "react";
import { Button } from "../../common/Button/Button";
import EditIcon from "@mui/icons-material/Edit";
import { FetchResult, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  UpdateTargetSchemaDocument,
  GetTargetSchemasDocument,
  TargetSchema,
  UpdateTargetSchemaMutation,
  UpdateWorkspaceSchemaMutation,
} from "../../generated";
import { ModalTargetSchemaField } from "./ModalTargetSchemaField";
import { CreateNewSchema } from "../../Sheets/Schema/CreateNewSchema";
import RightSidePanel from "../../common/RightSidePanel";
import { toast } from "react-toastify";
import { schemaJsonParser } from "../../helpers";

export interface EditFieldCellRendererProps {
  id: string;
  targetSchema?: TargetSchema;
  update: (
    parse: any
  ) => Promise<
    FetchResult<UpdateTargetSchemaMutation | UpdateWorkspaceSchemaMutation>
  >;
  data: any;
}

export const EditFieldCellRenderer = ({
  id,
  targetSchema,
  update,
  data,
}: EditFieldCellRendererProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const [mutateUpdate] = useMutation(UpdateTargetSchemaDocument, {
    refetchQueries: [GetTargetSchemasDocument],
    onCompleted: () => {
      closeDialog();
    },
  });

  const { projectId, workspaceId, schemaId } = useParams();

  return (
    <>
      <Button
        onClick={openDialog}
        color="grayAccent"
        variant="text"
        startIcon={<EditIcon />}
      >
        Edit
      </Button>
      {/* <ModalTargetSchemaField
        show={dialogOpen}
        editMode
        closeDialog={closeDialog}
        initialValues={data}
        submit={(v: any) => {
          const parse = JSON.parse(targetSchema?.jsonSchema || "{}");

          const properties = parse?.properties || {};

          properties[v.name] = {
            description: v.description,
            type: v.type,
          };

          if (v.minLength) {
            properties[v.name]["minLength"] = v.minLength;
          }

          if (v.maxLength) {
            properties[v.name]["maxLength"] = v.maxLength;
          }

          parse.properties = properties;

          const required: string[] = parse?.required || [];
          if (!required.includes(v.name) && v.required) {
            required.push(v.name);
          }

          if (required.length > 0) {
            parse.required = required;
          }

          return mutateUpdate({
            variables: {
              input: {
                targetSchemaId: schemaId || "",
                workspaceId: workspaceId || "",
                projectId: projectId || "",
                name: targetSchema?.name,
                description: targetSchema?.description,
                jsonSchema: JSON.stringify(parse),
              },
            },
          });
        }}
      /> */}
      <RightSidePanel
        isOpen={Boolean(dialogOpen)}
        onToggle={() => closeDialog()}
      >
        <CreateNewSchema
          closeDialog={() => closeDialog()}
          initialValues={data}
          targetSchema={targetSchema}
          editMode={true}
          submit={(v: any) => {
            const parse = schemaJsonParser(targetSchema, data, v);

            return update(parse)
              .then(() => {
                toast.success(`Schema field ${v.name} updated`);
                closeDialog();
              })
              .catch((err) => {
                closeDialog();
              });
          }}
        />
      </RightSidePanel>
    </>
  );
};
