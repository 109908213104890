import { Box, TableContainer, useTheme } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { BasicCard } from "../../common/BasicCard/BasicCard";
import { METADATA_SOURCES } from "../../mock-data/mock-data";
import { TableData } from "../../common/TableData/TableData";
import { StatusCell } from "../CellRenderer/StatusCell";
import { useState } from "react";
import { StorageDetail } from "./StorageDetail";
import { IconCell } from "../CellRenderer/IconCell";
import { LocationCell } from "../CellRenderer/LocationCell";
import { ActionBarStorage } from "./ActionBarStorage";
import { palette } from "@mui/system";
import { MetadataHolder } from "./style";

export const Storage = () => {
  const [detail, setDetail] = useState(null);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      cellRenderer: (item: any) => {
        return (
          <Box display="flex" alignItems="center">
            <IconCell value={item.cloudProvider} />
            <Typography fontWeight="bold" ml={3} variant="body3">
              {item.name}
            </Typography>
          </Box>
        );
      },
      headerRenderer: (item: any) => {
        return (
          <Box component="span" ml={9}>
            {item.headerName}
          </Box>
        );
      },
    },
    {
      field: "location",
      headerName: "Location",
      cellRenderer: (item: any) => {
        return (
          <Box display="flex" alignItems="center">
            <LocationCell value={item.location} />
            <Typography ml={2} variant="body3">
              {item.location}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "cloudProvider",
      headerName: "Cloud provider",
    },
    {
      field: "service",
      headerName: "Service",
    },
    {
      field: "access",
      headerName: "Access",
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: (item: any) => {
        return <StatusCell value={item.status} />;
      },
    },
  ];

  const rowData = METADATA_SOURCES;
  const { palette } = useTheme();

  return (
    <>
      <MetadataHolder display="grid" className="metadata-holder" gap="1rem">
        <Box style={{ overflowX: "auto" }}>
          <TableContainer component={BasicCard} style={{ minWidth: "770px" }}>
            <ActionBarStorage />
            <TableData
              columns={columns}
              rowData={rowData}
              onRowClicked={(item: any) => {
                setDetail(item);
              }}
            />
          </TableContainer>
        </Box>
        <Box>
          <BasicCard sx={{ padding: "1.6rem" }}>
            <Typography variant="subtitle2">
              Supercharge data discovery with metadata from your sources
            </Typography>
            <Typography variant="body3" mt={3}>
              Connect to metadata sources to populate and sync data catalogs for
              your organization and workspaces.
            </Typography>
            <Typography variant="body3" mt={2}>
              Brighthive uses{" "}
              <Box
                component="span"
                fontWeight="bold"
                color={palette.primary.dark}
              >
                OpenMetadata
              </Box>{" "}
              to allow members of your organization and workspaces to discover
              available data assets – no raw data access required.
            </Typography>
          </BasicCard>
        </Box>
      </MetadataHolder>
      <StorageDetail open={Boolean(detail)} onClose={() => setDetail(null)} />
    </>
  );
};
