import React, { useState } from "react";
import { Box, Dialog, useTheme } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { Button } from "../../common/Button/Button";
import { Alert } from "../../common/Alert";
import { SnackBar } from "../../common/SnakBar/SnakBar";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { RemoveDataAssetFromInputDataAssetGroupDocument } from "../../generated";

export const RemoveConfirmationModal = ({
  inputDataAssetId,
  inputDataAssetGroupId,
  inputDataAssetName,
}: {
  inputDataAssetId: string;
  inputDataAssetGroupId: string;
  inputDataAssetName: string;
}) => {
  const { workspaceId } = useParams();
  const { palette } = useTheme();
  const [open, setOpen] = React.useState(true);
  const [showSnakBar, setShowSnakBar] = React.useState(false);
  const [showSnakBarToast, setShowSnakBarToast] = useState<boolean>(false);
  const [del] = useMutation(RemoveDataAssetFromInputDataAssetGroupDocument);

  const handleCloseSnakBar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    setShowSnakBarToast(false);
  };
  const toggleDialog = () => {
    setOpen(false);
  };

  const toggleSnakBar = () => {
    setShowSnakBar(true);
  };
  const onDelete = async () => {
    await del({
      variables: {
        input: {
          dataAssetId: inputDataAssetId || "",
          inputDataAssetGroupId: inputDataAssetGroupId || "",
          workspaceId: workspaceId || "",
        },
      },
    });
  };
  return (
    <>
      {showSnakBarToast && (
        <SnackBar
          open={showSnakBarToast}
          message="Input data asset deleted from project"
          onClose={handleCloseSnakBar}
        />
      )}
      <Dialog maxWidth="sm" open={open}>
        <Box width="560px" height="auto" p={6} boxSizing="border-box">
          <Typography mb={4} variant="h5" color={palette.common.black}>
            Remove input data asset?
          </Typography>
          <Box mb={8}>
            {showSnakBar && (
              <Alert
                severity="warning"
                sx={{
                  marginBottom: "16px",
                }}
              >
                This action may cause validation, data lineage, or data quality
                issues.
              </Alert>
            )}
            <Typography
              variant="body3"
              color={palette.gray.dark}
              sx={{ mb: 4 }}
            >
              Are you sure you want to remove the following input data asset
              from the project?
            </Typography>
            <Typography
              variant="body3"
              color={palette.gray.dark}
              sx={{ mb: 2 }}
            >
              <ul>
                <li>{inputDataAssetName}</li>
              </ul>
            </Typography>
          </Box>
          <Box mt={4} display="flex" alignItems={"center"}>
            <Button
              color="grayAccent"
              variant="outlined"
              onClick={toggleDialog}
              sx={{ ml: "auto", mr: 3 }}
            >
              Cancel
            </Button>
            {showSnakBar ? (
              <Button
                color="error"
                variant="contained"
                onClick={async (e) => {
                  await onDelete();
                  setShowSnakBar(false);
                  setOpen(false);
                  setShowSnakBarToast(true);
                  window.location.reload();
                }}
              >
                I understand - remove asset
              </Button>
            ) : (
              <Button color="error" variant="contained" onClick={toggleSnakBar}>
                Remove asset
              </Button>
            )}
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
