import React from "react";
import { AlertProps, styled } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import merge from "lodash/merge";

export const ModifiedAlert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  }
);

export const Alert = styled(ModifiedAlert)(({ theme, variant, severity }) => {
  const { palette } = theme;

  let styles = {
    fontSize: "14px",
    lineHeight: "20px",
    boxShadow: "none",
  };

  // warning styling
  if (severity === "warning") {
    styles = merge(styles, {
      color: palette.warning.darkest,
      background: palette.warning.lighter,
    });
  }

  // warning styling
  if (severity === "error") {
    styles = merge(styles, {
      color: palette.error.darkest,
      background: palette.error.lighter,
    });
  }

  return styles;
});
