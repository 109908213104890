import React from "react";
import {
  Box,
  Divider,
  FormControl,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";

import { Typography } from "../../common/Typography/Typography";
import { BasicCard } from "../../common/BasicCard/BasicCard";
import { Label } from "../../common/Label/Label";
import { Tag } from "../../common/Tag/Tag";
import { Button } from "../../common/Button/Button";
import { AssetPolicyForm } from "../../common/AssetPolicyForm/AssetPolicyForm";
import { Radio } from "../../common/Radio/Radio";
import { Formik } from "formik";
import {
  AccessStatus,
  GetADataAssetQuery,
  GetDataAssetManagerDocument,
  UpdateDataAssetDocument,
  UpdateDataAssetInput,
} from "../../generated";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import AccessStatusChip from "../AccessStatusChip/AccessStatusChip";
import { AdditionalDetailsWrapper, AdditionalTopBox, ButtonDefault, ButtonPrimary, FieldLabel, FieldWrapper, ModalBtnsWrapper, PageDivider, TextParagraph } from "../style";
import { PlusIcon } from "../../common/Icons/PlusIcon";

export const AssetInformationEdit = ({
  data,
  onSave,
  onCancel,
  ...rest
}: {
  data: GetADataAssetQuery;
  onSave: () => any;

  onCancel: () => any;
}) => {
  const { palette } = useTheme();
  const { workspaceId } = useParams();
  const [tag, setTag] = React.useState("");
  const [saveAsset, { loading }] = useMutation(UpdateDataAssetDocument);
  const dataAsset = data.workspace?.dataAssets?.[0];
  const { data: managerData } = useQuery(GetDataAssetManagerDocument, {
    variables: {
      workspaceId: workspaceId || "",
      dataAssetId: dataAsset?.id || "",
    },
  });

  const tableProperties = [
    {
      key: "recordCount",
      value: dataAsset?.projectCount || "500",
    },
    {
      key: "avgRecordSize",
      value: dataAsset?.assetSize || "0",
    },
    {
      key: "compressionType",
      value: dataAsset?.connectionType || "none", 
    },
  ];

  if (!dataAsset) {
    return null;
  }

  return (
    <Formik
      initialValues={
        {
          dataAssetId: dataAsset.id,
          workspaceId: workspaceId || "",
          accessStatus: dataAsset.accessStatus,
          description: dataAsset.description,
          //@ts-ignore
          managerIds: dataAsset.managers?.[0]?.id,
          assetPolicies: {
            update: dataAsset.assetPolicies?.map((policy) => ({
              description: policy?.description,
              href: policy?.href,
              id: policy?.id,
              name: policy?.name,
            })),
            delete: [],
            create: [],
          },
          name: dataAsset.name,
          ownerId: dataAsset.owner?.id,
          refreshRate: dataAsset.refreshRate,
          sensitivity: dataAsset.sensitivity,
          source: dataAsset.source,
          tags: {
            disconnect: [],
            connectOrCreate: dataAsset.tags,
          },
        } as UpdateDataAssetInput
      }
      onSubmit={async (data) => {
        console.log(data);
        await saveAsset({
          variables: {
            updatedDataAsset: data,
          },
        });
        onSave();
      }}
    >
      {({ handleSubmit, handleChange, values, setFieldValue }) => {
        const onPolicyModify = (index: number, modifiedItem: any) => {
          const isNew =
            index + 1 > (values?.assetPolicies?.update?.length || 0);
          const createdList = values?.assetPolicies?.create || [];
          const updatedList = values?.assetPolicies?.update || [];
          if (isNew) {
            createdList[index - updatedList.length] = modifiedItem;
          } else {
            updatedList[index] = modifiedItem;
          }
          // console.log(index, updatedList, createdList, isNew);
          setFieldValue("assetPolicies", {
            delete: [...(values?.assetPolicies?.delete || [])],
            create: [...createdList],
            update: [...updatedList],
          });
        };
        const onDeletePolicy = (policy: any) =>
          setFieldValue("assetPolicies", {
            create: [
              ...(values?.assetPolicies?.create.filter(
                (assetPolicy) => assetPolicy.href !== policy.href
              ) || []),
            ],
            delete: [
              ...(values?.assetPolicies?.delete || []),
              ...(policy.id ? [policy.id] : []),
            ],
            update: [
              ...(values?.assetPolicies?.update.filter(
                (assetPolicy) => assetPolicy.id !== policy.id
              ) || []),
            ],
          });
        const onNewPolicy = () =>
          setFieldValue("assetPolicies", {
            delete: [...(values?.assetPolicies?.delete || [])],
            update: [...(values.assetPolicies?.update || [])],
            create: [
              ...(values?.assetPolicies?.create || []),
              {
                href: "",
                name: "",
                description: "",
              },
            ],
          });
        return (
          <AdditionalDetailsWrapper className="AssetInformationEdit" {...rest}>
            <AdditionalTopBox className="flex-box">
              <Typography variant={"h6"}>Asset information</Typography>
              <ModalBtnsWrapper>
                <ButtonDefault
                  onClick={onCancel}
                  size={"small"}
                  variant="outlined"
                  color={"grayAccent"}
                >
                  Cancel
                </ButtonDefault>
                <ButtonPrimary
                  onClick={() => handleSubmit()}
                  size={"small"}
                  variant="contained"
                  disabled={loading}
                >
                  Save Changes
                </ButtonPrimary>
              </ModalBtnsWrapper>
            </AdditionalTopBox>
            <PageDivider className="my-0" />
            <AdditionalTopBox className="p-same">
              <FieldWrapper>
                <FieldLabel value="Data asset name" />
                <TextField
                  hiddenLabel
                  fullWidth
                  onChange={handleChange("name")}
                  defaultValue={values.name}
                  inputProps={{
                    sx: { fontSize: "1rem", padding: ".375rem .75rem" },
                  }}
                  size="small"
                />
              </FieldWrapper>
              <FieldWrapper>
                <FieldLabel value="Description" />
                <TextField
                  hiddenLabel
                  fullWidth
                  onChange={handleChange("description")}
                  defaultValue={values.description}
                  multiline
                  inputProps={{
                    sx: {
                      fontSize: "1rem",
                      minHeight: "70px",
                      maxHeight: "200px",
                    },
                  }}
                  size="medium"
                />
              </FieldWrapper>
              <FieldWrapper>
                <FieldLabel value="Tags" info />
                <Box width="100%">
                  <TextField
                    hiddenLabel
                    fullWidth
                    placeholder="Add tags here"
                    value={tag}
                    onChange={(e) => setTag(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        setFieldValue("tags.connectOrCreate", [
                          ...(values.tags?.connectOrCreate || []),
                          tag,
                        ]);
                        setTag("");
                      }
                    }}
                    inputProps={{
                      sx: { fontSize: "1rem", padding: ".375rem .75rem" },
                    }}
                    size="small"
                  />
                  <Box display={"flex"} pt={"1.125rem"}>
                    {values.tags?.connectOrCreate.map((tag: string) => (
                      <Tag
                        label={tag}
                        dismissable={true}
                        mr={2}
                        onClickDismiss={() => {
                          setFieldValue("tags.connectOrCreate", [
                            ...(values.tags?.connectOrCreate.filter(
                              (aTag) => tag !== aTag
                            ) || []),
                          ]);
                          setFieldValue("tags.disconnect", [
                            ...(values.tags?.disconnect || []),
                            tag,
                          ]);
                        }}
                      ></Tag>
                    ))}
                  </Box>
                </Box>
              </FieldWrapper>
              <PageDivider className="mt-0" />
              <FieldWrapper>
                <FieldLabel value="Asset Manager" />
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={values.managerIds}
                    label="Asset Manager"
                    onChange={(e) =>
                      setFieldValue("managerIds", [e.target.value])
                    }
                    sx={{
                      fieldset: {
                        top: "0",
                      },
                      legend: {
                        display: "none",
                      },
                      ".MuiInputBase-input": {
                        width: "100%",
                        height: "36px",
                        fontSize: "1rem",
                        padding: "8px",
                        boxSizing: "border-box",
                      },
                    }}
                  >
                    {managerData?.dataAssetManagerCandidates.map(
                      (manager: any) => (
                        <MenuItem value={manager.id}>{manager.name}</MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </FieldWrapper>
              <FieldWrapper>
                <FieldLabel value="Asset Status" />
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={values.accessStatus}
                    renderValue={(v) =>
                      values.accessStatus ? (
                        <AccessStatusChip status={values.accessStatus} />
                      ) : null
                    }
                    label="Asset Status"
                    onChange={(e) =>
                      setFieldValue("accessStatus", e.target.value)
                    }
                    sx={{
                      fieldset: {
                        top: "0",
                      },
                      legend: {
                        display: "none",
                      },
                      ".MuiInputBase-input": {
                        width: "100%",
                        height: "36px",
                        fontSize: "1rem",
                        padding: "8px",
                        boxSizing: "border-box",
                      },
                    }}
                  >
                    {[
                      { id: "SHARED", name: "SHARED" },
                      { id: "RESTRICTED", name: "RESTRICTED" },
                      { id: "PRIVATE", name: "PRIVATE" },
                    ].map((manager: any) => (
                      <MenuItem value={manager.id}>{manager.name}</MenuItem>
                    ))}
                  </Select>
                  <Typography mt={2} variant="body3">
                    {values.accessStatus === AccessStatus.Shared &&
                      "Workspace members can add this data asset to any project within the workspace."}
                    {values.accessStatus === AccessStatus.Restricted &&
                      "Only the Asset Manager can add this data asset to a project."}
                    {values.accessStatus === AccessStatus.Private &&
                      "Private data assets cannot be added to or used in projects."}
                  </Typography>
                  <Typography color={palette.gray.dark} variant="body3">
                    {values.accessStatus === AccessStatus.Shared &&
                      "Any workspace member can view information about the asset."}
                    {values.accessStatus === AccessStatus.Restricted &&
                      "Workspace members can still discover and view information about the asset."}
                    {values.accessStatus === AccessStatus.Private &&
                      "Only members of the Data Asset Manager’s organization can view information about the asset."}
                  </Typography>
                </FormControl>
              </FieldWrapper>
              <PageDivider className="mt-0" />
              <FieldWrapper>
                <FieldLabel value="Data sensitivity" info />
                <Box>
                  <Radio
                    name="sensitivity"
                    label={"TIER_1"}
                    value={"TIER_1"}
                    checked={values?.sensitivity === "TIER_1"}
                    onChange={handleChange("sensitivity")}
                  />
                  <Radio
                    name="sensitivity"
                    label={"TIER_2"}
                    value={"TIER_2"}
                    checked={values?.sensitivity === "TIER_2"}
                    onChange={handleChange("sensitivity")}
                  />
                  <Radio
                    name="sensitivity"
                    label={"TIER_3"}
                    value={"TIER_3"}
                    onChange={handleChange("sensitivity")}
                    checked={values?.sensitivity === "TIER_3"}
                  />
                </Box>
              </FieldWrapper>
              <FieldWrapper>
                <FieldLabel value="Pll types" info />
                <Box>
                  {dataAsset?.piiTypes?.map((pllData: any) => (
                    <Tag label={pllData} dismissable={false} mr={2} />
                  ))}
                  {/* Not in Update API */}
                  {/* {data.pLLTypes.map((pllData: any) => (
                  <CheckBox label={pllData.value} />
                ))} */}
                </Box>
              </FieldWrapper>
              <FieldWrapper>
                <FieldLabel value="Asset-specific policies" info />
                <Box>
                  {[
                    ...(values?.assetPolicies?.update || []),
                    ...(values?.assetPolicies?.create || []),
                  ].map((policyData: any, index: number) => (
                    <AssetPolicyForm
                      onUpdate={(modified) => onPolicyModify(index, modified)}
                      policy={policyData}
                      ctaBtn={
                        <ButtonPrimary
                          color={"error"}
                          variant={"text"}
                          onClick={() => onDeletePolicy(policyData)}
                        >
                          Delete policy
                        </ButtonPrimary>
                      }
                      mb={4}
                    />
                  ))}
                  <ButtonPrimary
                    startIcon={<PlusIcon />}
                    color={"grayAccent"}
                    variant={"outlined"}
                    onClick={() => onNewPolicy()}
                  >
                    Add a policy
                  </ButtonPrimary>
                </Box>
              </FieldWrapper>
              <PageDivider className="mt-0" />
              <FieldWrapper>
                <FieldLabel value="Source" info />
                <TextField
                  hiddenLabel
                  fullWidth
                  onChange={handleChange("source")}
                  defaultValue={values.source}
                  inputProps={{
                    sx: { fontSize: "1rem", padding: ".375rem .75rem" },
                  }}
                  size="small"
                />
              </FieldWrapper>
              <FieldWrapper>
                <FieldLabel value="Connection" info />
                <Box>
                  <TextParagraph variant="body2">
                    {dataAsset.connectionType}
                  </TextParagraph>
                  <TextParagraph variant="body3" className="dark">
                    {dataAsset.connectionDescription}
                  </TextParagraph>
                </Box>
              </FieldWrapper>
              <FieldWrapper>
                <FieldLabel value="Data refresh" info />
                <Box>
                  {[
                    "NONE",
                    "DAILY",
                    "WEEKLY",
                    "MONTHLY",
                    "QUARTERLY",
                    "YEARLY",
                  ].map((item) => (
                    <Radio
                      name="refreshRate"
                      label={item}
                      value={item}
                      checked={values?.refreshRate === item}
                      onChange={handleChange("refreshRate")}
                    />
                  ))}
                </Box>
              </FieldWrapper>
              <FieldWrapper>
                <FieldLabel value="Storage service" info />
                <TextParagraph variant="body2" className="dark">
                  {dataAsset.storageService}
                </TextParagraph>
              </FieldWrapper>
              <FieldWrapper>
                <FieldLabel value="Asset size" info />
                <TextParagraph variant="body2" className="dark">
                  {dataAsset.assetSize}
                </TextParagraph>
              </FieldWrapper>
              <FieldWrapper>
                <FieldLabel value="Brighthive table name" info />
                <TextParagraph variant="body2" className="dark">
                  {dataAsset.tableName}
                </TextParagraph>
              </FieldWrapper>
              {/* <Box
                display={"flex"}
                pb={"1.5rem"}
                alignItems={"flex-start"}
                maxWidth={"58rem"}
              >
                <Label value="Table properties" info width={"15.5rem"} />
                <Typography variant="body2" color={palette.gray.dark}>
                  {tableProperties.map((tag: any) => (
                    <Tag
                      label={tag.key}
                      secondaryText={tag.value}
                      dismissable={false}
                      mr={2}
                      mb={2}
                    ></Tag>
                  ))}
                </Typography>
              </Box> */}
              {/* <Box
                display={"flex"}
                pb={"1.5rem"}
                alignItems={"flex-start"}
                maxWidth={"58rem"}
              >
                <Label value="Table properties" info width={"15.5rem"} />
                <Box display={"flex"} flexWrap="wrap">
                  Not in Get API
                  {data.tableProperties.map((tag: any) => (
                  <Tag
                    label={tag.property}
                    secondaryText={tag.value}
                    dismissable={false}
                    mr={2}
                    mb={2}
                  ></Tag>
                ))}
                </Box>
              </Box> */}
            </AdditionalTopBox>
          </AdditionalDetailsWrapper>
        );
      }}
    </Formik>
  );
};
