import React from "react";
import {
  Box,
  Checkbox as MUICheckBox,
  CheckboxProps as MUICheckBoxProps,
  useTheme,
} from "@mui/material";
import { Typography } from "../Typography/Typography";

export interface CheckBoxProps {
  type?: "default" | "small";
  label?: any;
}

export const CheckBox = ({
  type = "default",
  label = "CheckBox",
  ...rest
}: CheckBoxProps & MUICheckBoxProps) => {
  const { palette } = useTheme();

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <MUICheckBox
        sx={{
          color: palette.grayAccent.main,
          ml: -2,
          "&.Mui-checked": {
            color: palette.primary.main,
          },
          "& .MuiSvgIcon-root": {
            fontSize: 24,
          },
        }}
        {...rest}
      />
      <Typography
        variant={type === "default" ? "body2" : "body3"}
        ml={type === "default" ? ".25rem" : "0rem"}
      >
        {label}
      </Typography>
    </Box>
  );
};
