/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from "react";
import { css } from "@emotion/react";
import { Avatar, Box, useTheme } from "@mui/material";
import { AGGridWrapper } from "../../common/AGGridWrapper/AGGridWrapper";
import { ActionBarUsers } from "./ActionBarUsers";
import {
  GetQuickFilterTextParams,
  GridApi,
  ICellRendererParams,
} from "ag-grid-enterprise";
import { GridReadyEvent } from "ag-grid-community";
import { useQuery } from "@apollo/client";
import {
  GetOrganizationMembersDocument,
  GetWorkspaceMembersDocument,
  MemberStatus,
  OrganizationRoleEnum,
  WorkspaceRoleEnum,
} from "../../generated";
import { ArrowDropDown, Person } from "@mui/icons-material";
import { Tag } from "../../common/Tag/Tag";
import { OverridableStringUnion } from "@mui/types";
import { ChipPropsColorOverrides } from "@mui/material/Chip/Chip";
import { Chip } from "../../common/Chip/Chip";
import { RemoveUserCellRenderer } from "./RemoveUserCellRenderer";
import { displayToast } from "../../common/Toast/toast";
import { RoleCellEditor } from "./RoleCellEditor";
import { convertToTitleCase } from "../../helpers";
import { useParams } from "react-router-dom";
import { GridFooter } from "../../common/AGGridWrapper/GridFooter";
import { AvatarLabel } from "../../common/AvatarLabel/AvatarLabel";

export const Users = () => {
  const { workspaceId } = useParams();
  const { data, loading } = useQuery(GetOrganizationMembersDocument);

  const initialPaginationPageSize = 20;
  const [gridApi, setGridApi] = useState<GridApi>();
  const [paginationPageSize, setPaginationPageSize] = useState<number>(
    initialPaginationPageSize
  );
  const [paginationTotalPages, setPaginationTotalPages] = useState<number>(0);
  const [paginationCurrentPage, setPaginationCurrentPage] = useState<number>(0);

  const readOnlyData = useMemo(
    () => data?.currentUser?.organization?.members || [],
    [data?.currentUser?.organization?.members]
  );

  const isEditable = data?.currentUser?.organization?.isEditable;
  const organizationId = data?.currentUser?.organization?.id;

  const [rowData, setRowData] = useState<any[]>([]);

  useEffect(() => {
    if (Array.isArray(readOnlyData)) {
      const editableData = readOnlyData.map((curr) => ({
        ...curr,
        organizationId,
      }));
      setRowData(editableData);
    } else {
      setRowData([]);
    }
  }, [readOnlyData, organizationId]);

  const { palette } = useTheme();

  const onPaginationChanged = () => {
    if (gridApi) {
      setPaginationTotalPages(gridApi.paginationGetTotalPages());
      setPaginationCurrentPage(gridApi.paginationGetCurrentPage());
      setPaginationPageSize(gridApi.paginationGetPageSize());
    }
  };

  const columnDefs = useMemo(() => {
    return [
      {
        field: "name",
        headerName: "Name",
        getQuickFilterText: (params: GetQuickFilterTextParams) => params.value,
        type: "textColumn",
        cellClass: "dark-cell",
        cellRenderer: (params: ICellRendererParams) => {
          return <AvatarLabel size="small" label={params.value} />;
        },
      },
      {
        field: "emailAddress",
        headerName: "Email",
        getQuickFilterText: (params: GetQuickFilterTextParams) => params.value,
        type: "textColumn",
      },
      // {
      //   field: "organizationRole",
      //   headerName: "Organization Role",
      //   getQuickFilterText: (params: GetQuickFilterTextParams) => params.value,
      //   type: "textColumn",
      //   cellRenderer: (params: ICellRendererParams) => {
      //     const startIcon =
      //       params.value === OrganizationRoleEnum.Admin ? (
      //         <Person />
      //       ) : undefined;
      //     return (
      //       <Box
      //         width="100%"
      //         display="flex"
      //         justifyContent="space-between"
      //         alignItems="center"
      //         flexWrap="wrap"
      //       >
      //         <Tag
      //           startIcon={startIcon}
      //           label={convertToTitleCase(params.value)}
      //           dismissable={false}
      //         />
      //         <ArrowDropDown sx={{ color: palette.gray.dark }} />
      //       </Box>
      //     );
      //   },
      //   cellEditor: RoleCellEditor,
      //   cellEditorParams: {
      //     values: ["Admin", "General User"],
      //   },
      //   cellEditorPopup: true,
      //   editable: isEditable,
      // },
      {
        field: "assetManagedCount",
        type: "textColumn",
        headerName: "Assets managed",
        cellStyle: { justifyContent: "right" },
      },
      {
        field: "workspaceCount",
        type: "textColumn",
        headerName: "Workspaces",
        cellStyle: { justifyContent: "right" },
      },
      {
        field: "projectManagedCount",
        type: "textColumn",
        headerName: "Projects managed",
        cellStyle: { justifyContent: "right" },
      },
      {
        field: "createdAt",
        type: "dateColumn",
        headerName: "Date Added",
      },
      {
        field: "status",
        headerName: "Status",
        getQuickFilterText: (params: GetQuickFilterTextParams) => params.value,
        type: "textColumn",
        cellRenderer: (params: ICellRendererParams) => {
          let color: OverridableStringUnion<
            | "default"
            | "primary"
            | "secondary"
            | "error"
            | "info"
            | "success"
            | "warning",
            ChipPropsColorOverrides
          > = "primary";
          if (params.value) {
            color = "success";
          } else {
            color = "error";
          }
          return (
            <Chip
              size="small"
              color={color}
              label={params.value ? "Active" : "Inactive"}
            />
          );
        },
      },
      {
        field: "remove",
        headerName: "Remove",
        getQuickFilterText: (params: GetQuickFilterTextParams) => params.value,
        cellRenderer: (params: ICellRendererParams) => {
          const removeMember = () => {
            let deletedRow = params.data;
            params.api.applyTransaction({ remove: [deletedRow] });
            displayToast("Removed member");
          };
          return (
            <RemoveUserCellRenderer
              removeMember={removeMember}
              userId={params.data.id}
            />
          );
        },
        width: 80,
      },
    ].filter((item) => {
      if (!isEditable && item.field === "remove") {
        return false;
      }
      return true;
    });
  }, [isEditable, palette]);

  const [searchText, setSearchText] = useState("");

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params?.api);
    params?.api?.paginationSetPageSize(initialPaginationPageSize);
    params?.columnApi?.applyColumnState({
      state: [{ colId: "updated", sort: "asc" }],
    });
  };

  const [noResultsFoundFromFilter, setNoResultsFoundFromFilter] =
    useState(false);

  useEffect(() => {
    const onFilterChanged = ({ api }: { api: GridApi }) => {
      if (rowData.length > 0 && api.getDisplayedRowCount() === 0) {
        setNoResultsFoundFromFilter(true);
      } else {
        setNoResultsFoundFromFilter(false);
      }
    };
    gridApi?.addEventListener("filterChanged", onFilterChanged);
    return () => {
      gridApi?.removeEventListener("filterChanged", onFilterChanged);
    };
  }, [gridApi, rowData]);

  const onChangeSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchText(e.target.value);
    gridApi?.setQuickFilter(e.target.value);
  };

  return (
    <Box
      bgcolor={palette.common.white}
      className="ag-theme-material"
      flexGrow={1}
      display="flex"
      flexDirection="column"
      height="calc(100vh - 190px)"
    >
      <ActionBarUsers
        onChangeSearch={onChangeSearch}
        searchText={searchText}
        noResultsFoundFromFilter={noResultsFoundFromFilter}
      />
      <Box flexGrow={1}>
        <AGGridWrapper
          css={css`
            .ag-cell-inline-editing[col-id="role"] {
              height: inherit;
              padding: 0;
              box-shadow: none;
              background-color: initial;
            }
          `}
          loading={loading}
          onGridReady={onGridReady}
          rowData={rowData}
          columnDefs={columnDefs}
          onPaginationChanged={onPaginationChanged}
          defaultColDef={{
            flex: 1,
            minWidth: 200,
          }}
          singleClickEdit
        />
      </Box>
      <GridFooter
        totalRows={rowData.length}
        gridApi={gridApi}
        paginationPageSize={paginationPageSize}
        paginationCurrentPage={paginationCurrentPage}
        paginationTotalPages={paginationTotalPages}
        displayNoResults={noResultsFoundFromFilter || rowData.length === 0}
      />
    </Box>
  );
};
