import { Box, styled } from "@mui/material";
import { ArrowBack, Block } from "@mui/icons-material";
import { brighthiveTheme } from "../../theme/theme";
export const LeftRightHolder = styled(Box)`
  display: flex;
  @media (max-width: 992px) {
    display: block;
  }
  .left-elements {
    align-items: center;
    display: flex;
    @media (max-width: 992px) {
      margin-bottom: 10px;
      > div {
        width: 100%;
      }
    }
  }
  .right-elements {
    align-items: center;
    display: flex;
    > div {
      @media (max-width: 992px) {
        margin-left: 0;
      }
    }
  }
`;
