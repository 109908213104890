import React, { useState, useMemo } from "react";
import {
  Box,
  Dialog,
  InputAdornment,
  TextField,
  useTheme,
  Grid,
} from "@mui/material";

import { Typography } from "../../common/Typography/Typography";
import { Button } from "../../common/Button/Button";
import { Search } from "@mui/icons-material";
import { FilterByTagButton } from "../../common/ActionBarProjects/FilterByTagButton";
import { useParams } from "react-router-dom";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  AddDataAssetsToInputDataAssetGroupDocument,
  GetWorkflowDocument,
  OnboardDataAssetToInputDataAssetGroupDocument,
  GetProjectDataAssetDocument,
  GetDataAssetSuggestionsDocument,
  WorkflowInputDataAssetGroupDataAssetSuggestion,
} from "../../generated";
import DataAssetItem from "./DataAssetItem";
import { debounce, uniq } from "lodash";
import { SimpleLoader } from "../../common/Loader/Loader";
import { FormikValues } from "formik";
import { ImportAssetModal } from "../../AssetDetail/ImportAssetModal/ImportAssetModal";
import { toast } from "react-toastify";
import axios from "axios";

interface AddInputDataAssetsModalProps {
  show: boolean;
  closeDialog: Function;
  inputDataAssetGroupId: string;
  schemaId: string;
  selectedIds: string[];
}

export const AddInputDataAssetsModal = ({
  show,
  closeDialog,
  inputDataAssetGroupId,
  selectedIds,
  schemaId,
}: AddInputDataAssetsModalProps) => {
  const { palette } = useTheme();
  const [tagValues, setTagValues] = useState<FormikValues>({
    all: true,
  });
  const [tags, setTags] = useState<string[]>([]);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState<string[]>([]);

  const { workspaceId, projectId } = useParams();
  const client = useApolloClient();

  const [addDataAssetsToInputData, { loading: loadingMutate }] = useMutation(
    AddDataAssetsToInputDataAssetGroupDocument,
    {
      onCompleted: () => {
        closeDialog();
      },
      refetchQueries: [GetWorkflowDocument, GetProjectDataAssetDocument],
    }
  );

  const { data, loading } = useQuery(GetDataAssetSuggestionsDocument, {
    variables: {
      workflowFilterInput: {
        projectId: projectId || "",
        workspaceId: workspaceId || "",
      },
    },
    onCompleted: (res) => {
      const dataAssets =
        res?.workflow?.inputDataAssetGroups[0]?.dataAssetSuggestions || [];
      const newTags: string[] = [];
      dataAssets.forEach((item) => {
        item?.tags.forEach((tag) => {
          newTags.push(tag);
        });
      });
      setTags(uniq(newTags));
    },
  });

  const [onboardDataAssetToInputDataAssetGroupDocument] = useMutation(
    OnboardDataAssetToInputDataAssetGroupDocument
  );

  const rowData = useMemo(() => {
    const results =
      data?.workflow?.inputDataAssetGroups[0]?.dataAssetSuggestions
        ?.map((asset) => asset)
        .filter((asset) => {
          if (selectedIds.includes(asset?.id || "")) {
            return false;
          }
          if (search) {
            return asset?.name
              ?.toLocaleLowerCase()
              .includes(search.toLocaleLowerCase());
          }
          return true;
        }) || [];

    const getDataFilteredByTag = (
      assets: WorkflowInputDataAssetGroupDataAssetSuggestion[]
    ) => {
      if (tagValues.all === true) {
        return assets;
      } else {
        return assets.filter((curr) => {
          if (!curr?.tags.length) return false;
          let filter = false;
          Object.keys(tagValues).forEach((key) => {
            if (tagValues[key] && curr.tags.includes(key)) {
              filter = true;
            }
          });
          return filter;
        });
      }
    };

    return getDataFilteredByTag(
      results as WorkflowInputDataAssetGroupDataAssetSuggestion[]
    );
  }, [data?.workflow?.inputDataAssetGroups, selectedIds, search, tagValues]);

  const handleAddSelected = () => {
    addDataAssetsToInputData({
      variables: {
        input: {
          dataAssetIds: selected,
          inputDataAssetGroupId: inputDataAssetGroupId,
          workspaceId: workspaceId || "",
        },
      },
    });
  };

  return (
    <Dialog maxWidth="xl" open={show} onClose={() => closeDialog()}>
      <Box
        onClick={(e) => e.preventDefault()}
        width="50rem"
        p={6}
        boxSizing="border-box"
      >
        <Typography mb={4} variant="h5">
          Add input data assets
        </Typography>
        <Box display="flex" justifyContent="space-between">
          <TextField
            placeholder="Find a workspace data asset"
            size="small"
            onChange={debounce((e) => setSearch(e.target.value), 1000)}
            InputProps={{
              sx: {
                fontSize: "1rem",
                width: "20rem",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <Box display="flex" alignItems="center">
            <Typography mr={3} variant="button" color={palette.grayAccent.main}>
              Filter by:{" "}
            </Typography>
            <FilterByTagButton
              tags={tags}
              text="Tag"
              values={tagValues}
              onSubmit={(v) => {
                setTagValues(v);
              }}
            />
          </Box>
        </Box>
        <Box
          mt={4}
          sx={{
            maxHeight: "calc(100vh - 300px)",
            minHeight: "200px",
            overflowY: "auto",
          }}
        >
          {loading && (
            <Box display="flex" justifyContent="center">
              <SimpleLoader />
            </Box>
          )}
          <Grid container spacing={2}>
            {rowData.map((item) =>
              !item ? null : (
                <Grid item key={item.id} xs={12}>
                  <DataAssetItem
                    item={
                      item as WorkflowInputDataAssetGroupDataAssetSuggestion
                    }
                    selected={selected.includes(item.id)}
                    onClickSelected={(v) => {
                      let newSelected = Array.from(selected);
                      if (v) {
                        newSelected.push(item.id);
                      } else {
                        newSelected = newSelected.filter(
                          (id) => id !== item.id
                        );
                      }
                      setSelected(newSelected);
                    }}
                  />
                </Grid>
              )
            )}
          </Grid>
        </Box>
        <Box
          mt={4}
          display="flex"
          alignItems={"center"}
          justifyContent="space-between"
        >
          <Box>
            {selected.length > 0 && (
              <Typography variant="body3">
                {selected.length} selected
              </Typography>
            )}
          </Box>
          <Box display="flex">
            <Button
              onClick={(e) => {
                e.preventDefault();
                closeDialog();
              }}
              color="grayAccent"
              variant="outlined"
              sx={{ ml: "auto", mr: 3 }}
            >
              Cancel
            </Button>
            {schemaId && (
              <ImportAssetModal
                schemaId={schemaId}
                onSuccess={async (fileName: string, csv: string) => {
                  const res =
                    await onboardDataAssetToInputDataAssetGroupDocument({
                      variables: {
                        input: {
                          inputDataAssetGroupId,
                          projectId: projectId || "",
                          workspaceId: workspaceId || "",
                          name: fileName,
                        },
                      },
                    });

                  if (
                    res.data?.onboardDataAssetToInputDataAssetGroup.success &&
                    res.data.onboardDataAssetToInputDataAssetGroup.presignedUrl
                  ) {
                    const file = new File([csv], fileName, {
                      type: "text/csv",
                    });
                    await axios.put(
                      res.data.onboardDataAssetToInputDataAssetGroup
                        .presignedUrl,
                      file,
                      { headers: { "Content-Type": file.type } }
                    );

                    await client.refetchQueries({
                      include: [GetWorkflowDocument],
                    });
                    toast.success("Success import data");

                    closeDialog();
                  } else {
                    toast.error("Failed");
                  }
                }}
                render={(importer, launch) => {
                  return (
                    <Button
                      color="grayAccent"
                      variant="contained"
                      sx={{ mr: 3 }}
                      onClick={() => launch()}
                    >
                      Onboard new assets
                    </Button>
                  );
                }}
              />
            )}

            <Button
              disabled={loadingMutate || selected.length === 0}
              onClick={handleAddSelected}
              color="primary"
              variant="contained"
            >
              Add selected
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
