import React from "react";
import { Box, useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { BrighthiveLogoSvg } from "../../../common/Icons/BrighthiveLogoSvg";
import { Typography } from "../../../common/Typography/Typography";
import { Button } from "../../../common/Button/Button";
import { Label } from "../../../common/Label/Label";
import { useMutation } from "@apollo/client";
import { ConfirmUserDocument } from "../../../generated";

export interface RegisterUserProps {}

interface State {
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  showPassword: boolean;
}

export const RegisterUser = () => {
  const navigate = useNavigate();
  const { session = "", username = "" } = useParams();
  const { palette } = useTheme();
  const [mutate, { loading }] = useMutation(ConfirmUserDocument);

  const [values, setValues] = React.useState<State>({
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    showPassword: false,
  });

  const handleSubmit = async () => {
    const result = await mutate({
      variables: {
        user: {
          firstName: values.firstName,
          lastName: values.lastName,
          password: values.password,
          session,
          username,
        },
      },
    });

    if (result?.data?.confirmUser?.success) {
      localStorage.setItem("token", result.data?.confirmUser?.idToken || "");
      navigate("/agree-policy");
    }
  };
  const [showPasswordRule, setShowPasswordRule] = React.useState(false);
  const [showConfirmPasswordRule, setShowConfirmPasswordRule] =
    React.useState(false);

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const isDisabled = () => {
    if (
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(values.password)
    ) {
      return true;
    }
    if (
      values.firstName.length === 0 ||
      values.lastName.length === 0 ||
      values.password !== values.confirmPassword
    ) {
      return true;
    }

    if (loading) {
      return true;
    }

    return false;
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        p: 6,
        m: "auto",
        width: "28.5rem",
        background: "white",
        borderRadius: ".375rem",
        boxShadow:
          `0 .0625rem .0625rem ${palette.customRolled.border}, 0 .125rem .0625rem ${palette.action.disabledBackground}, 0 .0625rem .1875rem ${palette.customRolled.contrastText}`,
      }}
    >
      <BrighthiveLogoSvg
        sx={{
          height: "1.875rem",
          width: "initial",
          mb: 6,
        }}
      />
      <Typography variant="h5" mb={6}>
        Create a Brighthive account
      </Typography>
      <Typography mb={6} variant="body2">
        Please confirm that you belong to this organization to continue.
      </Typography>
      <Label value="First name" variant="subtitle3" sx={{ mb: ".375rem" }} />
      <TextField
        value={values.firstName}
        hiddenLabel
        fullWidth
        onChange={handleChange("firstName")}
        inputProps={{ sx: { fontSize: "1rem", padding: ".375rem .75rem" } }}
        size="small"
        sx={{ mb: 4 }}
      />
      <Label value="Last name" variant="subtitle3" sx={{ mb: ".375rem" }} />
      <TextField
        value={values.lastName}
        hiddenLabel
        fullWidth
        onChange={handleChange("lastName")}
        inputProps={{ sx: { fontSize: "1rem", padding: ".375rem .75rem" } }}
        size="small"
        sx={{ mb: 4 }}
      />
      <Label value="Password" variant="subtitle3" sx={{ mb: ".375rem" }} />
      <OutlinedInput
        onFocus={() => setShowPasswordRule(true)}
        onBlur={() => setShowPasswordRule(false)}
        fullWidth
        notched={false}
        sx={{ mb: 4 }}
        type={values.showPassword ? "text" : "password"}
        value={values.password}
        onChange={handleChange("password")}
        inputProps={{ sx: { fontSize: "1rem", padding: ".375rem .75rem" } }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {values.showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label="Password"
      />
      {showPasswordRule && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="body3" sx={{ display: "flex" }}>
            {/(?=.*?[a-z])/.test(values.password) ? (
              <CheckCircleOutlineIcon
                fontSize="small"
                color="primary"
                sx={{ mr: 1 }}
              />
            ) : (
              <ErrorOutlineIcon fontSize="small" color="error" sx={{ mr: 1 }} />
            )}
            Password must contain a lower case letter
          </Typography>
          <Typography variant="body3" sx={{ display: "flex" }}>
            {/(?=.*?[A-Z])/.test(values.password) ? (
              <CheckCircleOutlineIcon
                fontSize="small"
                color="primary"
                sx={{ mr: 1 }}
              />
            ) : (
              <ErrorOutlineIcon fontSize="small" color="error" sx={{ mr: 1 }} />
            )}
            Password must contain an upper case letter
          </Typography>
          <Typography variant="body3" sx={{ display: "flex" }}>
            {/(?=.*?[#?_=)(!@$%^&*-])/.test(values.password) ? (
              <CheckCircleOutlineIcon
                fontSize="small"
                color="primary"
                sx={{ mr: 1 }}
              />
            ) : (
              <ErrorOutlineIcon fontSize="small" color="error" sx={{ mr: 1 }} />
            )}
            Password must contain a special character
          </Typography>
          <Typography variant="body3" sx={{ display: "flex" }}>
            {/(?=.*?[0-9])/.test(values.password) ? (
              <CheckCircleOutlineIcon
                fontSize="small"
                color="primary"
                sx={{ mr: 1 }}
              />
            ) : (
              <ErrorOutlineIcon fontSize="small" color="error" sx={{ mr: 1 }} />
            )}
            Password must contain a number
          </Typography>
          <Typography variant="body3" sx={{ display: "flex" }}>
            {/.{8,}/.test(values.password) ? (
              <CheckCircleOutlineIcon
                fontSize="small"
                color="primary"
                sx={{ mr: 1 }}
              />
            ) : (
              <ErrorOutlineIcon fontSize="small" color="error" sx={{ mr: 1 }} />
            )}
            Password must contain at least 8 characters
          </Typography>
        </Box>
      )}
      <Label
        value="Confirm password"
        variant="subtitle3"
        sx={{ mb: ".375rem" }}
      />
      <OutlinedInput
        onFocus={() => setShowConfirmPasswordRule(true)}
        onBlur={() => setShowConfirmPasswordRule(false)}
        sx={{ mb: 6 }}
        fullWidth
        notched={false}
        type={values.showPassword ? "text" : "password"}
        value={values.confirmPassword}
        onChange={handleChange("confirmPassword")}
        inputProps={{ sx: { fontSize: "1rem", padding: ".375rem .75rem" } }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {values.showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label="Password"
      />
      {showConfirmPasswordRule && values.password !== values.confirmPassword && (
        <Typography
          variant="body3"
          sx={{ display: "flex", mb: 4, mt: -4 }}
          color={palette.error.main}
        >
          <ErrorOutlineIcon fontSize="small" color="error" sx={{ mr: 1 }} />
          Passwords do not match
        </Typography>
      )}
      <Button
        disabled={isDisabled()}
        onClick={handleSubmit}
        fullWidth
        variant="contained"
      >
        Create a account and continue
      </Button>
    </Box>
  );
};
