import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  Select,
  Card,
} from "@mui/material";
import { useState } from "react";
import { Button } from "react-activity-feed";

const data = [
  {
    name: "PortFort",
    status: "Connected",
    type: "Partner",
    dataAsset: 40,
    owner: {
      name: "BrightHivee",
      email: "brightgive@hibve.com",
    },
    url: "https://www.google.com/",
    dataServiceType: "Connection Type",
    description: "A new eve of motion... awpdawdawdk awdnaiwdniawd awd",
    tags: ["one", "two", "three"],
  },
  {
    name: "BrightHive",
    status: "Connected",
    type: "Partner",
    dataAsset: 40,
    owner: {
      name: "BrightHivee",
      email: "brightgive@hibve.com",
    },
    dataServiceType: "Connection Type",
    description: "A new eve of motion... awpdawdawdk awdnaiwdniawd awd",
    tags: ["one", "two", "three"],
  },
];

export const Subscription = ({ close }: { close: Function }) => {
  const [detail, setDetail] = useState<any>();

  return (
    <Box>
      <Typography>Add Source: Subscription</Typography>

      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Source Group</InputLabel>
        <Select label="Source Group">
          <MenuItem>Internal</MenuItem>
          <MenuItem>Partner</MenuItem>
          <MenuItem>Subscription</MenuItem>
          <MenuItem>Marketplace</MenuItem>
        </Select>
      </FormControl>

      <p>Select Subscriptions</p>
      <input name="source" />
      <p>Can not find the partner? Add Partner or Request</p>

      {data.map((row) => (
        <Box className="ag-theme-material">
          <Box
            sx={{
              cursor: "pointer",
              position: "relative",
            }}
          >
            <Box
              sx={{
                cursor: "pointer",
                display: "flex",
                flexDirection: "row",
              }}
              onClick={() => setDetail(row)}
              className="project-card"
              tabIndex={1}
              aria-labelledby={`${row.name}-title`}
              role="link"
            >
              <Card
                sx={{
                  cursor: "pointer",
                }}
              >
                <Typography fontWeight="bold" ml={3} variant="body3">
                  {row.name}
                </Typography>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                >
                  <p>Data Asset:</p>
                  <p>{row.dataAsset}</p>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                >
                  <p>Owner:</p>
                  <p>{row.owner.name}</p>
                </Box>
                <p>{row.description}</p>
              </Card>
            </Box>
          </Box>
        </Box>
      ))}
      <Box>
        <Button onClick={() => close()}>Close</Button>
        <Button onClick={() => console.log("submit")}>submit</Button>
      </Box>
    </Box>
  );
};
