import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const Partner = (props: SvgIconProps) => {
  return (
    <SvgIcon height="14" width="15" viewBox="0 0 15 14" fill="none" {...props}>
      <path
        d="M1.60001 13V11.6667C1.60001 10.9594 1.88096 10.2811 2.38105 9.78105C2.88115 9.28095 3.55943 9 4.26667 9H6.93334C7.64058 9 8.31886 9.28095 8.81896 9.78105C9.31905 10.2811 9.60001 10.9594 9.60001 11.6667V13M10.2667 1.08667C10.8403 1.23353 11.3487 1.56713 11.7118 2.03487C12.0748 2.50261 12.2719 3.07789 12.2719 3.67C12.2719 4.26211 12.0748 4.83739 11.7118 5.30513C11.3487 5.77287 10.8403 6.10647 10.2667 6.25333M13.6 13V11.6667C13.5966 11.0781 13.3986 10.5072 13.0369 10.0429C12.6751 9.57867 12.1699 9.24713 11.6 9.1M2.93334 3.66667C2.93334 4.37391 3.21429 5.05219 3.71439 5.55229C4.21448 6.05238 4.89276 6.33333 5.60001 6.33333C6.30725 6.33333 6.98553 6.05238 7.48562 5.55229C7.98572 5.05219 8.26667 4.37391 8.26667 3.66667C8.26667 2.95942 7.98572 2.28115 7.48562 1.78105C6.98553 1.28095 6.30725 1 5.60001 1C4.89276 1 4.21448 1.28095 3.71439 1.78105C3.21429 2.28115 2.93334 2.95942 2.93334 3.66667Z"
        stroke="currentColor"
        stroke-width="1.6"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};
