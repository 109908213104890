import axios from "axios";

// Set up a base URL for your API
const chatURL = process.env.REACT_APP_BASE_URL; // Replace with your actual API base URL

// Create an Axios instance with a base URL
const chatApi = axios.create({
  baseURL: chatURL,
});

chatApi.interceptors.request.use(
  function (config: any) {
    config.headers["X-Api-Key"] =
      process.env.REACT_APP_BRIGHTIBOT_API_KEY || "";
    config.headers["Content-Type"] = "application/json";

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

chatApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default chatApi;
