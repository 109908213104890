import React from "react";
import { PopoverProps } from "@mui/material";
import { allDefaultTagsFilterOperators, SpecificTextFilterOperator, TextFilterOperator } from "./gridHelpers";
import { TextPopoverFilterUI } from "./TextPopoverFilterUI";
import { Column, GridApi } from "ag-grid-enterprise";
import { useTagsFiltersWithGrid } from "./useTagsFiltersWithGrid";

interface SpecificTagsPopoverFilterProps {
  displayName: string;
  description?: string;
  column: Column;
  gridApi: GridApi;
  filterOperators: TextFilterOperator[];
}

export type TagsPopoverFilterProps = SpecificTagsPopoverFilterProps &
  PopoverProps;

export const TagsPopoverFilter = ({
  gridApi,
  column,
  displayName,
  description,
  filterOperators,
  ...rest
}: TagsPopoverFilterProps) => {
  const initialFilterOperator = filterOperators.includes(
    SpecificTextFilterOperator.CONTAINS
  )
    ? SpecificTextFilterOperator.CONTAINS
    : filterOperators[0];

  const {
    filterText,
    currentFilterOperator,
    uniqueFilterValues,
    selectedUniqueFilterValues,
    onChangeFilterOperator,
    onChangeSelectedUniqueFilterValues,
    onChangeFilterText,
    onClickRefreshFilter,
  } = useTagsFiltersWithGrid(filterOperators, column, gridApi, initialFilterOperator);

  if (filterOperators.length === 0) {
    return <div>No filter options provided</div>;
  }

  return (
    <TextPopoverFilterUI
      {...rest}
      displayName={displayName}
      description={description}
      currentFilterOperator={currentFilterOperator}
      onChangeFilterOperator={onChangeFilterOperator}
      filterOperators={filterOperators}
      selectedUniqueFilterValues={selectedUniqueFilterValues}
      onChangeSelectedUniqueFilterValues={onChangeSelectedUniqueFilterValues}
      uniqueFilterValues={uniqueFilterValues}
      filterText={filterText}
      onChangeFilterText={onChangeFilterText}
      onClickRefreshFilter={onClickRefreshFilter}
    />
  );
};

TagsPopoverFilter.defaultProps = {
  filterOperators: allDefaultTagsFilterOperators,
};
