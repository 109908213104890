import "ag-grid-enterprise";
import { ColumnApi, GridApi, ICellRendererParams } from "ag-grid-enterprise";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-material.css";
import React, { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { ActionBarDataAssetCatalog } from "../../DataAssetCatalog/ActionBarDataAssetCatalog/ActionBarDataAssetCatalog";
import { AGGridWrapper } from "../../common/AGGridWrapper/AGGridWrapper";
import { Tag } from "../../common/Tag/Tag";
import { GridFooter } from "../../common/AGGridWrapper/GridFooter";
import { GetQuickFilterTextParams, GridReadyEvent } from "ag-grid-community";
import { RemoveFieldCellRenderer } from "./RemoveFieldCellRenderer";
import { displayToast } from "../../common/Toast/toast";
import { EditFieldCellRenderer } from "./EditFieldCellRenderer";
import {
  FilterOperator,
  SpecificTextFilterOperator,
} from "../../common/AGGridWrapper/gridHelpers";
import CommonTooltip from "../../common/ToolTips/CommonTooltip";
import { EllipsisBox } from "../../common/StyledComp/EllipsisBox";
import { ButtonLink } from "./style";
import { DisplayDrawerHandlerType } from "../../common/Drawer";

type Props = {
  schemaDetail: any;
  loading?: any;
  update: any;
  displayDrawerHandler: DisplayDrawerHandlerType;
  rowData: any[];
  handleApiReady: any;
};

export const ProjectSchemaDetailGrid = ({
  schemaDetail,
  loading,
  update,
  displayDrawerHandler,
  rowData,
  handleApiReady,
}: Props) => {
  // const { data, loading } = useQuery(GetTargetSchemasDocument, {
  //   variables: {
  //     input: {
  //       workspaceId: workspaceId || "",
  //     },
  //     projectFilter: {
  //       projectId: projectId || "",
  //     },
  //     targetSchemaFilter: {
  //       schemaId: schemaId || "",
  //     },
  //   },
  // });

  // const schemaDetail = useMemo(() => {
  //   return data?.workspace?.projects?.[0]?.targetSchemas?.[0];
  // }, [data?.workspace?.projects?.[0]?.targetSchemas?.[0]]);

  const columnDefs = [
    {
      field: "no",
      headerName: "Order",
      cellRenderer: "agGroupCellRenderer",
      width: 120,
    },
    {
      field: "name",
      headerName: "Field name",
      getQuickFilterText: (params: GetQuickFilterTextParams) => params.value,
      headerComponentParams: {
        description: "The name of the data asset.",
        filterOperators: [
          SpecificTextFilterOperator.CONTAINS,
          SpecificTextFilterOperator.STARTS_WITH,
          SpecificTextFilterOperator.ENDS_WITH,
          FilterOperator.IS_ANY_OF,
          FilterOperator.IS_NONE_OF,
        ],
      },
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <ButtonLink
            onClick={() => displayDrawerHandler("view", params.data?.no)}
          >
            {params.value}
          </ButtonLink>
        );
      },
    },
    {
      field: "title",
      headerName: "Field title",
    },
    {
      field: "type",
      headerName: "Data type",
    },
    {
      field: "description",
      headerName: "Field description",
      minWidth: 500,
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <CommonTooltip
            title={params.data?.description || ""}
            placement="bottom-start"
          >
            <EllipsisBox>{params.data?.description || ""}</EllipsisBox>
          </CommonTooltip>
        );
      },
    },
    {
      field: "requirements",
      headerName: "Data requirements",
      minWidth: 300,
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <Box display="flex" gap={2}>
            {params.data.required && <Tag label="required" />}
            {params.data.minLength && (
              <Tag label="minLength" secondaryText={params.data.minLength} />
            )}
            {params.data.maxLength && (
              <Tag label="maxLength" secondaryText={params.data.maxLength} />
            )}
            {params.data.minimun && (
              <Tag label="minimun" secondaryText={params.data.minimun} />
            )}
          </Box>
        );
      },
    },
    {
      field: "remove",
      headerName: "Action",
      getQuickFilterText: (params: GetQuickFilterTextParams) => params.value,
      cellRenderer: (params: ICellRendererParams) => {
        const removeField = () => {
          let deletedRow = params.data;
          params.api.applyTransaction({ remove: [deletedRow] });
          displayToast("Removed Field");
        };
        return (
          <Box display="flex">
            <RemoveFieldCellRenderer
              targetSchema={schemaDetail}
              removeField={removeField}
              id={params.data.name}
            />
            <EditFieldCellRenderer
              update={update}
              targetSchema={schemaDetail}
              id={params.data.name}
              data={params.data}
            />
          </Box>
        );
      },
    },
  ];

  const initialPaginationPageSize = 20;

  const [gridApi, setGridApi] = useState<GridApi>();
  const [columnApi, setColumnApi] = useState<ColumnApi>();
  const [paginationPageSize, setPaginationPageSize] = useState<number>(
    initialPaginationPageSize
  );
  const [paginationTotalPages, setPaginationTotalPages] = useState<number>(0);
  const [paginationCurrentPage, setPaginationCurrentPage] = useState<number>(0);
  const [searchText, setSearchText] = useState("");

  const onPaginationChanged = () => {
    if (gridApi) {
      setPaginationTotalPages(gridApi.paginationGetTotalPages());
      setPaginationCurrentPage(gridApi.paginationGetCurrentPage());
      setPaginationPageSize(gridApi.paginationGetPageSize());
    }
  };

  const onChangeSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchText(e.target.value);
    gridApi?.setQuickFilter(e.target.value);
  };

  const onGridReady = (params: GridReadyEvent) => {
    handleApiReady(params?.api);
    setGridApi(params?.api);
    setColumnApi(params?.columnApi);
    params?.api?.paginationSetPageSize(initialPaginationPageSize);
    params?.columnApi?.applyColumnState({
      state: [{ colId: "updated", sort: "asc" }],
    });
  };

  const [noResultsFoundFromFilter, setNoResultsFoundFromFilter] =
    useState(false);

  useEffect(() => {
    const onFilterChanged = ({ api }: { api: GridApi }) => {
      if (rowData.length > 0 && api.getDisplayedRowCount() === 0) {
        setNoResultsFoundFromFilter(true);
      } else {
        setNoResultsFoundFromFilter(false);
      }
    };
    gridApi?.addEventListener("filterChanged", onFilterChanged);
    return () => {
      gridApi?.removeEventListener("filterChanged", onFilterChanged);
    };
  }, [gridApi, rowData]);

  const onResetFilters = (gridApi: GridApi | undefined) => {
    gridApi?.setFilterModel(null);
    gridApi?.setQuickFilter("");
    setSearchText("");
  };

  const detailCellRendererParams = useMemo<any>(() => {
    return {
      detailGridOptions: {
        columnDefs: [
          {
            field: "type",
            headerName: "Data type",
          },
          {
            field: "requirements",
            headerName: "Field requirements",
            minWidth: 300,
            cellRenderer: (params: ICellRendererParams) => {
              return (
                <Box display="flex" gap={2}>
                  {params.data.required && <Tag label="required" />}
                  {params.data.minLength && (
                    <Tag
                      label="minChar"
                      secondaryText={params.data.minLength}
                    />
                  )}
                  {params.data.maxLength && (
                    <Tag
                      label="maxChar"
                      secondaryText={params.data.maxLength}
                    />
                  )}
                  {params.data.minimun && (
                    <Tag label="minimun" secondaryText={params.data.minimun} />
                  )}
                </Box>
              );
            },
          },
        ],
        defaultColDef: {
          flex: 1,
        },
      },
      headerHeight: 40,
      rowHeight: 40,
      getDetailRowData: (params: any) => {
        params.successCallback(params?.data?.callRecords || [{ name: "" }]);
      },
    };
  }, []);

  return (
    <>
      <Box
        className="ag-theme-material"
        flexGrow={1}
        display="flex"
        flexDirection="column"
      >
        <ActionBarDataAssetCatalog
          hideChipFilter
          onChangeSearch={onChangeSearch}
          searchText={searchText}
          noResultsFoundFromFilter={noResultsFoundFromFilter}
          onResetFilters={() => onResetFilters(gridApi)}
          searchPlaceholder="Search name, description, or tags"
        />
        <Box flexGrow={1}>
          <AGGridWrapper
            getRowId={(params) => params.data.no}
            onGridReady={onGridReady}
            rowData={rowData}
            columnDefs={columnDefs}
            onPaginationChanged={onPaginationChanged}
            loading={loading}
            detailCellRendererParams={detailCellRendererParams}
            masterDetail={true}
          />
        </Box>
        <GridFooter
          totalRows={rowData.length}
          gridApi={gridApi}
          paginationPageSize={paginationPageSize}
          paginationCurrentPage={paginationCurrentPage}
          paginationTotalPages={paginationTotalPages}
          displayNoResults={noResultsFoundFromFilter || rowData.length === 0}
        />
      </Box>
    </>
  );
};
