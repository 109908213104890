import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Button } from "../common/Button/Button";
import { BrighthiveLogoSvgVii } from "../common/Icons/BrighthiveLogoSvgVii";
import { ErrorPage } from "../common/Icons/ErrorPage";
import { PageContent, PageHeader, Wrapper } from "./style";

export const Unauthorized = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <PageHeader>
        <BrighthiveLogoSvgVii />
      </PageHeader>

      <PageContent>
        <Typography variant="h3">404 Page Not Found</Typography>
        <ErrorPage />
        <Button variant="contained" onClick={() => navigate("/")}>
          Navigate Home
        </Button>
      </PageContent>
    </Wrapper>
  );
};
