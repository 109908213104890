import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { WorkspaceSwitcherDialog } from "./WorkspaceSwitcherDialog";

const WorkspaceSwitcherPage = () => {
  const navigate = useNavigate();
  const gotoLogin = useCallback(() => {
    navigate("/login");
  }, [navigate]);
  return <WorkspaceSwitcherDialog open={true} onClose={gotoLogin} />;
};

export default WorkspaceSwitcherPage;
