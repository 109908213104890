import { Box, BoxProps, useTheme } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Delete } from "@mui/icons-material";

import { Button } from "../common/Button/Button";
import { Typography } from "../common/Typography/Typography";
import { Label } from "../common/Label/Label";

export interface DocAdditionProps {
  id?: string;
  name?: string;
  href?: string;
  onModify?: (doc: { id?: string; name: string; href: string }) => void;
  onDelete?: () => void;
}

export const DocAddition = ({
  id,
  name = "",
  href = "",
  onModify,
  onDelete,
  ...rest
}: DocAdditionProps & BoxProps) => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: palette.background.default,
        padding: "1rem",
        marginBottom: "1rem",
      }}
      {...rest}
    >
      <Typography mb={"1rem"}>Document link</Typography>
      <Label value="Document name" variant="body3" mb={1.5} />
      <TextField
        hiddenLabel
        fullWidth
        defaultValue={name}
        onChange={(e) =>
          onModify &&
          onModify({
            id,
            href,
            name: e.target.value,
          })
        }
        inputProps={{
          sx: {
            fontSize: "1rem",
            padding: ".375rem .75rem",
            background: palette.common.white,
          },
        }}
        size="small"
        sx={{ mb: 5 }}
      />
      <Label value="URL" variant="body3" mb={1.5} />
      <TextField
        hiddenLabel
        fullWidth
        defaultValue={href}
        onChange={(e) =>
          onModify &&
          onModify({
            id,
            name,
            href: e.target.value,
          })
        }
        inputProps={{
          sx: {
            fontSize: "1rem",
            padding: ".375rem .75rem",
            background: palette.common.white,
          },
        }}
        size="small"
        sx={{ mb: 2 }}
      />
      <Button
        startIcon={<Delete />}
        color={"error"}
        variant={"text"}
        onClick={onDelete}
      >
        Delete document link
      </Button>
    </Box>
  );
};
