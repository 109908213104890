import { Box, TextField, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import { FormLabel } from "../../common/Form/FormLabel";
import { KnowledgeCenter } from "../KnowledgeCenter";
import { AllGrid } from "./TermsTable";
import { useQuery } from "@apollo/client";
import { TermsDocument } from "../../generated";
import { useParams } from "react-router-dom";
import { DownloadCSV } from "../../common/DownloadCSV/DownloadCSV";

export const Terms = ({}) => {
  const { palette } = useTheme();
  const { workspaceId, projectId } = useParams();
  const { data, loading, refetch } = useQuery(TermsDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
    },
  });

  return (
    <KnowledgeCenter
      title="Terms"
      rightElement={!loading && <DownloadCSV json={data?.workspace?.terms} />}
    >
      <AllGrid data={data?.workspace?.terms || null} loading={loading} />
    </KnowledgeCenter>
  );
};
