import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  lighten,
  useTheme,
} from "@mui/material";
import { Typography } from "../common/Typography/Typography";
import { Chip } from "../common/Chip/Chip";
import {
  Check,
  ErrorOutline,
  ExpandMore,
  Person,
  WarningAmber,
} from "@mui/icons-material";
import { WorkflowIssueStatus } from "../generated";
import { NODE_WIDTH } from "./helpers";
import { LinesEllipsisTypography } from "../common/LinesEllipsisTypography/LinesEllipsisTypography";
import { Handle, Position, useStore } from "react-flow-renderer";

export interface DataNodeProps {
  id?: string;
  title?: string;
  issueStatus?: WorkflowIssueStatus;
  caption?: string;
  containsPii?: boolean;
  bodyText?: string;
  footer?: React.ReactNode;
  accordionSummaryContent?: React.ReactNode;
  accordionDetailsContent?: React.ReactNode;
  selected?: boolean;
  targetHandle: boolean;
  sourceHandle: boolean;
  targetHandlePosition: Position;
  sourceHandlePosition: Position;
  targetHandleB?: Position;
  sourceHandleB?: Position;
}

export const DataNode = ({
  id,
  title,
  issueStatus,
  caption,
  containsPii,
  accordionSummaryContent,
  accordionDetailsContent,
  bodyText,
  selected,
  footer,
  targetHandle,
  sourceHandle,
  targetHandlePosition,
  sourceHandlePosition,
  targetHandleB,
  sourceHandleB,
}: DataNodeProps) => {
  const { palette } = useTheme();
  const store = useStore();
  const [expand, setExpand] = useState<boolean>(false);

  const toggleAcordion = () => {
    setExpand((prev) => !prev);
  };

  const getLabelIssueStatus = (issueStatus: WorkflowIssueStatus) => {
    if (issueStatus === WorkflowIssueStatus.None) {
      return "No Issues";
    } else if (issueStatus === WorkflowIssueStatus.Major) {
      return "Major Issues";
    } else if (issueStatus === WorkflowIssueStatus.Minor) {
      return "Minor Issues";
    } else {
      return "";
    }
  };
  const getIssueColor = (issueStatus: WorkflowIssueStatus) => {
    if (issueStatus === WorkflowIssueStatus.None) {
      return "primary";
    } else if (issueStatus === WorkflowIssueStatus.Major) {
      return "error";
    } else if (issueStatus === WorkflowIssueStatus.Minor) {
      return "warning";
    } else {
      return "primary";
    }
  };
  const getIssueIcon = (issueStatus: WorkflowIssueStatus) => {
    if (issueStatus === WorkflowIssueStatus.None) {
      return <Check />;
    } else if (issueStatus === WorkflowIssueStatus.Major) {
      return <ErrorOutline />;
    } else if (issueStatus === WorkflowIssueStatus.Minor) {
      return <WarningAmber />;
    } else {
      return <Check />;
    }
  };

  const textColor = selected ? palette.common.white : palette.common.black;

  return (
    <Box
      sx={{
        cursor: "pointer",
      }}
      width={NODE_WIDTH}
      bgcolor={selected ? palette.grayAccent.main : palette.common.white}
      border={`.0625rem solid ${palette.grayAccent.main}`}
      borderRadius=".375rem"
      boxShadow={`${palette.gray.main} 0 0 6px 0`}
    >
      <Box
        p={4}
        sx={{
          "&:hover": {
            bgcolor: selected
              ? lighten(palette.grayAccent.main, 0.05)
              : palette.grayAccent.lighter,
          },
        }}
      >
        {targetHandle && (
          <Handle type="target" position={targetHandlePosition} id="a" />
        )}
        {sourceHandle && (
          <Handle type="source" position={sourceHandlePosition} id="a" />
        )}

        {sourceHandleB && (
          <Handle type="source" position={sourceHandleB} id="b" />
        )}

        {targetHandleB && (
          <Handle type="target" position={targetHandleB} id="b" />
        )}

        <LinesEllipsisTypography
          color={textColor}
          maxLines={1}
          text={title}
          mb={2}
          variant="subtitle2"
        />
        <Box>
          {issueStatus && (
            <Chip
              color={getIssueColor(issueStatus)}
              sx={{ mr: 2 }}
              size="small"
              icon={getIssueIcon(issueStatus)}
              label={getLabelIssueStatus(issueStatus)}
            />
          )}
          {containsPii && (
            <Chip
              sx={{
                bgcolor: palette.common.white,
                color: palette.common.black,
                border: `.0625rem solid ${palette.common.black}`,
                mr: 2,
              }}
              size="small"
              color="default"
              avatar={<Person />}
              label="Contains PII"
            />
          )}
          <Typography color={textColor} variant="caption">
            {caption}
          </Typography>
        </Box>
        {bodyText && (
          <LinesEllipsisTypography
            color={textColor}
            mt={2}
            mb={2}
            maxLines={1}
            text={bodyText}
            variant="caption"
          />
        )}
        {footer}
      </Box>
      {(accordionSummaryContent || accordionDetailsContent) && (
        <Accordion
          disableGutters
          expanded={expand}
          onChange={(event, value) => {
            if (id) {
              store.addSelectedNodes([id]);
            }
            event.preventDefault();
          }}
          sx={{
            "&.MuiAccordion-root:before": {
              content: "none",
            },
            boxShadow: "none",
            borderTop: `.0625rem solid ${palette.outline}`,
            "&:hover": {
              bgcolor: palette.grayAccent.lighter,
            },
          }}
        >
          <AccordionSummary
            sx={{
              mt: 0,
              mb: 0,
              ".MuiAccordionSummary-content": {
                mt: 0,
                mb: 0,
              },
              pl: 4,
              pr: 4,
            }}
            onClick={toggleAcordion}
            expandIcon={<ExpandMore />}
          >
            {accordionSummaryContent}
          </AccordionSummary>
          <AccordionDetails
            sx={{
              "&.MuiAccordionDetails-root": {
                pt: 0,
                pb: 4,
                pl: 4,
                pr: 4,
              },
            }}
          >
            {accordionDetailsContent}
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
};

DataNode.defaultProps = {
  targetHandle: true,
  sourceHandle: true,
  targetHandlePosition: Position.Left,
  sourceHandlePosition: Position.Right,
};
