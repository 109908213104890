import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const Marketplace = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.27587 6.66675H14.725C14.9653 6.66672 15.2028 6.71866 15.4212 6.819C15.6395 6.91934 15.8336 7.06571 15.9901 7.24808C16.1466 7.43045 16.2618 7.64451 16.3278 7.87557C16.3938 8.10663 16.4091 8.34923 16.3725 8.58675L15.3267 15.3801C15.2359 15.9705 14.9367 16.5088 14.4834 16.8977C14.03 17.2866 13.4524 17.5003 12.855 17.5001H7.14504C6.54787 17.5001 5.97044 17.2863 5.51723 16.8974C5.06402 16.5086 4.76498 15.9703 4.67421 15.3801L3.62837 8.58675C3.59185 8.34923 3.60712 8.10663 3.67313 7.87557C3.73914 7.64451 3.85434 7.43045 4.01083 7.24808C4.16732 7.06571 4.36139 6.91934 4.57974 6.819C4.7981 6.71866 5.03557 6.66672 5.27587 6.66675Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 9.16667V5C7.5 4.33696 7.76339 3.70107 8.23223 3.23223C8.70107 2.76339 9.33696 2.5 10 2.5C10.663 2.5 11.2989 2.76339 11.7678 3.23223C12.2366 3.70107 12.5 4.33696 12.5 5V9.16667"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
