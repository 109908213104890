import { useParams } from "react-router-dom";
import { GetWorkspaceInfoDocument } from "../generated";
import { useQuery } from "@apollo/client";
import { useGetUserRole } from "./useGetUserRole";

export default function useGetWorkspace() {
  const { workspaceId } = useParams();
  const { accessible } = useGetUserRole(workspaceId)
  const { data: workspaceData } = useQuery(GetWorkspaceInfoDocument, {
    variables: {
      input: { workspaceId: workspaceId || "" },
      accessible: accessible
    },
  });

  const workspace = workspaceData?.workspace;
  return { workspace };
}
