import "ag-grid-enterprise";
import {
  ColDef,
  ColGroupDef,
  ColumnApi,
  GetQuickFilterTextParams,
  GridApi,
  ICellRendererParams,
  ValueGetterParams,
} from "ag-grid-enterprise";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-material.css";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Avatar, Box, IconButton, Typography, useTheme } from "@mui/material";
import { CorporateFare } from "@mui/icons-material";
import { ActionBarDataAssetCatalog } from "../../DataAssetCatalog/ActionBarDataAssetCatalog/ActionBarDataAssetCatalog";
import { AGGridWrapper } from "../../common/AGGridWrapper/AGGridWrapper";
import {
  FilterOperator,
  SpecificTextFilterOperator,
} from "../../common/AGGridWrapper/gridHelpers";
import { Tag } from "../../common/Tag/Tag";
import { GridFooter } from "../../common/AGGridWrapper/GridFooter";
import { useQuery } from "@apollo/client";
import { GetTargetSchemasDocument } from "../../generated";
import { GridReadyEvent } from "ag-grid-community";
import { caseInsensitiveSort, convertToTitleCase } from "../../helpers";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "../../common/Link/Link";
import { Chip } from "../../common/Chip/Chip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ActionsCellRenderer } from "./ActionCellRender";
import { SchemaName } from "./style";
export const ProjectSchemaGrid = () => {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const { workspaceId, projectId } = useParams();
  const [edit, setEdit] = useState<boolean>(false);
  const { data, loading } = useQuery(GetTargetSchemasDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
      projectFilter: {
        projectId: projectId || "",
      },
    },
  });

  const rowData = useMemo(() => {
    return (
      data?.workspace?.projects?.[0]?.targetSchemas?.map((asset) => ({
        ...asset,
      })) || []
    );
  }, [data?.workspace?.projects?.[0]?.targetSchemas]);

  const [columnDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      field: "name",
      headerName: "Name",
      width: 300,
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <SchemaName
            variant="body3"
            title={params.value}
            to={`/workspace/${workspaceId}/project/${projectId}/project-schema/${params?.data.id}`}
            tabIndex={-1}
          >
            {params.value}
          </SchemaName>
        );
      },
      comparator: caseInsensitiveSort,
      getQuickFilterText: (params: GetQuickFilterTextParams) => params.value,
      headerComponentParams: {
        description: "The name of the schema.",
        filterOperators: [
          SpecificTextFilterOperator.CONTAINS,
          SpecificTextFilterOperator.NOT_CONTAINS,
          SpecificTextFilterOperator.STARTS_WITH,
          SpecificTextFilterOperator.ENDS_WITH,
          FilterOperator.EQUALS,
          FilterOperator.NOT_EQUALS,
          FilterOperator.IS_EMPTY,
          FilterOperator.IS_NOT_EMPTY,
        ],
      },
    },
    {
      field: "description",
      headerName: "Description",
      width: 400,
      comparator: caseInsensitiveSort,
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <Typography
            variant="body3"
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              display: "inline-block",
              textOverflow: "ellipsis",
            }}
            title={params.value}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "types",
      headerName: "Type",
      valueGetter: (params: ValueGetterParams) => {
        return params.data.types[0];
      },
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["Input", "Output"],
      },
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value === null || params.value === undefined) return null;

        return (
          <Chip
            label={params.value}
            color={params.value === "OUTPUT" ? "warning" : "primary"}
          />
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value === null || params.value === undefined) return null;
        const value = params.value;
        return (
          <Chip
            label={params.value}
            color={
              value === "MAJOR"
                ? "error"
                : value === "MINOR"
                ? "warning"
                : "primary"
            }
          />
        );
      },
    },
    {
      field: "owner",
      editable: false,
      headerName: "Schema owner",
      valueGetter: (params: ValueGetterParams) => {
        return params.data.owner?.name;
      },
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value === null || params.value === undefined) return null;
        const ownerName = convertToTitleCase(params.data.owner?.name);
        if (ownerName === "") return "";
        return (
          <Box display="flex" alignItems="center">
            <CorporateFare
              sx={{
                color: palette.gray.dark,
                mr: ".3125rem",
                fontSize: ".875rem",
                width: ".875rem",
                height: ".875rem",
              }}
            />
            {ownerName}
          </Box>
        );
      },
      headerComponentParams: {
        description: "The organization that legally controls the data asset.",
        filterOperators: [
          SpecificTextFilterOperator.CONTAINS,
          FilterOperator.EQUALS,
          FilterOperator.NOT_EQUALS,
          FilterOperator.IS_ANY_OF,
          FilterOperator.IS_NONE_OF,
          FilterOperator.IS_EMPTY,
          FilterOperator.IS_NOT_EMPTY,
        ],
      },
    },
    {
      field: "managers",
      headerName: "Schema manager",
      valueGetter: (params: ValueGetterParams) => {
        const managers = params.data?.managers;
        if (Array.isArray(managers)) {
          return managers.reduce((accum, manger) => {
            if (accum === "") {
              return `${manger.firstName || ""} ${manger.lastName || ""}`;
            } else {
              return `${accum}, ${manger.firstName || ""} ${
                manger.lastName || ""
              }`;
            }
          }, "");
        } else {
          return "";
        }
      },
      cellRenderer: (params: ICellRendererParams) => {
        if (
          params.value === null ||
          params.value === undefined ||
          params.value === ""
        )
          return null;
        return (
          <Box display="flex" alignItems="center">
            <Avatar
              sx={{ height: "1rem", width: "1rem", fontSize: "1rem", mr: 1 }}
            />
            {params.value}
          </Box>
        );
      },
    },
    {
      field: "versionNumber",
      headerName: "Version",
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value === null || params.value === undefined) return null;

        return <Tag label={params.value} />;
      },
    },
    {
      field: "piiTypes",
      headerName: "PII Types",
      type: "tagsColumn",
      width: 200,
    },
    {
      field: "createdAt",
      type: "dateColumn",
      headerName: "Created",
      editable: false,
    },
    {
      field: "modifiedAt",
      type: "dateColumn",
      headerName: "Updated",
      cellEditor: "datePicker",
      editable: true,
    },
    {
      field: "tags",
      headerName: "Tags",
      type: "tagsColumn",
      width: 200,
    },

    {
      sortable: false,
      pinned: "right",
      field: "actions",
      headerName: "Actions",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <ActionsCellRenderer
            rowIndex={params.rowIndex}
            isEditable={true}
            id={params.data.id}
          />
        );
      },
      suppressMenu: true,
      width: 75,
    },
  ]);
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      editable: true,
    };
  }, []);

  const initialPaginationPageSize = 20;

  const [gridApi, setGridApi] = useState<GridApi>();
  const [columnApi, setColumnApi] = useState<ColumnApi>();
  const [paginationPageSize, setPaginationPageSize] = useState<number>(
    initialPaginationPageSize
  );
  const [paginationTotalPages, setPaginationTotalPages] = useState<number>(0);
  const [paginationCurrentPage, setPaginationCurrentPage] = useState<number>(0);
  const [searchText, setSearchText] = useState("");

  const onPaginationChanged = () => {
    if (gridApi) {
      setPaginationTotalPages(gridApi.paginationGetTotalPages());
      setPaginationCurrentPage(gridApi.paginationGetCurrentPage());
      setPaginationPageSize(gridApi.paginationGetPageSize());
    }
  };

  const onChangeSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchText(e.target.value);
    gridApi?.setQuickFilter(e.target.value);
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params?.api);
    setColumnApi(params?.columnApi);
    params?.api?.paginationSetPageSize(initialPaginationPageSize);
    params?.columnApi?.applyColumnState({
      state: [{ colId: "updated", sort: "asc" }],
    });
  };

  const [noResultsFoundFromFilter, setNoResultsFoundFromFilter] =
    useState(false);

  useEffect(() => {
    const onFilterChanged = ({ api }: { api: GridApi }) => {
      if (rowData.length > 0 && api.getDisplayedRowCount() === 0) {
        setNoResultsFoundFromFilter(true);
      } else {
        setNoResultsFoundFromFilter(false);
      }
    };
    gridApi?.addEventListener("filterChanged", onFilterChanged);
    return () => {
      gridApi?.removeEventListener("filterChanged", onFilterChanged);
    };
  }, [gridApi, rowData]);

  const onResetFilters = (gridApi: GridApi | undefined) => {
    gridApi?.setFilterModel(null);
    gridApi?.setQuickFilter("");
    setSearchText("");
  };

  return (
    <>
      <Box
        className="ag-theme-material"
        flexGrow={1}
        display="flex"
        flexDirection="column"
      >
        <ActionBarDataAssetCatalog
          hideChipFilter
          onChangeSearch={onChangeSearch}
          searchText={searchText}
          noResultsFoundFromFilter={noResultsFoundFromFilter}
          onResetFilters={() => onResetFilters(gridApi)}
          searchPlaceholder="Search name, description, or tags"
        />
        <Box flexGrow={1}>
          <AGGridWrapper
            onGridReady={onGridReady}
            rowData={rowData}
            columnDefs={columnDefs}
            onPaginationChanged={onPaginationChanged}
            loading={loading}
          />
        </Box>
        <GridFooter
          totalRows={rowData.length}
          gridApi={gridApi}
          paginationPageSize={paginationPageSize}
          paginationCurrentPage={paginationCurrentPage}
          paginationTotalPages={paginationTotalPages}
          displayNoResults={noResultsFoundFromFilter || rowData.length === 0}
        />
      </Box>
    </>
  );
};
