import React, { useState } from "react";
import { IconButton, useTheme } from "@mui/material";
import { Menu } from "../../common/Menu/Menu";
import { MenuItem } from "../../common/Menu/MenuItem";
import { MoreHoriz } from "@mui/icons-material";
import { AddDataAssetDialog } from "../AddDataAssetDialog/AddDataAssetDialog";
import { ContactManagerDialog } from "../ContactManagerDialog/ContactManagerDialog";
import { AccessStatus } from "../../generated";
import { useNavigate, useParams } from "react-router-dom";

export interface ActionsCellRendererProps {
  id: string;
  rowIndex: number;
  accessStatus: AccessStatus | undefined;
  isEditable: boolean;
}

export const ActionsCellRenderer = ({
  id,
  rowIndex,
  accessStatus,
  isEditable,
}: ActionsCellRendererProps) => {
  const navigate = useNavigate();
  const { workspaceId } = useParams();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onClickAddToProject = () => {
    setDialogOpen(true);
    handleClose();
  };
  const { palette } = useTheme();

  const onClickUpdate = () => {
    navigate(`/workspace/${workspaceId}/asset-details/${id}?edit=true`);
  };

  return (
    <>
      <IconButton
        sx={{
          color: palette.gray.dark,
        }}
        id={`icon-button-${rowIndex}`}
        onClick={handleClick}
        aria-controls={open ? `action-menu-${rowIndex}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id={`action-menu-${rowIndex}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": `icon-button-${rowIndex}`,
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
      >
        <MenuItem disabled={!isEditable} onClick={onClickUpdate}>
          Update
        </MenuItem>
      </Menu>
      {accessStatus === AccessStatus.Shared ? (
        <AddDataAssetDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          onSubmit={() => setDialogOpen(false)}
        />
      ) : (
        <ContactManagerDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
        />
      )}
    </>
  );
};
