import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Typography } from "../../common/Typography/Typography";
import { Tag } from "../../common/Tag/Tag";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";
import { DOCLink } from "../../common/Link/Link";
import SyncIcon from "@mui/icons-material/Sync";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(4),
  borderTop: `1px solid ${theme.palette.action.disabledBackground}`,
}));

const JOBS = [
  {
    id: "1",
    name: "Job name",
    description: "Dag that does a thing",
    usage: "In-use",
    projects: [
      { name: "Project name" },
      { name: "Project name" },
      { name: "Project name" },
    ],
  },
  {
    id: "2",
    name: "Job name 2",
    description: "Dag that does a thing",
    usage: "In-use",
    projects: [
      { name: "Project name" },
      { name: "Project name" },
      { name: "Project name" },
    ],
  },
];

export function EngineJobs() {
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <div>
      {JOBS.map((item) => (
        <Accordion
          expanded={expanded === item.id}
          onChange={handleChange(item.id)}
        >
          <AccordionSummary>
            <Box>
              <Typography variant="subtitle3">{item.name}</Typography>
              <Box display="flex" mt={1} gap={2} alignItems="center">
                <Tag
                  startIcon={<SyncIcon />}
                  fontSize="small"
                  label={item.usage}
                />
                <Typography variant="caption">{item.id}</Typography>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <Typography mb={2} variant="subtitle2">
                Job details
              </Typography>
              <Typography mb={1} variant="body3">
                <Box component="span" fontWeight="bold">
                  Job name:
                </Box>{" "}
                {item.name}
              </Typography>
              <Typography mb={1} variant="body3">
                <Box component="span" fontWeight="bold">
                  Job ID:
                </Box>{" "}
                {item.id}
              </Typography>
              <Typography mb={2} variant="body3">
                <Box component="span" fontWeight="bold">
                  Job description:
                </Box>{" "}
                {item.description}
              </Typography>
              <DOCLink name="View source" href="/" />

              <Box mt={2}>
                <Typography mb={1} variant="subtitle2">
                  Project usage
                </Typography>
                <Tag
                  startIcon={<SyncIcon />}
                  fontSize="small"
                  label={item.usage}
                />
              </Box>

              <Box mt={4}>
                <Typography mb={1} variant="subtitle3">
                  Projects (3)
                </Typography>
                {item.projects.map((project) => (
                  <DOCLink name={project.name} href="/" />
                ))}
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
