import { styled, Box, IconButton, Typography, Button } from "@mui/material";
import { brighthiveTheme } from "../theme/theme";

export const MemberHead = styled<any>(Box)`
  flex-grow: 0;
  border-width: 0.0625rem 0.0625rem 0 0.0625rem;
  border-color: ${brighthiveTheme.palette.outline as any};
  border-style: solid;
  border-radius: 0.25rem 0.25rem 0 0;
`;
export const MemberBlock = styled(Box)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
export const MemberPageContent = styled(Box)`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex-grow: 1;
`;
export const UpDateRole = styled(Box)`
  width: 35rem;
  box-sizing: border-box;
`;

export const UpDateRoleContent = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;
