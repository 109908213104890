import React from "react";
import { Column, GridApi } from "ag-grid-enterprise";
import { PopoverProps, } from "@mui/material";
import { allDefaultTextFilterOperators, SpecificTextFilterOperator, TextFilterOperator, } from "./gridHelpers";
import { useFiltersWithGrid } from "./useFiltersWithGrid";
import { TextPopoverFilterUI } from "./TextPopoverFilterUI";

interface SpecificTextPopoverFilterProps {
  displayName: string;
  description?: string;
  column: Column;
  gridApi: GridApi;
  filterOperators: TextFilterOperator[];
}

export type TextPopoverFilterProps = SpecificTextPopoverFilterProps &
  PopoverProps;

export const TextPopoverFilter = ({
  gridApi,
  column,
  displayName,
  description,
  filterOperators,
  ...rest
}: TextPopoverFilterProps) => {

  const initialFilterOperator = filterOperators.includes(
    SpecificTextFilterOperator.CONTAINS
  )
    ? SpecificTextFilterOperator.CONTAINS
    : filterOperators[0];

  const {
    filterText,
    currentFilterOperator,
    uniqueFilterValues,
    selectedUniqueFilterValues,
    onChangeFilterOperator,
    onChangeSelectedUniqueFilterValues,
    onChangeFilterText,
    onClickRefreshFilter,
  } = useFiltersWithGrid(filterOperators, column, gridApi, initialFilterOperator, "text");

  if (filterOperators.length === 0) {
    return <div>No filter options provided</div>;
  }

  return (
    <TextPopoverFilterUI
      {...rest}
      displayName={displayName}
      description={description}
      currentFilterOperator={currentFilterOperator}
      onChangeFilterOperator={onChangeFilterOperator}
      filterOperators={filterOperators}
      selectedUniqueFilterValues={selectedUniqueFilterValues}
      onChangeSelectedUniqueFilterValues={onChangeSelectedUniqueFilterValues}
      uniqueFilterValues={uniqueFilterValues}
      filterText={filterText}
      onChangeFilterText={onChangeFilterText}
      onClickRefreshFilter={onClickRefreshFilter}
    />
  );
};

TextPopoverFilter.defaultProps = {
  filterOperators: allDefaultTextFilterOperators,
};
