import React from "react";
import { TypographyOptions } from "@mui/material/styles/createTypography";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    subtitle3: React.CSSProperties;
    body3: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    subtitle3?: React.CSSProperties;
    body3?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    subtitle3: true;
    body3: true;
  }
}

export const typography: TypographyOptions = {
  fontFamily: "Roboto",
  h1: {
    fontWeight: 300,
    fontSize: "3rem",
    lineHeight: "3.75rem",
    letterSpacing: 0,
  },
  h2: {
    fontWeight: "normal",
    fontSize: "2.5rem",
    lineHeight: "3.25rem",
    letterSpacing: 0,
  },
  h3: {
    fontWeight: "normal",
    fontSize: "2rem",
    lineHeight: "2.75rem",
    letterSpacing: "0.0025em",
  },
  h4: {
    fontWeight: "normal",
    fontSize: "1.625rem",
    lineHeight: "2rem",
    letterSpacing: 0,
  },
  h5: {
    fontWeight: 500,
    fontSize: "1.5rem",
    lineHeight: "1.75rem",
    letterSpacing: "0.0015em",
  },
  h6: {
    fontWeight: 500,
    fontSize: "1.125rem",
    lineHeight: "1.5rem",
    letterSpacing: "0.0025em",
  },
  overline: {
    fontWeight: "bold",
    fontSize: ".75rem",
    lineHeight: ".875rem",
    letterSpacing: ".08em",
    textTransform: "uppercase",
  },
  subtitle1: {
    fontWeight: "normal",
    fontSize: "1.125rem",
    lineHeight: "1.5rem",
    letterSpacing: 0,
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "1.5rem",
    letterSpacing: "0.0025em",
  },
  subtitle3: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: ".875rem",
    lineHeight: "1.25rem",
    letterSpacing: "0.0025em",
  },
  body1: {
    fontWeight: "normal",
    fontSize: "1.125rem",
    lineHeight: "1.75rem",
    letterSpacing: 0,
  },
  body2: {
    fontWeight: "normal",
    fontSize: "1rem",
    lineHeight: "1.5rem",
    letterSpacing: 0,
  },
  body3: {
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontSize: ".875rem",
    lineHeight: "1.25rem",
    letterSpacing: "0.0025em",
  },
  caption: {
    fontWeight: 500,
    fontSize: ".75rem",
    lineHeight: "1.25rem",
    letterSpacing: "0.01em",
  },
  button: {
    fontWeight: 500,
    fontSize: ".875rem",
    lineHeight: "1.25rem",
    letterSpacing: "0.02em",
    textTransform: "none",
  },
};
