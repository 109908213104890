export const getColorByUUID = (uuid: string) => {
  const BH_COLORS = ["009e83", "53c8e7", "cc7e9c", "e79023"];
  const cleanedUuid = uuid.replace(/-/g, "");
  const index = _modUuid(_reduceUuidLength(cleanedUuid), BH_COLORS.length);

  return `#${BH_COLORS[index]}`;
};

export const _modUuid = (uuid: string, amount: number) => {
  const number = parseInt(uuid, 16);
  return number % amount;
};

export const _reduceUuidLength = (uuid: string | any[]) => {
  let total = "";
  for (let i = 0; i < uuid.length; i = i + 2) {
    total = total + uuid[i + 1];
    if (total.length >= 13) break;
  }
  return total;
};

export const generateGradientColors = (
  endColor: any,
  startColor: any,
  numOfGradients: number
) => {
  const removeHashInHex = (str: string) =>
    str.charAt(0) === "#" ? str.substring(1, 7) : str;

  const convertToRGB = (hex: any) => {
    let color = [];

    color[0] = parseInt(removeHashInHex(hex).substring(0, 2), 16);
    color[1] = parseInt(removeHashInHex(hex).substring(2, 4), 16);
    color[2] = parseInt(removeHashInHex(hex).substring(4, 6), 16);

    return color;
  };

  let start = convertToRGB(startColor);
  let end = convertToRGB(endColor);
  var alpha = 0.0;
  var gradientList = [];

  const hex = (c: any) => {
    let s = "0123456789abcdef";
    let i = parseInt(c);
    if (i === 0 || isNaN(c)) return "00";
    i = Math.round(Math.min(Math.max(0, i), 255));

    return s.charAt((i - (i % 16)) / 16) + s.charAt(i % 16);
  };

  const convertRGBTripletToHex = (rgb: any[]) =>
    hex(rgb[0]) + hex(rgb[1]) + hex(rgb[2]);

  for (let i = 0; i < numOfGradients; i++) {
    let c = [];
    alpha += 1.0 / numOfGradients;

    c[0] = start[0] * alpha + (1 - alpha) * end[0];
    c[1] = start[1] * alpha + (1 - alpha) * end[1];
    c[2] = start[2] * alpha + (1 - alpha) * end[2];

    gradientList.push(convertRGBTripletToHex(c));
  }
  return gradientList;
};
