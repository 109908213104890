import React from "react";
import { Box, BoxProps } from "@mui/material";
import { LeftRightHolder } from "./style";

export interface SpecificLeftRightContentProps {
  leftElements: React.ReactNode;
  rightElements: React.ReactNode;
  leftContainerProps?: BoxProps;
  rightContainerProps?: BoxProps;
}

export type LeftRightContentProps = BoxProps & SpecificLeftRightContentProps;

export const LeftRightContent = ({
  leftElements,
  rightElements,
  leftContainerProps,
  rightContainerProps,
  ...rest
}: LeftRightContentProps) => {
  return (
    <LeftRightHolder
      flexGrow={1}
      alignItems="center"
      justifyContent="space-between"
      {...rest}
    >
      <Box className="left-elements" {...leftContainerProps}>
        {leftElements}
      </Box>
      <Box
        className="right-elements"
        {...rightContainerProps}
        sx={{
          flexShrink: "0",

          "> .MuiTypography-root.MuiTypography-button": {
            marginLeft: "12px",
          },

          "> button": {
            "&:nth-child(1), &:nth-child(2)": {
              marginLeft: "12px",
            },
          },
        }}
      >
        {rightElements}
      </Box>
    </LeftRightHolder>
  );
};
