import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, Paper, Typography, useTheme } from "@mui/material";

interface ViewSelectedSchemaProps {
  name: string;
  onDelete: any;
}

export const ViewSelectedSchema = ({
  name,
  onDelete,
}: ViewSelectedSchemaProps) => {
  const { palette } = useTheme();
  return (
    <Box
      mb={4}
      sx={{
        background: palette.settings.lighter,
        borderRadius: "4px",
        padding: "10px",
        paddingBottom: "3px",
      }}
    >
      <Typography
        variant="body1"
        mb={1}
        fontSize="14px"
        fontWeight={500}
        color={palette.common.black}
      >
        Schema template
      </Typography>
      <Grid item xs={12} mb={2} sx={{ minWidth: "360px" }}>
        <Paper
          variant="outlined"
          sx={{
            p: 2,
            display: "flex",
            minHeight: "100%",
            boxSizing: "border-box",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            display="flex"
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              mr={3}
              width="1.8rem"
              sx={{
                ".MuiSvgIcon-root": {
                  minWidth: "2rem",
                  width: "2rem",
                  height: "1.5rem",
                  path: {
                    fill: palette.grayAccent.light,
                  },
                },
              }}
            >
              <AutoFixHighIcon />
            </Box>
            <Typography
              variant="subtitle2"
              color={palette.common.black}
              sx={{
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              {name}
            </Typography>
          </Box>
          <CloseIcon
            color="grayAccent"
            sx={{ cursor: "pointer" }}
            fontSize="small"
            onClick={onDelete}
          />
        </Paper>
      </Grid>
    </Box>
  );
};
