import React from "react";
import {
  WorkflowDataAssetGroupOutput,
  WorkflowIssueStatus,
} from "../generated";
import { DataBox, Status } from "./DataBox";
import { DataNode } from "./DataNode";
import { Typography } from "../common/Typography/Typography";
import { Chip } from "../common/Chip/Chip";

export interface FinalDataProductNodeProps {
  data: WorkflowDataAssetGroupOutput & {
    onSubNodeClick: (id: string, parentId: string) => void;
  };
  selected?: boolean;
  id?: string;
}

export const FinalDataProductNode = ({
  data: { dataAssets, name, issueStatus, pii, onSubNodeClick },
  selected,
  id,
}: FinalDataProductNodeProps) => {
  const getStatus = (issueStatus: WorkflowIssueStatus | undefined) => {
    if (issueStatus === WorkflowIssueStatus.Minor) {
      return Status.Warning;
    } else if (issueStatus === WorkflowIssueStatus.None) {
      return Status.Success;
    } else if (issueStatus === WorkflowIssueStatus.Major) {
      return Status.Error;
    } else {
      return undefined;
    }
  };
  const status = getStatus(issueStatus);

  return (
    <DataNode
      id={id}
      selected={selected}
      title={name}
      issueStatus={issueStatus}
      containsPii={pii}
      accordionSummaryContent={
        <>
          <Typography mr={2} variant="body3">
            Final data products
          </Typography>
          <Chip
            color={dataAssets.length === 0 ? "gray" : "grayAccent"}
            size="small"
            label={dataAssets.length || 0}
          />
        </>
      }
      accordionDetailsContent={
        <>
          {dataAssets.map((dataAsset) => (
            <DataBox
              mb={2}
              title={dataAsset?.name}
              caption={dataAsset?.ownerName}
              status={status}
              onSubNodeClick={() =>
                dataAsset && onSubNodeClick(dataAsset.id, id || "")
              }
            />
          ))}
        </>
      }
    />
  );
};
