import React from "react";
import { FormLabel as MUIFormLabel, FormLabelProps, useTheme } from "@mui/material";

export const FormLabel = ({ sx, ...rest }: FormLabelProps) => {
  const { typography, palette } = useTheme();

  return (
    <MUIFormLabel sx={{
      mb: 2,
      ...typography.body3,
      fontWeight: 500,
      color: palette.common.black,
      "&.Mui-focused": {
        color: palette.common.black,
      },
      ...sx
    }} {...rest}/>
  );
};
