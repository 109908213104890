import { Box, Drawer, Tab, IconButton } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { StatusCell } from "../CellRenderer/StatusCell";
import { EngineConfiguration } from "./EngineConfiguration";
import { Tabs } from "../../common/Tabs/Tabs";
import { useState } from "react";
import { EngineJobs } from "./EngineJobs";
import Close from "@mui/icons-material/Close";

interface EngineDetailProps {
  open: boolean;
  onClose: () => void;
}

export const EngineDetail = ({ open, onClose }: EngineDetailProps) => {
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box width={400} p={8}>
        <Box position="relative" mb={4}>
          <Box position="absolute" top={-10} right={0}>
            <IconButton onClick={() => onClose()} size="small">
              <Close />
            </IconButton>
          </Box>
          <Typography variant="overline">ENGINE</Typography>
          <Typography mt={1} mb={3} variant="h5">
            source-name
          </Typography>
          <StatusCell value="Connected" />
        </Box>

        <Box
          sx={{
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            fontWeight: "bold",
          }}
          mt={4}
          mb={4}
        >
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            textColor="inherit"
          >
            <Tab label="Configuration" />
            <Tab label="Tab 2" />
          </Tabs>
        </Box>

        <Box mt={6}>
          {value === 0 && <EngineConfiguration />}
          {value === 1 && <EngineJobs />}
        </Box>
      </Box>
    </Drawer>
  );
};
