import React from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Alert,
} from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { Button } from "../../common/Button/Button";

interface SpecificAddDataAssetDialogProps {
  onClose: () => void;
  onSubmit: () => void;
}

type ReplaceApiKeyDialogProps = DialogProps & SpecificAddDataAssetDialogProps;

export const ReplaceApiKeyDialog = (props: ReplaceApiKeyDialogProps) => {
  const onClickCancel = () => {
    if (props.onClose) props.onClose();
  };

  return (
    <Dialog {...props} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography mb={4} variant="h5">
          Replace existing API key?
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Alert severity="warning">
          This action will cause any applications using the existing key to lose
          connection.
        </Alert>
        <Box mt={4} mb={6}>
          <Typography variant="body2">
            Are you sure you want to{" "}
            <Box component="span" fontWeight="bold">
              replace
            </Box>{" "}
            the existing API key for the engine,{" "}
            <Box component="span" fontWeight="bold">
              source-name
            </Box>
            ?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickCancel} color="grayAccent" variant="outlined">
          Cancel
        </Button>
        <Button
          color="error"
          variant="contained"
          onClick={() => props.onSubmit()}
        >
          I understand - replace API key
        </Button>
      </DialogActions>
    </Dialog>
  );
};
