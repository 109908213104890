import React from "react";
import { Box, Divider, useTheme } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { Typography } from "../../common/Typography/Typography";
import { BasicCard } from "../../common/BasicCard/BasicCard";
import { Label } from "../../common/Label/Label";
import { Button } from "../../common/Button/Button";
import { DOCLink } from "../../common/Link/Link";
import { GetGovernanceInfoQuery } from "../../generated";
import { convertToSentenceCase } from "../../helpers";
import { ButtonDefault } from "./style";

export const WorkspaceDetail = ({
  data,
  onEdit,
  ...rest
}: {
  data: GetGovernanceInfoQuery;
  onEdit: () => void;
}) => {
  const { palette } = useTheme();
  const workspace = data?.workspace;
  return (
    <BasicCard {...rest}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ padding: ".75rem 1.5rem" }}
      >
        <Typography variant={"h6"}>Workspace details</Typography>
        {data?.workspace?.governance?.isEditable ? (
          <ButtonDefault
            onClick={onEdit}
            size={"small"}
            startIcon={<EditOutlinedIcon />}
          >
            Edit
          </ButtonDefault>
        ) : null}
      </Box>
      <Divider />
      <Box sx={{ padding: "1.5rem 2rem" }}>
        <Box
          display={"flex"}
          pb={"1.5rem"}
          alignItems={"flex-start"}
          maxWidth={"58rem"}
        >
          <Label value="Workspace name" width={"15.5rem"} />
          <Typography variant="body2" color={palette.gray.dark}>
            {workspace?.name}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          pb={"1.5rem"}
          alignItems={"flex-start"}
          maxWidth={"58rem"}
        >
          <Label value="Description" width={"15.5rem"} />
          <Typography variant="body2" color={palette.gray.dark}>
            {workspace?.description}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          pb={"1.5rem"}
          alignItems={"flex-start"}
          maxWidth={"58rem"}
        >
          <Label value="Governance type" width={"15.5rem"} />
          <Box>
            <Typography variant="body2" color={palette.gray.dark}>
              {convertToSentenceCase(
                workspace?.governance?.governanceType.split("_").join(" ")
              )}
            </Typography>
          </Box>
        </Box>

        <Box
          display={"flex"}
          pb={"1.5rem"}
          alignItems={"flex-start"}
          maxWidth={"58rem"}
        >
          <Label value="Goals" width={"15.5rem"} />
          <Box>
            {data?.workspace?.governance?.goals?.map((goal) => (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="body2">{goal?.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body3" mb={3} color={palette.gray.dark}>
                    {goal?.description}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Box>
        <Divider sx={{ mb: 10, mt: 4 }} />
        <Box
          display={"flex"}
          pb={"1.5rem"}
          alignItems={"flex-start"}
          maxWidth={"58rem"}
        >
          <Label value="Legal documents" width={"15.5rem"} />
          <Box>
            {data?.workspace?.governance?.legalDocuments?.map((doc) => (
              <DOCLink
                key={doc?.id}
                name={doc?.name || ""}
                href={doc?.href || ""}
                target="_blank"
              />
            ))}
          </Box>
        </Box>
        <Box
          display={"flex"}
          pb={"1.5rem"}
          alignItems={"flex-start"}
          maxWidth={"58rem"}
        >
          <Label value="Reference documents" width={"15.5rem"} />
          <Box>
            {data?.workspace?.governance?.referenceDocuments?.map((doc) => (
              <DOCLink
                key={doc?.id}
                name={doc?.name || ""}
                href={doc?.href || ""}
                target="_blank"
              />
            ))}
          </Box>
        </Box>
      </Box>
    </BasicCard>
  );
};
