import { styled, Box, IconButton, Typography, Button, Tab } from '@mui/material';
import { ArrowBack } from "@mui/icons-material";
import { brighthiveTheme } from "../../theme/theme";
import { Tabs } from "../../common/Tabs/Tabs";

export const TabsWrapper = styled(Tabs)`
  margin-bottom: 32px;

  .MuiTabs-indicator{
    height: 2px;
    background-color: ${brighthiveTheme.palette.primary.dark};
  }
`;

export const TabButton = styled(Tab)`
  font-size: 16px;
  line-height: 1.42;
  padding: 12px 24px !important;
  min-width: 1px;
  color: ${brighthiveTheme.palette.gray.dark};
  background-color: transparent;
  border-radius: 4px 4px 0 0;

  &.Mui-selected,
  &:hover{
    background-color: transparent !important;
    color: ${brighthiveTheme.palette.primary.dark};
  }
`;

export const ButtonPrimary = styled(Button)`
  background-color: ${brighthiveTheme.palette.primary.main};
  color: ${brighthiveTheme.palette.primary.darkest};
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: none;
  min-width: 1px;

  &:hover{
    background-color: ${brighthiveTheme.palette.primary.dark};
    color: ${brighthiveTheme.palette.common.white};
    border-color: transparent;
    box-shadow: none;
  }

  svg{
    width: 18px;
    height: 18px;
  }
`;