import React from "react";
import { Button } from "../Button/Button";
import { Link } from "../Link/Link";
import { Typography } from "../Typography/Typography";
import { alpha, useTheme } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ButtonAdd, IconAddWrap, LabelText, LinkButton, SideNavLink } from "./style";
import { PlusCircleViiIcon } from "../Icons/PlusCircleViiIcon";

interface SidenavButtonLinkProps {
  label: React.ReactNode;
  icon?: React.ReactNode;
  active?: boolean;
  to: string;
  disabled: boolean;
  className?: string;
  add?: boolean;
}

export const SidenavButtonLink = ({
  label,
  icon,
  active,
  to,
  disabled,
  className,
  add,
}: SidenavButtonLinkProps) => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  return (
    <>
      <SideNavLink
        to={to}
        className={`${className} ${disabled ? "disabled" : ""}`}
      >
        <LinkButton
          disabled={disabled}
          startIcon={icon}
          variant="text"
        >
          <LabelText variant="body3">
            <Tooltip
              placement="bottom"
              title={label}
              componentsProps={{
                tooltip: { sx: { backgroundColor: palette.common.black } },
                arrow: { sx: { color: palette.common.black } },
              }}
              arrow
            >
              <span className="sidebutton-text cs-hidden-text">{label}</span>
            </Tooltip>
          </LabelText>
        </LinkButton>
      </SideNavLink>
      {add && (
        <ButtonAdd
          onClick={() =>
            navigate(to, {
              state: {
                add: true,
              },
            })
          }
        >
          <IconAddWrap>
            <PlusCircleViiIcon />
          </IconAddWrap>
          {label}
        </ButtonAdd>
      )}
    </>
  );
};

SidenavButtonLink.defaultProps = {
  disabled: false,
};
