import "ag-grid-enterprise";
import {
  ColDef,
  ColGroupDef,
  ColumnApi,
  GetQuickFilterTextParams,
  GridApi,
  ICellRendererParams,
  ValueGetterParams,
} from "ag-grid-enterprise";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-material.css";
import React, { useEffect, useMemo, useState } from "react";
import { Avatar, Box, useTheme } from "@mui/material";
import { CorporateFare } from "@mui/icons-material";
import { ActionBarDataAssetCatalog } from "../../DataAssetCatalog/ActionBarDataAssetCatalog/ActionBarDataAssetCatalog";
import { AGGridWrapper } from "../../common/AGGridWrapper/AGGridWrapper";
import { Link } from "../../common/Link/Link";
import {
  FilterOperator,
  SpecificNumberFilterOperator,
  SpecificTextFilterOperator,
} from "../../common/AGGridWrapper/gridHelpers";
import { Tag } from "../../common/Tag/Tag";
import { GridFooter } from "../../common/AGGridWrapper/GridFooter";
import { useQuery } from "@apollo/client";
import { GetProjectDataAssetDocument } from "../../generated";
import { GridReadyEvent } from "ag-grid-community";
import { convertToTitleCase } from "../../helpers";
import { useParams } from "react-router-dom";
import RightSidePanel from "../../common/RightSidePanel";
import { Sheets } from "../../Sheets";
import { NodeType } from "../../ProjectWorkflow/helpers";

export const DataAssetCatalogGrid = () => {
  const { palette } = useTheme();
  const { workspaceId, projectId } = useParams();
  const [detail, setDetail] = useState<any>(undefined);

  const { data, loading } = useQuery(GetProjectDataAssetDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
      projectFilter: {
        projectId: projectId || "",
      },
    },
  });

  const rowData = useMemo(() => {
    return (
      data?.workspace?.projects?.[0]?.inputDataAssets?.map((asset) => ({
        ...asset,
      })) || []
    );
  }, [data?.workspace]);
  const [columnDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      field: "name",
      headerName: "Data asset name",
      width: 350,
      getQuickFilterText: (params: GetQuickFilterTextParams) => params.value,
      headerComponentParams: {
        description: "The name of the data asset.",
        filterOperators: [
          SpecificTextFilterOperator.CONTAINS,
          SpecificTextFilterOperator.NOT_CONTAINS,
          SpecificTextFilterOperator.STARTS_WITH,
          SpecificTextFilterOperator.ENDS_WITH,
          FilterOperator.EQUALS,
          FilterOperator.NOT_EQUALS,
          FilterOperator.IS_EMPTY,
          FilterOperator.IS_NOT_EMPTY,
        ],
      },
    },

    {
      field: "owner",
      headerName: "Asset owner",
      valueGetter: (params: ValueGetterParams) => {
        return params.data.owner?.name;
      },
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value === null || params.value === undefined) return null;
        const ownerName = convertToTitleCase(params.data.owner?.name);
        if (ownerName === "") return "";
        return (
          <Box display="flex" alignItems="center">
            <CorporateFare
              sx={{
                color: palette.gray.dark,
                mr: ".3125rem",
                fontSize: ".875rem",
                width: ".875rem",
                height: ".875rem",
              }}
            />
            {ownerName}
          </Box>
        );
      },
      headerComponentParams: {
        description: "The organization that legally controls the data asset.",
        filterOperators: [
          SpecificTextFilterOperator.CONTAINS,
          FilterOperator.EQUALS,
          FilterOperator.NOT_EQUALS,
          FilterOperator.IS_ANY_OF,
          FilterOperator.IS_NONE_OF,
          FilterOperator.IS_EMPTY,
          FilterOperator.IS_NOT_EMPTY,
        ],
      },
    },
    {
      field: "managers",
      headerName: "Asset manager",
      valueGetter: (params: ValueGetterParams) => {
        const managers = params.data?.managers;
        if (Array.isArray(managers)) {
          return managers.reduce((accum, manger) => {
            if (accum === "") {
              return `${manger.firstName || ""} ${manger.lastName || ""}`;
            } else {
              return `${accum}, ${manger.firstName || ""} ${
                manger.lastName || ""
              }`;
            }
          }, "");
        } else {
          return "";
        }
      },
      cellRenderer: (params: ICellRendererParams) => {
        if (
          params.value === null ||
          params.value === undefined ||
          params.value === ""
        )
          return null;
        return (
          <Box display="flex" alignItems="center">
            <Avatar
              sx={{ height: "1rem", width: "1rem", fontSize: "1rem", mr: 1 }}
            />
            {params.value}
          </Box>
        );
      },
    },
    {
      field: "sensitivity",
      headerName: "Sensitivity",
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value === null || params.value === undefined) return null;
        return (
          <Box display="flex" alignItems="center" flexWrap="wrap" gap={1}>
            <Tag label={params.value} dismissable={false} />
          </Box>
        );
      },
    },

    {
      field: "description",
      headerName: "Description",
    },
    {
      field: "tags",
      headerName: "Tags",
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value === null || params.value === undefined) return null;
        const items = (params.value as string[]) || [];
        return (
          <Box display="flex" alignItems="center" flexWrap="nowrap" gap={1}>
            {items.map((item) => (
              <Tag label={item} dismissable={false} />
            ))}
          </Box>
        );
      },
    },
    {
      field: "refreshRate",
      headerName: "Data refresh",
    },
    {
      field: "connectionType",
      headerName: "Connection type",
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value === "Project") {
          return (
            <Link
              variant="body3"
              fontWeight={700}
              to="/#test-url"
              tabIndex={-1}
            >
              {params.value}
            </Link>
          );
        } else {
          return params.value;
        }
      },
    },
  ]);

  const initialPaginationPageSize = 20;

  const [gridApi, setGridApi] = useState<GridApi>();
  const [columnApi, setColumnApi] = useState<ColumnApi>();
  const [paginationPageSize, setPaginationPageSize] = useState<number>(
    initialPaginationPageSize
  );
  const [paginationTotalPages, setPaginationTotalPages] = useState<number>(0);
  const [paginationCurrentPage, setPaginationCurrentPage] = useState<number>(0);
  const [searchText, setSearchText] = useState("");

  const onPaginationChanged = () => {
    if (gridApi) {
      setPaginationTotalPages(gridApi.paginationGetTotalPages());
      setPaginationCurrentPage(gridApi.paginationGetCurrentPage());
      setPaginationPageSize(gridApi.paginationGetPageSize());
    }
  };

  const onChangeSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchText(e.target.value);
    gridApi?.setQuickFilter(e.target.value);
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params?.api);
    setColumnApi(params?.columnApi);
    params?.api?.paginationSetPageSize(initialPaginationPageSize);
    params?.columnApi?.applyColumnState({
      state: [{ colId: "updated", sort: "asc" }],
    });
  };

  const [noResultsFoundFromFilter, setNoResultsFoundFromFilter] =
    useState(false);

  useEffect(() => {
    const onFilterChanged = ({ api }: { api: GridApi }) => {
      if (rowData.length > 0 && api.getDisplayedRowCount() === 0) {
        setNoResultsFoundFromFilter(true);
      } else {
        setNoResultsFoundFromFilter(false);
      }
    };
    gridApi?.addEventListener("filterChanged", onFilterChanged);
    return () => {
      gridApi?.removeEventListener("filterChanged", onFilterChanged);
    };
  }, [gridApi, rowData]);

  const [assetsInUseFilterActive, setAssetsInUseFilterActive] = useState(false);
  const [assetIssuesFilterActive, setAssetIssuesFilterActive] = useState(false);

  const onClickAssetsIssuesFilter = () => {
    const newAssetIssuesFilterActive = !assetIssuesFilterActive;
    setAssetIssuesFilterActive(newAssetIssuesFilterActive);
    const filterInstance = gridApi?.getFilterInstance("issues");
    if (newAssetIssuesFilterActive) {
      filterInstance?.setModel({
        filterType: "text",
        type: SpecificNumberFilterOperator.IS_NOT_EMPTY,
        filter: "|||",
      });
    } else {
      filterInstance?.setModel(null);
    }
    gridApi?.onFilterChanged();
  };

  const onClickAssetsInUseFilter = () => {
    const newAssetsInUseFilterActive = !assetsInUseFilterActive;
    setAssetsInUseFilterActive(newAssetsInUseFilterActive);
    const filterInstance = gridApi?.getFilterInstance("accessStatus");
    if (newAssetsInUseFilterActive) {
      filterInstance?.setModel({
        filterType: "text",
        type: FilterOperator.EQUALS,
        filter: "Shared",
      });
    } else {
      filterInstance?.setModel(null);
    }
    gridApi?.onFilterChanged();
  };

  const onResetFilters = (gridApi: GridApi | undefined) => {
    gridApi?.setFilterModel(null);
    gridApi?.setQuickFilter("");
    setSearchText("");
  };

  useEffect(() => {
    const syncAccessStatusFilterWithExternalFilter = ({
      api,
    }: {
      api: GridApi;
    }) => {
      const filterInstance = api?.getFilterInstance("accessStatus");
      const currentModel = filterInstance?.getModel();
      if (
        currentModel?.type === FilterOperator.EQUALS &&
        typeof currentModel?.filter === "string" &&
        currentModel.filter.toLowerCase() === "shared"
      ) {
        setAssetsInUseFilterActive(true);
      } else {
        setAssetsInUseFilterActive(false);
      }
    };

    const syncIssuesFilterWithExternalFilter = ({ api }: { api: GridApi }) => {
      const filterInstance = api?.getFilterInstance("issues");
      const currentModel = filterInstance?.getModel();
      if (currentModel?.type === SpecificNumberFilterOperator.IS_NOT_EMPTY) {
        setAssetIssuesFilterActive(true);
      } else {
        setAssetIssuesFilterActive(false);
      }
    };

    const accessStatusColumn = columnApi?.getColumn("accessStatus");
    const issuesColumn = columnApi?.getColumn("issues");
    accessStatusColumn?.addEventListener(
      "filterChanged",
      syncAccessStatusFilterWithExternalFilter
    );
    issuesColumn?.addEventListener(
      "filterChanged",
      syncIssuesFilterWithExternalFilter
    );
    return () => {
      accessStatusColumn?.removeEventListener(
        "filterChanged",
        syncAccessStatusFilterWithExternalFilter
      );
      issuesColumn?.removeEventListener(
        "filterChanged",
        syncIssuesFilterWithExternalFilter
      );
    };
  }, [columnApi]);

  return (
    <>
      <Box
        className="ag-theme-material"
        flexGrow={1}
        display="flex"
        flexDirection="column"
      >
        <ActionBarDataAssetCatalog
          hideChipFilter={true}
          onChangeSearch={onChangeSearch}
          searchText={searchText}
          noResultsFoundFromFilter={noResultsFoundFromFilter}
          onClickAssetsIssuesFilter={onClickAssetsIssuesFilter}
          onClickAssetsInUseFilter={onClickAssetsInUseFilter}
          onResetFilters={() => onResetFilters(gridApi)}
          searchPlaceholder="Search name, description, or tags"
        />
        <Box flexGrow={1}>
          <AGGridWrapper
            onGridReady={onGridReady}
            rowData={rowData}
            columnDefs={columnDefs}
            onPaginationChanged={onPaginationChanged}
            loading={loading}
            onRowClicked={(row) => setDetail(row.data)}
          />
        </Box>
        <GridFooter
          totalRows={rowData.length}
          gridApi={gridApi}
          paginationPageSize={paginationPageSize}
          paginationCurrentPage={paginationCurrentPage}
          paginationTotalPages={paginationTotalPages}
          displayNoResults={noResultsFoundFromFilter || rowData.length === 0}
        />
      </Box>
      <RightSidePanel
        isOpen={Boolean(detail)}
        onToggle={() => setDetail(undefined)}
      >
        <Sheets
          node={{
            type: NodeType.INPUT_DATA_ITEM,
            id: detail?.id || "",
            data: { parentId: detail?.inputDataAssetGroup?.id || "" },
          }}
        />
      </RightSidePanel>

      {/* <ProjectDataAssetDetail
        open={}
        onClose={() => setDetail(undefined)}
        detail={detail}
      /> */}
    </>
  );
};
