import React from "react";
import { alpha, Box, BoxProps, IconButton, useTheme } from "@mui/material";
import { Typography } from "../Typography/Typography";
import { Close } from "@mui/icons-material";

interface SpecificTagProps {
  label: string;
  highlighted?: boolean;
  secondaryText?: string;
  startIcon?: React.ReactNode;
  dismissable?: boolean;
  onClickDismiss?: () => void;
}

type TagProps = SpecificTagProps & BoxProps;

export const Tag = ({
  label,
  secondaryText,
  startIcon,
  dismissable,
  onClickDismiss,
  highlighted = false,
  ...rest
}: TagProps) => {
  const { palette } = useTheme();

  const borderRadiusLabel = secondaryText ? ".25rem 0 0 .25rem" : ".25rem";
  const closeIcon = (
    <IconButton
      onClick={onClickDismiss}
      disableRipple
      sx={{ padding: 0, color: palette.accent.darkest }}
    >
      <Close />
    </IconButton>
  );

  return (
    <Box display="flex" color={palette.accent.darkest} {...rest}>
      <Box
        className="left-text"
        alignItems="center"
        display="flex"
        padding=".125rem .375rem"
        bgcolor={highlighted ? palette.accent.active : palette.accent.outline}
        borderRadius={borderRadiusLabel}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            ".MuiSvgIcon-root": { fontSize: "1rem", mr: 1 },
          }}
        >
          {startIcon}
        </Box>
        <Typography
          variant="body3"
          color={highlighted ? palette.accent.darkest : palette.accent.darkest}
          fontWeight={500}
        >
          {label}
        </Typography>
        {dismissable && !secondaryText && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ".MuiSvgIcon-root": { fontSize: "1rem", ml: 1 },
            }}
          >
            {closeIcon}
          </Box>
        )}
      </Box>
      {secondaryText && (
        <Box
          display="flex"
          bgcolor={alpha(palette.grayAccent.main, 0.06)}
          padding=".125rem .375rem"
          borderRadius="0 .25rem .25rem 0"
        >
          <Typography variant="body3">{secondaryText}</Typography>
          {dismissable && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                ".MuiSvgIcon-root": { fontSize: "1rem", ml: 1 },
              }}
            >
              {closeIcon}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
