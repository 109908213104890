import { Typography } from "@mui/material";
import React from "react";
import { BasicCard } from "../../common/BasicCard/BasicCard";

export default function CustomPolicyCard() {
  return (
    <BasicCard sx={{ padding: "1.5rem" }}>
      <Typography variant="h6" sx={{ mb: 4 }}>
        Custom policies
      </Typography>
      <Typography variant="body3">
        Adding custom policies to the workspace helps members to understand
        standards for compliance without delving into legal documents. These
        policies are not enforced by the Brighthive platform, but provide a
        centralized place to onboard new workspace members to collaboration
        governance.
      </Typography>
    </BasicCard>
  );
}
