import React from "react";
import { FlatfileButton, FlatfileImporter } from "@flatfile/react";
import { useQuery } from "@apollo/client";
import { GetTargetSchemasDocument } from "../../generated";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material";
import {
  convertSchemaToFields,
  convertToCSV,
  generateFieldHooks,
  generateOnRecordChange,
} from "./importUtils";
interface ImportAssetModalProps {
  schemaId: string;
  onSuccess?: Function;
  onCompleted?: ((data: any) => void) | undefined;
  onCancel?: () => void;
  render: (importer: FlatfileImporter | undefined, launch: () => void) => any;
}

export const ImportAssetModal = ({
  render,
  schemaId,
  onSuccess,
  onCancel,
  onCompleted,
}: ImportAssetModalProps) => {
  const { palette } = useTheme();
  const { workspaceId, projectId } = useParams();

  const primaryButton = {
    backgroundColor: palette.primary.main,
    color: palette.common.white,
    border: `1px solid ${palette.primary.main}`,
    ":hover": {
      backgroundColor: palette.primary.dark,
      border: `1px solid ${palette.primary.dark}`,
    },
  };

  const { data } = useQuery(GetTargetSchemasDocument, {
    variables: {
      targetSchemaFilter: { schemaId },
      projectFilter: { projectId: projectId as string },
      input: {
        workspaceId: workspaceId as string,
      },
    },
    onCompleted,
  });

  const targetSchema = data?.workspace?.projects?.[0]?.targetSchemas?.[0];
  const jsonSchema = targetSchema?.jsonSchema
    ? JSON.parse(targetSchema.jsonSchema)
    : {};

  const convertedFields = convertSchemaToFields(jsonSchema);
  const fieldsHooks = generateFieldHooks(jsonSchema);
  const onRecordChange = generateOnRecordChange(jsonSchema);

  if (convertedFields.length === 0) {
    return <div></div>;
  }

  const flatFileKey = process.env.REACT_APP_FLATFILE_KEY;

  return (
    <FlatfileButton
      licenseKey={flatFileKey || ""}
      customer={{
        userId: "12345",
      }}
      onCancel={onCancel}
      onRecordChange={onRecordChange}
      fieldHooks={fieldsHooks}
      settings={{
        allowCustom: true,
        title: "What data would you like to import?",
        type: "Data",
        fields: convertedFields,
        allowInvalidSubmit: false,
        theme: {
          global: {
            backgroundColor: palette.common.white,
            textColor: palette.customViewer.dark,
            primaryTextColor: palette.customViewer.dark,
            secondaryTextColor: palette.customViewer.text,
            fontFamily: `"Roboto", sans-serif`,
          },
          buttons: {
            primary: primaryButton,
          },
        },
      }}
      onData={async (results: any) => {
        const fileName = results.fileName || `data-${new Date().getTime()}.csv`;

        onSuccess?.(fileName, convertToCSV(results.data));
        return "Successfully sent data!";
      }}
      render={render}
    />
  );
};
