import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import { useState } from "react";

import { Formik } from "formik";
import ProjectOverviewGoalForm from "./ProjectOverviewProjectGoalForm";
import ProjectOverviewBackgroundMaterialForm from "./ProjectOverviewBackgroundMaterialForm";
import {
  Goal,
  ProjectOutput,
  UpdateProjectInput,
  UpdateReferenceDocumentInput,
  CreateReferenceDocumentInput,
  Document,
  GetProjectManagersAndOwnersDocument,
  DataProductType,
} from "../../generated";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { omit } from "lodash";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  ButtonPrimary,
  FormLabelHeading,
  FormLabelSubHeading,
  LabelHeading,
} from "./style";
import TextAreaAutosize from "../../common/TextAreaAutosize";
import { MAX_DESCRIPTION_CHARS } from "../../Constants";

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};
type ProjectOverviewFormType = {
  project: RecursivePartial<ProjectOutput>;
  onChange: (modifiedProject: UpdateProjectInput) => void;
};

const ProjectOverviewForm = ({
  project,
  onChange,
}: ProjectOverviewFormType) => {
  const { palette } = useTheme();
  const { workspaceId } = useParams();
  const { data } = useQuery(GetProjectManagersAndOwnersDocument, {
    variables: {
      projectId: project.id || "",
      workspaceId: workspaceId || "",
    },
  });

  const [values, setValues] = useState<UpdateProjectInput>({
    name: project?.name || "",
    description: project?.description || "",
    ownerId: project?.owner?.id,
    dataProductType: project?.dataProductType,
    goals: {
      create: [],
      update: [
        ...(project?.goals
          ? (project?.goals.map((goal) =>
              omit(goal, ["__typename", "name"])
            ) as Goal[])
          : []),
      ],
      delete: [],
    },
    tags: {
      connectOrCreate: project?.tags?.map((tag) => tag || "") || [],
      disconnect: [],
    },
    dataConsumers: {
      connectOrCreate:
        project?.dataConsumers?.map((consumer) => consumer || "") || [],
      disconnect: [],
    },
    backgroundMaterials: {
      create: [],
      update: [
        ...((project?.backgroundMaterials?.map((m) =>
          omit(m, "__typename")
        ) as UpdateReferenceDocumentInput[]) || []),
      ],
      delete: [],
    },
    projectId: project?.id || "",
    workspaceId: workspaceId || "",
  });
  const setValueAndPush = (values: UpdateProjectInput) => {
    setValues(values);
    onChange(values);
  };

  const onModify = (
    type: "goals" | "backgroundMaterials",
    index: number,
    modifiedItem: any
  ) => {
    const goalsOrBackgroundMaterials = [...(values?.[type]?.update || [])];
    const isNew =
      !values?.[type]?.update.length ||
      !goalsOrBackgroundMaterials.filter((item) => item.id === modifiedItem.id)
        .length;
    const createdList = values?.[type]?.create || [];
    const updatedList = values?.[type]?.update || [];
    if (isNew) {
      createdList[index - updatedList.length] = modifiedItem;
    } else {
      updatedList[index] = modifiedItem;
    }

    setValueAndPush({
      ...values,
      [type]: {
        delete: [...(values?.[type]?.delete || [])],
        create: [...createdList],
        update: [...updatedList],
      },
    });
  };
  const onNewGoal = () => {
    setValueAndPush({
      ...values,
      goals: {
        delete: [...(values?.goals?.delete || [])],
        update: [...(values.goals?.update || [])],
        create: [
          ...(values?.goals?.create || []),
          {
            description: "",
            metrics: [],
          },
        ],
      },
    });
  };
  const onDeleteGoal = (goal: Goal) => {
    setValueAndPush({
      ...values,
      goals: {
        create: [
          ...(values?.goals?.create.filter(
            (aGoal) => goal.description !== aGoal.description
          ) || []),
        ],
        delete: [
          ...(values?.goals?.delete || []),
          ...(goal.id ? [goal.id] : []),
        ],
        update: [
          ...(values?.goals?.update.filter((aGoal) => goal.id !== aGoal.id) ||
            []),
        ],
      },
    });
  };
  const onNewBGMaterial = () => {
    setValueAndPush({
      ...values,
      backgroundMaterials: {
        delete: [...(values?.backgroundMaterials?.delete || [])],
        update: [...(values.backgroundMaterials?.update || [])],
        create: [
          ...(values?.backgroundMaterials?.create || []),
          {
            href: "",
            name: "",
          },
        ],
      },
    });
  };
  const onDeleteBackgroundMaterial = (bgMaterial: Document) => {
    setValueAndPush({
      ...values,
      backgroundMaterials: {
        create: [
          ...(values?.backgroundMaterials?.create.filter(
            (doc) => doc.href !== bgMaterial.href
          ) || []),
        ],
        delete: [
          ...(values?.backgroundMaterials?.delete || []),
          ...(bgMaterial.id ? [bgMaterial.id] : []),
        ],
        update: [
          ...(values?.backgroundMaterials?.update.filter(
            (doc) => doc.id !== bgMaterial.id
          ) || []),
        ],
      },
    });
  };

  if (project?.id && data) {
    return (
      <Formik initialValues={values} onSubmit={setValues}>
        {() => (
          <Box
            sx={{
              padding: "1.5rem 2rem",
            }}
          >
            {false && (
              <Box mb={6} display="flex" flexDirection="row">
                <FormLabelHeading variant="body1">
                  Project template
                  <FormLabelSubHeading variant="body1">
                    (Optional)
                  </FormLabelSubHeading>
                </FormLabelHeading>
                <ButtonPrimary color="grayAccent" variant="contained">
                  Apply a template
                </ButtonPrimary>
              </Box>
            )}
            <Box mb={6} display="flex" flexDirection="row">
              <LabelHeading variant="body1">Project name</LabelHeading>
              <TextField
                sx={{
                  width: "100%",
                  ".MuiInputBase-input": {
                    width: "100%",
                    height: "36px",
                    fontSize: "16px",
                    lineHeight: "20px",
                    padding: "8px",
                    boxSizing: "border-box",
                  },
                }}
                value={values.name}
                onChange={(e) =>
                  setValueAndPush({
                    ...values,
                    name: e.target.value,
                  })
                }
                inputProps={{ maxLength: 250 }}
              />
            </Box>
            <Box mb={6} display="flex" flexDirection="row">
              <LabelHeading variant="body1">Description</LabelHeading>
              <TextAreaAutosize
                value={values.description || ""}
                onChange={(e) =>
                  setValueAndPush({
                    ...values,
                    description: e.target.value,
                  })
                }
                maxLength={MAX_DESCRIPTION_CHARS}
              />
            </Box>
            <Box mb={6} display="flex" flexDirection="row">
              <LabelHeading variant="body1">Data product type</LabelHeading>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={values.dataProductType}
                  name="radio-buttons-group"
                  value={values.dataProductType}
                  onChange={(e) =>
                    setValueAndPush({
                      ...values,
                      dataProductType: e.target.value as DataProductType,
                    })
                  }
                >
                  <FormControlLabel
                    value="FINAL_DATA_PRODUCT"
                    control={<Radio />}
                    label="Final data product"
                  />
                  <FormControlLabel
                    value="POC"
                    control={<Radio />}
                    label="Proof of concept (POC)"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box mb={6} display="flex" flexDirection="row">
              <LabelHeading variant="body1">Project owner</LabelHeading>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={values.ownerId}
                  label="Owner"
                  onChange={(e) =>
                    setValueAndPush({ ...values, ownerId: e.target.value })
                  }
                  sx={{
                    fieldset: {
                      top: "0",
                    },
                    legend: {
                      display: "none",
                    },
                    ".MuiInputBase-input": {
                      width: "100%",
                      height: "36px",
                      fontSize: "16px",
                      lineHeight: "20px",
                      padding: "8px",
                      boxSizing: "border-box",
                    },
                  }}
                >
                  {data.owners.map((owner) => (
                    <MenuItem value={owner.id}>{owner.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box mb={6} display="flex" flexDirection="row">
              <LabelHeading variant="body1">Project manager</LabelHeading>
              {/* <FormControl fullWidth>
                <Autocomplete
                  options={data.managers || []}
                  getOptionLabel={(item) => item.name}
                  value={data.managers?.find(
                    (item) => item.id === values.managers
                  )}
                  size="small"
                  onChange={(e, value) =>
                    setValueAndPush({ ...values, managerId: value?.id })
                  }
                  sx={{
                    ".MuiInputBase-input": {
                      width: "100%",
                      height: "26px",
                      fontSize: "16px",
                      lineHeight: "20px",
                      padding: "2px !important",
                      boxSizing: "border-box",
                    },
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormControl> */}
            </Box>
            <Box
              mb={6}
              display="flex"
              flexDirection="row"
              sx={{
                ".MuiChip-colorDefault": {
                  margin: "0 0.5rem 1rem 0",
                  borderRadius: "4px",
                  background: palette.settings.darkest,
                  color: palette.common.black,
                  fontWeight: 500,
                  fontSize: "0.875rem",
                  height: "24px",
                },
                ".MuiChip-label": {
                  paddingLeft: "0.5rem",
                  paddingRight: "0.5rem",
                },
              }}
            >
              <LabelHeading variant="body1">Tags</LabelHeading>
              <Box>
                <Autocomplete<string, boolean, boolean, boolean>
                  multiple
                  id="free-solo-demo"
                  freeSolo
                  defaultValue={values.tags?.connectOrCreate || []}
                  onChange={(_, value) => {
                    const removed = values.tags?.connectOrCreate.filter(
                      (item) => value?.indexOf(item) === -1
                    );
                    setValueAndPush({
                      ...values,
                      tags: {
                        disconnect: [
                          ...(values?.tags?.disconnect || []),
                          ...(removed || []),
                        ],
                        connectOrCreate: value as string[],
                      },
                    });
                  }}
                  options={values.tags?.connectOrCreate || []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{ ...params.inputProps, maxLength: 50 }}
                      helperText="Press enter between each tag you wish to apply to this project."
                    />
                  )}
                  sx={{
                    width: "100%",
                    ".MuiInputBase-input": {
                      width: "100%",
                      height: "36px",
                      fontSize: "16px",
                      lineHeight: "20px",
                      padding: "8px",
                      boxSizing: "border-box",
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              mb={6}
              display="flex"
              flexDirection="row"
              sx={{
                ".MuiChip-colorDefault": {
                  margin: "0 0.5rem 1rem 0",
                  borderRadius: "4px",
                  background: palette.settings.darkest,
                  color: palette.common.black,
                  fontWeight: 500,
                  fontSize: "0.875rem",
                  height: "24px",
                },
                ".MuiChip-label": {
                  paddingLeft: "0.5rem",
                  paddingRight: "0.5rem",
                },
              }}
            >
              <LabelHeading variant="body1">Data consumers</LabelHeading>
              <Autocomplete<string, boolean, boolean, boolean>
                multiple
                id="free-solo-demo"
                freeSolo
                defaultValue={values?.dataConsumers?.connectOrCreate || []}
                options={values?.dataConsumers?.connectOrCreate || []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, maxLength: 50 }}
                  />
                )}
                onChange={(_, value) => {
                  const removed = values.dataConsumers?.connectOrCreate.filter(
                    (item) => value?.indexOf(item) === -1
                  );
                  setValueAndPush({
                    ...values,
                    dataConsumers: {
                      disconnect: [
                        ...(values?.dataConsumers?.disconnect || []),
                        ...(removed || []),
                      ],
                      connectOrCreate: value as string[],
                    },
                  });
                }}
                sx={{
                  width: "100%",
                  ".MuiInputBase-input": {
                    width: "100%",
                    height: "36px",
                    fontSize: "16px",
                    lineHeight: "20px",
                    padding: "8px",
                    boxSizing: "border-box",
                  },
                }}
              />
            </Box>

            <Box mb={8}>
              <Box mb={6} display="flex" flexDirection="row">
                <LabelHeading variant="body1">Project Goals</LabelHeading>

                <Grid
                  sx={{
                    width: "100%",
                  }}
                >
                  {[
                    ...(values.goals?.update || []),
                    ...(values.goals?.create || []),
                  ].map((goal: any, index: number) => (
                    <ProjectOverviewGoalForm
                      key={index}
                      index={index + 1}
                      goal={goal}
                      onUpdate={(modifiedGoal) =>
                        onModify("goals", index, modifiedGoal)
                      }
                      onDelete={() => onDeleteGoal(goal)}
                    />
                  ))}
                  <ButtonPrimary
                    color="grayAccent"
                    variant="contained"
                    onClick={onNewGoal}
                  >
                    Add a project goal
                  </ButtonPrimary>
                </Grid>
              </Box>
            </Box>
            <Box mb={8}>
              <Box mb={6} display="flex" flexDirection="row">
                <LabelHeading variant="body1">Background Material</LabelHeading>

                <Grid sx={{ width: "100%" }}>
                  {[
                    ...(values.backgroundMaterials?.update || []),
                    ...(values.backgroundMaterials?.create || []),
                  ].map(
                    (material: CreateReferenceDocumentInput, index: number) => (
                      <ProjectOverviewBackgroundMaterialForm
                        key={index}
                        index={index + 1}
                        material={material as UpdateReferenceDocumentInput}
                        onUpdate={(modified) =>
                          onModify("backgroundMaterials", index, modified)
                        }
                        onDelete={() =>
                          onDeleteBackgroundMaterial(material as Document)
                        }
                      />
                    )
                  )}
                  <ButtonPrimary
                    color="grayAccent"
                    variant="contained"
                    onClick={onNewBGMaterial}
                  >
                    Add a resource link
                  </ButtonPrimary>
                </Grid>
              </Box>
            </Box>
          </Box>
        )}
      </Formik>
    );
  }
  return null;
};
export default ProjectOverviewForm;
