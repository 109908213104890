import {
  GetIngestionServicesDocument,
  GetTransformationServicesDocument,
  GetWarehouseServicesDocument,
} from "../../generated";
import { Services } from "../../common/Services/Services";

export const Connection = () => {
  return <Services title="Connections" query={GetIngestionServicesDocument} />;
};
