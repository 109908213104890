/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Box, Switch, useTheme } from "@mui/material";
import Highlighter from "react-highlight-words";
import { FormikValues } from "formik";
import { useState } from "react";

import { Typography } from "../common/Typography/Typography";
import { BasicCard } from "../common/BasicCard/BasicCard";
import { Tag } from "../common/Tag/Tag";
import { DOCLink } from "../common/Link/Link";
import { Label } from "../common/Label/Label";
import { GovernancePolicyItemMenuButton } from "./GovernancePolicyItemMenuButton";

type referenceDoc = {
  name: string;
  href: string;
};

export interface GovernancePolicyItemProps {
  title: string;
  type: string;
  description: string;
  dateTime: string;
  addedBy: any;
  referenceDocuments?: referenceDoc[];
  tags?: string[];
  keyword?: string;
  tagValues?: FormikValues;
  addMode?: boolean;
  isEditable?: boolean;
  handleEdit?: Function;
  handleDelete?: Function;
  handleOpenNewTab?: Function;
}
export const GovernancePolicyItem = ({
  title,
  type,
  description,
  dateTime,
  addedBy,
  referenceDocuments,
  tags,
  keyword = "",
  tagValues = [],
  addMode = false,
  handleDelete,
  handleEdit,
  handleOpenNewTab,
  isEditable,
}: GovernancePolicyItemProps) => {
  const [enabled, setEnabled] = useState(false);
  const { palette } = useTheme();

  return (
    <BasicCard
      sx={{
        padding: "1.5rem",
        bgcolor: enabled ? palette.primary.lighter : palette.common.white,
      }}
      display={"flex"}
      justifyContent="space-between"
    >
      <Box sx={{ maxWidth: "43.75rem" }}>
        <Typography variant="subtitle1" sx={{ mb: 2 }}>
          <Highlighter
            highlightClassName="YourHighlightClass"
            searchWords={[keyword]}
            autoEscape={true}
            textToHighlight={title}
          />
        </Typography>
        <Typography variant="caption" color={"grayAccent"}>
          {type}
        </Typography>
        <Typography variant="caption" color={"grayAccent"} sx={{ mx: 2 }}>
          {"|"}
        </Typography>
        <Typography variant="caption" color={"grayAccent"}>
          Added {dateTime} by {addedBy.firstName + " " + addedBy.lastName}
        </Typography>

        <Typography variant="body2" color={"grayAccent"} mt={4}>
          <Highlighter
            highlightClassName="YourHighlightClass"
            searchWords={[keyword]}
            autoEscape={true}
            textToHighlight={description}
          />
        </Typography>
        {tags && (
          <Box>
            <Typography variant="subtitle3" sx={{ mt: 4, mb: 2 }}>
              Applied to
            </Typography>
            <Box display={"flex"}>
              {tags.map((tag) => (
                <Tag
                  color="primary"
                  highlighted={tagValues[tag]}
                  label={tag}
                  dismissable={false}
                  mr={1}
                />
              ))}
            </Box>
          </Box>
        )}
        {referenceDocuments && (
          <Box>
            <Typography variant="subtitle3" sx={{ mt: 4, mb: 2 }}>
              Reference documents
            </Typography>
            <Box>
              <ul
                css={css`
                  paddingleft: "1.5rem";
                  margin: 0;
                `}
              >
                {referenceDocuments.map((doc: any) => (
                  <li>
                    <DOCLink sx={{ mb: 2 }} name={doc.name} href={doc.href} />
                  </li>
                ))}
              </ul>
            </Box>
          </Box>
        )}
      </Box>
      <Box>
        {addMode ? (
          <Box display={"flex"} sx={{ mt: -1 }}>
            <Label
              value={enabled ? "Enabled" : "Disabled"}
              fontSize={"small"}
            />
            <Switch onChange={() => setEnabled(!enabled)} value={enabled} />
          </Box>
        ) : (
          <GovernancePolicyItemMenuButton
            handleEdit={handleEdit}
            handleDelete={isEditable ? handleDelete : undefined}
            handleOpenNewTab={handleOpenNewTab}
          />
        )}
      </Box>
    </BasicCard>
  );
};
