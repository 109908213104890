import { Button, Divider } from "@mui/material";
import { FooterContainer } from "./style";

type Props = {
  loading: boolean;
  handleSubmit: () => void;
  onClose: () => void;
};

const DrawerFooter = ({ loading, handleSubmit, onClose }: Props) => {
  return (
    <FooterContainer>
      <Divider style={{ marginBottom: "1rem" }} />
      <Button
        color="primary"
        variant="contained"
        fullWidth
        sx={{ marginBottom: "10px" }}
        disabled={loading}
        onClick={handleSubmit}
      >
        Save
      </Button>
      <Button color="grayAccent" variant="outlined" fullWidth onClick={onClose}>
        Cancel
      </Button>
    </FooterContainer>
  );
};

export default DrawerFooter;
