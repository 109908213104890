import React from "react";
import { Box, BoxProps } from "@mui/material";

interface SpecificSquareIconProps {
  backgroundColor: string;
  color: string;
  icon: React.ReactNode;
  size?: "small" | "medium";
}

export type SquareIconProps = SpecificSquareIconProps & BoxProps;

export const SquareIcon = ({
  backgroundColor,
  color,
  icon,
  sx,
  size,
  ...rest
}: SquareIconProps) => {
  const smallProps = {
    height: "1.25rem",
    width: "1.25rem",
    sx: {
      ".MuiSvgIcon-root": {
        height: ".5625rem",
        width: ".5625rem",
        fontSize: ".5625rem",
      },
      ...sx,
    },
  };

  const mediumProps = {
    height: "1.75rem",
    width: "1.75rem",
    sx: {
      ".MuiSvgIcon-root": {
        height: "1.041875rem",
        width: "1.041875rem",
        fontSize: "1.041875rem",
      },
      ...sx,
    },
  };

  let sizeProps = mediumProps;

  if (size === "small") {
    sizeProps = smallProps;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor={backgroundColor}
      color={color}
      borderRadius=".375rem"
      {...sizeProps}
      {...rest}
    >
      {icon}
    </Box>
  );
};
