import {
  GetWorkflowDocument,
  GetWorkflowQuery,
  WorkflowFilterInput,
} from "../../generated";
import { aWorkflow } from "./generated-mocks";
import { MockedResponse } from "@apollo/client/testing";

const workflowFilterInput: WorkflowFilterInput = {
  projectId: "68c99a9a-17ef-4da0-98ea-7f3eea6684c1",
  workspaceId: "f4861165-f244-4cad-9827-65921385b9a0",
};

const data: GetWorkflowQuery = {
  workflow: aWorkflow(),
};

export const workflowMock: MockedResponse = {
  request: {
    query: GetWorkflowDocument,
    variables: {
      workflowFilterInput,
    },
  },
  result: {
    data,
  },
  delay: 2000,
};
