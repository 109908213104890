import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const BrighthiveLogoSvgVii = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="129"
      height="28"
      fill="none"
      viewBox="0 0 129 28"
      {...props}
    >
      <g clip-path="url(#clip0_4270_44435)">
        <path d="M17.9309 24.1531V16.5391L9.55062 21.3756V24.1531C9.55079 24.5767 9.38583 24.9838 9.09076 25.2877C8.79573 25.5918 8.3938 25.7688 7.97034 25.7813H19.559C19.1272 25.7813 18.7131 25.6098 18.4077 25.3044C18.1024 24.9991 17.9309 24.5849 17.9309 24.1531Z" fill="#A1D2AB"/>
        <path d="M9.55071 4.04026V11.6543L17.9309 6.81771V4.04026C17.9309 3.60844 18.1024 3.19433 18.4078 2.88899C18.7131 2.58365 19.1273 2.41211 19.559 2.41211H7.92252C8.35433 2.41211 8.76846 2.58365 9.0738 2.88899C9.37909 3.19433 9.55071 3.60844 9.55071 4.04026Z" fill="#FEBE2D"/>
        <path d="M20.3731 5.42935L17.9309 6.81808V16.5392L24.5872 12.7082C24.9566 12.4971 25.394 12.4391 25.8057 12.5465C26.2174 12.6539 26.5707 12.9182 26.7899 13.2828L20.9477 3.22656C21.0586 3.40785 21.1318 3.60966 21.1629 3.8199C21.194 4.03014 21.1825 4.24449 21.1288 4.45014C21.0752 4.65579 20.9806 4.84852 20.8507 5.01677C20.7209 5.18502 20.5584 5.32534 20.3731 5.42935Z" fill="#5BCBF5"/>
        <path d="M26.7899 14.8634C26.9266 14.6319 26.9931 14.3658 26.9814 14.0972C26.9919 13.8136 26.9257 13.5324 26.7899 13.2831C26.5707 12.9185 26.2174 12.6543 25.8057 12.5469C25.394 12.4395 24.9566 12.4975 24.5872 12.7085L17.9309 16.5395V24.1535C17.9309 24.5853 18.1024 24.9994 18.4077 25.3048C18.7131 25.6101 19.1272 25.7816 19.559 25.7816H19.99L20.421 25.59L20.7083 25.3986V25.3028L20.8998 25.0633L25.5449 16.9705L26.742 14.9592L26.7899 14.8634Z" fill="#15B9A8"/>
        <path d="M17.9309 4.0412V6.81865L20.3731 5.42992C20.5584 5.32591 20.7209 5.18559 20.8507 5.01734C20.9806 4.84909 21.0752 4.65636 21.1288 4.45071C21.1825 4.24506 21.194 4.03071 21.1629 3.82047C21.1318 3.61023 21.0586 3.40842 20.9477 3.22713C20.8962 3.09769 20.814 2.98266 20.7083 2.89192C20.4057 2.61919 20.0142 2.46599 19.6069 2.46094H19.559C19.1353 2.46075 18.7283 2.62569 18.4243 2.92075C18.1203 3.21581 17.9433 3.61774 17.9309 4.0412Z" fill="#89A150"/>
        <path d="M7.10849 22.7641L9.55071 21.3753V11.6543L2.94232 15.4852C2.57287 15.6963 2.1355 15.7543 1.7238 15.6469C1.31211 15.5395 0.958816 15.2752 0.739532 14.9106L6.53385 24.9668C6.32282 24.5974 6.2648 24.16 6.37219 23.7483C6.47959 23.3366 6.74388 22.9833 7.10849 22.7641Z" fill="#F6A7C0"/>
        <path d="M9.55055 24.1544V21.377L7.10833 22.7657C6.74372 22.985 6.47943 23.3382 6.37204 23.7499C6.26463 24.1616 6.32266 24.599 6.53369 24.9685L6.77312 25.3037H6.82101C6.96689 25.4449 7.13938 25.5558 7.32845 25.6297C7.51753 25.7037 7.71943 25.7394 7.92241 25.7347H7.9703C8.3856 25.7226 8.78059 25.5523 9.07438 25.2584C9.36812 24.9647 9.53848 24.5697 9.55055 24.1544Z" fill="#A79087"/>
        <path d="M2.94223 15.4852L9.55064 11.6542V4.04026C9.55064 3.60845 9.37911 3.19433 9.07373 2.88899C8.76841 2.58365 8.35428 2.41211 7.92247 2.41211H7.49148C7.35097 2.44622 7.21999 2.5117 7.10838 2.60365L6.82107 2.7952C6.77318 2.84309 6.77318 2.84309 6.77318 2.89098L6.58164 3.13041H6.53375L1.88872 11.1754L0.691545 13.1866C0.577686 13.4269 0.512585 13.6873 0.5 13.9529C0.506452 14.2406 0.58907 14.5215 0.739435 14.7669C0.835874 14.9597 0.969619 15.1314 1.13292 15.272C1.29622 15.4127 1.48583 15.5195 1.69074 15.5863C1.89566 15.6532 2.11179 15.6787 2.32662 15.6613C2.54145 15.644 2.7507 15.5841 2.94223 15.4852Z" fill="#F5843C"/>
        <path d="M34.9745 8.92455H39.3098C39.827 8.92614 40.3411 9.00646 40.8343 9.16275C41.246 9.29765 41.621 9.52591 41.93 9.82972C42.1466 10.0393 42.3176 10.2914 42.4324 10.5701C42.5471 10.8488 42.6032 11.1481 42.597 11.4495C42.6 11.7571 42.5517 12.063 42.454 12.3547C42.3556 12.5922 42.2275 12.8163 42.0729 13.0216L41.5488 13.4981C41.3683 13.6122 41.1768 13.7079 40.9771 13.7839L41.8347 14.165C42.0856 14.2794 42.312 14.4412 42.5016 14.6414C42.6869 14.8436 42.8469 15.0676 42.9781 15.3084C43.0914 15.614 43.1557 15.9356 43.1687 16.2612C43.1811 16.6766 43.0823 17.0877 42.8828 17.4522C42.6952 17.7908 42.4348 18.0837 42.1205 18.3097C41.7819 18.5755 41.3929 18.7699 40.9771 18.8814C40.4775 19.0006 39.9663 19.0646 39.4527 19.0719H34.9745V8.92455ZM39.1192 13.4027C39.705 13.4257 40.2846 13.2767 40.7866 12.974C40.9965 12.8213 41.1667 12.6205 41.2828 12.3884C41.3989 12.1562 41.4574 11.8996 41.4536 11.64C41.4653 11.4096 41.4191 11.1798 41.3193 10.9718C41.2195 10.7637 41.0691 10.584 40.8819 10.4491C40.3876 10.1243 39.8059 9.95805 39.2145 9.97264H36.1179V13.4504L39.1192 13.4027ZM39.5003 18.0239C40.1548 18.0691 40.8063 17.902 41.3583 17.5474C41.5682 17.3948 41.7383 17.194 41.8545 16.9618C41.9705 16.7297 42.0291 16.4731 42.0253 16.2135C42.0305 15.9615 41.9667 15.7129 41.8407 15.4945C41.7148 15.2762 41.5315 15.0965 41.3107 14.9749C40.7118 14.6061 40.0105 14.4392 39.3098 14.4984H36.1179V18.0716L39.5003 18.0239Z" fill="#1F2641"/>
        <path d="M45.8846 8.92457H50.2675C50.8319 8.92326 51.3935 9.00348 51.935 9.16277C52.387 9.31837 52.7953 9.57971 53.1259 9.92502C53.4012 10.1641 53.6001 10.4789 53.6976 10.8302C53.8498 11.1757 53.9308 11.5484 53.9359 11.9259C53.936 12.3193 53.8549 12.7086 53.6976 13.0693C53.5996 13.4069 53.3999 13.7064 53.1259 13.9268C52.8691 14.1707 52.5807 14.379 52.2685 14.5461L51.1727 14.8796L54.2693 19.0244H52.8878L49.9341 15.0701H47.028V19.0244H45.8846V8.92457ZM50.1723 14.0698C50.5267 14.0723 50.8796 14.0242 51.2203 13.9268L52.0302 13.5457C52.2727 13.3737 52.4691 13.1446 52.602 12.8788C52.735 12.6129 52.8004 12.3183 52.7925 12.0212C52.802 11.7356 52.742 11.4519 52.6175 11.1946C52.493 10.9373 52.3078 10.7141 52.0779 10.5443C51.5155 10.1694 50.8473 9.98561 50.1723 10.0203H47.028V14.1173L50.1723 14.0698Z" fill="#1F2641"/>
        <path d="M56.7939 8.92455H57.9373V19.0719H56.7939V8.92455Z" fill="#1F2641"/>
        <path d="M65.9889 19.2149C65.257 19.2207 64.5302 19.0916 63.8452 18.8338C63.2343 18.5646 62.6836 18.1758 62.2253 17.6904C61.7636 17.2321 61.4062 16.6797 61.1773 16.0707C60.947 15.4127 60.8342 14.7192 60.8438 14.0222C60.8308 13.336 60.9607 12.6547 61.2249 12.0212C61.4544 11.4093 61.7934 10.8443 62.2253 10.3539C62.6919 9.8782 63.2407 9.49085 63.8452 9.21049C64.5047 8.91806 65.2199 8.77179 65.9414 8.78172L67.0847 8.877L68.0851 9.1152L68.9427 9.54396L69.7049 10.1157L68.9427 11.0208L68.371 10.5444L67.6564 10.1633L66.8465 9.92509L65.8937 9.8298C65.353 9.83854 64.8191 9.95179 64.3215 10.1633C63.868 10.3823 63.463 10.6901 63.1305 11.0684C62.763 11.446 62.4861 11.9021 62.3207 12.4024C62.1292 12.9209 62.0324 13.4695 62.0348 14.0222C62.0245 14.591 62.1214 15.1567 62.3207 15.6896C62.4743 16.1952 62.7527 16.654 63.1305 17.0235C63.4674 17.4174 63.8916 17.7274 64.3692 17.9287C64.8989 18.1442 65.4648 18.2573 66.0366 18.2621C66.5949 18.2757 67.1489 18.1617 67.6564 17.9287C68.1244 17.7691 68.5599 17.5271 68.9427 17.2141V14.6891H65.8937V13.6887H70.0384V17.7381C69.5265 18.1896 68.9481 18.5592 68.3233 18.8338C67.5769 19.1097 66.7843 19.2391 65.9889 19.2149Z" fill="#1F2641"/>
        <path d="M73.1349 8.92445H74.2783V13.4026H80.138V8.92445H81.2814V19.0719H80.138V14.4984H74.2783V19.0719H73.1349V8.92445Z" fill="#1F2641"/>
        <path d="M86.997 9.97255H83.6147V8.92445H91.5711V9.97255H88.1408V19.0719H86.997V9.97255Z" fill="#1F2641"/>
        <path d="M93.9057 8.92445H95.0486V13.4026H100.909V8.92445H102.052V19.0719H100.909V14.4984H95.0486V19.0719H93.9057V8.92445Z" fill="#1F2641"/>
        <path d="M105.435 8.92445H106.578V19.0719H105.435V8.92445Z" fill="#1F2641"/>
        <path d="M108.912 8.92445H110.199L113.772 17.6426L117.393 8.92445H118.632L114.296 19.1195H113.248L108.912 8.92445Z" fill="#1F2641"/>
        <path d="M120.917 8.92445H128.254V9.9249H122.061V13.4503H127.587V14.4508H122.061V18.0238H128.302V19.0719H120.917V8.92445Z" fill="#1F2641"/>
      </g>
      <defs>
        <clipPath id="clip0_4270_44435">
          <rect width="127.802" height="26.4828" fill="white" transform="translate(0.5 0.757812)"/>
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
