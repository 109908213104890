import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

interface DropdownProps {
  options?: any[];
  onChange?: (event: SelectChangeEvent) => void;
  value?: string;
}

export default function Dropdown({ options, onChange, value }: DropdownProps) {
  return (
    <Box width="100%" sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <Select
          onChange={onChange}
          value={value}
          sx={{
            "& .MuiInputBase-input": {
              padding: ".375rem 1.625rem .375rem .75rem",
            },
          }}
        >
          {options?.map((option) => (
            <MenuItem value={option}>{option}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
