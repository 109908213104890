import React from "react";
import { Node } from "react-flow-renderer";
import { Typography } from "../common/Typography/Typography";
import { Box, useTheme } from "@mui/material";
import { NODE_WIDTH } from "./helpers";

export interface HeaderNodeData {
  headerLabel: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  description?: string;
  footer?: React.ReactNode;
}

export const HeaderNode = ({
  data: { headerLabel, leftIcon, rightIcon, description, footer },
}: Node<HeaderNodeData>) => {
  const { palette } = useTheme();

  return (
    <Box display="flex" flexDirection="column" width={NODE_WIDTH}>
      <Box mb={2} display="flex" alignItems="center">
        {leftIcon}
        <Typography mr="auto" variant="h6" color={palette.common.black}>
          {headerLabel}
        </Typography>
        {rightIcon}
      </Box>
      <Box flexGrow={1} mb={4} p={2} bgcolor={palette.settings.lighter}>
        <Typography variant="caption" color={palette.gray.dark}>
          {description}
        </Typography>
      </Box>
      {footer}
    </Box>
  );
};
