import React from "react";
import { stringAvatarLetters, stringToColor } from "../../helpers";
import { Avatar, AvatarProps } from "@mui/material";

export interface ColoredAvatarProps {
  name: string;
}

export const ColoredAvatar = ({
  name,
  sx,
  ...rest
}: ColoredAvatarProps & AvatarProps) => {
  return (
    <Avatar
      sx={{
        height: "1.5rem",
        width: "1.5rem",
        bgcolor: stringToColor(name),
        fontWeight: 700,
        fontSize: ".5rem",
        ...sx,
      }}
      alt={name}
      {...rest}
    >
      {stringAvatarLetters(name)}
    </Avatar>
  );
};
