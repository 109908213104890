import { FieldProps } from "formik";
import TextAreaAutosize from ".";
import { TextareaAutosizeProps } from "@mui/material";
import { StyledFormHelperText } from "./style";

type FormikTextareaProps = FieldProps & TextareaAutosizeProps;

const FormikTextAreaAutosize = ({
  field,
  form,
  ...props
}: FormikTextareaProps) => {
  return (
    <div>
      <TextAreaAutosize
        {...field}
        {...props}
        aria-invalid={
          form.touched[field.name] && Boolean(form.errors[field.name])
        }
        aria-describedby={`${field.name}-error`}
      />
      {form.touched[field.name] && form.errors[field.name] && (
        <StyledFormHelperText error>
          {form.errors[field.name]}
        </StyledFormHelperText>
      )}
    </div>
  );
};

export default FormikTextAreaAutosize;
