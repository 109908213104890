import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const AttachmentIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M13.9983 -0.00268136C15.1663 -0.00296181 16.3091 0.3377 17.2863 0.977514C18.2635 1.61733 19.0328 2.52847 19.4996 3.59916C19.9665 4.66985 20.1107 5.85353 19.9145 7.00499C19.7183 8.15645 19.1903 9.22561 18.3953 10.0813L18.2053 10.2753L9.47828 19.0023L9.42528 19.0523L9.36928 19.0973C8.65836 19.7102 7.7421 20.0317 6.8041 19.9975C5.86611 19.9633 4.97569 19.5759 4.31129 18.9129C3.64689 18.2499 3.2576 17.3603 3.22144 16.4224C3.18527 15.4845 3.50491 14.5675 4.11628 13.8553L4.26528 13.6913L4.28028 13.6803L11.5703 6.37632C11.7577 6.18855 12.0119 6.0829 12.2772 6.08262C12.5425 6.08233 12.797 6.18744 12.9848 6.37482C13.1726 6.56219 13.2782 6.81649 13.2785 7.08176C13.2788 7.34703 13.1737 7.60155 12.9863 7.78932L5.69628 15.0933L5.68428 15.1013C5.39005 15.4155 5.22425 15.8286 5.21958 16.259C5.21492 16.6895 5.37174 17.106 5.65911 17.4265C5.94647 17.747 6.34351 17.9482 6.7719 17.9904C7.2003 18.0326 7.62894 17.9126 7.97328 17.6543L8.09528 17.5543L8.09628 17.5553L16.7983 8.85532L16.9573 8.69032C17.6574 7.92078 18.0301 6.90874 17.9964 5.86892C17.9627 4.82911 17.5252 3.84333 16.7768 3.12074C16.0283 2.39815 15.0277 1.99561 13.9874 1.99852C12.947 2.00143 11.9487 2.40956 11.2043 3.13632L11.0493 3.29632L11.0313 3.30832L1.70528 12.6383C1.61237 12.7312 1.50208 12.8048 1.38071 12.855C1.25935 12.9052 1.12927 12.9311 0.997926 12.931C0.866578 12.931 0.736525 12.9051 0.615192 12.8547C0.49386 12.8044 0.383625 12.7307 0.29078 12.6378C0.197935 12.5449 0.1243 12.4346 0.074078 12.3133C0.023856 12.1919 -0.00196897 12.0618 -0.00192254 11.9305C-0.00182878 11.6652 0.103639 11.4108 0.29128 11.2233L9.60028 1.91032L9.64628 1.86732C10.2064 1.27578 10.8814 0.804807 11.6299 0.483259C12.3784 0.161711 13.1836 -0.00364483 13.9983 -0.00268136Z" fill="currentColor"/>
    </SvgIcon>
  );
};
