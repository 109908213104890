import React from "react";
import { LeftRightContent } from "../../common/LeftRightContent/LeftRightContent";
import { ChipFilter } from "../../common/ChipFilter/ChipFilter";
import { SearchBar } from "../../common/SearchBar/SearchBar";
import { Button } from "../../common/Button/Button";
import { alpha, Box, useTheme } from "@mui/material";
import { RefreshIcon } from "../../common/Icons/RefreshIcon";
import { Typography } from "../../common/Typography/Typography";
import { ButtonDefault } from "./style";

export interface ActionBarDataAssetCatalogProps {
  amountOfAssetsInUse?: number;
  amountOfAssetIssues?: number;
  assetsInUseFilterActive?: boolean;
  assetIssuesFilterActive?: boolean;
  assetsInUseDisabled?: boolean;
  assetIssuesDisabled?: boolean;
  hideChipFilter?: boolean;
  onChangeSearch?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  onResetFilters?: () => void;
  onClickAssetsInUseFilter?: () => void;
  onClickAssetsIssuesFilter?: () => void;
  searchText?: string;
  searchPlaceholder?: string;
  noResultsFoundFromFilter: boolean;
}

export const ActionBarDataAssetCatalog = ({
  onClickAssetsInUseFilter,
  onClickAssetsIssuesFilter,
  amountOfAssetsInUse,
  amountOfAssetIssues,
  onChangeSearch,
  assetIssuesFilterActive,
  assetsInUseFilterActive,
  onResetFilters,
  assetsInUseDisabled,
  assetIssuesDisabled,
  hideChipFilter,
  searchText,
  noResultsFoundFromFilter,
  searchPlaceholder,
}: ActionBarDataAssetCatalogProps) => {
  const { palette } = useTheme();

  const border = `.0625rem ${palette.outline} solid`;

  return (
    <Box
      sx={{
        flexGrow: 0,
        borderLeft: border,
        borderRight: border,
        borderTop: border,
        borderRadius: ".25rem .25rem 0 0",
      }}
    >
      <LeftRightContent
        sx={{ pb: 2, pt: 2, pr: 4, pl: 4 }}
        leftElements={
          !hideChipFilter ? (
            <>
              <ChipFilter
                onClick={onClickAssetsInUseFilter}
                aria-disabled={assetsInUseDisabled}
                disabled={assetsInUseDisabled}
                aria-label="asset-in-use-filter"
                checked={assetsInUseFilterActive}
                sx={{ mr: 2 }}
                badgeVariant="gray"
                label="Your assets in use"
                amount={amountOfAssetsInUse}
              />
              <ChipFilter
                onClick={onClickAssetsIssuesFilter}
                aria-disabled={assetIssuesDisabled}
                disabled={assetIssuesDisabled}
                aria-label="asset-issues-filter"
                checked={assetIssuesFilterActive}
                badgeVariant="error"
                label="Your asset issues"
                amount={amountOfAssetIssues}
              />
            </>
          ) : null
        }
        rightElements={
          <>
            <SearchBar
              sx={{ width: "18rem", mr: 2 }}
              placeholder={searchPlaceholder || "Search name or organization"}
              onChange={onChangeSearch}
              value={searchText}
            />
            <ButtonDefault
              onClick={onResetFilters}
              variant="outlined"
              startIcon={<RefreshIcon />}
            >
              Reset Filters
            </ButtonDefault>
          </>
        }
      />
      {searchText && searchText.length > 0 && (
        <Box
          borderTop={border}
          pt={1}
          pb={1}
          pl={4}
          pr={4}
          bgcolor={alpha(palette.primary.main, palette.action.hoverOpacity)}
        >
          <Typography variant="body3">
            {noResultsFoundFromFilter
              ? `0 results for "${searchText}"`
              : `Results for ${searchText}`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
