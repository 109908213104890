import React from "react";
import { Refresh } from "@mui/icons-material";
import { SvgIconProps } from "@mui/material";

export const RefreshIcon = ({ sx, ...rest }: SvgIconProps) => {

  return (
    <Refresh sx={{ transform: "scale(-1, 1) rotate(45deg)", ...sx }} {...rest} />
  );
};
