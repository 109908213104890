import { Chip } from "../../common/Chip/Chip";
import CheckIcon from "@mui/icons-material/Check";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";

interface StatusCellProps {
  value: string;
}

export const StatusCell = ({ value }: StatusCellProps) => {
  let icon = <InfoOutlinedIcon />;
  let color = "error";

  if (value === "Connected") {
    icon = <CheckIcon />;
    color = "primary";
  }

  if (value === "Enabled") {
    icon = <CheckIcon />;
    color = "primary";
  }

  if (value === "Disabled") {
    icon = <DoNotDisturbIcon />;
    color = "error";
  }

  return <Chip label={value} icon={icon} color={color as any} />;
};
