import { useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { ICellRendererParams, ValueGetterParams } from "ag-grid-community";
import { AGGridWrapper } from "../../common/AGGridWrapper/AGGridWrapper";
import { useNavigate } from "react-router-dom";
import { Chip } from "../../common/Chip/Chip";
import { ProjectStatus } from "../../generated";
import { ChipPropsColorOverrides } from "@mui/material/Chip/Chip";
import { OverridableStringUnion } from "@mui/types";
import { convertToTitleCase } from "../../helpers";
import { ProjectCardActionDropdown } from "../../common/ActionBarProjects/ProjectCardActionDropdown";
import { Link } from "../../common/Link/Link";
import { FilterOperator } from "../../common/AGGridWrapper/gridHelpers";
import { palette } from "@mui/system";
import { Tag } from "../../common/Tag/Tag";
import { wrap } from "module";
import { Background } from "react-flow-renderer";

export const ProjectGridView = ({
  workspaceName,
  workspaceId,
  refetchProjectDocuments,
  rowData,
}: {
  rowData: any;
  workspaceName: string;
  workspaceId?: string;
  refetchProjectDocuments: Function;
}) => {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const columns = [
    {
      field: "name",
      headerName: "Name",
      cellRenderer: (params: any) => {
        const item = params.data;
        return (
          <Box
            display="flex"
            alignItems="center"
            sx={{
              ".name": {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }}
          >
            <Link
              variant="body3"
              fontWeight={700}
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                display: "inline-block",
                textOverflow: "ellipsis",
                color: `${palette.accent.darkest} !important`,
              }}
              title={item.name}
              to={`/workspace/${workspaceId}/project/${params?.data?.id}/project-overview`}
              tabIndex={-1}
            >
              {item.name}
            </Link>
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: (params: any) => {
        const item = params.data;
        let chipColor: OverridableStringUnion<
          "primary" | "secondary" | "error" | "info" | "success" | "warning",
          ChipPropsColorOverrides
        > = "primary";
        if (item.status === ProjectStatus.Draft) {
          chipColor = "grayAccent";
        } else if (item.status === ProjectStatus.Private) {
          chipColor = "gray";
        }
        return (
          <Chip
            sx={{
              mr: 1,
              backgroundColor: palette.grayAccent.lighter,
              color: palette.common.black,
            }}
            size="small"
            color={chipColor}
            label={convertToTitleCase(item.status)}
          />
        );
      },
      headerComponentParams: {
        description: "The status of the data asset.",
        filterOperators: [FilterOperator.IS_ANY_OF, FilterOperator.IS_NONE_OF],
      },
    },
    {
      field: "inputAssetCount",
      headerName: "Input Assets",
    },
    {
      field: "createdAt",
      headerName: "Created Assets",
    },
    {
      field: "owner",
      headerName: "Owning Org",
      valueGetter: (params: ValueGetterParams) => {
        return params.data.owner?.name;
      },
      headerComponentParams: {
        description: "The owner of the data asset.",
        filterOperators: [FilterOperator.IS_ANY_OF, FilterOperator.IS_NONE_OF],
      },
    },
    {
      field: "managers",
      headerName: "Managers",
      cellRenderer: (params: any) => {
        const managers = params.data.managers;

        return (
          <>
            {managers?.map((manager: any) => (
              <Chip
                sx={{
                  mr: 1,
                  backgroundColor: palette.grayAccent.lighter,
                  color: palette.common.black,
                }}
                size="small"
                color={"gray"}
                label={`${manager.firstName} ${manager.lastName}`}
              />
            ))}
          </>
        );
      },
      headerComponentParams: {
        description: "The manager of the project",
        filterOperators: [FilterOperator.IS_ANY_OF, FilterOperator.IS_NONE_OF],
      },
    },
    {
      field: "tags",
      headerName: "Tags",
      cellRenderer: (params: any) => {
        const tags = params.data.tags;

        return (
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {tags.map((tag: string) => (
              <Chip
                sx={{
                  mr: 1,
                  backgroundColor: palette.grayAccent.lighter,
                  color: palette.common.black,
                }}
                size="small"
                color={"gray"}
                label={tag}
              />
            ))}
          </Box>
        );
      },
      headerComponentParams: {
        description: "The tags of the data asset.",
        filterOperators: [FilterOperator.IS_ANY_OF, FilterOperator.IS_NONE_OF],
      },
    },
    // {
    //   field: "templateName",
    //   headerName: "Template",
    // },
    {
      field: "action",
      sortable: false,
      pinned: "right",
      headerName: "Action",
      cellRenderer: (params: any) => {
        const item = params.data;

        return (
          <ProjectCardActionDropdown
            workspaceId={workspaceId}
            id={item.id}
            refetchProjectDocuments={refetchProjectDocuments}
          />
        );
      },
      suppressMenu: true,
      width: 50,
      maxWidth: 85,
      resizable: false,
    },
  ];

  return (
    <Box
      className="ag-theme-material"
      sx={{
        marginTop: "-1px",

        ".ag-theme-material .ag-overlay-no-rows-wrapper.ag-layout-auto-height":
          {
            paddingTop: "0 !important",
          },
        ".ag-row .ag-cell.ag-cell-not-inline-editing.ag-cell-value > div.MuiBox-root":
          {
            flex: "1",
            maxWidth: "100%",
          },
        ".ag-row .ag-cell.ag-cell-not-inline-editing.ag-cell-value p": {
          maxWidth: "calc(100% - 36px)",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },

        ".ag-root-wrapper .ag-has-focus .ag-pinned-right-cols-container .ag-cell.ag-cell-focus":
          {
            borderLeftWidth: "1px !important",
            borderStyle: "solid !important",
          },

        ".ag-header-cell": {
          paddingLeft: "16px",
          paddingRight: "16px",
        },

        ".ag-pinned-right-cols-container": {
          ".ag-cell": {
            borderLeft: "0 !important",

            "> div": {
              textAlign: "right",
              display: "flex",
              justifyContent: "flex-end",
            },
          },
        },

        ".ag-row": {
          ".ag-cell:first-child": {
            paddingLeft: "2px",
          },
        },

        ".ag-header-cell:not(:last-child)": {
          borderRight: "0 !important",
        },

        ".ag-pinned-right-header": {
          borderLeft: "0 !important",

          ".ag-left-aligned-header": {
            justifyContent: "flex-end",
          },
        },
      }}
    >
      <AGGridWrapper
        // loading={loading}
        rowData={rowData}
        columnDefs={columns as any}
        defaultColDef={{
          sortable: true,
          flex: 1,
          minWidth: 200,
        }}
        getRowHeight={(params) => (params.node.group ? 32 : 62)}
        groupDefaultExpanded={1}
        domLayout="autoHeight"
      />
    </Box>
  );
};
