import React from "react";
import { Box, BoxProps, useTheme } from "@mui/material";
import { Check, ErrorOutline, WarningAmber } from "@mui/icons-material";
import { LinesEllipsisTypography } from "../common/LinesEllipsisTypography/LinesEllipsisTypography";

export enum Status {
  Success,
  Error,
  Warning,
}

export interface DataBoxProps {
  title?: string;
  status?: Status;
  caption?: string;
  onSubNodeClick: () => void;
}

export const DataBox = ({
  title,
  status,
  caption,
  onSubNodeClick,
  ...rest
}: DataBoxProps & BoxProps) => {
  const { palette } = useTheme();

  const getChip = (status: Status | undefined) => {
    const warningChip = (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="1.5rem"
        width="1.5rem"
        borderRadius={999}
        bgcolor={palette.warning.light}
      >
        <WarningAmber
          sx={{ height: "1rem", width: "1rem", fontSize: "1rem" }}
        />
      </Box>
    );
    const errorChip = (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="1.5rem"
        width="1.5rem"
        borderRadius={999}
        bgcolor={palette.error.light}
      >
        <ErrorOutline
          sx={{ height: "1rem", width: "1rem", fontSize: "1rem" }}
        />
      </Box>
    );
    const successChip = (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="1.5rem"
        width="1.5rem"
        borderRadius={999}
        bgcolor={palette.primary.light}
      >
        <Check sx={{ height: "1rem", width: "1rem", fontSize: "1rem" }} />
      </Box>
    );
    if (status === Status.Error) {
      return errorChip;
    } else if (status === Status.Success) {
      return successChip;
    } else if (status === Status.Warning) {
      return warningChip;
    } else {
      return null;
    }
  };

  return (
    <Box
      onClick={(event) => {
        event.stopPropagation();
        onSubNodeClick();
      }}
      borderRadius=".375rem"
      pt={3}
      pb={3}
      pl={4}
      pr={4}
      border={`.0625rem solid ${palette.grayAccent.main}`}
      {...rest}
    >
      <Box display="flex" alignItems="bottom" justifyContent="space-between">
        <LinesEllipsisTypography
          maxLines={1}
          mt="0.125rem"
          mr={2}
          variant="body3"
          text={title}
        />
        {getChip(status)}
      </Box>
      <LinesEllipsisTypography maxLines={1} variant="caption" text={caption} />
    </Box>
  );
};
