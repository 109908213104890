import { styled, Box, Tab, Button } from "@mui/material";
import { brighthiveTheme } from "../../theme/theme";

export const TopNavWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .tabLink{
    margin-bottom: 0;

    &.active{
    
      > .MuiButton-text{
        background-color: transparent;
        color: ${brighthiveTheme.palette.primary.dark};
        border-bottom-color: currentColor;
      }
    }

    > .MuiButton-text{
      padding: 12px 12px 13px;
      background-color: transparent;
      color: ${brighthiveTheme.palette.gray.dark};
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      min-width: 1px;
      border-bottom: 2px solid transparent;
      border-radius: 0;

      @media screen and (min-width: 1200px){
        padding: 12px 24px;
        font-size: 16px;
      }

      > span{
       font-size: inherit;
       font-weight: inherit;
      }

      &:hover{
        color: ${brighthiveTheme.palette.primary.dark};
      }
    }

    .MuiButton-startIcon{
      display: none;
    }
  }
`;