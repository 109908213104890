import {
  Box,
  CircularProgress,
  FormControl,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { KnowledgeCenter } from "../../KnowledgeCenter";
import { useQuery } from "@apollo/client";
import { GetSchemasDocument } from "../../../generated";
import { useNavigate, useParams } from "react-router-dom";
import { useMemo, useState } from "react";
import { BasicCard } from "../../../common/BasicCard/BasicCard";
import { Button } from "../../../common/Button/Button";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { SchemaList } from "./SchemaList";
import {
  ButtonHolder,
  CompareHeader,
  CompareWrapper,
  ContentWrapper,
  EmptyBox,
  Wrapper,
  SubHeader,
  HeaderCompareScore,
} from "./style";
import { CompareIcon } from "../../../common/Icons/CompareIcon";
import { CompareScore } from "./CompareScore";
import { responseCompare } from "./TestData/response";
import { SchemaBuilderPage } from "../SchemaBuilder/SchemaBuilderPage";
import { testSchemaOne } from "./TestData/schemaOne";
import { testSchemaTwo } from "./TestData/schemaTwoResponse";
import { clientPost } from "../../../api/baseApi";

export const SchemaCompare = ({}) => {
  const navigate = useNavigate();
  const [schemaOne, setSchemaOne] = useState<any>();
  const [schemaTwo, setSchemaTwo] = useState<any>();
  const [comparisonResult, setComparisonResult] = useState<any>(false);
  const { palette } = useTheme();
  const { workspaceId } = useParams();
  const { data, loading } = useQuery(GetSchemasDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
    },
  });

  console.log(schemaOne)

  const getCompareResult = () => {
    const json1 = JSON.parse(schemaOne.jsonSchema);
    const json2 = JSON.parse(schemaTwo.jsonSchema);
    clientPost(
      `${process.env.REACT_APP_COMPARE_SCHEMA_URL}/compare-schemas` || "",
      { schema1: json1, schema2: json2 }
    ).then((res) => {
      console.log(res?.data);
      setComparisonResult(res?.data);
    });
  };

  const rowData = useMemo(() => {
    return (
      data?.workspace?.schemas?.map((asset) => ({
        ...asset,
      })) || []
    );
  }, [data?.workspace?.schemas]);

  return (
    <KnowledgeCenter
      title="Schemas"
      back={true}
      rightElement={<SchemaBuilderPage buttonText="Create" />}
    >
      <Wrapper>
        <Typography variant="h6" className="heading">
          Compare Schemas
        </Typography>
        <Box sx={{ flex: 1 }}>
          {!loading ? (
            <BasicCard
              sx={{
                background: "white",
                boxShadow: "none",
                border: `1px solid ${palette.action.disabledBackground}`,
                maxWidth: "1175px",
                height: "100%",
              }}
            >
              <SubHeader>
                <ButtonHolder>
                  <Button
                    type="button"
                    disabled={!(schemaOne && schemaTwo)}
                    onClick={() => {
                      getCompareResult();
                    }}
                    sx={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: "700",
                      padding: "8px 12px",
                      color: palette.grayAccent.main,
                      backgroundColor: palette.common.white,
                      border: `1px solid ${palette.action.disabledBackground}`,

                      "&:hover": {
                        color: palette.primary.dark,
                        borderColor: palette.primary.dark,
                        backgroundColor: palette.primary.lighter,
                      },
                    }}
                  >
                    <CompareIcon sx={{ marginRight: "8px" }} /> Compare Schemas
                  </Button>
                </ButtonHolder>
                {comparisonResult && (
                  <HeaderCompareScore>
                    <Typography variant="h6">Comparison Score</Typography>
                    <CompareScore
                      data={comparisonResult?.overall_comparison_score}
                      className="rounded"
                    />
                  </HeaderCompareScore>
                )}
              </SubHeader>
              <ContentWrapper>
                <Typography variant="body2" className="sub-heading">
                  Choose schemas to compare one another
                </Typography>

                <CompareWrapper>
                  <CompareHeader>
                    <FormControl
                      className="select-dropdown"
                      placeholder="please select a schema"
                      fullWidth
                    >
                      <Select
                        onChange={(e) => {
                          const val = rowData.find(
                            (row) => row.id === e.target.value
                          );
                          // setSchemaOne(testSchemaOne);
                          setSchemaOne(val);
                          setComparisonResult(false);
                        }}
                        value={schemaOne?.id}
                        placeholder="please select a schema"
                        sx={{
                          "& .MuiInputBase-input": {
                            padding: ".375rem 1.625rem .375rem .75rem",
                          },
                        }}
                      >
                        {rowData
                          ?.filter(
                            (row) => row.name && row.name !== schemaTwo?.name
                          )
                          .map((el) => (
                            <MenuItem value={el?.id}>{el.name}</MenuItem>
                          ))}
                        <SchemaBuilderPage
                          buttonText="Add Schema"
                          className="inside-drop"
                        />
                      </Select>
                    </FormControl>
                    {comparisonResult ? (
                      <HeaderCompareScore>
                        <CompareScore
                          data={comparisonResult?.overall_comparison_score}
                        />
                      </HeaderCompareScore>
                    ) : (
                      <EmptyBox />
                    )}
                    <FormControl className="select-dropdown" fullWidth>
                      <Select
                        onChange={(e) => {
                          const val = rowData.find(
                            (row) => row.id === e.target.value
                          );
                          setSchemaTwo(val);
                          // setSchemaTwo(testSchemaTwo);
                          setComparisonResult(false);
                        }}
                        value={schemaTwo?.id}
                        placeholder="please select a schema"
                        sx={{
                          "& .MuiInputBase-input": {
                            padding: ".375rem 1.625rem .375rem .75rem",
                          },
                        }}
                      >
                        {rowData
                          ?.filter(
                            (row) => row.name && row.name !== schemaOne?.name
                          )
                          .map((el) => (
                            <MenuItem value={el?.id}>{el.name}</MenuItem>
                          ))}

                        <SchemaBuilderPage
                          buttonText="Add Schema"
                          className="inside-drop"
                        />
                      </Select>
                    </FormControl>
                  </CompareHeader>
                  <Box sx={{ overflow: "auto" }}>
                    {schemaOne && (
                      <SchemaList
                        baseSchema={schemaOne}
                        comparableSchema={schemaTwo}
                        compare={comparisonResult?.field_comparisons}
                      />
                    )}
                  </Box>
                </CompareWrapper>
              </ContentWrapper>
            </BasicCard>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress sx={{ color: palette.primary.darkest }} />
            </Box>
          )}
        </Box>
      </Wrapper>
    </KnowledgeCenter>
  );
};
