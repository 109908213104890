import React, { useState } from "react";
import { BrighthiveLogoSvg } from "../common/Icons/BrighthiveLogoSvg";
import { Label } from "../common/Label/Label";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Box, useTheme } from "@mui/material";
import { Button } from "../common/Button/Button";
import { useMutation } from "@apollo/client";
import { LoginDocument } from "../generated";
import { SimpleLoader } from "../common/Loader/Loader";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { Typography } from "../common/Typography/Typography";

export interface LoginProps {}

interface State {
  username: string;
  password: string;
}

export const Login = () => {
  const navigate = useNavigate();
  const { palette } = useTheme();

  const [login] = useMutation(LoginDocument);

  const [loginInProgress, setLoginInProgress] = useState(false);

  const [values, setValues] = React.useState<State>({
    username: "",
    password: "",
  });

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const isDisabled = () => {
    if (
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{50,}$/.test(values.password)
    ) {
      return true;
    }
    if (
      values.username.length === 0 ||
      values.password.length === 0 ||
      values.password.length < 8
    ) {
      return true;
    }

    return false;
  };

  const onClickLogin = (values: State) => {
    setLoginInProgress(true);
    login({
      variables: {
        input: { username: values.username, password: values.password },
      },
    })
      .then((res) => {
        setLoginInProgress(false);
        if (
          !res.data?.login.success &&
          !res.data?.login?.challengeResponse?.name
        ) {
          throw new Error("Login failed");
        }
        if (res.data?.login?.idToken) {
          localStorage.setItem("token", res.data.login.idToken);
          localStorage.setItem("accessToken", res.data.login.accessToken || "");
          localStorage.setItem(
            "refreshToken",
            res.data?.login?.refreshToken || ""
          );
          localStorage.setItem("username", values.username);

          navigate("/workspace");
        } else if (
          res.data?.login?.challengeResponse?.name == "NEW_PASSWORD_REQUIRED"
        ) {
          console.log("Change Password");
          navigate(
            `/register-user/${values.username}/${res.data?.login?.challengeResponse?.session}`
          );
        } else {
          throw new Error("Login failed");
        }
      })
      .catch((err) => {
        setLoginInProgress(false);
        toast.error("Invalid username or password.");
        console.error(err);
      });
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        p: 6,
        m: "auto",
        width: "28.5rem",
        background: "white",
        borderRadius: ".375rem",
        boxShadow:
          `0 .0625rem .0625rem ${palette.customRolled.border}, 0 .125rem .0625rem ${palette.action.disabledBackground}, 0 .0625rem .1875rem ${palette.customRolled.contrastText}`,
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="left">
        <BrighthiveLogoSvg
          sx={{
            height: "1.875rem",
            width: "155px",
            mb: 6,
          }}
        />
      </Box>
      <Typography
        variant="h5"
        color={palette.common.black}
        sx={{ margin: "0 0 24px" }}
      >
        Login
      </Typography>
      {loginInProgress ? (
        <Box
          display="flex"
          flex="column"
          alignItems="center"
          justifyContent="center"
        >
          <SimpleLoader text="Logging in..." />
        </Box>
      ) : (
        <>
          <Label value="Username" variant="subtitle3" sx={{ mb: ".375rem" }} />
          <TextField
            name="username"
            value={values.username}
            hiddenLabel
            fullWidth
            onChange={handleChange("username")}
            inputProps={{ sx: { fontSize: "1rem", padding: ".375rem .75rem" } }}
            size="small"
            sx={{ mb: 4 }}
          />
          <Label value="Password" variant="subtitle3" sx={{ mb: ".375rem" }} />
          <OutlinedInput
            type="password"
            name="password"
            fullWidth
            notched={false}
            sx={{ mb: 4 }}
            value={values.password}
            onChange={handleChange("password")}
            inputProps={{ sx: { fontSize: "1rem", padding: ".375rem .75rem" } }}
            label="Password"
          />
          <Box
            sx={{
              padding: "8px 0 16px",
              a: {
                color: palette.link.main,
                textDecoration: "none",
                fontSize: "14px",
                lineHeight: "1",
                fontWeight: "700",
              },
            }}
          >
            <Link to="/login/forgotPassword">Forgot password?</Link>
          </Box>
          <Button
            style={{ marginTop: "1rem" }}
            onClick={() => onClickLogin(values)}
            disabled={isDisabled()}
            fullWidth
            variant="contained"
          >
            Login
          </Button>{" "}
        </>
      )}
    </Box>
  );
};
