import { Snackbar, SnackbarCloseReason, SnackbarOrigin } from "@mui/material";
import { ReactNode } from "react";

interface SnakBarProps {
  open: boolean;
  anchorOrigin?: SnackbarOrigin | undefined;
  message: string;
  autoHideDuration?: number;
  onClose?:
    | ((
        event: Event | React.SyntheticEvent<any, Event>,
        reason: SnackbarCloseReason
      ) => void)
    | undefined;
  action?: ReactNode;
}

export const SnackBar = ({
  open,
  anchorOrigin,
  message,
  autoHideDuration,
  onClose,
  action,
}: SnakBarProps) => {
  return (
    <Snackbar
      open={open}
      anchorOrigin={anchorOrigin}
      message={message}
      autoHideDuration={6000}
      onClose={onClose}
      action={action}
    />
  );
};
SnackBar.defaultProps = {
  anchorOrigin: { vertical: "top", horizontal: "right" },
  autoHideDuratin: 6000,
};
