import React, { useEffect, useRef, useState } from "react";
import { IHeaderParams } from "ag-grid-enterprise";
import { Typography } from "../Typography/Typography";
import { Box, IconButton, ListItemText, PopoverOrigin, useTheme, } from "@mui/material";
import { FilterIcon } from "../Icons/FilterIcon";
import { ArrowDownward, ArrowUpward, MoreVert } from "@mui/icons-material";
import { MenuItem } from "../Menu/MenuItem";
import { Menu } from "../Menu/Menu";
import { TextPopoverFilter } from "./TextPopoverFilter";
import { DateFilterOperator, NumberFilterOperator, TextFilterOperator } from "./gridHelpers";
import { NumberPopoverFilter } from "./NumberPopoverFilter";
import { DatePopoverFilter } from "./DatePopoverFilter";
import { BooleanPopoverFilter } from "./BooleanPopoverFilter";
import { TagsPopoverFilter } from "./TagsPopoverFilter";

enum SortState {
  ASC = "asc",
  DESC = "desc",
  INACTIVE = "",
}

interface SpecificColumnHeaderParams {
  headerAlignment?: "left" | "right";
  description?: string;
  filterOperators?: TextFilterOperator[] | NumberFilterOperator[];
}

type ColumnHeaderParams = IHeaderParams & SpecificColumnHeaderParams;

export const ColumnHeader = (props: ColumnHeaderParams) => {
  const { palette } = useTheme();
  const [anchorElementMenu, setAnchorElementMenu] =
    React.useState<null | Element>(null);
  const [anchorElementFilter, setAnchorElementFilter] =
    React.useState<null | Element>(null);
  const [filterOpen, setFilterOpen] = React.useState<boolean>(false);
  const headerRef = useRef();
  const menuOpen = Boolean(anchorElementMenu);
  const openMenu = () => {
    const field = props.column.getColDef().field;
    const anchorEl = document.querySelector(`[col-id=${field}]`);
    setAnchorElementMenu(anchorEl);
    setAnchorElementFilter(anchorEl);
  };
  const closeMenu = () => {
    setAnchorElementMenu(null);
  };
  const closeFilter = () => {
    setFilterOpen(false);
  };
  const onClickSortByAscMenu = () => {
    closeMenu();
    props.setSort(SortState.ASC, false);
  };

  const onClickSortByDescMenu = () => {
    closeMenu();
    props.setSort(SortState.DESC, false);
  };

  const onClickOpenFilter = () => {
    setFilterOpen(true);
    closeMenu();
  };
  const [sortState, setSortState] = useState(SortState.INACTIVE);

  useEffect(() => {
    const onSortChanged = () => {
      if (props.column.isSortAscending()) {
        setSortState(SortState.ASC);
      } else if (props.column.isSortDescending()) {
        setSortState(SortState.DESC);
      } else if (
        !props.column.isSortAscending() &&
        !props.column.isSortDescending()
      ) {
        setSortState(SortState.INACTIVE);
      }
    };

    props.column.addEventListener("sortChanged", onSortChanged);
    onSortChanged();
    return () => {
      props.column.removeEventListener("sortChanged", onSortChanged);
    };
  }, [props.column]);

  let menuButton = null;
  if (props.enableMenu) {
    menuButton = (
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          openMenu();
        }}
        className="header-action-menu-button"
        sx={{ color: palette.grayAccent.main }}
      >
        <MoreVert sx={{ width: "1rem", height: "1rem" }} />
      </IconButton>
    );
  }

  let sortButton = null;
  if (props.enableSorting) {
    sortButton = (
      <div className="header-sort-button">
        <IconButton
          sx={{
            color: palette.primary.main,
            display: sortState === SortState.ASC ? "inline-flex" : "none",
          }}
          onClick={(e) => props.setSort(SortState.DESC, e.shiftKey)}
        >
          <ArrowUpward sx={{ width: "1rem", height: "1rem" }} />
        </IconButton>
        <IconButton
          sx={{
            color: palette.primary.main,
            display: sortState === SortState.DESC ? "inline-flex" : "none",
          }}
          onClick={(e) => props.setSort(null, e.shiftKey)}
        >
          <ArrowDownward sx={{ width: "1rem", height: "1rem" }} />
        </IconButton>
        <IconButton
          className={
            sortState === SortState.INACTIVE ? "header-initial-sort-button" : ""
          }
          sx={{
            display: "none",
            color: palette.action.disabled,
          }}
          onClick={(e) => props.setSort(SortState.ASC, e.shiftKey)}
        >
          <ArrowUpward sx={{ width: "1rem", height: "1rem" }} />
        </IconButton>
      </div>
    );
  }

  let filterButton = null;
  if (props.column.isFilterAllowed() && props.column.isFilterActive()) {
    filterButton = (
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onClickOpenFilter();
        }}
        className="header-filter-button"
        sx={{
          color: palette.primary.main,
          flexShrink: 0,
        }}
      >
        <FilterIcon sx={{ width: "1rem", height: "1rem" }} />
      </IconButton>
    );
  }

  const ascSortEnabled = props.enableSorting && sortState !== SortState.ASC;
  const descSortEnabled = props.enableSorting && sortState !== SortState.DESC;
  const filterEnabled = props.column.isFilterAllowed();

  const anchorOrigin: PopoverOrigin =
    props.headerAlignment === "left"
      ? {
          vertical: "bottom",
          horizontal: "right",
        }
      : {
          vertical: "bottom",
          horizontal: "left",
        };
  const transformOrigin: PopoverOrigin =
    props.headerAlignment === "left"
      ? {
          vertical: "top",
          horizontal: "right",
        }
      : {
          vertical: "top",
          horizontal: "left",
        };

  const onClickHeader = (e: React.MouseEvent<HTMLDivElement>) => {
    if (props.enableSorting) {
      if (sortState === SortState.ASC) {
        props.setSort(SortState.DESC);
      } else if (sortState === SortState.DESC) {
        props.setSort(null);
      } else {
        props.setSort(SortState.ASC);
      }
    }
    return;
  };

  const columnType = props?.column?.getColDef()?.type;

  return (
    <>
      <Box
        className={props.headerAlignment === "right" ? "ag-right-aligned-header" : "ag-left-aligned-header"}
        ref={headerRef}
        onClick={onClickHeader}
        display="flex"
        alignItems="center"
        width="100%"
        height="100%"
      >
        {filterButton}
        <Typography
          className="header-display-name-text"
          overflow="hidden"
          textOverflow="ellipsis"
          color={palette.common.black}
          variant="body3"
          fontWeight={700}
        >
          {props.displayName}
        </Typography>
        {sortButton}
        {menuButton}
      </Box>
      <Menu
        aria-label={`action-menu-${props.column.getColDef().field}`}
        anchorEl={anchorElementMenu}
        open={menuOpen}
        onClose={closeMenu}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <MenuItem disabled={!ascSortEnabled} onClick={onClickSortByAscMenu}>
          <ArrowUpward sx={{ mr: ".375rem" }} />
          <ListItemText>Sort By ASC</ListItemText>
        </MenuItem>
        <MenuItem disabled={!descSortEnabled} onClick={onClickSortByDescMenu}>
          <ArrowDownward sx={{ mr: ".375rem" }} />
          <ListItemText>Sort By DESC</ListItemText>
        </MenuItem>
        <MenuItem disabled={!filterEnabled} onClick={onClickOpenFilter}>
          <FilterIcon sx={{ mr: ".375rem" }} />
          <ListItemText>Filter</ListItemText>
        </MenuItem>
      </Menu>
      { filterEnabled && (columnType === "textColumn") && (
          <TextPopoverFilter
            gridApi={props.api}
            column={props.column}
            displayName={props.displayName}
            anchorEl={anchorElementFilter}
            onClose={closeFilter}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            open={filterOpen}
            filterOperators={props.filterOperators as TextFilterOperator[]}
          />
        )}
      { filterEnabled && columnType === "numberColumn" && (
        <NumberPopoverFilter
          gridApi={props.api}
          column={props.column}
          displayName={props.displayName}
          anchorEl={anchorElementFilter}
          onClose={closeFilter}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          open={filterOpen}
          filterOperators={props.filterOperators as NumberFilterOperator[]}
        />
      )}
      { filterEnabled && columnType === "dateColumn" && (
        <DatePopoverFilter
          gridApi={props.api}
          column={props.column}
          displayName={props.displayName}
          anchorEl={anchorElementFilter}
          onClose={closeFilter}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          open={filterOpen}
          filterOperators={props.filterOperators as DateFilterOperator[]}
        />
      )}
      { filterEnabled && columnType === "booleanColumn" && (
        <BooleanPopoverFilter
          gridApi={props.api}
          column={props.column}
          displayName={props.displayName}
          anchorEl={anchorElementFilter}
          onClose={closeFilter}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          open={filterOpen}
        />
      )}
      { filterEnabled && columnType === "tagsColumn" && (
        <TagsPopoverFilter
          gridApi={props.api}
          column={props.column}
          displayName={props.displayName}
          anchorEl={anchorElementFilter}
          onClose={closeFilter}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          open={filterOpen}
        />
      )}
    </>
  );
};
