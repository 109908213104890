import { styled, Box, IconButton, Typography, Button } from '@mui/material';
import { ArrowBack } from "@mui/icons-material";
import { brighthiveTheme } from "../../../theme/theme";

export const ButtonPrimary = styled(Button)`
  background-color: ${brighthiveTheme.palette.primary.main};
  color: ${brighthiveTheme.palette.primary.darkest};
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: none;
  min-width: 1px;

  &:hover{
    background-color: ${brighthiveTheme.palette.primary.dark};
    color: ${brighthiveTheme.palette.common.white};
    border-color: transparent;
    box-shadow: none;
  }

  svg{
    width: 18px;
    height: 18px;
  }
`;