import { Box, BoxProps, useTheme } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Delete } from "@mui/icons-material";

import { Button } from "../common/Button/Button";
import { Typography } from "../common/Typography/Typography";
import { Label } from "../common/Label/Label";

export interface GoalAdditionProps {
  id?: string;
  name?: string;
  description?: string;
  onModify?: (goal: { id?: string; name: string; description: string }) => void;
  onDelete?: () => void;
}

export const GoalAddition = ({
  id,
  name = "",
  description = "",
  onModify,
  onDelete,
  ...rest
}: GoalAdditionProps & BoxProps) => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: palette.background.default,
        padding: "1rem",
        marginBottom: "1rem",
      }}
      {...rest}
    >
      <Typography mb={"1rem"}>Goal</Typography>
      <Label value="Goal name" variant="body3" mb={1.5} />
      <TextField
        hiddenLabel
        fullWidth
        defaultValue={name}
        onChange={(e) =>
          onModify &&
          onModify({
            id,
            description,
            name: e.target.value,
          })
        }
        inputProps={{
          sx: {
            fontSize: "1rem",
            padding: ".375rem .75rem",
            background: palette.common.white,
          },
        }}
        size="small"
        sx={{ mb: 5 }}
      />
      <Label value="Goal Description" variant="body3" mb={1.5} />
      <TextField
        multiline
        hiddenLabel
        fullWidth
        defaultValue={description}
        onChange={(e) =>
          onModify &&
          onModify({
            id,
            name,
            description: e.target.value,
          })
        }
        sx={{ background: palette.common.white, mb: 2 }}
        inputProps={{
          sx: { fontSize: "1rem", background: palette.common.white },
        }}
        size="small"
      />
      <Button
        startIcon={<Delete />}
        color={"error"}
        variant={"text"}
        onClick={onDelete}
      >
        Delete goal
      </Button>
    </Box>
  );
};
