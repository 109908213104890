import { useMutation, useQuery } from "@apollo/client";
import * as Yup from "yup";
import {
  AddSourceDocument,
  GetSourceDefinitionSpecificationDocument,
  SourceDefinitionOutput,
} from "../../../../generated";
import FormRJS from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { useParams } from "react-router-dom";
import {
  Box,
  FormControl,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { Label } from "../../../../common/Label/Label";
import { Select, TextField } from "@mui/material";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { SimpleLoader } from "../../../../common/Loader/Loader";
import { useState } from "react";

export const Specification = ({
  definition,
  close,
}: {
  definition: SourceDefinitionOutput;
  close: Function;
}) => {
  const { workspaceId } = useParams();
  const { palette } = useTheme();
  const [isLoading, setIsLoading] = useState(false);

  const { data } = useQuery(GetSourceDefinitionSpecificationDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
      sourceDefinitionSpecFilter: {
        sourceDefinitionId: definition?.sourceDefinitionId || "",
      },
    },
  });

  const [mutate] = useMutation(AddSourceDocument);

  const schema =
    data?.workspace?.services?.sourceDefinitionSpecification
      ?.connectionSpecification;

  const submitHandler = (values: any) => {
    let sourceName = definition.documentationUrl.split("/");
    values.sourceConfigs = {
      ...values.sourceConfigs,
      sourceType: sourceName[sourceName.length - 1],
    };
    setIsLoading(true);

    mutate({
      variables: {
        input: {
          ...values,
          name: values.name,
          documentationUrl: values.documentationUrl,
          description: values.description,
          connectionSchedule: {
            scheduleType: values.connectionSchedule.scheduleType,
            ...(values.connectionSchedule.scheduleType === "CRON" &&
              values.connectionSchedule.cronExpression.length > 0 && {
                cronExpression: values.connectionSchedule.cronExpression,
              }),
          },
          workspaceId: workspaceId || "",
          sourceConfigs: JSON.stringify(values.sourceConfigs),
        },
      },
    }).then((res) => {
      setIsLoading(false);
      if (res.data?.addSource) {
        toast.success("Source Added");
        close();
      } else {
        toast.error("Could not Add Source");
      }
    });
  };

  return (
    <>
      {!schema ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ margin: "2rem 0", ">div": { minWidth: "100%" } }}
        >
          <SimpleLoader text="Fetching Form..." />
        </Box>
      ) : (
        <Formik
          validationSchema={Yup.object().shape({
            name: Yup.string().required(),
            documentationUrl: Yup.string().required(),
            description: Yup.string().required(),
          })}
          initialValues={{
            name: definition.name,
            documentationUrl: definition.documentationUrl,
            description: "",
            connectionSchedule: {
              scheduleType: "MANUAL",
              cronExpression: "",
            },
            sourceConfigs: "",
          }}
          onSubmit={(data) => console.log(data)}
        >
          {({ handleChange, values, setFieldValue, validateForm }) => (
            <Box
              sx={{
                "#root__title": {
                  marginTop: "0",
                  marginBottom: "0",
                  display: "none",

                  "+ h6": {
                    color: palette.gray.darkest,
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "20px",
                    marginTop: "0 !important",
                    marginBottom: "0",
                  },

                  h5: {
                    color: palette.common.black,
                    letterSpacing: "0.0025em",
                    fontWeight: "500",
                    fontSize: "24px",
                    lineHeight: "28px",
                    paddingBottom: "17px",
                    marginBottom: "24px",
                    backgroundImage: `linear-gradient(45deg, ${palette.action.disabledBackground} 0%, ${palette.action.disabledBackground} 100%)`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "0 100%",
                    backgroundSize: "calc(100% - 104px) 1px",
                  },

                  "+ div": {
                    marginTop: "0 !important",

                    "[id*='__title']": {
                      "+ h6": {
                        marginTop: "0",
                        marginBottom: "8px",
                        color: palette.gray.darkest,
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "24px",
                      },

                      h5: {
                        borderTop: `1px solid ${palette.action.disabledBackground}`,
                        paddingTop: "24px",
                        color: palette.common.black,
                        letterSpacing: "0.0025em",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "20px",
                        marginBottom: "8px",
                      },

                      "> hr": {
                        display: "none",
                      },
                    },

                    "> div": {
                      paddingTop: "0",
                      marginBottom: "24px !important",
                    },

                    ".MuiFormControl-root.MuiTextField-root": {
                      width: "100%",

                      "> .MuiInputLabel-formControl": {
                        position: "static",
                        transform: "none",
                        color: palette.common.black,
                        letterSpacing: "0.0025em",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "20px",
                        marginBottom: "6px",
                      },

                      ".MuiInputBase-formControl": {
                        borderRadius: "3px",
                        height: "36px !important",
                        padding: "7px 0",

                        "> .MuiSelect-select": {
                          "&:before": {
                            content: "''",
                            position: "absolute",
                            right: "15px",
                            top: "12px",
                            border: `solid ${palette.gray.dark}`,
                            borderWidth: "0 2px 2px 0",
                            transform: "rotate(45deg)",
                            width: "7px",
                            height: "7px",
                          },

                          "&[aria-expanded='true']": {
                            "&:before": {
                              transform: "rotate(-135deg)",
                              top: "16px",
                            },
                          },
                        },

                        ".MuiSelect-icon": {
                          display: "none",
                        },

                        fieldset: {
                          borderColor: palette.grayAccent.light,
                          top: "0",
                          borderWidth: "1px",

                          legend: {
                            display: "none",
                          },
                        },

                        ".MuiOutlinedInput-input": {
                          color: palette.common.black,
                          fontSize: "14px",
                          lineHeight: "23px",
                          fontWeight: "400",
                          paddingLeft: "12px",
                          paddingRight: "12px",

                          "&::placeholder": {
                            color: palette.gray.dark,
                            opacity: "1",
                          },
                        },
                      },
                    },

                    ".MuiTypography-caption": {
                      h6: {
                        marginTop: "8px",
                        fontSize: "12px",
                        lineHeight: "18px",
                        color: palette.grayAccent.light,
                        letterSpacing: "0.001rem",
                        fontWeight: "400",
                      },
                    },

                    ".form-group.field": {
                      ".MuiPaper-elevation": {
                        boxShadow: "none",
                        border: "0",
                        borderRadius: "0",
                        padding: "0",

                        ".MuiGrid-item > .MuiIconButton-sizeSmall": {
                          marginTop: "18px",
                        },

                        ".MuiPaper-elevation": {
                          padding: "0",
                        },

                        "> div": {
                          padding: "0",

                          "> .MuiGrid-root.MuiGrid-container": {
                            "> .MuiGrid-root.MuiGrid-container:last-child": {
                              justifyContent: "center",
                            },
                          },
                        },

                        h5: {
                          color: palette.common.black,
                          letterSpacing: "0.0025em",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "20px",
                          marginTop: "0",
                          marginBottom: "6px",

                          "+ hr": {
                            display: "none",
                          },
                        },

                        h6: {
                          marginTop: "0",
                          marginBottom: "8px",
                          color: palette.gray.darkest,
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "24px",
                        },
                      },
                    },
                  },

                  hr: {
                    display: "none",
                  },
                },

                ".panel.panel-default.panel-body": {
                  ".form-group.field": {
                    marginTop: "24px",

                    ".form-group.field:only-child": {
                      marginTop: "0",
                    },
                  },

                  ".MuiGrid-container": {
                    ".MuiIconButton-sizeSmall": {
                      marginTop: "26px",
                      width: "32px",
                      height: "30px",
                    },

                    ".MuiGrid-item + .MuiGrid-container": {
                      justifyContent: "center",
                    },
                  },
                },

                ".MuiGrid-item": {
                  overflow: "hidden !important",

                  "> .MuiIconButton-sizeSmall": {
                    marginTop: "20px",
                    marginLeft: "10px",
                    backgroundColor: palette.warning.light,
                    color: palette.common.black,

                    "&:hover": {
                      backgroundColor: palette.customRolled.main,
                    },
                  },

                  ".MuiIconButton-colorPrimary": {
                    backgroundColor: palette.primary.main,
                    color: palette.common.white,

                    "&:hover": {
                      backgroundColor: palette.primary.dark,
                    },
                  },
                },

                form: {
                  "> .MuiBox-root": {
                    display: "flex",
                    justifyContent: "flex-start",

                    ".MuiButton-containedPrimary": {
                      borderRadius: "3px",
                      boxShadow: "none",
                      padding: "8px 12px",
                      fontSize: "14px",
                      fontWeight: "700",
                      lineHeight: "20px",
                    },
                  },
                },
              }}
            >
              {isLoading && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    position: "absolute",
                    left: "0",
                    right: "0",
                    top: "0",
                    bottom: "0",
                    background: palette.disabledLightTextOnDarkBackground,
                    zIndex: "99",
                    ">div": { minWidth: "100%" },
                  }}
                >
                  <SimpleLoader text="Adding Source ..." />
                </Box>
              )}
              <Box sx={{ padding: "1rem 0 0" }}>
                <Box
                  sx={{
                    h5: {
                      margin: "0 0 1rem",
                      borderBottom: `1px solid ${palette.action.disabledBackground}`,
                      paddingBottom: "5px",
                    },
                  }}
                >
                  <Typography variant="h5" color={palette.common.black} mb={8}>
                    Source Details
                  </Typography>
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"flex-start"}
                  flexDirection="column"
                  mb={4}
                  sx={{
                    h6: {
                      color: palette.common.black,
                      letterSpacing: "0.0025em",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "20px",
                      marginBottom: "6px",
                    },
                  }}
                >
                  <Label value="Name" />
                  <TextField
                    hiddenLabel
                    fullWidth
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    inputProps={{
                      sx: { fontSize: "1rem", padding: ".375rem .75rem" },
                    }}
                    size="small"
                  />
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"flex-start"}
                  flexDirection="column"
                  mb={4}
                  sx={{
                    h6: {
                      color: palette.common.black,
                      letterSpacing: "0.0025em",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "20px",
                      marginBottom: "6px",
                    },
                  }}
                >
                  <Label value="Description" />
                  <TextField
                    hiddenLabel
                    fullWidth
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    multiline
                    inputProps={{
                      sx: { fontSize: "1rem", padding: ".375rem .75rem" },
                    }}
                    size="small"
                  />
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"flex-start"}
                  flexDirection="column"
                  mb={4}
                  sx={{
                    h6: {
                      color: palette.common.black,
                      letterSpacing: "0.0025em",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "20px",
                      marginBottom: "6px",
                    },
                  }}
                >
                  <Label value="Documentation Url" />
                  <TextField
                    hiddenLabel
                    fullWidth
                    name="documentationUrl"
                    value={values.documentationUrl}
                    onChange={handleChange}
                    inputProps={{
                      sx: { fontSize: "1rem", padding: ".375rem .75rem" },
                    }}
                    size="small"
                  />
                </Box>
                <FormControl
                  fullWidth
                  sx={{
                    marginBottom: "24px",

                    ".MuiInputBase-formControl": {
                      borderRadius: "3px",
                    },

                    fieldset: {
                      borderColor: palette.grayAccent.light,
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: palette.common.black,
                      letterSpacing: "0.0025em",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "20px",
                      marginBottom: "6px",
                    }}
                  >
                    Connection Schedule
                  </Typography>
                  <Select
                    value={values.connectionSchedule.scheduleType}
                    name="connectionSchedule.scheduleType"
                    onChange={handleChange}
                    sx={{
                      "> div": {
                        borderRadius: "3px",
                        background: palette.common.white,
                        height: "20px !important",
                        padding: "7px 15px",
                        fontSize: "14px",
                        lineHeight: "1",
                        fontWeight: "400",
                        display: "flex",
                        alignItems: "center",

                        "&:before": {
                          content: "''",
                          position: "absolute",
                          right: "15px",
                          top: "10px",
                          border: `solid ${palette.gray.dark}`,
                          borderWidth: "0 2px 2px 0",
                          transform: "rotate(45deg)",
                          width: "7px",
                          height: "7px",
                        },

                        "&[aria-expanded='true']": {
                          "&:before": {
                            transform: "rotate(-135deg)",
                            top: "14px",
                          },
                        },

                        svg: {
                          width: "20px",
                          height: "20px",
                          color: palette.grayAccent.light,
                          marginRight: "3px",
                        },
                      },

                      ".MuiSelect-icon": {
                        display: "none",
                      },
                    }}
                  >
                    <MenuItem
                      value={"MANUAL"}
                      sx={{
                        padding: "8px",
                        borderRadius: "4px",
                        border: `1px solid ${palette.customRolled.lighter}`,
                        fontSize: "14px",
                        lineHeight: "20px",
                        letterSpacing: "0.0025rem",
                        fontWeight: "500",
                        margin: "8px",

                        "&:first-child": {
                          marginTop: "0",
                        },

                        "&:last-child": {
                          marginBottom: "0",
                        },

                        svg: {
                          width: "24px",
                          height: "24px",
                          color: palette.grayAccent.light,
                          marginRight: "12px",
                          verticalAlign: "top",
                        },
                      }}
                    >
                      Manual
                    </MenuItem>
                    <MenuItem
                      value={"CRON"}
                      sx={{
                        padding: "8px",
                        borderRadius: "4px",
                        border: `1px solid ${palette.customRolled.lighter}`,
                        fontSize: "14px",
                        lineHeight: "20px",
                        letterSpacing: "0.0025rem",
                        fontWeight: "500",
                        margin: "8px",

                        "&:first-child": {
                          marginTop: "0",
                        },

                        "&:last-child": {
                          marginBottom: "0",
                        },

                        svg: {
                          width: "24px",
                          height: "24px",
                          color: palette.grayAccent.light,
                          marginRight: "12px",
                          verticalAlign: "top",
                        },
                      }}
                    >
                      Cron
                    </MenuItem>
                  </Select>
                </FormControl>
                {values.connectionSchedule.scheduleType === "CRON" && (
                  <Box
                    display={"flex"}
                    alignItems={"flex-start"}
                    flexDirection="column"
                    mb={4}
                    sx={{
                      h6: {
                        color: palette.common.black,
                        letterSpacing: "0.0025em",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "20px",
                        marginBottom: "6px",
                      },
                    }}
                  >
                    <Label value="Cron Expression" />
                    <TextField
                      hiddenLabel
                      fullWidth
                      name="connectionSchedule.cronExpression"
                      value={values.connectionSchedule.cronExpression}
                      onChange={handleChange}
                      inputProps={{
                        sx: { fontSize: "1rem", padding: ".375rem .75rem" },
                      }}
                      size="small"
                    />
                  </Box>
                )}
              </Box>
              <FormRJS
                schema={JSON.parse(schema)}
                formData={values.sourceConfigs}
                validator={validator}
                disabled={isLoading}
                onChange={(data) =>
                  setFieldValue("sourceConfigs", data.formData)
                }
                onSubmit={async () => {
                  const val = await validateForm();
                  if (Object.keys(val).length === 0) {
                    submitHandler(values);
                  }
                }}
              />
            </Box>
          )}
        </Formik>
      )}
    </>
  );
};
