import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const BrightBotViiIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.9982 5.65258C6.9659 5.54205 6.9356 5.45521 6.89892 5.35968C6.64747 4.72446 6.3068 4.12752 5.887 3.58648C5.17506 2.6519 4.28277 1.86656 3.26189 1.27605C3.0238 1.14653 2.77616 1.03503 2.52109 0.942486C2.50714 0.765023 2.43407 0.597167 2.31336 0.465255C2.19264 0.333342 2.03111 0.244837 1.85411 0.213625C1.67711 0.182413 1.49465 0.21026 1.33535 0.292798C1.17606 0.375335 1.04895 0.507892 0.973968 0.669672C0.898984 0.831452 0.880367 1.0133 0.921039 1.18669C0.961711 1.36007 1.05937 1.51518 1.19869 1.62769C1.33802 1.74019 1.51112 1.80372 1.69085 1.80831C1.87058 1.81289 2.04676 1.75828 2.19176 1.65303C2.76749 1.80342 3.32848 2.14488 3.81052 2.50015C4.96 3.33977 5.95876 4.46321 6.45635 5.8014C6.46095 5.81685 6.46682 5.8319 6.4739 5.8464C6.64933 5.81219 6.82715 5.78587 7.00737 5.76745C7.0125 5.72895 7.00938 5.6898 6.9982 5.65258Z" fill="currentColor"/>
      <path d="M15.1785 5.65258C15.2108 5.54205 15.2411 5.45521 15.2778 5.35968C15.5292 4.72446 15.8699 4.12752 16.2897 3.58648C17.0017 2.65186 17.8942 1.86652 18.9152 1.27605C19.1533 1.14653 19.4009 1.03503 19.656 0.942486C19.6699 0.765023 19.743 0.597167 19.8637 0.465255C19.9844 0.333342 20.146 0.244837 20.323 0.213625C20.5 0.182413 20.6824 0.21026 20.8417 0.292798C21.001 0.375335 21.1281 0.507892 21.2031 0.669672C21.2781 0.831452 21.2967 1.0133 21.256 1.18669C21.2154 1.36007 21.1177 1.51518 20.9784 1.62769C20.8391 1.74019 20.6659 1.80372 20.4862 1.80831C20.3065 1.81289 20.1303 1.75828 19.9853 1.65303C19.41 1.80263 18.8486 2.14527 18.3666 2.50054C17.2171 3.34214 16.2203 4.46361 15.7207 5.80179C15.7161 5.81724 15.7103 5.83229 15.7032 5.84679C15.5277 5.81258 15.3499 5.78626 15.1697 5.76784C15.1644 5.72924 15.1674 5.68996 15.1785 5.65258Z" fill="currentColor"/>
      <path d="M7.72927 4.97073L5.35137 5.38585C5.14471 5.42192 5.00671 5.61704 5.04315 5.82165L5.10588 6.17385C5.14232 6.37846 5.3394 6.51508 5.54606 6.479L7.92396 6.06389C8.13063 6.02781 8.26862 5.83269 8.23218 5.62808L8.16945 5.27588C8.13301 5.07127 7.93594 4.93465 7.72927 4.97073Z" fill="currentColor"/>
      <path d="M16.8664 5.38279L14.4885 4.96767C14.2818 4.93159 14.0847 5.06821 14.0483 5.27282L13.9855 5.62503C13.9491 5.82963 14.0871 6.02475 14.2938 6.06083L16.6717 6.47595C16.8783 6.51202 17.0754 6.3754 17.1118 6.17079L17.1746 5.81859C17.211 5.61398 17.073 5.41887 16.8664 5.38279Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.5682 5.70508H13.6346C17.5209 5.70508 20.6714 8.82424 20.6714 12.6719C20.6714 16.5196 17.5209 19.6388 13.6346 19.6388H8.5682C4.68187 19.6388 1.53137 16.5196 1.53137 12.6719C1.53137 8.82424 4.68187 5.70508 8.5682 5.70508ZM8.57495 7.48867H13.641C16.5324 7.48867 18.8764 9.80927 18.8764 12.6719C18.8764 15.5345 16.5324 17.8551 13.641 17.8551H8.57495C5.68349 17.8551 3.33949 15.5345 3.33949 12.6719C3.33949 9.80927 5.68349 7.48867 8.57495 7.48867Z" fill="currentColor"/>
      <path d="M13.6408 7.48828H8.57484C5.68338 7.48828 3.33939 9.80888 3.33939 12.6715C3.33939 15.5341 5.68338 17.8547 8.57484 17.8547H13.6408C16.5323 17.8547 18.8763 15.5341 18.8763 12.6715C18.8763 9.80888 16.5323 7.48828 13.6408 7.48828Z" fill="#3A4369" fillOpacity="0.16"/>
      <path d="M15.3125 13.0644C15.3125 13.0644 14.4118 12.1723 13.6236 12.7297C12.8353 13.2871 12.7229 13.8445 12.498 13.7331C12.2731 13.6218 12.3852 11.4551 13.9051 11.1452C15.4249 10.8353 16.0999 13.3988 15.3125 13.0644Z" fill="currentColor"/>
      <path d="M6.89041 13.0644C6.89041 13.0644 7.7911 12.1723 8.57935 12.7297C9.3676 13.2871 9.48003 13.8445 9.7053 13.7331C9.93058 13.6218 9.81774 11.4551 8.29786 11.1452C6.77798 10.8353 6.10296 13.3988 6.89041 13.0644Z" fill="currentColor"/>
      <path d="M11.1372 16.2493C9.75014 16.2493 8.75974 15.3872 8.74499 15.3741C8.67837 15.3148 8.63828 15.2318 8.63353 15.1432C8.62878 15.0546 8.65977 14.9678 8.71967 14.9018C8.77958 14.8359 8.86349 14.7962 8.95296 14.7915C9.04242 14.7868 9.13011 14.8175 9.19673 14.8768C9.27288 14.9439 11.0208 16.4423 13.0243 14.8641C13.0944 14.8089 13.1838 14.7835 13.2729 14.7936C13.3619 14.8036 13.4432 14.8482 13.499 14.9176C13.5548 14.987 13.5804 15.0755 13.5703 15.1637C13.5602 15.2518 13.5151 15.3323 13.445 15.3876C12.6252 16.033 11.8318 16.2493 11.1372 16.2493Z" fill="currentColor"/>
    </SvgIcon>
  );
};
