import React from "react";
import { Box } from "@mui/material";
import { Link } from "../../common/Link/Link";
import { ArrowBack, KeyboardArrowDown } from "@mui/icons-material";
import { Typography } from "../../common/Typography/Typography";
import { Button } from "../../common/Button/Button";
import { BasicPage } from "../../common/BasicPage/BasicPage";
import { AssetDetail } from "../AssetDetail";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GetADataAssetDocument } from "../../generated";
import AccessStatusChip from "../AccessStatusChip/AccessStatusChip";
import { BasicPageWrapper } from "../style";

export interface AssetDetailPageProps {}

export const AssetDetailPage = () => {
  const { workspaceId, dataAssetId } = useParams();
  const navigate = useNavigate();
  const { data } = useQuery(GetADataAssetDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
      dataAssetFilter: {
        dataAssetId: dataAssetId || "",
      },
    },
  });
  const dataAsset = data?.workspace?.dataAssets?.[0];
  const isEditable = dataAsset?.isEditable;

  return (
    <BasicPageWrapper
      className="AssetDetailPage"
      leftHeaderElements={
        <Box>
          <Box mb={3}>
            {/* <Box
              onClick={() => navigate(-1)}
              sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
            >
              <ArrowBack
                sx={{
                  width: "1.25rem",
                  height: "1.25rem",
                  fontSize: "1.25rem",
                  mr: 2,
                }}
              />
              <Typography variant="body3">Back</Typography>
            </Box> */}
          </Box>
          <Box display="flex">
            <Typography mr={4} variant="h5">
              {dataAsset?.name || ""}
            </Typography>
            {dataAsset?.accessStatus && (
              <AccessStatusChip status={dataAsset?.accessStatus} />
            )}
          </Box>
        </Box>
      }
      rightHeaderElements={
        isEditable ? (
          <>
            {/* <Button
              sx={{ mr: 2 }}
              variant="outlined"
              color="grayAccent"
              endIcon={<KeyboardArrowDown />}
            >
              Manage asset
            </Button>
            <Button
              variant="contained"
              color="primary"
              endIcon={<KeyboardArrowDown />}
            >
              Add to project
            </Button> */}
          </>
        ) : null
      }
      content={<AssetDetail />}
    />
  );
};
