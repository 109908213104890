import React from "react";
import { WorkflowTransformationOutput } from "../generated";
import { DataNode } from "./DataNode";
import { Position } from "react-flow-renderer";

interface Data extends WorkflowTransformationOutput {
  position?: string;
}
export interface TransformationNodeProps {
  data: Data;
  selected?: boolean;
  id?: string;
}

export const TransformationNode = ({
  data: { name, issueStatus, jobCount, job, linkedTransformations, position },
  selected,
  id,
}: TransformationNodeProps) => {
  
  const getJobText = (jobCount: number) => {
    if (jobCount === 0) {
      return "0 jobs";
    } else if (jobCount === 1) {
      return "1 job";
    } else {
      return `${jobCount} jobs`;
    }
  };

  const JobStatus = () => {
    if (job?.state && job?.startedAt) {
     return `${job?.state} | ${job?.startedAt}`
    }
    else if (job?.state && !job?.startedAt) {
      return job?.state
    }
    else if (!job?.state && job?.startedAt) {
      return job?.state
    }
    else {
      return ''
    }
  } 

  return (
    <DataNode
      id={id}
      selected={selected}
      title={name}
      caption={getJobText(jobCount)}
      issueStatus={issueStatus}
      bodyText={JobStatus() || ''}
      sourceHandle={linkedTransformations.length > 0}
      targetHandle={linkedTransformations.length > 0}
      sourceHandleB={position === "last" ? undefined : Position.Right}
      targetHandleB={position === "first" ? undefined : Position.Left}
    />
  );
};
