import {
  Column,
  GridApi,
  IFilter,
  RowNode,
  ValueGetterParams,
} from "ag-grid-enterprise";
import React, { useEffect, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import { FilterOperator, SpecificTagsFilterOperator } from "./gridHelpers";

export const useTagsFiltersWithGrid = (
  filterOperators: string[],
  column: Column,
  gridApi: GridApi,
  initialFilterOperator: string
) => {
  const [currentFilterOperator, setCurrentFilterOperator] = useState<string>(
    initialFilterOperator
  );
  const [filterText, setFilterText] = useState<string>("");
  const [uniqueFilterValues, setUniqueFilterValues] = useState<any[]>([]);
  const [selectedUniqueFilterValues, setSelectedUniqueFilterValues] = useState<
    any[]
  >([]);

  const filterInstance = gridApi.getFilterInstance(column);

  const changeInternalGridFilter = (
    filterValue: string,
    filterOperator: string,
    filterInstance: IFilter | null | undefined,
    filterType: string,
    gridApi: GridApi
  ) => {
    if (
      filterOperator === SpecificTagsFilterOperator.IS_EMPTY ||
      filterOperator === SpecificTagsFilterOperator.IS_NOT_EMPTY
    ) {
      filterInstance?.setModel({
        filterType,
        type: filterOperator,
        filter: "|||",
      });
    } else {
      if (filterValue === "") {
        filterInstance?.setModel(null);
      } else {
        if (
          filterOperator === FilterOperator.IS_ANY_OF ||
          filterOperator === FilterOperator.IS_NONE_OF
        ) {
          filterInstance?.setModel({
            filterType: "text",
            type: filterOperator,
            filter: filterValue,
          });
        } else {
          filterInstance?.setModel({
            filterType,
            type: filterOperator,
            filter: filterValue,
          });
        }
      }
    }
    gridApi.onFilterChanged();
  };

  const onChangeFilterOperator = (e: SelectChangeEvent<unknown>) => {
    const filterOperator = e.target.value as string;
    setCurrentFilterOperator(filterOperator);
    setSelectedUniqueFilterValues([]);
    if (
      filterOperator === FilterOperator.IS_ANY_OF ||
      filterOperator === FilterOperator.IS_NONE_OF
    ) {
      setFilterText("");
      changeInternalGridFilter(
        "",
        filterOperator,
        filterInstance,
        "text",
        gridApi
      );
    } else if (
      filterOperator === SpecificTagsFilterOperator.IS_EMPTY ||
      filterOperator === SpecificTagsFilterOperator.IS_NOT_EMPTY
    ) {
      setFilterText("");
      changeInternalGridFilter(
        "",
        filterOperator,
        filterInstance,
        "text",
        gridApi
      );
    } else {
      changeInternalGridFilter(
        filterText,
        filterOperator,
        filterInstance,
        "text",
        gridApi
      );
    }
  };

  const onClickRefreshFilter = () => {
    setFilterText("");
    setSelectedUniqueFilterValues([]);
    filterInstance?.setModel(null);
    gridApi.onFilterChanged();
  };

  const onChangeSelectedUniqueFilterValues = (
    event: SelectChangeEvent<unknown>
  ) => {
    const {
      target: { value },
    } = event;
    let newSelectedUniqueFilterValues = [];
    if (Array.isArray(value)) {
      newSelectedUniqueFilterValues = value;
    } else if (typeof value === "string" || typeof value === "number") {
      const stringValue = String(value);
      newSelectedUniqueFilterValues = stringValue.split(",");
    }
    setSelectedUniqueFilterValues(newSelectedUniqueFilterValues);
    changeInternalGridFilter(
      newSelectedUniqueFilterValues.join("|||"),
      currentFilterOperator,
      filterInstance,
      "text",
      gridApi
    );
  };

  const onChangeFilterText = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newFilterText = e.target.value;
    setFilterText(newFilterText);
    changeInternalGridFilter(
      newFilterText,
      currentFilterOperator,
      filterInstance,
      "text",
      gridApi
    );
  };

  useEffect(() => {
    const syncFilterWithGridFilterModel = ({
      column,
      api,
    }: {
      column: Column;
      api: GridApi;
    }) => {
      const currentFilterModel = api?.getFilterModel();
      const currentField = column?.getColDef()?.field;
      if (currentField && currentFilterModel?.[currentField]) {
        const { filter: gridFilter, type: newOperator } =
          currentFilterModel[currentField];
        setCurrentFilterOperator(newOperator);
        if (
          newOperator === SpecificTagsFilterOperator.IS_EMPTY ||
          newOperator === SpecificTagsFilterOperator.IS_NOT_EMPTY
        ) {
          setSelectedUniqueFilterValues([]);
          setFilterText("");
        } else if (
          newOperator === FilterOperator.IS_NONE_OF ||
          newOperator === FilterOperator.IS_ANY_OF
        ) {
          setSelectedUniqueFilterValues(String(gridFilter).split("|||"));
          setFilterText("");
        } else {
          setSelectedUniqueFilterValues([]);
          setFilterText(gridFilter);
        }
      } else {
        setFilterText("");
        setSelectedUniqueFilterValues([]);
      }
    };

    const syncFilterWithRowData = ({ api }: { api: GridApi }) => {
      const field = column?.getColDef()?.field;
      if (field) {
        const valueGetter = column?.getColDef()?.valueGetter;
        const allValuesForField: string[] = [];
        api.forEachNode((rowNode: RowNode) => {
          let value: any = [];
          if (typeof valueGetter === "function") {
            value = valueGetter({ data: rowNode.data } as ValueGetterParams);
          } else if (rowNode?.data[field]) {
            value = rowNode?.data[field];
          }
          if (Array.isArray(value)) {
            value.forEach((currValue) => {
              if (!allValuesForField.includes(currValue)) {
                allValuesForField.push(currValue);
              }
            });
          }
        });
        setUniqueFilterValues(allValuesForField);
      } else {
        setUniqueFilterValues([]);
      }
      setSelectedUniqueFilterValues([]);
    };

    column.addEventListener("filterChanged", syncFilterWithGridFilterModel);
    const anyOfEnabled = filterOperators.includes(FilterOperator.IS_ANY_OF);
    const isNoneOfEnabled = !filterOperators.includes(
      FilterOperator.IS_NONE_OF
    );
    if (anyOfEnabled || isNoneOfEnabled) {
      gridApi.addEventListener("rowDataChanged", syncFilterWithRowData);
      syncFilterWithRowData({ api: gridApi });
    }
    return () => {
      column.removeEventListener(
        "filterChanged",
        syncFilterWithGridFilterModel
      );
      gridApi.removeEventListener("rowDataChanged", syncFilterWithRowData);
    };
  }, [filterOperators, column, gridApi, initialFilterOperator]);

  return {
    filterText,
    currentFilterOperator,
    uniqueFilterValues,
    selectedUniqueFilterValues,
    onChangeFilterOperator,
    onChangeSelectedUniqueFilterValues,
    onChangeFilterText,
    onClickRefreshFilter,
  };
};
