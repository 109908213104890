import React, { useState } from "react";
import { Box, BoxProps, useTheme } from "@mui/material";
import { Navbar } from "../Navbar/Navbar";
import { useQuery } from "@apollo/client";
import { GetCurrentUserInfoDocument } from "../../generated";
import { SideNavBar } from "./SideNavBar";
import { TopNavRight } from "./style";

export interface BasicPageProps {
  content?: React.ReactNode;
  leftHeaderElements?: React.ReactNode;
  rightHeaderElements?: React.ReactNode;
}

export const BasicPageNav = ({
  content,
  leftHeaderElements,
  rightHeaderElements,
  ...rest
}: BasicPageProps & BoxProps) => {
  const { data } = useQuery(GetCurrentUserInfoDocument);
  const [collapse, setCollapse] = useState(true);

  const fullName = `${data?.currentUser?.firstName || ""} ${
    data?.currentUser?.lastName
  }`;

  const emailAddress = data?.currentUser?.emailAddress;
  const { palette } = useTheme();

  if (!data) return null;

  return (
    <SideNavBar content={content} collapse={collapse} setCollapse={setCollapse}>
      <TopNavRight {...rest}>
        <Navbar
          username={fullName}
          emailAddress={emailAddress}
          multipleWorkspaces
        />
      </TopNavRight>
    </SideNavBar>
  );
};
