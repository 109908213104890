import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const SidebarMenuCollapse = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.2418 1.75833L4.48346 5L1.2418 8.24167C0.916797 8.56667 0.916797 9.09167 1.2418 9.41667C1.5668 9.74167 2.0918 9.74167 2.4168 9.41667L6.2418 5.59167C6.5668 5.26667 6.5668 4.74167 6.2418 4.41667L2.4168 0.583333C2.0918 0.258333 1.5668 0.258333 1.2418 0.583333C0.92513 0.908333 0.92513 1.44167 1.2418 1.75833ZM10.1668 0C10.6251 0 11.0001 0.375 11.0001 0.833333V9.16667C11.0001 9.625 10.6251 10 10.1668 10C9.70846 10 9.33346 9.625 9.33346 9.16667V0.833333C9.33346 0.375 9.70846 0 10.1668 0Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
