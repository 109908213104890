import { useLazyQuery } from "@apollo/client";
import { Button } from "../Button/Button";
import { toast } from "react-toastify";
import { GetExportCsvDocument } from "../../generated";

function downloadCSV(csvStr: any) {
  var hiddenElement = document.createElement("a");
  hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvStr);
  hiddenElement.target = "_blank";
  hiddenElement.download = "ExportDoc.csv";
  hiddenElement.click();
}

export const DownloadCSV = ({ json }: any) => {
  const [getCsv, { loading, error, data }] = useLazyQuery(GetExportCsvDocument);

  const handleClick = () => {
    const toastId = toast.loading("Fetching csv...");

    getCsv({
      variables: {
        data: json,
      },
    })
      .then((res) => {
        if (res.data?.exportCSV) {
          downloadCSV(res.data?.exportCSV);
          toast.update(toastId, {
            render: "Fetching complete",
            closeOnClick: true,
            closeButton: true,
            type: toast.TYPE.SUCCESS,
            isLoading: false,
            autoClose: 2000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.update(toastId, {
          render: "Error while fetching",
          closeOnClick: true,
          closeButton: true,
          type: toast.TYPE.ERROR,
          isLoading: false,
          autoClose: 2000,
        });
      });
  };

  return (
    <div>
      <Button onClick={() => handleClick()}>Download CSV</Button>
    </div>
  );
};
