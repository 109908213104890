import React, { ForwardedRef } from "react";
import { MenuItem as MUIMenuItem, MenuItemProps as MUIMenuItemProps, } from "@mui/material";


export type MenuItemsProps = MUIMenuItemProps;

export const MenuItem = React.forwardRef(
  (props: MenuItemsProps, ref: ForwardedRef<HTMLLIElement>) => {
    return <MUIMenuItem ref={ref} {...props} />;
  }
);
