import React from "react";
import { Box, Divider, useTheme } from "@mui/material";
import { SquareIcon } from "../SquareIcon/SquareIcon";
import { GridView } from "@mui/icons-material";
import { Typography } from "../Typography/Typography";
import { BasePageHeader } from "../BasePageHeader/BasePageHeader";
import { Footer } from "../Footer/Footer";
import { AppContext } from "../../App";
import { ProjectSidenav } from "../ProjectSidenav/ProjectSidenav";
import { useQuery } from "@apollo/client";
import {
  GetCurrentUserInfoDocument,
  GetProjectDocument,
} from "../../generated";
import { useLocation, useParams } from "react-router-dom";
import { useData } from "../../hooks/dataContext";
import { CollapsableNav } from "../SideNavigationPage/CollapsableNav";
import { ProjectNavBar } from "../ProjectSidenav/ProjectNavBar";

export interface ProjectPageProps {
  content: React.ReactNode;
  leftHeaderElements?: React.ReactNode;
  rightHeaderElements?: React.ReactNode;
  projectContent?: React.ReactNode;
}

export const ProjectPage = ({
  content,
  leftHeaderElements,
  rightHeaderElements,
  projectContent,
}: ProjectPageProps) => {
  const { palette } = useTheme();
  const location: any = useLocation();
  const { workspaceId, projectId } = useParams();
  const { dispatch } = useData();

  const { data } = useQuery(GetProjectDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
      projectFilter: {
        projectId: projectId || "",
      },
    },
  });

  const workspace = data?.workspace;
  const project = data?.workspace?.projects?.[0];

  const { data: userData } = useQuery(GetCurrentUserInfoDocument, {
    onCompleted: (res) => {
      dispatch({ type: "user", payload: res.currentUser });
    },
  });
  const workspaceName = workspace?.name || location.state?.workspaceName;
  const projectName = project?.name || location.state?.projectName;

  const fullName = `${userData?.currentUser?.firstName || ""} ${
    userData?.currentUser?.lastName
  }`;

  const emailAddress = userData?.currentUser?.emailAddress;

  return (
    <AppContext.Consumer>
      {({ showSidenav, setShowSidenav }) => (
        <Box
          sx={{ background: palette.common.white }}
          height="100vh"
          display="flex"
          flexDirection="column"
        >
          <Box display="flex" flexDirection="column" flexGrow={1}>
            <ProjectNavBar projectName={projectName}
              workspaceName={workspaceName}
              show={showSidenav}
              setShowSidenav={setShowSidenav} />
            {/* <ProjectSidenav
              projectName={projectName}
              workspaceName={workspaceName}
              show={showSidenav}
              setShowSidenav={setShowSidenav}
            /> */}

            <Box
              display="flex"
              flexDirection="column"
              flexGrow={1}
              bgcolor={palette.background.default}
              sx={{
                transition: "all ease-in-out 0.25s",
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                flexGrow={1}
                pl={6}
                pr={6}
                pt="1.125rem"
                pb="1.125rem"
              >
                <BasePageHeader
                  sideNav
                  expanded={showSidenav}
                  onClickToggleNav={() => setShowSidenav(!showSidenav)}
                  leftElements={leftHeaderElements}
                  rightElements={rightHeaderElements}
                />
                <Divider sx={{ mb: 4, mt: 4 }} />
                <Box display="flex" flexDirection="column" flexGrow={1}>
                  {content}
                </Box>
              </Box>
              <Footer />
            </Box>
          </Box>
        </Box>
      )}
    </AppContext.Consumer>
  );
};
