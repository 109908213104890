import React from "react";
import { Typography } from "../Typography/Typography";
import { Box, Icon, useTheme } from "@mui/material";
import { DoNotDisturb } from "@mui/icons-material";

export interface NoDataOverlayProps {}

export const NoDataOverlay = () => {
  const { palette } = useTheme();

  return (
    <Box color={palette.gray.dark}>
      <Icon
        sx={{
          height: "1.625rem",
          width: "1.625rem",
          fontSize: "1.625rem",
        }}
      >
        <DoNotDisturb />
      </Icon>
      <Typography variant="body1">
        There are no results that match your criteria.
      </Typography>
    </Box>
  );
};
