import { toast, ToastOptions, TypeOptions } from "react-toastify";
import { brighthiveTheme } from "../../theme/theme";

export const displayToast = (
  message: string,
  options?: ToastOptions,
  type: TypeOptions = "success"
) => {
  return toast(message, {
    style: { backgroundColor: brighthiveTheme.palette.common.white },
    autoClose: 5000,
    closeButton: false,
    hideProgressBar: false,
    theme: "light",
    position: "top-right",
    ...options,
    type: type,
  });
};
