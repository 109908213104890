import React, { useState } from "react";
import { Button } from "../../common/Button/Button";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Menu } from "../../common/Menu/Menu";
import { MenuItem } from "../../common/Menu/MenuItem";
import { useMenu } from "../../common/Menu/useMenu";
import { ListItemText } from "../../common/Menu/ListItemText";
import { WorkflowDataAssetGroupOutput } from "../../generated";
import { SelectInputDataAssetGroupsModal } from "./SelectInputDataAssetGroupsModal/SelectInputDataAssetGroupsModal";
import { AddDataAssetModal } from "./AddDataAssetModal";
import { AddInputDataAssetsModal } from "../../ProjectWorkflow/AddInputDataAssetsModal/AddInputDataAssetsModal";
import { useGetUserRole } from "../../hooks/useGetUserRole";
import { useParams } from "react-router-dom";

export interface AddDataAssetButtonProps {
  onClick: (type: AddDataAssetButtonType) => void;
}

export enum AddDataAssetButtonType {
  Upload = "Upload",
  ExtractLoad = "Extract and load",
  APIconnect = "API connect",
  BulkTransfer = "Bulk Transfer",
  FromCatalog = "From Catalog",
}

export const AddDataAssetButton = ({ onClick }: AddDataAssetButtonProps) => {
  const { workspaceId } = useParams();
  const { accessible } = useGetUserRole(workspaceId);
  const [menuOpen, anchorEl, openMenuFromAnchorElement, closeMenu] = useMenu();
  const [item, setItem] = useState<WorkflowDataAssetGroupOutput | null>(null);
  const [selectDataAssetOpen, setSelectDataAssetOpen] = useState(false);
  const [action, setAction] = useState<AddDataAssetButtonType | null>(null);

  return (
    <>
      <Button
        id="add-data-asset-button"
        aria-controls={menuOpen ? "add-data-asset-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        variant="contained"
        onClick={openMenuFromAnchorElement}
        endIcon={menuOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      >
        Add data assets
      </Button>
      <Menu
        id="add-data-asset-menu"
        MenuListProps={{ "aria-labelledby": "add-data-asset-button" }}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ ".MuiList-root": { width: "10rem" } }}
      >
        <MenuItem
          disableRipple
          onClick={() => {
            closeMenu();
            setAction(AddDataAssetButtonType.Upload);
            setSelectDataAssetOpen(true);
          }}
        >
          <ListItemText>{AddDataAssetButtonType.Upload}</ListItemText>
        </MenuItem>
        {accessible && (
          <MenuItem
            disableRipple
            onClick={() => {
              closeMenu();
              setAction(AddDataAssetButtonType.FromCatalog);
              setSelectDataAssetOpen(true);
            }}
          >
            <ListItemText>{AddDataAssetButtonType.FromCatalog}</ListItemText>
          </MenuItem>
        )}
        {/* <MenuItem
          disableRipple
          onClick={() => {
            closeMenu();
            onClick(AddDataAssetButtonType.ExtractLoad);
          }}
        >
          <ListItemText>{AddDataAssetButtonType.ExtractLoad}</ListItemText>
        </MenuItem>
        <MenuItem
          disableRipple
          onClick={() => {
            closeMenu();
            onClick(AddDataAssetButtonType.APIconnect);
          }}
        >
          <ListItemText>{AddDataAssetButtonType.APIconnect}</ListItemText>
        </MenuItem>
        <MenuItem
          disableRipple
          onClick={() => {
            closeMenu();
            onClick(AddDataAssetButtonType.BulkTransfer);
          }}
        >
          <ListItemText>{AddDataAssetButtonType.BulkTransfer}</ListItemText>
        </MenuItem> */}
      </Menu>
      <SelectInputDataAssetGroupsModal
        show={selectDataAssetOpen}
        closeDialog={() => setSelectDataAssetOpen(false)}
        submit={(v) => {
          setItem(v);
          setSelectDataAssetOpen(false);
        }}
      />
      {item && action === AddDataAssetButtonType.FromCatalog ? (
        <AddInputDataAssetsModal
          show={Boolean(item) && action === AddDataAssetButtonType.FromCatalog}
          closeDialog={() => setItem(null)}
          inputDataAssetGroupId={item?.id}
          selectedIds={[]}
          schemaId={item?.schemaId || ""}
        />
      ) : null}
      <AddDataAssetModal
        show={Boolean(item) && action === AddDataAssetButtonType.Upload}
        closeDialog={() => setItem(null)}
        item={item}
      />
    </>
  );
};

