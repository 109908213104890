import React, { useState } from "react";
import { Box, Paper, useTheme, List, ListItem } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { DOCLink } from "../../common/Link/Link";
import { WorkflowTransformationOutput } from "../../generated";
import { getAlertMessage } from "../helpers";

export const TransformationDetails = ({
  issueType,
  transformation,
}: {
  issueType: any;
  transformation: Partial<WorkflowTransformationOutput>;
}) => {
  const { palette } = useTheme();
  const [show, setShow] = useState(false);
  const job = transformation?.job;
  if (transformation) {
    return (
      <Box boxSizing="border-box">
        <Box
          sx={{
            paddingBottom: "16px",
            borderBottom: `1px solid ${palette.outline}`,
          }}
          mb={6}
        >
          {getAlertMessage(
            issueType,
            `One or more of the assets in this schema set has an issue that should be resolved. Please review the data assets.`
          )}
          <Typography
            mb={2}
            variant="h5"
            color={palette.gray.darkest}
            sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: "normal" }}
          >
            {transformation.description}
          </Typography>
        </Box>
        {show && (
          <Box mb={6}>
            <Paper
              variant="outlined"
              sx={{
                p: 4,
                display: "flex",
                flexDirection: "column",
                minHeight: "100%",
                boxSizing: "border-box",
              }}
            >
              <Box mb={4}>
                <Typography gutterBottom variant="subtitle2">
                  Transformation Service
                </Typography>
                <List
                  sx={{
                    fontSize: "14px",
                    lineHeight: "20px",
                    padding: "0",
                    li: {
                      width: "inherit",
                      display: "flex",
                      marginBottom: "5px",
                      flexWrap: "wrap",
                      strong: {
                        display: "inline-block",
                        verticalAlign: "middle",
                        marginRight: "10px",
                        fontWeight: "500",
                        color: palette.common.black,
                        "&:after": {
                          content: '":"',
                          display: "inline-block",
                          verticalAlign: "top",
                          marginLeft: "2px",
                        },
                      },
                      span: {
                        display: "inline-block",
                        verticalAlign: "middle",
                        color: palette.gray.darkest,
                      },
                    },
                  }}
                >
                  <ListItem disablePadding>
                    <strong>Job name</strong>
                    <span>{job?.name}</span>
                  </ListItem>
                  <ListItem disablePadding>
                    <strong>Job ID</strong>
                    <span>{job?.id}</span>
                  </ListItem>
                  <ListItem disablePadding>
                    <strong>Job description</strong>
                    <span>{job?.description}</span>
                  </ListItem>
                </List>
                <DOCLink
                  sx={{ mb: 2 }}
                  name="Open dbt Cloud"
                  href="#"
                  target="_blank"
                />
              </Box>
              <Box>
                <Typography gutterBottom variant="subtitle2">
                  Post run event details
                </Typography>
                <List
                  sx={{
                    fontSize: "14px",
                    lineHeight: "20px",
                    padding: "0",
                    li: {
                      width: "inherit",
                      display: "flex",
                      marginBottom: "5px",
                      flexWrap: "wrap",
                      strong: {
                        display: "inline-block",
                        verticalAlign: "middle",
                        marginRight: "10px",
                        fontWeight: "500",
                        color: palette.common.black,
                        "&:after": {
                          content: '":"',
                          display: "inline-block",
                          verticalAlign: "top",
                          marginLeft: "2px",
                        },
                      },
                      span: {
                        display: "inline-block",
                        verticalAlign: "middle",
                        color: palette.gray.darkest,
                      },
                    },
                  }}
                >
                  <ListItem disablePadding>
                    <strong>Run ID</strong>
                    <span>{job?.runId}</span>
                  </ListItem>
                  <ListItem disablePadding>
                    <strong>Created at </strong>
                    <span>{job?.createdAt}</span>
                  </ListItem>
                  <ListItem disablePadding>
                    <strong>Updated at </strong>
                    <span>{job?.modifiedAt}</span>
                  </ListItem>
                  <ListItem disablePadding>
                    <strong>Nominal start time</strong>
                    <span>{job?.nominalStartTime}</span>
                  </ListItem>
                  <ListItem disablePadding>
                    <strong>Nominal end time</strong>
                    <span>{job?.nominalEndTime}</span>
                  </ListItem>
                  <ListItem disablePadding>
                    <strong>State</strong>
                    <span>COMPLETED</span>
                  </ListItem>
                  <ListItem disablePadding>
                    <strong>Started at</strong>
                    <span>{job?.startedAt}</span>
                  </ListItem>
                  <ListItem disablePadding>
                    <strong>Ended at</strong>
                    <span>{job?.endedAt}</span>
                  </ListItem>
                  <ListItem disablePadding>
                    <strong>Duration (ms)</strong>
                    <span>{job?.duration}</span>
                  </ListItem>
                  <ListItem disablePadding>
                    <strong>Arguments</strong>
                    <span>{`{}`}</span>
                  </ListItem>
                  <ListItem disablePadding>
                    <strong>Facets</strong>
                    <span>{`{}`}</span>
                  </ListItem>
                </List>
              </Box>
            </Paper>
          </Box>
        )}
        <Box mb={6}>
          <Paper
            variant="outlined"
            sx={{
              p: 4,
              display: "flex",
              flexDirection: "column",
              minHeight: "100%",
              boxSizing: "border-box",
            }}
          >
            <Box mb={4}>
              <Typography gutterBottom variant="subtitle2">
                Transformation Service
              </Typography>
              {transformation.sourceUrl && (
                <DOCLink
                  sx={{ mb: 2 }}
                  name="Open Transformation Service"
                  target="_blank"
                  href={transformation.sourceUrl}
                />
              )}
            </Box>
          </Paper>
        </Box>
      </Box>
    );
  }
  return null;
};
