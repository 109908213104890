import React from "react";
import {
  BoltOutlined,
  BubbleChartOutlined,
  CorporateFare,
  LockOutlined,
} from "@mui/icons-material";
import { brighthiveTheme } from "../theme/theme";
import { CreatedAssetIcon } from "../common/Icons/CreatedAssetIcon";
import { DestinationIcon } from "../common/Icons/Destination";
import { Node, XYPosition } from "react-flow-renderer";
import { HeaderNodeData } from "./HeaderNode";
import { GetWorkflowQuery, WorkflowIssueStatus } from "../generated";
import { Status } from "./DataBox";
import { AddNodeData } from "./AddNode";

const HEADER_Y = 16;
const ADD_Y = 216;
const COLUMN_SPACING = 112;
const NODE_SPACING = 40;
const INITIAL_X = 56;
export const NODE_WIDTH = 332;
export const HEADER_NODE_HEIGHT = 232;

export enum NodeType {
  ORGANIZATION = "organizations",
  INPUT_DATA = "INPUT_DATA_ASSET_GROUP",
  INPUT_DATA_ITEM = "inputDataItem",
  TRANSFORMATIONS = "TRANSFORMATION",
  FINAL_DATA_PRODUCTS = "FINAL_DATA_PRODUCT_GROUP",
  DATA_PRODUCT = "FINAL_DATA_PRODUCT_GROUP",
  DESTINATIONS = "DESTINATION",
}

interface ColumnXIndexObj {
  [NodeType.ORGANIZATION]: number;
  [NodeType.INPUT_DATA]: number;
  [NodeType.INPUT_DATA_ITEM]: number;
  [NodeType.TRANSFORMATIONS]: number;
  [NodeType.FINAL_DATA_PRODUCTS]: number;
  [NodeType.DATA_PRODUCT]: number;
  [NodeType.DESTINATIONS]: number;
}

const COLUMN_X_POS: ColumnXIndexObj = {
  [NodeType.ORGANIZATION]: INITIAL_X,
  [NodeType.INPUT_DATA]: INITIAL_X + COLUMN_SPACING + NODE_WIDTH,
  [NodeType.INPUT_DATA_ITEM]: INITIAL_X + COLUMN_SPACING + NODE_WIDTH,
  [NodeType.TRANSFORMATIONS]: INITIAL_X + 2 * (COLUMN_SPACING + NODE_WIDTH),
  [NodeType.FINAL_DATA_PRODUCTS]: INITIAL_X + 3 * (COLUMN_SPACING + NODE_WIDTH),
  [NodeType.DATA_PRODUCT]: INITIAL_X + 3 * (COLUMN_SPACING + NODE_WIDTH),
  [NodeType.DESTINATIONS]: INITIAL_X + 4 * (COLUMN_SPACING + NODE_WIDTH),
};

export enum ElementType {
  HEADER_NODE = "headerNode",
  ADD_NODE = "addNode",
}

const NODE_HEIGHT = {
  [NodeType.ORGANIZATION]: 200,
  [NodeType.INPUT_DATA]: 200,
  [NodeType.INPUT_DATA_ITEM]: 200,
  [NodeType.TRANSFORMATIONS]: 200,
  [NodeType.FINAL_DATA_PRODUCTS]: 200,
  [NodeType.DATA_PRODUCT]: 200,
  [NodeType.DESTINATIONS]: 200,
};

export const getNodePosition = (
  nodeType: NodeType,
  numberOfNode: number
): XYPosition => {
  return {
    x: COLUMN_X_POS[nodeType],
    y: HEADER_Y + numberOfNode * (NODE_HEIGHT[nodeType] + NODE_SPACING + 50),
  };
};

export const getHeaderNodes = (): Node<HeaderNodeData>[] => {
  return [
    {
      id: NodeType.ORGANIZATION,
      type: ElementType.HEADER_NODE,
      data: {
        headerLabel: "Sources",
        leftIcon: (
          <CorporateFare
            sx={{
              mr: 2,
              color: brighthiveTheme.palette.gray.dark,
              fontSize: "1.25rem",
              width: "1.25rem",
              height: "1.25rem",
            }}
          />
        ),
        rightIcon: <LockOutlined sx={{ width: ".75rem", height: ".75rem" }} />,
        description:
          "Data sources populate automatically when input data assets are added to the project.",
      },
      position: {
        x: COLUMN_X_POS[NodeType.ORGANIZATION],
        y: HEADER_Y,
      },
    },
    {
      id: NodeType.INPUT_DATA,
      type: ElementType.HEADER_NODE,
      data: {
        headerLabel: "Input data",
        leftIcon: (
          <BubbleChartOutlined
            sx={{
              mr: 2,
              color: brighthiveTheme.palette.gray.dark,
              fontSize: "1.25rem",
              width: "1.25rem",
              height: "1.25rem",
            }}
          />
        ),
        description:
          "In the input data layer, data assets are grouped and validated against the schema selected for their group.",
        // footer: (
        //   <Button variant="contained" color="grayAccent" fullWidth>
        //     Add input schema set
        //   </Button>
        // ),
      },
      position: {
        x: COLUMN_X_POS[NodeType.INPUT_DATA],
        y: HEADER_Y,
      },
    },
    {
      id: NodeType.TRANSFORMATIONS,
      type: ElementType.HEADER_NODE,
      data: {
        headerLabel: "Transformations",
        leftIcon: (
          <BoltOutlined
            sx={{
              mr: 2,
              color: brighthiveTheme.palette.gray.dark,
              fontSize: "1.25rem",
              width: "1.25rem",
              height: "1.25rem",
            }}
          />
        ),
        description:
          "The Transformation layer is run using one of the transformation services for your workspace.",
        // footer: (
        //   <Button variant="contained" color="grayAccent" fullWidth>
        //     Add Transformation
        //   </Button>
        // ),
      },
      position: {
        x: COLUMN_X_POS[NodeType.TRANSFORMATIONS],
        y: HEADER_Y,
      },
    },
    {
      id: NodeType.FINAL_DATA_PRODUCTS,
      type: ElementType.HEADER_NODE,
      data: {
        headerLabel: "Output Data Assets",
        leftIcon: (
          <CreatedAssetIcon
            sx={{
              mr: 2,
              color: brighthiveTheme.palette.gray.dark,
              fontSize: "1.25rem",
              width: "1.25rem",
              height: "1.25rem",
            }}
          />
        ),
        description:
          "Output Data Assets are organized by a name and target schema. They are validated against the schema.",
        // footer: (
        //   <Button variant="contained" color="grayAccent" fullWidth>
        //     Add Data Product Schema Set
        //   </Button>
        // ),
      },
      position: {
        x: COLUMN_X_POS[NodeType.FINAL_DATA_PRODUCTS],
        y: HEADER_Y,
      },
    },
    {
      id: NodeType.DESTINATIONS,
      type: ElementType.HEADER_NODE,
      data: {
        headerLabel: "Destinations",
        leftIcon: (
          <DestinationIcon
            sx={{
              mr: 2,
              color: brighthiveTheme.palette.gray.dark,
              fontSize: "1.25rem",
              width: "1.25rem",
              height: "1.25rem",
            }}
          />
        ),
        description:
          "Destinations specify the means by and format in which data assets may leave the platform.",
        // footer: (
        //   <Button variant="contained" color="grayAccent" fullWidth>
        //     Add Destination
        //   </Button>
        // ),
      },
      position: {
        x: COLUMN_X_POS[NodeType.DESTINATIONS],
        y: HEADER_Y,
      },
    },
  ];
};
export const getAddNodes = (
  transformationExist: boolean
): Node<AddNodeData>[] => {
  return [
    {
      id: "addInputData",
      type: ElementType.ADD_NODE,
      data: {
        buttonLabel: "+ Add Input",
        nodeType: NodeType.INPUT_DATA,
      },
      position: {
        x: COLUMN_X_POS[NodeType.INPUT_DATA],
        y: ADD_Y,
      },
    },
    {
      id: "addTransformation",
      type: ElementType.ADD_NODE,
      data: {
        buttonLabel: "+ Add Transformation",
        nodeType: NodeType.TRANSFORMATIONS,
        disabled: transformationExist,
      },
      position: {
        x: COLUMN_X_POS[NodeType.TRANSFORMATIONS],
        y: ADD_Y,
      },
    },

    {
      id: "addDataProduct",
      type: ElementType.ADD_NODE,
      data: {
        buttonLabel: "+ Add Output",
        nodeType: NodeType.FINAL_DATA_PRODUCTS,
      },
      position: {
        x: COLUMN_X_POS[NodeType.FINAL_DATA_PRODUCTS],
        y: ADD_Y,
      },
    },
    {
      id: "addDestination",
      type: ElementType.ADD_NODE,
      data: {
        buttonLabel: "+ Add Destinations",
        nodeType: NodeType.DESTINATIONS,
      },
      position: {
        x: COLUMN_X_POS[NodeType.DESTINATIONS],
        y: ADD_Y,
      },
    },
  ];
};

export const getStatusFromWorkflowIssueStatus = (
  issueStatus: WorkflowIssueStatus | undefined
) => {
  if (issueStatus === WorkflowIssueStatus.Minor) {
    return Status.Warning;
  } else if (issueStatus === WorkflowIssueStatus.None) {
    return Status.Success;
  } else if (issueStatus === WorkflowIssueStatus.Major) {
    return Status.Error;
  } else {
    return undefined;
  }
};

export const workflowUpdater = (
  workflow: GetWorkflowQuery | undefined,
  sourceNodeId: string | undefined,
  tartgetNodeId: string | undefined
) => {
  const inputData = workflow?.workflow?.inputDataAssetGroups.map((node) => {
    if (node?.id === sourceNodeId) {
      return {
        ...node,
        linkedNodes: [...(node?.linkedNodes || []), tartgetNodeId],
      };
    }
    return node;
  });

  const transformations = workflow?.workflow?.transformations.map((node) => {
    if (node?.id === sourceNodeId) {
      return {
        ...node,
        linkedNodes: [...(node?.linkedNodes || []), tartgetNodeId],
      };
    }
    return node;
  });

  const organizations = workflow?.workflow?.organizations.map((node) => {
    if (node?.id === sourceNodeId) {
      return {
        ...node,
        linkedNodes: [...(node?.linkedNodes || []), tartgetNodeId],
      };
    }
    return node;
  });

  const finalDataProducts = workflow?.workflow?.finalDataProducts.map(
    (node) => {
      if (node?.id === sourceNodeId) {
        return {
          ...node,
          linkedNodes: [...(node?.linkedNodes || []), tartgetNodeId],
        };
      }
      return node;
    }
  );

  return {
    workflow: {
      ...workflow?.workflow,
      inputDataAssetGroups: inputData || undefined,
      finalDataProducts,
      transformations,
      organizations,
    },
  };
};
