import { styled, Box, IconButton, Typography, Button } from "@mui/material";
import { brighthiveTheme } from "../theme/theme";

export const SidenavHolder = styled(Box)`
  position: fixed;
  top: 48px;
  left: 260px;
  height: calc(100% - 48px);
  z-index: 99;
  border-right: 1px solid ${brighthiveTheme.palette.custom.contrastText};
  transition: all ease-in-out 0.25s;
  width: 220px;
  overflow: auto;
  @media (max-width: 992px) {
    width: 180px;
  }
  .linkholder {
    display: flex;
    align-items: center;
    position: relative;

    .action-button {
      padding: 0;
      min-width: 10px;
      margin-left: 5px;
      &.delete {
        svg {
          color: ${brighthiveTheme.palette.error.main};
        }
      }
      svg {
        font-size: 18px;
      }
      span {
        display: none;
      }
    }
    a {
      display: flex;
      align-items: center;
      width: 100%;

      &:hover {
        text-decoration: none;
        background: ${brighthiveTheme.palette.primary.light};
      }
      button {
        padding: 10px;
        overflow: hidden;
        width: calc(100% - 30px);
        display: block;

        &:hover {
          background: none;
          box-shadow: none;
        }
      }
      svg {
        font-size: 20px !important;
        @media (max-width: 992px) {
          display: none;
        }
      }

      .sidebutton-text {
        display: block;
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        text-align: left;
      }
    }
  }
  + .content-holder {
    padding: 0 0 0 220px;
    @media (max-width: 992px) {
      padding: 0 0 0 180px;
    }
  }

  @keyframes rollout {
    0% { transform: translateY(-100px); }
    100% { transform: none; }
  }

  .roll-out {
    animation: rollout 0.4s;
  }
`;

export const DropdownHolder = styled(Box)`
  position: absolute;
  right: 3px;
  top: 4px;

  button {
    background: transparent;
    color: ${brighthiveTheme.palette.gray.darkest};
    box-shadow: none;
    min-width: 20px;
    padding: 5px;
    height: 30px;

    &:hover {
      background: ${brighthiveTheme.palette.primary.darkest};
      color: ${brighthiveTheme.palette.common.white};
    }
  }
`;
