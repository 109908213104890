import moment from "moment";
import React from "react";
import { useMessageContext, ReactionSelector } from "stream-chat-react";
import { Typography } from "../../common/Typography/Typography";
import { Avatar, Box, IconButton, Popover, useTheme } from "@mui/material";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import { AddEmojiIcon } from "../../common/Icons/AddEmojiIcon";
import { Button } from "../../common/Button/Button";
import { Emoji } from "emoji-picker-react";
import ShowdownService from "showdown";

const showdownService = new ShowdownService.Converter();

const reactions = [
  {
    type: "like",
    unified: "1f44d",
  },
  {
    type: "love",
    unified: "2764-fe0f",
  },
  {
    type: "haha",
    unified: "1f602",
  },
  {
    type: "wow",
    unified: "1f62e",
  },
  {
    type: "sad",
    unified: "1f614",
  },
  {
    type: "angry",
    unified: "1f620",
  },
];

function CustomMessage() {
  const { message, handleOpenThread } = useMessageContext();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { palette } = useTheme();

  return (
    <Box
      pt={4}
      bgcolor={palette.common.white}
      border={`1px solid ${palette.action.disabledBackground}`}
      borderRadius={1}
      mb={1}
      sx={{}}
    >
      <Box px={4} pb={4} display="flex">
        <Avatar sx={{ width: 32, height: 32 }}>
          {message.user?.name?.[0] || ""}
        </Avatar>
        <Box marginLeft={4} width="100%">
          <Box display="flex" alignItems="center">
            <Typography fontWeight="bold" variant="body2" marginRight={1}>
              {message.user?.name}
            </Typography>
            <Typography color={palette.gray.dark} variant="caption">
              {moment(message.created_at).fromNow()}
            </Typography>
          </Box>
          <Box mt={1}>
            <Typography
              variant="body3"
              dangerouslySetInnerHTML={{
                __html: showdownService.makeHtml(
                  message.text?.replaceAll("\\", "") || ""
                ),
              }}
            ></Typography>
          </Box>
          <Box
            mt={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" position="relative">
              {Object.keys((message.reaction_counts as any) || {}).map(
                (key) => {
                  const icon = reactions.find((r) => r.type === key);
                  return (
                    <IconButton
                      key={key}
                      size="small"
                      sx={{
                        borderRadius: 20,
                        py: 0.5,
                        px: 1,
                        mr: 2,
                        display: "flex",
                        alignItems: "center",
                        background: `linear-gradient(0deg, ${palette.settings.lighter}, ${palette.settings.lighter}), ${palette.custom.lighter}`,
                      }}
                    >
                      <Emoji unified={icon?.unified || ""} size={16} />
                      <Typography ml={1} variant="caption">
                        {(message.reaction_counts as any)[key]}
                      </Typography>
                    </IconButton>
                  );
                }
              )}
              <IconButton
                sx={{
                  width: 36,
                  height: 24,
                  borderRadius: 20,
                  background: `linear-gradient(0deg, ${palette.settings.lighter}, ${palette.settings.lighter}), ${palette.custom.lighter}`,
                }}
                onClick={handleClick}
                size="small"
              >
                <AddEmojiIcon />
              </IconButton>
            </Box>
            {message.parent_id ? null : (
              <Button
                color="grayAccent"
                startIcon={<ReplyAllIcon fontSize="small" />}
                onClick={handleOpenThread}
              >
                Reply{message.reply_count ? ` (${message.reply_count})` : ""}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            borderRadius: "30px",
          },
        }}
      >
        <Box onClick={handleClose} sx={{ width: "202px", height: "62px" }}>
          <ReactionSelector />
        </Box>
      </Popover>
    </Box>
  );
}

export function CustomMessageChannel() {
  const { message } = useMessageContext();

  if (message.status === "sending") {
    return null;
  }
  return <CustomMessage />;
}

export default CustomMessage;
