import { BigQueryIcon } from "../../common/Icons/BigQueryIcon";
import { DatabricksIcon } from "../../common/Icons/DatabricksIcon";
import { BrighthiveIcon } from "../../common/Icons/BrighthiveIcon";
import { AwsIcon } from "../../common/Icons/AwsIcon";
import { GlueDataCatalogIcon } from "../../common/Icons/GlueDataCatalogIcon";

interface IconCellProps {
  value: string;
}

export const IconCell = ({ value }: IconCellProps) => {
  if (value === "BigQuery") {
    return <BigQueryIcon />;
  }

  if (value === "Databricks") {
    return <DatabricksIcon />;
  }

  if (value === "AWS") {
    return <AwsIcon />;
  }

  if (value === "GlueDataCatalog") {
    return <GlueDataCatalogIcon />;
  }

  return <BrighthiveIcon />;
};
