import React, { useEffect } from "react";
import { Box, Divider, Tooltip, useTheme } from "@mui/material";
import { Sidenav } from "../Sidenav/Sidenav";
import { Button } from "../Button/Button";
import { GridView, Home } from "@mui/icons-material";
import { SidenavButtonLink } from "../Sidenav/SidenavButtonLink";
import { Typography } from "../Typography/Typography";
import { SquareIcon } from "../SquareIcon/SquareIcon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ProjectIcon } from "../Icons/ProjectIcon";
import { DataAssetLibraryIcon } from "../Icons/DataAssetLibraryIcon";
import { MembersIcon } from "../Icons/MembersIcon";
import { GovernanceIcon } from "../Icons/GovernanceIcon";
import { WorkspaceSetupIcon } from "../Icons/WorkspaceSetupIcon";
import { useGetUserRole } from "../../hooks/useGetUserRole";
import { useWorkspaceId } from "../../hooks/useWorkspace";
import { BrighthiveIcon } from "../Icons/BrighthiveIcon";
import { GridIcon } from "../Icons/GridIcon";
import { SourcesIcon } from "../Icons/SourcesIcon";
import { ServicesIcon } from "../Icons/ServicesIcon";
import { SidebarMenuCollapse } from "../Icons/SidebarMenuCollapse";
import { QuestionCircle } from "../Icons/QuestionCircle";
import { BrighthiveLogoSvgWhite } from "../Icons/BrighthiveLogoSvgWhite";
import {
  GetCurrentUserInfoDocument,
  GetWorkspaceInfoDocument,
  WorkspaceRoleEnum,
} from "../../generated";
import { useQuery } from "@apollo/client";
import { getRandomNumber } from "../../helpers";
import { ChatBotIcon } from "../Icons/ChatBotIcon";
import { SearchListIcon } from "../Icons/SearchListIcon";

import {
  InnerCollapsibleWrapper,
  PostTitle,
  SidebarOwnCollapseButton,
  SidebarOwnCollapseWrapper,
  SideNavLogo,
  SideNavLogoLink,
  SideNavLogoText,
  SideNavLogoTypo,
  SideNavTopWrap,
  Wrapper,
} from "./style";
import { BrighthiveLogoSvg } from "../Icons/BrighthiveLogoSvg";
import { BrighthiveLogoSvgVii } from "../Icons/BrighthiveLogoSvgVii";
import { HomeViiIcon } from "../Icons/HomeViiIcon";
import { DabbeViiIcon } from "../Icons/DabbeViiIcon";
import { BrightBotViiIcon } from "../Icons/BrightBotViiIcon";
import { NavConnectViiIcon } from "../Icons/NavConnectViiIcon";
import { NavGovernViiIcon } from "../Icons/NavGovernViiIcon";
import { NavBuildViiIcon } from "../Icons/NavBuildViiIcon";
import { NavShareViiIcon } from "../Icons/NavShareViiIcon";
import { SourcesViiIcon } from "../Icons/SourcesViiIcon";
import { LayoutGridViiIcon } from "../Icons/LayoutGridViiIcon";
import { TablerListSearchViiIcon } from "../Icons/TablerListSearchViiIcon";
import { InnerCollapsibleButton } from "../SideBarV2/InnerCollapsibleButton";
import { genNav } from "./genNav";
import { AssetRoutes } from "../../routes";

export interface WorkspaceSidenavProps {
  workspaceName?: string;
  collapse: boolean;
  setCollapse: Function;
  isEditable?: boolean;
}

export const SideBarV3 = ({
  workspaceName,
  collapse,
  setCollapse,
  isEditable,
}: WorkspaceSidenavProps) => {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const { pathname } = useLocation();
  const { workspaceId } = useWorkspaceId();
  const { accessible, role, loading } = useGetUserRole(workspaceId);
  const { data, loading: workspaceLoading } = useQuery(
    GetWorkspaceInfoDocument,
    {
      variables: {
        input: {
          workspaceId: workspaceId || "",
        },
      },
    }
  );
  const showConfig = false;

  const { data: userInfo } = useQuery(GetCurrentUserInfoDocument);

  useEffect(() => {
    if (userInfo) {
      if (
        userInfo?.currentUser.workspaces.find(
          (workpace) => workpace?.id === workspaceId
        )?.acceptedPolicies
      )
        return;
      else {
        navigate(`/agree-policy/${workspaceId}`);
      }
    }
  }, [userInfo]);

  if (!data) return null;

  return (
    <>
      <Sidenav show={collapse} aria-label={workspaceName}>
        <Wrapper className={collapse ? "" : "sidenav-collapsed"}>
          <SideNavLogo aria-label="global navigation" role="navigation">
            <SideNavLogoLink
              tabIndex={1}
              removeStyling
              to={`/workspace/${workspaceId}/project`}
              sx={{
                ".large-logo": {
                  display: collapse ? "none" : "inline",

                  "@media (min-width: 768px)": {
                    display: collapse ? "inline" : "none",
                  },
                },
                ".logo-icon": {
                  display: collapse ? "inline" : "none",

                  "@media (min-width: 768px)": {
                    display: collapse ? "none" : "inline",
                  },
                },
              }}
            >
              {data?.workspace?.logoUrl ? (
                <SideNavLogoText>
                  <img
                    src={
                      `${data?.workspace?.logoUrl}?${getRandomNumber()}` || ""
                    }
                    width={24}
                    height={24}
                  />
                  <SideNavLogoTypo
                    variant="subtitle3"
                    sx={{
                      opacity: collapse ? "0" : "1",
                      visibility: collapse ? "hidden" : "visible",

                      "@media (min-width: 768px)": {
                        opacity: collapse ? "1" : "0",
                        visibility: collapse ? "visible" : "hidden",
                      },
                    }}
                  >
                    <Tooltip
                      title={workspaceName}
                      placement="bottom"
                      componentsProps={{
                        tooltip: {
                          sx: { backgroundColor: palette.common.black },
                        },
                        arrow: { sx: { color: palette.common.black } },
                      }}
                      arrow
                    >
                      <span>{workspaceName}</span>
                    </Tooltip>
                  </SideNavLogoTypo>
                </SideNavLogoText>
              ) : (
                <>
                  <BrighthiveLogoSvg className="large-logo" />
                  <BrighthiveIcon className="logo-icon" />
                </>
              )}
            </SideNavLogoLink>
          </SideNavLogo>

          {/*
            <Box alignItems="center" display="flex" sx={{ padding: "13px 16px" }}>
              <SquareIcon
                icon={<GridIcon />}
                backgroundColor={`${palette.gray.lighter}`}
                color={`${palette.common.white}`}
                sx={{
                  width: "28px",
                  height: "28px",
                  minWidth: "28px",
                  mr: 2,
                  borderRadius: "6px",
                }}
              />

              <Typography
                variant="subtitle3"
                sx={{
                  color: palette.common.white,
                  maxWidth: "180px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  opacity: collapse ? "0" : "1",
                  visibility: collapse ? "hidden" : "visible",
                  transition: "opacity 0.3s ease, visibility 0.3s ease",

                  "@media (min-width: 768px)": {
                    opacity: collapse ? "1" : "0",
                    visibility: collapse ? "visible" : "hidden",
                  },
                }}
              >
                <Tooltip
                  title={workspaceName}
                  placement="bottom"
                  componentsProps={{
                    tooltip: { sx: { backgroundColor: palette.common.black } },
                    arrow: { sx: { color: palette.common.black } },
                  }}
                  arrow
                >
                  <span>{workspaceName}</span>
                </Tooltip>
              </Typography>
            </Box>
          */}
          {!loading && (
            <SideNavTopWrap>
              {genNav(AssetRoutes.Workspace.routes, pathname, workspaceId, role)}
            </SideNavTopWrap>
          )}

          <Box
            display="none"
            mt="auto"
            p={2}
            justifyContent="center"
            sx={{
              a: {
                textDecoration: "none"
              },
              "a:hover, a:active": {
                textDecoration: "underline",
              },
            }}
          >
            {showConfig && (
              <Link to={`/workspace/${workspaceId}/workspace-configuration`}>
                <Button
                  variant="outlined"
                  color="grayAccent"
                  startIcon={<WorkspaceSetupIcon />}
                  sx={{ textTransform: "initial" }}
                >
                  Configure Workspace
                </Button>
              </Link>
            )}
          </Box>

          <SidebarOwnCollapseWrapper>
            <SidebarOwnCollapseButton
              sx={{
                transform: collapse ? "rotate(0)" : "rotate(180deg)",
                left: collapse ? "16px" : "20px",

                "@media (min-width: 768px)": {
                  transform: collapse ? "rotate(180deg)" : "rotate(0)",
                  left: collapse ? "20px" : "16px",
                },
              }}
              onClick={() => setCollapse((prev: boolean) => !prev)}
            >
              <SidebarMenuCollapse />
            </SidebarOwnCollapseButton>
          </SidebarOwnCollapseWrapper>
        </Wrapper>
      </Sidenav>
    </>
  );
};
