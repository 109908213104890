import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const SchemaListIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0001 1.6665C11.3808 1.6665 12.5001 2.7858 12.5001 4.1665C12.5001 5.25474 11.8048 6.18057 10.8342 6.52398L10.834 8.43835C12.2714 8.80861 13.3334 10.1136 13.3334 11.6665C13.3334 12.0923 13.2536 12.4995 13.108 12.8738L14.3914 13.7907C14.7989 13.5025 15.2964 13.3332 15.8334 13.3332C17.2142 13.3332 18.3334 14.4524 18.3334 15.8332C18.3334 17.2139 17.2142 18.3332 15.8334 18.3332C14.4527 18.3332 13.3334 17.2139 13.3334 15.8332C13.3334 15.5965 13.3663 15.3676 13.4277 15.1506L12.1345 14.227C11.5563 14.7095 10.8121 14.9998 10.0001 14.9998C9.18808 14.9998 8.44389 14.7095 7.86567 14.227L6.57244 15.1506C6.63387 15.3676 6.66675 15.5965 6.66675 15.8332C6.66675 17.2139 5.54746 18.3332 4.16675 18.3332C2.78604 18.3332 1.66675 17.2139 1.66675 15.8332C1.66675 14.4524 2.78604 13.3332 4.16675 13.3332C4.70377 13.3332 5.20125 13.5025 5.6087 13.7907L6.89211 12.8738C6.74659 12.4995 6.66675 12.0923 6.66675 11.6665C6.66675 10.1133 7.72916 8.80811 9.16695 8.43814L9.16681 6.52428C8.19577 6.1811 7.50008 5.25504 7.50008 4.1665C7.50008 2.7858 8.61933 1.6665 10.0001 1.6665ZM4.16675 14.9998C3.70651 14.9998 3.33341 15.3729 3.33341 15.8332C3.33341 16.2934 3.70651 16.6665 4.16675 16.6665C4.62698 16.6665 5.00008 16.2934 5.00008 15.8332C5.00008 15.3729 4.62698 14.9998 4.16675 14.9998ZM15.8334 14.9998C15.3732 14.9998 15.0001 15.3729 15.0001 15.8332C15.0001 16.2934 15.3732 16.6665 15.8334 16.6665C16.2937 16.6665 16.6667 16.2934 16.6667 15.8332C16.6667 15.3729 16.2937 14.9998 15.8334 14.9998ZM10.0001 9.99984C9.07958 9.99984 8.33342 10.746 8.33342 11.6665C8.33342 12.587 9.07958 13.3332 10.0001 13.3332C10.9206 13.3332 11.6667 12.587 11.6667 11.6665C11.6667 10.746 10.9206 9.99984 10.0001 9.99984ZM10.0001 3.33317C9.53983 3.33317 9.16675 3.70627 9.16675 4.1665C9.16675 4.62674 9.53983 4.99984 10.0001 4.99984C10.4603 4.99984 10.8334 4.62674 10.8334 4.1665C10.8334 3.70627 10.4603 3.33317 10.0001 3.33317Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
