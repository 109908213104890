import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const ChatBotIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99993 11.6667C9.35821 11.6672 8.72636 11.5159 8.16086 11.2264C7.59537 10.9368 7.11386 10.518 6.7594 10.0074L7.68495 9.42033C7.93832 9.78503 8.28231 10.0842 8.68624 10.2912C9.09017 10.4981 9.54149 10.6064 9.99993 10.6064C10.4584 10.6064 10.9097 10.4981 11.3136 10.2912C11.7176 10.0842 12.0615 9.78503 12.3149 9.42033L13.2405 10.0074C12.886 10.518 12.4045 10.9368 11.839 11.2264C11.2735 11.5159 10.6417 11.6672 9.99993 11.6667ZM12.2221 5.8334C12.0024 5.8334 11.7876 5.8956 11.6048 6.01214C11.4221 6.12868 11.2797 6.29432 11.1956 6.48812C11.1115 6.68192 11.0895 6.89517 11.1324 7.10091C11.1753 7.30664 11.2811 7.49562 11.4365 7.64395C11.5919 7.79228 11.7898 7.89329 12.0054 7.93421C12.2209 7.97513 12.4443 7.95413 12.6473 7.87386C12.8504 7.79358 13.0239 7.65764 13.146 7.48323C13.2681 7.30881 13.3332 7.10376 13.3332 6.89399C13.3347 6.75432 13.307 6.61577 13.2517 6.48645C13.1964 6.35713 13.1146 6.23965 13.0111 6.14088C12.9076 6.04211 12.7846 5.96404 12.6491 5.91124C12.5136 5.85844 12.3685 5.83198 12.2221 5.8334ZM7.77773 5.8334C7.55797 5.8334 7.34315 5.8956 7.16043 6.01214C6.97771 6.12868 6.8353 6.29432 6.7512 6.48812C6.66711 6.68192 6.6451 6.89517 6.68798 7.10091C6.73085 7.30664 6.83667 7.49562 6.99206 7.64395C7.14745 7.79228 7.34543 7.89329 7.56096 7.93421C7.7765 7.97513 7.9999 7.95413 8.20293 7.87386C8.40596 7.79358 8.57949 7.65764 8.70158 7.48323C8.82366 7.30881 8.88883 7.10376 8.88883 6.89399C8.89032 6.75432 8.86259 6.61577 8.80728 6.48645C8.75197 6.35713 8.67018 6.23965 8.56671 6.14088C8.46323 6.04211 8.34016 5.96404 8.20468 5.91124C8.0692 5.85844 7.92405 5.83198 7.77773 5.8334Z"
        fill="currentColor"
      />
      <path
        d="M10.93 17.5L10 16.9643L12.1429 13.2143H15.3571C15.4979 13.2145 15.6373 13.1869 15.7674 13.1332C15.8975 13.0794 16.0157 13.0005 16.1152 12.9009C16.2148 12.8014 16.2937 12.6832 16.3474 12.5531C16.4012 12.423 16.4288 12.2836 16.4286 12.1429V4.64286C16.4288 4.5021 16.4012 4.36268 16.3474 4.23259C16.2937 4.1025 16.2148 3.9843 16.1152 3.88477C16.0157 3.78524 15.8975 3.70632 15.7674 3.65256C15.6373 3.59879 15.4979 3.57122 15.3571 3.57143H4.64286C4.5021 3.57122 4.36268 3.59879 4.23259 3.65256C4.1025 3.70632 3.9843 3.78524 3.88477 3.88477C3.78524 3.9843 3.70632 4.1025 3.65256 4.23259C3.59879 4.36268 3.57122 4.5021 3.57143 4.64286V12.1429C3.57122 12.2836 3.59879 12.423 3.65256 12.5531C3.70632 12.6832 3.78524 12.8014 3.88477 12.9009C3.9843 13.0005 4.1025 13.0794 4.23259 13.1332C4.36268 13.1869 4.5021 13.2145 4.64286 13.2143H9.46429V14.2857H4.64286C4.07454 14.2857 3.52949 14.0599 3.12763 13.6581C2.72576 13.2562 2.5 12.7112 2.5 12.1429V4.64286C2.49993 4.36143 2.55531 4.08275 2.66297 3.82274C2.77064 3.56272 2.92847 3.32647 3.12747 3.12747C3.32647 2.92847 3.56272 2.77064 3.82274 2.66297C4.08275 2.55531 4.36143 2.49993 4.64286 2.5H15.3571C15.6386 2.49993 15.9172 2.55531 16.1773 2.66297C16.4373 2.77064 16.6735 2.92847 16.8725 3.12747C17.0715 3.32647 17.2294 3.56272 17.337 3.82274C17.4447 4.08275 17.5001 4.36143 17.5 4.64286V12.1429C17.5 12.7112 17.2742 13.2562 16.8724 13.6581C16.4705 14.0599 15.9255 14.2857 15.3571 14.2857H12.767L10.93 17.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
