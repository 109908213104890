import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const FolderIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="70"
      height="88"
      viewBox="0 0 70 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.91669 78.3759V17.4176C2.91669 12.2837 2.91669 9.71493 3.91579 7.75407C4.79463 6.02925 6.19594 4.62795 7.92076 3.74911C9.88161 2.75 12.4504 2.75 17.5842 2.75H59.7509C62.3178 2.75 63.6031 2.75 64.5835 3.24955C65.4459 3.68897 66.1426 4.38962 66.5821 5.25203C67.0816 6.23246 67.0834 7.51687 67.0834 10.0838V64.1671C67.0834 66.734 67.0816 68.0157 66.5821 68.9961C66.1426 69.8585 65.4468 70.5615 64.5844 71.0009C63.6049 71.5 62.3212 71.5 59.7593 71.5H13.2292C7.53375 71.5 2.91669 76.1171 2.91669 81.8125C2.91669 83.711 4.45571 85.25 6.35419 85.25H55.176C57.7379 85.25 59.0216 85.25 60.0011 84.7509C60.8635 84.3115 61.5593 83.609 61.9987 82.7466C62.4983 81.7661 62.5 80.4836 62.5 77.9167V71.5"
        stroke="currentColor"
        fill="none"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
