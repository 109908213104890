import { Avatar, Box, IconButton, useTheme } from "@mui/material";
import React from "react";
import { Button } from "../Button/Button";
import { AddCommnetIcon } from "../Icons/AddCommentIcon";
import { Typography } from "../Typography/Typography";

function NotificationItem() {
  const { palette } = useTheme();
  return (
    <Box bgcolor={palette.common.white} p={4} display="flex" borderRadius={1}>
      <Avatar sx={{ width: 32, height: 32 }}>A</Avatar>
      <Box marginLeft={4}>
        <Box display="flex" alignItems="center">
          <Typography fontWeight="bold" variant="body2" marginRight={1}>
            Sharon Agathon
          </Typography>
          <Typography color={palette.gray.dark} variant="caption">
            1 hour ago
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography variant="body3">
            Replied to your comment in{" "}
            <Box component="span" fontWeight="bold">
              Regional Dual Enrollment Outcomes
            </Box>{" "}
            in the{" "}
            <Box component="span" fontWeight="bold">
              Post Secondary Outcomes
            </Box>{" "}
            workspace
          </Typography>
        </Box>
        <Box
          mt={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <IconButton
            sx={{
              width: 36,
              height: 24,
              borderRadius: 20,
              background: `linear-gradient(0deg, ${palette.settings.lighter}, ${palette.settings.lighter}), ${palette.custom.lighter}`,
            }}
            size="small"
          >
            <AddCommnetIcon />
          </IconButton>

          <Button color="link" sx={{ padding: 0 }}>
            View thread
            <Box
              sx={{
                height: 16,
                width: 16,
                background: palette.link.main,
                marginLeft: 1,
              }}
            />
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default NotificationItem;
