import React from "react";
import { Box, BoxProps, useTheme } from "@mui/material";

interface SpecificSidenavProps {
  show: boolean;
}

type SidenavProps = SpecificSidenavProps & BoxProps;

export const Sidenav = ({ show, ...rest }: SidenavProps) => {
  const { palette } = useTheme();

  return (
    <Box
      flexShrink={0}
      role="navigation"
      display="flex"
      flexDirection="column"
      position="relative"
      //paddingBottom="6rem"
      minHeight="100%"
      boxSizing="border-box"
      sx={{
        backgroundColor: palette.common.white,
        borderRight: `1px solid ${palette.action.disabledBackground}`,
        width: "260px"
      }}
      //sx={{
        //transition: "all linear 0.2s",
        // width: show ? "5rem" : "15rem",
        //width: show ? "61px" : "240px",

        //"@media (min-width: 768px)": {
          // width: show ? "15rem" : "5rem"
          //width: show ? "240px" : "61px",
        //},
      //}}
      {...rest}
    />
  );
};
