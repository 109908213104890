import { Validator } from "jsonschema";

export const convertSchemaToFields = (schema: any): any => {
  const properties = schema?.properties;
  if (typeof properties === "object") {
    return Object.getOwnPropertyNames(properties).map((key) => {
      const property = properties[key];
      const validators = [];
      if (typeof Array.isArray(schema?.required)) {
        if (schema?.required?.includes(key)) {
          validators.push({ validate: "required" });
        }
      }
      if (property.uniqueItems) {
        validators.push({ validate: "unique" });
      }
      return {
        key,
        label: property.label || key,
        description: property.description,
        validators,
      };
    });
  }
  return [];
};

const validator = new Validator();

const validateValue = (value: any, property: any) => {
  let valueToValidate = value;
  const propertyIsNumberOrInteger =
    property.type === "number" || property.type === "integer";
  const anyOfContainsNumberOrInteger =
    Array.isArray(property.anyOf) &&
    property.anyOf.some(
      (prop: any) => prop?.type === "number" || prop?.type === "integer"
    ) &&
    !isNaN(value);
  const enumContainsNumericValue =
    Array.isArray(property.enum) &&
    property.enum.some((currEnumValue: any) => !isNaN(currEnumValue)) &&
    !isNaN(value);

  if (
    propertyIsNumberOrInteger ||
    anyOfContainsNumberOrInteger ||
    enumContainsNumericValue
  ) {
    valueToValidate = Number(value);
  }
  return validator.validate(valueToValidate, property, { nestedErrors: true });
};

export const generateFieldHooks = (schema: any) => {
  const fieldsHooks: any = {};
  const properties = schema?.properties;
  if (typeof properties === "object") {
    Object.getOwnPropertyNames(properties).forEach((key) => {
      const property = properties[key];
      fieldsHooks[key] = (values: any) => {
        let changeValues: any = [];
        values.forEach(([value, index]: any) => {
          const validationResult = validateValue(value, property);
          if (
            Array.isArray(validationResult.errors) &&
            validationResult.errors.length > 0
          ) {
            const message = validationResult.errors
              .map((error) => error.message)
              .join(", ");
            changeValues.push([
              {
                info: [
                  {
                    message,
                    level: "error",
                  },
                ],
              },
              index,
            ]);
          }
        });
        return changeValues;
      };
    });
  }
  return fieldsHooks;
};

export const generateOnRecordChange = (schema: any): any => {
  let onRecordChange = null;
  const properties = schema?.properties;
  if (typeof properties === "object") {
    onRecordChange = (record: any, index: number) => {
      const onRecordChangeObject: any = {};
      Object.getOwnPropertyNames(properties).forEach((key) => {
        const property = properties[key];
        if (record && record[key]) {
          const validationResult = validateValue(record[key], property);
          if (
            Array.isArray(validationResult.errors) &&
            validationResult.errors.length > 0
          ) {
            const message = validationResult.errors
              .map((error) => error.message)
              .join(", ");
            onRecordChangeObject[key] = {
              info: [
                {
                  message,
                  level: "error",
                },
              ],
            };
          }
        }
      });
      return onRecordChangeObject;
    };
  }
  return onRecordChange;
};

export function convertToCSV(data: any) {
  const csv = data.map((row: any) => Object.values(row));
  csv.unshift(Object.keys(data[0]));
  return csv.join("\n");
}
