import React, { useState } from "react";
import { Button } from "../Button/Button";
import {
  CheckBox,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { Menu } from "../Menu/Menu";
import { MenuItem } from "../Menu/MenuItem";
import { useMenu } from "../Menu/useMenu";
import { ListItemText } from "../Menu/ListItemText";
import UppyModal from "../UppyModal/UppyModal";
import { useApolloClient, useMutation } from "@apollo/client";
import {
  GetDataAssetsDocument,
  OnboardDataAssetToWorkspaceDocument,
} from "../../generated";
import { useParams } from "react-router-dom";
import { Checkbox } from "@mui/material";

export interface AddDataAssetButtonProps {
  onClick: (type: AddDataAssetButtonType) => void;
}

export enum AddDataAssetButtonType {
  Upload = "Upload",
  ExtractLoad = "Extract and load",
  APIconnect = "API connect",
  BulkTransfer = "Bulk Transfer",
}

export const AddDataAsset = ({
  uploadOpen,
  setUploadOpen,
}: {
  uploadOpen: boolean;
  setUploadOpen: Function;
}) => {
  const { workspaceId } = useParams();
  const [onboardDataAsset] = useMutation(OnboardDataAssetToWorkspaceDocument);
  const client = useApolloClient();

  return (
    <>
      <UppyModal
        open={uploadOpen}
        onClose={() => {
          client.refetchQueries({ include: [GetDataAssetsDocument] });
          setUploadOpen(false);
        }}
        getUploadParameters={async (file) => {
          const res = await onboardDataAsset({
            variables: {
              input: {
                workspaceId: workspaceId || "",
                name: file.name,
              },
            },
          });
          return {
            url: res.data?.onboardDataAssetToWorkspace?.presignedUrl || "",
            headers: {
              "content-type": "text/csv",
            },
            method: "PUT",
          };
        }}
      />
    </>
  );
};
